import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { EXPLANATIONS_OF_SOME_INFRA_STATUSES } from "../channels/ChannelTableCell";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingLayout from "../../layout/Loading";
import ArrowBackIcon from "../../icons/ArrowBack";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import EventSummary from "../../components/EventSummary";
import DotIcon from "../../icons/Dot";
import type { RootState } from "../../store";
import type { ChannelSingleState } from "./singleSlice";
import { getSingleChannel, resetSingleChannel } from "./singleSlice";
import {
  cellIdToString,
  channelInfraStatusToString,
  channelStatusToString,
  expIdToString,
} from "../../utils/labels";
import colors from "../../theme/colors";
import { Divider } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { AuthState } from "../../store/auth/slice";

const SingleChannelLayout = () => {
  const { channel_id = "" } = useParams();
  const isTablet = useMediaQuery("(min-width:760px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    channel,
    status: { get: channelStatus },
    error: { get: channelError },
  } = useSelector<RootState, ChannelSingleState>(
    ({ channelSingle }) => channelSingle
  );
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  useEffect(() => {
    dispatch(resetSingleChannel());
    dispatch(getSingleChannel(parseInt(channel_id)));
  }, [channel_id, user, dispatch]);

  if (channelStatus !== "succeeded" || !channel) {
    return (
      <Box p={32} style={{ background: "white" }}>
        <LoadingLayout light error={channelError || ""} />
      </Box>
    );
  }

  return (
    <>
      <Paper variant="outlined" square>
        <Box px={isTablet ? 6 : 0} py={3} display="flex" alignItems="center">
          <IconButton
            size="large"
            onClick={() => navigate("/infrastructure/channels")}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h2">
            Channel ID:{" "}
            <span style={{ fontWeight: 400 }}>{channel.channel.fullname}</span>
          </Typography>

          <Box ml="auto" display="flex" alignItems="center">
            <ButtonLogEvent itemType={"channel"} objects={[channel]} />
          </Box>
        </Box>
      </Paper>

      <Grid container>
        <Grid item xs={12}>
          <ChannelInfo channel={channel} />
        </Grid>
        <Grid item xs={12}>
          <ExperimentInfo channel={channel} />
        </Grid>
        <Grid item xs={12}>
          <EventSummary
            resourceType={"channel"}
            resource_id={channel.channel.channel_id}
          />
        </Grid>
      </Grid>
    </>
  );
};

type ChannelInfoProps = {
  channel: Channel;
};

const ChannelInfo = ({ channel }: ChannelInfoProps) => {
  const isTablet = useMediaQuery("(min-width:760px)");
  const navigate = useNavigate();
  const from = `/infrastructure/channels/${channel.channel.channel_id}`;
  const channelStatusInfo = () =>
    channel.channel.status ? (
      <>
        <DotIcon
          style={{
            width: 8,
            height: 8,
            marginBottom: 2,
            color:
              channel.channel.status === "O"
                ? colors.accent.cta
                : channel.channel.status === "I"
                ? colors.accent.green
                : colors.accent.red,
          }}
        />
        {` ${channelStatusToString(channel.channel.status)}`}
      </>
    ) : (
      "-"
    );
  const infraStatusInfo = () => {
    const infraStatus = channel.channel.infra_status;
    const tooltipText = EXPLANATIONS_OF_SOME_INFRA_STATUSES[infraStatus];
    if (tooltipText) {
      return (
        <Tooltip arrow placement="left" title={tooltipText}>
          <div>{channelInfraStatusToString(infraStatus)}</div>
        </Tooltip>
      );
    }
    return channelInfraStatusToString(infraStatus);
  };

  return (
    <Box px={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Channel Info</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Channel Status
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    <Typography paragraph>{channelStatusInfo()}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Infrastructure Status
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    <Typography paragraph>{infraStatusInfo()}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Tester
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography paragraph>
                    {channel.tester.name || "-"}
                  </Typography>
                </Grid>
                {channel.test_stand && channel.test_stand.test_stand_id && (
                  <>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Test Stand
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Box display="flex" alignItems="center">
                        <Typography paragraph>
                          {" "}
                          <MuiLink
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/infrastructure/test-stands/${channel.test_stand.test_stand_id}`,
                                {
                                  state: {
                                    from,
                                  },
                                }
                              )
                            }
                          >
                            {channel.test_stand.name}
                          </MuiLink>
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Divider orientation="vertical" flexItem />
                <Box ml={isTablet ? 5 : 0} width="100%">
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Incubator
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {channel.incubator.name || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Postion ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {channel.position.name || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Reserved Cell
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {channel.reserved_cell.cell_id || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

const ExperimentInfo = ({ channel }: ChannelInfoProps) => {
  const navigate = useNavigate();
  const from = `/infrastructure/channels/${channel.channel.channel_id}`;
  const cell_id = channel.cell.cell_id;
  const exp_id = channel.experiment.exp_id;

  return (
    <Box px={6} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Current Experiment</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Experiment ID
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    {exp_id ? (
                      <MuiLink
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/experiments/${exp_id}`, {
                            state: {
                              from,
                            },
                          })
                        }
                      >
                        {expIdToString(exp_id)}
                      </MuiLink>
                    ) : (
                      "-"
                    )}
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Project
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    <Typography paragraph>
                      {channel.experiment.project.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    Owner
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    <Typography paragraph>
                      {channel.owner.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="textSecondary" paragraph>
                    On-Test Cell ID
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box display="flex" alignItems="center">
                    {cell_id ? (
                      <MuiLink
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/cells/${cell_id}`, {
                            state: {
                              from,
                              stateKey: "ALL",
                            },
                          })
                        }
                      >
                        {cellIdToString(cell_id)}
                      </MuiLink>
                    ) : (
                      "-"
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SingleChannelLayout;
