import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import client, {
  PAGE_SIZE,
  GATEWAY_TIMEOUT_ERROR,
  GATEWAY_TIMEOUT_RESPONSE,
} from "../../api";
import { isoDateToDateString } from "../../utils/labels";
import assembleCellsQuery from "../../utils/assembleCellsQuery";
import * as Sentry from "@sentry/react";

export type CellFilterArgs = APIListArgs<Cell, CellFilters>;

const getValueFromKey = (cell: Cell, falconKey: FalconKey<Cell>) => {
  if (falconKey === "condition__specified") {
    return isoDateToDateString(cell.condition.specified);
  }

  if (falconKey === "staged") {
    return isoDateToDateString(cell.staged);
  }

  if (falconKey === "committed") {
    return isoDateToDateString(cell.committed);
  }

  if (falconKey === "test__created_time") {
    return isoDateToDateString(cell.test.created_time);
  }

  if (falconKey === "ready_off__completed_at") {
    return isoDateToDateString(cell.ready_off.completed_at);
  }

  const res = falconKey.split("__");
  const key = res[0] as keyof Cell;
  const subKey = res[1];

  if (!subKey) {
    return cell[key];
  }

  // TODO proper typing
  return (cell[key] as any)[subKey];
};

const getBulkValues = (cells: Cell[], falconKey: FalconKey<Cell>) => {
  const array = cells.map((cell) => getValueFromKey(cell, falconKey));
  return new Set<string | number>(array);
};

export interface CellListState {
  cells: Cell[];
  visibleCells: Cell[];
  hasMore: boolean;
  args: CellFilterArgs;
  grouping: {
    groupingEnabled: boolean;
    allGroupsExpanded: boolean;
    expandedGroups: (string | number)[];
    expandedConditions: number[];
  };
  selectedView: CustomTableView<Cell> | null;
  selected: number[];
  justStaged: number[];
  justCommitted: number[];
  justFlaggedReadyOff: number[];
  justUnflaggedReadyOff: number[];
  justDiscontinued: number[];
  justUndiscontinued: number[];
  channelUsage: Partial<Record<ChannelAvailability, number>>;
  totals: Partial<Record<CellStatus, number>>;
  status: {
    totals: "idle" | "loading" | "succeeded" | "failed";
    list: "idle" | "loading" | "succeeded" | "failed";
    stage: "idle" | "loading" | "succeeded" | "failed";
    modify: "idle" | "loading" | "succeeded" | "failed";
    commit: "idle" | "loading" | "succeeded" | "pending" | "failed";
    priority: "idle" | "loading" | "succeeded" | "failed";
    readyOff: "idle" | "loading" | "succeeded" | "failed";
    takeOff: "idle" | "loading" | "succeeded" | "failed";
    discontinue: "idle" | "loading" | "succeeded" | "failed";
    channelUsage: "idle" | "loading" | "succeeded" | "failed";
    channelReservation: "idle" | "loading" | "succeeded" | "failed";
    testStandReservation: "idle" | "loading" | "succeeded" | "failed";
    testStandReservationCancellation:
      | "idle"
      | "loading"
      | "succeeded"
      | "failed";
    toggleTestStandsForCells: "idle" | "loading" | "succeeded" | "failed";
    reservationCancellation: "idle" | "loading" | "succeeded" | "failed";
  };
  warning: {
    commit: null | string;
  };
  error: {
    totals: null | string;
    list: null | string;
    stage: null | string;
    modify: null | string;
    commit: null | string;
    priority: null | string;
    readyOff: null | string;
    takeOff: null | string;
    discontinue: null | string;
    channelUsage: null | string;
    channelReservation: null | string;
    testStandReservation: null | string;
    testStandReservationCancellation: null | string;
    toggleTestStandsForCells: null | string;
    reservationCancellation: null | string;
  };
}

const initialState = (prefix: string): CellListState => ({
  cells: [],
  visibleCells: [],
  hasMore: false,
  args: {
    orderBy:
      prefix === "ALL" || prefix === "EXPERIMENT"
        ? "condition__specified"
        : "experiment__exp_id",
    order: "desc",
    page: 0,
    filters: {},
    presetFilters: {},
  },
  grouping: {
    groupingEnabled: false,
    allGroupsExpanded: false,
    expandedGroups: [],
    expandedConditions: [],
  },
  selectedView: null,
  selected: [],
  justStaged: [],
  justCommitted: [],
  justFlaggedReadyOff: [],
  justUnflaggedReadyOff: [],
  justDiscontinued: [],
  justUndiscontinued: [],
  channelUsage: {},
  totals: {},
  status: {
    totals: "idle",
    list: "idle",
    stage: "idle",
    modify: "idle",
    commit: "idle",
    priority: "idle",
    readyOff: "idle",
    takeOff: "idle",
    discontinue: "idle",
    channelUsage: "idle",
    channelReservation: "idle",
    testStandReservation: "idle",
    testStandReservationCancellation: "idle",
    toggleTestStandsForCells: "idle",
    reservationCancellation: "idle",
  },
  warning: {
    commit: null,
  },
  error: {
    totals: null,
    list: null,
    stage: null,
    modify: null,
    commit: null,
    priority: null,
    readyOff: null,
    takeOff: null,
    discontinue: null,
    channelUsage: null,
    channelReservation: null,
    testStandReservation: null,
    testStandReservationCancellation: null,
    toggleTestStandsForCells: null,
    reservationCancellation: null,
  },
});

const createCellsSlice = (prefix: string) => {
  const CELLS = `CELLS/${prefix}`;

  const getCellTotals = createAsyncThunk(`${CELLS}/totals`, async () => {
    const response = await client.get(`meta/cells/counts`);
    return response.counts;
  });

  const listCells = createAsyncThunk(
    `${CELLS}/list`,
    async ({
      pageSize = PAGE_SIZE,
      useV2API = false,
      refresh,
      ...args
    }: CellFilterArgs) => {
      let response;
      if (useV2API) {
        response = await client.get(
          `meta/cells?${assembleCellsQuery({
            pageSize,
            refresh,
            ...args,
          })}`,
          { apiVersion: "v2" }
        );
      } else {
        response = await client.get(
          `meta/cells?cells_of_condition=True${assembleCellsQuery({
            pageSize,
            refresh,
            ...args,
          })}`
        );
      }

      return { response, args: { pageSize, ...args }, refresh };
    }
  );

  const stageCells = createAsyncThunk(
    `${CELLS}/stage`,
    async ({ cell_ids, undo }: { cell_ids: number[]; undo: boolean }) => {
      const updates = cell_ids.map((cell_id) => ({
        method: "POST",
        path: `/api/v1/meta/cells/${cell_id}/${!undo ? "stage" : "unstage"}`,
        data: {},
      }));

      const results: BatchResponse<any>[] = await client.post(
        "core/batch",
        updates
      );
      const errors = results
        .filter(({ status }) => status >= 300)
        .map(({ body: { title } }) => title);

      return { justStaged: !undo ? cell_ids : [], errors };
    }
  );

  const commitCells = createAsyncThunk(
    `${CELLS}/commit`,
    async (cell_ids: number[]) => {
      let cellsCommitted: number[] = [];
      let error = null;
      try {
        await client.post("meta/cells/commit", { cell_ids });
        cellsCommitted = cell_ids;
      } catch (err) {
        error = err;
        if (cell_ids.length > 1) error += " No cells were committed.";
      }

      return { justCommitted: cellsCommitted, error };
    }
  );

  const changePriority = createAsyncThunk(
    `${CELLS}/priority`,
    async ({
      condition_ids,
      priority,
    }: {
      condition_ids: number[];
      priority: number;
    }) => {
      const updates = condition_ids.map((condition_id) => ({
        method: "PUT",
        path: `/api/v1/meta/cell-conditions/${condition_id}/priority`,
        data: { priority },
      }));

      const results: BatchResponse<any>[] = await client.post(
        "core/batch",
        updates
      );
      const errors = results
        .filter(({ status }) => status >= 300)
        .map(({ body: { title } }) => title);

      return { errors };
    }
  );

  const readyOff = createAsyncThunk(
    `${CELLS}/readyOff`,
    async ({
      cells,
      cancel,
      user_id,
    }: {
      cells: (Cell | CellSingle)[];
      cancel: boolean;
      user_id?: number;
    }) => {
      const test_meta_ids = cells.map(
        ({ test_meta__test_meta_id }) => test_meta__test_meta_id
      );
      const cell_ids = cells.map(({ cell_id }) => cell_id);

      const updates = test_meta_ids.map((test_meta_id) => ({
        method: "PATCH",
        path: cancel
          ? `/api/v1/meta/test-metas/${test_meta_id}/undo-complete/ready_off`
          : `/api/v1/meta/test-metas/${test_meta_id}/complete/ready_off`,
        data: {
          user_id,
        },
      }));

      const results: BatchResponse<any>[] = await client.post(
        "core/batch",
        updates
      );
      const errors = results
        .filter(({ status }) => status >= 300)
        .map(({ body: { title } }) => title);

      return {
        justFlaggedReadyOff: cancel ? [] : cell_ids,
        justUnflaggedReadyOff: cancel ? cell_ids : [],
        errors,
      };
    }
  );

  const takeOff = createAsyncThunk(
    `${CELLS}/takeOff`,
    async ({
      cells,
      user_id,
    }: {
      cells: (Cell | CellSingle)[];
      user_id?: number;
    }) => {
      const cell_ids = cells.map((cell) => cell.cell_id);

      let error: any = "";
      try {
        await client.post("meta/cells/bulk-off-test", { cell_ids: cell_ids });
      } catch (err) {
        error = err;
      }

      return {
        error,
      };
    }
  );

  const toggleTestStandsForCells = createAsyncThunk(
    `${CELLS}/toggleTestStandsForCells`,
    async ({ cells }: { cells: (Cell | CellSingle)[] }) => {
      const cell_condition_ids = cells
        .filter((cell) => !!cell.condition.cell_condition_id)
        .map((cell) => cell.condition.cell_condition_id);

      let error: any = "";
      try {
        await client.put("meta/cell-conditions/toggle-test-stand-override", {
          cell_condition_ids,
        });
      } catch (err) {
        error = err;
      }

      return {
        error,
      };
    }
  );

  const discontinueCells = createAsyncThunk(
    `${CELLS}/discontinue`,
    async ({
      cells,
      cancel,
    }: {
      cells: (Cell | CellSingle)[];
      cancel: boolean;
    }) => {
      const cell_ids = cells.map(({ cell_id }) => cell_id);

      const updates = cell_ids.map((cell_id) => ({
        method: "POST",
        path: cancel
          ? `/api/v1/meta/cells/${cell_id}/undo-discontinue`
          : `/api/v1/meta/cells/${cell_id}/discontinue`,
        data: {},
      }));

      const results: BatchResponse<any>[] = await client.post(
        "core/batch",
        updates
      );
      const errors = results
        .filter(({ status }) => status >= 300)
        .map(({ body: { title } }) => title);

      return {
        justDiscontinued: cancel ? [] : cell_ids,
        justUndiscontinued: cancel ? cell_ids : [],
        errors,
      };
    }
  );

  const getChannelUsage = createAsyncThunk(
    `${CELLS}/channelUsage`,
    async (cell_ids: number[]) =>
      client.get(`meta/channels/usage?cell_ids=${cell_ids.join("&cell_ids=")}`)
  );

  const reserveChannels = createAsyncThunk(
    `${CELLS}/reserveChannels`,
    async (_reservations: Record<number, Channel | null>) => {
      const reservations: Record<string, number[]> = {};

      for (const cell_id in _reservations) {
        if (_reservations[parseInt(cell_id)]) {
          const channel = _reservations[parseInt(cell_id)]!.channel;
          if (channel.fullname) {
            if (channel.fullname in reservations) {
              reservations[channel.fullname].push(parseInt(cell_id));
            } else {
              reservations[channel.fullname] = [parseInt(cell_id)];
            }
          } else {
            const errMessage = `Channel ${channel.channel_id} does not have a fullname value, could not reserve channel for Cell ${cell_id}.`;
            Sentry.captureMessage(errMessage, {
              level: Sentry.Severity.Warning,
            });
          }
        }
      }

      return client.post("meta/channels/reserve", {
        data: reservations,
      });
    }
  );

  const cancelReservations = createAsyncThunk(
    `${CELLS}/cancelReservations`,
    async (_cells: Cell[]) => {
      const channelsToCancel = _cells.reduce((channels, selectedCell) => {
        const channel_name = selectedCell.reserved_channel?.channel?.fullname;
        if (!channel_name) return channels;
        return {
          ...channels,
          [channel_name]: null,
        };
      }, {});

      return client.post("meta/channels/reserve", {
        data: channelsToCancel,
      });
    }
  );

  const reserveTestStands = createAsyncThunk(
    `${CELLS}/reserveTestStands`,
    async (_reservations: Record<number, TestStand | null>) => {
      const reservations: Record<number, number> = {};

      for (const cell_id in _reservations) {
        if (_reservations[parseInt(cell_id)]) {
          reservations[cell_id] =
            _reservations[parseInt(cell_id)]!.test_stand_id;
        }
      }

      return client.post("meta/test-stands/reserve", {
        data: reservations,
      });
    }
  );

  const cancelTestStandReservations = createAsyncThunk(
    `${CELLS}/cancelTestStandReservations`,
    async (_cells: Cell[]) => {
      const testStandReservationsToCancel = _cells.reduce(
        (cellIds, selectedCell) => {
          return {
            ...cellIds,
            [selectedCell.cell_id]: null,
          };
        },
        {}
      );

      return client.post("meta/test-stands/reserve", {
        data: testStandReservationsToCancel,
      });
    }
  );

  const modifyCells = createAsyncThunk(
    `${CELLS}/modifyCells`,
    async ({ cells, data }: { cells: Cell[]; data: Partial<Cell> }) =>
      client.post(
        "core/batch",
        cells.map(({ cell_id }) => ({
          method: "PUT",
          path: `/api/v1/meta/cells/${cell_id}`,
          data,
        }))
      )
  );

  const slice = createSlice({
    name: CELLS,
    initialState: initialState(prefix),
    reducers: {
      resetCellListState: (state) => Object.assign(state, initialState(prefix)),
      resetCellListStatus: (state) => {
        state.status.list = "idle";
        state.error.list = null;
      },
      resetStageCells: (state) => {
        state.justStaged = [];
        state.status.stage = "idle";
        state.error.stage = null;
      },
      resetCommitCells: (state) => {
        state.justCommitted = [];
        state.status.commit = "idle";
        state.error.commit = null;
      },
      resetPriority: (state) => {
        state.status.priority = "idle";
        state.error.priority = null;
      },
      resetReadyOff: (state) => {
        state.justFlaggedReadyOff = [];
        state.justUnflaggedReadyOff = [];
        state.status.readyOff = "idle";
        state.error.readyOff = null;
      },
      resetTakeOff: (state) => {
        state.status.takeOff = "idle";
        state.error.takeOff = null;
      },
      resetToggleTestStandsForCells: (state) => {
        state.status.toggleTestStandsForCells = "idle";
        state.error.toggleTestStandsForCells = null;
      },
      resetDiscontinue: (state) => {
        state.justDiscontinued = [];
        state.justUndiscontinued = [];
        state.status.discontinue = "idle";
        state.error.discontinue = null;
      },
      resetModify: (state) => {
        state.status.modify = "idle";
        state.error.modify = null;
      },
      resetChannelUsage: (state) => {
        state.channelUsage = {};
        state.status.channelUsage = "idle";
        state.error.channelUsage = null;
      },
      resetChannelReservation: (state) => {
        state.status.channelReservation = "idle";
        state.error.channelReservation = null;
      },
      resetTestStandReservation: (state) => {
        state.status.testStandReservation = "idle";
        state.error.testStandReservation = null;
      },
      resetReservationCancellation: (state) => {
        state.status.reservationCancellation = "idle";
        state.error.reservationCancellation = null;
      },
      resetTestStandReservationCancellation: (state) => {
        state.status.testStandReservationCancellation = "idle";
        state.error.testStandReservationCancellation = null;
      },
      selectView: (
        state,
        { payload: view }: PayloadAction<CustomTableView<Cell> | null>
      ) => {
        state.selectedView = view;
      },
      enableGrouping(state) {
        state.grouping.groupingEnabled = true;
        state.grouping.allGroupsExpanded = true;
        const bulkValues = getBulkValues(state.cells, state.args.orderBy);
        state.grouping.expandedGroups = Array.from(bulkValues);
      },
      disableGrouping(state) {
        state.grouping.expandedGroups = [];
        state.grouping.groupingEnabled = false;
      },
      expandAllGroups(state) {
        const bulkValues = getBulkValues(state.cells, state.args.orderBy);
        state.grouping.allGroupsExpanded = true;
        state.grouping.expandedGroups = Array.from(bulkValues);
        const bulkConditions = getBulkValues(
          state.cells,
          "condition__cell_condition_id"
        );
        state.grouping.expandedConditions = Array.from(bulkConditions).map(
          (id) => parseInt(id as string)
        );
      },
      collapseAllGroups(state) {
        state.grouping.expandedGroups = [];
        state.grouping.expandedConditions = [];
        state.grouping.allGroupsExpanded = false;
      },
      expandGroup(state, action: PayloadAction<string | number>) {
        state.grouping.expandedGroups = [
          ...state.grouping.expandedGroups,
          action.payload,
        ];
      },
      collapseGroup(state, action: PayloadAction<string | number>) {
        const groups = [...state.grouping.expandedGroups];
        const index = groups.indexOf(action.payload);
        groups.splice(index, 1);
        state.grouping.expandedGroups = groups;
        state.grouping.allGroupsExpanded = false;

        // Collapse inner conditions too
        if (state.grouping.expandedConditions.length > 0) {
          const groupCells = state.cells.filter(
            (cell) =>
              getValueFromKey(cell, state.args.orderBy) === action.payload
          );
          const conditionIds = uniq(
            groupCells.map((cell) => cell.condition.cell_condition_id!)
          );
          const conditions = [...state.grouping.expandedConditions];
          conditionIds.forEach((id) => {
            const index = conditions.indexOf(id);
            if (index !== -1) {
              conditions.splice(index, 1);
            }
          });
          state.grouping.expandedConditions = conditions;
        }
      },
      expandCondition(state, action: PayloadAction<number>) {
        state.grouping.expandedConditions = [
          ...state.grouping.expandedConditions,
          action.payload,
        ];
      },
      collapseCondition(state, action: PayloadAction<number>) {
        const groups = [...state.grouping.expandedConditions];
        const index = groups.indexOf(action.payload);
        groups.splice(index, 1);
        state.grouping.expandedConditions = groups;
        state.grouping.allGroupsExpanded = false;
      },
      selectCells(state, action: PayloadAction<number[]>) {
        state.selected = uniq([...state.selected, ...action.payload]);
      },
      deselectCells(state, action: PayloadAction<number[]>) {
        const selected = [...state.selected];
        action.payload.forEach((id) => {
          const index = selected.indexOf(id);
          if (index !== -1) {
            selected.splice(index, 1);
          }
        });
        state.selected = uniq(selected);
      },
      selectAllVisibleCells(state) {
        state.selected = state.visibleCells.map((cell) => cell.cell_id);
      },
      deselectAllVisibleCells(state) {
        state.selected = [];
      },
    },
    extraReducers: (builder) => {
      builder
        // Cell totals
        .addCase(getCellTotals.pending, (state) => {
          state.status.totals = "loading";
        })
        .addCase(getCellTotals.fulfilled, (state, { payload }) => {
          state.status.totals = "succeeded";
          state.totals = payload;
        })
        .addCase(getCellTotals.rejected, (state, { error }) => {
          state.status.totals = "failed";
          state.error.totals = error.message as string;
        })
        // List Cells
        .addCase(listCells.pending, (state) => {
          state.status.list = "loading";
        })
        .addCase(listCells.fulfilled, (state, { payload }) => {
          if (!payload.response) {
            state.status.list = "failed";
            state.error.list = "Unknown error";
            return;
          }

          state.hasMore = payload.response.meta.more;

          let argsChanged = payload.args.page === 0;
          for (const argKey in payload.args) {
            if (argKey === "page" || argKey === "presetFilters") {
              continue;
            }

            if (argKey === "filters") {
              for (let filterKey in payload.args.filters) {
                const existingFilter =
                  state.args.filters[filterKey as keyof CellFilters] || [];
                const newFilter =
                  payload.args.filters[filterKey as keyof CellFilters] || [];

                if (!isEqual(existingFilter, newFilter)) {
                  argsChanged = true;
                }
              }
            } else if (
              state.args[argKey as keyof CellFilterArgs] !==
              payload.args[
                argKey as keyof Omit<CellFilterArgs, "refresh" | "useV2API">
              ]
            ) {
              argsChanged = true;
            }
          }

          if (payload.refresh) {
            state.cells = payload.response.data;

            // Experiments currently require hidden cells because the
            // cells associated with modules will show up in a separate
            // list view on the same page
            prefix === "EXPERIMENT"
              ? (state.visibleCells = payload.response.data.filter(
                  (cell: Cell) => cell.module.module_id === null
                ))
              : (state.visibleCells = payload.response.data);
          } else if (argsChanged) {
            // Reset the array because args changed
            state.cells = payload.response.data;
            state.visibleCells = payload.response.data;
            state.selected = [...state.selected].filter((cell_id) =>
              payload.response.data.some(
                (cell: Cell) => cell.cell_id === cell_id
              )
            );
            state.grouping.expandedGroups = [];
            if (state.grouping.groupingEnabled) {
              state.grouping.allGroupsExpanded = true;
              state.grouping.expandedConditions = [];
            }
          } else {
            // Add any fetched cells to the array
            state.cells = state.cells.concat(payload.response.data);
            state.visibleCells = state.visibleCells.concat(
              payload.response.data
            );
          }

          state.args = payload.args;

          if (state.grouping.allGroupsExpanded) {
            const bulkValues = getBulkValues(state.cells, state.args.orderBy);
            state.grouping.expandedGroups = Array.from(bulkValues);
          }

          state.status.list = "succeeded";
        })
        .addCase(listCells.rejected, (state, { error }) => {
          state.status.list = "failed";
          state.error.list = error.message as string;
        })
        .addCase(stageCells.pending, (state) => {
          state.status.stage = "loading";
        })
        .addCase(
          stageCells.fulfilled,
          (state, { payload: { justStaged, errors } }) => {
            if (errors.length === 0) {
              state.status.stage = "succeeded";
              state.justStaged = justStaged;
            } else {
              state.status.stage = "failed";
              state.error.stage = errors.join(" ");
            }
          }
        )
        .addCase(stageCells.rejected, (state, { error }) => {
          state.status.stage = "failed";
          state.error.stage = error.message as string;
        })
        .addCase(commitCells.pending, (state) => {
          state.status.commit = "loading";
        })
        .addCase(
          commitCells.fulfilled,
          (state, { payload: { justCommitted, error } }) => {
            if (error) {
              if (error === GATEWAY_TIMEOUT_ERROR) {
                state.status.commit = "pending";
                state.warning.commit = GATEWAY_TIMEOUT_RESPONSE;
              } else {
                state.status.commit = "failed";
                state.error.commit = error as string;
              }
            } else {
              state.status.commit = "succeeded";
              state.justCommitted = justCommitted;
            }
          }
        )
        .addCase(commitCells.rejected, (state, { error }) => {
          if (error.message === GATEWAY_TIMEOUT_ERROR) {
            state.status.commit = "pending";
            state.warning.commit = GATEWAY_TIMEOUT_RESPONSE;
          } else {
            state.status.commit = "failed";
            state.error.commit = error.message as string;
          }
        })
        .addCase(changePriority.pending, (state) => {
          state.status.priority = "loading";
        })
        .addCase(changePriority.fulfilled, (state, { payload: { errors } }) => {
          if (errors.length === 0) {
            state.status.priority = "succeeded";
          } else {
            state.status.priority = "failed";
            state.error.priority = errors.join(" ");
          }
        })
        .addCase(changePriority.rejected, (state, { error }) => {
          state.status.priority = "failed";
          state.error.priority = error.message as string;
        })
        .addCase(readyOff.pending, (state) => {
          state.status.readyOff = "loading";
        })
        .addCase(
          readyOff.fulfilled,
          (
            state,
            { payload: { justFlaggedReadyOff, justUnflaggedReadyOff, errors } }
          ) => {
            if (errors.length === 0) {
              state.status.readyOff = "succeeded";
              state.justFlaggedReadyOff = justFlaggedReadyOff;
              state.justUnflaggedReadyOff = justUnflaggedReadyOff;
            } else {
              state.status.readyOff = "failed";
              state.error.readyOff = errors.join(" ");
            }
          }
        )
        .addCase(readyOff.rejected, (state, { error }) => {
          state.status.readyOff = "failed";
          state.error.readyOff = error.message as string;
        })
        .addCase(discontinueCells.pending, (state) => {
          state.status.discontinue = "loading";
        })
        .addCase(
          discontinueCells.fulfilled,
          (
            state,
            { payload: { justDiscontinued, justUndiscontinued, errors } }
          ) => {
            if (errors.length === 0) {
              state.status.discontinue = "succeeded";
              state.justDiscontinued = justDiscontinued;
              state.justUndiscontinued = justUndiscontinued;
            } else {
              state.status.discontinue = "failed";
              state.error.discontinue = errors.join(" ");
            }
          }
        )
        .addCase(discontinueCells.rejected, (state, { error }) => {
          state.status.discontinue = "failed";
          state.error.discontinue = error.message as string;
        })
        .addCase(takeOff.pending, (state) => {
          state.status.discontinue = "loading";
        })
        .addCase(takeOff.fulfilled, (state, { payload: { error } }) => {
          if (error.length === 0) {
            state.status.takeOff = "succeeded";
          } else {
            state.status.takeOff = "failed";
            state.error.takeOff = error;
          }
        })
        .addCase(takeOff.rejected, (state, { error }) => {
          state.status.discontinue = "failed";
          state.error.discontinue = error.message as string;
        })
        .addCase(getChannelUsage.pending, (state) => {
          state.status.channelUsage = "loading";
        })
        .addCase(getChannelUsage.fulfilled, (state, { payload }) => {
          state.status.channelUsage = "succeeded";
          state.channelUsage = payload;
        })
        .addCase(getChannelUsage.rejected, (state, { error }) => {
          state.status.channelUsage = "failed";
          state.error.channelUsage = error.message as string;
        })
        .addCase(reserveChannels.pending, (state) => {
          state.status.channelReservation = "loading";
        })
        .addCase(reserveChannels.fulfilled, (state) => {
          state.status.channelReservation = "succeeded";
        })
        .addCase(reserveChannels.rejected, (state, { error }) => {
          state.status.channelReservation = "failed";
          state.error.channelReservation = error.message as string;
        })
        .addCase(reserveTestStands.pending, (state) => {
          state.status.testStandReservation = "loading";
        })
        .addCase(reserveTestStands.fulfilled, (state) => {
          state.status.testStandReservation = "succeeded";
        })
        .addCase(reserveTestStands.rejected, (state, { error }) => {
          state.status.testStandReservation = "failed";
          state.error.testStandReservation = error.message as string;
        })
        .addCase(cancelReservations.pending, (state) => {
          state.status.reservationCancellation = "loading";
        })
        .addCase(cancelReservations.fulfilled, (state) => {
          state.status.reservationCancellation = "succeeded";
        })
        .addCase(cancelReservations.rejected, (state, { error }) => {
          state.status.reservationCancellation = "failed";
          state.error.reservationCancellation = error.message as string;
        })
        .addCase(cancelTestStandReservations.pending, (state) => {
          state.status.testStandReservationCancellation = "loading";
        })
        .addCase(cancelTestStandReservations.fulfilled, (state) => {
          state.status.testStandReservationCancellation = "succeeded";
        })
        .addCase(cancelTestStandReservations.rejected, (state, { error }) => {
          state.status.testStandReservationCancellation = "failed";
          state.error.testStandReservationCancellation =
            error.message as string;
        })
        .addCase(modifyCells.pending, (state) => {
          state.status.modify = "loading";
        })
        .addCase(modifyCells.fulfilled, (state) => {
          state.status.modify = "succeeded";
        })
        .addCase(modifyCells.rejected, (state, { error }) => {
          state.status.modify = "failed";
          state.error.modify = error.message as string;
        });
    },
  });

  return {
    getCellTotals,
    listCells,
    stageCells,
    cancelReservations,
    commitCells,
    changePriority,
    readyOff,
    takeOff,
    toggleTestStandsForCells,
    discontinueCells,
    getChannelUsage,
    reserveChannels,
    reserveTestStands,
    cancelTestStandReservations,
    modifyCells,
    slice,
    ...slice.actions,
    initialState: initialState(prefix),
  };
};

export const cellsSlices = {
  EXPERIMENT: createCellsSlice("EXPERIMENT"),
  ALL: createCellsSlice("ALL"),
  SPECIFIED: createCellsSlice("SPECIFIED"),
  STAGED: createCellsSlice("STAGED"),
  COMMITTED: createCellsSlice("COMMITTED"),
  TESTING: createCellsSlice("TESTING"),
};
