import React from "react";
import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";
import styled from "@mui/styles/styled";
import colors, { addAlpha } from "../theme/colors";
import { WhiteBadge } from "./Badge";

import TimesIcon from "../icons/Times";

const StyledChip = styled(Chip)({
  backgroundColor: "transparent",
  "&.MuiChip-clickable:hover": {
    backgroundColor: addAlpha(colors.accent.primary, 0.1),
  },
  "&.MuiChip-clickable:focus": {
    backgroundColor: "transparent",
  },
  "&.active": {
    backgroundColor: addAlpha(colors.accent.primary, 0.1),
  },
  "&.MuiChip-clickable:focus.active": {
    backgroundColor: addAlpha(colors.accent.primary, 0.1),
  },
  "&.rightIcon": {
    flexDirection: "row-reverse",
    "& .MuiChip-icon": {
      marginLeft: -6,
      marginRight: 5,
    },
  },
});

type Props = {
  count?: number;
  rightIcon?: boolean;
} & ChipProps;

const CustomChip = ({
  count = 0,
  onDelete,
  label,
  rightIcon,
  ...props
}: Props) => (
  <StyledChip
    {...props}
    className={`${count > 0 ? "active" : ""} ${rightIcon ? "rightIcon" : ""}`}
    label={
      <>
        {label} {count > 0 ? <WhiteBadge badgeContent={`${count}`} /> : null}
      </>
    }
    onDelete={count > 0 ? onDelete : undefined}
    deleteIcon={
      <TimesIcon
        style={{ width: 16, height: 16, color: colors.text.secondary }}
      />
    }
  />
);

export default CustomChip;
