import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Undo = ({ style, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{
      transform: "scaleX(-1)",
      ...style,
    }}
  >
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.727 17.49a9.752 9.752 0 1 1 1.529-5.243V14.5"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m23.256 11.497-3 3-3-3"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Undo;
