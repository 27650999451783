import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { Box, Grid, Typography } from "@mui/material";
import { expIdToString } from "../../utils/labels";
import { readCSVFromClipboardData } from "../../utils/batchEntryUtils";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";

type ConditionsCreationTableProps = {
  exp_id: string;
  cellType: string;
  columns: GridColDef[];
  apiRef: React.MutableRefObject<GridApi>;
  conditionRows: GridValidRowModel[];
  onConditionRowsUpdate: (newRows: GridValidRowModel[]) => void;
};

const ConditionsCreationTable = ({
  exp_id,
  apiRef,
  conditionRows,
  onConditionRowsUpdate,
  columns,
  cellType,
}: ConditionsCreationTableProps) => {
  const [activeCell, setActiveCell] = useState<GridCellParams | null>(null);
  const [showPasteInstructions, setShowPasteInstructions] = useState(false);
  const [firstPastedFieldAndVal, setFirstPastedFieldAndVal] = useState<{
    [key: string]: string | number;
  } | null>(null);
  const [showAllowPasteModal, setShowAllowPasteModal] = useState(false);
  const processRowUpdate = (updatedRow: GridValidRowModel) => {
    const activeFirstPastedField = firstPastedFieldAndVal || {};
    const updatedRow_ = {
      ...updatedRow,
      ...activeFirstPastedField,
    };
    const newRows = conditionRows.map((conditionRow_) => {
      if (updatedRow.id === conditionRow_.id) {
        return updatedRow_;
      }
      return conditionRow_;
    });
    onConditionRowsUpdate(newRows);
    if (firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
    return updatedRow_;
  };
  const handleCellClick: GridEventListener<"cellClick"> = (clickedCell) => {
    if (
      !activeCell ||
      activeCell.id !== clickedCell.id ||
      activeCell.field !== clickedCell.field
    ) {
      setActiveCell(clickedCell);
    }
  };
  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      if (!!activeCell && activeCell.isEditable) {
        var splitTextFromClipboard: null | string[] = null;
        try {
          splitTextFromClipboard = readCSVFromClipboardData(event, /\n|\r/g);
        } catch (error: any) {
          if (error.name === "NotAllowedError") {
            setShowAllowPasteModal(true);
            return;
          }
        }

        if (splitTextFromClipboard && splitTextFromClipboard.length > 0) {
          const clickedRowIndex = conditionRows.findIndex(
            (row_) => row_.id === activeCell.id
          );
          let clipboardIndex = 0;
          const newRows = conditionRows.map((conditionRow_, rowIndex) => {
            if (
              rowIndex < clickedRowIndex ||
              clipboardIndex >= splitTextFromClipboard!.length
            )
              return conditionRow_;
            const valFromClipboard = splitTextFromClipboard![clipboardIndex];
            clipboardIndex += 1;
            return {
              ...conditionRow_,
              [activeCell.field]: valFromClipboard,
            };
          });
          onConditionRowsUpdate(newRows);
          setFirstPastedFieldAndVal({
            [activeCell.field]: splitTextFromClipboard[0],
          });
          apiRef.current.setEditCellValue({
            id: activeCell.id,
            field: activeCell.field,
            value: splitTextFromClipboard[0],
          });
        }
      } else {
        if (!activeCell) setShowPasteInstructions(true);
        if (!!firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
      }
    },
    [
      activeCell,
      apiRef,
      conditionRows,
      firstPastedFieldAndVal,
      onConditionRowsUpdate,
    ]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, [handlePaste]);

  return (
    <Grid item xs={12}>
      <Box my={6}>
        <Box>
          <Typography variant="h2">{`${expIdToString(
            exp_id
          )}, ${cellType}`}</Typography>
        </Box>
        <Box>
          <Typography sx={{ fontStyle: "italic", marginTop: 2 }}>
            Set names and other basic fields for conditions, which will be
            created with status 'WIP'. Further specification can take place
            after conditions are created.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <DataGrid
          apiRef={apiRef}
          rows={conditionRows}
          onCellClick={handleCellClick}
          columns={columns}
          processRowUpdate={processRowUpdate}
          autoHeight
        />
      </Box>
      <Modal
        open={showAllowPasteModal}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          setShowAllowPasteModal(false);
        }}
      >
        <Typography variant="h2" align="center" mb={4}>
          Please allow paste from clipboard.
        </Typography>
        <Typography align="center">
          The pasting functionality requires the browser to access the
          clipboard. Please refresh the page and click 'Allow' when the browser
          asks for clipboard permission.
        </Typography>
      </Modal>
      <Toast
        severity="warning"
        open={showPasteInstructions}
        onClose={() => {
          setShowPasteInstructions(false);
        }}
      >
        To paste, click the first cell in the column you'd like to paste, then
        try again.
      </Toast>
    </Grid>
  );
};

export default ConditionsCreationTable;
