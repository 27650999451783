import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Chip from "../../components/Chip";
import SortIcon from "../../icons/Sort";

type Props<T> = {
  value: FalconKey<T>;
  items: DataLabel<T>[];
  onChange: (orderBy: FalconKey<T>) => void;
};

const ButtonSort = <T extends Cell | Experiment | Channel>({
  value,
  items,
  onChange,
}: Props<T>) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  const selectedLabel = items.find(({ id }) => id === value);

  return (
    <>
      <Chip
        clickable
        onClick={handleButtonClick}
        icon={<SortIcon />}
        label={selectedLabel ? `Sort: ${selectedLabel.label}` : "Select Sort"}
      />
      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {items.map(({ id, label }) => (
          <MenuItem
            key={id as string}
            onClick={() => {
              onChange(id);
              handleButtonClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonSort;
