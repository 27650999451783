import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigationMenuHorizontalIcon from "../../icons/NavigationMenuHorizontal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  CharacterizationState,
  resetGetMaterials,
  resetSamples,
} from "./slice";
import { Tooltip } from "@mui/material";
import samplesUtils from "./samplesUtils";

const SamplesOverflowMenu = () => {
  const { samples, selected } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );

  const [buttonEl, setButtonEl] = useState<
    null | HTMLButtonElement | HTMLAnchorElement
  >(null);
  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement
    >
  ) => {
    event.stopPropagation();
    setButtonEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sampleOrSamples = `sample${selected.length > 1 ? "s" : ""}`;
  const selectedSamples = samples.filter(({ sample_id }) =>
    selected.includes(`${sample_id}`)
  );
  const allSameSampleTypesSelected =
    samplesUtils.allSameSampleTypes(selectedSamples);

  return (
    <>
      <IconButton
        size="small"
        disabled={selected.length === 0}
        onClick={handleButtonClick}
      >
        <NavigationMenuHorizontalIcon style={{ width: 20, height: 20 }} />
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleButtonClose(e);
            navigate(
              `/characterization/samples?sample_id=${selected.join(",")}`,
              { state: { from: { pathname: "/characterization" } } }
            );
          }}
        >
          {`View full data for selected ${sampleOrSamples}`}
        </MenuItem>
        <Tooltip
          arrow
          placement="left"
          title={
            "All selected samples must have same sample type in order to batch edit"
          }
          disableHoverListener={allSameSampleTypesSelected}
          disableFocusListener={allSameSampleTypesSelected}
        >
          <div>
            <MenuItem
              disabled={!allSameSampleTypesSelected}
              onClick={(e) => {
                handleButtonClose(e);
                dispatch(resetSamples());
                dispatch(resetGetMaterials());
                navigate(
                  `/characterization/edit-samples?sample_id=${selected.join(
                    ","
                  )}`,
                  { state: { from: { pathname: "/characterization" } } }
                );
              }}
            >
              {`Edit selected ${sampleOrSamples}`}
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </>
  );
};

export default SamplesOverflowMenu;
