import TableCell from "@mui/material/TableCell";
import type { TableCellProps } from "@mui/material/TableCell";
import colors, { addAlpha } from "../../theme/colors";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
  smallWidth?: boolean;
  largeWidth?: boolean;
} & TableCellProps;

const useCellStyles = makeStyles({
  root: {
    border: `1px solid ${colors.rules}`,
    textAlign: "left",
    height: 48,
    fontWeight: 400,
    padding: 0,
    width: 236,
    minWidth: 236,
    maxWidth: 236,
    "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
      width: 206,
      minWidth: 206,
      maxWidth: 206,
    },
    "& .thCellInner": {
      width: "calc(236px - 2rem)",
    },
    '&[class*="cellStatus-"]': {
      backgroundColor: addAlpha(colors.accent.cta, 0.1),
    },
    "&.cellStatus-T": {
      backgroundColor: addAlpha(colors.accent.violet, 0.1),
    },
    "&.cellStatus-S": {
      backgroundColor: addAlpha(colors.accent.green, 0.1),
    },
    "&.cellStatus-I": {
      backgroundColor: "transparent",
    },
    "&.componentCompleted": {
      backgroundColor: addAlpha(colors.accent.green, 0.1),
    },
    "&.textPrimary": {
      color: colors.text.primary,

      "& input.Mui-disabled": {
        color: colors.text.primary,
        "-webkit-text-fill-color": colors.text.primary,
      },
    },
    "&.readonlyField": {
      background: colors.striping,
      color: colors.text.primary,

      "& input.Mui-disabled": {
        color: colors.text.primary,
        "-webkit-text-fill-color": colors.text.primary,
      },
    },
  },
});

const useSmallCellStyles = makeStyles({
  root: {
    width: 172,
    minWidth: 172,
    maxWidth: 172,
    "& .thCellInner": {
      width: "calc(172px - 2rem)",
    },
    "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
      width: 152,
      minWidth: 152,
      maxWidth: 152,
    },
  },
});

export const useLargeCellStyles = makeStyles({
  root: {
    whiteSpace: "pre-wrap",
    width: 254,
    minWidth: 254,
    maxWidth: 254,
    "& .thCellInner": {
      width: "calc(254px - 2rem)",
      minHeight: 21,
      maxHeight: 42,
    },
    "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
      width: 234,
      minWidth: 234,
      maxWidth: 234,
    },
    "@media (min-width: 1360px)": {
      width: 360,
      minWidth: 360,
      maxWidth: 360,
      "& .thCellInner": {
        width: "calc(360px - 2rem)",
        minHeight: 21,
        maxHeight: 42,
      },
      "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
        width: 340,
        minWidth: 340,
        maxWidth: 340,
      },
    },
    "@media (min-width: 1440px)": {
      width: 460,
      minWidth: 460,
      maxWidth: 460,
      "& .thCellInner": {
        width: "calc(460px - 2rem)",
        minHeight: 21,
        maxHeight: 42,
      },
      "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
        width: 440,
        minWidth: 440,
        maxWidth: 440,
      },
    },
    "@media (min-width: 1560px)": {
      width: 560,
      minWidth: 560,
      maxWidth: 560,
      "& .thCellInner": {
        width: "calc(560px - 2rem)",
        minHeight: 21,
        maxHeight: 42,
      },
      "&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox": {
        width: 540,
        minWidth: 540,
        maxWidth: 540,
      },
    },
  },
});

const ConditionCell = ({
  smallWidth,
  largeWidth,
  className = "",
  ...props
}: Props) => {
  const tableClass = useCellStyles();
  const smClass = useSmallCellStyles();
  const lgClass = useLargeCellStyles();

  return smallWidth ? (
    <TableCell
      className={`${className} ${tableClass.root} ${smClass.root}`}
      {...props}
    />
  ) : largeWidth ? (
    <TableCell
      className={`${className} ${tableClass.root} ${lgClass.root}`}
      {...props}
    />
  ) : (
    <TableCell className={`${className} ${tableClass.root}`} {...props} />
  );
};

export default ConditionCell;
