import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Star = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M6.52447 1.12356C6.67415 0.662903 7.32585 0.662904 7.47553 1.12356L8.68386 4.84241C8.75079 5.04842 8.94277 5.1879 9.15938 5.1879H13.0696C13.554 5.1879 13.7554 5.80771 13.3635 6.09241L10.2001 8.39078C10.0248 8.5181 9.95149 8.74379 10.0184 8.9498L11.2268 12.6686C11.3764 13.1293 10.8492 13.5124 10.4573 13.2277L7.29389 10.9293C7.11865 10.802 6.88135 10.802 6.70611 10.9293L3.54267 13.2277C3.15081 13.5124 2.62357 13.1293 2.77325 12.6686L3.98157 8.9498C4.04851 8.74379 3.97518 8.5181 3.79994 8.39078L0.636495 6.09241C0.244639 5.8077 0.446028 5.1879 0.93039 5.1879H4.84062C5.05723 5.1879 5.24921 5.04842 5.31614 4.84241L6.52447 1.12356Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Star;
