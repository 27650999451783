import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Warning = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <path
      d="M8 12.5C7.86193 12.5 7.75 12.6119 7.75 12.75C7.75 12.8881 7.86193 13 8 13C8.13807 13 8.25 12.8881 8.25 12.75C8.25 12.6119 8.13807 12.5 8 12.5V12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 10.5V5.5" stroke="currentColor" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08067 1.17317C8.87826 0.761097 8.45911 0.5 8.00001 0.5C7.5409 0.5 7.12175 0.761097 6.91934 1.17317L0.604008 14.0385C0.449457 14.3529 0.468142 14.7248 0.653426 15.0221C0.83871 15.3195 1.16434 15.5001 1.51467 15.4998H14.4853C14.8357 15.5001 15.1613 15.3195 15.3466 15.0221C15.5319 14.7248 15.5506 14.3529 15.396 14.0385L9.08067 1.17317Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Warning;
