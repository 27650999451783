import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Search = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9685 17.3777C17.2429 15.986 18.7693 12.2034 17.3777 8.92895C15.986 5.65448 12.2034 4.12812 8.92896 5.51974C5.65449 6.91136 4.12813 10.694 5.51975 13.9684C6.91137 17.2429 10.694 18.7693 13.9685 17.3777Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0039 16.0035L21.0003 21.0006"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Search;
