import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import type { PaperProps } from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import SettingsIcon from "../../icons/Settings";
import SettingsActiveIcon from "../../icons/SettingsActive";
import colors from "../../theme/colors";
import ChannelAttributeFilterButton from "./ChannelAttributeFilterButton";
import { ChannelOptionFilters } from "./ChannelDropdown";

const AIRLINES_OPTIONS = ["0", "1", "2"];
const HIGH_RANGE_SELECTION = "0";
const LOW_RANGE_SELECTION = "1";
const RANGE_OPTIONS = ["Low", "Any", "High"];
const TEMPERATURE_OPTIONS = ["60", "45", "30", "5"];
const TESTER_BRAND_OPTIONS = ["Bio-Logic", "Arbin", "Maccor"];

export const dropdownFilterStyles = {
  additionalInput: {
    padding: "0 30px 0 12px",
  },
  additionalTextField: {
    maxHeight: "0.45rem",
  },
  dropdown: {
    maxWidth: "100%",
    background: "transparent",
    border: `1px solid ${colors.rules}`,
    borderRadius: 3,
  },
  customEndAdornment: {
    backgroundColor: "#E7E7E7",
    borderRadius: "2px",
    position: "absolute",
    right: "9px",
    top: "5px",
    padding: "1px 8px",
  } as React.CSSProperties,
};

type ChannelDropdownFiltersProps = {
  cells?: number[];
  value: ChannelOptionFilters | null;
  onChange: (value: ChannelOptionFilters | null) => void;
} & Omit<PaperProps, "onChange">;

type RangeSelectionState = {
  value?: "0" | "1";
};

const ChannelDropdownFilters = ({
  cells = [],
  children,
  value,
  onChange,
  style,
  ...props
}: ChannelDropdownFiltersProps) => {
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState(value);
  const [selectedCurrentOption, setSelectedCurrentOption] =
    useState<RangeSelectionState>({});
  const [currentVal, setCurrentVal] = useState("");
  const [selectedVoltageOption, setSelectedVoltageOption] =
    useState<RangeSelectionState>({});
  const [voltageVal, setVoltageVal] = useState("");

  useEffect(() => {
    if (filtering) {
      setFilters(value);
    }
  }, [filtering, value]);

  if (!filtering) {
    return (
      <Paper {...props} style={{ ...style, minWidth: 400 }}>
        {children}
        <Divider />
        <Box px={4} display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!filters?.cell_ids?.length}
                onChange={() => {
                  if (!!filters?.cell_ids?.length) {
                    onChange(null);
                  } else {
                    onChange({ cell_ids: cells });
                  }
                }}
                color="secondary"
              />
            }
            label={
              <Typography
                color="textSecondary"
                className="small"
                fontWeight={600}
              >
                Match cell condition
              </Typography>
            }
            style={cells.length === 0 ? { visibility: "hidden" } : {}}
          />
          <IconButton
            size="small"
            style={{
              color: colors.text.secondary,
              visibility: !!filters?.cell_ids?.length ? "hidden" : "visible",
            }}
            onClick={() => setFiltering(true)}
          >
            {value ? (
              <SettingsActiveIcon style={{ width: 18, height: 18 }} />
            ) : (
              <SettingsIcon style={{ width: 18, height: 18 }} />
            )}
          </IconButton>
        </Box>
      </Paper>
    );
  }

  const renderButtonGroup = (filterFieldKey: keyof ChannelOptionFilters) => {
    return (
      <Grid item xs={5}>
        <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
          <ChannelAttributeFilterButton
            value={filters?.[filterFieldKey] as "0" | "1"}
            onChange={(value) => {
              const { [filterFieldKey]: _filterFieldToModify, ...others } =
                filters || {};
              if (!value) {
                setFilters(others);
                return;
              }

              setFilters({
                ...others,
                [filterFieldKey]: value,
              });
            }}
          />
        </Box>
      </Grid>
    );
  };

  const renderDropdown = (
    filterFieldKey: keyof ChannelOptionFilters,
    dropdownOptions: string[]
  ) => {
    return (
      <Grid item xs={5}>
        <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
          <Select
            className="tiny"
            size="small"
            variant="filled"
            disableUnderline
            style={dropdownFilterStyles.dropdown}
            value={filters?.[filterFieldKey] || "Any"}
            onChange={(e) => {
              const { [filterFieldKey]: _filterFieldToModify, ...others } =
                filters || {};

              if (e.target.value === "Any") {
                setFilters(others);
                return;
              }

              setFilters({
                ...others,
                [filterFieldKey]: e.target.value as "0" | "1",
              });
            }}
          >
            <MenuItem
              className="small"
              value="Any"
              disabled={!filters?.[filterFieldKey]}
            >
              Any
            </MenuItem>
            {dropdownOptions.map((option) => (
              <MenuItem
                key={`${option}-item`}
                className="small"
                value={option}
                disabled={filters?.[filterFieldKey] === option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
    );
  };

  return (
    <Paper {...props} style={{ ...style, minWidth: 500 }}>
      <Box py={2} px={4}>
        <Typography className="small" fontWeight={700}>
          Channel attributes to match with cell
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <Grid
          container
          className="small"
          style={{ maxHeight: 250, overflowY: "auto" }}
        >
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Temperature
            </Box>
          </Grid>
          {renderDropdown("temperature", TEMPERATURE_OPTIONS)}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Sets of Airlines
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
              <Select
                className="tiny"
                size="small"
                variant="filled"
                disableUnderline
                style={dropdownFilterStyles.dropdown}
                value={
                  filters?.airlines__null ? "0" : filters?.airlines || "Any"
                }
                onChange={(e) => {
                  const { airlines, ...others } = filters || {};

                  if (e.target.value === "0") {
                    setFilters({
                      ...others,
                      airlines__null: "1",
                    });
                    return;
                  }

                  delete others.airlines__null;
                  if (e.target.value === "Any") {
                    setFilters(others);
                    return;
                  }
                  setFilters({
                    ...others,
                    airlines: e.target.value as "0" | "1",
                  });
                }}
              >
                <MenuItem
                  className="small"
                  value="Any"
                  disabled={!filters?.airlines && !filters?.airlines__null}
                >
                  Any
                </MenuItem>
                {AIRLINES_OPTIONS.map((setsOfAirlines) => (
                  <MenuItem
                    key={`airlines${setsOfAirlines}`}
                    className="small"
                    value={setsOfAirlines}
                    disabled={filters?.airlines === setsOfAirlines}
                  >
                    {setsOfAirlines}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              TC Channels
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
              <ChannelAttributeFilterButton
                value={filters?.tc_channels__null ? "0" : filters?.tc_channels}
                onChange={(value) => {
                  const { tc_channels, ...others } = filters || {};
                  if (value === "0") {
                    setFilters({
                      ...others,
                      tc_channels__null: "1",
                    });
                    return;
                  }

                  delete others.tc_channels__null;
                  if (!value) {
                    setFilters(others);
                    return;
                  }
                  setFilters({
                    ...others,
                    tc_channels: value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Cathode Switching
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
              <ChannelAttributeFilterButton
                value={
                  filters?.cathode_switching__null
                    ? "0"
                    : filters?.cathode_switching
                }
                onChange={(value) => {
                  const { cathode_switching, ...others } = filters || {};
                  if (value === "0") {
                    setFilters({
                      ...others,
                      cathode_switching__null: "1",
                    });
                    return;
                  }

                  delete others.cathode_switching__null;
                  if (!value) {
                    setFilters(others);
                    return;
                  }
                  setFilters({
                    ...others,
                    cathode_switching: value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              CO2 Scrubbing
            </Box>
          </Grid>
          {renderButtonGroup("co2_scrubbing")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Humidity Control
            </Box>
          </Grid>
          {renderButtonGroup("humidity_control")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Tester Brand
            </Box>
          </Grid>
          {renderDropdown("tester_brand", TESTER_BRAND_OPTIONS)}
          <Grid item xs={5}>
            <Box pl={4} pr={2} py={1}>
              Current Range
            </Box>
          </Grid>
          <Grid item xs={selectedCurrentOption.value ? 3 : 7}>
            <Box pl={1} pr={2} py={1} display="flex" justifyContent="flex-end">
              <ChannelAttributeFilterButton
                buttonOptions={RANGE_OPTIONS}
                value={selectedCurrentOption.value}
                onChange={(value) => {
                  const { ...currentFilters } = filters || {};
                  setCurrentVal("");
                  delete currentFilters.high_current_range__lte;
                  delete currentFilters.low_current_range__gte;
                  setFilters(currentFilters);

                  if (!value) {
                    setSelectedCurrentOption({});
                    return;
                  }
                  setSelectedCurrentOption({ value });
                }}
              />
            </Box>
          </Grid>
          {selectedCurrentOption.value && (
            <Grid item xs={4}>
              <Box
                pl={1}
                pr={2}
                py={1}
                display="flex"
                justifyContent="flex-end"
                sx={{ position: "relative" }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  color="secondary"
                  value={currentVal}
                  onChange={(event) => {
                    const { ...currentFilters } = filters || {};
                    const inputVal = event.target.value;
                    setCurrentVal(inputVal);
                    if (inputVal.length === 0) {
                      delete currentFilters.high_current_range__lte;
                      delete currentFilters.low_current_range__gte;
                      setFilters(currentFilters);
                      return;
                    }

                    if (selectedCurrentOption.value === HIGH_RANGE_SELECTION) {
                      setFilters({
                        ...currentFilters,
                        high_current_range__lte: inputVal,
                      });
                    }
                    if (selectedCurrentOption.value === LOW_RANGE_SELECTION) {
                      setFilters({
                        ...currentFilters,
                        low_current_range__gte: inputVal,
                      });
                    }
                  }}
                  inputProps={{ sx: dropdownFilterStyles.additionalInput }}
                  sx={dropdownFilterStyles.additionalTextField}
                />
                <div style={dropdownFilterStyles.customEndAdornment}>
                  x100 A
                </div>
              </Box>
            </Grid>
          )}
          <Grid item xs={5}>
            <Box pl={4} pr={2} py={1}>
              Voltage Range
            </Box>
          </Grid>
          <Grid item xs={selectedVoltageOption.value ? 4 : 7}>
            <Box pl={1} pr={2} py={1} display="flex" justifyContent="flex-end">
              <ChannelAttributeFilterButton
                buttonOptions={RANGE_OPTIONS}
                value={selectedVoltageOption.value}
                onChange={(value) => {
                  const { ...currentFilters } = filters || {};
                  setVoltageVal("");
                  delete currentFilters.max_voltage__lte;
                  delete currentFilters.min_voltage__gte;
                  setFilters(currentFilters);

                  if (!value) {
                    setSelectedVoltageOption({});
                    return;
                  }
                  setSelectedVoltageOption({ value });
                }}
              />
            </Box>
          </Grid>
          {selectedVoltageOption.value && (
            <Grid item xs={3}>
              <Box
                pl={1}
                pr={2}
                py={1}
                display="flex"
                justifyContent="flex-end"
                sx={{ position: "relative" }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  color="secondary"
                  value={voltageVal}
                  onChange={(event) => {
                    const { ...currentFilters } = filters || {};
                    const inputVal = event.target.value;
                    setVoltageVal(inputVal);
                    if (inputVal.length === 0) {
                      delete currentFilters.max_voltage__lte;
                      delete currentFilters.min_voltage__gte;
                      setFilters(currentFilters);
                      return;
                    }

                    if (selectedVoltageOption.value === HIGH_RANGE_SELECTION) {
                      setFilters({
                        ...currentFilters,
                        max_voltage__lte: inputVal,
                      });
                    }
                    if (selectedVoltageOption.value === LOW_RANGE_SELECTION) {
                      setFilters({
                        ...currentFilters,
                        min_voltage__gte: inputVal,
                      });
                    }
                  }}
                  inputProps={{ sx: dropdownFilterStyles.additionalInput }}
                  sx={dropdownFilterStyles.additionalTextField}
                />
                <div style={dropdownFilterStyles.customEndAdornment}>V</div>
              </Box>
            </Grid>
          )}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Hazelnut installed
            </Box>
          </Grid>
          {renderButtonGroup("hazelnut_installed")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Subscale H2 Interlock Installed
            </Box>
          </Grid>
          {renderButtonGroup("subscale_h2_interlock_installed")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Hydrogen Monitoring
            </Box>
          </Grid>
          {renderButtonGroup("hydrogen_monitoring")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Aux Voltage Monitoring
            </Box>
          </Grid>
          {renderButtonGroup("aux_voltage_monitoring")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              EIS Functionality
            </Box>
          </Grid>
          {renderButtonGroup("eis_functionality")}
        </Grid>
      </Box>
      <Divider />
      <Box py={2} px={4} display="flex" justifyContent="space-between">
        <Button
          disabled={!filters}
          color="tertiary"
          size="small"
          onClick={() => onChange(null)}
        >
          <b>Reset</b>
        </Button>
        <Box ml="auto" mr={2}>
          <Button
            color="secondary"
            size="small"
            onClick={() => setFiltering(false)}
          >
            Cancel
          </Button>
        </Box>
        <Button color="primary" size="small" onClick={() => onChange(filters)}>
          Apply
        </Button>
      </Box>
    </Paper>
  );
};

export default ChannelDropdownFilters;
