import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Clipboard = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style, fill: "transparent" }}>
    {children}
    <path
      d="M13.71 2.938h-3.125c0-1.379-1.121-2.5-2.5-2.5a2.502 2.502 0 0 0-2.5 2.5H2.46c-1.035 0-1.875.84-1.875 1.875v13.75c0 1.035.84 1.875 1.875 1.875h11.25c1.035 0 1.875-.84 1.875-1.875V4.813c0-1.035-.84-1.875-1.875-1.875Zm-5.625-.937c.52 0 .937.418.937.937 0 .52-.418.938-.937.938a.935.935 0 0 1-.938-.938c0-.52.418-.937.938-.937Zm5.625 16.328a.235.235 0 0 1-.234.234H2.694a.235.235 0 0 1-.234-.234V5.048c0-.13.105-.235.234-.235h1.641V6.22c0 .258.21.47.469.47h6.562a.47.47 0 0 0 .469-.47V4.813h1.64c.13 0 .235.106.235.235v13.28Z"
      fill="#000"
    />
  </SvgIcon>
);

export default Clipboard;
