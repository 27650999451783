import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import OverflowTooltip from "../../../../components/OverflowTooltip";
import EyeIcon from "../../../../icons/Eye";
import EyeSlashIcon from "../../../../icons/EyeSlash";

type Props = {
  title: string;
  lastOneActive: boolean;
  editMode?: boolean;
  active: boolean;
  onClick: () => void;
};

const BuildTypeSubAssemblyLineItem = ({
  title,
  lastOneActive,
  editMode,
  active,
  onClick,
}: Props) => {
  const button = (
    <IconButton
      size="small"
      color="primary"
      disabled={(active && lastOneActive) || editMode}
      onClick={onClick}
    >
      {active ? (
        <EyeIcon style={{ width: 16, height: 16 }} />
      ) : (
        <EyeSlashIcon style={{ width: 16, height: 16 }} />
      )}
    </IconButton>
  );

  return (
    <Box pl={16} pr={1} my={2} display="flex" alignItems="center">
      <div style={{ width: 142 }}>
        <OverflowTooltip title={title}>
          <Typography
            color={active ? "textPrimary" : "textSecondary"}
            variant="inherit"
            component="span"
          >
            {title}
          </Typography>
        </OverflowTooltip>
      </div>
      {editMode ? (
        <Tooltip arrow title="Cannot toggle conditions while in edit mode.">
          <span>{button}</span>
        </Tooltip>
      ) : active && lastOneActive ? (
        <Tooltip arrow title="At least one test condition must be visible.">
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
    </Box>
  );
};

export default BuildTypeSubAssemblyLineItem;
