import Box from "@mui/material/Box";
import Button from "../components/Button";

import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import colors from "../theme/colors";

type Props = {
  error?: string;
  onReset: () => void;
};

const Error = ({ error, onReset }: Props) => {
  const doReset = (e: React.MouseEvent) => {
    e.preventDefault();
    onReset();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        background: colors.striping,
        color: colors.text.primary,
        textAlign: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <ExclamationCircleOutlinedIcon
        style={{ width: 40, height: 40, color: colors.accent.red }}
      />
      <h2 style={{ fontWeight: 400 }}>An error has occured</h2>
      <p>
        <code>{error}</code>
      </p>
      <p>
        The Oak team has been notified.
        <br />
        You can give us more information using the Feedback button, or simply{" "}
        <Button
          color="tertiary"
          size="small"
          style={{ padding: "0.5rem" }}
          onClick={doReset}
        >
          <b>click here</b>
        </Button>{" "}
        to exit out of this screen.
      </p>
    </Box>
  );
};

export default Error;
