import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Filter = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9987 3.64139C21.0096 3.47543 20.9513 3.31237 20.8377 3.19088C20.7241 3.0694 20.5653 3.00031 20.399 3H3.60104C3.43465 3.00007 3.27572 3.06909 3.16208 3.19063C3.04844 3.31217 2.99025 3.47537 3.00134 3.64139C3.29112 7.67634 6.23108 11.0252 10.1945 11.8351V19.7523C10.1946 19.9855 10.3296 20.1977 10.5408 20.2966C10.752 20.3955 11.0014 20.3634 11.1807 20.2141L13.5859 18.2097C13.7231 18.0957 13.8025 17.9264 13.8023 17.7479V11.8351C17.7672 11.0268 20.7088 7.67741 20.9987 3.64139Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Filter;
