import TableCell from "@mui/material/TableCell";
import Check from "../../icons/Check";
import colors from "../../theme/colors";
import Times from "../../icons/Times";
import { Box } from "@mui/material";
import { isBoolean } from "lodash";

type Props = {
  dataKey: FalconKey<TestStand> | "condition__cell_assembly" | FalconKey<Cell>;
  testStand: TestStand | null;
  cellData: Cell;
};

const TEST_STAND_FIELD_TO_TEST_CONDITION_FIELD = {
  temperature_range: "initial_heater_setpoint",
  h2_sensor_type: "hydrogen_monitoring",
};

const TestStandReservationTableCell = ({
  dataKey,
  testStand,
  cellData,
}: Props) => {
  const renderTimesIcon = () => (
    <Box
      className="iconCell iconCellTimes"
      style={{ flexGrow: 1, color: colors.accent.red }}
    >
      <Times
        style={{
          width: 20,
          marginTop: 4,
        }}
      />
    </Box>
  );
  const renderCheckIcon = () => (
    <Box
      className="iconCell iconCellCheck"
      style={{ flexGrow: 1, color: colors.accent.darkGreen }}
    >
      <Check
        style={{
          width: 20,
          marginTop: 4,
        }}
      />
    </Box>
  );
  const renderText = (text = "") => (
    <Box
      style={{
        flexGrow: text && text.length > 1 ? 0.5 : 1,
        backgroundColor: colors.striping,
        marginTop: 6,
        marginLeft: 16,
      }}
    >
      {text}
    </Box>
  );
  switch (dataKey) {
    case "condition__cell_assembly":
      return (
        <TableCell style={{ padding: 0 }}>
          <Box style={{ display: "flex", paddingLeft: 8 }}>
            {cellData.cell_type.cell_assembly || "-"}
          </Box>
          {renderText()}
        </TableCell>
      );

    case "on_test__number_of_sampling_ports":
    case "on_test__number_of_thermocouples_needed":
    case "on_test__reference_electrode_type_continuous":
    case "on_test__reference_electrode_type_intermittent":
      const dataKeyNoPrefix = dataKey.replace("on_test__", "") as
        | "number_of_sampling_ports"
        | "number_of_thermocouples_needed"
        | "reference_electrode_type_continuous"
        | "reference_electrode_type_intermittent";
      return (
        <TableCell style={{ padding: 0 }}>
          <Box style={{ display: "flex", paddingLeft: 4 }}>
            {cellData.on_test[dataKeyNoPrefix] || "-"}
          </Box>
          {renderText()}
        </TableCell>
      );

    case "anode_current_sensors":
    case "dual_oee":
    case "one_half_cell":
    case "two_half_cells":
    case "proto_1":
    case "proto_2":
    case "evt":
    case "megasus":
    case "flex_cell":
    case "vehicle_303":
      return (
        <TableCell style={{ padding: 0 }}>
          <Box style={{ display: "flex" }}>
            {renderText()}
            {!testStand
              ? renderText()
              : !isBoolean(testStand[dataKey])
              ? renderText("-")
              : testStand[dataKey]
              ? renderCheckIcon()
              : renderTimesIcon()}
          </Box>
        </TableCell>
      );

    case "temperature_range":
    case "gde_air_range":
    case "sweep_air_range":
    case "max_current_limit":
    case "daq_channels":
    case "h2_sensor_type":
      const testConditionVal = Object.keys(
        TEST_STAND_FIELD_TO_TEST_CONDITION_FIELD
      ).includes(dataKey)
        ? cellData.on_test[
            TEST_STAND_FIELD_TO_TEST_CONDITION_FIELD[
              dataKey as "temperature_range" | "h2_sensor_type"
            ] as "initial_heater_setpoint" | "hydrogen_monitoring"
          ]
        : null;
      return (
        <TableCell style={{ padding: 0 }}>
          <Box style={{ display: "flex", paddingLeft: 8 }}>
            {isBoolean(testConditionVal)
              ? testConditionVal
                ? renderCheckIcon()
                : renderTimesIcon()
              : testConditionVal
              ? renderText(`${testConditionVal}`)
              : renderText()}
            {!testStand
              ? renderText()
              : renderText(
                  (testStand[dataKey] && `${testStand[dataKey]}`) || "-"
                )}
          </Box>
        </TableCell>
      );

    case "active_reservations":
      return (
        <TableCell style={{ padding: 0 }}>
          {renderText()}
          <Box style={{ display: "flex" }}>
            {renderText()}
            {!testStand
              ? renderText()
              : renderText(`${testStand.active_reservations.length}`)}
          </Box>
        </TableCell>
      );

    default:
      return <TableCell>{dataKey} not implemented</TableCell>;
  }
};

export default TestStandReservationTableCell;
