import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import CellHeader from "./CellHeader";
import ReferenceCalibrationSingleCell from "./ReferenceCalibrationSingleCell";
import { hasPassedStatus } from "../../../utils/statuses";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  executor?: User | null;
  onSave: (callback?: () => void) => void;
  minimumStatus: CellStatus;
  maximumStatus: CellStatus;
  eventMap: EventMap;
  onViewEvents: (cell_id: number) => void;
};

const ReferenceCalibrationSingleSubSection = ({
  formFields,
  executor: preferredExecutor,
  onSave,
  minimumStatus,
  maximumStatus,
  eventMap,
  onViewEvents,
}: Props) => {
  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const { control } = useFormContext();

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );
  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);

  return (
    <>
      <TableHead>
        <BaseTableRow>
          <TableCell>EXECUTOR</TableCell>
          <TableCell />
          {formFields.map((field, fieldIndex) => (
            <CellHeader
              key={fieldIndex}
              cell_id={field.cell_id}
              hasEvents={field.cell_id in eventMap}
              onViewEvents={onViewEvents}
            />
          ))}
        </BaseTableRow>
      </TableHead>
      <TableBody>
        <BaseTableRow>
          <UserCell
            clearable={false}
            style={{ width: 254, minWidth: 254 }}
            error={!executor}
            value={executor}
            onChange={setExecutor}
            disabled={formFields.some(
              (field) =>
                !hasPassedStatus(minimumStatus, field.status) ||
                hasPassedStatus(maximumStatus, field.status, false)
            )}
          />
          <ConditionCell smallWidth>
            <Box pl={4}>
              <Typography color="textSecondary" className="small">
                Reference Calibration
              </Typography>
            </Box>
          </ConditionCell>
          {formFields.map((field, fieldIndex) => (
            <Controller
              key={field.id}
              name={`metadata.${fieldIndex}.ref_cal_initial`}
              control={control}
              defaultValue={field.ref_cal_initial}
              rules={{
                required: field.ref_cal_required,
              }}
              render={({ field: { value }, fieldState: { invalid } }) => (
                <ReferenceCalibrationSingleCell
                  key={fieldIndex}
                  disabled={
                    !hasPassedStatus(minimumStatus, field.status) ||
                    hasPassedStatus(maximumStatus, field.status, false)
                  }
                  validating={invalid}
                  cell_id={field.cell_id}
                  value={value}
                  executor={executor}
                  onSave={onSave}
                />
              )}
            />
          ))}
        </BaseTableRow>
      </TableBody>
    </>
  );
};

export default ReferenceCalibrationSingleSubSection;
