import Input from "@mui/material/Input";
import styled from "@mui/styles/styled";
import colors from "../../theme/colors";

const InputCellInput = styled(Input)({
  padding: "0.29rem 0.25rem",
  borderRadius: 0,
  "&.pendingChange": {
    textDecoration: "line-through",
    "& .pendingIconContainer": {
      width: 24,
      height: 20,
      color: colors.text.primary,
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
  },
  "&:not(.Mui-focused):not(.Mui-error)": {
    border: "1px solid transparent",
  },
});

export default InputCellInput;
