import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Collapse from "@mui/material/Collapse";

import { RedBadge } from "../../../../components/Badge";
import type { ButtonProps } from "../../../../components/Button";
import OverflowTooltip from "../../../../components/OverflowTooltip";
import ExpandIcon from "../../../../icons/Expand";
import FlagIcon from "../../../../icons/Flag";
import SidebarButtonBase from "../../../../components/SidebarButton";
import colors from "../../../../theme/colors";
import Warning from "../../../../icons/Warning";

type Props = {
  title: string;
  step: number;
  activeStep?: number;
  disabled?: boolean;
  onClick?: () => void;
  children?: Omit<Props, "children">[];
  flagKeys?: string[];
  sopDeviationKeys?: string[];
};

const SidebarButton = ({
  title,
  step,
  activeStep,
  disabled,
  onClick,
  children,
  flagKeys = [],
  sopDeviationKeys = [],
}: Props) => {
  const hasActiveChild =
    children?.some((c) => c.step === c.activeStep) || false;
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (hasActiveChild) {
      setExpanded(true);
    }
  }, [hasActiveChild]);

  const { formState, control } = useFormContext() || {};
  const { errors } = formState || { errors: {} };

  const countForStep = (step: number) => {
    if (!errors["conditions"]) {
      return 0;
    }

    let total = 0;
    errors["conditions"].forEach(({ items = [], ...keys }: any) => {
      if (step === -1) {
        total += Object.keys(keys).length;
      } else if (items[step]) {
        total += Object.keys(items[step]).length;
      }
    });

    return total;
  };

  const getClassName = (active: boolean, hasChildren: boolean) => {
    let className = "";
    if (active) {
      className += " active";
    }
    if (!hasChildren) {
      className += " noChildren";
    }
    return className;
  };

  const count = countForStep(step);

  const ButtonEl = control ? ButtonWithWatch : ButtonWithoutWatch;

  return children ? (
    <Collapse
      in={expanded || hasActiveChild}
      collapsedSize={47}
      className="small"
    >
      <>
        <ButtonEl
          className={getClassName(false, children.length > 0)}
          size="small"
          color="text"
          disabled={disabled}
          onClick={() => setExpanded(!expanded || hasActiveChild)}
          startIcon={
            <ExpandIcon
              style={
                expanded || hasActiveChild ? { transform: "rotate(90deg)" } : {}
              }
            />
          }
          flagKeys={flagKeys}
          sopDeviationKeys={sopDeviationKeys}
        >
          <OverflowTooltip title={title}>
            <span>{title}</span>
          </OverflowTooltip>
        </ButtonEl>

        {children.map(
          (
            {
              title,
              step,
              activeStep,
              disabled,
              onClick,
              flagKeys = [],
              sopDeviationKeys = [],
            },
            i
          ) => (
            <ButtonEl
              className={getClassName(step === activeStep, false)}
              key={i}
              href={`#tc-section-${step}`}
              size="small"
              color="text"
              disabled={disabled}
              endIcon={
                countForStep(step) > 0 ? (
                  <RedBadge badgeContent={`${countForStep(step)}`} />
                ) : null
              }
              onClick={onClick}
              flagKeys={flagKeys}
              sopDeviationKeys={sopDeviationKeys}
              style={{ paddingLeft: "2rem" }}
            >
              <OverflowTooltip title={title}>
                <span>{title}</span>
              </OverflowTooltip>
            </ButtonEl>
          )
        )}
      </>
    </Collapse>
  ) : (
    <ButtonEl
      className={getClassName(step === activeStep, !!children)}
      href={`#tc-section-${step}`}
      size="small"
      color="text"
      disabled={disabled}
      onClick={onClick}
      endIcon={count > 0 ? <RedBadge badgeContent={`${count}`} /> : null}
      flagKeys={flagKeys}
      sopDeviationKeys={sopDeviationKeys}
      style={{ paddingLeft: "0.75rem" }}
    >
      <OverflowTooltip title={title}>
        <span>{title}</span>
      </OverflowTooltip>
    </ButtonEl>
  );
};

type ButtonWithoutWatchProps = {
  flagKeys: string[];
  sopDeviationKeys: string[];
} & ButtonProps;

const ButtonWithoutWatch = ({
  flagKeys,
  sopDeviationKeys,
  startIcon,
  style,
  ...props
}: ButtonWithoutWatchProps) => {
  const paddingLeft = !startIcon
    ? `calc(${style?.paddingLeft || "0rem"} + 1.5rem)`
    : style?.paddingLeft;

  return (
    <SidebarButtonBase
      {...props}
      style={{ ...style, paddingLeft, height: 44 }}
      startIcon={startIcon}
    />
  );
};

type ButtonWithWatchProps = {
  flagKeys: string[];
  sopDeviationKeys: string[];
} & ButtonProps;

const ButtonWithWatch = ({
  flagKeys,
  sopDeviationKeys,
  startIcon,
  style,
  ...props
}: ButtonWithWatchProps) => {
  const { control } = useFormContext();

  const flagState = useWatch({
    name: flagKeys,
    control,
    disabled: flagKeys.length === 0,
  });
  const flag = Object.values(flagState).some((value) => value);

  const sopDeviationState = useWatch({
    name: sopDeviationKeys,
    control,
    disabled: sopDeviationKeys.length === 0,
  });
  const sopDeviation = Object.values(sopDeviationState).some(
    (value) => !!value
  );

  const paddingLeft =
    !flag && !startIcon
      ? `calc(${style?.paddingLeft || "0rem"} + 1.5rem)`
      : style?.paddingLeft;

  return (
    <SidebarButtonBase
      {...props}
      style={{ ...style, paddingLeft, height: 44 }}
      endIcon={
        props.endIcon ||
        (sopDeviation ? (
          <Warning style={{ color: colors.accent.burntYellow }} />
        ) : null)
      }
      startIcon={
        flag ? (
          <FlagIcon style={{ color: colors.accent.burntYellow }} />
        ) : (
          startIcon
        )
      }
    />
  );
};

export default SidebarButton;
