import React, { useEffect, useState } from "react";
import MuiIconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import NavigationMenuHorizontalIcon from "../../icons/NavigationMenuHorizontal";
import { useNavigate } from "react-router";
import styled from "@mui/styles/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  duplicateCondition,
  resetDuplicateCondition,
} from "../conditions/slice";
import type { RootState } from "../../store";
import type { ConditionState } from "../conditions/slice";
import { editConditionPath } from "../../utils/urlPathHelpers";

const IconButton = styled(MuiIconButton)({
  position: "absolute",
  background: "white",
  top: 4,
  right: 4,
});

type Props = {
  cell_condition_id: number;
  disabled: boolean;
  exp_id: number;
};

const ConditionMenu = ({ cell_condition_id, disabled, exp_id }: Props) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLButtonElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setButtonEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(editConditionPath(exp_id, cell_condition_id));
  };

  const handleDuplicate = async (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(duplicateCondition(cell_condition_id));
  };

  // Existing conditions in redux
  const {
    conditions,
    status: { copy: copyStatus },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  useEffect(() => {
    if (copyStatus === "succeeded") {
      dispatch(resetDuplicateCondition());
      const cell_condition_id =
        conditions[conditions.length - 1].cell_condition_id!;
      navigate(editConditionPath(exp_id, cell_condition_id));
    }
  }, [copyStatus, conditions, dispatch, navigate, exp_id]);

  return (
    <div className={`cellTable-conditionMenu ${!!buttonEl ? "opened" : ""}`}>
      <Tooltip
        arrow
        placement="left"
        title="This test condition is backfilled from TrackerWare and cannot be edited or duplicated."
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
      >
        <span>
          <IconButton
            size="small"
            style={{ pointerEvents: "auto" }}
            disabled={disabled}
            onClick={handleButtonClick}
          >
            <NavigationMenuHorizontalIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </span>
      </Tooltip>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleEdit}>Edit test condition</MenuItem>
        <MenuItem onClick={handleDuplicate}>Duplicate test condition</MenuItem>
      </Menu>
    </div>
  );
};

export default ConditionMenu;
