import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import OverflowTooltip from "../../../../components/OverflowTooltip";
import ExpandIcon from "../../../../icons/Expand";
import BuildTypeSubAssemblyLineItem from "./BuildTypeSubAssemblyLineItem";
import NumberBadge from "../../../../components/NumberBadge";

type Props = {
  title: string;
  version: string;
  items?: ConditionSidebarAssemblyLayoutItem[];
  onToggleCondition?: (id: number) => void;
  expanded?: boolean;
  editMode?: boolean;
};

const BuildTypeSubAssembly = ({
  title,
  version,
  items,
  onToggleCondition,
  expanded: _expanded,
  editMode,
}: Props) => {
  const [expanded, setExpanded] = useState(_expanded);
  const active = items?.some((item) => item.active);

  useEffect(() => {
    setExpanded(_expanded);
  }, [_expanded]);

  const lastOneActive = items?.filter((item) => item.active).length === 1;

  return (
    <Collapse in={expanded} collapsedSize={56} className="small">
      <>
        <Box px={1} mt={3} display="flex" alignItems="center">
          <IconButton
            size="small"
            style={{ padding: "0.125rem" }}
            color="primary"
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandIcon
              style={expanded ? { transform: "rotate(90deg)" } : {}}
            />
          </IconButton>
          <Box ml={1} mr="auto">
            {active ? (
              <Box display="flex" alignItems="center">
                <div style={{ maxWidth: 150 }}>
                  <OverflowTooltip title={title}>
                    <b>{title}</b>
                  </OverflowTooltip>
                </div>
                <NumberBadge label="Version number" value={version} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <div style={{ maxWidth: 150 }}>
                  <OverflowTooltip title={title}>
                    <Typography
                      color="textSecondary"
                      variant="inherit"
                      component="span"
                      onClick={() => {
                        if (!active && items && onToggleCondition) {
                          onToggleCondition(
                            items[0] && items[0].cell_condition_id
                          );
                          setExpanded(true);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {title}
                    </Typography>
                  </OverflowTooltip>
                </div>
                <NumberBadge label="Version number" value={version} />
              </Box>
            )}
          </Box>
        </Box>

        {items?.map((item, index) => (
          <BuildTypeSubAssemblyLineItem
            key={index}
            title={item.name}
            lastOneActive={lastOneActive}
            editMode={editMode}
            active={item.active}
            onClick={() =>
              onToggleCondition && onToggleCondition(item.cell_condition_id)
            }
          />
        ))}
      </>
    </Collapse>
  );
};

export default BuildTypeSubAssembly;
