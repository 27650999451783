import { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import MinusCircleIcon from "../../icons/MinusCircle";
import SmallChip from "../SmallChip";
import client from "../../api";
import { useDebouncedCallback } from "use-debounce";

type MetaFilterProps = {
  multiple?: boolean;
  endpoint: string;
  removable?: boolean;
  style?: React.CSSProperties;
  onRemove?: () => void;
  value: any | any[];
  prefix?: string;
  valueKey?: string;
  descriptionKey?: string;
  onChange: (values: any | any[]) => void;
  disabled?: boolean;
  error?: boolean;
  queryParameters?: Object;
  displayBackupVal?: (option: any) => string;
};

const MetaFilter = ({
  multiple = true,
  endpoint,
  removable,
  onRemove,
  value,
  prefix = "",
  valueKey,
  descriptionKey,
  onChange,
  disabled,
  error,
  style,
  queryParameters,
  displayBackupVal,
}: MetaFilterProps) => {
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<string[]>([]);
  const [search, setSearch] = useState<string | null>(null);

  const _handleLookup = useCallback(async () => {
    if (search === null) {
      return;
    }

    setLoading(true);

    try {
      const response = await client.get(
        `${endpoint}?${valueKey ? `${valueKey}__contains` : "search"}=${search
          .toUpperCase()
          .replace(prefix, "")}` +
          /* append optional query parameters */
          (queryParameters
            ? "&" +
              Object.entries(queryParameters)
                .map(([k, v]) => k + "=" + v)
                .join("&")
            : "")
      );
      setOptions(response.data);
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
  }, [search, valueKey, prefix, endpoint, queryParameters]);

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleLookup();
  }, [search, handleLookup]);

  return (
    <Box display="flex" style={{ width: 450, ...style }}>
      <FormControl fullWidth>
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          options={options}
          getOptionLabel={(option) =>
            `${
              valueKey && option && option[valueKey]
                ? option[valueKey]
                : displayBackupVal
                ? displayBackupVal(option)
                : option
            }`
          }
          isOptionEqualToValue={(option, value) => {
            // Position ID needs special handling because it's displayed as "Cell Position ID" but saved as "cell_id"
            const isCellPositionLookup =
              valueKey === "cell_position_id" && !!descriptionKey;

            const isAddCellToModuleModalDropdown =
              valueKey === "name" && descriptionKey === "serial_number";
            const isModuleSignalMapCopyDropdown =
              valueKey === "module_id" && descriptionKey === "name";
            return isCellPositionLookup
              ? option[descriptionKey] === value[descriptionKey]
              : isAddCellToModuleModalDropdown || isModuleSignalMapCopyDropdown
              ? option["module_id"] === value["module_id"]
              : prefix
              ? String(option).replace(prefix, "") ===
                String(value).replace(prefix, "")
              : option === value;
          }}
          filterOptions={(options) => options}
          value={value}
          onOpen={() => setSearch("")}
          onClose={() => setSearch(null)}
          disableCloseOnSelect={multiple}
          onChange={(e, data) => onChange(data)}
          loading={loading}
          noOptionsText={
            search !== null && search.length > 0
              ? "No results found."
              : "Start typing..."
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              inputProps={{
                ...params.inputProps,
                className: `${params.inputProps.className} qa-${prefix}-input`,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                value: search,
                onChange: (e) => setSearch(e.target.value.toUpperCase()),
                error,
              }}
            />
          )}
          renderOption={(props, value) => {
            const label = prefix
              ? prefix +
                `${valueKey ? value[valueKey] : value}`.padStart(
                  prefix === "CEL" ? 6 : 0,
                  "0"
                )
              : valueKey && value[valueKey]
              ? value[valueKey]
              : displayBackupVal
              ? displayBackupVal(value)
              : value;

            return (
              <li {...props} key={valueKey ? value[valueKey] : value}>
                <Checkbox
                  color="secondary"
                  className="qa-filter-option-checkbox"
                  checked={props["aria-selected"] === true}
                />
                <Box py={2} width="100%">
                  <Typography
                    color="textPrimary"
                    className={descriptionKey ? "small" : undefined}
                  >
                    {label}
                  </Typography>
                  {descriptionKey ? (
                    <Typography color="textSecondary" className="tiny">
                      {value[descriptionKey]}
                    </Typography>
                  ) : null}
                </Box>
              </li>
            );
          }}
          renderTags={(values, getTagProps) =>
            values.map((value, index) => {
              const label = prefix
                ? prefix +
                  `${valueKey ? value[valueKey] : value}`.padStart(
                    prefix === "CEL" ? 6 : 0,
                    "0"
                  )
                : valueKey
                ? value[valueKey]
                : value;

              return (
                <Box key={valueKey ? value[valueKey] : value} mr={2} mb={1}>
                  <SmallChip
                    label={
                      descriptionKey ? (
                        <Tooltip arrow title={value[descriptionKey]}>
                          <span>{label}</span>
                        </Tooltip>
                      ) : (
                        label
                      )
                    }
                    {...getTagProps({ index })}
                  />
                </Box>
              );
            })
          }
        />
      </FormControl>
      {removable ? (
        <IconButton size="small" onClick={onRemove}>
          <MinusCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default MetaFilter;
