import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";
import { GridValidRowModel } from "@mui/x-data-grid";
import { DateTime } from "luxon";

export const BATCH_CONDITIONS_SPECIFICATION = "BATCH_CONDITIONS_SPECIFICATION";

type BatchConditionEditFormComponent = {
  component_id: number;
  required: string;
  build_stage?: string;
  component_type?: string;
  name: string;
  component_fields: ConditionUIField[];
  component_order: number;
  deleted: boolean;
  test_condition_section?: boolean;
  prefix?: string;
  title?: string;
};

type BatchEditableCondition = Condition & {
  test_condition: {
    test_condition_id: number;
  } & {
    [key: string]: string | number | boolean | null;
  };
  condition_components: {
    [key: string]: string | number | boolean | null;
  }[];
  // committed timestamp for a cell in the condition, if any has one.
  committed: string | null;
};

export interface BatchConditionEditPayload {
  [cellConditionId: string]: {
    condition_component_id?: string;
    test_condition_id?: string;
    id?: number;
    [key: string]: any;
  }[];
}

export interface BatchConditionsSpecificationsState {
  conditionsToEdit: BatchEditableCondition[] | null;
  createdCellConditionIds: number[];
  editFormTemplate: BatchConditionEditFormComponent[] | null;
  overviewFormOptions: {
    cell_types_to_assemblies: { [key: string]: string[] } | null;
  };
  status: {
    create: "idle" | "loading" | "succeeded" | "failed" | "async";
    get: "idle" | "loading" | "succeeded" | "failed";
    getOverviewForm: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
    specify: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    create: null | string;
    get: null | string;
    getOverviewForm: null | string;
    save: null | string;
    specify: null | string;
  };
}

const initialState: BatchConditionsSpecificationsState = {
  conditionsToEdit: null,
  createdCellConditionIds: [],
  editFormTemplate: null,
  overviewFormOptions: {
    cell_types_to_assemblies: null,
  },
  status: {
    create: "idle",
    get: "idle",
    getOverviewForm: "idle",
    save: "idle",
    specify: "idle",
  },
  error: {
    create: null,
    get: null,
    getOverviewForm: null,
    save: null,
    specify: null,
  },
};

const getSpecificationDataForConditionIds = async (
  cellConditionIds: string[]
) => {
  const response = await client.get(
    `meta/cell-conditions/batch?${cellConditionIds
      .map((conditionId) => `cell_condition_id=${conditionId}`)
      .join("&")}`
  );
  return response;
};

export const getOverviewFormData = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/getOverviewFormData`,
  async () => {
    const response = await client.get(
      "meta/cell-conditions/overview-form-data"
    );
    return response;
  }
);

export const getSpecificationData = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/getSpecData`,
  getSpecificationDataForConditionIds
);

export const createCellConditions = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/create`,
  async (
    cellConditions: Pick<
      Condition,
      | "cell_type"
      | "cell_assembly"
      | "name"
      | "description"
      | "replicates"
      | "pool"
      | "plan_test_start_date"
      | "build_config"
      | "build_phase"
      | "exp_id"
    >[]
  ) => {
    const response = await client.post(`meta/cell-conditions/batch`, {
      cell_conditions: cellConditions,
    });
    return response.data;
  }
);

export const saveConditions = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/saveConditions`,
  async ({
    cellConditions,
    allCellConditionIds,
  }: {
    cellConditions: GridValidRowModel[];
    allCellConditionIds: string[];
  }) => {
    const dataToUpdate = cellConditions.map(
      ({
        name,
        description,
        plan_test_start_date,
        pool,
        id,
        build_config,
        build_phase,
      }) => {
        const testStartDate: string | null =
          plan_test_start_date instanceof Date
            ? DateTime.fromJSDate(plan_test_start_date)
                .toUTC()
                .toISO({ suppressMilliseconds: true })
            : plan_test_start_date;
        return {
          name,
          description,
          plan_test_start_date: testStartDate,
          pool,
          cell_condition_id: id,
          build_config,
          build_phase,
        };
      }
    );
    const updates = dataToUpdate.flatMap(
      ({
        name,
        description,
        plan_test_start_date,
        pool,
        cell_condition_id,
        build_config,
        build_phase,
      }) => {
        return [
          {
            method: "PUT",
            path: `/api/v1/meta/cell-conditions/${cell_condition_id}`,
            data: {
              name,
              description,
              plan_test_start_date,
              pool,
              build_config,
              build_phase,
            },
          },
        ];
      }
    );

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);
    const response = await getSpecificationDataForConditionIds(
      allCellConditionIds
    );
    return Promise.resolve({ response, errors });
  }
);

export const saveSpecificationComponentData = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/saveSpecificationComponentData`,
  async ({
    batchEditConditionPayload,
    allCellConditionIds,
  }: {
    batchEditConditionPayload: BatchConditionEditPayload;
    allCellConditionIds: string[];
  }) => {
    await client.put(`meta/cell-conditions/batch`, batchEditConditionPayload);
    const response = await getSpecificationDataForConditionIds(
      allCellConditionIds
    );
    return Promise.resolve(response);
  }
);

export const specifyConditions = createAsyncThunk(
  `${BATCH_CONDITIONS_SPECIFICATION}/specifyConditions`,
  async ({
    cellConditionIdsToSpecify,
    allCellConditionIds,
  }: {
    cellConditionIdsToSpecify: (string | number)[];
    allCellConditionIds: string[];
  }) => {
    const updates = cellConditionIdsToSpecify.map((conditionId) => ({
      method: "POST",
      path: `/api/v1/meta/cell-conditions/${conditionId}/specify`,
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);
    const response = await getSpecificationDataForConditionIds(
      allCellConditionIds
    );
    return Promise.resolve({ response, errors });
  }
);

const batchConditinosSpecificationSlice = createSlice({
  name: BATCH_CONDITIONS_SPECIFICATION,
  initialState,
  reducers: {
    resetBatchConditionsSpecification: (state) =>
      Object.assign(state, initialState),
    resetCreateConditions: (state) => {
      state.createdCellConditionIds = [];
      state.status.create = "idle";
      state.error.create = null;
    },
    resetSaveConditionData: (state) => {
      state.status.save = "idle";
      state.status.specify = "idle";
      state.error.save = null;
      state.error.specify = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get data to populate fields on Overview Form
      .addCase(getOverviewFormData.pending, (state) => {
        state.status.getOverviewForm = "loading";
      })
      .addCase(
        getOverviewFormData.fulfilled,
        (state, { payload: { data } }) => {
          state.status.getOverviewForm = "succeeded";
          state.overviewFormOptions = {
            cell_types_to_assemblies: data.cell_types_to_assemblies,
          };
        }
      )
      .addCase(getOverviewFormData.rejected, (state, { error }) => {
        state.status.getOverviewForm = "failed";
        state.error.getOverviewForm = error.message as string;
      })
      // Get data for cell conditions on batch Edit cell conditions page
      .addCase(getSpecificationData.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(
        getSpecificationData.fulfilled,
        (state, { payload: { cell_conditions, form_template } }) => {
          state.conditionsToEdit = cell_conditions;
          state.editFormTemplate = form_template;
          state.status.get = "succeeded";
        }
      )
      .addCase(getSpecificationData.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Create cell conditions
      .addCase(createCellConditions.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(createCellConditions.fulfilled, (state, { payload }) => {
        state.status.create = payload.async ? "async" : "succeeded";
        if (payload && Array.isArray(payload)) {
          state.createdCellConditionIds = payload.map(
            (createdCellCondition) => createdCellCondition.cell_condition_id
          );
        }
      })
      .addCase(createCellConditions.rejected, (state, { error }) => {
        state.status.create = "failed";
        state.error.create = error.message as string;
      })
      // Save cell conditions
      .addCase(saveConditions.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(
        saveConditions.fulfilled,
        (
          state,
          {
            payload: {
              response: { cell_conditions, form_template },
              errors,
            },
          }
        ) => {
          if (!errors || errors.length === 0) {
            state.status.save = "succeeded";
            state.status.get = "succeeded";
          } else {
            state.status.save = "failed";
            state.error.save = errors.join(", ");
          }

          state.conditionsToEdit = cell_conditions;
          state.editFormTemplate = form_template;
        }
      )
      .addCase(saveConditions.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      })
      // Specify cell conditions
      .addCase(specifyConditions.pending, (state) => {
        state.status.specify = "loading";
      })
      .addCase(
        specifyConditions.fulfilled,
        (
          state,
          {
            payload: {
              response: { cell_conditions, form_template },
              errors,
            },
          }
        ) => {
          if (!errors || errors.length === 0) {
            state.status.specify = "succeeded";
            state.status.get = "succeeded";
          } else {
            state.status.specify = "failed";
            state.error.specify = errors.join(", ");
          }

          state.conditionsToEdit = cell_conditions;
          state.editFormTemplate = form_template;
        }
      )
      .addCase(specifyConditions.rejected, (state, { error }) => {
        state.status.specify = "failed";
        state.error.specify = error.message as string;
      })
      // Save cell condition updates
      .addCase(saveSpecificationComponentData.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(
        saveSpecificationComponentData.fulfilled,
        (state, { payload: { cell_conditions, form_template } }) => {
          state.status.save = "succeeded";
          state.status.get = "succeeded";
          state.conditionsToEdit = cell_conditions;
          state.editFormTemplate = form_template;
        }
      )
      .addCase(saveSpecificationComponentData.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      });
  },
});

export const {
  resetBatchConditionsSpecification,
  resetCreateConditions,
  resetSaveConditionData,
} = batchConditinosSpecificationSlice.actions;

export default batchConditinosSpecificationSlice.reducer;
