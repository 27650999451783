import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@mui/styles/styled";
import Chip from "../Chip";
import CaretBottomIcon from "../../icons/CaretBottom";
import { ListItemText } from "@mui/material";
import colors from "../../theme/colors";

export type CellIdentifierObj = {
  id: "cell" | "cell_serial_number" | "position_id";
  label: string;
  prefix: string;
  identifierKey: keyof DynamicItem;
  secondaryIdentifierKey?: string;
};

export const CELL_IDENTIFIER_OPTIONS: CellIdentifierObj[] = [
  { id: "cell", label: "Cell ID", prefix: "CEL", identifierKey: "id" },
  {
    id: "cell_serial_number",
    label: "Cell serial number",
    prefix: "",
    identifierKey: "cell_serial_number",
  },
  {
    id: "position_id",
    label: "Position on module ",
    prefix: "Position ",
    identifierKey: "position_id",
    secondaryIdentifierKey: "module_name",
  },
];

const StyledChip = styled(Chip)({
  flexDirection: "row-reverse",
});

type Props = {
  onSelectCellIdentifier: (cellIdentifier: string) => void;
  cellIdentifier: string;
  moduleName: string | null;
};

const ButtonCellIdentifierSelection = ({
  onSelectCellIdentifier,
  cellIdentifier,
  moduleName,
}: Props) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);
  const label = `by ${
    CELL_IDENTIFIER_OPTIONS.find(({ id }) => id === cellIdentifier)!.label
  }${moduleName || ""}`;

  return (
    <>
      <StyledChip
        clickable
        onClick={handleButtonClick}
        icon={<CaretBottomIcon />}
        rightIcon
        label={label}
        size="small"
        sx={{ color: colors.accent.primary }}
      />
      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {CELL_IDENTIFIER_OPTIONS.map(({ id, label }) => {
          return (
            <MenuItem
              key={id}
              onClick={() => {
                onSelectCellIdentifier(id);
                handleButtonClose();
              }}
            >
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonCellIdentifierSelection;
