import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import OverflowTooltip from "../../../components/OverflowTooltip";
import WarningIcon from "../../../icons/Warning";
import colors from "../../../theme/colors";

export type InnerBlockProps = {
  title: string;
  contents: (React.ReactNode | string)[];
  warning?: boolean;
  leftBorder?: boolean;
  large?: boolean;
};

type Props = {
  items: InnerBlockProps[];
};

const InfoBlock = ({ items }: Props) => (
  <Grid container style={{ maxWidth: 254 * 3 }}>
    {items.map(({ title, contents, warning }, index) =>
      items.length <= 1 ? (
        <Grid key={index} item xs={12}>
          <InnerBlock
            large
            title={title}
            contents={contents}
            warning={warning}
          />
        </Grid>
      ) : (
        <Grid key={index} item xs={12} md={4}>
          <InnerBlock
            title={title}
            contents={contents}
            leftBorder={index % 3 !== 0}
            warning={warning}
          />
        </Grid>
      )
    )}
  </Grid>
);

const InnerBlock = ({
  title,
  contents,
  leftBorder,
  warning,
  large,
}: InnerBlockProps) => {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const width = large
    ? "100%"
    : isDesktop && leftBorder
    ? `calc(254px - 3rem)`
    : `calc(254px - 1.5rem)`;

  return (
    <Box
      display="flex"
      pt={title ? 4 : undefined}
      pb={4}
      style={{
        borderBottom: `1px solid ${colors.rules}`,
        minHeight: title ? `calc(100% - 2rem)` : `calc(100% - 1rem)`,
      }}
    >
      <Box
        pl={isDesktop && leftBorder ? 6 : undefined}
        pr={6}
        style={{
          borderLeft:
            isDesktop && leftBorder ? `1px solid ${colors.rules}` : undefined,
          width,
        }}
      >
        {title ? (
          <Box mb={2}>
            <Typography
              color="textSecondary"
              className="small"
              component="div"
              style={{ fontWeight: 500 }}
            >
              <OverflowTooltip title={title}>
                <span>
                  {warning ? (
                    <WarningIcon
                      color="primary"
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: "0.5rem",
                        marginBottom: "-0.1rem",
                      }}
                    />
                  ) : null}
                  <span>{title}</span>
                </span>
              </OverflowTooltip>
            </Typography>
          </Box>
        ) : null}

        {contents.length > 0 ? (
          <Box
            display={title ? "flex" : "block"}
            justifyContent="space-between"
          >
            {contents.map((element, index) => (
              <Box key={index}>{element}</Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default InfoBlock;
