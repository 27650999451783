import { uniq } from "lodash";

const parseCellIdString = (
  cellIdString: string,
  requestedCellIds?: number[]
) => {
  const allIds = cellIdString.split(",").map((ids) => ids.split("-"));
  const allConditionIds = uniq(allIds.map((ids) => parseInt(ids[0])));
  const allCellIds = allIds.map((ids_) => parseInt(ids_[1]));
  let visibleIds: string[][] = [];
  if (requestedCellIds) {
    visibleIds = allIds.filter((ids) =>
      requestedCellIds.some((cellId) => ids.includes(`${cellId}`))
    );
  }
  const visibleConditionIds = uniq(visibleIds.map((ids_) => parseInt(ids_[0])));
  let hiddenIds: string[][] = [];
  if (requestedCellIds) {
    hiddenIds = allIds.filter((ids) =>
      requestedCellIds.every((cellId) => !ids.includes(`${cellId}`))
    );
  }
  const hiddenConditionIds = uniq(hiddenIds.map((ids) => parseInt(ids[0])));
  const conditionIdsToRequestedCellIds = allIds.reduce(
    (
      conditionIdsToRequestedCellIds: { [key: string]: string[] },
      conditionIdToCellId
    ) => {
      if (
        !Object.keys(conditionIdsToRequestedCellIds).includes(
          conditionIdToCellId[0]
        )
      ) {
        conditionIdsToRequestedCellIds[conditionIdToCellId[0]] = [];
      }
      conditionIdsToRequestedCellIds[conditionIdToCellId[0]] = [
        ...conditionIdsToRequestedCellIds[conditionIdToCellId[0]],
        conditionIdToCellId[1],
      ];
      return conditionIdsToRequestedCellIds;
    },
    {}
  );
  return {
    allIds,
    allCellIds,
    allConditionIds,
    hiddenConditionIds,
    visibleConditionIds,
    conditionIdsToRequestedCellIds,
  };
};

export default parseCellIdString;
