import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "./Chip";
import Microscope from "../icons/Microscope";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Modal from "./Modal";
import SamplesForm from "../features/characterization/SamplesForm";
import { useDispatch } from "react-redux";
import {
  getSampleFormFields,
  resetGetMaterials,
  resetSamples,
} from "../features/characterization/slice";
import Button from "./Button";
import { cellIdToString } from "../utils/labels";
import { isEmpty } from "lodash";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const CreateSamplesButton = ({
  from,
  cell_ids,
  shrinkButton = false,
}: {
  from?: string;
  cell_ids?: string[];
  shrinkButton?: boolean;
}) => {
  const [sampleCreateModalOpen, setSampleCreateModalOpen] = useState(false);
  const [selectedCellIds, setSelectedCellIds] = useState<string[]>([]);
  const [cellIdSelectionOpen, setCellIdSelectionOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSampleFormFields());
  }, [dispatch]);

  useEffect(() => {
    if (sampleCreateModalOpen && !isEmpty(cell_ids)) {
      setSelectedCellIds(cell_ids!.map((cellId_) => `${cellId_}`));
    }
  }, [sampleCreateModalOpen, setSelectedCellIds, cell_ids]);

  const handleCompleteModalForm = (
    newSamplesFromModal: CharacterizationSample[]
  ) => {
    dispatch(resetSamples());
    dispatch(resetGetMaterials());
    navigate("/characterization/new-samples", {
      state: { from: { pathname: from }, cell_ids, newSamplesFromModal },
    });
  };

  return (
    <>
      {shrinkButton ? (
        <Tooltip arrow title="Add samples for characterization">
          <span>
            <IconButton
              size="small"
              onClick={() => setSampleCreateModalOpen(true)}
            >
              <Microscope />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Chip
          clickable
          onClick={() => setSampleCreateModalOpen(true)}
          icon={<Microscope />}
          label="Add samples for characterization"
        />
      )}

      <Modal
        open={sampleCreateModalOpen}
        onClose={() => setSampleCreateModalOpen(false)}
      >
        <Typography variant="h2">
          {`Create${
            isEmpty(cell_ids) ? "" : " multiple"
          } characterization samples`}
        </Typography>
        {!isEmpty(cell_ids) && (
          <>
            <Box my={2}>
              <Typography color="textPrimary">
                {`Fill in shared sample data from ${
                  selectedCellIds.length
                } cell${selectedCellIds.length === 1 ? "" : "s"}`}
              </Typography>
              <Button
                color="secondary"
                size="small"
                type="submit"
                onClick={() => {
                  setCellIdSelectionOpen(!cellIdSelectionOpen);
                }}
                style={{ marginTop: 12 }}
              >
                Modify cells
              </Button>
            </Box>
            {cellIdSelectionOpen && (
              <Box>
                <FormGroup>
                  {cell_ids?.map((cellId_) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={selectedCellIds.includes(cellId_)}
                          onChange={() => {
                            const newCellIdCollection =
                              selectedCellIds.includes(cellId_)
                                ? selectedCellIds.filter(
                                    (cellIdInCollection) =>
                                      cellIdInCollection !== cellId_
                                  )
                                : [...selectedCellIds, cellId_];
                            setSelectedCellIds(newCellIdCollection);
                          }}
                        />
                      }
                      label={<>{cellIdToString(cellId_)}</>}
                      key={`${cellId_}-checkbox`}
                    />
                  ))}
                </FormGroup>
              </Box>
            )}
          </>
        )}

        <SamplesForm
          modalForm
          onCompleteModalForm={handleCompleteModalForm}
          originCellIds={selectedCellIds}
        />
      </Modal>
    </>
  );
};

export default CreateSamplesButton;
