import { Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { isEmpty, uniq } from "lodash";
import React from "react";
import Link from "../../components/Link";
import StatusChip from "../../components/StatusChip";
import {
  cellIdToString,
  expIdToString,
  getCharacterizationLabelForId,
  isoDateToDateString,
  isoDateToLocalTimeString,
  sampleIdToString,
} from "../../utils/labels";
import {
  DATE_SAMPLE_HARVESTED_LOOKUP_ID,
  STATUS_FIELD_ID,
  TECHNIQUE_REQUESTED_LOOKUP_ID,
} from "./slice";

type Props = {
  dataKey: keyof Sample;
  sample: CharacterizationSample;
};

const CHIP_COLOR_FOR_STATUS = {
  submitted: "yellow",
};

const SampleTableCell = ({ dataKey, sample }: Props) => {
  const MAX_MULTISELECT_ITEMS_IN_CELL = 1;
  const MAX_TECHNIQUES_IN_CELL = 3;

  switch (dataKey) {
    case "sample_id":
      return <TableCell>{sampleIdToString(sample.sample_id!)}</TableCell>;
    case "sample_field__sample_type":
      const sampleTypeField = sample.fields.find(
        (sampleField) => sampleField.field_lookup_id === "sample_type"
      );
      return (
        <TableCell>
          {sampleTypeField?.value
            ? getCharacterizationLabelForId(sampleTypeField.value as string)
            : "-"}
        </TableCell>
      );
    case "sample_field__description":
      const descriptionField = sample.fields.find(
        (sampleField) => sampleField.field_lookup_id === "description"
      );
      return (
        <TableCell>
          {descriptionField?.value ? (descriptionField.value as string) : "-"}
        </TableCell>
      );
    case "sample__cell__condition__name":
      return (
        <TableCell>{sample.sample__cell__condition__name || "-"}</TableCell>
      );
    case "sample_field__cell_id":
    case "sample_field__exp_id":
      const formatConfigByField = {
        sample_field__cell_id: ["cell_id", cellIdToString, `/cells`],
        sample_field__exp_id: ["exp_id", expIdToString, `/experiments`],
      };
      const [lookupId, _func, path] = formatConfigByField[dataKey];
      const stringifyFunc = _func as (_id: string) => string;
      const fieldCollection = sample.fields.filter(
        (sampleField) =>
          sampleField.field_lookup_id === lookupId && sampleField.value
      );
      const overflowFields = fieldCollection.slice(
        MAX_MULTISELECT_ITEMS_IN_CELL
      );
      const displayedField =
        !isEmpty(fieldCollection) && (fieldCollection[0].value as string);
      const tooltipProps = {
        arrow: true,
        title: overflowFields
          .map((field) => stringifyFunc(field.value as string))
          .join(", "),
        placement: "right" as "right",
      };
      const WrapperComponent = overflowFields ? Tooltip : React.Fragment;

      return (
        <TableCell>
          {displayedField ? (
            <WrapperComponent key={displayedField} {...tooltipProps}>
              <span>
                <Link
                  to={`${path}/${displayedField}`}
                  color="secondary"
                  key={displayedField}
                  onClick={(clickEvent: React.MouseEvent) => {
                    clickEvent.stopPropagation();
                  }}
                  state={{ from: "/characterization " }}
                >
                  {stringifyFunc(displayedField)}
                </Link>
                {!isEmpty(overflowFields) ? (
                  <span
                    style={{ marginLeft: 4 }}
                  >{` +${overflowFields.length}`}</span>
                ) : null}
              </span>
            </WrapperComponent>
          ) : (
            "-"
          )}
        </TableCell>
      );

    case "specimen_field__technique_requested":
      const requestedTechniques = uniq(
        sample.specimens.reduce(
          (
            techniqueCollection: { technique: string; status: string }[],
            _specimen
          ) => {
            const _technique: CharacterizationFormField | undefined =
              _specimen.fields.find(
                ({ field_lookup_id, value }) =>
                  field_lookup_id === TECHNIQUE_REQUESTED_LOOKUP_ID && value
              );
            if (_technique) {
              const _specimenStatusField: any = _specimen.fields.find(
                ({ field_lookup_id }: CharacterizationFormField) =>
                  field_lookup_id === STATUS_FIELD_ID
              )!;
              return [
                ...techniqueCollection,
                ...[
                  {
                    technique: _technique.value as string,
                    status: _specimenStatusField.value as string,
                  },
                ],
              ];
            } else return techniqueCollection;
          },
          []
        )
      );

      const renderTechniqueForIndex = (
        _techniqueObj: { technique: string; status: string },
        index: number
      ) => {
        const { technique: _technique, status } = _techniqueObj;
        const _status = status.toLowerCase();
        if (index < MAX_TECHNIQUES_IN_CELL) {
          return (
            <Tooltip arrow title={status} key={`${_technique}-${index}`}>
              <span>
                <StatusChip
                  label={getCharacterizationLabelForId(
                    _technique,
                    "techniques"
                  )}
                  style={{ marginLeft: 4 }}
                  color={
                    _status in CHIP_COLOR_FOR_STATUS
                      ? (CHIP_COLOR_FOR_STATUS[_status as "submitted"] as
                          | "green"
                          | "yellow"
                          | "violet")
                      : undefined
                  }
                />
              </span>
            </Tooltip>
          );
        }
        if (index === MAX_TECHNIQUES_IN_CELL) {
          return (
            <span key={`${_technique}-${index}`}>{` +${
              requestedTechniques.length - MAX_TECHNIQUES_IN_CELL
            }`}</span>
          );
        }
        return <React.Fragment key={`${_technique}-${index}`} />;
      };

      const renderTechniqueLabels = () => {
        if (isEmpty(requestedTechniques)) return "-";
        if (requestedTechniques.length > MAX_TECHNIQUES_IN_CELL) {
          return (
            <Tooltip
              arrow
              title={requestedTechniques
                .slice(MAX_TECHNIQUES_IN_CELL)
                .map((overflowTechnique) =>
                  getCharacterizationLabelForId(
                    overflowTechnique.technique,
                    "techniques"
                  )
                )
                .join(", ")}
            >
              <span>{requestedTechniques.map(renderTechniqueForIndex)}</span>
            </Tooltip>
          );
        }
        return requestedTechniques.map(renderTechniqueForIndex);
      };

      return <TableCell>{renderTechniqueLabels()}</TableCell>;

    case "sample_field__sample_owner":
      const sampleOwnerFields = sample.fields.filter(
        (sampleField) =>
          sampleField.field_lookup_id === "sample_owner_id" && sampleField.value
      );
      const overflowUsers = sampleOwnerFields.slice(
        MAX_MULTISELECT_ITEMS_IN_CELL
      );
      const displayedUser =
        !isEmpty(sampleOwnerFields) &&
        (sampleOwnerFields[0].value as User).name;
      const tooltipPropsUser = {
        arrow: true,
        title: overflowUsers
          .map((userObj) => (userObj.value as User).name)
          .join(", "),
        placement: "right" as "right",
      };
      const WrapperComponentUser = overflowUsers ? Tooltip : React.Fragment;
      return (
        <TableCell>
          {displayedUser ? (
            <WrapperComponentUser key={displayedUser} {...tooltipPropsUser}>
              <span>
                {displayedUser}
                {!isEmpty(overflowUsers) ? (
                  <span
                    style={{ marginLeft: 4 }}
                  >{` +${overflowUsers.length}`}</span>
                ) : null}
              </span>
            </WrapperComponentUser>
          ) : (
            "-"
          )}
        </TableCell>
      );
    case "sample_field__date_sample_harvested":
      const dateField = sample.fields.find(
        (sampleField) =>
          sampleField.field_lookup_id === DATE_SAMPLE_HARVESTED_LOOKUP_ID
      );
      return (
        <TableCell>
          <Tooltip
            disableHoverListener={!dateField || !dateField.value}
            arrow
            title={
              dateField
                ? isoDateToLocalTimeString(dateField.value as string)
                : ""
            }
          >
            <span>
              {dateField?.value
                ? isoDateToDateString(dateField.value as string)
                : "-"}
            </span>
          </Tooltip>
        </TableCell>
      );
    default:
      return <TableCell>{dataKey} not implemented</TableCell>;
  }
};

export default SampleTableCell;
