import { useSelector } from "react-redux";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import uniq from "lodash/uniq";

import Button from "../../../components/Button";
import ExclamationCircleOutlinedIcon from "../../../icons/ExclamationCircleOutlined";
import OverflowTooltip from "../../../components/OverflowTooltip";
import colors from "../../../theme/colors";

import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import type { ConditionUIState } from "../uiSlice";

import BaseTableRow from "../../../components/forms/BaseTableRow";
import ConditionCell from "../../../components/forms/ConditionCell";
import InputCell from "../../../components/forms/InputCell";
import TextareaCell from "../../../components/forms/TextareaCell";
import ThCell from "../../../components/forms/ThCell";
import UserCell from "../../../components/forms/UserCell";
import useAutocompleteStyles from "../../../components/forms/useAutocompleteStyles";

import { ConditionState } from "../slice";
import MetaCell from "../../../components/forms/MetaCell";
import ConditionDatePicker from "../ConditionDatePicker";
import { BUILD_PHASES, CHANNEL_POOL_EXPLAINER } from "../../../utils/labels";
import SelectCell from "../../../components/forms/SelectCell";

type OverviewProps = {
  exp_id: number;
  onNext: (conditions: Omit<Condition, "items" | "status">) => void;
  onCombinationChange?: (type: string, assembly: string) => void;
};

type ConditionsForm = {
  id?: string;
  exp_id: number;
  name: string;
  description: string;
  replicates: number;
  cell_type: string | null;
  cell_assembly: string | null;
  pool: string | null;
  engineer_flag: boolean;
  preferred_executor: User | null;
  plan_test_start_date: string | null;
  build_phase: string | null;
  build_config: string | null;
}[];

const Overview = ({ exp_id, onNext, onCombinationChange }: OverviewProps) => {
  const acClasses = useAutocompleteStyles();
  // UI state in redux
  const { ui, status: uiStatus } = useSelector<RootState, ConditionUIState>(
    ({ conditionUI }) => conditionUI
  );

  // Existing conditions in redux
  const {
    conditions,
    status: { get: conditionStatus },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Form management
  const { control, handleSubmit, setValue } = useForm<{
    conditions: ConditionsForm;
  }>({
    defaultValues: {
      conditions: [
        {
          name: "",
          description: "",
          replicates: 1,
          cell_type: null,
          cell_assembly: null,
          pool: null,
          engineer_flag: false,
          preferred_executor: null,
          plan_test_start_date: null,
          build_config: null,
          build_phase: null,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "conditions",
  });

  const state = useWatch({
    control,
    name: "conditions",
  });

  // We shouldn't hit that, Overview should be a child component only
  if (uiStatus !== "succeeded" || conditionStatus !== "succeeded") {
    return null;
  }

  // Options from the UI / form state
  const cellTypeOptions = uniq(ui?.map(({ cell_type }) => cell_type));

  const cellAssemblyOptions = (type: string) =>
    ui
      ?.filter(({ cell_type }) => cell_type === type)
      ?.map(({ cell_assembly }) => cell_assembly) || [];

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onNext(
          data.conditions.map((c, condition_order) => ({
            exp_id,
            condition_order,
            name: c.name,
            description: c.description,
            replicates: c.replicates,
            cell_type: c.cell_type!,
            cell_assembly: c.cell_assembly!,
            ref_cal_options: [],
            pool: c.pool!,
            engineer_flag: c.engineer_flag,
            preferred_executor: c.preferred_executor!,
            plan_test_start_date: c.plan_test_start_date,
            build_phase: c.build_phase,
            build_config: c.build_config,
            cells: [],
          }))[0]
        )
      )}
    >
      <Table size="small" style={{ width: "auto" }}>
        <TableHead>
          <BaseTableRow>
            <TableCell>
              <Box pt={2}>SPECIFICATIONS</Box>
            </TableCell>
            {fields.map((field, fieldIndex) => (
              <TableCell
                key={`title-${field.id}`}
                style={{ width: 204, flexShrink: 0 }}
              >
                <Box pt={2} display="flex" alignItems="center">
                  <OverflowTooltip
                    title={
                      state![fieldIndex]?.name || `CONDITION ${fieldIndex + 1}`
                    }
                  >
                    <span>
                      {state![fieldIndex]?.name ||
                        `CONDITION ${fieldIndex + 1}`}
                    </span>
                  </OverflowTooltip>
                </Box>
              </TableCell>
            ))}
          </BaseTableRow>
        </TableHead>
        <TableBody className="small">
          <BaseTableRow>
            <ThCell required>Condition name</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`name-${field.id}`}
                name={`conditions.${fieldIndex}.name`}
                defaultValue={field.name}
                control={control}
                rules={{
                  required: true,
                  validate: (val = "") => {
                    if (!val) {
                      return false;
                    }

                    const visibleMatch = fields.some(
                      (_field, i) =>
                        _field.id !== field.id &&
                        state![i].name.toLowerCase() === val.toLowerCase()
                    );

                    if (visibleMatch) {
                      return false;
                    }

                    const conditionMatch = conditions?.some(
                      (condition) =>
                        condition.name.toLowerCase() === val.toLowerCase()
                    );

                    return !conditionMatch;
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <InputCell
                    ref={ref}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{
                      className: "qa-exp-name-input",
                    }}
                    error={invalid}
                    endAdornment={
                      invalid ? (
                        <Tooltip
                          arrow
                          title={
                            !value
                              ? "Please fill in a condition name."
                              : "This condition name is already used in this experiment."
                          }
                        >
                          <div
                            style={{
                              color: colors.accent.red,
                              height: 20,
                              width: 20,
                              marginRight: 4,
                            }}
                          >
                            <ExclamationCircleOutlinedIcon
                              color="inherit"
                              style={{
                                width: 20,
                                height: 20,
                                transform: "rotate(180deg)",
                              }}
                            />
                          </div>
                        </Tooltip>
                      ) : null
                    }
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Condition description</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`description-${field.id}`}
                name={`conditions.${fieldIndex}.description`}
                defaultValue={field.description}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <TextareaCell
                    ref={ref}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={invalid}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Cells with replicate conditions </ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`replicates-${field.id}`}
                name={`conditions.${fieldIndex}.replicates`}
                defaultValue={field.replicates}
                control={control}
                rules={{ required: true, min: 1 }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <InputCell
                    ref={ref}
                    name={name}
                    type="number"
                    inputProps={{
                      min: 1,
                      onWheel: (e) => e.currentTarget.blur(),
                    }}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(parseInt(e.target.value))}
                    error={invalid}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Cell type</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`type-${field.id}`}
                name={`conditions.${fieldIndex}.cell_type`}
                defaultValue={field.cell_type}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <ConditionCell>
                    <FormControl fullWidth>
                      <Autocomplete
                        className={acClasses.root}
                        options={cellTypeOptions}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        value={value}
                        onBlur={onBlur}
                        onChange={(e, val) => {
                          setValue(
                            `conditions.${fieldIndex}.cell_assembly`,
                            null,
                            { shouldDirty: true }
                          );
                          onChange(val || null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              className: `${params.InputProps.className} qa-cell-type-input`,
                              error: invalid,
                            }}
                          />
                        )}
                        renderOption={(props, label) => (
                          <li {...props}>
                            <Box className="qa-cell-type-option" px={4} py={2}>
                              {label}
                            </Box>
                          </li>
                        )}
                      />
                    </FormControl>
                  </ConditionCell>
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Cell assembly</ThCell>

            {fields.map((field, fieldIndex) => (
              <Controller
                key={`assembly-${field.id}`}
                name={`conditions.${fieldIndex}.cell_assembly`}
                defaultValue={field.cell_assembly}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid },
                }) => (
                  <ConditionCell>
                    <FormControl fullWidth>
                      <Autocomplete
                        className={acClasses.root}
                        options={cellAssemblyOptions(
                          state![fieldIndex]?.cell_type || ""
                        )}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        value={value}
                        onBlur={onBlur}
                        onChange={(e, data) => {
                          onChange(data || null);
                          if (data && onCombinationChange) {
                            onCombinationChange(
                              state![fieldIndex]?.cell_type || "",
                              data
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              className: `${params.InputProps.className} qa-cell-assembly-input`,
                              error: invalid,
                            }}
                          />
                        )}
                        renderOption={(props, label) => (
                          <li {...props}>
                            <Box
                              className="qa-cell-assembly-option"
                              px={4}
                              py={2}
                            >
                              {label}
                            </Box>
                          </li>
                        )}
                      />
                    </FormControl>
                  </ConditionCell>
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Build phase</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`build-phase-${field.id}`}
                name={`conditions.${fieldIndex}.build_phase`}
                defaultValue={field.build_phase}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <SelectCell
                    ref={ref}
                    options={BUILD_PHASES}
                    error={invalid}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Build config</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`build-config-${field.id}`}
                name={`conditions.${fieldIndex}.build_config`}
                defaultValue={field.build_config}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <InputCell
                    ref={ref}
                    name={name}
                    value={value || ""}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value || null)}
                    error={invalid}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Channel pool</ThCell>

            {fields.map((field, fieldIndex) => (
              <Controller
                key={`pool-${field.id}`}
                name={`conditions.${fieldIndex}.pool`}
                defaultValue={field.pool}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { invalid },
                }) => (
                  <MetaCell
                    ref={ref}
                    endpoint="meta/channels/pool-ids"
                    searchKey="pool"
                    dropdownTooltipText={CHANNEL_POOL_EXPLAINER}
                    error={invalid}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Planned on-test date</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`plan_test_start_date-${field.id}`}
                name={`conditions.${fieldIndex}.plan_test_start_date`}
                defaultValue={field.plan_test_start_date}
                control={control}
                rules={{ required: true }}
                render={({ field: fieldProps, fieldState: { invalid } }) => (
                  <ConditionDatePicker
                    fieldIndex={fieldIndex}
                    invalid={invalid}
                    {...fieldProps}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Engineer build for all steps</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`engineer_flag-${field.id}`}
                name={`conditions.${fieldIndex}.engineer_flag`}
                defaultValue={field.engineer_flag}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ConditionCell key={fieldIndex} style={{ padding: 0 }}>
                    <Button
                      color="tertiary"
                      type="button"
                      size="small"
                      onClick={(e) => {
                        if (!value) {
                          setValue(
                            `conditions.${fieldIndex}.preferred_executor`,
                            user,
                            { shouldDirty: true }
                          );
                        } else {
                          setValue(
                            `conditions.${fieldIndex}.preferred_executor`,
                            null,
                            { shouldDirty: true }
                          );
                        }
                        onChange(!value);
                      }}
                    >
                      <b>{value ? "Un-flag all" : "Flag all"}</b>
                    </Button>
                  </ConditionCell>
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Assign executor for all steps</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`preferred_executor-${field.id}`}
                name={`conditions.${fieldIndex}.preferred_executor`}
                defaultValue={field.preferred_executor}
                control={control}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { invalid },
                }) => (
                  <UserCell
                    ref={ref}
                    disabled={state![fieldIndex]?.engineer_flag}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={invalid}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <TableCell
              colSpan={2}
              style={{
                textAlign: "right",
                borderBottom: 0,
                paddingTop: "1.5rem",
                paddingRight: 0,
              }}
            >
              <Button
                className="qa-test-condition-next"
                color="primary"
                size="small"
                type="submit"
              >
                Next
              </Button>
            </TableCell>
          </BaseTableRow>
        </TableBody>
      </Table>
    </form>
  );
};

export default Overview;
