import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";
import styled from "@mui/styles/styled";
import colors, { addAlpha } from "../theme/colors";

const getStyle = (color: string) => ({
  backgroundColor: addAlpha(color, 0.5),
  color: colors.text.primary,
  borderRadius: "1rem",
  height: 24,
  margin: 0,
  "& .MuiChip-label": {
    fontSize: "0.75rem",
    fontWeight: 600,
  },
});

const GreenChip = styled(Chip)(getStyle(colors.accent.green));
const YellowChip = styled(Chip)(getStyle(colors.accent.cta));
const VioletChip = styled(Chip)(getStyle(colors.accent.violet));
const DefaultChip = styled(Chip)(getStyle(colors.rules));

type Props = {
  color?: "green" | "yellow" | "violet";
} & Omit<ChipProps, "color">;

const StatusChip = ({ color, ...props }: Props) => {
  switch (color) {
    case "green":
      return <GreenChip {...props} />;
    case "violet":
      return <VioletChip {...props} />;
    case "yellow":
      return <YellowChip {...props} />;
    default:
      return <DefaultChip {...props} />;
  }
};

export default StatusChip;
