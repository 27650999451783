import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState, getAllCyclingProtocols } from "./slice";
import type { CyclingProtocolsState } from "./slice";

import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";

import type { RootState } from "../../store";

// Style overrides for this component.
export const useCyclingProtocolStyles = makeStyles({
  root: {
    // Allow descriptions to wrap; default is nowrap.
    "& .MuiTableCell-root": {
      whiteSpace: "normal",
    },
  },
});

const CyclingProtocolsLayout = () => {
  const isTablet = useMediaQuery("(min-width:760px)");
  // Cycling protocols state from Redux.
  const { cyclingProtocolsList, status, error } = useSelector<
    RootState,
    CyclingProtocolsState
  >(({ cyclingProtocols }) => cyclingProtocols);
  const dispatch = useDispatch();
  const classes = useCyclingProtocolStyles();

  // Issue a request for all test scripts,
  // the result of which will be made available in Redux state.
  useEffect(() => {
    dispatch(resetState());
    dispatch(getAllCyclingProtocols());
  }, [dispatch]);

  // JSX template for the page body.
  return (
    <>
      {/*Header*/}
      <Paper variant="outlined" square>
        <Box
          px={isTablet ? 6 : 0}
          py={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h2">TPVs (Test Scripts)</Typography>
          </Box>
        </Box>
      </Paper>

      <Box
        px={isTablet ? 6 : 0}
        my={4}
        style={{ minHeight: "calc(100vh - 48px - 60px)" }}
      >
        <TableContainer component={Paper}>
          <Table
            size="small"
            aria-label="test script table"
            className={`dataTable ${classes.root}`}
          >
            <TableHead>
              <TableRow>
                <TableCell>TPV</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error.list && (
               <Box
                  mt={6}
                  display="flex"
                  alignItems="center"
                  style={{ padding: "10px" }}
               >
                  <Typography
                    color="textSecondary"
                    variant="inherit"
                    className="small"
                  >
                    Error: {error.list}
                  </Typography>
               </Box>
              )}
              {!(cyclingProtocolsList.length > 0 || error.list) && (
                <Box
                  mt={6}
                  display="flex"
                  alignItems="center"
                  style={{ padding: "10px" }}
                >
                  <Typography
                    color="textSecondary"
                    variant="inherit"
                    className="small"
                    style={{ textTransform: "capitalize" }}
                  >
                    {status.list}...
                  </Typography>

                  <Box ml={4}>
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  </Box>
                </Box>
              )}
              {cyclingProtocolsList && (
                cyclingProtocolsList.map((row) => (
                  <TableRow
                    key={row.cycling_protocol_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.show_fact_sheet_link ? (
                        <a
                          href={"/cycling-protocols/" + row.cycling_protocol_id}
                        >
                          {row.cycling_protocol_id}
                        </a>
                      ) : (
                        row.cycling_protocol_id
                      )}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.created_time}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CyclingProtocolsLayout;
