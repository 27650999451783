import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";

const ItemCountHeader = ({ itemCount }: { itemCount: number }) => {
  return (
    <>
      <Box>
        <Typography color="textSecondary" variant="inherit" className="small">
          Viewing {itemCount}
        </Typography>
      </Box>
      <Box ml={4} py={1} height="2rem">
        <Divider orientation="vertical" />
      </Box>
    </>
  );
};

export default ItemCountHeader;
