import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useChannelCellStyles } from "./ReserveChannelsModal";
import colors from "../../theme/colors";
import { cellIdToString } from "../../utils/labels";
import SelectCell from "../../components/forms/SelectCell";

export const OFF_TEST_REASONS = ["End of test", "Cell failure"];

export type ModifyTeardownTypesTableProps = {
  teardownSpecsByCondition: {
    [key: string]: {
      teardownTypeByCellId: { [key: string]: string };
      offTestReasonByCellId: { [key: string]: string | null };
      teardown_flags: { [key: string]: any };
      cellCondition: Condition;
      component_meta_modes: string[];
      cell_statuses_for_condition: string[];
      teardown_condition_component_fields: ConditionUIField[];
    };
  };
  handleChangeTeardownTypes: (
    cellIds: string[],
    newValue: string | null
  ) => void;
  handleChangeOffTestReasons: (
    cellIds: string[],
    newValue: string | null
  ) => void;
  handleChangeTeardownFlags: (
    conditionIds_: string[],
    flag: string,
    newValue: string | boolean | User | null,
    persistEmpty?: boolean
  ) => void;
  teardownTypesToUpdate: { [key: string]: string };
  offTestReasonsToUpdate: { [key: string]: string | null };
  showOffTestReasons: boolean;
  teardownFlagsToUpdate: {
    [key: string]: { [key: string]: string | boolean | User | null };
  };
};

const ModifyTeardownTypesTable = ({
  teardownSpecsByCondition,
  handleChangeTeardownTypes,
  handleChangeOffTestReasons,
  teardownTypesToUpdate,
  offTestReasonsToUpdate,
  showOffTestReasons,
}: ModifyTeardownTypesTableProps) => {
  const classes = useChannelCellStyles();

  return (
    <Table
      className={`dataTable ${classes.root}`}
      size="small"
      style={{ background: colors.striping, width: "auto" }}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ background: "white" }} />
          {Object.keys(teardownSpecsByCondition).map(
            (str_cell_condition_id, index) => (
              <TableCell
                key={`teardown-types-header${str_cell_condition_id}`}
                style={{
                  background: "white",
                  width: "336px",
                  maxWidth: "336px",
                  minWidth: "272px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {
                  teardownSpecsByCondition[str_cell_condition_id].cellCondition
                    .name
                }
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell variant="head">Teardown type</TableCell>
          {Object.keys(teardownSpecsByCondition).map(
            (str_cell_condition_id, index) => {
              return (
                <TableCell
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  key={`condition-teardowntypes${str_cell_condition_id}`}
                >
                  {Object.keys(
                    teardownSpecsByCondition[str_cell_condition_id]
                      .teardownTypeByCellId
                  ).map((cellId_) => {
                    return (
                      <Box
                        display="flex"
                        style={{ fontSize: 12 }}
                        key={`cell-teardown-types${cellId_}`}
                      >
                        <Box flexGrow={1} alignSelf="center" paddingLeft={4}>
                          {cellIdToString(cellId_)}
                        </Box>
                        <SelectCell
                          key={`select-${index}`}
                          noTable
                          disableClearable
                          options={
                            teardownSpecsByCondition[str_cell_condition_id]
                              .component_meta_modes
                          }
                          onChange={(newVal_) => {
                            const newVal = newVal_ as string | null;
                            handleChangeTeardownTypes([cellId_], newVal);
                          }}
                          value={
                            teardownTypesToUpdate[cellId_] ||
                            teardownSpecsByCondition[str_cell_condition_id]
                              .teardownTypeByCellId[cellId_]
                          }
                          style={{ flexGrow: 6 }}
                        />
                      </Box>
                    );
                  })}
                </TableCell>
              );
            }
          )}
        </TableRow>
        {showOffTestReasons && (
          <TableRow>
            <TableCell variant="head">Off-test reason</TableCell>
            {Object.keys(teardownSpecsByCondition).map(
              (str_cell_condition_id, index) => {
                return (
                  <TableCell
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    key={`condition-teardown-reasons${str_cell_condition_id}`}
                  >
                    {Object.keys(
                      teardownSpecsByCondition[str_cell_condition_id]
                        .offTestReasonByCellId
                    ).map((cellId_) => {
                      return (
                        <Box
                          display="flex"
                          style={{ fontSize: 12 }}
                          key={`cell-teardownreasons${cellId_}`}
                        >
                          <Box flexGrow={1} alignSelf="center" paddingLeft={4}>
                            {cellIdToString(cellId_)}
                          </Box>
                          <SelectCell
                            key={`select-${index}`}
                            noTable
                            options={OFF_TEST_REASONS}
                            onChange={(newVal_) => {
                              const newVal = newVal_ as string | null;
                              handleChangeOffTestReasons([cellId_], newVal);
                            }}
                            value={
                              offTestReasonsToUpdate[cellId_] ||
                              teardownSpecsByCondition[str_cell_condition_id]
                                .offTestReasonByCellId[cellId_] ||
                              null
                            }
                            style={{ flexGrow: 6 }}
                          />
                        </Box>
                      );
                    })}
                  </TableCell>
                );
              }
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ModifyTeardownTypesTable;
