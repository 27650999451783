import { DateTime } from "luxon";
import uniq from "lodash/uniq";

export const EXPERIMENT_LABELS: DataLabel<Experiment>[] = [
  { id: "exp_id", label: "Exp ID" },
  { id: "owner__name", label: "Exp Owner" },
  { id: "project", label: "Project" },
  { id: "cell_count", label: "Cells" },
  { id: "last_commit", label: "Date Last Commit" },
  { id: "status", label: "Status" },
  { id: "description", label: "Description" },
];

export const EXPERIMENT_FILTER_LABELS: DataLabel<Experiment>[] = [
  ...EXPERIMENT_LABELS,
  { id: "owner", label: "Exp Owner" },
];

export const CELL_LABELS_ALL: DataLabel<Cell>[] = [
  { id: "cell_id", label: "Cell ID" },
  { id: "experiment__exp_id", label: "Exp ID" },
  { id: "condition__name", label: "Condition Name" },
  { id: "condition__description", label: "Condition Description" },
  { id: "condition__group_number", label: "Group Number" },
  { id: "condition__plan_test_start_date", label: "Planned On-Test Date" },
  { id: "condition__pool", label: "Specified Channel Pool" },
  { id: "condition__build_config", label: "Build Config" },
  { id: "condition__build_phase", label: "Build Phase" },
  { id: "full__build_test_location", label: "Build/Test Location" },
  { id: "owner__name", label: "Exp Owner" },
  { id: "experiment__project", label: "Project" },
  { id: "status", label: "Status" },
  { id: "priority", label: "Priority" },
  { id: "condition__cell_assembly", label: "Cell Assembly" },
  { id: "channel__fullname", label: "Channel ID" },
  { id: "position__shelf", label: "Channel Shelf" },
  { id: "reserved_channel__fullname", label: "Reserved Channel ID" },
  { id: "reserved_test_stand__name", label: "Reserved Test Stand" },
  { id: "tester__name", label: "Tester Name" },
  { id: "incubator__name", label: "Incubator Name" },
  { id: "anode__part_number", label: "Anode Part #" },
  { id: "anode__icm_pcm", label: "Anode Material Part #/ICM/PCM" },
  { id: "anode__additive_icm_pcm", label: "Anode Additive Part #" },
  { id: "anode__assembly_type", label: "Anode Assembly Type" },
  { id: "anode__qc_targets", label: "Anode QC Targets" },
  { id: "anode__nominal_mass", label: "Anode Mass spec. (g)" },
  { id: "anode__mass_actual", label: "Anode 1 Mass actual (g)" },
  { id: "anode__mass_actual_2", label: "Anode 2 Mass actual (g)" },
  { id: "anode__mass_actual_3", label: "Anode 3 Mass actual (g)" },
  { id: "anode__mass_actual_4", label: "Anode 4 Mass actual (g)" },
  { id: "anode__mass_actual_5", label: "Anode 5 Mass actual (g)" },
  { id: "anode__mass_actual_6", label: "Anode 6 Mass actual (g)" },
  {
    id: "anode__mass_actual_total",
    label: "Combined Anode Fe Mass actual (g)",
  },
  {
    id: "ref_anode__reference_electrode_type",
    label: "Anode Reference Electrode Type",
  },
  {
    id: "ref_gde__reference_electrode_type",
    label: "GDE Reference Electrode Type",
  },
  {
    id: "ref_oee__reference_electrode_type",
    label: "OEE Reference Electrode Type",
  },
  { id: "electrolyte__icm_pcm", label: "Electrolyte Part #/ICM/PCM" },
  { id: "fill__nominal_volume", label: "Electrolyte Volume (mL)" },
  { id: "gde__icm_pcm", label: "GDE Part #/ICM/PCM" },
  { id: "gde__gde_ids", label: "GDE ID(s)" },
  { id: "hca__hca_ids", label: "HCA ID(s)" },
  { id: "oee__icm_pcm", label: "OEE Part #/ICM/PCM" },
  { id: "oee__oee_ids", label: "OEE ID(s)" },
  { id: "condition__specified", label: "Date Specified" },
  { id: "staged", label: "Date Staged" },
  { id: "committed", label: "Date Committed" },
  { id: "test__created_time", label: "Date On-Test" },
  { id: "current_ref_cal__measurement", label: "Current Ref. Calibration" },
  { id: "on_test__cycling_protocol_id", label: "TPV" },
  { id: "anode__completed_at", label: "Anode" },
  { id: "gde__completed_at", label: "GDE" },
  { id: "oee__completed_at", label: "OEE" },
  { id: "counter__completed_at", label: "Counter-electrode" },
  { id: "ref_anode__completed_at", label: "Ref. Anode" },
  { id: "ref_gde__completed_at", label: "Ref. GDE" },
  { id: "ref_oee__completed_at", label: "Ref. OEE" },
  { id: "full__serial_number", label: "Serial #" },
  { id: "full__completed_at", label: "Full Assembly" },
  { id: "thermocouples_specified", label: "Thermocouple Specified" },
  { id: "thermocouples_attached", label: "Thermocouple Attached" },
  { id: "electrolyte__mix_task__completed", label: "Elyte Mix Task" },
  { id: "electrolyte__qa_task__completed", label: "Elyte QA Task" },
  { id: "electrolyte__completed_at", label: "Electrolyte Production" },
  { id: "fill__completed_at", label: "Fill" },
  { id: "on_test__completed_at", label: "On test" },
  { id: "on_test__temperature_setpoint", label: "Temperature specified" },
  {
    id: "on_test__air_flow_rate_setpoint",
    label: "Air flow rate specified (sccm)",
  },
  {
    id: "on_test__h2_sweep_gas_flow_rate",
    label: "H2 sweep gas flow rate (spec.)",
  },
  {
    id: "test_meta__h2_sweep_gas_flow_rate",
    label: "H2 sweep gas flow rate (actual)",
  },
  {
    id: "on_test__eis_testing_beginning_of_life",
    label: "EIS - BoL",
  },
  { id: "on_test__adapter_cable_type", label: "Adapter cable specified" },
  { id: "on_test__hydrogen_monitoring", label: "Hydrogen Monitoring" },
  { id: "on_test__initial_heater_setpoint", label: "Initial Heater Setpoint" },
  { id: "on_test__number_of_sampling_ports", label: "# of sampling ports" },
  {
    id: "on_test__number_of_thermocouples_needed",
    label: "# of thermocouples needed",
  },
  { id: "test_meta__adapter_cable_type", label: "Adapter cable used" },
  { id: "ready_off__completed_at", label: "Ready off" },
  { id: "off_test__completed_at", label: "Off test" },
  {
    id: "off_test__eis_testing_end_of_life",
    label: "EIS - EoL",
  },
  { id: "teardown__completed_at", label: "Teardown" },
  { id: "teardown__flags", label: "Teardown Flags" },
  { id: "teardown__type", label: "Teardown Type" },
];

export const CELL_LABELS_DEFAULT: DataLabel<Cell>[] = [
  ...CELL_LABELS_ALL.filter(({ id }) =>
    [
      "cell_id",
      "experiment__exp_id",
      "condition__name",
      "owner__name",
      "experiment__project",
      "status",
      "priority",
      "condition__cell_assembly",
    ].includes(id)
  ),
];

export const CELL_LABELS_EXPERIMENT: DataLabel<Cell>[] = [
  ...CELL_LABELS_ALL.filter(({ id }) =>
    [
      "cell_id",
      "condition__name",
      "status",
      "condition__cell_assembly",
      "anode__part_number",
      "anode__icm_pcm",
      "anode__nominal_mass",
      "electrolyte__icm_pcm",
      "fill__nominal_volume",
      "condition__specified",
      "test__created_time",
    ].includes(id)
  ),
];

export const CELL_FILTER_LABELS: { id: keyof CellFilters; label: string }[] = [
  { id: "cell_id", label: "Cell ID" },
  { id: "experiment__exp_id", label: "Exp ID" },
  { id: "experiment__project", label: "Project" },
  { id: "experiment__owner", label: "Exp Owner" },
  { id: "condition__name", label: "Condition Name" },
  { id: "condition__pool", label: "Specified Channel Pool" },
  { id: "condition__build_config", label: "Build Config" },
  { id: "condition__build_phase", label: "Build Phase" },
  { id: "full__build_test_location", label: "Build/Test Location" },
  { id: "status", label: "Status" },
  { id: "priority", label: "Priority" },
  { id: "channel__fullname", label: "Channel ID" },
  { id: "position__shelf", label: "Channel Shelf" },
  { id: "incubator__name", label: "Incubator Name" },
  { id: "tester__name", label: "Tester Name" },
  { id: "reserved_channel__fullname", label: "Reserved Channel ID" },
  { id: "condition__specified", label: "Date Specified" },
  { id: "test__created_time", label: "Date On-Test" },
  { id: "condition__plan_test_start_date", label: "Planned On-Test Date" },
  { id: "committed", label: "Date Committed" },
  { id: "engineer_flag", label: "Engineer Build" },
  { id: "deviations_from_sop", label: "SOP Deviation" },
  { id: "condition__group_number", label: "Group Number" },
  { id: "condition__cell_type", label: "Cell Type" },
  { id: "condition__cell_assembly", label: "Cell Assembly" },
  { id: "gde__icm_pcm", label: "GDE Part #/ICM/PCM" },
  { id: "gde__gde_ids", label: "GDE ID" },
  { id: "hca__hca_ids", label: "HCA ID" },
  { id: "oee__icm_pcm", label: "OEE Part #/ICM/PCM" },
  { id: "oee__oee_ids", label: "OEE ID" },
  { id: "anode__part_number", label: "Anode Part #" },
  { id: "anode__icm_pcm", label: "Anode Material Part #/ICM/PCM" },
  { id: "anode__additive_icm_pcm", label: "Anode Additive Part #" },
  { id: "anode__assembly_type", label: "Anode Assembly Type" },
  { id: "anode__qc_targets", label: "Anode QC Target" },
  { id: "electrolyte__icm_pcm", label: "Electrolyte Part #/ICM/PCM" },
  { id: "on_test__cycling_protocol", label: "Cycling Protocol" },
  { id: "anode__completed_at__null", label: "Anode" },
  { id: "electrolyte__mix_task__completed", label: "Elyte Mix Task" },
  { id: "electrolyte__qa_task__completed", label: "Elyte QA Task" },
  { id: "separator__completed_at__null", label: "Separator" },
  { id: "gde__completed_at__null", label: "GDE" },
  { id: "oee__completed_at__null", label: "OEE" },
  { id: "counter__completed_at__null", label: "Counter-electrode" },
  { id: "ref_anode__completed_at__null", label: "Ref. Anode" },
  { id: "ref_gde__completed_at__null", label: "Ref. GDE" },
  { id: "ref_oee__completed_at__null", label: "Ref. OEE" },
  { id: "full__completed_at__null", label: "Full Assembly" },
  { id: "full__serial_number", label: "Serial #" },
  { id: "thermocouples_specified", label: "Thermocouple specified" },
  { id: "thermocouples_attached", label: "Thermocouple attached" },
  { id: "electrolyte__completed_at__null", label: "Electrolyte Production" },
  { id: "fill__completed_at__null", label: "Fill" },
  { id: "on_test__completed_at__null", label: "On test" },
  {
    id: "on_test__eis_testing_beginning_of_life",
    label: "EIS - BoL",
  },
  { id: "on_test__temperature_setpoint", label: "Temperature specified" },
  { id: "on_test__adapter_cable_type", label: "Adapter cable specified" },
  {
    id: "on_test__air_flow_rate_setpoint",
    label: "Air flow rate specified (sccm)",
  },
  {
    id: "on_test__h2_sweep_gas_flow_rate",
    label: "H2 sweep gas flow rate specified (sccm)",
  },
  {
    id: "test_meta__h2_sweep_gas_flow_rate",
    label: "H2 sweep gas flow rate measured (sccm)",
  },
  { id: "test_meta__adapter_cable_type", label: "Adapter cable used" },
  {
    id: "ready_off__completed_at__null",
    label: "Ready Off",
  },
  { id: "off_test__completed_at__null", label: "Off test" },
  {
    id: "off_test__eis_testing_end_of_life",
    label: "EIS - EoL",
  },
  { id: "teardown__completed_at__null", label: "Teardown" },
  { id: "teardown__flags", label: "Teardown Flags" },
  { id: "teardown__type", label: "Teardown Type" },
];

export const CHANNEL_LABELS_ALL: DataLabel<Channel>[] = [
  { id: "position__name", label: "Pos ID" },
  { id: "position__shelf", label: "Shelf" },
  { id: "channel__fullname", label: "Channel ID" },
  { id: "channel__status", label: "Channel Status" },
  { id: "channel__infra_status", label: "Infra Status" },
  {
    id: "experiment__exp_id",
    label: "Exp ID (Reserved & On Test)",
    filterLabel: "Exp ID",
  },
  { id: "owner__name", label: "Exp Owner Name" },
  {
    id: "experiment__description",
    label: "Exp Description (Reserved & On Test)",
    filterLabel: "Exp Description",
  },
  { id: "experiment__project", label: "Project" },
  { id: "cell__cell_id", label: "Cell ID" },
  { id: "reserved_cell__cell_id", label: "Reserved Cell ID" },
  { id: "test_meta__ready_off__completed_at", label: "Ready Off" },
  {
    id: "cell_condition__cell_type",
    label: "Cell Type (Reserved & On Test)",
    filterLabel: "Cell Type",
  },
  {
    id: "cell_condition__cell_assembly",
    label: "Cell Assembly (Reserved & On Test)",
    filterLabel: "Cell Assembly",
  },
  {
    id: "cell_condition__name",
    label: "Cell Condition Name (Reserved & On Test)",
    filterLabel: "Cell Condition Name",
  },
  {
    id: "cell_condition__description",
    label: "Cell Condition Description (Reserved & On Test)",
    filterLabel: "Cell Condition Description",
  },
  {
    id: "full__cell_component__serial_number",
    label: "On-test Cell Serial #",
  },
  { id: "channel__airlines", label: "Sets of Airlines" },
  { id: "channel__aux_channels", label: "Aux Channels" },
  { id: "channel__aux_voltage_box_type", label: "Aux Voltage Box Type" },
  { id: "channel__aux_voltage_monitoring", label: "Aux Voltage Monitoring" },
  { id: "channel__cathode_switching", label: "Cathode Switching" },
  { id: "channel__co2_scrubbing", label: "CO2 Scrubbing" },
  { id: "channel__eis_functionality", label: "EIS Functionality" },
  { id: "channel__flow_valve_type", label: "Flow Valve Type" },
  { id: "channel__hazelnut_installed", label: "Hazelnut Installed" },
  {
    id: "channel__subscale_h2_interlock_installed",
    label: "Subscale H2 Interlock Installed",
  },
  { id: "channel__high_current_range", label: "High Current Range (A) >=" },
  { id: "channel__humidity_control", label: "Humidity Control" },
  { id: "channel__hydrogen_monitoring", label: "Hydrogen Monitoring" },
  { id: "channel__low_current_range", label: "Low Current Range (A) <=" },
  { id: "channel__kilobubbler_count", label: "Kilobubbler Count" },
  { id: "channel__max_voltage", label: "Max Voltage (V) >=" },
  { id: "channel__min_voltage", label: "Min Voltage (V) <=" },
  { id: "channel__pool", label: "Pool" },
  { id: "channel__preferred_test_vehicle", label: "Preferred Test Vehicle" },
  { id: "channel__pressure_setpoint", label: "Pressure Setpoint (psi)" },
  { id: "channel__tc_channels", label: "TC Channels" },
  { id: "channel__temperature", label: "Temperature (°C)" },
  {
    id: "channel__thermal_uniformity_fans_installed",
    label: "Thermal Uniformity Fans Installed",
  },
  { id: "channel__tester_brand", label: "Tester Brand" },
  { id: "channel__water", label: "Water" },
  { id: "channel__yearly_calibration_date", label: "Yearly Calibration Date" },
  { id: "incubator__name", label: "Incubator Name" },
  { id: "incubator__bay", label: "Incubator Bay" },
  { id: "test_stand__name", label: "Test Stand Name" },
];

export const CHANNEL_LABELS_DEFAULT: DataLabel<Channel>[] = [
  ...CHANNEL_LABELS_ALL.filter(({ id }) =>
    [
      "position__name",
      "channel__fullname",
      "channel__status",
      "channel__infra_status",
      "experiment__exp_id",
      "experiment__project",
      "cell__cell_id",
      "reserved_cell__cell_id",
      "test_meta__ready_off__completed_at",
      "cell_condition__cell_type",
      "cell_condition__cell_assembly",
    ].includes(id)
  ),
];

export const CHANNEL_FILTER_LABELS: DataLabel<Channel>[] = [
  ...CHANNEL_LABELS_ALL,
  {
    id: "test_meta__ready_off__completed_at__null",
    label: "Ready Off",
  },
  {
    id: "full__cell_component__serial_number",
    label: "On-test Cell Serial #",
  },
  {
    id: "reserved_cell__cell_id__null",
    label: "Channels without reservations",
  },
  { id: "position__name", label: "Pos ID" },
  { id: "experiment__owner", label: "Exp Owner" },
  { id: "res__owner", label: "Reserved Exp Owner" },
  {
    id: "res__exp_id",
    label: "Reserved Exp ID",
  },
  { id: "tester__name", label: "Tester Name" },
  { id: "channel__number", label: "Channel No." },
  { id: "incubator__name", label: "Incubator Name" },
  { id: "incubator__bay", label: "Incubator Bay" },
];

export const TEST_STAND_LABELS_ALL: DataLabel<TestStand>[] = [
  { id: "name", label: "Test stand name" },
  { id: "active_reservations", label: "Active Reservations" },
  { id: "channels", label: "Active Test Count (/ Max)" },
  { id: "anode_current_sensors", label: "Anode current sensors" },
  { id: "h2_sensor_type", label: "H2 sensor type" },
  { id: "temperature_range", label: "Temp. range" },
  { id: "gde_air_range", label: "GDE Air range" },
  { id: "sweep_air_range", label: "Sweep air range" },
  { id: "max_current_limit", label: "Max current limit" },
  { id: "daq_channels", label: "DAQ Channels" },
  { id: "dual_oee", label: "Dual OEE" },
  { id: "one_half_cell", label: "1 half cell" },
  { id: "two_half_cells", label: "2 half cells" },
  { id: "proto_1", label: "Proto 1" },
  { id: "proto_2", label: "Proto 2" },
  { id: "evt", label: "EVT" },
  { id: "megasus", label: "Megasus" },
  { id: "flex_cell", label: "FlexCell" },
  { id: "vehicle_303", label: "303" },
];

export const TEST_STAND_LABELS_DEFAULT: DataLabel<TestStand>[] = [
  ...TEST_STAND_LABELS_ALL.filter(({ id }) =>
    [
      "name",
      "active_reservations",
      "channels",
      "anode_current_sensors",
      "h2_sensor_type",
      "temperature_range",
      "max_current_limit",
      "gde_air_range",
      "sweep_air_range",
    ].includes(id)
  ),
];

export const EIS_TEST_TYPE_IDS = {
  eis_bol: "eis_beginning_of_life",
  eis_eol: "eis_end_of_life",
};

export const PREFLIGHT_TEST_TYPE_ID: string = "preflight_check";

export const STD_TEST_TYPE_LABELS: { id: string; label: string }[] = [
  { id: "cycle_test", label: "Cycle Test" },
  { id: EIS_TEST_TYPE_IDS.eis_bol, label: "EIS Testing - Beginning of Life" },
  { id: EIS_TEST_TYPE_IDS.eis_eol, label: "EIS Testing - End of Life" },
];

export const PREFLIGHT_TEST_TYPE_LABEL: { id: string; label: string } = {
  id: PREFLIGHT_TEST_TYPE_ID,
  label: "Pre-Flight Check",
};

export const TEST_TYPE_LABELS: { id: string; label: string }[] = [
  ...STD_TEST_TYPE_LABELS,
  PREFLIGHT_TEST_TYPE_LABEL,
];

export const DISCARD = "Discard";

export const TEARDOWN_FLAG_LABELS = [
  {
    id: "make_asana_task_to_discuss_characterization",
    label: "Make Asana task to discuss characterization",
  },
  {
    id: "pcb_health_check_flag",
    label: "PCB health check",
  },
  {
    id: "air_sensitive_teardown",
    label: "Air-sensitive teardown",
  },
  {
    id: "save_anode_flag",
    label: "Save anode",
  },
  {
    id: "air_sensitive_anode_handling",
    label: "Air-sensitive anode handling",
  },
  {
    id: "take_anode_core_sample",
    label: "Take anode core sample",
  },
  {
    id: "wip_linear_abraser_cohesion_test_on_anode",
    label: "WIP linear abraser cohension test on anode",
  },
  {
    id: "save_electrolyte_flag",
    label: "Save Elyte",
  },
  {
    id: "save_gde_flag",
    label: "Save GDE",
  },
  {
    id: "save_oee_flag",
    label: "Save OEE",
  },
  {
    id: "special_handling_coated_oee",
    label: "Special handling - coated OEE",
  },
  {
    id: "suspected_anode_delamination",
    label: "Suspected Anode Delamination, Risk of Self-Heating Upon Teardown",
  },
  {
    id: "rinse_oee_before_saving",
    label: "Rinse OEE before saving",
  },
  {
    id: "wash_sludge",
    label: "Rinse and re-mass sludge",
  },
  {
    id: "save_sludge",
    label: "Save sludge",
  },
  {
    id: "magnetic_sludge_collection",
    label: "Magnetic Sludge Collection",
  },
  {
    id: "save_sludge_on_filter_paper",
    label: "Save sludge on filter paper",
  },
  {
    id: "scrape_sludge_into_vial_as_standalone_sample",
    label: "Save sludge into vial as standalone sample",
  },
  {
    id: "non_standard",
    label: "Other non-standard teardown request",
  },
  {
    id: "engineer_flag",
    label: "Engineer teardown",
  },
  {
    id: "non_standard_post_mortem_request_flag",
    label: "Non-standard post-mortem request flag",
    type: "boolean",
  },
  {
    id: "non_standard_post_mortem_request_notes",
    label: "Non-standard post-mortem request notes",
    type: "text",
  },
  {
    id: "fast_teardown_turnaround_requested",
    label: "Fast teardown turnaround time post-Off-Test requested",
    type: "boolean",
  },
  {
    id: "requested_turnaround_date",
    label: "Requested turnaround date",
    type: "date",
  },
];

export const findLabelForFlag = (flagId: string) => {
  // returns flagId if label not found.
  return (
    TEARDOWN_FLAG_LABELS.find((optionObj) => optionObj.id === flagId)?.label ||
    flagId
  );
};

export const testTypeToString = (testType: string | null) => {
  return (TEST_TYPE_LABELS.find(({ id }) => id === testType) ||
    TEST_TYPE_LABELS.find(({ id }) => id === "cycle_test"))!.label;
};

export const expStatusToString = (status: ExperimentStatus) => {
  switch (status) {
    case "I":
      return "In Progress";
    case "P":
      return "Paused";
    case "U":
      return "Discontinued";
    case "C":
      return "Complete";
  }
};

export const channelStatusToString = (status: ChannelStatus) => {
  switch (status) {
    case "O":
      return "Open";
    case "I":
      return "In Use";
  }
};

export const channelAvailabilityToString = (status: ChannelAvailability) => {
  switch (status) {
    case "A":
      return "Availabel";
    case "R":
      return "Reserved";
    case "I":
      return "In Use";
    case "T":
      return "Take Off";
  }
};

export const channelInfraStatusToString = (status: ChannelInfraStatus) => {
  switch (status) {
    case "O":
      return "Online";
    case "C":
      return "Commissioning";
    case "H":
      return "Channel Issue";
    case "M":
      return "Decommissioned";
    case "W":
      return "Permanently Down";
    case "T":
      return "Temperature Change In Progress";
    case "R":
      return "Reserved for Cell Move";
    case "2":
      return "Low Function - No H2 Sensing";
    case "V":
      return "Low Function - Aux Voltage";
    case "G":
      return "Ganged";
    case "F":
      return "Low Function";
    case "B":
      return "Low Function - Incubator Temperature";
    case "P":
      return "Low Function - Thermocouple";
    case "N":
      return "Non-Standard Use";
    // Deprecated:
    case "I":
      return "Incubator Issue";
    case "U":
      return "Unknown Issue";
    case "A":
      return "Calibration";
    case "E":
      return "Temp. Equilibration";
    case "D":
      return "Decommissioning";
    case "L":
      return "Long-term Down";
    case "X":
      return "Blocked";
    case "S":
      return "SW Commissioning";
  }
};

export const cellStatusToString = (status: CellStatus) => {
  switch (status) {
    case "I":
      return "WIP";
    case "S":
      return "Specified";
    case "T":
      return "Staged";
    case "C":
      return "Committed";
    case "A":
      return "Active Material Started";
    case "M":
      return "Active Material Complete";
    case "D":
      return "Dry Build Started";
    case "E":
      return "Dry Build Complete";
    case "L":
      return "Fill Complete";
    case "P":
      return "On Test (Preflight)";
    case "N":
      return "On Test";
    case "F":
      return "Off Test";
    case "O":
      return "Torn Down";
    case "U":
      return "Discontinued";
  }
};

export const engineerFlagToString = (flag: EngineerFlagKey) => {
  switch (flag) {
    case "all_engineer_flag":
      return "All steps";
    case "anode__engineer_flag":
      return "Anode";
    case "gde__engineer_flag":
      return "GDE";
    case "oee__engineer_flag":
      return "OEE";
    case "counter__engineer_flag":
      return "Counter electrode";
    case "ref_anode__engineer_flag":
      return "Ref. Anode";
    case "ref_gde__engineer_flag":
      return "Ref. GDE";
    case "ref_oee__engineer_flag":
      return "Ref. OEE";
    case "full__engineer_flag":
      return "Full assembly";
    case "fill__engineer_flag":
      return "Fill";
    case "electrolyte__engineer_flag":
      return "Electrolyte";
    case "on_test__engineer_flag":
      return "On test";
    case "during_test__engineer_flag":
      return "During test";
    case "off_test__engineer_flag":
      return "Off test";
    case "teardown__engineer_flag":
      return "Teardown";
  }
};

export const cellIdToString = (cell_id: number | string) =>
  "CEL" + `${cell_id}`.padStart(6, "0");

export const expIdToString = (exp_id: number | string) =>
  "EXP" + `${exp_id}`.padStart(6, "0");

export const sampleIdToString = (sample_id: number | string) =>
  "SAM" + `${sample_id}`.padStart(6, "0");

export const isoDateToDateString = (isoDate?: string | null) => {
  if (!isoDate) {
    return "-";
  }
  const dt = DateTime.fromISO(isoDate, { zone: "UTC" });
  if (!dt.isValid) {
    return "-";
  }
  return dt.setLocale("en").toLocal().toFormat("yyyy-MM-dd");
};

export const isoDateToDateTimeString = (isoDate?: string | null) => {
  if (!isoDate) {
    return "-";
  }
  const dt = DateTime.fromISO(isoDate, { zone: "UTC" });
  if (!dt.isValid) {
    return "-";
  }
  return dt.setLocale("en").toLocal().toFormat("H:mm, yyyy-MM-dd");
};

export const isoDateToLocalTimeString = (
  isoTime?: string | null,
  includeDate = true
) => {
  if (!isoTime) {
    return "-";
  }
  const dateObj = new Date(isoTime as string);
  if (!dateObj.getTime()) {
    return "-";
  }

  if (includeDate) {
    return dateObj.toLocaleString("en-US", {
      timeStyle: "short",
      dateStyle: "short",
    });
  }
  return dateObj.toLocaleString("en-US", { timeStyle: "short" });
};

export const isoDateToTimeString = (isoDate?: string | null) => {
  if (!isoDate) {
    return "-";
  }
  const dt = DateTime.fromISO(isoDate, { zone: "UTC" });
  if (!dt.isValid) {
    return "-";
  }
  return dt.setLocale("en").toLocal().toFormat("H:mm");
};

export const isoDateToHumanizedString = (isoDate?: string | null) => {
  if (!isoDate) {
    return "-";
  }
  const dt = DateTime.fromISO(isoDate, { zone: "UTC" });
  if (!dt.isValid) {
    return "-";
  }
  return dt.setLocale("en").toLocal().toRelative();
};

export const cellFilterToString = (filters: CellFilters) =>
  Object.keys(filters)
    .map((key) => {
      const typedKey = key as keyof CellFilters;

      const { label } =
        CELL_FILTER_LABELS.find(({ id }) => id === typedKey) || {};

      if (!filters[typedKey]) {
        return "INVALID";
      }

      if (!label) {
        return "INVALID";
      }

      if (key === "condition__specified") {
        return `${label}: (${filters[key]!.join(" to ")})`;
      }

      if (key === "test__created_time") {
        return `${label}: (${filters[key]!.join(" to ")})`;
      }

      if (key === "cell_id") {
        return `${label}: (${filters[key]!.map((id) => cellIdToString(id)).join(
          ", "
        )})`;
      }

      if (key === "experiment__exp_id") {
        return `${label}: (${filters[key]!.map((id) => expIdToString(id)).join(
          ", "
        )})`;
      }

      if (key === "experiment__owner") {
        return `${label}: (${filters[key]!.map(({ name }) => name).join(
          ", "
        )})`;
      }

      if (key === "status") {
        return `${label}: (${filters[key]!.map((s) =>
          cellStatusToString(s)
        ).join(", ")})`;
      }

      if (key === "channel__fullname" || key === "reserved_channel__fullname") {
        return `${label}: (${filters[key]!.map((s) => s.channel_fullname).join(
          ", "
        )})`;
      }

      if (
        key === "gde__icm_pcm" ||
        key === "oee__icm_pcm" ||
        key === "anode__part_number" ||
        key === "anode__icm_pcm" ||
        key === "electrolyte__icm_pcm"
      ) {
        return `${label}: (${filters[key]!.map(
          ({ material_id }) => material_id
        ).join(", ")})`;
      }

      if (key === "on_test__cycling_protocol") {
        return `${label}: (${filters[key]!.map(
          ({ cycling_protocol_id }) => cycling_protocol_id
        ).join(", ")})`;
      }

      if (
        key === "anode__completed_at__null" ||
        key === "gde__completed_at__null" ||
        key === "oee__completed_at__null" ||
        key === "counter__completed_at__null" ||
        key === "ref_anode__completed_at__null" ||
        key === "ref_gde__completed_at__null" ||
        key === "ref_oee__completed_at__null" ||
        key === "full__completed_at__null" ||
        key === "electrolyte__completed_at__null" ||
        key === "fill__completed_at__null" ||
        key === "on_test__completed_at__null" ||
        key === "ready_off__completed_at__null" ||
        key === "off_test__completed_at__null" ||
        key === "teardown__completed_at__null"
      ) {
        return `${label}: (${
          filters[key]!.includes("0") ? "Completed" : "Not completed"
        })`;
      }

      if (key === "engineer_flag" || key === "deviations_from_sop") {
        const flags = filters[key]!.filter(
          (flagFilters) => flagFilters[1] === "1"
        );
        const noFlags = filters[key]!.filter(
          (flagFilters) => flagFilters[1] === "0"
        );

        let result = "";
        if (flags.length > 0) {
          result += `Cells with flag: ${flags
            .map((flagFilter) =>
              engineerFlagToString(flagFilter[0] as EngineerFlagKey)
            )
            .join(", ")}. `;
        }
        if (noFlags.length > 0) {
          result += `Cells without flag: ${noFlags
            .map((flagFilter) =>
              engineerFlagToString(flagFilter[0] as EngineerFlagKey)
            )
            .join(", ")}. `;
        }

        return `${`${label}: (${result.trim()})`}`;
      }

      return `${label}: (${filters[typedKey]?.join(",")})`;
    })
    .join(", ");

export const expFilterToString = (filters: ExperimentFilters) =>
  Object.keys(filters)
    .map((key) => {
      const typedKey = key as keyof ExperimentFilters;

      const { label } =
        EXPERIMENT_FILTER_LABELS.find(({ id }) => id === typedKey) || {};

      if (!filters[typedKey]) {
        return "INVALID";
      }

      if (!label) {
        return "INVALID";
      }

      if (key === "last_commit") {
        return `${label}: (${filters[key]!.join(" to ")})`;
      }

      if (key === "owner") {
        return `${label}: (${filters[key]!.map(({ name }) => name).join(
          ", "
        )})`;
      }

      if (key === "exp_id") {
        return `${label}: (${filters[key]!.map((id) => expIdToString(id)).join(
          ", "
        )})`;
      }

      if (key === "status") {
        return `${label}: (${filters[key]!.map((s) =>
          expStatusToString(s)
        ).join(", ")})`;
      }

      return `${label}: (${filters[typedKey]?.join(",")})`;
    })
    .join(", ");

export const channelFilterToString = (filters: ChannelFilters) =>
  Object.keys(filters)
    .map((key) => {
      const typedKey = key as keyof ChannelFilters;

      const { label } =
        CHANNEL_FILTER_LABELS.find(({ id }) => id === typedKey) || {};

      if (!filters[typedKey]) {
        return "INVALID";
      }

      if (!label) {
        return "INVALID";
      }

      if (key === "experiment__owner" || key === "res__owner") {
        return `${label}: (${filters[key]!.map(({ name }) => name).join(
          ", "
        )})`;
      }

      if (key === "experiment__exp_id" || key === "res__exp_id") {
        return `${label}: (${filters[key]!.map((id) => expIdToString(id)).join(
          ", "
        )})`;
      }

      if (key === "channel__fullname") {
        return `${label}: (${filters[key]!.map(
          ({ channel_fullname }) => channel_fullname
        ).join(", ")})`;
      }

      if (key === "cell__cell_id" || key === "reserved_cell__cell_id") {
        return `${label}: (${filters[key]!.map((id) => cellIdToString(id)).join(
          ", "
        )})`;
      }

      if (key === "channel__status") {
        return `${label}: (${filters[key]!.map((s) =>
          channelStatusToString(s)
        ).join(", ")})`;
      }

      if (
        key === "channel__cathode_switching" ||
        key === "channel__co2_scrubbing" ||
        key === "channel__humidity_control" ||
        key === "channel__hydrogen_monitoring" ||
        key === "channel__aux_voltage_monitoring" ||
        key === "channel__eis_functionality"
      ) {
        return `${label}: (${filters[key]!.includes("0") ? "Yes" : "No"})`;
      }

      return `${label}: (${filters[typedKey]?.join(",")})`;
    })
    .join(", ");

export const getCombinedLabel = (_labels: string[]): string => {
  const labels = _labels.map((label) => (!label ? "" : label));
  const uniques = uniq(labels);
  if (!uniques[0]) {
    return "-";
  }

  const plusLabel = uniques.length > 1 ? ` + ${uniques.length - 1}` : "";
  return `${labels[0]}${plusLabel}`;
};

export const getInitialsForUser = (user?: User | null): string => {
  const splitName = user?.name?.split(" ") || [];
  const initials =
    splitName.length > 0
      ? `${splitName[0] ? splitName[0][0] : ""}${
          splitName[1] ? splitName[1][0] : ""
        }`
      : "?";

  return initials?.toUpperCase();
};

export const getNoteLabelForResource = (
  resource: NoteItemRelation["resource"]
) => {
  switch (resource) {
    case "cell":
      return "Cell ID";
  }
};

export const getNoteItemLabelForResource = (
  resource: NoteItemRelation["resource"],
  id: number
) => {
  switch (resource) {
    case "cell":
      return cellIdToString(id);
  }
};

export const CHANNEL_POOL_EXPLAINER =
  "Select Channel pool intended for cycle testing, not EIS testing";

const TARGET_THICKNESS_EXPLAINER =
  "Effective active material thickness, regardless of current collector.";

export const INPUT_INFO_TEXT_BY_FIELD_ID = {
  target_thickness: TARGET_THICKNESS_EXPLAINER,
  target_cold_press_thickness: TARGET_THICKNESS_EXPLAINER,
};

export const SAMPLE_LABELS_ALL = [
  { id: "sample_id", label: "Sample ID" },
  { id: "sample_field__sample_type", label: "Sample Type" },
  { id: "sample_field__date_sample_harvested", label: "Date Harvested" },
  { id: "sample_field__description", label: "Sample Description" },
  { id: "sample_field__cell_id", label: "Cell ID" },
  { id: "sample_field__exp_id", label: "Exp ID" },
  { id: "sample__cell__condition__name", label: "Condition Name" },
  { id: "sample_field__sample_owner", label: "Sample Owner(s)" },
  // {
  //   id: "specimen_field__technique_requested",
  //   label: "Requested Technique(s)",
  // },
];

export const TECHNIQUE_TYPES = [
  { id: "not_sure", label: "Not sure", exclusive: true },
  {
    id: "bet",
    label: "BET",
    description: "Brunauer, Emmett, Teller Specific Surface Area Analysis",
  },
  {
    id: "lcm",
    label: "LCM",
    description: "Laser Confocal Microscope",
  },
  {
    id: "csca",
    label: "CSCA",
    description: "Carbon Sulfur Combustion Analysis",
  },
  {
    id: "eds",
    label: "EDS",
    description: "Energy-Dispersive X-ray Spectroscopy",
    destructive: true,
  },
  { id: "gas_pycnometry", label: "GP", description: "Gas Pycnometry" },
  {
    id: "icp_oes",
    label: "ICP-OES",
    description: "Inductively Coupled Plasma Optical Emission Spectroscopy",
    destructive: true,
  },
  { id: "om", label: "OM", description: "Optical Microscopy" },
  {
    id: "sem",
    label: "SEM",
    description: "Scanning Electron Microscopy",
    destructive: true,
  },
  {
    id: "lpt",
    label: "LPT",
    description: "Lead Perchlorate Titration (Potentiometric Titration)",
  },
  { id: "visc", label: "VISC", description: "Viscosity" },
  { id: "wt", label: "WT", description: "Warder Titration" },
  { id: "xrd", label: "XRD", description: "X-ray Powder Diffraction" },
  { id: "xrf", label: "XRF", description: "X-ray Fluorescence" },
];

export const getCharacterizationLabelForId = (
  id: string,
  labelCollection: "sampleTypes" | "techniques" = "sampleTypes"
) => {
  interface CommonLabel {
    id: string;
    label: string;
  }
  const collectionToSearch: CommonLabel[] =
    labelCollection === "techniques"
      ? TECHNIQUE_TYPES
      : CHARACTERIZATION_SAMPLE_TYPES;
  return collectionToSearch.find(
    (techniqueType: { id: string }) => techniqueType.id === id
  )!.label;
};

export const ANODE_MATERIAL_OPTIONS = [
  { id: "iron", label: "Iron" },
  { id: "iron_oxide", label: "Iron Oxide" },
];

export const GDE_MATERIAL_OPTIONS = [
  { id: "binder", label: "Binder" },
  { id: "carbon", label: "Carbon" },
  { id: "catalyst", label: "Catalyst" },
  { id: "current_collector", label: "Current Collector" },
  { id: "cycled_gde", label: "Cycled GDE" },
  { id: "pristine_gde", label: "Pristine GDE" },
  { id: "solvent", label: "Solvent" },
];

export const OEE_MATERIAL_OPTIONS = [
  { id: "pristine_oee", label: "Pristine OEE" },
  { id: "cycled_oee", label: "Cycled OEE" },
  { id: "powder", label: "Powder" },
  { id: "ink_bath", label: "Ink bath" },
  { id: "electrolyte", label: "Electrolyte" },
  { id: "other", label: "Other" },
];

export const ANODE_FORM_FACTOR_OPTIONS = [
  { id: "core", label: "Core" },
  { id: "chunk", label: "Chunk" },
  { id: "electrode", label: "Electrode" },
  { id: "pellet", label: "Pellet" },
  { id: "powder", label: "Powder" },
];

export const ALL_FORM_FACTOR_OPTIONS = [
  ...ANODE_FORM_FACTOR_OPTIONS,
  { id: "film", label: "Film" },
  { id: "foil", label: "Foil" },
  { id: "liquid", label: "Liquid" },
  { id: "mesh", label: "Mesh" },
  { id: "puck", label: "Puck" },
  { id: "other", label: "Other" },
];

export const SAMPLE_LOCATION_OPTIONS = [
  { id: "center", label: "Center" },
  { id: "counter_facing", label: "Counter facing" },
  { id: "current_collector", label: "Current collector" },
  { id: "edge", label: "Edge" },
  { id: "other", label: "Other" },
];

export const ELYTE_SAMPLE_LOCATION_OPTIONS = [
  { id: "top", label: "Top" },
  { id: "middle", label: "Middle" },
  { id: "bottom", label: "Bottom" },
  { id: "other", label: "Other" },
];

export const OEE_MESH_TYPE_OPTIONS = [
  { id: "r3", label: "R3" },
  { id: "r5", label: "R5" },
  { id: "cst", label: "CSt" },
  { id: "other", label: "Other" },
];

export const ELYTE_STATE_OPTIONS = [
  { id: "cycled", label: "Cycled" },
  { id: "pristine", label: "Pristine" },
  { id: "soaked", label: "Soaked" },
  { id: "other", label: "Other" },
];

export const ELYTE_STORAGE_OPTIONS = [
  { id: "nitrogen_blanketed", label: "Nitrogen blanketed" },
  { id: "not_nitrogen_blanketed", label: "Not nitrogen blanketed" },
];

export const SAMPLE_TYPE_SLUDGE_OPTIONS = [
  { id: "rinsed", label: "Rinsed" },
  { id: "unrinsed", label: "Unrinsed" },
];

export const SANDBLASTED_OPTIONS = [
  { id: "60", label: "60" },
  { id: "120", label: "120" },
  { id: "none", label: "None" },
];

export const SPECIMEN_PRIORITY_OPTIONS = [
  { id: "p1", label: "P1" },
  { id: "p3", label: "P3" },
  { id: "p5", label: "P5" },
  { id: "p10", label: "P10" },
];

export const ICP_SPECIMEN_REQUEST_TYPE_OPTIONS = [
  { id: "internal", label: "Internal" },
  { id: "external_rj_lee", label: "External - RJ Lee" },
];

export const SPECIMEN_REQUEST_TYPE_OPTIONS = [
  { id: "standard", label: "Standard" },
  { id: "caas_non_standard", label: "CaaS Non-standard" },
  { id: "exp_owner_non_standard", label: "Experiment Owner Non-standard" },
];

export const QUANTITY_TYPE_OPTIONS = [
  { id: "cm2", label: "cm²" },
  { id: "mL", label: "mL" },
  { id: "g", label: "g" },
  { id: "items", label: "item(s)" },
];

export const FSC_SIGNAL_LABELS_LIST: DataLabel<Signal>[] = [
  // For the list view
  { id: "name", label: "Signal Name" },
  { id: "device_class", label: "Device Class" },
  { id: "device_address", label: "Device Address" },
  { id: "offset", label: "Offset" },
  { id: "gain", label: "Gain" },
  { id: "sensor_type", label: "Sensor Type" },
  { id: "thermocouple_type", label: "Thermocouple Type" },
  { id: "start_register", label: "Start Register" },
  { id: "register_count", label: "Register Count" },
  { id: "big_endian", label: "Big Endian?" },
  { id: "unit_id", label: "Modbus Unit ID" },
  { id: "is_coil", label: "Coil Signal?" },
];

export const FAULT_CONDITION_LABELS_LIST: DataLabel<FaultCondition>[] = [
  // For the list view
  { id: "parsed_expression_string", label: "Expression" },
  { id: "dti", label: "Detection Time Interval" },
  { id: "fault_category", label: "Fault Category" },
];

export const FSC_SIGNAL_LABELS_MODAL: DataLabel<SignalFormData>[] = [
  // For the modal form
  { id: "offset", label: "Offset" },
  { id: "gain", label: "Gain" },
  { id: "measurementType", label: "Measurement Type" },
  { id: "sensorType", label: "Sensor Type" },
  { id: "deviceClass", label: "Device Class" },
  { id: "units", label: "Units" },
  { id: "namingPattern", label: "Signal Name" },
  { id: "addressPattern", label: "Device Address" },
  { id: "thermocouple_type", label: "Thermocouple Type" },
  { id: "start_register", label: "Start Register" },
  { id: "register_count", label: "Register Count" },
  { id: "big_endian", label: "Is this signal big endian?" },
  { id: "unit_id", label: "Unit ID" },
  { id: "is_coil", label: "Is this a register or coil signal?" },
];

export const SERIAL_HPHC_SIGNAL_LABELS_LIST: DataLabel<Signal>[] = [
  // For the list view
  ...FSC_SIGNAL_LABELS_LIST,
  { id: "cell_position_id", label: "Cell Position ID" },
];

export const SERIAL_HPHC_SIGNAL_LABELS_MODAL: DataLabel<SignalFormData>[] = [
  // For the modal form
  ...FSC_SIGNAL_LABELS_MODAL,
  { id: "cell_id", label: "Cell Position ID" }, // Saved as cell_id but displayed as position_id
];

export const BAM_SIGNAL_LABELS_LIST: DataLabel<Signal>[] = [
  ...SERIAL_HPHC_SIGNAL_LABELS_LIST,
  { id: "signal_group", label: "Signal Group" },
  { id: "signal_range", label: "Signal Range" },
  { id: "board", label: "Board" },
  { id: "port_name", label: "Port Name" },
  { id: "power_coeff_2", label: "x^2 Coefficient" },
  { id: "power_coeff_3", label: "x^3 Coefficient" },
  { id: "power_coeff_4", label: "x^4 Coefficient" },
  { id: "power_coeff_5", label: "x^5 Coefficient" },
];

export const BAM_SIGNAL_LABELS_MODAL: DataLabel<SignalFormData>[] = [
  // For the modal form
  ...SERIAL_HPHC_SIGNAL_LABELS_MODAL,
  { id: "signal_group_id", label: "Signal Group" },
  { id: "power_coeff_2", label: "x^2 Coefficient" },
  { id: "power_coeff_3", label: "x^3 Coefficient" },
  { id: "power_coeff_4", label: "x^4 Coefficient" },
  { id: "power_coeff_5", label: "x^5 Coefficient" },
  { id: "signal_range", label: "Signal Range" },
  { id: "board", label: "Board" },
  { id: "port_name", label: "Port Name" },
];

export const BUILD_PHASES = ["EVT1", "EVT2", "FSC303", "Proto1", "Proto2"];

export const CHARACTERIZATION_SAMPLE_TYPES = [
  // legacy sample types (for grandfathering in existing samples)
  { id: "anode", label: "Anode", enabled: false },
  { id: "elyte", label: "Electrolyte", enabled: false },
  { id: "dri", label: "DRI", enabled: false },
  { id: "gde", label: "GDE", enabled: false },
  { id: "hpsi", label: "HPSI", enabled: false },
  { id: "mush", label: "Mush", enabled: false },
  { id: "oee", label: "OEE", enabled: false },
  { id: "sludge", label: "Sludge", enabled: false },
  { id: "other", label: "Other", enabled: false },
  // autogenerated from `generate_asana_characterization_frontend_constants()`
  {
    id: "AS_RECEIVED_UNCYCLED_ELECTROLYTE",
    label: "As Received - Uncycled Electrolyte",
    enabled: true,
  },
  { id: "CYCLED_ELECTROLYTE", label: "Cycled Electrolyte", enabled: true },
  { id: "RAW_IRON_MATERIAL", label: "Raw Iron Material", enabled: true },
  { id: "BLENDED_ANODE_POWDER", label: "Blended Anode Powder", enabled: true },
  {
    id: "AS_RECEIVED_UNCYCLED_OEE",
    label: "As Received - Uncycled OEE",
    enabled: true,
  },
  { id: "CYCLED_OEE", label: "Cycled OEE", enabled: true },
  { id: "GDE_RAW_MATERIAL", label: "GDE Raw Material", enabled: true },
  {
    id: "AS_RECEIVED_UNCYCLED_GDE",
    label: "As Received - Uncycled GDE",
    enabled: true,
  },
  { id: "CYCLED_GDE", label: "Cycled GDE", enabled: true },
  {
    id: "AS_RECEIVED_UNCYCLED_ANODE",
    label: "As Received - Uncycled Anode",
    enabled: true,
  },
  { id: "CYCLED_ANODE", label: "Cycled Anode", enabled: true },
  { id: "SLUDGE", label: "Sludge", enabled: true },
  { id: "AS_RECEIVED_HCA", label: "As Received HCA", enabled: true },
  { id: "CURRENT_COLLECTOR", label: "Current Collector", enabled: true },
  { id: "SEPARATOR", label: "Separator", enabled: true },
  {
    id: "OTHER_PLEASE_DESCRIBE",
    label: "Other (please describe)",
    enabled: true,
  },
  { id: "NEAT_MATERIAL", label: "Neat Material", enabled: true },
  {
    id: "MICROWAVE_DIGESTED_MATERIAL",
    label: "Microwave Digested Material",
    enabled: true,
  },
  { id: "KFR_POWDER", label: "KFR powder", enabled: true },
  { id: "CYCLED_CORE", label: "Cycled Core", enabled: true },
  { id: "NEAT_CORE", label: "Neat Core", enabled: true },
];

// autogenerated from `generate_asana_characterization_frontend_constants()`
export const ASANA_CHARACTERIZATION_SAMPLE_LOCATION_OPTIONS = [
  // { id: "TEARDOWN_BLUE_CABINET", label: "Teardown Blue Cabinet" },
  // { id: "TEARDOWN_FLEXIBLE_STORAGE", label: "Teardown Flexible Storage" },
  // { id: "CAAS_DROP_OFF_AS", label: "CaaS Drop-Off AS" },
  // { id: "FLEX_STAGING_NAS", label: "Flex Staging NAS" },
  // { id: "CAAS_DROP_OFF_NAS", label: "CaaS Drop-Off NAS" },
  // { id: "ICP_OES_DROP_OFF", label: "ICP-OES Drop-Off" },
  // { id: "WTI_DROP_OFF", label: "WTI Drop Off" },
  // {
  //   id: "MARK_FOR_EXTERNAL_CHARACTERIZATION",
  //   label: "Mark for External Characterization",
  // },
  {
    id: "_30_DANE_TEARDOWN_BLUE_CABINET",
    label: "30 Dane - Teardown Blue Cabinet",
  },
  {
    id: "_30_DANE_TEARDOWN_FLEXIBLE_STORAGE",
    label: "30 Dane - Teardown Flexible Storage",
  },
  { id: "_30_DANE_IN_TRANSIT", label: "30 Dane - In Transit" },
  { id: "_30_DANE_RELA_AS", label: "30 Dane - RELA AS" },
  { id: "_30_DANE_RELA_NAS", label: "30 Dane - RELA NAS" },
  {
    id: "_30_DANE_FLEX_STAGING_NAS_30_DANE",
    label: "30 Dane - Flex Staging NAS - 30 Dane",
  },
  { id: "_30_DANE_FLEX_STAGING_AS", label: "30 Dane - Flex Staging AS" },
  { id: "_30_DANE_WTI_DROP_OFF", label: "30 Dane - WTI Drop Off" },
  { id: "CA_IN_TRANSIT", label: "CA - In Transit" },
  { id: "PA_IN_TRANSIT", label: "PA - In Transit" },
  // { id: "SR_CHEMICAL_STORAGE", label: "S&R Chemical Storage" },
  { id: "_22_KENT_IN_TRANSIT", label: "22 Kent - In Transit" },
  { id: "_200_IB_IN_TRANSIT", label: "200 IB - In Transit" },
  { id: "_200IB_WET_LAB", label: "200IB - Wet Lab" },
  { id: "_200IB_ANALYTICS_LAB", label: "200IB -  Analytics Lab" },
  {
    id: "MARK_FOR_EXTERNAL_CHARACTERIZATION",
    label: "Mark for External Characterization",
  },
  { id: "DISPOSAL_QUEUE", label: "Disposal Queue" },
  // { id: "FLEX_STAGING_AS", label: "Flex Staging AS" },
  // { id: "_200_IB_IN_TRANSIT", label: "200 IB- In Transit" },
  { id: "UNKNOWN", label: "Unknown" },
];

// autogenerated from `generate_asana_characterization_frontend_constants()`
export const ASANA_CHARACTERIZATION_ANALYSIS_TYPE_OPTIONS = [
  { id: "ICP_OES_STD_NEAT_ELYTE", label: "ICP-OES - Std Neat Elyte" },
  { id: "ICP_OES_STD_CYCLED_ELYTE", label: "ICP-OES - Std Cycled Elyte" },
  { id: "ICP_OES_SOLID", label: "ICP-OES - Solid" },
  { id: "ICP_OES_NON_STD", label: "ICP-OES - Non-Std" },
  { id: "XRD_SCAN_ONLY", label: "XRD - Scan Only" },
  {
    id: "XRD_SCAN_CAAS_PHASE_IDENTIFICATION",
    label: "XRD - Scan + CaaS Phase Identification",
  },
  { id: "SEM_SAMPLE_PREP_ONLY", label: "SEM - Sample Prep Only" },
  {
    id: "SEM_SAMPLE_PREP_CAAS_AUTOMATIC_IMAGE_MAPPING",
    label: "SEM - Sample Prep + CaaS Automatic Image Mapping",
  },
  {
    id: "SEM_SAMPLE_PREP_CAAS_ENGINEERING_MICROSCOPY_REQUEST",
    label: "SEM - Sample Prep + CaaS Engineering Microscopy Request",
  },
  { id: "PYC_SKELETAL_DENSITY", label: "PYC - Skeletal Density" },
  { id: "BET_SURFACE_AREA_ANALYSIS", label: "BET Surface Area Analysis" },
  { id: "ELYTE_FTIRREFRACTOMETRY", label: "Elyte - FTIR/Refractometry" },
  { id: "ELYTE_CONDUCTIVITY", label: "Elyte - Conductivity" },
  { id: "ELYTE_VISCOSITY", label: "Elyte - Viscosity" },
  { id: "ELYTE_DENSITY", label: "Elyte - Density" },
  { id: "ELYTE_WTIDENSITY", label: "Elyte - WTI/Density" },
  { id: "CS_CARBON_SULFUR_ANALYSIS", label: "C/S - Carbon Sulfur Analysis" },
  { id: "LASER_CONFOCAL_MICROSCOPE", label: "Laser Confocal Microscope" },
  { id: "FTIR_SPECTROSCOPY", label: "FTIR Spectroscopy" },
  { id: "RAMAN_MICROSCOPY", label: "Raman Microscopy" },
  { id: "OTHER_DISCUSS_WITH_CAAS", label: "Other - Discuss with CaaS" },
  { id: "PSA_PARTICLE_SIZE_ANALYSIS", label: "PSA - Particle Size Analysis" },
  { id: "TGA", label: "TGA" },
  { id: "SEM_RD", label: "SEM - R&D" },
  { id: "XRF", label: "XRF" },
  { id: "TENSILE_TESTING", label: "Tensile Testing" },
  { id: "LINEAR_ABRASION", label: "Linear Abrasion" },
  { id: "CURRENT_COLLECTOR_ADHESION", label: "Current collector adhesion" },
];

// autogenerated from `generate_frontend_constants()`
export const ASANA_CHARACTERIZATION_TASK_TEMPLATE_ID_OPTIONS = [
  {
    id: "GENERAL_EXPXXXXXX_SAMYYYYYY_ZZZZZZ",
    label: "General - EXPXXXXXX_SAMYYYYYY-ZZZZZZ",
  },
  {
    id: "CYCLED_ELYTE_EXPXXXXXX_SAMYYYYYY_ZZZZZZ",
    label: "Cycled Elyte - EXPXXXXXX_SAMYYYYYY-ZZZZZZ",
  },
  {
    id: "ELYTE_QA_SAMXXXXXX_SAMYYYYYY",
    label: "Elyte QA - SAMXXXXXX-SAMYYYYYY",
  },
  { id: "EVT2_ANODE_SAMXXXXXX_YYYYYY", label: "EVT2 Anode - SAMXXXXXX-YYYYYY" },
  {
    id: "CA_MODULE_ELYTE_EXPXXXXXX_SAMYYYYYY_ZZZZZZZ",
    label: "CA Module Elyte - EXPXXXXXX_SAMYYYYYY-ZZZZZZZ",
  },
  {
    id: "REPOLISH_FE_FOILS_EXP104XXX_SAMYYYYYY_ZZZZZZ",
    label: "Repolish Fe Foils - EXP104XXX_SAMYYYYYY-ZZZZZZ",
  },
];
export const SAS_MODULE = "Sas: Module";
export const FSC_BATTERY_MODULE = "FSC: Battery Module";
export const MODULE_TYPE_OPTIONS = [SAS_MODULE, "Sas: Virtual Module"];
