import { forwardRef, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import client from "../../api";
import colors from "../../theme/colors";
import TestStandDropdownFilters from "./TestStandDropdownFilters";
import { uniq } from "lodash";
import { useDebouncedCallback } from "use-debounce";

export type TestStandOptionFilters = Partial<{
  cell_ids: number[];
  h2_sensor_type: string;
  anode_current_sensors: "0" | "1";
  dual_oee: "0" | "1";
  one_half_cell: "0" | "1";
  two_half_cells: "0" | "1";
  proto_1: "0" | "1";
  proto_2: "0" | "1";
  evt: "0" | "1";
  megasus: "0" | "1";
  flex_cell: "0" | "1";
  vehicle_303: "0" | "1";
}>;

type Props = {
  value: TestStand | null;
  onChange: (testStand: TestStand | null) => void;
  onBlur?: () => void;
  error?: boolean;
};

const TestStandDropdown = forwardRef(
  (
    { value, onChange, onBlur, error }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<TestStand[]>([]);
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState<TestStandOptionFilters | null>(null);

    const _handleLookup = useCallback(async () => {
      if (!open || (!search && !filters)) {
        return;
      }

      setLoading(true);

      try {
        let endpoint = `meta/test-stands/advanced?channel__infra_status=${
          "O" as ChannelInfraStatus
        }`;

        if (search) {
          endpoint += `&name__contains=${search}`;
        }

        if (filters) {
          Object.keys(filters).forEach((key) => {
            if (key === "cell_ids") {
              endpoint += filters[key]!.map(
                (cell_id) => `&${key}=${cell_id}`
              ).join("");
            } else {
              endpoint += `&${key}=${
                filters[key as keyof TestStandOptionFilters]
              }`;
            }
          });
        }

        const response: { data: TestStand[] } = await client.get(endpoint);
        setOptions(response.data);
      } catch (err) {
        setOptions([]);
      }

      setLoading(false);
    }, [search, filters, open]);

    const handleLookup = useDebouncedCallback<() => void>(_handleLookup, 400, {
      leading: true,
      trailing: true,
    });

    useEffect(() => {
      if (open) {
        handleLookup();
      }
    }, [open, search, filters, handleLookup]);

    return (
      <div style={{ width: "100%" }}>
        <Autocomplete
          ref={ref}
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.test_stand_id === value.test_stand_id
          }
          value={value?.name ? value : null}
          onChange={(e, data) => {
            setOpen(false);
            onChange(data);
          }}
          loading={loading}
          noOptionsText={
            search || filters
              ? "No results found."
              : "Start typing or select filters..."
          }
          open={open}
          onBlur={onBlur}
          onFocus={() => setOpen(true)}
          PaperComponent={(props) => (
            <TestStandDropdownFilters
              {...props}
              value={filters}
              onChange={setFilters}
            />
          )}
          inputValue={search}
          onInputChange={(e, value) => {
            if (e) {
              setOpen(true);
            }
            setSearch(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                    >
                      {params.InputProps.endAdornment}
                    </span>
                  </>
                ),
                error,
              }}
            />
          )}
          renderOption={(props, opt) => {
            const numActiveTests = uniq(
              opt.channels
                .filter((channel) => channel.cell && !!channel.cell.cell_id)
                .map((activeChannel) => activeChannel.cell.cell_id)
            ).length;
            const numActiveReservations = opt.active_reservations.length;
            const activeDataStrings = [
              `${numActiveTests} active test${numActiveTests === 1 ? "" : "s"}`,
              `${numActiveReservations} reservation${
                numActiveReservations === 1 ? "" : "s"
              }`,
            ];
            return (
              <li {...props}>
                <Box px={4} py={2} width="100%">
                  {opt.name}
                  <em style={{ color: colors.accent.burntYellow }}>
                    {" "}
                    {`(${activeDataStrings.join(", ")})`}
                  </em>
                </Box>
              </li>
            );
          }}
        />
      </div>
    );
  }
);

export default TestStandDropdown;
