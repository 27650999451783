import { forwardRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FlagIcon from "../../../icons/Flag";
import colors, { addAlpha } from "../../../theme/colors";

type Props = {
  ref: React.Ref<HTMLDivElement>;
  step: number;
  activeStep: number;
  title: string;
  flag?: boolean;
  executor?: User | null;
  children: React.ReactNode;
};

const Section = forwardRef(
  (
    { step, activeStep, title, flag, executor, children }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const headerStyle: React.CSSProperties = {
      position: "sticky",
      top: 74,
      zIndex: 1,
    };

    const inView = step === activeStep;

    return (
      <>
        <div
          id={step < 0 ? `tc-section-${step}` : undefined}
          style={{
            width: "calc(100vw - 256px - 3rem)",
            height: 1,
            pointerEvents: "none",
            position: "absolute",
            top: -54,
          }}
        />
        <div style={inView ? headerStyle : {}}>
          <Box style={{ background: colors.body.light }}>
            <Box
              py={3}
              display="flex"
              style={{ background: addAlpha(colors.rules, 0.3) }}
            >
              <Box px={4} pt={2} pr={4}>
                <Typography variant="h3">{title}</Typography>
              </Box>
              {flag || executor ? (
                <>
                  <Divider orientation="vertical" flexItem />
                  {flag ? (
                    <Box ml={4} my="auto">
                      <FlagIcon
                        style={{
                          color: colors.accent.burntYellow,
                          width: 16,
                          marginTop: 4,
                        }}
                      />
                    </Box>
                  ) : null}
                  <Box ml={3} my="auto">
                    {flag ? (
                      <Typography color="textSecondary" className="small">
                        {executor?.name || "The engineer"} will complete{" "}
                        {step === -1 ? "every" : "this"} step
                      </Typography>
                    ) : (
                      <Typography color="textSecondary" className="small">
                        Preferred executor: {executor?.name}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
            <Divider />
          </Box>
        </div>
        <div style={{ position: "relative" }}>
          <Box px={4} py={4}>
            {children}
          </Box>
          <div
            ref={ref}
            style={{
              width: "calc(100vw - 256px - 3rem)",
              height: 1,
              pointerEvents: "none",
              position: "absolute",
              bottom: 69,
            }}
          />
          <div
            id={step >= -1 ? `tc-section-${step + 1}` : undefined}
            style={{
              width: "calc(100vw - 256px - 3rem)",
              height: 1,
              pointerEvents: "none",
              position: "absolute",
              bottom: 49,
            }}
          />
        </div>
      </>
    );
  }
);

export default Section;
