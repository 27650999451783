import { Controller, useFormContext, useWatch } from "react-hook-form";
import type { FieldArrayWithId, FieldPath } from "react-hook-form";
import { useSelector } from "react-redux";
import { isEqual, isNull, isNil, isNumber, toNumber } from "lodash";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import colors from "../../theme/colors";
import ConditionCell from "./ConditionCell";
import InputCellInput from "./InputCellInput";
import BaseTableRow from "./BaseTableRow";
import TdCell from "./TdCell";
import ThCell from "./ThCell";
import { getKeyForItemIndex } from "../../utils/conditions";
import { hasPassedStatus } from "../../utils/statuses";
import type { ConditionState } from "../../features/conditions/slice";
import type { RootState } from "../../store";
import ClockIcon from "../../icons/Clock";
import {
  compareValue,
  defaultValuePresent,
  hasMultipleDefaults,
  isStandardGrouping,
  uiFieldHasDefaults,
} from "../../utils/multiDefaultsUtils";
import { useMemo } from "react";
import { INPUT_INFO_TEXT_BY_FIELD_ID } from "../../utils/labels";
import ExclamationCircleOutlined from "../../icons/ExclamationCircleOutlined";

export const MODAL_MESSAGE_TYPES = ["warning_message"];

type Props = {
  fields: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
  stepId: number;
  uiField: ConditionUIField;
  activeDefaultIndex: number | null;
  onChangeActiveDefaultIndex: (index: null) => void;
  onChangeConfirmationMessages: (fieldKey: string, val: string | null) => void;
  otherUiField?: ConditionUIField;
  allUiFields: ConditionUIField[];
  diffView: boolean;
  editMode: boolean;
  keysToWatchByFullAssembly?: { fieldKey: string; default: any }[];
  overrideDisabled: null | boolean;
};

const TableRow = ({
  fields,
  stepId,
  uiField,
  activeDefaultIndex,
  onChangeActiveDefaultIndex,
  onChangeConfirmationMessages,
  otherUiField,
  allUiFields,
  diffView,
  editMode,
  keysToWatchByFullAssembly = [],
  overrideDisabled = null,
}: Props) => {
  const { control, setValue } = useFormContext<ConditionRHFormFormat>();
  const topLevelId = allUiFields.find(
    (field) => "top_level_hierarchy" in field
  )?.id;
  const { pendingChanges } = useSelector<RootState, ConditionState>(
    ({ condition }) => condition
  );

  let depName: FieldPath<ConditionRHFormFormat>[] = [];
  let parentUiField: ConditionUIField | false | undefined;
  let depWatchKey = "";
  if (uiField.enabled_by) {
    // Special handling for engineer flag
    parentUiField =
      uiField.enabled_by.id.includes("engineer_flag") &&
      allUiFields.find((field) => field.id.includes("engineer_flag"));

    depWatchKey =
      parentUiField && parentUiField.enabled_by
        ? parentUiField.enabled_by.id
        : uiField.enabled_by.id;

    depName = fields.map(
      (field, fieldIndex) =>
        `conditions.${fieldIndex}.items.${stepId}.${depWatchKey}` as FieldPath<ConditionRHFormFormat>
    );
  }

  const depState = useWatch({
    control,
    name: depName,
    disabled: depName.length === 0,
  });

  const allChecked = Object.values(depState).every((val) => !!val === true);
  const allUnchecked = Object.values(depState).every((val) => !!val === false);

  const diffKeys = fields.flatMap((field, fieldIndex) => {
    if (otherUiField) {
      return [
        `conditions.${fieldIndex}.items.${stepId}.${uiField.id}` as FieldPath<ConditionRHFormFormat>,
        `conditions.${fieldIndex}.items.${stepId}.${otherUiField.id}` as FieldPath<ConditionRHFormFormat>,
      ];
    }
    return [
      `conditions.${fieldIndex}.items.${stepId}.${uiField.id}` as FieldPath<ConditionRHFormFormat>,
    ];
  });

  const diffState = useWatch({
    control,
    name: diffKeys,
  });

  // Note that we don't check for otherUiField dependencies for now, given that it's gonna be on the same row no need to duplicate that logic.
  let rowDependencyHidden =
    uiField.enabled_by &&
    !uiField.enabled_by.id.includes("engineer_flag") &&
    (uiField.enabled_by.conditional_val
      ? Object.values(depState).every(
          (val) => val !== uiField.enabled_by!.conditional_val
        )
      : uiField.enabled_by && uiField.enabled_by.conditional_vals
      ? Object.values(depState).every(
          (val) => !uiField.enabled_by!.conditional_vals!.includes(val)
        )
      : uiField.enabled_by.neg
      ? allChecked
      : allUnchecked);

  if (parentUiField && parentUiField.enabled_by) {
    rowDependencyHidden =
      parentUiField.enabled_by &&
      !parentUiField.enabled_by.id.includes("engineer_flag") &&
      (parentUiField.enabled_by.neg ? allChecked : allUnchecked);
  }

  const diffViewHidden =
    diffView &&
    Object.values(diffState).every((val, index) => {
      if (!otherUiField || index % 2 === 0) {
        return isEqual(val, diffState[0]);
      } else {
        return isEqual(val, diffState[1]);
      }
    });

  const rowHidden = rowDependencyHidden || diffViewHidden;

  // Enforce the non-standard flag when changing ASY/Rev from default
  const uiFieldsWithDefaults = useMemo(
    () => allUiFields?.filter(uiFieldHasDefaults),
    [allUiFields]
  );
  const confirmationMsgFields = useMemo(
    () =>
      allUiFields?.filter((uiField_) => uiField_.type === "warning_message"),
    [allUiFields]
  );

  let nonstandardDep: FieldPath<ConditionRHFormFormat>[] = [];
  if (
    uiField.id.includes("non_standard") &&
    (uiFieldsWithDefaults.length > 0 || keysToWatchByFullAssembly.length > 0)
  ) {
    const otherSectionKeys = fields.flatMap((field, fieldIndex) =>
      keysToWatchByFullAssembly.map(
        ({ fieldKey }) =>
          `conditions.${fieldIndex}.${fieldKey}` as FieldPath<ConditionRHFormFormat>
      )
    );

    nonstandardDep = fields
      .flatMap((field, fieldIndex) =>
        uiFieldsWithDefaults.map(
          (uiField) =>
            `conditions.${fieldIndex}.items.${stepId}.${uiField.id}` as FieldPath<ConditionRHFormFormat>
        )
      )
      .concat(otherSectionKeys);
  }

  const nonstandardState = useWatch({
    control,
    name: nonstandardDep,
    disabled: nonstandardDep.length === 0,
  });

  if (MODAL_MESSAGE_TYPES.includes(uiField.type)) {
    return null;
  }

  const isDisabled = (
    status: CellStatus,
    fieldIndex: number,
    value: any,
    entity_type: string
  ) => {
    if (!isNull(overrideDisabled)) {
      return overrideDisabled;
    }

    if (hasPassedStatus("C", status, true) && !editMode) {
      return true;
    }

    if (
      uiField.id.includes("non_standard") &&
      entity_type === "condition_component" &&
      (uiFieldsWithDefaults.length > 0 || keysToWatchByFullAssembly.length > 0)
    ) {
      const disabledByThisSection =
        !!value &&
        uiFieldsWithDefaults.some((_uiField) => {
          const nonStandardStateVal =
            nonstandardState[
              nonstandardDep.indexOf(
                `conditions.${fieldIndex}.items.${stepId}.${_uiField.id}`
              )
            ];
          const inputVal = isNumber(_uiField.default)
            ? toNumber(nonStandardStateVal)
            : nonStandardStateVal;
          return !isEqual(inputVal, _uiField.default);
        });

      if (keysToWatchByFullAssembly.length === 0) {
        return disabledByThisSection;
      }

      const disabledByOtherSections =
        !!value &&
        keysToWatchByFullAssembly.some(({ fieldKey, default: _default }) => {
          const nonStandardStateVal =
            nonstandardState[
              nonstandardDep.indexOf(
                `conditions.${fieldIndex}.${fieldKey}` as FieldPath<ConditionRHFormFormat>
              )
            ];
          if (hasMultipleDefaults(_default)) {
            return !_default
              .map(compareValue)
              .includes(compareValue(nonStandardStateVal));
          } else {
            return (
              defaultValuePresent(_default) &&
              !isEqual(nonStandardStateVal, _default)
            );
          }
        });

      return disabledByThisSection || disabledByOtherSections;
    }

    return false;
  };

  return (
    <BaseTableRow style={{ display: rowHidden ? "none" : undefined }}>
      <ThCell
        style={{ borderTop: `1px solid ${colors.rules}` }}
        required={uiField.required}
        title={`${uiField.label}${uiField.required ? " *" : ""}`}
      >
        {uiField.label} {otherUiField ? "/ Rev" : ""}
      </ThCell>

      {fields.map((field, fieldIndex) => {
        // Required handling
        const dependencyKey =
          `conditions.${fieldIndex}.items.${stepId}.${depWatchKey}` as FieldPath<ConditionRHFormFormat>;
        const thisField = field.items[stepId];

        let cellDependencyHidden =
          uiField.enabled_by &&
          !uiField.enabled_by.id.includes("engineer_flag") &&
          (uiField.enabled_by.neg
            ? depState[depName.indexOf(dependencyKey)]
            : uiField.enabled_by.conditional_val
            ? depState[depName.indexOf(dependencyKey)] !==
              uiField.enabled_by.conditional_val
            : uiField.enabled_by.conditional_vals
            ? !uiField.enabled_by.conditional_vals.includes(
                depState[depName.indexOf(dependencyKey)]
              )
            : !depState[depName.indexOf(dependencyKey)]);

        if (parentUiField && parentUiField.enabled_by) {
          cellDependencyHidden =
            parentUiField.enabled_by &&
            !parentUiField.enabled_by.id.includes("engineer_flag") &&
            (parentUiField.enabled_by.neg
              ? depState[depName.indexOf(dependencyKey)]
              : !depState[depName.indexOf(dependencyKey)]);
        }

        // Nonstandard handling
        const _onChange = (
          id: string,
          args: any,
          _default: any,
          callback: (args: any) => void
        ) => {
          onChangeActiveDefaultIndex(null);

          if (
            id.includes("preferred_executor") ||
            thisField.entity_type === "test_condition"
          ) {
            callback(args);
            return;
          }

          if (
            !isNull(activeDefaultIndex) &&
            !isNil(topLevelId) &&
            "lower_level_hierarchy" in uiField
          ) {
            const key = getKeyForItemIndex(
              topLevelId,
              fieldIndex,
              thisField,
              stepId
            );

            setValue(key, null, { shouldDirty: true });
          }

          let shouldSetFullNonStandard =
            id.includes("non_standard") &&
            thisField.full_dry_assembly_index >= stepId;
          // Handling collections of defaults
          let fieldsWithDefaults = [] as ConditionUIFieldWithVal[];
          if (hasMultipleDefaults(_default)) {
            fieldsWithDefaults = allUiFields.reduce((collection, uiField) => {
              if (hasMultipleDefaults(uiField.default)) {
                const defaults = uiField.default as any[];
                collection.push({
                  ...uiField,
                  default: defaults,
                  value: id === uiField.id ? args : thisField[uiField.id],
                });
              }
              return collection;
            }, fieldsWithDefaults);
          }

          const nonStandardKey = Object.keys(thisField).find((key) =>
            key.includes("non_standard")
          );
          if (
            (hasMultipleDefaults(_default) &&
              !isStandardGrouping(fieldsWithDefaults)) ||
            (!hasMultipleDefaults(_default) &&
              defaultValuePresent(_default) &&
              !isEqual(args, _default) &&
              !!nonStandardKey)
          ) {
            const key = getKeyForItemIndex(
              "non_standard",
              fieldIndex,
              thisField,
              stepId
            );

            setValue(key, true, { shouldDirty: true });
            shouldSetFullNonStandard =
              shouldSetFullNonStandard &&
              thisField.full_dry_assembly_index >= stepId;

            const confirmationMessageFieldForNonStandard =
              confirmationMsgFields.find(
                (msgField) => msgField.enabled_by?.id === nonStandardKey
              );
            if (confirmationMessageFieldForNonStandard) {
              const default_ = confirmationMessageFieldForNonStandard.default;
              const message = Array.isArray(default_) ? default_[0] : default_;
              const msgFieldKey = getKeyForItemIndex(
                confirmationMessageFieldForNonStandard.type,
                fieldIndex,
                thisField,
                stepId
              );
              onChangeConfirmationMessages(msgFieldKey, message as string);
            }
          } else {
            // If new value warrants a confirmation message,
            // send message in onChangeConfirmationMessages payload.
            const confirmationMsgForChangedField = confirmationMsgFields.find(
              (msgField) => msgField.enabled_by?.id === id
            );
            if (confirmationMsgForChangedField) {
              const default_ = confirmationMsgForChangedField.default;
              const message = Array.isArray(default_) ? default_[0] : default_;
              const msgFieldKey = getKeyForItemIndex(
                confirmationMsgForChangedField.type,
                fieldIndex,
                thisField,
                stepId
              );
              // currently just handles if new value is truthy or not,
              // does not check specific args value
              onChangeConfirmationMessages(
                msgFieldKey,
                args ? (message as string) : null
              );
            }
          }

          if (
            shouldSetFullNonStandard &&
            !isNaN(thisField.full_dry_assembly_index)
          ) {
            const fullKey = getKeyForItemIndex(
              "non_standard",
              fieldIndex,
              thisField,
              thisField.full_dry_assembly_index
            );

            setValue(fullKey, true, { shouldDirty: true });
          }

          if (uiField.id.includes("component_used") && !args) {
            const key = getKeyForItemIndex(
              "engineer_flag",
              fieldIndex,
              thisField,
              stepId
            );
            setValue(key, false, { shouldDirty: true });
          }
          callback(args);
        };

        const changeRequestKey = isNaN(thisField.condition_component_id)
          ? thisField.test_condition_id
          : thisField.condition_component_id;

        const itemChanges =
          pendingChanges[`${thisField.entity_type}_${changeRequestKey}`] || {};

        if (!otherUiField) {
          let endAdornment: React.ReactNode | null = null;
          if (uiField.id in INPUT_INFO_TEXT_BY_FIELD_ID) {
            endAdornment = (
              <Tooltip
                arrow
                title={
                  INPUT_INFO_TEXT_BY_FIELD_ID[
                    uiField.id as
                      | "target_thickness"
                      | "target_cold_press_thickness"
                  ]
                }
              >
                <div
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 8,
                  }}
                >
                  <ExclamationCircleOutlined
                    style={{
                      color: colors.text.primary,
                      width: 20,
                      height: 20,
                      transform: "rotate(180deg)",
                    }}
                  />
                </div>
              </Tooltip>
            );
          }
          return (
            <Controller
              key={`${uiField.id}-${field.id}`}
              name={`conditions.${fieldIndex}.items.${stepId}.${uiField.id}`}
              control={control}
              defaultValue={thisField[uiField.id]}
              rules={{
                required: !cellDependencyHidden && uiField.required,
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <TdCell
                  ref={ref}
                  name={name}
                  disabled={isDisabled(
                    field.status,
                    fieldIndex,
                    value,
                    thisField.entity_type
                  )}
                  uiField={uiField}
                  className={`cellStatus-${field.status}`}
                  conditionKey={`conditions.${fieldIndex}.items.${stepId}`}
                  onBlur={onBlur}
                  onChange={(args) =>
                    _onChange(uiField.id, args, uiField.default, onChange)
                  }
                  value={value}
                  pendingChange={itemChanges[uiField.id]}
                  error={invalid}
                  endAdornment={endAdornment}
                />
              )}
            />
          );
        }

        // Note enabled_by won't be enforced here to simplify the logic
        return (
          <ConditionCell
            key={`${uiField.id}-${field.id}`}
            className={`cellStatus-${field.status}`}
          >
            <Controller
              key={`${uiField.id}-${field.id}`}
              name={`conditions.${fieldIndex}.items.${stepId}.${uiField.id}`}
              control={control}
              defaultValue={thisField[uiField.id]}
              rules={{
                required: !cellDependencyHidden && uiField.required,
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <FormControl
                  style={{
                    width: "70%",
                    height: "100%",
                    borderRight: `1px solid ${colors.rules}`,
                  }}
                >
                  <InputCellInput
                    ref={ref}
                    name={name}
                    className={
                      itemChanges[uiField.id] !== undefined ||
                      itemChanges[otherUiField.id] !== undefined
                        ? "pendingChange"
                        : undefined
                    }
                    disableUnderline
                    type="text"
                    onBlur={onBlur}
                    disabled={isDisabled(
                      field.status,
                      fieldIndex,
                      value,
                      thisField.entity_type
                    )}
                    onChange={(e) =>
                      _onChange(
                        uiField.id,
                        e.target.value.toUpperCase(),
                        uiField.default,
                        onChange
                      )
                    }
                    title={isNull(value) ? "" : value}
                    value={isNull(value) ? "" : value}
                    error={invalid}
                  />
                </FormControl>
              )}
            />
            <Controller
              key={`${otherUiField.id}-${field.id}`}
              name={`conditions.${fieldIndex}.items.${stepId}.${otherUiField.id}`}
              control={control}
              defaultValue={thisField[otherUiField.id]}
              rules={{
                required: !cellDependencyHidden && otherUiField.required,
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <FormControl
                  style={{
                    width: "30%",
                    height: "100%",
                    borderRight: `1px solid ${colors.rules}`,
                  }}
                >
                  <InputCellInput
                    ref={ref}
                    name={name}
                    disableUnderline
                    className={
                      itemChanges[uiField.id] !== undefined ||
                      itemChanges[otherUiField.id] !== undefined
                        ? "pendingChange"
                        : undefined
                    }
                    type={
                      otherUiField.type === "string"
                        ? "text"
                        : otherUiField.type
                    }
                    onBlur={onBlur}
                    disabled={isDisabled(
                      field.status,
                      fieldIndex,
                      value,
                      thisField.entity_type
                    )}
                    onChange={(e) =>
                      _onChange(
                        otherUiField.id,
                        e.target.value.toUpperCase(),
                        otherUiField.default,
                        onChange
                      )
                    }
                    title={isNull(value) ? "" : value}
                    value={isNull(value) ? "" : value}
                    error={invalid}
                    endAdornment={
                      itemChanges[uiField.id] !== undefined ||
                      itemChanges[otherUiField.id] !== undefined ? (
                        <Tooltip
                          arrow
                          title={`Pending specification change to "${
                            itemChanges[uiField.id]
                              ? itemChanges[uiField.id]
                              : thisField[uiField.id]
                          } - ${
                            itemChanges[otherUiField.id]
                              ? itemChanges[otherUiField.id]
                              : thisField[otherUiField.id]
                          }"`}
                        >
                          <div className="pendingIconContainer">
                            <ClockIcon />
                          </div>
                        </Tooltip>
                      ) : null
                    }
                  />
                </FormControl>
              )}
            />
          </ConditionCell>
        );
      })}
    </BaseTableRow>
  );
};

export default TableRow;
