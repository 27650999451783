export const addZeroTimeZoneIfAmbiguous = (isoTime: string | null) => {
  if (!isoTime) return null;
  const isoTimeSplit = isoTime.split("T");
  if (
    isoTimeSplit.length < 2 ||
    isoTimeSplit[1].includes("-") ||
    isoTime.endsWith("Z")
  )
    return isoTime;
  return `${isoTime}Z`;
};
