import Badge from "@mui/material/Badge";
import styled from "@mui/styles/styled";
import colors, { addAlpha } from "../theme/colors";

export const NotificationBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: colors.accent.red,
    color: "white",
  },
});

export const RedBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    position: "relative",
    transform: "none",
    marginLeft: "0.5rem",
    flexShrink: 0,
    backgroundColor: addAlpha(colors.accent.red, 0.15),
    color: colors.accent.red,
  },
});

export const GreyBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    position: "relative",
    transform: "none",
    marginLeft: "0.5rem",
    flexShrink: 0,
    backgroundColor: colors.rules,
    color: colors.text.secondary,
  },
});

export const WhiteBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    position: "relative",
    transform: "none",
    marginLeft: "0.5rem",
    flexShrink: 0,
    backgroundColor: "#FFF",
    color: colors.text.secondary,
  },
});
