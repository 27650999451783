import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { GridColDef, GridValidRowModel, useGridApiRef } from "@mui/x-data-grid";
import { useBeforeunload } from "react-beforeunload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Loading from "../../layout/Loading";
import {
  FSC_BATTERY_MODULE,
  cellIdToString,
  cellStatusToString,
} from "../../utils/labels";
import Button from "../../components/Button";
import ArrowBack from "../../icons/ArrowBack";
import { isNumber, uniq } from "lodash";
import Toast from "../../components/Toast";
import AutocompleteEditInputCell from "../batch-external-data-entry/AutocompleteEditIndputCell";
import {
  BatchCellExternalMetadataEntryState,
  getExternalMetadataForCells,
  resetBatchCellExternalMetadataEntry,
  resetSaveIdentifiersForCells,
  saveCellIdentifiers,
} from "./batchCellExternalMetadataEntrySlice";
import BatchExternalDataEntryGrid from "./BatchExternalDataEntryGrid";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 1,
});
export const ALLOWED_CELL_ASSEMBLIES_FOR_EDIT = [
  "FSC: Flex Cell",
  "Full Width HPHC",
  "FSC: Cell",
  "FSC: Hot Soak Flex Cell",
];

export const handleMultiLinePaste = (incomingVal: string) => {
  if (isNumber(incomingVal)) return incomingVal;
  const splitVal = incomingVal.trim().split(/\s/g);
  return splitVal.length > 1 ? splitVal[0] : incomingVal;
};

const ELECTRODE_FIELDS = [
  {
    field: "hca_id",
    headerName: "Anode 1 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_1",
    headerName: "Anode 1 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_2",
    headerName: "Anode 2 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_2",
    headerName: "Anode 2 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_3",
    headerName: "Anode 3 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_3",
    headerName: "Anode 3 Mass (g)",
    width: 148,
    type: "number",
  },
  {
    field: "hca_id_4",
    headerName: "Anode 4 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_4",
    headerName: "Anode 4 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_5",
    headerName: "Anode 5 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_5",
    headerName: "Anode 5 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_6",
    headerName: "Anode 6 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_6",
    headerName: "Anode 6 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  { field: "hot_pocket_id", headerName: "GDE 1 ID" },
  { field: "hot_pocket_2_id", headerName: "GDE 2 ID" },
  { field: "hot_pocket_3_id", headerName: "GDE 3 ID" },
  { field: "oee_1_serial_number", headerName: "OEE 1 ID" },
  { field: "oee_1b_serial_number", headerName: "OEE 1b ID" },
  { field: "oee_2_serial_number", headerName: "OEE 2 ID" },
  { field: "oee_2b_serial_number", headerName: "OEE 2b ID" },
  { field: "oee_3_serial_number", headerName: "OEE 3 ID" },
  { field: "oee_3b_serial_number", headerName: "OEE 3b ID" },
  { field: "oee_4_serial_number", headerName: "OEE 4 ID" },
  { field: "oee_4b_serial_number", headerName: "OEE 4b ID" },
  { field: "oee_5_serial_number", headerName: "OEE 5 ID" },
  { field: "oee_5b_serial_number", headerName: "OEE 5b ID" },
  { field: "oee_6_serial_number", headerName: "OEE 6 ID" },
  { field: "oee_6b_serial_number", headerName: "OEE 6b ID" },
];

const BatchExternalDataEntryLayout = () => {
  const { cell_id_string = "" } = useParams();
  const location = useLocation();
  const { from } = (location.state as LocationState) || {};
  const [allowCellEditing, setAllowCellEditing] = useState(false);
  const [cellRows, setCellRows] = useState<GridValidRowModel[]>([]);

  const {
    cells,
    status: { save: saveCellsMetadataStatus },
    error: { save: saveCellsMetadataError },
  } = useSelector<RootState, BatchCellExternalMetadataEntryState>(
    ({ batchCellExternalMetadata }) => batchCellExternalMetadata
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const editsMade = useMemo(() => {
    if (!cells) return false;
    const entryFields = ELECTRODE_FIELDS.map(
      (electrodeField) => electrodeField.field
    );
    return cellRows.some((cellRow_) => {
      const cellInState = cells.find(
        (cell_) => cell_.cell_id === cellRow_.cell_id
      )!;
      if (cellRow_.serial_number !== cellInState.serial_number) return true;
      return entryFields.some(
        (entryField) =>
          cellRow_[entryField] !==
          cellInState[entryField as keyof CellExternalMetadataEntry]
      );
    });
  }, [cellRows, cells]);

  useBeforeunload((e) => {
    if (editsMade) {
      e.preventDefault();
    }
  });

  const columns: GridColDef[] = [
    { field: "display_cell_id", headerName: "Oak Cell ID", width: 150 },
    {
      field: "status",
      headerName: "Oak Status",
      width: 150,
      valueGetter: (val, row) => cellStatusToString(val),
    },
    {
      field: "cell_condition_name",
      headerName: "Cell Condition Name",
      width: 360,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 484,
      editable: allowCellEditing,
      renderEditCell: (params) => {
        return (
          <AutocompleteEditInputCell
            params={params}
            allElectrodeFields={ELECTRODE_FIELDS}
            value={params.value}
            options={[]}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.external_id
            }
          />
        );
      },
    },
    ...(ELECTRODE_FIELDS.map((electrodeField) => ({
      ...electrodeField,
      editable: allowCellEditing,
      width: electrodeField.width || 172,
      headerAlign: "center",
      align: "center",
      type: electrodeField.type || "string",
      valueParser: (value) => value && handleMultiLinePaste(value),
      valueSetter: (newVal, row) => ({
        ...row,
        [electrodeField.field]: newVal && handleMultiLinePaste(newVal),
      }),
    })) as GridColDef[]),
  ];

  const handleNavigateBack = useCallback(() => {
    const backDestination = from || "/cells/committed";
    if (!cells) {
      navigate(backDestination);
      return;
    }
    if (editsMade && saveCellsMetadataStatus !== "succeeded") {
      if (window.confirm("Are you sure? Changes have not been saved.")) {
        dispatch(resetBatchCellExternalMetadataEntry());
        navigate(backDestination);
        return;
      }
    } else {
      dispatch(resetBatchCellExternalMetadataEntry());
      navigate(backDestination);
    }
  }, [cells, editsMade, dispatch, navigate, saveCellsMetadataStatus, from]);

  useEffect(() => {
    if (!cells && cell_id_string) {
      dispatch(getExternalMetadataForCells(cell_id_string.split(",")));
    }
  }, [dispatch, cells, cell_id_string]);

  useEffect(() => {
    if (cells && cells.length > 0) {
      // if all cells belong to same module, redirect to /metadata/module/:module_id
      if (
        cells.every(
          (cell_) =>
            !!cell_.module_id &&
            cell_.cell_assembly === FSC_BATTERY_MODULE &&
            cell_.module_id === cells[0].module_id
        )
      ) {
        navigate(`/metadata/module/${cells[0].module_id}`);
      }
      // validate incoming cells collection can be edited
      if (
        cells.every((cell_) => {
          return (
            !!cell_.cell_assembly &&
            ALLOWED_CELL_ASSEMBLIES_FOR_EDIT.includes(cell_.cell_assembly) &&
            cell_.cell_assembly === cells[0].cell_assembly
          );
        })
      ) {
        setAllowCellEditing(true);
      }
      setCellRows(
        cells.map((cell_) => ({
          ...cell_,
          display_cell_id: cellIdToString(cell_.cell_id),
          cell_id: cell_.cell_id,
          id: cell_.cell_id,
        }))
      );
    }
  }, [cells, navigate]);

  const cellTypes = useMemo(() => {
    if (!cells || cells.length === 0) {
      return [];
    }
    return uniq(
      cells
        .filter((cell_) => !!cell_.cell_assembly)
        .map((cell_) => cell_.cell_assembly)
    );
  }, [cells]);

  const handleSave = () => {
    dispatch(
      saveCellIdentifiers(
        cellRows.map((cellRow_) => ({
          cell_id: cellRow_.id,
          serial_number: cellRow_.serial_number,
          hca_id: cellRow_.hca_id,
          hca_id_2: cellRow_.hca_id_2,
          hca_id_3: cellRow_.hca_id_3,
          hca_id_4: cellRow_.hca_id_4,
          hca_id_5: cellRow_.hca_id_5,
          hca_id_6: cellRow_.hca_id_6,
          mass_actual: cellRow_.anode_mass_1,
          mass_actual_2: cellRow_.anode_mass_2,
          mass_actual_3: cellRow_.anode_mass_3,
          mass_actual_4: cellRow_.anode_mass_4,
          mass_actual_5: cellRow_.anode_mass_5,
          mass_actual_6: cellRow_.anode_mass_6,
          hot_pocket_id: cellRow_.hot_pocket_id,
          hot_pocket_2_id: cellRow_.hot_pocket_2_id,
          hot_pocket_3_id: cellRow_.hot_pocket_3_id,
          oee_1_serial_number: cellRow_.oee_1_serial_number,
          oee_1b_serial_number: cellRow_.oee_1b_serial_number,
          oee_2_serial_number: cellRow_.oee_2_serial_number,
          oee_2b_serial_number: cellRow_.oee_2b_serial_number,
          oee_3_serial_number: cellRow_.oee_3_serial_number,
          oee_3b_serial_number: cellRow_.oee_3b_serial_number,
          oee_4_serial_number: cellRow_.oee_4_serial_number,
          oee_4b_serial_number: cellRow_.oee_4b_serial_number,
          oee_5_serial_number: cellRow_.oee_5_serial_number,
          oee_5b_serial_number: cellRow_.oee_5b_serial_number,
          oee_6_serial_number: cellRow_.oee_6_serial_number,
          oee_6b_serial_number: cellRow_.oee_6b_serial_number,
        }))
      )
    );
  };
  const savingCellIdentifiers = saveCellsMetadataStatus === "loading";

  if (!cells) {
    return <Loading fullscreen light />;
  }

  return (
    <Box>
      <HeaderContainer
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h2" style={{ marginLeft: "32px" }}>
            <IconButton
              size="large"
              onClick={handleNavigateBack}
              sx={{ paddingTop: "8px" }}
            >
              <ArrowBack />
            </IconButton>
            Edit cell identifier metadata
          </Typography>
        </Box>
        <Box style={{ marginRight: "36px" }}>
          <Button
            color="tertiary"
            type="button"
            size="small"
            disabled={!editsMade}
            style={{
              padding: "0.5rem",
              marginRight: "8px",
            }}
            onClick={handleNavigateBack}
          >
            <b>Cancel</b>
          </Button>
          <Button
            color="primary"
            type="button"
            size="small"
            disabled={!editsMade}
            style={{
              padding: "0.5rem",
            }}
            onClick={handleSave}
          >
            <b>Save</b>
            <>
              {savingCellIdentifiers ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ marginLeft: "8px" }}
                />
              ) : null}
            </>
          </Button>
        </Box>
      </HeaderContainer>
      <Box style={{ padding: "12px 36px 36px 36px" }}>
        <Paper
          variant="outlined"
          square
          sx={{ marginBottom: "12px", minHeight: "36px" }}
        >
          <Box px={3} pt={3} pb={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box marginBottom={2}>
                      <Typography variant="h2">
                        Cell External Metadata Entry
                      </Typography>
                    </Box>
                    <Typography color="textSecondary">
                      {cellTypes.join(", ") || "-"}
                    </Typography>
                    {!allowCellEditing && (
                      <Typography marginTop={2} color="textSecondary">
                        This view only allows editing when all cells are full
                        scale cells that belong to same cell type.{` `}
                        For Battery Module cells, visit the module's experiment
                        details page, then click “Input module metadata”.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <BatchExternalDataEntryGrid
          apiRef={apiRef}
          cellRows={cellRows}
          columns={columns}
          onCellRowsUpdate={(newRows) => setCellRows(newRows)}
        />
      </Box>
      <Toast
        severity="success"
        open={saveCellsMetadataStatus === "succeeded"}
        onClose={() => {
          dispatch(resetSaveIdentifiersForCells());
        }}
      >
        Metadata saved for cells.
      </Toast>
      <Toast
        severity="error"
        open={saveCellsMetadataStatus === "failed"}
        onClose={() => {
          dispatch(resetSaveIdentifiersForCells());
        }}
      >
        {saveCellsMetadataError || "Error saving metadata for cells."}
      </Toast>
    </Box>
  );
};

export default BatchExternalDataEntryLayout;
