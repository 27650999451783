import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import client, { PAGE_SIZE } from "../../api";
import { isoDateToDateString } from "../../utils/labels";
import assembleCellsQuery from "../../utils/assembleCellsQuery";

export type CellFilterArgs = APIListArgs<Cell, CellFilters>;

const getValueFromKey = (cell: Cell, falconKey: FalconKey<Cell>) => {
  if (falconKey === "condition__specified") {
    return isoDateToDateString(cell.condition.specified);
  }

  if (falconKey === "staged") {
    return isoDateToDateString(cell.staged);
  }

  if (falconKey === "committed") {
    return isoDateToDateString(cell.committed);
  }

  if (falconKey === "test__created_time") {
    return isoDateToDateString(cell.test.created_time);
  }

  if (falconKey === "ready_off__completed_at") {
    return isoDateToDateString(cell.ready_off.completed_at);
  }

  const res = falconKey.split("__");
  const key = res[0] as keyof Cell;
  const subKey = res[1];

  if (!subKey) {
    return cell[key];
  }

  // TODO proper typing
  return (cell[key] as any)[subKey];
};

const getBulkValues = (cells: Cell[], falconKey: FalconKey<Cell>) => {
  const array = cells.map((cell) => getValueFromKey(cell, falconKey));
  return new Set<string | number>(array);
};

export const MODULES = "MODULES";
const CELLS = "CELLS";

export interface ModuleState {
  hasMore: boolean;
  args: CellFilterArgs;
  grouping: {
    groupingEnabled: boolean;
    allGroupsExpanded: boolean;
    expandedGroups: (string | number)[];
    expandedConditions: number[];
  };
  selectedView: CustomTableView<Cell> | null;
  selected: number[];
  justFlaggedReadyOff: number[];
  justUnflaggedReadyOff: number[];
  justDiscontinued: number[];
  justUndiscontinued: number[];
  channelUsage: Partial<Record<ChannelAvailability, number>>;
  totals: Partial<Record<CellStatus, number>>;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    remove: "idle" | "loading" | "succeeded" | "failed";
    update: "idle" | "loading" | "succeeded" | "failed";
    create: "idle" | "loading" | "succeeded" | "failed";

    // Cells
    totals: "idle" | "loading" | "succeeded" | "failed";
    list: "idle" | "loading" | "succeeded" | "failed";
    priority: "idle" | "loading" | "succeeded" | "failed";
    readyOff: "idle" | "loading" | "succeeded" | "failed";
    takeOff: "idle" | "loading" | "succeeded" | "failed";
    discontinue: "idle" | "loading" | "succeeded" | "failed";
    channelUsage: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    remove: null | string;
    update: null | string;
    create: null | string;

    // Cells
    totals: null | string;
    list: null | string;
    priority: null | string;
    readyOff: null | string;
    takeOff: null | string;
    discontinue: null | string;
    channelUsage: null | string;
  };
}

export const initialState: ModuleState = {
  hasMore: false,
  args: {
    orderBy: "experiment__exp_id",
    order: "desc",
    page: 0,
    filters: {},
    presetFilters: {},
  },
  grouping: {
    groupingEnabled: false,
    allGroupsExpanded: false,
    expandedGroups: [],
    expandedConditions: [],
  },
  selectedView: null,
  selected: [],
  justFlaggedReadyOff: [],
  justUnflaggedReadyOff: [],
  justDiscontinued: [],
  justUndiscontinued: [],
  channelUsage: {},
  totals: {},
  status: {
    get: "idle",
    remove: "idle",
    update: "idle",
    create: "idle",

    // Cells
    totals: "idle",
    list: "idle",
    priority: "idle",
    readyOff: "idle",
    takeOff: "idle",
    discontinue: "idle",
    channelUsage: "idle",
  },
  error: {
    get: null,
    remove: null,
    update: null,
    create: null,

    // Cells
    totals: null,
    list: null,
    priority: null,
    readyOff: null,
    takeOff: null,
    discontinue: null,
    channelUsage: null,
  },
};

export const addToModule = createAsyncThunk(
  `${MODULES}/list`,
  async (data: any) => {
    const response = await client.post(`meta/modules`, data);
    return { ...response };
  }
);

export const createModule = createAsyncThunk(
  `${MODULES}/create`,
  async (data: any) => {
    const response = await client.post(`meta/modules`, data);
    return { ...response };
  }
);

export const removeFromModule = createAsyncThunk(
  `${MODULES}/remove`,
  async (data: any) => {
    const response = await client.post(`meta/modules/remove`, data);
    return { ...response };
  }
);

export const getCellTotals = createAsyncThunk(`${CELLS}/totals`, async () => {
  const response = await client.get(`meta/cells/counts`);
  return response.counts;
});

export const listCells = createAsyncThunk(
  `${CELLS}/list`,
  async ({
    pageSize = PAGE_SIZE,
    useV2API = false,
    refresh,
    ...args
  }: CellFilterArgs) => {
    let response;
    if (useV2API) {
      response = await client.get(
        `meta/cells?${assembleCellsQuery({
          pageSize,
          refresh,
          ...args,
        })}`,
        { apiVersion: "v2" }
      );
    } else {
      response = await client.get(
        `meta/cells?cells_of_condition=True${assembleCellsQuery({
          pageSize,
          refresh,
          ...args,
        })}`
      );
    }

    return { response, args: { pageSize, ...args }, refresh };
  }
);

export const changePriority = createAsyncThunk(
  `${CELLS}/priority`,
  async ({
    condition_ids,
    priority,
  }: {
    condition_ids: number[];
    priority: number;
  }) => {
    const updates = condition_ids.map((condition_id) => ({
      method: "PUT",
      path: `/api/v1/meta/cell-conditions/${condition_id}/priority`,
      data: { priority },
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    return { errors };
  }
);

export const readyOff = createAsyncThunk(
  `${CELLS}/readyOff`,
  async ({
    cells,
    cancel,
    user_id,
  }: {
    cells: Cell[];
    cancel: boolean;
    user_id?: number;
  }) => {
    const test_meta_ids = cells.map(
      ({ test_meta__test_meta_id }) => test_meta__test_meta_id
    );
    const cell_ids = cells.map(({ cell_id }) => cell_id);

    const updates = test_meta_ids.map((test_meta_id) => ({
      method: "PATCH",
      path: cancel
        ? `/api/v1/meta/test-metas/${test_meta_id}/undo-complete/ready_off`
        : `/api/v1/meta/test-metas/${test_meta_id}/complete/ready_off`,
      data: {
        user_id,
      },
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    return {
      justFlaggedReadyOff: cancel ? [] : cell_ids,
      justUnflaggedReadyOff: cancel ? cell_ids : [],
      errors,
    };
  }
);

export const takeOff = createAsyncThunk(
  `${CELLS}/takeOff`,
  async ({ cells, user_id }: { cells: Cell[]; user_id?: number }) => {
    const cell_ids = cells.map((cell) => cell.cell_id);

    let error: any = "";
    try {
      await client.post("meta/cells/bulk-off-test", { cell_ids: cell_ids });
    } catch (err) {
      error = err;
    }

    return {
      error,
    };
  }
);

export const discontinueCells = createAsyncThunk(
  `${CELLS}/discontinue`,
  async ({ cells, cancel }: { cells: Cell[]; cancel: boolean }) => {
    const cell_ids = cells.map(({ cell_id }) => cell_id);

    const updates = cell_ids.map((cell_id) => ({
      method: "POST",
      path: cancel
        ? `/api/v1/meta/cells/${cell_id}/undo-discontinue`
        : `/api/v1/meta/cells/${cell_id}/discontinue`,
      data: {},
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    return {
      justDiscontinued: cancel ? [] : cell_ids,
      justUndiscontinued: cancel ? cell_ids : [],
      errors,
    };
  }
);

const getChannelUsage = createAsyncThunk(
  `${CELLS}/channelUsage`,
  async (cell_ids: number[]) =>
    client.get(`meta/channels/usage?cell_ids=${cell_ids.join("&cell_ids=")}`)
);

const modulesSlice = createSlice({
  name: MODULES,
  initialState,
  reducers: {
    resetModuleState: (state) => Object.assign(state, initialState),
    resetAddToModuleStatus: (state) => {
      state.status.update = "idle";
      state.error.update = null;
    },
    resetCreateModuleStatus: (state) => {
      state.status.create = "idle";
      state.error.create = null;
    },
    resetRemoveFromModule: (state) => {
      state.status.remove = "idle";
      state.error.remove = null;
    },

    // Cells
    resetCellListState: (state) => Object.assign(state, initialState),
    resetCellListStatus: (state) => {
      state.status.list = "idle";
      state.error.list = null;
    },
    resetPriority: (state) => {
      state.status.priority = "idle";
      state.error.priority = null;
    },
    resetReadyOff: (state) => {
      state.justFlaggedReadyOff = [];
      state.justUnflaggedReadyOff = [];
      state.status.readyOff = "idle";
      state.error.readyOff = null;
    },
    resetTakeOff: (state) => {
      state.status.takeOff = "idle";
      state.error.takeOff = null;
    },
    resetDiscontinue: (state) => {
      state.justDiscontinued = [];
      state.justUndiscontinued = [];
      state.status.discontinue = "idle";
      state.error.discontinue = null;
    },
    resetChannelUsage: (state) => {
      state.channelUsage = {};
      state.status.channelUsage = "idle";
      state.error.channelUsage = null;
    },
    selectView: (
      state,
      { payload: view }: PayloadAction<CustomTableView<Cell> | null>
    ) => {
      state.selectedView = view;
    },
    enableGrouping(state, action: PayloadAction<Cell[]>) {
      state.grouping.groupingEnabled = true;
      state.grouping.allGroupsExpanded = true;
      const bulkValues = getBulkValues(action.payload, state.args.orderBy);
      state.grouping.expandedGroups = Array.from(bulkValues);
    },
    disableGrouping(state) {
      state.grouping.expandedGroups = [];
      state.grouping.groupingEnabled = false;
    },
    expandAllGroups(state, action: PayloadAction<Cell[]>) {
      const bulkValues = getBulkValues(action.payload, state.args.orderBy);
      state.grouping.allGroupsExpanded = true;
      state.grouping.expandedGroups = Array.from(bulkValues);
      const bulkConditions = getBulkValues(
        action.payload,
        "condition__cell_condition_id"
      );
      state.grouping.expandedConditions = Array.from(bulkConditions).map((id) =>
        parseInt(id as string)
      );
    },
    collapseAllGroups(state) {
      state.grouping.expandedGroups = [];
      state.grouping.expandedConditions = [];
      state.grouping.allGroupsExpanded = false;
    },
    expandGroup(state, action: PayloadAction<string | number>) {
      state.grouping.expandedGroups = [
        ...state.grouping.expandedGroups,
        action.payload,
      ];
    },
    collapseGroup(
      state,
      action: PayloadAction<{ value: string | number; cells: Cell[] }>
    ) {
      const value = action.payload.value;
      const cells = action.payload.cells;
      const groups = [...state.grouping.expandedGroups];
      const index = groups.indexOf(value);
      groups.splice(index, 1);
      state.grouping.expandedGroups = groups;
      state.grouping.allGroupsExpanded = false;

      // Collapse inner conditions too
      if (state.grouping.expandedConditions.length > 0) {
        const groupCells = cells.filter(
          (cell) => getValueFromKey(cell, state.args.orderBy) === value
        );
        const conditionIds = uniq(
          groupCells.map((cell) => cell.condition.cell_condition_id!)
        );
        const conditions = [...state.grouping.expandedConditions];
        conditionIds.forEach((id) => {
          const index = conditions.indexOf(id);
          if (index !== -1) {
            conditions.splice(index, 1);
          }
        });
        state.grouping.expandedConditions = conditions;
      }
    },
    expandCondition(state, action: PayloadAction<number>) {
      state.grouping.expandedConditions = [
        ...state.grouping.expandedConditions,
        action.payload,
      ];
    },
    collapseCondition(state, action: PayloadAction<number>) {
      const groups = [...state.grouping.expandedConditions];
      const index = groups.indexOf(action.payload);
      groups.splice(index, 1);
      state.grouping.expandedConditions = groups;
      state.grouping.allGroupsExpanded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createModule.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(createModule.fulfilled, (state, { payload }) => {
        state.status.create = "succeeded";
      })
      .addCase(createModule.rejected, (state, { error }) => {
        state.status.create = "failed";
        state.error.create = error.message as string;
      })
      .addCase(addToModule.pending, (state) => {
        state.status.update = "loading";
      })
      .addCase(addToModule.fulfilled, (state, { payload }) => {
        state.status.update = "succeeded";
      })
      .addCase(addToModule.rejected, (state, { error }) => {
        state.status.update = "failed";
        state.error.update = error.message as string;
      })
      .addCase(removeFromModule.pending, (state) => {
        state.status.remove = "loading";
      })
      .addCase(removeFromModule.fulfilled, (state, { payload }) => {
        state.status.remove = "succeeded";
      })
      .addCase(removeFromModule.rejected, (state, { error }) => {
        state.status.remove = "failed";
        state.error.remove = error.message as string;
      })

      // Cells
      // Cell totals
      .addCase(getCellTotals.pending, (state) => {
        state.status.totals = "loading";
      })
      .addCase(getCellTotals.fulfilled, (state, { payload }) => {
        state.status.totals = "succeeded";
        state.totals = payload;
      })
      .addCase(getCellTotals.rejected, (state, { error }) => {
        state.status.totals = "failed";
        state.error.totals = error.message as string;
      })
      // List Cells
      .addCase(listCells.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(listCells.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status.list = "failed";
          state.error.list = "Unknown error";
          return;
        }

        state.hasMore = payload.response.meta.more;

        // let argsChanged = payload.args.page === 0;
        for (const argKey in payload.args) {
          if (argKey === "page" || argKey === "presetFilters") {
            continue;
          }

          if (argKey === "filters") {
            for (let filterKey in payload.args.filters) {
              const existingFilter =
                state.args.filters[filterKey as keyof CellFilters] || [];
              const newFilter =
                payload.args.filters[filterKey as keyof CellFilters] || [];

              if (!isEqual(existingFilter, newFilter)) {
                // argsChanged = true;
              }
            }
          } else if (
            state.args[argKey as keyof CellFilterArgs] !==
            payload.args[
              argKey as keyof Omit<CellFilterArgs, "refresh" | "useV2API">
            ]
          ) {
            // argsChanged = true;
          }
        }

        state.args = payload.args;
        state.status.list = "succeeded";
      })
      .addCase(listCells.rejected, (state, { error }) => {
        state.status.list = "failed";
        state.error.list = error.message as string;
      })
      .addCase(changePriority.pending, (state) => {
        state.status.priority = "loading";
      })
      .addCase(changePriority.fulfilled, (state, { payload: { errors } }) => {
        if (errors.length === 0) {
          state.status.priority = "succeeded";
        } else {
          state.status.priority = "failed";
          state.error.priority = errors.join(" ");
        }
      })
      .addCase(changePriority.rejected, (state, { error }) => {
        state.status.priority = "failed";
        state.error.priority = error.message as string;
      })
      .addCase(readyOff.pending, (state) => {
        state.status.readyOff = "loading";
      })
      .addCase(
        readyOff.fulfilled,
        (
          state,
          { payload: { justFlaggedReadyOff, justUnflaggedReadyOff, errors } }
        ) => {
          if (errors.length === 0) {
            state.status.readyOff = "succeeded";
            state.justFlaggedReadyOff = justFlaggedReadyOff;
            state.justUnflaggedReadyOff = justUnflaggedReadyOff;
          } else {
            state.status.readyOff = "failed";
            state.error.readyOff = errors.join(" ");
          }
        }
      )
      .addCase(readyOff.rejected, (state, { error }) => {
        state.status.readyOff = "failed";
        state.error.readyOff = error.message as string;
      })
      .addCase(discontinueCells.pending, (state) => {
        state.status.discontinue = "loading";
      })
      .addCase(
        discontinueCells.fulfilled,
        (
          state,
          { payload: { justDiscontinued, justUndiscontinued, errors } }
        ) => {
          if (errors.length === 0) {
            state.status.discontinue = "succeeded";
            state.justDiscontinued = justDiscontinued;
            state.justUndiscontinued = justUndiscontinued;
          } else {
            state.status.discontinue = "failed";
            state.error.discontinue = errors.join(" ");
          }
        }
      )
      .addCase(discontinueCells.rejected, (state, { error }) => {
        state.status.discontinue = "failed";
        state.error.discontinue = error.message as string;
      })
      .addCase(takeOff.pending, (state) => {
        state.status.discontinue = "loading";
      })
      .addCase(takeOff.fulfilled, (state, { payload: { error } }) => {
        if (error.length === 0) {
          state.status.takeOff = "succeeded";
        } else {
          state.status.takeOff = "failed";
          state.error.takeOff = error;
        }
      })
      .addCase(takeOff.rejected, (state, { error }) => {
        state.status.discontinue = "failed";
        state.error.discontinue = error.message as string;
      })
      .addCase(getChannelUsage.pending, (state) => {
        state.status.channelUsage = "loading";
      })
      .addCase(getChannelUsage.fulfilled, (state, { payload }) => {
        state.status.channelUsage = "succeeded";
        state.channelUsage = payload;
      })
      .addCase(getChannelUsage.rejected, (state, { error }) => {
        state.status.channelUsage = "failed";
        state.error.channelUsage = error.message as string;
      });
  },
});

export const {
  resetAddToModuleStatus,
  resetCreateModuleStatus,
  resetRemoveFromModule,
  resetCellListState,
  resetCellListStatus,
  enableGrouping,
  selectView,
  disableGrouping,
  resetPriority,
  resetReadyOff,
  resetDiscontinue,
  resetTakeOff,
  collapseAllGroups,
  expandAllGroups,
  expandGroup,
  collapseGroup,
  expandCondition,
  collapseCondition,
} = modulesSlice.actions;
export default modulesSlice.reducer;
