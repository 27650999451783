import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Chip from "../Chip";
import Button from "../Button";
import FilterTextIcon from "../../icons/FilterText";
import SearchIcon from "../../icons/Search";
import TimesIcon from "../../icons/Times";
import { expFilterToString } from "../../utils/labels";
import type { RootState } from "../../store";
import {
  listSavedFilters,
  ExperimentSavedFilterState,
} from "../../features/experiments/filtersSlice";

type Props = {
  onSelect: (filters: ExperimentFilters) => void;
};

const ButtonSavedExperimentFilters = ({ onSelect }: Props) => {
  const {
    savedFilters,
    status: { list: status },
  } = useSelector<RootState, ExperimentSavedFilterState>(
    ({ experimentFilters }) => experimentFilters
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(savedFilters);

  useEffect(() => {
    if (status === "idle") {
      dispatch(listSavedFilters());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setFilters(savedFilters);
  }, [savedFilters]);

  useEffect(() => {
    setFilters(
      savedFilters.filter(({ title }) =>
        title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, savedFilters]);

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  return (
    <>
      <Chip
        clickable
        onClick={handleButtonClick}
        icon={<FilterTextIcon />}
        label="Saved Filters"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: 640 },
        }}
      >
        <Box pt={6} px={6} display="flex" alignItems="center">
          <FormControl style={{ width: "75%" }}>
            <Input
              disableUnderline
              placeholder="Filter name"
              startAdornment={
                <Box pl={2}>
                  <SearchIcon />
                </Box>
              }
              endAdornment={
                search ? (
                  <IconButton size="small" onClick={() => setSearch("")}>
                    <TimesIcon fontSize="small" />
                  </IconButton>
                ) : null
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </FormControl>
          <Box ml="auto">
            <Button
              color="tertiary"
              size="small"
              onClick={() => navigate("/experiments/filters")}
            >
              <b>View all</b>
            </Button>
          </Box>
        </Box>
        <Box pt={2} px={6} pb={4}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Filter name</TableCell>
                  <TableCell>Filters applied</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filters.slice(0, 10).map(({ title, data }, i) => (
                  <TableRow
                    key={i}
                    hover
                    className="clickable"
                    onClick={() => {
                      onSelect(data);
                      setButtonEl(null);
                    }}
                  >
                    <TableCell>{title}</TableCell>
                    <TableCell>{expFilterToString(data)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Popover>
    </>
  );
};

export default ButtonSavedExperimentFilters;
