import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { expIdToString } from "../utils/labels";
import Button from "./Button";
import ArrowForwardIcon from "../icons/ArrowForward";

type Props = {
  exp_id: number;
  link: string;
  description?: string;
  linkView?: boolean;
};

const ExperimentLink = ({ exp_id, link, description, linkView }: Props) => {
  const [buttonEl, setButtonEl] = useState<
    null | HTMLButtonElement | HTMLAnchorElement
  >(null);
  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement
    >
  ) => {
    event.stopPropagation();
    setButtonEl(null);
  };
  const navigate = useNavigate();

  return (
    <>
      {linkView ? (
        <MuiLink color="secondary" onClick={handleButtonClick}>
          {description ? (
            <Tooltip arrow placement="right" title={description}>
              <span>{expIdToString(exp_id)}</span>
            </Tooltip>
          ) : (
            expIdToString(exp_id)
          )}
        </MuiLink>
      ) : (
        <Button color="tertiary" size="small" onClick={handleButtonClick}>
          {description ? (
            <Tooltip arrow placement="right" title={description}>
              <span>{expIdToString(exp_id)}</span>
            </Tooltip>
          ) : (
            expIdToString(exp_id)
          )}
        </Button>
      )}

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleButtonClose(e);
            navigate(`/experiments/${exp_id}`);
          }}
        >
          EXP details
          <ListItemIcon style={{ flexGrow: 1 }}>
            <ArrowForwardIcon
              style={{ width: 14, height: 14, marginLeft: "auto" }}
            />
          </ListItemIcon>
        </MenuItem>
        {link ? (
          <MenuItem
            onClick={(e) => {
              handleButtonClose(e);
              window.open(link);
            }}
          >
            EXP doc
            <ListItemIcon style={{ flexGrow: 1 }}>
              <ArrowForwardIcon
                style={{ width: 14, height: 14, marginLeft: "auto" }}
              />
            </ListItemIcon>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default ExperimentLink;
