import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import ConditionCell from "./ConditionCell";
import TextareaCell from "./TextareaCell";
import Modal from "../Modal";
import BaseTableRow from "./BaseTableRow";
import ThCell from "./ThCell";
import Button from "../Button";
import CheckboxCell from "./CheckboxCell";
import {
  ConditionState,
  previewConditionChanges,
  resetSaveCondition,
  saveConditions,
} from "../../features/conditions/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getMetadata, MetadataState } from "../../features/metadata/slice";
import { CircularProgress, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { AuthState } from "../../store/auth/slice";
import { hasPassedStatus } from "../../utils/statuses";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editConditionPath } from "../../utils/urlPathHelpers";
import UserCell from "./UserCell";
import parseCellIdString from "../../utils/parseCellIdString";

type Props = {
  className?: string;
  uiField: ConditionUIField;
};

const TeardownUpdateFlagSpecCell = ({ className, uiField }: Props) => {
  const { cell_id_string = "" } = useParams();
  const { ui } = useSelector<RootState, MetadataState>(
    ({ metadata }) => metadata
  );
  const { api_path, label, cell_condition_id } = uiField;
  const {
    allAutoApproveFields,
    status: { preview: previewStatus, save: saveConditionStatus },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const [open, setOpen] = useState(false);
  const [conditionMeta, setConditionMeta] = useState<null | ConditionMetadata>(
    null
  );
  const [flagFormState, setFlagFormState] = useState<DynamicFormValues>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (ui && !conditionMeta) {
      const conditionMetadata = ui?.find(
        (ui) => ui.cell_condition_id === cell_condition_id
      );
      if (conditionMetadata)
        setConditionMeta(conditionMetadata as ConditionMetadata);
    }
  }, [ui, conditionMeta, cell_condition_id]);

  useEffect(() => {
    if (open && conditionMeta && api_path) {
      dispatch(
        previewConditionChanges({
          conditions: [
            {
              ...conditionMeta.condition,
              items: {
                "1": { api_path },
              },
            },
          ],
        })
      );
    }
  }, [open, conditionMeta, dispatch, api_path]);

  const teardownFields = conditionMeta?.spec.find(
    (specField) => specField.title === "Teardown"
  );
  useEffect(() => {
    if (isEmpty(flagFormState) && teardownFields?.values) {
      const persistedFlagFormState: DynamicFormValues = {};
      allAutoApproveFields.forEach((fieldId: string) => {
        persistedFlagFormState[fieldId] = teardownFields.values![
          fieldId
        ] as string;
      });
      setFlagFormState(persistedFlagFormState);
    }
    // eslint-disable-next-line
  }, [allAutoApproveFields, teardownFields]);

  useEffect(() => {
    if (saveConditionStatus === "succeeded" && open && conditionMeta) {
      const { conditionIdsToRequestedCellIds } =
        parseCellIdString(cell_id_string);
      dispatch(getMetadata({ conditionIdsToRequestedCellIds }));
      setOpen(false);
      dispatch(resetSaveCondition());
    }
  }, [saveConditionStatus, conditionMeta, dispatch, open, cell_id_string]);

  const showTeardownUpdateForm =
    allAutoApproveFields && !isEmpty(flagFormState) && teardownFields;
  const enableTeardownUpdateButton =
    conditionMeta &&
    (user?.role === "O" || !hasPassedStatus("F", conditionMeta.status));

  const renderUpdateTeardownFlagsButton = () => (
    <Button
      color="tertiary"
      type="button"
      size="small"
      disabled={!enableTeardownUpdateButton}
      style={{
        padding: "0.5rem",
      }}
      onClick={() => setOpen(true)}
    >
      <b>{label}</b>
    </Button>
  );
  const renderEditConditionPageButton = () => (
    <Button
      color="tertiary"
      type="button"
      size="small"
      disabled={!conditionMeta}
      style={{
        padding: "0.5rem",
      }}
      onClick={() =>
        navigate(
          editConditionPath(
            conditionMeta!.exp_id,
            conditionMeta!.cell_condition_id
          ),
          { state: { from: pathname } }
        )
      }
    >
      <b>Edit condition specification</b>
    </Button>
  );

  return (
    <>
      <ConditionCell className={className}>
        <Box
          px={4}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Tooltip
            arrow
            placement="left"
            title="A cell in this condition specification may have started being torn down. Teardown specification changes will need to go through a change request process. Please proceed to ‘Edit condition specification’."
            disableHoverListener={!!enableTeardownUpdateButton}
            disableFocusListener={!!enableTeardownUpdateButton}
          >
            <div>
              {enableTeardownUpdateButton
                ? renderUpdateTeardownFlagsButton()
                : renderEditConditionPageButton()}
            </div>
          </Tooltip>
        </Box>
      </ConditionCell>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box mb={6}>
          <Typography variant="h2">{label}</Typography>
        </Box>
        {previewStatus === "loading" ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table size="small">
              <TableBody>
                {showTeardownUpdateForm
                  ? allAutoApproveFields.map((fieldId) => {
                      const teardownField = teardownFields.fields?.find(
                        ({ id }) => id === fieldId
                      );
                      if (
                        !teardownField ||
                        (teardownField.enabled_by &&
                          !flagFormState[teardownField.enabled_by.id])
                      )
                        return null;
                      const dependantFields = teardownFields.fields?.filter(
                        (teardownField) =>
                          teardownField.enabled_by?.id === fieldId
                      );
                      const teardownFieldVal =
                        flagFormState[teardownField.id as string];
                      return (
                        <BaseTableRow key={fieldId}>
                          <ThCell title={teardownField.label}>
                            {teardownField.label}
                          </ThCell>
                          {["bool", "boolean"].includes(teardownField.type) ? (
                            <CheckboxCell
                              className={className}
                              checked={Boolean(teardownFieldVal)}
                              onChange={() => {
                                const newVal =
                                  !flagFormState![teardownField.id];
                                let nullifiedDependantFields =
                                  {} as DynamicFormValues;
                                if (!newVal) {
                                  dependantFields?.forEach((teardownField) => {
                                    nullifiedDependantFields[teardownField.id] =
                                      null;
                                  });
                                }
                                const updatedFlagFieldsState = {
                                  ...flagFormState,
                                  ...nullifiedDependantFields,
                                  [teardownField.id]: newVal,
                                };
                                setFlagFormState(updatedFlagFieldsState);
                              }}
                            />
                          ) : teardownField.type === "user_options" ? (
                            <UserCell
                              onChange={(selectedUser) => {
                                const updatedFlagFieldsState = {
                                  ...flagFormState,
                                  [teardownField.id]: selectedUser,
                                };
                                setFlagFormState(updatedFlagFieldsState);
                              }}
                              value={(teardownFieldVal as User) || null}
                              clearable={true}
                            />
                          ) : (
                            <TextareaCell
                              className={className}
                              value={teardownFieldVal || ""}
                              onChange={(e) => {
                                const updatedFlagFieldsState = {
                                  ...flagFormState,
                                  [teardownField.id]: e.target.value,
                                };
                                setFlagFormState(updatedFlagFieldsState);
                              }}
                            />
                          )}
                        </BaseTableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {showTeardownUpdateForm ? (
          <Box mt={6} textAlign="right">
            <Button
              size="small"
              color="primary"
              onClick={() => {
                const updatePayload = Object.keys(flagFormState).reduce(
                  (existingObj, flagFormStateKey) => {
                    if (
                      teardownFields.values![flagFormStateKey] ===
                      flagFormState[flagFormStateKey]
                    )
                      return existingObj;
                    return {
                      ...existingObj,
                      [flagFormStateKey]: flagFormState[flagFormStateKey],
                    };
                  },
                  {}
                );
                dispatch(
                  saveConditions({
                    conditions: [
                      {
                        ...conditionMeta!.condition,
                        items: {
                          "1": {
                            api_path,
                            ...updatePayload,
                          },
                        },
                      },
                    ],
                    exp_id: `${conditionMeta!.exp_id}`,
                  })
                );
              }}
              disabled={
                ["succeeded", "loading"].includes(saveConditionStatus) ||
                Object.keys(flagFormState).every(
                  (flagKey) =>
                    teardownFields.values![flagKey] === flagFormState[flagKey]
                )
              }
            >
              Save
            </Button>
          </Box>
        ) : null}
      </Modal>
    </>
  );
};

export default TeardownUpdateFlagSpecCell;
