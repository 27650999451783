import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import colors from "../../theme/colors";
import ExclamationCircleOutlinedIcon from "../../icons/ExclamationCircleOutlined";
import type { RootState } from "../../store";
import type { SignalMapState } from "./slice";

const SignalMapSummary = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(min-width:760px)");
  const { signalMap, dutType, allTestData, isMostRecentSignalMap } =
    useSelector<RootState, SignalMapState>(
      ({ signalMapSingle }) => signalMapSingle
    );
  let isModuleSignalMap = useMemo(() => dutType === "module", [dutType]);
  let colSpacing = useMemo(
    () => (isModuleSignalMap ? 6 : 10),
    [isModuleSignalMap]
  );

  return (
    <Box px={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Summary</Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography color="textSecondary">Test ID</Typography>
            </Grid>
            <Grid item xs={colSpacing}>
              <Box display="flex" alignItems="left">
                {signalMap && Object.keys(allTestData).length > 0 ? (
                  <Box display="flex" alignItems="center" width="100%">
                    <Select
                      value={signalMap.test_id}
                      onChange={(e) => {
                        navigate(
                          `/signal-maps/${
                            dutType === "cell" ? "cell" : "module"
                          }s/${signalMap.dut_id}?test-id=${e.target.value}`
                        );
                        window.location.reload();
                      }}
                      style={{ width: "400px" }}
                    >
                      {Object.keys(allTestData).map((key) => (
                        <MenuItem key={key} value={key}>
                          {allTestData[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    {!isMostRecentSignalMap && (
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          "You are viewing/editing a prior version of the signal map."
                        }
                      >
                        <span>
                          <ExclamationCircleOutlinedIcon
                            style={{
                              marginLeft: "10px",
                              color: colors.accent.red,
                            }}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                ) : (
                  "-"
                )}
              </Box>
            </Grid>

            {isModuleSignalMap && (
              <>
                <Grid item xs={3}>
                  <Typography color="textSecondary">
                    Over Voltage Protection (V)
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="left">
                    <Typography>
                      {signalMap && signalMap.over_voltage_protection
                        ? signalMap.over_voltage_protection
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={2}>
              <Typography color="textSecondary">Oak Test Status</Typography>
            </Grid>
            <Grid item xs={colSpacing}>
              <Box display="flex" alignItems="left">
                <Typography>
                  {signalMap
                    ? signalMap.test_id !== null
                      ? signalMap.is_on_test
                        ? "On Test"
                        : "Off Test"
                      : "N/A"
                    : "-"}
                </Typography>
              </Box>
            </Grid>

            {isModuleSignalMap && (
              <>
                <Grid item xs={3}>
                  <Typography color="textSecondary">
                    Over Current Protection (A)
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="left">
                    <Typography>
                      {signalMap && signalMap.over_current_protection
                        ? signalMap.over_current_protection
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={2}>
              <Typography color="textSecondary">Signal Count</Typography>
            </Grid>
            <Grid item xs={colSpacing}>
              <Box display="flex" alignItems="left">
                <Typography>
                  {signalMap ? signalMap.signal_count : "-"}
                </Typography>
              </Box>
            </Grid>

            {isModuleSignalMap && (
              <>
                <Grid item xs={3}>
                  <Typography color="textSecondary">
                    Over Power Protection (W)
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="left">
                    <Typography>
                      {signalMap && signalMap.over_power_protection
                        ? signalMap.over_power_protection
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={2}>
              <Typography color="textSecondary">Device Name(s)</Typography>
            </Grid>
            <Grid item xs={colSpacing}>
              <Box display="flex" alignItems="left">
                {signalMap ? (
                  signalMap.device_names === "-" ? (
                    <Tooltip
                      arrow
                      placement="right"
                      title={`Device names are only available once the ${dutType} has a reserved channel or is on-test`}
                    >
                      <Typography>-</Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{signalMap.device_names}</Typography>
                  )
                ) : (
                  "-"
                )}
              </Box>
            </Grid>

            {isModuleSignalMap && (
              <>
                <Grid item xs={3}>
                  <Typography color="textSecondary">1-Wire Slots</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="left">
                    <Typography>
                      {signalMap && signalMap.one_wire_slots
                        ? signalMap.one_wire_slots
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={2}>
              <Typography color="textSecondary">Addresses</Typography>
            </Grid>
            <Grid item xs={colSpacing}>
              <Box display="flex" alignItems="left">
                <Typography>{signalMap ? signalMap.addresses : "-"}</Typography>
              </Box>
            </Grid>

            {isModuleSignalMap && (
              <>
                <Grid item xs={3}>
                  <Typography color="textSecondary">
                    RTD Resistance (ohms)
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="left">
                    <Typography>
                      {signalMap && signalMap.rtd_resistance
                        ? signalMap.rtd_resistance
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SignalMapSummary;
