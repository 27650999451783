import { Navigate, Route, Routes } from "react-router-dom";

import NavHeader from "../components/NavHeader";
import ExperimentTabs from "../components/ExperimentTabs";
import InfrastructureTabs from "../components/InfrastructureTabs";
import SingleCellLayout from "../features/cells/Single";
import SingleChannelLayout from "../features/channels/SingleChannelLayout";
import SingleExperimentLayout from "../features/experiments/Single";
import CreateExperimentLayout from "../features/experiments/Create";
import ConditionLayout from "../features/conditions";
import CyclingProtocolsLayout from "../features/cycling-protocols/CyclingProtocolsLayout";
import SingleCyclingProtocolLayout from "../features/cycling-protocols/SingleCyclingProtocolLayout";
import MetadataLayout from "../features/metadata";
import ChangeRequests from "../features/change-requests";
import SingleChangeRequestLayout from "../features/change-requests/Single";
import SavedCellFilters from "./SavedCellFilters";
import SavedExperimentFilters from "./SavedExperimentFilters";
import SavedChannelFilters from "./SavedChannelFilters";
import NewSamplesLayout from "../features/characterization/layout/NewSamplesLayout";
import ExistingSamplesLayout from "../features/characterization/layout/ExistingSamplesLayout";
import EditSamplesLayout from "../features/characterization/layout/EditSamplesLayout";
import CharacterizationTabs from "../components/CharacterizationTabs";
import SingleTestStandLayout from "../features/test-stands/SingleTestStandLayout";
import SignalMapLayout from "../features/signal-maps/SignalMapLayout";
import ModuleMetadataLayout from "../features/module-metadata";
import BatchCellExternalMetadataEntryLayout from "../features/batch-external-data-entry";
import NewConditionsCreationForms from "../features/batch-conditions/NewConditionsCreationForms";
import BatchCellConditionEdit from "../features/batch-conditions/BatchCellConditionEdit";

const Main = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/experiments" />} />
    <Route path="/experiments/create" element={<CreateExperimentLayout />} />
    <Route
      path="/experiments/filters"
      element={
        <>
          <NavHeader />
          <SavedExperimentFilters />
        </>
      }
    />
    <Route
      path="/experiments/:exp_id/conditions/new"
      element={<ConditionLayout isNew />}
    />
    <Route
      path="/experiments/:exp_id/batch-conditions/new"
      element={<NewConditionsCreationForms />}
    />
    <Route
      path="/experiments/:exp_id/batch-conditions/:cell_condition_id_string"
      element={<BatchCellConditionEdit />}
    />
    <Route
      path="/experiments/:exp_id/conditions"
      element={<ConditionLayout />}
    />
    <Route
      path="/experiments/:exp_id/conditions/:cell_condition_id"
      element={<ConditionLayout />}
    />
    <Route
      path="/metadata/module/:module_id"
      element={<ModuleMetadataLayout />}
    />
    <Route
      path="/metadata/batch-external-data-entry/:cell_id_string"
      element={<BatchCellExternalMetadataEntryLayout />}
    />
    <Route path="/metadata/:cell_id_string" element={<MetadataLayout />} />
    <Route
      path="/experiments/:exp_id"
      element={
        <>
          <NavHeader />
          <SingleExperimentLayout />
        </>
      }
    />
    <Route
      path="/experiments"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="ALL_EXPERIMENTS" />
        </>
      }
    />
    <Route
      path="/cells/filters"
      element={
        <>
          <NavHeader />
          <SavedCellFilters />
        </>
      }
    />
    <Route
      path="/cells/specified"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="SPECIFIED_CELLS" />
        </>
      }
    />
    <Route
      path="/cells/staged"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="STAGED_CELLS" />
        </>
      }
    />
    <Route
      path="/cells/committed"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="COMMITTED_CELLS" />
        </>
      }
    />
    <Route
      path="/cells/testing"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="TESTING_CELLS" />
        </>
      }
    />

    <Route
      path="/cells/:cell_id/notes/:note_id"
      element={
        <>
          <NavHeader />
          <SingleCellLayout notesOpen />
        </>
      }
    />
    <Route
      path="/cells/:cell_id/notes"
      element={
        <>
          <NavHeader />
          <SingleCellLayout notesOpen />
        </>
      }
    />
    <Route
      path="/cells/:cell_id"
      element={
        <>
          <NavHeader />
          <SingleCellLayout />
        </>
      }
    />
    <Route
      path="/cells"
      element={
        <>
          <NavHeader />
          <ExperimentTabs selectedTab="ALL_CELLS" />
        </>
      }
    />
    <Route
      path="/change-requests/complete"
      element={
        <>
          <NavHeader />
          <ChangeRequests selectedTab="COMPLETE" />
        </>
      }
    />
    <Route
      path="/change-requests/:cell_condition_id"
      element={
        <>
          <NavHeader />
          <SingleChangeRequestLayout />
        </>
      }
    />
    <Route
      path="/change-requests"
      element={
        <>
          <NavHeader />
          <ChangeRequests selectedTab="PENDING" />
        </>
      }
    />
    <Route
      path="/characterization"
      element={
        <>
          <NavHeader />
          <CharacterizationTabs selectedTab="ALL_SAMPLES" />
        </>
      }
    />
    <Route
      path="/characterization/new-samples"
      element={
        <>
          <NewSamplesLayout />
        </>
      }
    />
    <Route
      path="/characterization/edit-samples"
      element={
        <>
          <EditSamplesLayout />
        </>
      }
    />
    <Route
      path="/characterization/samples"
      element={
        <>
          <ExistingSamplesLayout />
        </>
      }
    />
    <Route
      path="/infrastructure"
      element={<Navigate to="/infrastructure/channels" />}
    />
    <Route
      path="/infrastructure/channels/filters"
      element={
        <>
          <NavHeader />
          <SavedChannelFilters />
        </>
      }
    />
    <Route
      path="/infrastructure/channels"
      element={
        <>
          <NavHeader />
          <InfrastructureTabs selectedTab="CHANNELS" />
        </>
      }
    />
    <Route
      path="/infrastructure/channels/:channel_id"
      element={
        <>
          <NavHeader />
          <SingleChannelLayout />
        </>
      }
    />
    <Route
      path="/infrastructure/test-stands"
      element={
        <>
          <NavHeader />
          <InfrastructureTabs selectedTab="TEST_STANDS" />
        </>
      }
    />
    <Route
      path="/infrastructure/test-stands/:test_stand_id"
      element={
        <>
          <NavHeader />
          <SingleTestStandLayout />
        </>
      }
    />
    <Route
      path="/signal-maps/cells/:dut_id"
      element={
        <>
          <NavHeader />
          <SignalMapLayout dutType="cell" />
        </>
      }
    />
    <Route
      path="/signal-maps/modules/:dut_id"
      element={
        <>
          <NavHeader />
          <SignalMapLayout dutType="module" />
        </>
      }
    />
    <Route
      path="/cycling-protocols/"
      element={
        <>
          <NavHeader />
          <CyclingProtocolsLayout />
        </>
      }
    />
    <Route
      path="/cycling-protocols/:cycling_protocol_id"
      element={
        <>
          <NavHeader />
          <SingleCyclingProtocolLayout />
        </>
      }
    />
  </Routes>
);

export default Main;
