import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRow,
  GridRowProps,
  GridValidRowModel,
  RowPropsOverrides,
} from "@mui/x-data-grid";
import { isBoolean, isNil, isNull, isString } from "lodash";
import {
  CONDITION_NAME_COLUMN_WIDTH,
  OVERVIEW_SCREEN,
} from "./BatchCellConditionEdit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { BatchConditionsSpecificationsState } from "./batchConditionsSpecificationsSlice";
import { readCSVFromClipboardData } from "../../utils/batchEntryUtils";
import colors from "../../theme/colors";
import Toast from "../../components/Toast";

const ConditionRow = (props: GridRowProps & RowPropsOverrides) => {
  const [conditionNameLeftPositionPx, setConditionNameLeftPositionPx] =
    useState<number | null>(null);
  const maxWidth = CONDITION_NAME_COLUMN_WIDTH * 0.6;
  const { activeScreen, getScrollPosition, ...domProps } = props;
  const renderConditionNameBox = () =>
    !!conditionNameLeftPositionPx ? (
      <Box
        sx={{
          position: "absolute",
          left: `${conditionNameLeftPositionPx}px`,
          color: colors.text.secondary,
          display: "flex",
          height: "52px",
          padding: "0 12px",
          maxWidth,
          zIndex: 3,
        }}
      >
        <span className="overflow-ellipsis">{props.row.name}</span>
      </Box>
    ) : null;

  const handleHoverEnter = () => {
    if (activeScreen === OVERVIEW_SCREEN) return;
    const scrollPos: { top: number; left: number } = getScrollPosition();
    const conditionNameLikelyHidden =
      scrollPos.left > CONDITION_NAME_COLUMN_WIDTH * 0.9;
    if (conditionNameLikelyHidden) {
      setConditionNameLeftPositionPx(scrollPos.left);
    }
  };
  const handleHoverOut = () => {
    if (Number.isInteger(conditionNameLeftPositionPx)) {
      setConditionNameLeftPositionPx(null);
    }
  };

  return (
    <Box onMouseOver={handleHoverEnter} onMouseLeave={handleHoverOut}>
      {renderConditionNameBox()}
      <Box sx={{ position: "relative", zIndex: 5 }}>
        <GridRow {...domProps} />
      </Box>
    </Box>
  );
};

export interface MultiDefaultsCollection {
  [fieldKey: string]: string | number | null;
}

export const findMatchingSpecificationSetIndex = (
  conditionRow: GridValidRowModel,
  multiDefaultsCollection: MultiDefaultsCollection[]
) => {
  // Try to find a specification set in multiDefaultsCollection that matches
  // the user's current entry. If not, return null.
  const matchingIndex = multiDefaultsCollection!.findIndex(
    (specificationsObj) => {
      return Object.keys(specificationsObj).every((specKey) => {
        if (specKey === "non_standard") return true;
        return isString(specificationsObj[specKey]) &&
          (specificationsObj[specKey] as string).toLowerCase() === "none"
          ? conditionRow[specKey] === "none" || isNull(conditionRow[specKey])
          : !specificationsObj[specKey]
          ? !conditionRow[specKey] === !specificationsObj[specKey]
          : conditionRow[specKey] === specificationsObj[specKey];
      });
    }
  );
  return matchingIndex >= 0 ? matchingIndex : null;
};

type ConditionsEditGridProps = {
  activeScreen: string;
  columns: (GridColDef & { default?: any })[];
  apiRef: React.MutableRefObject<GridApi>;
  conditionRows: GridValidRowModel[];
  onConditionRowsUpdate: (
    newRows: GridValidRowModel[],
    updatedData: {
      cellConditionId: string;
      conditionComponentId?: string;
      testConditionId?: string;
      [key: string]: any; // edited CellCondition fields may also be appended
    }[]
  ) => void;
  multiDefaultsCollection: MultiDefaultsCollection[] | null;
};

const BatchCellConditionEditGrid = ({
  activeScreen,
  apiRef,
  conditionRows,
  onConditionRowsUpdate,
  columns,
  multiDefaultsCollection,
}: ConditionsEditGridProps) => {
  const {
    status: { get: getSpecificationsDataStatus, save: saveConditionDataStatus },
  } = useSelector<RootState, BatchConditionsSpecificationsState>(
    ({ batchConditionsSpecification }) => batchConditionsSpecification
  );
  const title =
    activeScreen.toLowerCase() === OVERVIEW_SCREEN ? "Overview" : activeScreen;
  const [firstPastedFieldAndVal, setFirstPastedFieldAndVal] = useState<{
    [key: string]: string | number | boolean;
  } | null>(null);
  const [activeCell, setActiveCell] = useState<GridCellParams | null>(null);
  const [showPasteInstructions, setShowPasteInstructions] = useState(false);

  const colsToDefaults = useMemo(() => {
    return columns.reduce((colsToDefaults_: { [key: string]: any }, col_) => {
      if (isNil(col_.default)) return colsToDefaults_;
      return {
        ...colsToDefaults_,
        [col_.field]: col_.default,
      };
    }, {});
  }, [columns]);

  const setNonStandard = useCallback(
    (updatedRow_: GridValidRowModel) => {
      if (
        !("non_standard" in updatedRow_) ||
        updatedRow_.non_standard ||
        activeScreen.toLowerCase() === "teardown"
      ) {
        return updatedRow_;
      }
      if (
        Object.keys(updatedRow_).some((updatedRowKey) => {
          // if key doesn't have a default value, its value can't be nonstandard.
          if (!(updatedRowKey in colsToDefaults)) return false;
          // if default value is Array with nulls, its value can't be nonstandard.
          if (
            Array.isArray(colsToDefaults[updatedRowKey]) &&
            colsToDefaults[updatedRowKey].every((defaultVal_: any) =>
              isNull(defaultVal_)
            )
          )
            return false;
          const lookupVal =
            isBoolean(updatedRow_[updatedRowKey]) ||
            isNull(updatedRow_[updatedRowKey])
              ? updatedRow_[updatedRowKey]
              : updatedRow_[updatedRowKey].material_id ||
                updatedRow_[updatedRowKey].cycling_protocol_id ||
                updatedRow_[updatedRowKey].user_id ||
                updatedRow_[updatedRowKey];
          return Array.isArray(colsToDefaults[updatedRowKey])
            ? !colsToDefaults[updatedRowKey].includes(lookupVal)
            : colsToDefaults[updatedRowKey] !== lookupVal;
        })
      ) {
        updatedRow_.non_standard = true;
      }
      return updatedRow_;
    },
    [activeScreen, colsToDefaults]
  );

  const handleMultiStandardSpecificationRow = (
    updatedRow_: GridValidRowModel,
    existingRow_: GridValidRowModel
  ) => {
    // manage multi-defaults functionality. default_options is an index of
    // multiDefaultsCollection that's been chosen
    const defaultIndexHasChanged =
      updatedRow_.default_options !== existingRow_.default_options;
    const nonStandardManuallySet =
      updatedRow_.non_standard &&
      updatedRow_.non_standard !== existingRow_.non_standard;
    if (defaultIndexHasChanged) {
      // A new multiDefault option has been selected.
      if (Number.isInteger(updatedRow_.default_options)) {
        updatedRow_ = {
          ...updatedRow_,
          ...multiDefaultsCollection![updatedRow_.default_options],
        };
      }
    } else if (nonStandardManuallySet) {
      updatedRow_.default_options = "";
    } else {
      // another field has changed. check to see row's values align with an item
      // in multiDefaultsCollection. If not, set it to "".
      const matchingIndex = findMatchingSpecificationSetIndex(
        updatedRow_,
        multiDefaultsCollection!
      );
      updatedRow_.default_options = isNull(matchingIndex) ? "" : matchingIndex;
    }
    updatedRow_.non_standard = !Number.isInteger(updatedRow_.default_options);
    return updatedRow_;
  };

  const handleCellClick: GridEventListener<"cellClick"> = (clickedCell) => {
    if (
      !activeCell ||
      activeCell.id !== clickedCell.id ||
      activeCell.field !== clickedCell.field
    ) {
      setActiveCell(clickedCell);
    }
  };

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();

      const getGridValue = (value_: string) => {
        // if column type is boolean, return pasted value's boolean counterpart
        return activeCell!.colDef.type !== "boolean"
          ? value_
          : !["false", "none", "0", "no", ""].includes(value_.toLowerCase());
      };

      if (!!activeCell && activeCell.isEditable) {
        setShowPasteInstructions(false);
        var splitTextFromClipboard: null | string[] = readCSVFromClipboardData(
          event,
          /\n|\r/g
        );
        if (splitTextFromClipboard && splitTextFromClipboard.length > 0) {
          const updatedIds: {
            cellConditionId: string;
            conditionComponentId?: string;
            testConditionId?: string;
          }[] = [];
          const clickedRowIndex = conditionRows.findIndex(
            (row_) => row_.id === activeCell.id
          );
          let clipboardIndex = 0;
          const newRows = conditionRows.map((cellRow_, rowIndex) => {
            if (
              rowIndex < clickedRowIndex ||
              clipboardIndex >= splitTextFromClipboard!.length
            )
              return cellRow_;
            const valFromClipboard = splitTextFromClipboard![clipboardIndex];
            clipboardIndex += 1;
            updatedIds.push({
              cellConditionId: cellRow_.id,
              conditionComponentId: cellRow_.condition_component_id,
              testConditionId: cellRow_.test_condition_id,
            });
            let newRow_ = {
              ...cellRow_,
              [activeCell.field]: getGridValue(valFromClipboard),
            };
            newRow_ = setNonStandard(newRow_);
            return newRow_;
          });
          onConditionRowsUpdate(newRows, updatedIds);
          setFirstPastedFieldAndVal({
            [activeCell.field]: getGridValue(splitTextFromClipboard[0]),
          });
          apiRef.current.setEditCellValue({
            id: activeCell.id,
            field: activeCell.field,
            value: getGridValue(splitTextFromClipboard[0]),
          });
        }
      } else {
        if (!activeCell) setShowPasteInstructions(true);
        if (!!firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
      }
    },
    [
      activeCell,
      apiRef,
      conditionRows,
      firstPastedFieldAndVal,
      setNonStandard,
      onConditionRowsUpdate,
    ]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, [handlePaste]);

  const processRowUpdate = (updatedRow: GridValidRowModel) => {
    const activeFirstPastedField = firstPastedFieldAndVal || {};
    let updatedRow_ = {
      ...updatedRow,
      ...activeFirstPastedField,
    };
    const newRows = conditionRows.map((conditionRow_) => {
      if (updatedRow_.id === conditionRow_.id) {
        if (!!multiDefaultsCollection) {
          updatedRow_ = handleMultiStandardSpecificationRow(
            updatedRow_,
            conditionRow_
          );
        } else {
          // determine if 'non standard' needs to be auto-checked
          updatedRow_ = setNonStandard(updatedRow_);
        }
        return updatedRow_;
      }
      return conditionRow_;
    });
    const idsPayload = {
      cellConditionId: updatedRow_.id,
      conditionComponentId: updatedRow_.condition_component_id,
      testConditionId: updatedRow_.test_condition_id,
    };
    onConditionRowsUpdate(newRows, [idsPayload]);
    if (firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
    return updatedRow_;
  };

  // allow test condition rows to be changed at any time in cell lifecycle,
  // condition components that have cells past committed must go thru
  // change request process
  const determineCellEditable = (params: GridCellParams) =>
    !!params.row.test_condition_id ||
    (!params.row.committed && !!params.colDef.editable);

  return (
    <Grid item xs={12}>
      <Box my={6}>
        <Box>
          <Typography variant="h2">{title}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <DataGrid
          apiRef={apiRef}
          rows={conditionRows}
          columns={columns}
          onCellClick={handleCellClick}
          processRowUpdate={processRowUpdate}
          slots={{ row: ConditionRow }}
          slotProps={{
            row: {
              activeScreen,
              getScrollPosition: () => apiRef.current.getScrollPosition(),
            },
          }}
          autoHeight
          loading={[
            getSpecificationsDataStatus,
            saveConditionDataStatus,
          ].includes("loading")}
          hideFooter={conditionRows.length < 100}
          isCellEditable={determineCellEditable}
        />
      </Box>
      <Toast
        severity="warning"
        open={showPasteInstructions}
        onClose={() => {
          setShowPasteInstructions(false);
        }}
      >
        To paste, click the first cell in the column you'd like to paste, then
        try again.
      </Toast>
    </Grid>
  );
};

export default BatchCellConditionEditGrid;
