import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Autocomplete,
  Box,
  Checkbox,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import FilterIcon from "../../icons/Filter";
import type { RootState } from "../../store";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import Chip from "../../components/Chip";
import SmallChip from "../../components/SmallChip";
import { uniq, isNull } from "lodash";

import type { SignalMapState } from "./slice";
import {
  deselectAllSignals,
  selectSignals,
  filterSignals,
  resetFilterSignals,
} from "./slice";

type Props = {
  allSensorTypes: string[];
  allDeviceClasses: string[];
  allSignalGroups: { [key: string]: number | string }[];
};

const ButtonSignalFilters = ({
  allSensorTypes,
  allDeviceClasses,
  allSignalGroups,
}: Props) => {
  const dispatch = useDispatch();
  const FORM_WIDTH = 450;
  const LABEL_WIDTH = 200;
  const {
    dutType,
    selected: { signals: selected },
    signals,
    visible: { signals: visible },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  const isCell = dutType === "cell";
  // Local filter state
  let defaultFilterState = {
    signalNames: [],
    signalGroups: [],
    sensorTypes: [],
    deviceClasses: [],
    cellSignals: false,
    moduleSignals: false,
  };
  const [filterState, setFilterState] = useState<any>(defaultFilterState);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const snakeCaseToCapitalize = (str: string) => {
    return str
      .split("_")
      .flatMap((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(" ");
  };

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  // Apply action
  const handleApplyClick = () => {
    setFiltersApplied(true);
    let newVisibleSignals = [
      // Signal names
      ...signals
        .filter((s) => filterState.signalNames.includes(s.name))
        .flatMap((s) => s.signal_id),
      // Signal groups
      ...signals
        .filter((s) => filterState.signalGroups.includes(s.signal_group))
        .flatMap((s) => s.signal_id),
      // Sensor types
      ...signals
        .filter((s) => filterState.sensorTypes.includes(s.sensor_type))
        .flatMap((s) => s.signal_id),
      // Device classes
      ...signals
        .filter((s) => filterState.deviceClasses.includes(s.device_class))
        .flatMap((s) => s.signal_id),
      // Cell signals
      ...(filterState.cellSignals
        ? [
            ...signals
              .filter((s) => !isNull(s.cell_id))
              .flatMap((s) => s.signal_id),
          ]
        : []),
      // Module signals
      ...(filterState.moduleSignals
        ? [
            ...signals
              .filter((s) => isNull(s.cell_id))
              .flatMap((s) => s.signal_id),
          ]
        : []),
    ];
    dispatch(filterSignals(newVisibleSignals));
    handleButtonClose();

    // Remove any invisible signals from the selected state
    let _selected = [...selected];
    dispatch(deselectAllSignals());
    dispatch(
      selectSignals(newVisibleSignals.filter((s) => _selected.includes(s)))
    );
  };

  return (
    <>
      <Chip
        count={filtersApplied ? visible.length : 0}
        clickable
        onClick={handleButtonClick}
        onDelete={() => {
          setFilterState(defaultFilterState);
          setFiltersApplied(false);
          dispatch(resetFilterSignals());
        }}
        icon={<FilterIcon />}
        label="Filter"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={6}>
          <Box style={{ width: FORM_WIDTH }} display="flex" alignItems="center">
            <Box style={{ width: LABEL_WIDTH }}>
              <Typography className="small" color="textSecondary">
                Signal Name(s)
              </Typography>
            </Box>

            <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
              <Autocomplete
                multiple
                options={uniq(signals.flatMap((s) => s.name)).sort()}
                getOptionLabel={(option) => {
                  return option;
                }}
                isOptionEqualToValue={(option, value) => option === value}
                value={filterState.signalNames}
                onChange={(e, val) => {
                  let copy = { ...filterState };
                  copy.signalNames = uniq(val);
                  setFilterState(copy);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
                renderOption={(props, opt) => (
                  <li {...props}>
                    <Checkbox
                      color="secondary"
                      checked={props["aria-selected"] === true}
                    />
                    <Box py={2} width="100%">
                      {opt}
                    </Box>
                  </li>
                )}
                renderTags={(values, getTagProps) =>
                  values.map((value, index) => (
                    <Box key={index} mr={2} mb={1}>
                      <SmallChip label={value} {...getTagProps({ index })} />
                    </Box>
                  ))
                }
              />
            </Box>
          </Box>

          {!isCell && (
            <>
              <Box
                style={{ width: FORM_WIDTH }}
                display="flex"
                alignItems="center"
                mt={5}
              >
                <Box style={{ width: LABEL_WIDTH }}>
                  <Typography className="small" color="textSecondary">
                    Signal Group(s)
                  </Typography>
                </Box>

                <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
                  <Autocomplete
                    multiple
                    options={allSignalGroups.sort()}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    value={filterState.signalGroups}
                    onChange={(e, val) => {
                      let copy = { ...filterState };
                      copy.signalGroups = uniq(val);
                      setFilterState(copy);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        color="secondary"
                      />
                    )}
                    renderOption={(props, opt) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          checked={props["aria-selected"] === true}
                        />
                        <Box py={2} width="100%">
                          {opt.name}
                        </Box>
                      </li>
                    )}
                    renderTags={(values, getTagProps) =>
                      values.map((sg, index) => (
                        <Box key={index} mr={2} mb={1}>
                          <SmallChip
                            label={sg.name}
                            {...getTagProps({ index })}
                          />
                        </Box>
                      ))
                    }
                  />
                </Box>
              </Box>

              <Box
                style={{ width: FORM_WIDTH }}
                display="flex"
                alignItems="center"
                mt={5}
              >
                <Box style={{ width: LABEL_WIDTH }}>
                  <Typography className="small" color="textSecondary">
                    Device Class(es)
                  </Typography>
                </Box>

                <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
                  <Autocomplete
                    multiple
                    options={allDeviceClasses.sort()}
                    getOptionLabel={(option) => {
                      return option;
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={filterState.deviceClasses}
                    onChange={(e, val) => {
                      let copy = { ...filterState };
                      copy.deviceClasses = uniq(val);
                      setFilterState(copy);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        color="secondary"
                      />
                    )}
                    renderOption={(props, opt) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          checked={props["aria-selected"] === true}
                        />
                        <Box py={2} width="100%">
                          {opt}
                        </Box>
                      </li>
                    )}
                    renderTags={(values, getTagProps) =>
                      values.map((value, index) => (
                        <Box key={index} mr={2} mb={1}>
                          <SmallChip
                            label={value}
                            {...getTagProps({ index })}
                          />
                        </Box>
                      ))
                    }
                  />
                </Box>
              </Box>
            </>
          )}

          <Box
            style={{ width: FORM_WIDTH }}
            display="flex"
            alignItems="center"
            mt={5}
          >
            <Box style={{ width: LABEL_WIDTH }}>
              <Typography className="small" color="textSecondary">
                Sensor Type(s)
              </Typography>
            </Box>

            <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
              <Autocomplete
                multiple
                options={allSensorTypes.sort()}
                getOptionLabel={(option) => {
                  return snakeCaseToCapitalize(option);
                }}
                isOptionEqualToValue={(option, value) =>
                  snakeCaseToCapitalize(option) === snakeCaseToCapitalize(value)
                }
                value={filterState.sensorTypes}
                onChange={(e, val) => {
                  let copy = { ...filterState };
                  copy.sensorTypes = uniq(
                    val.map((st) => snakeCaseToCapitalize(st))
                  );
                  setFilterState(copy);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
                renderOption={(props, opt) => (
                  <li {...props}>
                    <Checkbox
                      color="secondary"
                      checked={props["aria-selected"] === true}
                    />
                    <Box py={2} width="100%">
                      {snakeCaseToCapitalize(opt)}
                    </Box>
                  </li>
                )}
                renderTags={(values, getTagProps) =>
                  values.map((value, index) => (
                    <Box key={index} mr={2} mb={1}>
                      <SmallChip
                        label={snakeCaseToCapitalize(value)}
                        {...getTagProps({ index })}
                      />
                    </Box>
                  ))
                }
              />
            </Box>
          </Box>

          {!isCell && (
            <>
              <Box
                style={{ width: FORM_WIDTH }}
                display="flex"
                alignItems="center"
                mt={5}
              >
                <Box style={{ width: LABEL_WIDTH }}>
                  <Typography className="small" color="textSecondary">
                    Cell-specific Signals
                  </Typography>
                </Box>

                <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
                  <Checkbox
                    checked={filterState.cellSignals}
                    onChange={(e) => {
                      let copy = { ...filterState };
                      copy.cellSignals = e.target.checked;
                      setFilterState(copy);
                    }}
                    style={{
                      color: `${filterState.cellSignals ? "#1E5471" : ""}`,
                      paddingLeft: 0,
                    }}
                  />
                </Box>
              </Box>

              <Box
                style={{ width: FORM_WIDTH }}
                display="flex"
                alignItems="center"
                mt={5}
              >
                <Box style={{ width: LABEL_WIDTH }}>
                  <Typography className="small" color="textSecondary">
                    Module-specific Signals
                  </Typography>
                </Box>

                <Box style={{ width: FORM_WIDTH - LABEL_WIDTH }}>
                  <Checkbox
                    checked={filterState.moduleSignals}
                    onChange={(e) => {
                      let copy = { ...filterState };
                      copy.moduleSignals = e.target.checked;
                      setFilterState(copy);
                    }}
                    style={{
                      color: `${filterState.moduleSignals ? "#1E5471" : ""}`,
                      paddingLeft: 0,
                    }}
                  />
                </Box>
              </Box>
            </>
          )}

          <Box mt={5} display="flex" justifyContent="space-between">
            <Button
              color="tertiary"
              type="button"
              size="small"
              onClick={() => {
                setFilterState(defaultFilterState);
                setFiltersApplied(false);
                dispatch(resetFilterSignals());
              }}
            >
              <b>Reset</b>
            </Button>

            <Box>
              <Button
                color="primary"
                type="button"
                size="small"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ButtonSignalFilters;
