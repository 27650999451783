import { forwardRef, Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { FieldPath } from "react-hook-form";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import type { FieldArrayWithId } from "react-hook-form";
import ConditionMenu from "../ConditionMenu";
import OverflowTooltip from "../../../components/OverflowTooltip";
import colors, { addAlpha } from "../../../theme/colors";
import { hasPassedStatus } from "../../../utils/statuses";

type Props = {
  ref: React.Ref<HTMLDivElement>;
  step: number;
  activeStep: number;
  title: string;
  fields: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
  children: React.ReactNode;
  onDuplicate: (id: number) => void;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
};

const Section = forwardRef(
  (
    {
      step,
      activeStep,
      title,
      fields,
      children,
      onDuplicate: _onDuplicate,
      onEdit: _onEdit,
      onDelete: _onDelete,
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const headerStyle: React.CSSProperties = {
      position: "sticky",
      top: 74,
      zIndex: 1,
    };

    const inView = step === activeStep;

    const { control } = useFormContext<ConditionRHFormFormat>();

    const watchKeys = fields.flatMap((field, fieldIndex) => [
      `conditions.${fieldIndex}.name` as FieldPath<ConditionRHFormFormat>,
      `conditions.${fieldIndex}.status` as FieldPath<ConditionRHFormFormat>,
      `conditions.${fieldIndex}.cell_condition_id` as FieldPath<ConditionRHFormFormat>,
    ]);

    const state = useWatch({
      control,
      name: watchKeys,
    });

    const onDuplicate = (index: number) => {
      return () =>
        _onDuplicate(
          parseInt(
            state[watchKeys.indexOf(`conditions.${index}.cell_condition_id`)] ||
              ""
          )
        );
    };

    const onEdit = (index: number) => {
      const status = state[
        watchKeys.indexOf(`conditions.${index}.status`)
      ] as CellStatus;

      if (!hasPassedStatus("C", status, true)) {
        return;
      }

      return () =>
        _onEdit(
          parseInt(
            state[watchKeys.indexOf(`conditions.${index}.cell_condition_id`)] ||
              ""
          )
        );
    };

    const onDelete = (index: number) => {
      return () =>
        _onDelete(
          parseInt(
            state[watchKeys.indexOf(`conditions.${index}.cell_condition_id`)] ||
              ""
          )
        );
    };

    return (
      <>
        <div
          id={step < 0 ? `tc-section-${step}` : undefined}
          style={{
            width: "calc(100vw - 256px - 3rem)",
            height: 1,
            pointerEvents: "none",
            position: "absolute",
            top: -54,
          }}
        />
        <div style={inView ? headerStyle : {}}>
          <Box style={{ background: colors.body.light }}>
            <Box
              py={3}
              display="flex"
              style={{ background: addAlpha(colors.rules, 0.3) }}
            >
              <Box px={4} pt={2} pr={4}>
                <Typography
                  variant="h3"
                  style={inView ? { width: "calc(236px - 1rem)" } : {}}
                >
                  {inView ? (
                    <OverflowTooltip title={title}>
                      <span>{title}</span>
                    </OverflowTooltip>
                  ) : (
                    title
                  )}
                </Typography>
              </Box>

              {inView ? (
                <>
                  {fields.map((field, fieldIndex) => (
                    <Fragment key={fieldIndex}>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        px={4}
                        display="flex"
                        alignItems="center"
                        style={{ width: 204, flexShrink: 0 }}
                      >
                        <OverflowTooltip
                          title={
                            state[
                              watchKeys.indexOf(`conditions.${fieldIndex}.name`)
                            ] || `CONDITION ${fieldIndex + 1}`
                          }
                        >
                          <span>
                            {state[
                              watchKeys.indexOf(`conditions.${fieldIndex}.name`)
                            ] || `CONDITION ${fieldIndex + 1}`}
                          </span>
                        </OverflowTooltip>
                        <Box ml="auto">
                          <ConditionMenu
                            onDuplicate={onDuplicate(fieldIndex)}
                            onEdit={onEdit(fieldIndex)}
                            onDelete={onDelete(fieldIndex)}
                          />
                        </Box>
                      </Box>
                    </Fragment>
                  ))}

                  <Divider orientation="vertical" flexItem />
                </>
              ) : null}
            </Box>
            <Divider />
          </Box>
        </div>
        <div style={{ position: "relative" }}>
          <Box px={4} py={4}>
            {children}
          </Box>
          <div
            ref={ref}
            style={{
              width: "calc(100vw - 256px - 3rem)",
              height: 1,
              pointerEvents: "none",
              position: "absolute",
              bottom: 80,
            }}
          />
          <div
            id={step >= -1 ? `tc-section-${step + 1}` : undefined}
            style={{
              width: "calc(100vw - 256px - 3rem)",
              height: 1,
              pointerEvents: "none",
              position: "absolute",
              bottom: 70,
            }}
          />
        </div>
      </>
    );
  }
);

export default Section;
