import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import isArray from "lodash/isArray";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import InfoBlock from "./InfoBlock";
import Button from "../../../components/Button";
import CommonFileTextIcon from "../../../icons/CommonFileText";
import ClockIcon from "../../../icons/Clock";
import FlagIcon from "../../../icons/Flag";
import colors from "../../../theme/colors";
import { MODAL_MESSAGE_TYPES } from "../../../components/forms/TableRow";
import { isoDateToDateString } from "../../../utils/labels";
import { addZeroTimeZoneIfAmbiguous } from "../../../utils/timeHelpers";

type Props = {
  values: DynamicFormValues;
  fields: ConditionMetadataLabel["fields"];
  changeRequestKey: string;
};

const StepInfoBlock = ({ fields, values, changeRequestKey }: Props) => {
  const { pendingChanges } = useSelector<RootState, MetadataState>(
    ({ metadata }) => metadata
  );
  const itemChanges = pendingChanges[changeRequestKey] || {};

  const onshapeRev = fields?.find(({ spec_field }) =>
    spec_field.id.includes("subassembly_rev")
  );

  const flags: string[] = [];

  const infoCells = fields.map(({ spec_field }) => {
    let value = values[spec_field.id];

    // Special handling
    if (
      spec_field.id.includes("subassembly_rev") ||
      MODAL_MESSAGE_TYPES.includes(spec_field.type)
    ) {
      return null;
    }

    let display = <span>{value}</span>;

    const pendingChange = itemChanges[spec_field.id] as string | undefined;
    let pending =
      pendingChange !== undefined ? (
        <Box ml="auto">
          <Tooltip
            arrow
            title={`Pending specification change to ${pendingChange}`}
          >
            <div style={{ height: 20, width: 24 }}>
              <ClockIcon style={{ width: 20, height: 20 }} />
            </div>
          </Tooltip>
        </Box>
      ) : null;

    if (spec_field.type === "sopdeviation_options" && value) {
      value = value as SOPDeviation[];
      display = (
        <span>
          {value
            .map(({ deviation_type_name }) => deviation_type_name)
            .join(", ")}
        </span>
      );

      const pendingChange = itemChanges[spec_field.id] as
        | SOPDeviation[]
        | undefined;

      pending =
        pendingChange !== undefined ? (
          <Box ml="auto">
            <Tooltip
              arrow
              title={`Pending specification change to ${pendingChange}`}
            >
              <div style={{ height: 20, width: 24 }}>
                <ClockIcon style={{ width: 20, height: 20 }} />
              </div>
            </Tooltip>
          </Box>
        ) : null;
    }

    if (spec_field.type === "date" && value) {
      display = (
        <Box ml="auto">
          <span>
            {isoDateToDateString(addZeroTimeZoneIfAmbiguous(value as string)) ||
              "-"}
          </span>
        </Box>
      );
    }

    if (spec_field.type === "icmpcm_options" && value) {
      value = value as Material;
      display = (
        <Tooltip arrow title={value.description}>
          <span>{value.material_id}</span>
        </Tooltip>
      );

      const pendingChange = itemChanges[spec_field.id] as Material | undefined;

      pending =
        pendingChange !== undefined ? (
          <Box ml="auto">
            <Tooltip
              arrow
              title={`Pending specification change to ${pendingChange}`}
            >
              <div style={{ height: 20, width: 24 }}>
                <ClockIcon style={{ width: 20, height: 20 }} />
              </div>
            </Tooltip>
          </Box>
        ) : null;
    }

    if (spec_field.type === "cyclingprotocol_options" && value) {
      value = value as CyclingProtocol;
      display = (
        <Tooltip arrow title={value.description}>
          <span>{value.cycling_protocol_id}</span>
        </Tooltip>
      );

      const pendingChange = itemChanges[spec_field.id] as
        | CyclingProtocol
        | undefined;

      pending =
        pendingChange !== undefined ? (
          <Box ml="auto">
            <Tooltip
              arrow
              title={`Pending specification change to ${pendingChange}`}
            >
              <div style={{ height: 20, width: 24 }}>
                <ClockIcon style={{ width: 20, height: 20 }} />
              </div>
            </Tooltip>
          </Box>
        ) : null;
    }

    if (spec_field.type === "button" && value) {
      const linkTextMatch = /link/i;
      display = (
        <Button
          color="tertiary"
          style={{
            padding: 0,
            marginTop: 8,
          }}
          startIcon={<CommonFileTextIcon />}
          href={`${value}`}
          target="_blank"
        >
          {spec_field.label
            ? spec_field.label.replace(linkTextMatch, "Document")
            : "SOP Document"}
        </Button>
      );
    }

    let shouldShow = value && !["user_options"].includes(spec_field.type);

    if (["bool", "boolean"].includes(spec_field.type)) {
      if (
        spec_field.id.includes("engineer_flag") ||
        spec_field.id.includes("non_standard") ||
        !value
      ) {
        return null;
      }
      flags.push(spec_field.label);
      return null;
    }

    if ((isArray(value) && value.length === 0) || !shouldShow) {
      return null;
    }

    let title = spec_field.label;
    if (spec_field.type === "button") {
      title = "";
    }

    if (spec_field.id.includes("subassembly_partno") && onshapeRev) {
      title = `${title} / Revision`;
      display = (
        <span>
          {value}-{values[onshapeRev.spec_field.id]}
        </span>
      );

      const pendingChange = itemChanges[spec_field.id] as string | undefined;
      const pendingRevChange = itemChanges[onshapeRev.spec_field.id] as
        | string
        | undefined;

      pending =
        pendingChange !== undefined ? (
          <Box ml="auto">
            <Tooltip
              arrow
              title={`Pending specification change to "${
                pendingChange ? pendingChange : value
              } - ${
                pendingRevChange
                  ? pendingRevChange
                  : values[onshapeRev.spec_field.id]
              }"`}
            >
              <div style={{ height: 20, width: 24 }}>
                <ClockIcon style={{ width: 20, height: 20 }} />
              </div>
            </Tooltip>
          </Box>
        ) : null;
    }

    return {
      title,
      contents: [display, pending],
      fullWidth: spec_field.type === "text" || spec_field.type === "button",
      warning: spec_field.id.includes("deviations_from_sop"),
    };
  });

  const consolidatedInfoCells: {
    title: string;
    warning: boolean;
    contents: React.ReactNode[];
  }[][] = [];

  let newLine = true;
  infoCells.forEach((infoCell) => {
    if (!infoCell) {
      return;
    }
    const { title, warning, fullWidth, contents } = infoCell;

    if (fullWidth) {
      consolidatedInfoCells.push([{ title, contents, warning }]);
      newLine = true;
    } else if (newLine) {
      consolidatedInfoCells.push([{ title, contents, warning }]);
      newLine = false;
    } else {
      consolidatedInfoCells[consolidatedInfoCells.length - 1].push({
        title,
        contents,
        warning,
      });
    }
  });

  return (
    <>
      {consolidatedInfoCells.map((items, index) => (
        <InfoBlock key={index} items={items} />
      ))}
      {flags.map((title) => (
        <Box mt={4} display="flex" key={title}>
          <FlagIcon style={{ color: colors.accent.burntYellow }} />
          <Box ml={2} my="auto">
            {title}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default StepInfoBlock;
