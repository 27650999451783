import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import styled from "@mui/styles/styled";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import FilterIcon from "../../icons/Filter";
import PlusCircleIcon from "../../icons/PlusCircle";
import MetaFilter from "../../components/table/MetaFilter";
import TextFilter from "../../components/table/TextFilter";
import Button from "../../components/Button";
import Chip from "../../components/Chip";
import OptionFilter from "../../components/table/OptionFilter";

const MineLabel = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 500,
});

type Props = {
  labels: { id: string; label: string }[];
  filterOptions: FilterOptions<TestStandFilters>;
  filters: TestStandFilters;
  onChange: (filters: TestStandFilters) => void;
};

const DEFAULT_FILTERS: (keyof TestStandFilters)[] = [
  "name",
  "anode_current_sensors",
  "h2_sensor_type",
];

const ButtonTestStandFilters = ({
  labels,
  filterOptions,
  filters,
  onChange,
}: Props) => {
  // Local filter state
  const [filterState, setFilterState] = useState(filters);
  useEffect(() => setFilterState(filters), [filters]);

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  // Add filter button
  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAddFilterAnchorEl(event.currentTarget);
  const handleAddFilterClose = () => setAddFilterAnchorEl(null);

  // UI state
  const [visibleFilters, setVisibleFilters] =
    useState<(keyof TestStandFilters)[]>(DEFAULT_FILTERS);
  const availableFilters = (
    Object.keys(filterOptions) as Array<keyof TestStandFilters>
  ).filter((key) => !visibleFilters.includes(key));

  useEffect(() => {
    if (!!buttonEl) {
      return;
    }

    const newKeys = Object.keys(filters).map(
      (key) => key as keyof TestStandFilters
    );
    const changed = newKeys.filter((key) => visibleFilters.indexOf(key) === -1);

    if (changed.length > 0) {
      setVisibleFilters([...visibleFilters, ...changed]);
    }
  }, [buttonEl, filters, visibleFilters]);

  // Remove filter
  const handleRemoveFilter = (key: keyof TestStandFilters) => {
    const newState = { ...filterState };
    delete newState[key];
    const index = visibleFilters.indexOf(key);
    visibleFilters.splice(index, 1);
    setFilterState(newState);
    setVisibleFilters([...visibleFilters]);
  };

  // Reset action
  const handleResetClick = () => {
    setFilterState({});
    setVisibleFilters(DEFAULT_FILTERS);
  };

  const getCleanFilters = () => {
    const finalFilters = { ...filterState };
    for (const key in finalFilters) {
      const typedKey = key as keyof TestStandFilters;
      if (finalFilters[typedKey]?.length === 0) {
        delete finalFilters[typedKey];
      }
    }
    return finalFilters;
  };

  // Apply action
  const handleApplyClick = () => {
    const finalFilters = getCleanFilters();
    onChange(finalFilters);
    setButtonEl(null);
  };

  return (
    <>
      <Chip
        count={Object.keys(filters).length}
        clickable
        onClick={handleButtonClick}
        onDelete={() => onChange({})}
        icon={<FilterIcon />}
        label="Filter"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={6} pt={6}>
          <table>
            <tbody>
              {visibleFilters.map((key) => {
                if (!filterOptions[key]) {
                  return null;
                }

                const expLabel = labels.find((label) => label.id === key);
                const { options } = filterOptions[key]!;

                let control;
                switch (key) {
                  case "name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/test-stands/names"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "h2_sensor_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/test-stands/h2-sensor-types"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "anode_current_sensors":
                    control = (
                      <OptionFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={options || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value as ["1" | "0"],
                          })
                        }
                      />
                    );
                    break;
                  default:
                    control = (
                      <TextFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        onClear={() =>
                          setFilterState({
                            ...filterState,
                            [key]: [],
                          })
                        }
                        value={
                          filterState[key] ? filterState[key]![0] || "" : ""
                        }
                        onChange={(e) =>
                          setFilterState({
                            ...filterState,
                            [key]: [e.target.value],
                          })
                        }
                      />
                    );
                    break;
                }

                return (
                  <tr key={key}>
                    <Box pr={4} pb={4} component="td">
                      <MineLabel color="textSecondary">
                        {expLabel?.label}
                      </MineLabel>
                    </Box>
                    <Box pb={4} component="td">
                      {control}
                    </Box>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>

        {availableFilters.length > 0 ? (
          <Box pb={3} px={3}>
            <Button
              color="tertiary"
              size="small"
              startIcon={<PlusCircleIcon />}
              onClick={handleAddFilterClick}
            >
              Add Filter
            </Button>
            <Menu
              anchorEl={addFilterAnchorEl}
              open={!!addFilterAnchorEl}
              onClose={handleAddFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {availableFilters.map((key) => {
                const sampleLabel = labels.find((label) => label.id === key);
                return (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      setVisibleFilters([...visibleFilters, key]);
                      handleAddFilterClose();
                    }}
                  >
                    {sampleLabel?.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        ) : null}

        {visibleFilters.length > 0 ? (
          <Box pt={3} pb={6} px={6} display="flex">
            <Button
              color="tertiary"
              type="button"
              size="small"
              onClick={handleResetClick}
            >
              <b>Reset</b>
            </Button>
            <Box ml={3}>
              <Button
                color="primary"
                type="button"
                size="small"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        ) : null}
      </Popover>
    </>
  );
};

export default ButtonTestStandFilters;
