import { SAMPLE_TYPE_LOOKUP_ID } from "./slice";

const allSameSampleTypes = (sampleCollection: CharacterizationSample[]) => {
  const findSampleType = (sample_: CharacterizationSample) =>
    sample_.fields.find(
      ({ field_lookup_id }) => field_lookup_id === SAMPLE_TYPE_LOOKUP_ID
    )!;

  return sampleCollection.every(
    (sample, index, _fullCollection) =>
      findSampleType(sample).value === findSampleType(_fullCollection[0]).value
  );
};

const samplesUtils = {
  allSameSampleTypes,
};

export default samplesUtils;
