import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const CheckCircleOutlined = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    <path
      d="M5.30782 8.10599C5.09022 7.93598 4.77599 7.97457 4.60599 8.19218C4.43598 8.40978 4.47457 8.72401 4.69218 8.89401L5.30782 8.10599ZM8.2 11L7.89218 11.394C8.00714 11.4838 8.1556 11.519 8.29863 11.4902C8.44165 11.4614 8.56497 11.3716 8.63625 11.2443L8.2 11ZM11.4363 6.2443C11.5712 6.00337 11.4852 5.69867 11.2443 5.56375C11.0034 5.42882 10.6987 5.51476 10.5637 5.7557L11.4363 6.2443ZM4.69218 8.89401L7.89218 11.394L8.50782 10.606L5.30782 8.10599L4.69218 8.89401ZM8.63625 11.2443L11.4363 6.2443L10.5637 5.7557L7.76375 10.7557L8.63625 11.2443Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CheckCircleOutlined;
