import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, Interval } from "luxon";
import isEqual from "lodash/isEqual";
import client, { PAGE_SIZE } from "../../api";
import { isoDateToDateString } from "../../utils/labels";

export const EXPERIMENTS = "EXPERIMENTS";

type Args = APIListArgs<Experiment, ExperimentFilters>;

export interface ExperimentListState {
  experiments: Experiment[];
  hasMore: boolean;
  args: Args;
  grouping: {
    groupingEnabled: boolean;
    allGroupsExpanded: boolean;
    expandedGroups: (string | number)[];
  };
  status: "idle" | "loading" | "succeeded" | "failed";
  error: null | string;
}

const initialState: ExperimentListState = {
  experiments: [],
  hasMore: false,
  args: {
    orderBy: "exp_id",
    order: "desc",
    page: 0,
    filters: {},
  },
  grouping: {
    groupingEnabled: false,
    allGroupsExpanded: false,
    expandedGroups: [],
  },
  status: "idle",
  error: null,
};

export const listExperiments = createAsyncThunk(
  `${EXPERIMENTS}/list`,
  async ({ pageSize = PAGE_SIZE, refresh, ...args }: Args) => {
    const pageArg = refresh
      ? `&__offset=0&__limit=${(args.page + 1) * pageSize}`
      : `&__offset=${args.page * pageSize}&__limit=${pageSize}`;
    const sortArg = `&__sort=${args.order === "desc" ? "-" : ""}${
      args.orderBy === "status" ? "status_order" : args.orderBy
    }`;

    let filterArg = "";
    for (let key of Object.keys(args.filters)) {
      if (key === "owner") {
        const selected = args.filters[key] || [];
        filterArg += selected.map((sel) => `&owner_id=${sel.user_id}`).join("");
        continue;
      }

      if (key === "project") {
        const selected = args.filters[key] || [];
        filterArg += selected
          .map((sel) => `&project_id=${sel.project_id}`)
          .join("");
        continue;
      }

      if (key === "cell_count") {
        const selected = args.filters[key] || [];
        filterArg += selected.map((sel) => `&cell_count=${sel}`).join("");
        continue;
      }

      if (key === "last_commit") {
        const selected = args.filters[key] || [];
        const [start, end] = [
          selected[0]
            ? DateTime.fromFormat(selected[0], "yyyy-MM-dd")
            : DateTime.fromMillis(0),
          selected[1]
            ? DateTime.fromFormat(selected[1], "yyyy-MM-dd").plus({ days: 1 })
            : DateTime.local(),
        ];

        const interval = Interval.fromDateTimes(start, end);
        if (interval.isValid) {
          filterArg += `&${key}__gte=${start.toUTC().toISO()}`;
          filterArg += `&${key}__lt=${end.toUTC().toISO()}`;
          continue;
        }
      }

      if (key === "description") {
        const selected = args.filters[key as keyof ExperimentFilters] || [];
        filterArg += `&${key}__contains=${selected.join("")}`;
        continue;
      }

      const selected =
        (args.filters[key as keyof ExperimentFilters] as string[]) || [];
      filterArg += selected.map((sel) => `&${key}=${sel}`).join("");
    }

    const response = await client.get(
      `meta/experiments?${sortArg}${pageArg}${filterArg}`
    );

    return { response, args: { pageSize, ...args }, refresh };
  }
);

const getBulkValues = (
  experiments: Experiment[],
  falconKey: FalconKey<Experiment>
) => {
  const array = experiments.map((exp) => {
    if (falconKey === "last_commit") {
      return isoDateToDateString(exp.last_commit);
    }

    const res = falconKey.split("__");
    const key = res[0] as keyof Experiment;
    const subKey = res[1];

    if (!subKey) {
      return exp[key];
    }

    // TODO proper typing
    return (exp[key] as any)[subKey];
  });

  return new Set<string | number>(array);
};

const experimentsSlice = createSlice({
  name: EXPERIMENTS,
  initialState,
  reducers: {
    resetExperimentListState: (state) => Object.assign(state, initialState),
    resetExperimentListStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
    enableGrouping(state) {
      state.grouping.groupingEnabled = true;
      state.grouping.allGroupsExpanded = true;
      const bulkValues = getBulkValues(state.experiments, state.args.orderBy);
      state.grouping.expandedGroups = Array.from(bulkValues);
    },
    disableGrouping(state) {
      state.grouping.expandedGroups = [];
      state.grouping.groupingEnabled = false;
    },
    expandAllGroups(state) {
      const bulkValues = getBulkValues(state.experiments, state.args.orderBy);
      state.grouping.expandedGroups = Array.from(bulkValues);
      state.grouping.allGroupsExpanded = true;
    },
    collapseAllGroups(state) {
      state.grouping.expandedGroups = [];
      state.grouping.allGroupsExpanded = false;
    },
    expandGroup(state, action: PayloadAction<string | number>) {
      state.grouping.expandedGroups = [
        ...state.grouping.expandedGroups,
        action.payload,
      ];
    },
    collapseGroup(state, action: PayloadAction<string | number>) {
      const groups = [...state.grouping.expandedGroups];
      const index = groups.indexOf(action.payload);
      groups.splice(index, 1);
      state.grouping.expandedGroups = groups;
      state.grouping.allGroupsExpanded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // List Experiments
      .addCase(listExperiments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listExperiments.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status = "failed";
          state.error = "Unknown error";
          return;
        }

        state.hasMore = payload.response.meta.more;

        let argsChanged = payload.args.page === 0;
        for (const argKey in payload.args) {
          if (argKey === "page") {
            continue;
          }

          if (argKey === "filters") {
            for (let filterKey in payload.args.filters) {
              const existingFilter =
                state.args.filters[filterKey as keyof ExperimentFilters] || [];
              const newFilter =
                payload.args.filters[filterKey as keyof ExperimentFilters] ||
                [];
              if (!isEqual(existingFilter, newFilter)) {
                argsChanged = true;
              }
            }
          } else if (
            state.args[argKey as keyof Args] !==
            payload.args[argKey as keyof Omit<Args, "refresh">]
          ) {
            argsChanged = true;
          }
        }

        if (payload.refresh) {
          state.experiments = payload.response.data;
        } else if (argsChanged) {
          // Reset the array because args changed
          state.experiments = payload.response.data;
          if (state.grouping.groupingEnabled) {
            state.grouping.allGroupsExpanded = true;
          }
        } else {
          // Add any fetched experiments to the array
          state.experiments = state.experiments.concat(payload.response.data);
        }

        state.args = payload.args;

        if (state.grouping.allGroupsExpanded) {
          const bulkValues = getBulkValues(
            state.experiments,
            state.args.orderBy
          );
          state.grouping.expandedGroups = Array.from(bulkValues);
        } else {
          state.grouping.expandedGroups = [];
        }

        state.status = "succeeded";
      })
      .addCase(listExperiments.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message as string;
      });
  },
});

export const {
  resetExperimentListState,
  resetExperimentListStatus,
  enableGrouping,
  disableGrouping,
  expandAllGroups,
  collapseAllGroups,
  expandGroup,
  collapseGroup,
} = experimentsSlice.actions;
export default experimentsSlice.reducer;
