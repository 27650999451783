import React, { useState } from "react";
import jsonexport from "jsonexport/dist";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "../../icons/Download";
import Toast from "../Toast";
import {
  cellIdToString,
  cellStatusToString,
  findLabelForFlag,
  isoDateToDateString,
} from "../../utils/labels";
import client from "../../api";
import assembleCellsQuery from "../../utils/assembleCellsQuery";
import { CellFilterArgs } from "../../features/cells/slice";
import { CircularProgress } from "@mui/material";
import { isBoolean } from "lodash";
import { REF_FIELD_KEY_TO_COMPONENT_KEY } from "../../features/cells/CellTableCell";

type Props<T> = {
  viewName: string;
  columns: DataLabel<T>[];
  filterArgs: Omit<CellFilterArgs, "page">;
};

const QUERY_WARNING_THRESHOLD = 50000;
const SENTRY_WARNING_MESSAGE = `Large number of cells (> ${QUERY_WARNING_THRESHOLD}) returned in query for CSV. Consider investigating the use case for a CSV with so many rows, and if there's a better way we can get this data to the folks who need it.`;

const ButtonDownloadCsv = <T extends Cell>({
  viewName,
  columns,
  filterArgs,
}: Props<T>) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const generateCsv = async (cellData: Cell[]) => {
    const headers = columns.map(({ label }) => label);
    const data = cellData.map((row) => {
      const newRow: any = {};
      const addNonStandard = (result: string, deviation_from_sop: string) => {
        if (deviation_from_sop && deviation_from_sop.length > 0) {
          result += `NON-STANDARD: ${deviation_from_sop} `;
        } else {
          result += "NON-STANDARD ";
        }
        return result;
      };
      const iconToText = (result: string, icon: string) => {
        result +=
          icon === "na"
            ? "NA"
            : icon === "flag"
            ? "FLAG"
            : icon === "check"
            ? "COMPLETED"
            : "-";
        return result;
      };
      for (const { id } of columns) {
        let result = "";

        switch (id) {
          case "cell_id":
            newRow[id] = cellIdToString(row.cell_id);
            break;

          case "experiment__exp_id":
            newRow[id] = row.experiment.exp_id;
            break;

          case "condition__name":
            newRow[id] = row.condition.name;
            break;

          case "condition__description":
            newRow[id] = row.condition.description;
            break;
          case "condition__pool":
            newRow[id] = row.condition.pool;
            break;
          case "condition__build_config":
            newRow[id] = row.condition.build_config;
            break;
          case "condition__build_phase":
            newRow[id] = row.condition.build_phase;
            break;

          case "condition__group_number":
            newRow[id] = row.condition.group_number;
            break;

          case "owner__name":
            newRow[id] = row.owner.name;
            break;

          case "experiment__project":
            newRow[id] = row.experiment.project.name;
            break;

          case "incubator__name":
            newRow[id] = row.incubator.name;
            break;

          case "tester__name":
            newRow[id] = row.tester.name;
            break;

          case "status":
            newRow[id] = cellStatusToString(row.status);
            break;

          case "priority":
            newRow[id] = row.priority;
            break;

          case "channel__fullname":
            newRow[id] = row.channel.fullname;
            break;

          case "position__shelf":
            newRow[id] = row.position.shelf;
            break;

          case "reserved_channel__fullname":
            newRow[id] = row.reserved_channel.channel.fullname;
            break;

          case "reserved_test_stand__name":
            newRow[id] = row.reserved_test_stand.name || "-";
            break;

          case "condition__cell_assembly":
            newRow[id] = row.condition.cell_assembly;
            break;

          case "anode__part_number":
            newRow[id] = row.anode.part_number || "-";
            break;

          case "anode__icm_pcm":
            newRow[id] = row.anode.icm_pcm || "-";
            break;

          case "anode__additive_icm_pcm":
            newRow[id] = row.anode.additive_icm_pcm || "-";
            break;

          case "anode__assembly_type":
            newRow[id] = row.anode.assembly_type || "-";
            break;

          case "anode__qc_targets":
            newRow[id] = row.anode.qc_targets
              ? row.anode.qc_targets.join(", ")
              : "-";
            break;

          case "anode__nominal_mass":
          case "anode__mass_actual":
          case "anode__mass_actual_2":
          case "anode__mass_actual_3":
          case "anode__mass_actual_4":
          case "anode__mass_actual_5":
          case "anode__mass_actual_6":
          case "anode__mass_actual_total":
            const [massKey] = id.split("__").slice(-1);
            const anodeKey = massKey as
              | "nominal_mass"
              | "mass_actual"
              | "mass_actual_2"
              | "mass_actual_3"
              | "mass_actual_4"
              | "mass_actual_5"
              | "mass_actual_6"
              | "mass_actual_total";
            newRow[id] = row.anode[anodeKey] || "-";
            break;

          case "ref_anode__reference_electrode_type":
          case "ref_gde__reference_electrode_type":
          case "ref_oee__reference_electrode_type":
            const componentKey = REF_FIELD_KEY_TO_COMPONENT_KEY[
              id as
                | "ref_anode__reference_electrode_type"
                | "ref_gde__reference_electrode_type"
                | "ref_oee__reference_electrode_type"
            ] as "ref_anode" | "ref_gde" | "ref_oee";
            newRow[id] = row[componentKey].reference_electrode_type || "-";
            break;

          case "electrolyte__icm_pcm":
            newRow[id] = row.electrolyte.icm_pcm || "-";
            break;

          case "fill__nominal_volume":
            newRow[id] = row.fill.nominal_volume || "-";
            break;

          case "gde__icm_pcm":
            newRow[id] = row.gde.icm_pcm || "-";
            break;

          case "oee__icm_pcm":
            newRow[id] = row.oee.icm_pcm || "-";
            break;

          case "condition__specified":
            newRow[id] = isoDateToDateString(row.condition.specified);
            break;

          case "condition__plan_test_start_date":
            newRow[id] = isoDateToDateString(
              row.condition.plan_test_start_date
            );
            break;

          case "staged":
            newRow[id] = isoDateToDateString(row.staged);
            break;

          case "committed":
            newRow[id] = isoDateToDateString(row.committed);
            break;

          case "test__created_time":
            newRow[id] = isoDateToDateString(row.test.created_time);
            break;

          case "current_ref_cal__measurement":
            newRow[id] = row.current_ref_cal.measurement || "-";
            break;

          case "anode__completed_at":
            if (row.anode.non_standard) {
              result = addNonStandard(result, row.anode.deviations_from_sop);
            }

            result = iconToText(result, row.anode._icon);
            newRow[id] = result;
            break;

          case "gde__completed_at":
            if (row.gde.non_standard) {
              result = addNonStandard(result, row.gde.deviations_from_sop);
            }

            result = iconToText(result, row.gde._icon);
            newRow[id] = result;
            break;

          case "oee__completed_at":
            if (row.oee.non_standard) {
              result = addNonStandard(result, row.oee.deviations_from_sop);
            }

            result = iconToText(result, row.oee._icon);
            newRow[id] = result;
            break;

          case "oee__oee_ids":
            newRow[id] = row.oee.oee_ids ? row.oee.oee_ids.join(", ") : "-";
            break;

          case "gde__gde_ids":
            newRow[id] = row.gde.gde_ids ? row.gde.gde_ids.join(", ") : "-";
            break;

          case "hca__hca_ids":
            newRow[id] = row.hca.hca_ids ? row.hca.hca_ids.join(", ") : "-";
            break;

          case "counter__completed_at":
            if (row.counter.non_standard) {
              result = addNonStandard(result, row.counter.deviations_from_sop);
            }

            result = iconToText(result, row.counter._icon);
            newRow[id] = result;
            break;

          case "ref_anode__completed_at":
            if (row.ref_anode.non_standard) {
              result = addNonStandard(
                result,
                row.ref_anode.deviations_from_sop
              );
            }

            result = iconToText(result, row.ref_anode._icon);
            newRow[id] = result;
            break;

          case "ref_gde__completed_at":
            if (row.ref_gde.non_standard) {
              result = addNonStandard(result, row.ref_gde.deviations_from_sop);
            }

            result = iconToText(result, row.ref_gde._icon);
            newRow[id] = result;
            break;

          case "ref_oee__completed_at":
            if (row.ref_oee.non_standard) {
              result = addNonStandard(result, row.ref_oee.deviations_from_sop);
            }

            result = iconToText(result, row.ref_oee._icon);
            newRow[id] = result;
            break;

          case "full__completed_at":
            if (row.full.non_standard) {
              result = addNonStandard(result, row.full.deviations_from_sop);
            }

            result = iconToText(result, row.full._icon);
            newRow[id] = result;
            break;

          case "thermocouples_attached":
            newRow[id] = row.full.thermocouples_attached || "No";
            break;

          case "full__build_test_location":
            newRow[id] = row.full.build_test_location;
            break;

          case "full__serial_number":
            newRow[id] = row.full.serial_number;
            break;

          case "thermocouples_specified":
            newRow[id] = row.full.thermocouples_specified || "No";
            break;

          case "electrolyte__mix_task__completed":
            const mixTaskCompleted = row.electrolyte.elyte_mix_task_completed;
            newRow[id] = !isBoolean(mixTaskCompleted)
              ? "NA"
              : mixTaskCompleted
              ? "Complete"
              : "Not complete";
            break;

          case "electrolyte__qa_task__completed":
            const qaTaskCompleted = row.electrolyte.elyte_qa_task_completed;
            newRow[id] = !isBoolean(qaTaskCompleted)
              ? "NA"
              : qaTaskCompleted
              ? "Complete"
              : "Not complete";
            break;

          case "electrolyte__completed_at":
            if (row.electrolyte.non_standard) {
              result = addNonStandard(
                result,
                row.electrolyte.deviations_from_sop
              );
            }

            result = iconToText(result, row.electrolyte._icon);
            newRow[id] = result;
            break;

          case "fill__completed_at":
            if (row.fill.non_standard) {
              result = addNonStandard(result, row.fill.deviations_from_sop);
            }

            result = iconToText(result, row.fill._icon);
            newRow[id] = result;
            break;

          case "on_test__eis_testing_beginning_of_life":
            newRow[id] = row.on_test.eis_testing_beginning_of_life
              ? "Yes"
              : "No";
            break;

          case "off_test__eis_testing_end_of_life":
            newRow[id] = row.off_test.eis_testing_end_of_life ? "Yes" : "No";
            break;

          case "on_test__completed_at":
            if (row.on_test.non_standard) {
              result = addNonStandard(result, row.on_test.deviations_from_sop);
            }

            result = iconToText(result, row.on_test._icon);
            newRow[id] = result;
            break;

          case "on_test__temperature_setpoint":
            newRow[id] = row.on_test.temperature_setpoint;
            break;

          case "on_test__air_flow_rate_setpoint":
            newRow[id] = row.on_test.air_flow_rate_setpoint;
            break;

          case "on_test__h2_sweep_gas_flow_rate":
            newRow[id] = row.on_test.h2_sweep_gas_flow_rate;
            break;

          case "on_test__adapter_cable_type":
            newRow[id] = row.on_test.adapter_cable_type;
            break;

          case "test_meta__h2_sweep_gas_flow_rate":
            newRow[id] = row.test_meta__h2_sweep_gas_flow_rate;
            break;

          case "test_meta__adapter_cable_type":
            newRow[id] = row.test_meta__adapter_cable_type;
            break;

          case "ready_off__completed_at":
            if (row.ready_off.non_standard) {
              result = addNonStandard(
                result,
                row.ready_off.deviations_from_sop
              );
            }

            result += row.ready_off._icon === "check" ? "Yes" : "No";
            newRow[id] = result;
            break;

          case "off_test__completed_at":
            if (row.off_test.non_standard) {
              result = addNonStandard(result, row.off_test.deviations_from_sop);
            }

            result = iconToText(result, row.off_test._icon);
            newRow[id] = result;
            break;

          case "teardown__completed_at":
            if (row.teardown.non_standard) {
              result = addNonStandard(result, row.teardown.deviations_from_sop);
            }

            result = iconToText(result, row.teardown._icon);
            newRow[id] = result;
            break;

          case "teardown__type":
            newRow[id] = row.teardown.type;
            break;

          case "teardown__flags":
            newRow[id] =
              row.teardown.flags && row.teardown.flags.length > 0
                ? row.teardown
                    .flags!.map((flagId) => findLabelForFlag(flagId))
                    .join(", ")
                : "";
            break;

          case "on_test__cycling_protocol_id":
            newRow[id] = row.on_test.cycling_protocol_id;
            break;

          default:
            newRow[id] = `${id} not implemented`;
            break;
        }
      }

      return newRow;
    });

    try {
      const csv = await jsonexport(data, {
        rowDelimiter: ",",
        rename: headers,
      });

      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const filename = `${viewName} ${DateTime.now()
        .setLocale("en")
        .toLocal()
        .toISO()}.csv`;

      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.download = filename;

      document.body.appendChild(tempLink);
      tempLink.click();
      window.URL.revokeObjectURL(url);
      return {};
    } catch (err: any) {
      return { errorMessage: err.message };
    }
  };

  const fetchCellsForCSV = async () => {
    const response = await client.get(
      `meta/cells?${assembleCellsQuery(
        {
          ...filterArgs,
          page: 1,
        },
        true
      )}`
    );
    return response.data;
  };

  const handleDownloadCSVClick = async () => {
    setLoading(true);
    const cells = await fetchCellsForCSV();
    const csvGenerationResult = await generateCsv(cells);
    setLoading(false);
    if (csvGenerationResult.errorMessage) {
      setError(csvGenerationResult.errorMessage);
    }
    if (cells.length > QUERY_WARNING_THRESHOLD) {
      Sentry.captureMessage(SENTRY_WARNING_MESSAGE, {
        level: Sentry.Severity.Warning,
      });
    }
  };

  let errorToast;
  if (error) {
    errorToast = (
      <Toast open severity="error" onClose={() => setError(null)}>
        {error}
      </Toast>
    );
  }

  return (
    <>
      {loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <Tooltip arrow title="Download CSV">
          <span>
            <IconButton size="small" onClick={handleDownloadCSVClick}>
              <DownloadIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {errorToast}
    </>
  );
};

export default ButtonDownloadCsv;
