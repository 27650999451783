import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import colors, { addAlpha } from "../../theme/colors";
import type { RootState } from "../../store";
import { cellsSlices, CellListState } from "./slice";
import WarningIcon from "../../icons/Warning";
import { channelAvailabilityToString } from "../../utils/labels";
import OverflowTooltip from "../../components/OverflowTooltip";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  stateKey: keyof typeof cellsSlices;
};

const SummaryChannelUsage = ({ stateKey }: Props) => {
  const dispatch = useDispatch();
  const {
    selected,
    channelUsage,
    status: { channelUsage: status },
    error: { channelUsage: error },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  const refreshChannelUsage = useDebouncedCallback(
    () => dispatch(cellsSlices[stateKey].getChannelUsage(selected)),
    750,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    if (selected.length > 0) {
      refreshChannelUsage();
    } else {
      dispatch(cellsSlices[stateKey].resetChannelUsage());
    }
  }, [selected, dispatch, stateKey, refreshChannelUsage]);

  if (status === "idle" || stateKey !== "STAGED") {
    return null;
  }

  if (status === "loading") {
    return (
      <Box height={58}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === "failed") {
    return (
      <Box display="flex" alignItems="center">
        <WarningIcon
          color="error"
          style={{
            width: 16,
            height: 16,
            marginBottom: "-0.1rem",
          }}
        />
        <Box ml={2} className="small" my="auto">
          {error}
        </Box>
      </Box>
    );
  }

  const total = Object.values(channelUsage).reduce((a, b) => a + b);

  return (
    <>
      <Box display="flex" mb={2}>
        <InnerChannelCount
          count={channelUsage["A"] || 0}
          total={total}
          status="A"
          color={colors.accent.green}
        />
        <InnerChannelCount
          count={channelUsage["T"] || 0}
          total={total}
          status="T"
          color={colors.accent.orange}
        />
        <InnerChannelCount
          count={channelUsage["R"] || 0}
          total={total}
          status="R"
          color={colors.accent.yellow}
        />
        <InnerChannelCount
          count={channelUsage["I"] || 0}
          total={total}
          status="I"
          color={colors.accent.red}
        />
      </Box>
      {!channelUsage["A"] ? (
        <Box display="flex" alignItems="center">
          <WarningIcon
            color="error"
            style={{
              width: 16,
              height: 16,
              marginBottom: "-0.1rem",
            }}
          />
          <Box ml={2} className="small" my="auto">
            There are no channels available matching selected cell conditions.
          </Box>
        </Box>
      ) : (
        <Box display="flex" className="small" alignItems="center">
          Total channels matching these cell conditions: {total}
        </Box>
      )}
    </>
  );
};

type InnerChannelCountProps = {
  count: number;
  total: number;
  status: ChannelAvailability;
  color: string;
};

const InnerChannelCount = ({
  count,
  total,
  status,
  color,
}: InnerChannelCountProps) =>
  count ? (
    <Box
      px={2}
      py={1}
      width={`${(100 * count) / total}%`}
      className="small"
      style={{
        fontWeight: 700,
        opacity: 0.8,
        backgroundColor: addAlpha(color, 0.5),
      }}
    >
      <OverflowTooltip
        title={`${count} ${channelAvailabilityToString(status)}`}
      >
        <span>
          {count} {channelAvailabilityToString(status)}
        </span>
      </OverflowTooltip>
    </Box>
  ) : null;

export default SummaryChannelUsage;
