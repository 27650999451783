import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const TakingPicture = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3223 8.25L14.5661 6.75H10.0289L9.27269 8.25H7.0041C6.16882 8.25 5.4917 8.92157 5.4917 9.75V15.75C5.4917 16.5784 6.16882 17.25 7.0041 17.25H17.5909C18.4261 17.25 19.1033 16.5784 19.1033 15.75V9.75C19.1033 8.92157 18.4261 8.25 17.5909 8.25H15.3223Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2975 14.25C13.3416 14.25 14.188 13.4105 14.188 12.375C14.188 11.3395 13.3416 10.5 12.2975 10.5C11.2534 10.5 10.407 11.3395 10.407 12.375C10.407 13.4105 11.2534 14.25 12.2975 14.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.954468 3.75C0.954468 2.09315 2.29761 0.75 3.95447 0.75H20.6404C22.2973 0.75 23.6404 2.09315 23.6404 3.75V20.25C23.6404 21.9069 22.2973 23.25 20.6404 23.25H3.95446C2.29761 23.25 0.954468 21.9069 0.954468 20.25V3.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default TakingPicture;
