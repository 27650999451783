import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "@mui/styles/styled";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import ArrowBack from "../../icons/ArrowBack";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  BatchConditionEditPayload,
  BatchConditionsSpecificationsState,
  getSpecificationData,
  resetBatchConditionsSpecification,
  resetCreateConditions,
  resetSaveConditionData,
  saveConditions,
  saveSpecificationComponentData,
  specifyConditions,
} from "./batchConditionsSpecificationsSlice";
import Toast from "../../components/Toast";
import BatchCellConditionEditSidebar from "./BatchCellConditionEditSidebar";
import colors from "../../theme/colors";
import { BUILD_PHASES, CHANNEL_POOL_EXPLAINER } from "../../utils/labels";
import BatchFormMetaCell from "./BatchFormMetaCell";
import ConditionDatePicker from "../conditions/ConditionDatePicker";
import BatchCellConditionEditGrid, {
  MultiDefaultsCollection,
  findMatchingSpecificationSetIndex,
} from "./BatchCellConditionEditGrid";
import BatchCellConditionViewCell from "./BatchCellConditionViewCell";
import BatchCellConditionEditCell from "./BatchCellConditionEditCell";
import { REQUIRED_FIELDS_FOR_CREATE } from "./NewConditionsCreationForms";
import { hasMultipleDefaults } from "../../utils/multiDefaultsUtils";
import { FIELDS_TO_IGNORE_FOR_DEFAULTS } from "../conditions/layout/ConnectedSection";
import { isEmpty } from "lodash";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 2,
  position: "fixed",
});

export const OVERVIEW_SCREEN = "overview";
export const FIELD_TYPES_TO_HIDE = [
  "photo",
  "on_test__quality_checks_link",
  "warning_message",
  "requested_turnaround_date",
  "sop_link",
  "deviation_types",
];
export const CONDITION_NAME_COLUMN_WIDTH = 360;
const NON_EDITABLE_FIELD_TYPES = ["button"];
const ICM_PCM_DESCRIPTION_KEYS = [
  "part_number_description",
  "icm_pcm_description",
  "additive_icm_pcm_description",
];
const SOMETIMES_REQUIRED = "S";

export const fieldEnabled = (
  uiField_: ConditionUIField,
  specificationComponent_: GridValidRowModel
) => {
  if (!uiField_.enabled_by) return true;
  return Array.isArray(uiField_.enabled_by.conditional_vals)
    ? uiField_.enabled_by.conditional_vals.includes(
        specificationComponent_[uiField_.enabled_by.id] as string
      )
    : !!specificationComponent_[uiField_.enabled_by.id];
};

const BatchCellConditionEdit = () => {
  const { exp_id = "", cell_condition_id_string = "" } = useParams();
  const [activeScreen, setActiveScreen] = useState(OVERVIEW_SCREEN);
  const [conditionRows, setConditionRows] = useState<GridValidRowModel[]>([]);
  const [subassemblyColumns, setSubassemblyColumns] = useState<GridColDef[]>(
    []
  );
  // Collection of IDs with updated data not yet persisted to backend.
  const [updatedData, setUpdatedData] = useState<{
    [cellConditionId: string]: {
      testConditionIds: string[];
      conditionComponentIds: string[];
    };
  }>({});
  const [numErrorsBySubassembly, setNumErrorsBySubassembly] = useState<{
    [subassemblyName: string]: number;
  }>({});
  const [multiDefaultsCollection, setMultiDefaultsCollection] = useState<
    | {
        [fieldKey: string]: any;
      }[]
    | null
  >(null);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useBeforeunload((e) => {
    if (Object.keys(updatedData).length > 0) {
      e.preventDefault();
    }
  });

  const {
    conditionsToEdit,
    editFormTemplate,
    status: {
      create: createConditionsStatus,
      get: getSpecificationsDataStatus,
      save: saveConditionDataStatus,
      specify: specifyConditionsStatus,
    },
    error: {
      get: getSpecificationsDataError,
      save: saveConditionDataError,
      specify: specifyConditionsError,
    },
  } = useSelector<RootState, BatchConditionsSpecificationsState>(
    ({ batchConditionsSpecification }) => batchConditionsSpecification
  );

  const enableSave = useMemo(() => {
    return (
      saveConditionDataStatus !== "loading" &&
      Object.keys(updatedData).length > 0
    );
  }, [updatedData, saveConditionDataStatus]);

  const allConditionsSpecified = useMemo(() => {
    return conditionsToEdit?.every(
      (cellCondition) => !!cellCondition.specified
    );
  }, [conditionsToEdit]);

  const enableSpecify = useMemo(() => {
    return (
      !!conditionsToEdit &&
      !allConditionsSpecified &&
      !enableSave &&
      saveConditionDataStatus !== "loading" &&
      Object.keys(updatedData).length === 0
    );
  }, [
    allConditionsSpecified,
    conditionsToEdit,
    enableSave,
    updatedData,
    saveConditionDataStatus,
  ]);

  // initial data load
  useEffect(() => {
    if (!conditionsToEdit && getSpecificationsDataStatus === "idle") {
      dispatch(getSpecificationData(cell_condition_id_string.split(",")));
    }
  }, [
    conditionsToEdit,
    getSpecificationsDataStatus,
    cell_condition_id_string,
    dispatch,
  ]);

  useEffect(() => {
    if (saveConditionDataStatus === "succeeded") {
      setUpdatedData({});
    }
  }, [saveConditionDataStatus]);

  useEffect(() => {
    setUpdatedData({});
    dispatch(resetSaveConditionData());
  }, [activeScreen, dispatch]);

  const overviewCols: GridColDef[] = [
    {
      field: "cell_assembly",
      headerName: "Cell assembly",
      width: 188,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      width: CONDITION_NAME_COLUMN_WIDTH,
      headerName: "Condition name",
      editable: true,
    },
    {
      field: "replicates",
      headerName: "# cell replicates",
      type: "number",
      width: 140,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pool",
      renderHeader: () => (
        <>
          Channel pool
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      description: CHANNEL_POOL_EXPLAINER,
      align: "center",
      headerAlign: "center",
      width: 224,
      editable: true,
      type: "singleSelect",
      renderEditCell: ({ field, id, value }) => {
        return (
          <BatchFormMetaCell
            apiRef={apiRef}
            fieldName={field}
            fieldId={id}
            value={value || ""}
            endpoint="meta/channels/pool-ids"
            searchKey="pool"
          />
        );
      },
    },
    {
      field: "plan_test_start_date",
      renderHeader: () => (
        <>
          Planned on-test date
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      align: "center",
      headerAlign: "center",
      width: 192,
      editable: true,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      renderEditCell: ({ field, id, value }) => {
        return (
          <ConditionDatePicker
            wrapInCell={false}
            name={field}
            value={value}
            onChange={(newVal) => {
              apiRef.current.setEditCellValue({
                id,
                field,
                value: newVal && new Date(newVal),
              });
            }}
          />
        );
      },
    },
    {
      field: "build_phase",
      headerName: "Build phase",
      align: "center",
      headerAlign: "center",
      width: 192,
      editable: true,
      type: "singleSelect",
      valueOptions: BUILD_PHASES,
    },
    {
      field: "build_config",
      headerName: "Build config",
      headerAlign: "center",
      width: 192,
      editable: true,
    },
  ];

  useEffect(() => {
    if (!!conditionsToEdit && !!editFormTemplate) {
      // set rows for grid (conditionRows)
      if (activeScreen === OVERVIEW_SCREEN) {
        // Overview is the only screen hardcoded, so use 'overviewCols'
        const overviewKeys = overviewCols.map((col_) => col_.field);
        const overviewRows = conditionsToEdit.map((condition_) => {
          const conditionRow: GridValidRowModel = {
            id: condition_.cell_condition_id!,
          };
          overviewKeys.forEach((key_) => {
            const _key = key_ as string;
            conditionRow[key_] = condition_[_key as keyof Condition];
          });
          return conditionRow;
        });
        setConditionRows(overviewRows);
      } else {
        // dynamically construct subassemblyColumns and conditionRows using
        //   editFormTemplate and conditionsToEdit
        const subassemblyTemplate = editFormTemplate.find(
          (templateComponent) => {
            return [
              templateComponent.component_type,
              templateComponent.name,
            ].includes(activeScreen);
          }
        )!;
        const determineFieldEnabled = (
          row: GridValidRowModel,
          componentField: ConditionUIField,
          enablingField: ConditionUIField | null
        ) => {
          const disabledDueToComponentNotUsed =
            "component_used" in row && !row.component_used;
          if (disabledDueToComponentNotUsed) return false;
          const { enabled_by } = componentField;
          if (!enabled_by) return true;
          return enabled_by!.neg
            ? !row[enabled_by.id]
            : fieldEnabled(componentField, row) &&
                fieldEnabled(enablingField!, row);
        };
        const cols: (GridColDef & { default?: any })[] =
          subassemblyTemplate.component_fields
            .filter(
              (component_field_) =>
                !FIELD_TYPES_TO_HIDE.includes(component_field_.id)
            )
            .map((component_field_) => {
              const enablingField = !component_field_.enabled_by
                ? null
                : subassemblyTemplate.component_fields.find(
                    (otherField) =>
                      otherField.id === component_field_.enabled_by!.id
                  ) || null;
              return {
                field: component_field_.id,
                // renderHeader takes precedence over headerName
                renderHeader:
                  component_field_.required && !component_field_.enabled_by
                    ? () => (
                        <>
                          {component_field_.label}
                          <span style={{ color: colors.accent.red }}>*</span>
                        </>
                      )
                    : undefined,
                headerName: component_field_.label,
                editable: !NON_EDITABLE_FIELD_TYPES.includes(
                  component_field_.type
                ),
                default: component_field_.default,
                width: component_field_.type.includes("options") ? 272 : 192,
                headerAlign: "center",
                align: "center",
                // data type is primarily handled within renderEditCell() and
                // renderCell(), we're identifying booleans here so batch pasting
                // can be handled in BatchCellConditionEditGrid.
                type: ["bool", "boolean"].includes(component_field_.type)
                  ? "boolean"
                  : undefined,
                renderEditCell: (params: GridRenderEditCellParams<any>) => (
                  <BatchCellConditionEditCell
                    apiRef={apiRef}
                    gridParams={params}
                    componentField={component_field_}
                    disabled={
                      !determineFieldEnabled(
                        params.row,
                        component_field_,
                        enablingField
                      )
                    }
                  />
                ),
                renderCell: (params: GridRenderCellParams<any>) => (
                  <BatchCellConditionViewCell
                    gridParams={params}
                    componentField={component_field_}
                    disabled={
                      !determineFieldEnabled(
                        params.row,
                        component_field_,
                        enablingField
                      )
                    }
                  />
                ),
              };
            });

        let standardSpecs: MultiDefaultsCollection[] | null = null;
        // (if necessary) add dropdown to allow selecting one of multiple
        // standard options
        if (
          subassemblyTemplate.component_fields.some((component_field_) =>
            hasMultipleDefaults(component_field_.default)
          )
        ) {
          const multiDefaultFields =
            subassemblyTemplate.component_fields.filter(
              (component_field_) =>
                !FIELDS_TO_IGNORE_FOR_DEFAULTS.includes(component_field_.id) &&
                hasMultipleDefaults(component_field_.default)
            );

          // assemble standard specification groups, e.g.:
          // standardSpecs = [
          //  { icm_pcm: 'abc', assembly_type: 'pla' }, # default group 1
          //  { icm_pcm: 'def', assembly_type: 'cpsa' }, # default group 2
          // ]
          standardSpecs = [];
          // backend validates that all multidefault fields have same number of options per subassembly
          (multiDefaultFields[0].default as any[]).forEach(() => {
            standardSpecs!.push({});
          });
          multiDefaultFields.forEach((field_) => {
            (field_.default as any[]).forEach((defaultVal_, index_) => {
              standardSpecs![index_][field_.id] = defaultVal_;
            });
          });
          setMultiDefaultsCollection(standardSpecs);
          // transform standardSpecs into collection for dropdown
          const defaultOptions = standardSpecs.map((standardSpecs, index_) => {
            const objKeys = Object.keys(standardSpecs);
            // use first two fields (or at least first) as group identifier
            const labelVal = `${standardSpecs[objKeys[0]]}${
              !!objKeys[1] ? ` / ${standardSpecs[objKeys[1]]}` : ""
            }`;
            return {
              label: labelVal,
              value: index_,
            };
          });
          cols.unshift({
            field: "default_options",
            headerName: "Auto-fill a standard spec",
            align: "center",
            headerAlign: "center",
            width: 192,
            editable: true,
            type: "singleSelect",
            valueOptions: defaultOptions,
          });
        }

        // add checkbox to enable optional subassemblies
        if (subassemblyTemplate.required === SOMETIMES_REQUIRED) {
          cols.unshift({
            field: "component_used",
            width: 172,
            headerName: "Include this build step?",
            editable: true,
            type: "boolean",
            renderCell: ({ value }) => <>{value ? "Yes" : "No"}</>,
          });
        }
        // add condition name field
        cols.unshift({
          field: "name",
          width: CONDITION_NAME_COLUMN_WIDTH,
          headerName: "Condition name",
        });
        const subassemblyKeys = cols.map((col_) => col_.field);
        const rows: GridValidRowModel[] = conditionsToEdit.map((condition_) => {
          const conditionRow: GridValidRowModel = {
            id: condition_.cell_condition_id!,
            name: condition_.name,
            committed: condition_.committed,
          };
          const componentObj = subassemblyTemplate.test_condition_section
            ? condition_.test_condition
            : condition_.condition_components.find((condition_component_) => {
                return (
                  condition_component_.component_id ===
                  subassemblyTemplate.component_id
                );
              })!;
          conditionRow.default_options = "";
          subassemblyKeys.forEach((key_) => {
            if (!["name", "default_options"].includes(key_)) {
              conditionRow[key_] = componentObj[key_];
            }
          });
          if (!isEmpty(standardSpecs)) {
            const defaultOptionIndex = findMatchingSpecificationSetIndex(
              componentObj,
              standardSpecs!
            );
            if (Number.isInteger(defaultOptionIndex)) {
              conditionRow.default_options = defaultOptionIndex;
            } else {
              conditionRow.non_standard = true;
            }
          }
          ICM_PCM_DESCRIPTION_KEYS.forEach((key_) => {
            if (key_ in componentObj) {
              conditionRow[key_] = componentObj[key_];
            }
          });
          conditionRow.condition_component_id =
            componentObj.condition_component_id || null;
          conditionRow.test_condition_id =
            componentObj.test_condition_id || null;
          return conditionRow;
        });
        setSubassemblyColumns(cols);
        setConditionRows(rows);
      }
    }
    // eslint-disable-next-line
  }, [activeScreen, conditionsToEdit, editFormTemplate]);

  const handleConditionRowUpdate = (
    newRows: GridValidRowModel[],
    updatedDataInfo: {
      cellConditionId: string;
      conditionComponentId?: string;
      testConditionId?: string;
    }[]
  ) => {
    const updatedDataCopy = { ...updatedData };
    updatedDataInfo.forEach((updatedDataInfoObj) => {
      const { cellConditionId, conditionComponentId, testConditionId } =
        updatedDataInfoObj;
      // manage updatedData, for use when data is saved to backend
      if (!Object.keys(updatedDataCopy).includes(cellConditionId)) {
        updatedDataCopy[cellConditionId] = {
          conditionComponentIds: [],
          testConditionIds: [],
        };
      }
      const currentTestConditionIds =
        updatedDataCopy[cellConditionId].testConditionIds;
      if (
        !!testConditionId &&
        !currentTestConditionIds.includes(testConditionId)
      ) {
        updatedDataCopy[cellConditionId].testConditionIds = [
          ...currentTestConditionIds,
          testConditionId,
        ];
      }
      const currentConditionComponentIds =
        updatedDataCopy[cellConditionId].conditionComponentIds;
      if (
        !!conditionComponentId &&
        !currentConditionComponentIds.includes(conditionComponentId)
      ) {
        updatedDataCopy[cellConditionId].conditionComponentIds = [
          ...currentConditionComponentIds,
          conditionComponentId,
        ];
      }
      if (Object.keys(numErrorsBySubassembly).length > 0)
        setNumErrorsBySubassembly({});
    });

    setUpdatedData(updatedDataCopy);
    setConditionRows(newRows);
  };

  const validateSubassembly = (
    _componentField: ConditionUIField,
    _specificationComponent: {
      [key: string]: string | number | boolean | null;
    },
    _allComponentFields: ConditionUIField[]
  ) => {
    if (_componentField.enabled_by) {
      const enablingField = _allComponentFields.find(
        (otherComponentField_) =>
          otherComponentField_.id === _componentField.enabled_by!.id
      )!;
      if (
        _componentField.enabled_by.neg
          ? !_specificationComponent[_componentField.enabled_by.id]
          : fieldEnabled(_componentField, _specificationComponent) &&
            fieldEnabled(enablingField, _specificationComponent) &&
            !_specificationComponent[_componentField.id]
      ) {
        return false;
      }
    } else {
      if (!_specificationComponent[_componentField.id]) {
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    dispatch(resetSaveConditionData());

    if (activeScreen === OVERVIEW_SCREEN) {
      // save CellCondition data
      const updatedCellConditions = conditionRows.filter((cellCondition) => {
        return Object.keys(updatedData).includes(String(cellCondition.id));
      });
      // validate all required fields are present
      let absentRequiredCellConditionFields: string[] = [];
      updatedCellConditions.forEach((cellCondition_) => {
        REQUIRED_FIELDS_FOR_CREATE.forEach((reqField) => {
          if (!cellCondition_[reqField]) {
            absentRequiredCellConditionFields.push(reqField);
          }
        });
      });
      if (absentRequiredCellConditionFields.length > 0) {
        setNumErrorsBySubassembly({
          [OVERVIEW_SCREEN]: absentRequiredCellConditionFields.length,
        });
      } else {
        dispatch(
          saveConditions({
            cellConditions: updatedCellConditions,
            allCellConditionIds: cell_condition_id_string.split(","),
          })
        );
      }
    } else {
      // save condition or test component data
      const currentViewTemplate = editFormTemplate!.find((template) =>
        [template.component_type, template.name].includes(activeScreen)
      );
      const updatedRows = Object.keys(updatedData).some(
        (cellConditionId_) =>
          updatedData[cellConditionId_].conditionComponentIds.length > 0
      )
        ? conditionRows.filter((conditionRow_) =>
            Object.keys(updatedData).includes(String(conditionRow_.id))
          )
        : conditionRows.filter((conditionRow_) =>
            Object.keys(updatedData).includes(
              String(conditionRow_.test_condition_id)
            )
          );
      // validate required fields are present
      let absentRequiredFields: string[] = [];
      currentViewTemplate!.component_fields.forEach((componentField_) => {
        if (componentField_.required) {
          updatedRows.forEach((row_) => {
            if (
              !validateSubassembly(
                componentField_,
                row_,
                currentViewTemplate!.component_fields
              )
            ) {
              absentRequiredFields.push(componentField_.id);
            }
          });
        }
      });
      if (absentRequiredFields.length > 0) {
        // there are required fields that don't have values, do not attempt to save.
        setNumErrorsBySubassembly({
          [currentViewTemplate!.component_type || currentViewTemplate!.name]:
            absentRequiredFields.length,
        });
      } else {
        // find which condition records have been updated and should be send to backend.
        // Typically this would be done on the Redux side, but we need conditionRows
        const dataToUpdateByCellConditionId = Object.keys(updatedData).reduce(
          (dataToUpdate_: BatchConditionEditPayload, cellConditionId_) => {
            // Using find() in these next couple cases because we're enforcing either
            // saving a subassembly's data or resetting it in frontend state before
            // navigating to a different subassembly. This is an arbitrary rule
            // created by software team to manage this feature's scope. Feel free
            // to change to improve UX.
            const conditionComponentDataToUpdate = updatedData[
              cellConditionId_
            ].conditionComponentIds.map((conditionComponentId_) => {
              return conditionRows.find(
                (conditionRow_) =>
                  conditionRow_.condition_component_id === conditionComponentId_
              )!;
            });
            const testConditionDataToUpdate = updatedData[
              cellConditionId_
            ].testConditionIds.map((testConditionId_) => {
              return conditionRows.find(
                (conditionRow_) =>
                  conditionRow_.test_condition_id === testConditionId_
              )!;
            });
            dataToUpdate_[cellConditionId_] = [
              ...conditionComponentDataToUpdate,
              ...testConditionDataToUpdate,
            ];
            return dataToUpdate_;
          },
          {}
        );

        dispatch(
          saveSpecificationComponentData({
            batchEditConditionPayload: dataToUpdateByCellConditionId,
            allCellConditionIds: cell_condition_id_string.split(","),
          })
        );
      }
    }
  };

  const handleSpecify = () => {
    dispatch(resetSaveConditionData());

    const conditionsToSpecify = conditionsToEdit!.filter(
      (cellCondition) => !cellCondition.specified
    );

    // validate all subassemblies in all conditions to be specified
    const errCountBySubassembly: { [subassemblyName: string]: number } = {};
    editFormTemplate!.forEach((templateDefinition) => {
      let absentRequiredFields: string[] = [];
      templateDefinition!.component_fields.forEach((componentField_) => {
        if (componentField_.required) {
          conditionsToSpecify.forEach((cellCondition) => {
            const specificationComponent =
              templateDefinition.test_condition_section
                ? cellCondition.test_condition
                : cellCondition.condition_components.find(
                    (conditionComponent_) =>
                      conditionComponent_.component_id ===
                      templateDefinition.component_id
                  )!;
            if (
              !validateSubassembly(
                componentField_,
                specificationComponent,
                templateDefinition!.component_fields
              )
            ) {
              absentRequiredFields.push(componentField_.id);
            }
          });
        }
        if (absentRequiredFields.length > 0) {
          errCountBySubassembly[
            templateDefinition.component_type || templateDefinition.name
          ] = absentRequiredFields.length;
        }
      });
    });
    if (Object.keys(errCountBySubassembly).length > 0) {
      setNumErrorsBySubassembly(errCountBySubassembly);
    } else {
      const conditionsToSpecify = conditionsToEdit?.filter(
        (condition_) => !condition_.specified
      )!;
      const cellConditionIdsToSpecify = conditionsToSpecify.map(
        (condition_) => condition_.cell_condition_id!
      );
      const allCellConditionIds = conditionsToEdit!.map(
        (condition_) => `${condition_.cell_condition_id!}`
      );
      if (
        window.confirm(
          `Specify ${conditionsToSpecify.length} condition${
            conditionsToSpecify.length > 1 ? "s" : ""
          }?`
        )
      ) {
        dispatch(
          specifyConditions({ cellConditionIdsToSpecify, allCellConditionIds })
        );
      }
    }
  };

  return (
    <Box>
      <HeaderContainer
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h2" style={{ marginLeft: "24px" }}>
            <IconButton
              size="large"
              onClick={() => {
                if (Object.keys(updatedData).length > 0) {
                  if (
                    window.confirm("Are you sure? Changes have not been saved.")
                  ) {
                    dispatch(resetBatchConditionsSpecification());
                    navigate(`/experiments/${exp_id}`);
                    return;
                  }
                } else {
                  dispatch(resetBatchConditionsSpecification());
                  navigate(`/experiments/${exp_id}`);
                  return;
                }
              }}
              sx={{ paddingTop: "8px" }}
            >
              <ArrowBack />
            </IconButton>
            Edit cell/test conditions
          </Typography>
        </Box>
        <Box style={{ marginRight: "24px" }}>
          <Button
            color="primary"
            type="button"
            size="small"
            disabled={!enableSave}
            style={{
              marginRight: "8px",
            }}
            onClick={handleSave}
            endIcon={
              saveConditionDataStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
          >
            <b>Save</b>
          </Button>
          <Tooltip
            title="All cell conditions have already been specified."
            disableHoverListener={!allConditionsSpecified}
          >
            <span>
              <Button
                size="small"
                color="cta"
                style={{
                  marginRight: "8px",
                }}
                disabled={!enableSpecify}
                onClick={handleSpecify}
              >
                Specify
              </Button>
            </span>
          </Tooltip>
        </Box>
      </HeaderContainer>
      <BatchCellConditionEditSidebar
        onClickSubassembly={(subassemblyIdentifier) => {
          if (Object.keys(updatedData).length > 0) {
            if (window.confirm("Are you sure? Changes have not been saved.")) {
              setActiveScreen(subassemblyIdentifier);
              return;
            } else {
              return;
            }
          } else {
            setActiveScreen(subassemblyIdentifier);
          }
        }}
        activeSubassembly={activeScreen}
        numErrorsBySubassembly={numErrorsBySubassembly}
      />
      <Box style={{ padding: "80px 24px 36px 232px" }}>
        <Paper
          variant="outlined"
          square
          sx={{ marginBottom: "12px", minHeight: "80vh" }}
        >
          <Box px={3} pt={3} pb={6}>
            <Grid container spacing={4}>
              <BatchCellConditionEditGrid
                activeScreen={activeScreen}
                columns={
                  activeScreen === OVERVIEW_SCREEN
                    ? overviewCols
                    : subassemblyColumns
                }
                conditionRows={conditionRows}
                onConditionRowsUpdate={handleConditionRowUpdate}
                apiRef={apiRef}
                multiDefaultsCollection={multiDefaultsCollection}
              />
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Toast severity="error" open={getSpecificationsDataStatus === "failed"}>
        {getSpecificationsDataError || "Error fetching cell condition data."}
      </Toast>
      <Toast
        severity="error"
        open={!!saveConditionDataError || saveConditionDataStatus === "failed"}
      >
        {saveConditionDataError || "Error saving condition data."}
      </Toast>
      <Toast
        severity="error"
        open={!!specifyConditionsError || specifyConditionsStatus === "failed"}
      >
        {specifyConditionsError || "Error specifying condition data."}
      </Toast>
      <Toast severity="success" open={saveConditionDataStatus === "succeeded"}>
        {"Condition data saved."}
      </Toast>
      <Toast
        severity="success"
        open={createConditionsStatus === "succeeded"}
        onClose={() => {
          dispatch(resetCreateConditions());
        }}
      >
        Conditions successfully created.
      </Toast>
      <Toast
        severity="success"
        open={specifyConditionsStatus === "succeeded"}
        onClose={() => {
          dispatch(resetSaveConditionData());
        }}
      >
        Conditions successfully specified.
      </Toast>
    </Box>
  );
};

export default BatchCellConditionEdit;
