import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import client from "../../api";
import { isoDateToDateString } from "../../utils/labels";

export const CHANNELS = "CHANNELS";

type Args = APIListArgs<Channel, ChannelFilters>;

export interface ChannelListState {
  channels: Channel[];
  hasMore: boolean;
  selected: string[];
  args: Args;
  grouping: {
    groupingEnabled: boolean;
    allGroupsExpanded: boolean;
    expandedGroups: (string | number)[];
  };
  selectedView: CustomTableView<Channel> | null;
  status: {
    list: "idle" | "loading" | "succeeded" | "failed";
    changeStatus: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    list: null | string;
    changeStatus: null | string;
  };
}

export const initialState: ChannelListState = {
  channels: [],
  hasMore: false,
  selected: [],
  args: {
    orderBy: "channel__fullname",
    order: "asc",
    page: 0,
    filters: {},
  },
  grouping: {
    groupingEnabled: false,
    allGroupsExpanded: false,
    expandedGroups: [],
  },
  selectedView: null,
  status: {
    list: "idle",
    changeStatus: "idle",
  },
  error: {
    list: null,
    changeStatus: null,
  },
};

export const listChannels = createAsyncThunk(
  `${CHANNELS}/list`,
  async ({ pageSize = 500, refresh, ...args }: Args) => {
    const pageArg = refresh
      ? `&__offset=0&__limit=${(args.page + 1) * pageSize}`
      : `&__offset=${args.page * pageSize}&__limit=${pageSize}`;
    const sortArg = `&__sort=${args.order === "desc" ? "-" : ""}${
      args.orderBy === "channel__status" ? "status_order" : args.orderBy
    }${args.orderBy !== "channel__fullname" ? ",channel__fullname" : ""}`;

    let filterArg = "";

    for (let key of Object.keys(args.filters)) {
      if (key === "experiment__owner" || key === "res__owner") {
        const isReservationQuery = key === "res__owner";
        const selected = args.filters[key] || [];
        filterArg += selected
          .map(
            (sel) =>
              `&${isReservationQuery ? "res_" : ""}owner__user_id=${
                sel.user_id
              }`
          )
          .join("");
        continue;
      }

      if (key === "res__exp_id") {
        const selected = args.filters[key] || [];
        filterArg += selected
          .map((sel) => `&res_experiment__exp_id=${sel}`)
          .join("");
        continue;
      }

      if (key === "experiment__project") {
        const selected = args.filters[key] || [];
        filterArg += selected
          .map((sel) => `&project__project_id=${sel.project_id}`)
          .join("");
        continue;
      }

      if (key === "channel__fullname") {
        const selected = args.filters[key] || [];
        filterArg += selected
          .map((sel) => `&${key}=${encodeURIComponent(sel.channel_fullname)}`)
          .join("");
        continue;
      }

      if (
        key === "channel__low_current_range" ||
        key === "channel__min_voltage"
      ) {
        const selected = args.filters[key] || [];
        filterArg += selected.map((sel) => `&${key}__lte=${sel}`).join("");
        continue;
      }

      if (
        key === "channel__high_current_range" ||
        key === "channel__max_voltage"
      ) {
        const selected = args.filters[key] || [];
        filterArg += selected.map((sel) => `&${key}__gte=${sel}`).join("");
        continue;
      }

      const selected =
        (args.filters[key as keyof ChannelFilters] as string[]) || [];
      filterArg += selected
        .map((sel) => `&${key}=${encodeURIComponent(sel)}`)
        .join("");
    }

    const response = await client.get(
      `meta/channels/advanced?${sortArg}${pageArg}${filterArg}`
    );
    return { response, args: { pageSize, ...args }, refresh };
  }
);

export const changeStatus = createAsyncThunk(
  `${CHANNELS}/status`,
  async ({
    channels,
    infra_status,
  }: {
    channels: Channel[];
    infra_status: ChannelInfraStatus;
  }) =>
    client.put("meta/channels/many", {
      infra_status,
      ids: channels.map(({ channel }) => channel.channel_id),
    })
);

const getBulkValues = (channels: Channel[], falconKey: FalconKey<Channel>) => {
  const array = channels.map((channel) => {
    const res = falconKey.split("__");
    const key = res[0] as keyof Channel;
    const subKey = res[1];

    if (falconKey === "test_meta__ready_off__completed_at") {
      return isoDateToDateString(channel.test_meta.ready_off__completed_at);
    }

    if (!subKey) {
      return channel[key];
    }

    // TODO proper typing
    return (channel[key] as any)[subKey];
  });

  return new Set<string | number>(array);
};

const channelsSlice = createSlice({
  name: CHANNELS,
  initialState,
  reducers: {
    resetChannelListState: (state) => Object.assign(state, initialState),
    resetChannelListStatus: (state) => {
      state.status.list = "idle";
      state.error.list = null;
    },
    resetChannelChangeStatus: (state) => {
      state.status.changeStatus = "idle";
      state.error.changeStatus = null;
    },
    selectView: (
      state,
      { payload: view }: PayloadAction<CustomTableView<Channel> | null>
    ) => {
      state.selectedView = view;
    },
    enableGrouping(state) {
      state.grouping.groupingEnabled = true;
      state.grouping.allGroupsExpanded = true;
      const bulkValues = getBulkValues(state.channels, state.args.orderBy);
      state.grouping.expandedGroups = Array.from(bulkValues);
    },
    disableGrouping(state) {
      state.grouping.expandedGroups = [];
      state.grouping.groupingEnabled = false;
    },
    expandAllGroups(state) {
      const bulkValues = getBulkValues(state.channels, state.args.orderBy);
      state.grouping.expandedGroups = Array.from(bulkValues);
      state.grouping.allGroupsExpanded = true;
    },
    collapseAllGroups(state) {
      state.grouping.expandedGroups = [];
      state.grouping.allGroupsExpanded = false;
    },
    expandGroup(state, action: PayloadAction<string | number>) {
      state.grouping.expandedGroups = [
        ...state.grouping.expandedGroups,
        action.payload,
      ];
    },
    collapseGroup(state, action: PayloadAction<string | number>) {
      const groups = [...state.grouping.expandedGroups];
      const index = groups.indexOf(action.payload);
      groups.splice(index, 1);
      state.grouping.expandedGroups = groups;
      state.grouping.allGroupsExpanded = false;
    },
    selectChannels(state, action: PayloadAction<string[]>) {
      state.selected = uniq([...state.selected, ...action.payload]);
    },
    deselectChannels(state, action: PayloadAction<string[]>) {
      const selected = [...state.selected];
      action.payload.forEach((fullanme) => {
        const index = selected.indexOf(fullanme);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      });
      state.selected = uniq(selected);
    },
    selectAllVisibleChannels(state) {
      state.selected = state.channels.map(
        (channel) => channel.channel.fullname
      );
    },
    deselectAllVisibleChannels(state) {
      state.selected = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // List Channels
      .addCase(listChannels.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(listChannels.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status.list = "failed";
          state.error.list = "Unknown error";
          return;
        }

        state.hasMore = payload.response.meta.more;

        let argsChanged = payload.args.page === 0;
        for (const argKey in payload.args) {
          if (argKey === "page") {
            continue;
          }

          if (argKey === "filters") {
            for (let filterKey in payload.args.filters) {
              const existingFilter =
                state.args.filters[filterKey as keyof ChannelFilters] || [];
              const newFilter =
                payload.args.filters[filterKey as keyof ChannelFilters] || [];
              if (!isEqual(existingFilter, newFilter)) {
                argsChanged = true;
              }
            }
          } else if (
            state.args[argKey as keyof Args] !==
            payload.args[argKey as keyof Omit<Args, "refresh">]
          ) {
            argsChanged = true;
          }
        }

        if (payload.refresh) {
          state.channels = payload.response.data;
        } else if (argsChanged) {
          // Reset the array because args changed
          state.channels = payload.response.data;
          if (state.grouping.groupingEnabled) {
            state.grouping.allGroupsExpanded = true;
          }
        } else {
          // Add any fetched channels to the array
          state.channels = state.channels.concat(payload.response.data);
        }

        state.args = payload.args;

        if (state.grouping.allGroupsExpanded) {
          const bulkValues = getBulkValues(state.channels, state.args.orderBy);
          state.grouping.expandedGroups = Array.from(bulkValues);
        } else {
          state.grouping.expandedGroups = [];
        }

        state.status.list = "succeeded";
      })
      .addCase(listChannels.rejected, (state, { error }) => {
        state.status.list = "failed";
        state.error.list = error.message as string;
      })
      // Change status
      .addCase(changeStatus.pending, (state) => {
        state.status.changeStatus = "loading";
      })
      .addCase(changeStatus.fulfilled, (state, { payload: { errors } }) => {
        state.status.changeStatus = "succeeded";
      })
      .addCase(changeStatus.rejected, (state, { error }) => {
        state.status.changeStatus = "failed";
        state.error.changeStatus = error.message as string;
      });
  },
});

export const {
  resetChannelListState,
  resetChannelListStatus,
  resetChannelChangeStatus,
  selectView,
  enableGrouping,
  disableGrouping,
  expandAllGroups,
  collapseAllGroups,
  expandGroup,
  collapseGroup,
  selectChannels,
  deselectChannels,
  selectAllVisibleChannels,
  deselectAllVisibleChannels,
} = channelsSlice.actions;
export default channelsSlice.reducer;
