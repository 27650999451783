import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const MODULE_SINGLE = "MODULE_SINGLE";

export interface ModuleSingleState {
  module: Module | null;
  status: {
    completePretest: "idle" | "loading" | "succeeded" | "failed";
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    completePretest: null | string;
    get: null | string;
    save: null | string;
  };
}

const initialState: ModuleSingleState = {
  module: null,
  status: {
    completePretest: "idle",
    get: "idle",
    save: "idle",
  },
  error: {
    completePretest: null,
    get: null,
    save: null,
  },
};

export const getSingleModule = createAsyncThunk(
  `${MODULE_SINGLE}/get`,
  async (module_id: string | number) => {
    const response = await client.get(`meta/modules/${module_id}`);
    return response.data;
  }
);

export const saveModuleIdentifiers = createAsyncThunk(
  `${MODULE_SINGLE}/update`,
  async ({
    module_id,
    serial_number,
    cell_identifiers,
  }: {
    module_id: string | number;
    serial_number?: string | null;
    cell_identifiers: {
      cell_id: number;
      serial_number: string | null;
      position_id: string | null;
      hca_id: string | null;
      hca_id_2: string | null;
      hca_id_3: string | null;
      hca_id_4: string | null;
      hca_id_5: string | null;
      hca_id_6: string | null;
      mass_actual: number | null;
      mass_actual_2: number | null;
      mass_actual_3: number | null;
      mass_actual_4: number | null;
      mass_actual_5: number | null;
      mass_actual_6: number | null;
      hot_pocket_id: string | null;
      hot_pocket_2_id: string | null;
      hot_pocket_3_id: string | null;
      oee_1_serial_number: string | null;
      oee_1b_serial_number: string | null;
      oee_2_serial_number: string | null;
      oee_2b_serial_number: string | null;
      oee_3_serial_number: string | null;
      oee_3b_serial_number: string | null;
      oee_4_serial_number: string | null;
      oee_4b_serial_number: string | null;
      oee_5_serial_number: string | null;
      oee_5b_serial_number: string | null;
      oee_6_serial_number: string | null;
      oee_6b_serial_number: string | null;
    }[];
  }) => {
    const response = await client.put(`meta/modules/${module_id}/metadata`, {
      serial_number: serial_number,
      cell_identifiers,
    });
    return response.data;
  }
);

export const completePreTestModuleSteps = createAsyncThunk(
  `${MODULE_SINGLE}/complete-pretest-steps`,
  async (module_id: string | number) => {
    const response = await client.put(
      `meta/modules/${module_id}/complete-pretest-steps`,
      {}
    );
    return response.data;
  }
);

const moduleSingleSlice = createSlice({
  name: MODULE_SINGLE,
  initialState,
  reducers: {
    resetSingleModule: (state) => Object.assign(state, initialState),
    resetSaveModule: (state) => {
      state.status.save = "idle";
    },
    resetCompletePreTestSteps: (state) => {
      state.status.completePretest = "idle";
      state.error.completePretest = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get single module
      .addCase(getSingleModule.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSingleModule.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.module = payload;
      })
      .addCase(getSingleModule.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Update Serial Numbers/IDs for module and/or module cells
      .addCase(saveModuleIdentifiers.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(saveModuleIdentifiers.fulfilled, (state, { payload }) => {
        state.status.save = "succeeded";
        state.module = payload;
      })
      .addCase(saveModuleIdentifiers.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      })
      // Mark pre-test subassembly steps for all cells complete
      .addCase(completePreTestModuleSteps.pending, (state) => {
        state.status.completePretest = "loading";
      })
      .addCase(completePreTestModuleSteps.fulfilled, (state, { payload }) => {
        state.status.completePretest = "succeeded";
        state.module = payload;
      })
      .addCase(completePreTestModuleSteps.rejected, (state, { error }) => {
        state.status.completePretest = "failed";
        state.error.completePretest = error.message as string;
      });
  },
});

export const { resetCompletePreTestSteps, resetSingleModule, resetSaveModule } =
  moduleSingleSlice.actions;

export default moduleSingleSlice.reducer;
