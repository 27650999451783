import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import OverflowTooltip from "../../../../components/OverflowTooltip";
import ExpandIcon from "../../../../icons/Expand";
import PlusCircleIcon from "../../../../icons/PlusCircle";
import BuildTypeSubAssembly from "./BuildTypeSubAssembly";
import NumberBadge from "../../../../components/NumberBadge";
import colors from "../../../../theme/colors";

type Props = {
  started: boolean;
  editMode?: boolean;
  layout?: ConditionSidebarAssemblyLayout;
  onAdd?: () => void;
  onToggleCondition?: (id: number) => void;
};

const BuildTypeAssembly = ({
  started,
  editMode,
  layout,
  onAdd,
  onToggleCondition,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const layoutKeys = Object.keys(layout?.items || {});
  layoutKeys.sort((a, b) => {
    const aTitle = layout!.items[a].title;
    const aVersion = layout!.items[a].version;
    const bTitle = layout!.items[b].title;
    const bVersion = layout!.items[b].version;

    return `${aTitle}${aVersion}`.localeCompare(`${bTitle}${bVersion}`);
  });

  const activeHash = layoutKeys.find((key) =>
    layout?.items[key].conditions.some((c) => c.active)
  );

  const title = layout?.items[`${activeHash}`]?.title;
  const version = layout?.items[`${activeHash}`]?.version;

  const header = (
    <Box px={2} mt={3} display="flex" alignItems="center">
      <IconButton
        size="small"
        color="primary"
        style={{ padding: "0.125rem" }}
        onClick={() => setExpanded(!expanded)}
        disabled={!started}
      >
        <ExpandIcon style={expanded ? { transform: "rotate(90deg)" } : {}} />
      </IconButton>
      <Box ml={1} mr="auto">
        {expanded ? (
          <Typography color="textSecondary" variant="inherit">
            Build type/assembly
          </Typography>
        ) : started && title ? (
          <Box display="flex" alignItems="center">
            <div style={{ maxWidth: 117 }}>
              <OverflowTooltip title={title}>
                <b>{title}</b>
              </OverflowTooltip>
            </div>
            <NumberBadge value={version} label="Version number" />
          </Box>
        ) : (
          <b>New condition</b>
        )}
      </Box>
      {onAdd ? (
        <IconButton
          size="small"
          color="primary"
          disabled={editMode}
          onClick={() => onAdd()}
        >
          <PlusCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );

  return (
    <Collapse
      in={expanded}
      collapsedSize={60}
      className="small"
      style={{
        background: colors.striping,
        borderBottom: `2px solid ${colors.rules}`,
      }}
    >
      <>
        {header}
        <Box pl={3}>
          {layoutKeys.map((hash, index) => (
            <BuildTypeSubAssembly
              key={index}
              title={`${layout?.items[hash].title}`}
              version={`${layout?.items[hash].version}`}
              items={layout?.items[hash].conditions}
              expanded={hash === activeHash}
              editMode={editMode}
              onToggleCondition={onToggleCondition}
            />
          ))}
        </Box>
      </>
    </Collapse>
  );
};

export default BuildTypeAssembly;
