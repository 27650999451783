import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GreyBadge } from "./Badge";
import ExperimentList from "../features/experiments/List";
import CellList from "../features/cells/List";
import CellSummary from "../features/cells/Summary";
import { useEffect } from "react";
import { CellListState, cellsSlices } from "../features/cells/slice";
import type { RootState } from "../store";
import colors from "../theme/colors";

type LabelProps = {
  text: string;
  count?: number;
};

const Label = ({ text, count }: LabelProps) =>
  isNaN(count as number) ? (
    <>{text}</>
  ) : (
    <GreyBadge badgeContent={`${count}`}>{text}</GreyBadge>
  );

type Props = {
  selectedTab:
    | "ALL_EXPERIMENTS"
    | "ALL_CELLS"
    | "SPECIFIED_CELLS"
    | "STAGED_CELLS"
    | "COMMITTED_CELLS"
    | "TESTING_CELLS";
};

const ExperimentTabs = ({ selectedTab }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { totals } = useSelector<RootState, CellListState>(
    ({ cellList_ALL }) => cellList_ALL
  );

  useEffect(() => {
    dispatch(cellsSlices.ALL.getCellTotals());
  }, [dispatch]);

  const isTablet = useMediaQuery("(min-width:760px)");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    switch (newValue) {
      case "ALL_EXPERIMENTS":
        navigate("/experiments");
        break;
      case "ALL_CELLS":
        navigate("/cells");
        break;
      case "SPECIFIED_CELLS":
        navigate("/cells/specified");
        break;
      case "STAGED_CELLS":
        navigate("/cells/staged");
        break;
      case "COMMITTED_CELLS":
        navigate("/cells/committed");
        break;
      case "TESTING_CELLS":
        navigate("/cells/testing");
        break;
    }
  };

  let contents;
  switch (selectedTab) {
    case "ALL_EXPERIMENTS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <ExperimentList />
        </Box>
      );
      break;
    case "ALL_CELLS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <CellSummary stateKey="ALL" />
          <CellList stateKey="ALL" />
        </Box>
      );
      break;
    case "SPECIFIED_CELLS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <CellSummary stateKey="SPECIFIED" />
          <CellList stateKey="SPECIFIED" status={["S"]} />
        </Box>
      );
      break;
    case "STAGED_CELLS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <CellSummary stateKey="STAGED" />
          <CellList stateKey="STAGED" status={["T"]} />
        </Box>
      );
      break;
    case "COMMITTED_CELLS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <CellSummary stateKey="COMMITTED" />
          <CellList
            stateKey="COMMITTED"
            status={["C", "A", "M", "D", "E", "L"]}
          />
        </Box>
      );
      break;
    case "TESTING_CELLS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <CellSummary stateKey="TESTING" />
          <CellList
            stateKey="TESTING"
            status={["L", "N", "P", "F", "O", "U"]}
          />
        </Box>
      );
      break;
  }

  return (
    <Box
      py={6}
      px={isTablet ? 8 : 3}
      style={{
        backgroundColor: colors.body.light,
        minHeight: "calc(100vh - 48px - 60px)",
      }}
    >
      {isTablet ? (
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            value="ALL_EXPERIMENTS"
            className="qa-experiments-tab"
            label={
              <Tooltip arrow title="All experiments that have been added">
                <span>
                  <Label text="All Experiments" />
                </span>
              </Tooltip>
            }
          />
          <Tab
            value="ALL_CELLS"
            label={
              <Tooltip
                arrow
                title="All cells that have been added for all experiments"
              >
                <span>
                  <Label text="All Cells" />
                </span>
              </Tooltip>
            }
          />
          <Tab
            value="SPECIFIED_CELLS"
            label={
              <Tooltip
                arrow
                title="Cells for which required parameters have been specified and are ready to move forward with planning and building"
              >
                <span>
                  <Label text="Specified" count={totals.S} />
                </span>
              </Tooltip>
            }
          />
          <Tab
            value="STAGED_CELLS"
            label={
              <Tooltip
                arrow
                title="Cells that are ready for Tech Ops managers to plan for building"
              >
                <span>
                  <Label text="Staged" count={totals.T} />
                </span>
              </Tooltip>
            }
          />
          <Tab
            value="COMMITTED_CELLS"
            label={
              <Tooltip
                arrow
                title="Cells that are ready for assembly and filling or are in the build process"
              >
                <span>
                  <Label
                    text="Committed"
                    count={
                      Object.keys(totals).length > 0
                        ? totals.C! + totals.D! + totals.E! + totals.L!
                        : undefined
                    }
                  />
                </span>
              </Tooltip>
            }
          />
          <Tab
            value="TESTING_CELLS"
            label={
              <Tooltip
                arrow
                title="Cells that are ready to be put on test, are on test, ready to be taken off test, or torn down"
              >
                <span>
                  <Label
                    text="Testing +"
                    count={
                      Object.keys(totals).length > 0
                        ? totals.L! +
                          totals.N! +
                          totals.F! +
                          totals.O! +
                          totals.U!
                        : undefined
                    }
                  />
                </span>
              </Tooltip>
            }
          />
        </Tabs>
      ) : null}
      {contents}
    </Box>
  );
};

export default ExperimentTabs;
