import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import client from "../../api";

export const TEST_STANDS = "TEST_STANDS";
type Args = Omit<
  APIListArgs<CharacterizationSample, TestStandFilters>,
  "orderBy"
> & {
  orderBy: keyof TestStandFilters;
};

export interface TestStandState {
  testStands: TestStand[];
  hasMore: boolean;
  selected: string[];
  args: Args;
  grouping: {
    expandedGroups: (string | number)[];
  };
  status: {
    listTestStands: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    listTestStands: null | string;
  };
}

const initialState: TestStandState = {
  testStands: [],
  hasMore: false,
  selected: [],
  grouping: {
    expandedGroups: [],
  },
  args: {
    orderBy: "name",
    order: "asc",
    page: 0,
    filters: {},
  },
  status: {
    listTestStands: "idle",
  },
  error: {
    listTestStands: null,
  },
};

export const listTestStands = createAsyncThunk(
  `${TEST_STANDS}/list`,
  async ({ pageSize = 100, refresh, ...args }: Args) => {
    const pageArg = refresh
      ? `&__offset=0&__limit=${(args.page + 1) * pageSize}`
      : `&__offset=${args.page * pageSize}&__limit=${pageSize}`;
    const sortArg = `&__sort=${args.order === "desc" ? "-" : ""}${
      args.orderBy
    }`;

    let filterArg = "";

    for (let key of Object.keys(args.filters)) {
      const selected =
        (args.filters[key as keyof TestStandFilters] as string[] | User[]) ||
        [];

      if (key === "sample_field__sample_owner") {
        filterArg += (selected as User[])
          .map((sel) => `&${key}=${sel.user_id}`)
          .join("");
        continue;
      }

      filterArg += (selected as string[])
        .map((sel) => `&${key}=${sel}`)
        .join("");
    }

    const response = await client.get(
      `meta/test-stands/advanced?${sortArg}${pageArg}${filterArg}`
    );

    return { response, args: { pageSize, ...args }, refresh };
  }
);

const slice = createSlice({
  name: TEST_STANDS,
  initialState,
  reducers: {
    resetTestStands: (state) => {
      state.testStands = initialState.testStands;
      state.status.listTestStands = "idle";
      state.error.listTestStands = null;
    },
    selectTestStands(state, action: PayloadAction<string[]>) {
      state.selected = uniq([...state.selected, ...action.payload]);
    },
    deselectTestStands(state, action: PayloadAction<string[]>) {
      const selected = [...state.selected];
      action.payload.forEach((fullanme) => {
        const index = selected.indexOf(fullanme);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      });
      state.selected = uniq(selected);
    },
    selectAllVisibleTestStands(state) {
      state.selected = state.testStands.map(
        (testStand) => `${testStand.test_stand_id!}`
      );
    },
    deselectAllVisibleTestStands(state) {
      state.selected = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listTestStands.pending, (state) => {
        state.status.listTestStands = "loading";
      })
      .addCase(listTestStands.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status.listTestStands = "failed";
          state.error.listTestStands = "Unknown error";
          return;
        }

        state.hasMore = payload.response.meta.more;

        state.args = payload.args;
        state.testStands = payload.response.data;
        state.status.listTestStands = "succeeded";
      })
      .addCase(listTestStands.rejected, (state, { error }) => {
        state.status.listTestStands = "failed";
        state.error.listTestStands = error.message as string;
      });
  },
});

export const {
  resetTestStands,
  selectTestStands,
  selectAllVisibleTestStands,
  deselectTestStands,
  deselectAllVisibleTestStands,
} = slice.actions;

export default slice.reducer;
