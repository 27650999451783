import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isArray from "lodash/isArray";
import isNull from "lodash/isNull";
import uniq from "lodash/uniq";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { LARGE_PAGE_SIZE } from "../../api";
import { cellsSlices, CellListState } from "./slice";
import type { AuthState } from "../../store/auth/slice";
import type { RootState } from "../../store";
import useMediaQuery from "@mui/material/useMediaQuery";
import colors from "../../theme/colors";
import {
  CELL_LABELS_ALL,
  CELL_LABELS_DEFAULT,
  CELL_LABELS_EXPERIMENT,
  CELL_FILTER_LABELS,
  cellIdToString,
  cellStatusToString,
  isoDateToDateString,
  FSC_BATTERY_MODULE,
} from "../../utils/labels";
import { CELL_FILTERS } from "../../utils/filters";
import ConditionMenu from "./ConditionMenu";
import ExperimentLink from "../../components/ExperimentLink";
import TableBody from "./TableBody";
import CellTableCell from "./CellTableCell";
import ReadyOffMenu from "./ReadyOffMenu";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import ButtonSort from "../../components/table/ButtonSort";
import ButtonCellFilters from "../../components/table/ButtonCellFilters";
import ButtonSavedCellFilters from "../../components/table/ButtonSavedCellFilters";
import ExpandIcon from "../../icons/Expand";
import GroupIcon from "../../icons/Group";
import ListIcon from "../../icons/List";
import SearchBarIcon from "../../icons/SearchBar";
import ButtonDownloadCsv from "../../components/table/ButtonDownloadCsv";
import ButtonViews from "../../components/table/ButtonViews";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import {
  CustomViewState,
  getCustomViews,
  resetAddViewStatus,
  resetEditViewStatus,
  resetListViewsStatus,
} from "../custom-views/slice";
import PriorityChip from "../../components/PriorityChip";
import ButtonPriority from "../../components/table/ButtonPriority";
import {
  EMPTY_STATE_ROW_HEIGHT,
  GROUPING_HEADER_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import TestModal from "../metadata/layout/TestModal";
import CreateSamplesButton from "../../components/CreateSamplesButton";
import ItemCountHeader from "../../components/table/ItemCountHeader";
import { shiftClickList } from "../../utils/shiftClick";
import {
  TeardownSpecificationsState,
  resetUpdateTeardownFlags,
} from "./modifyTeardownSpecsSlice";

type Props = {
  stateKey: keyof typeof cellsSlices;
  onCellLoad?: (cells: Cell[]) => void;
  hideControls?: boolean;
  exp_id?: string;
  status?: CellStatus[];
  module_id?: string;
};

const CellList = ({
  stateKey,
  onCellLoad,
  hideControls = false,
  exp_id: presetExpId,
  status: presetStatus = [],
  module_id,
}: Props) => {
  const { user, impersonating } = useSelector<RootState, AuthState>(
    ({ auth }) => auth
  );

  const {
    cells,
    visibleCells,
    args: { order, orderBy, filters, presetFilters, page },
    grouping: {
      groupingEnabled,
      allGroupsExpanded,
      expandedGroups,
      expandedConditions,
    },
    selectedView,
    justStaged,
    justCommitted,
    justFlaggedReadyOff,
    justUnflaggedReadyOff,
    justDiscontinued,
    justUndiscontinued,
    selected,
    hasMore,
    status: {
      list: status,
      stage: stageStatus,
      commit: commitStatus,
      priority: priorityStatus,
      readyOff: readyOffStatus,
      takeOff: takeOffStatus,
      discontinue: discontinueStatus,
      modify: modifyStatus,
      channelUsage: channelUsageStatus,
      channelReservation: channelReservationStatus,
      reservationCancellation: reservationCancellationStatus,
      testStandReservation: testStandResStatus,
      testStandReservationCancellation: testStandResCancelStatus,
    },
    warning: { commit: commitWarning },
    error: {
      list: error,
      stage: stageError,
      commit: commitError,
      priority: priorityError,
      readyOff: readyOffError,
      takeOff: takeOffError,
      discontinue: discontinueError,
      modify: modifyError,
      reservationCancellation: reservationCancellationError,
      testStandReservationCancellation: testStandResCancelError,
    },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  const {
    status: { updateTeardownFlags: updateTeardownFlagsStatus },
  } = useSelector<RootState, TeardownSpecificationsState>(
    ({ teardownSpecs }) => teardownSpecs
  );

  // For all tabs except "ALL" the overflow width is 1500px
  const MENUBAR_OVERFLOW_WIDTH = stateKey === "ALL" ? "1300" : "1500";
  const menuBarOverflow = !useMediaQuery(
    `(min-width:${MENUBAR_OVERFLOW_WIDTH}px)`
  );

  const selectedCells = cells.filter((cell) => selected.includes(cell.cell_id));

  useEffect(() => {
    onCellLoad && onCellLoad(cells);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells]);

  const CELL_LABELS = presetExpId
    ? CELL_LABELS_EXPERIMENT
    : selectedView
    ? selectedView.columns.map(
        (id) => CELL_LABELS_ALL.find(({ id: allId }) => id === allId)!
      )
    : CELL_LABELS_DEFAULT;

  const {
    views,
    viewsQueried,
    justEdited: justEditedView,
    status: { list: listViewsStatus, add: addViewStatus, edit: editViewStatus },
    error: { list: listViewsError },
  } = useSelector<RootState, CustomViewState>(({ customViews }) => customViews);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(cellsSlices[stateKey].resetCellListStatus());
    dispatch(cellsSlices[stateKey].resetStageCells());
    dispatch(cellsSlices[stateKey].resetCommitCells());
  }, [dispatch, stateKey]);

  useEffect(() => {
    if (status === "idle") {
      const presetFilters: CellFilters = {};
      if (presetExpId) {
        presetFilters.experiment__exp_id = [`${presetExpId}`];
      }
      if (module_id) {
        presetFilters.module__module_id = [`${module_id}`];
      }
      if (presetStatus.length > 0) {
        presetFilters.status = presetStatus;
      }

      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page,
          pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
          refresh: true,
          useV2API: stateKey === "EXPERIMENT",
          columns:
            stateKey === "EXPERIMENT"
              ? CELL_LABELS.map(({ id }) => id)
              : undefined,
        })
      );

      if (presetStatus.length > 0) {
        dispatch(cellsSlices[stateKey].enableGrouping());
      }
    }
  }, [
    stateKey,
    presetExpId,
    module_id,
    presetStatus,
    dispatch,
    status,
    order,
    orderBy,
    filters,
    presetFilters,
    page,
    CELL_LABELS,
  ]);

  const [viewsLoaded, setViewsLoaded] = useState<typeof stateKey[]>([]);
  const [disableCommitBtn, setDisableCommitBtn] = useState(true);

  useEffect(() => {
    const currentTableId = `CELLS_${stateKey}`;
    if (
      listViewsStatus === "idle" ||
      (listViewsStatus === "succeeded" &&
        !viewsQueried.includes(currentTableId))
    ) {
      dispatch(getCustomViews(currentTableId));
    }
  }, [dispatch, stateKey, listViewsStatus, viewsQueried]);

  useEffect(() => {
    setViewsLoaded([]);
    dispatch(resetListViewsStatus());
  }, [dispatch, user]);

  const onSelectView = useCallback(
    (view: CustomTableView<Cell> | null) => {
      if (view && view.table_id !== `CELLS_${stateKey}`) {
        return;
      }

      const presetFilters: CellFilters = {};
      if (presetExpId) {
        presetFilters.experiment__exp_id = [`${presetExpId}`];
      }
      if (presetStatus.length > 0) {
        presetFilters.status = presetStatus;
      }

      dispatch(cellsSlices[stateKey].selectView(view));

      if (view) {
        if (view.grouped) {
          dispatch(cellsSlices[stateKey].enableGrouping());
        } else {
          dispatch(cellsSlices[stateKey].disableGrouping());
        }
      } else {
        if (presetStatus.length > 0) {
          dispatch(cellsSlices[stateKey].enableGrouping());
        } else {
          dispatch(cellsSlices[stateKey].disableGrouping());
        }
      }

      dispatch(
        cellsSlices[stateKey].listCells({
          order: view
            ? view.order
            : cellsSlices[stateKey].initialState.args.order,
          orderBy: view
            ? view.order_by
            : cellsSlices[stateKey].initialState.args.orderBy,
          filters,
          presetFilters,
          page: 0,
          pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
        })
      );
    },
    [dispatch, filters, presetExpId, presetStatus, stateKey]
  );

  useEffect(() => {
    if (listViewsStatus === "succeeded" && !viewsLoaded.includes(stateKey)) {
      const defaultView = views.find(
        ({ is_default, table_id }) =>
          is_default && table_id === `CELLS_${stateKey}`
      );

      if (defaultView) {
        onSelectView(defaultView);
        setViewsLoaded([...viewsLoaded, stateKey]);
      }
    }
  }, [views, viewsLoaded, listViewsStatus, onSelectView, stateKey]);

  useEffect(() => {
    if (editViewStatus === "succeeded" || addViewStatus === "succeeded") {
      onSelectView(justEditedView!);
      dispatch(resetAddViewStatus());
      dispatch(resetEditViewStatus());
    }
  }, [addViewStatus, editViewStatus, onSelectView, dispatch, justEditedView]);

  let errorToast;
  if (status === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(
            cellsSlices[stateKey].listCells({
              order,
              orderBy,
              filters,
              presetFilters,
              page: 0,
              pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
            })
          )
        }
      >
        {error}
      </Toast>
    );
  } else if (listViewsStatus === "failed") {
    errorToast = (
      <Toast open severity="error">
        {listViewsError}
      </Toast>
    );
  }

  let stageToast;
  if (stageStatus === "failed") {
    stageToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetStageCells())}
      >
        {stageError}
      </Toast>
    );
  } else if (stageStatus === "succeeded" && justStaged.length > 0) {
    stageToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetStageCells())}
      >
        <div>
          {justStaged.length === 1
            ? `${cellIdToString(justStaged[0])} staged`
            : `${justStaged.length} cells staged`}
        </div>
        <Button
          size="small"
          color="tertiary"
          type="button"
          onClick={() =>
            dispatch(
              cellsSlices[stateKey].stageCells({
                cell_ids: justStaged,
                undo: true,
              })
            )
          }
          style={{
            padding: 4,
            minWidth: "auto",
          }}
        >
          <b>Undo</b>
        </Button>
      </Toast>
    );
  }

  useEffect(() => {
    if (stageStatus === "succeeded") {
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(cellsSlices.STAGED.resetCellListStatus());
      dispatch(cellsSlices.COMMITTED.resetCellListStatus());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
        })
      );
    }
  }, [dispatch, stateKey, stageStatus, order, orderBy, filters, presetFilters]);

  let commitToast;
  if (commitStatus === "failed") {
    commitToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetCommitCells())}
      >
        {commitError}
      </Toast>
    );
  } else if (commitStatus === "pending") {
    commitToast = (
      <Toast
        open
        severity="warning"
        onClose={() => dispatch(cellsSlices[stateKey].resetCommitCells())}
      >
        {commitWarning}
      </Toast>
    );
  } else if (commitStatus === "succeeded" && justCommitted.length > 0) {
    commitToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetCommitCells())}
      >
        {justCommitted.length === 1
          ? `${cellIdToString(justCommitted[0])} committed`
          : `${justCommitted.length} cells committed`}
      </Toast>
    );
  }

  useEffect(() => {
    if (commitStatus === "succeeded") {
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(cellsSlices.SPECIFIED.resetCellListStatus());
      dispatch(cellsSlices.STAGED.resetCellListStatus());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    commitStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  let priorityToast;
  if (priorityStatus === "failed") {
    priorityToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetPriority())}
      >
        {priorityError}
      </Toast>
    );
  }

  useEffect(() => {
    if (
      priorityStatus === "succeeded" ||
      reservationCancellationStatus === "succeeded"
    ) {
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    priorityStatus,
    reservationCancellationStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  let modifyTeardownToast;
  if (updateTeardownFlagsStatus === "succeeded") {
    modifyTeardownToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetUpdateTeardownFlags())}
      >
        Teardown specifications updated.
      </Toast>
    );
  }

  let readyOffToast;
  if (readyOffStatus === "failed") {
    readyOffToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetReadyOff())}
      >
        {readyOffError}
      </Toast>
    );
  } else if (readyOffStatus === "succeeded" && justFlaggedReadyOff.length > 0) {
    readyOffToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetReadyOff())}
      >
        {justFlaggedReadyOff.length === 1
          ? `${cellIdToString(justFlaggedReadyOff[0])} flagged as Ready off`
          : `${justFlaggedReadyOff.length} cells marked as Ready off`}
      </Toast>
    );
  } else if (
    readyOffStatus === "succeeded" &&
    justUnflaggedReadyOff.length > 0
  ) {
    readyOffToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetReadyOff())}
      >
        {justUnflaggedReadyOff.length === 1
          ? `Ready off flag removed for ${cellIdToString(
              justUnflaggedReadyOff[0]
            )}`
          : `Ready off flag removed for ${justUnflaggedReadyOff.length} cells`}
      </Toast>
    );
  }

  useEffect(() => {
    if (
      readyOffStatus === "succeeded" ||
      updateTeardownFlagsStatus === "succeeded"
    ) {
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(cellsSlices.ALL.resetCellListStatus());
      dispatch(cellsSlices.TESTING.resetCellListStatus());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    readyOffStatus,
    order,
    orderBy,
    filters,
    presetFilters,
    updateTeardownFlagsStatus,
  ]);

  let discontinueToast;
  if (discontinueStatus === "failed") {
    discontinueToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetDiscontinue())}
      >
        {discontinueError}
      </Toast>
    );
  } else if (discontinueStatus === "succeeded" && justDiscontinued.length > 0) {
    discontinueToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetDiscontinue())}
      >
        {justDiscontinued.length === 1
          ? `${cellIdToString(justDiscontinued[0])} marked as Discontinued`
          : `${justDiscontinued.length} cells marked as Discontinued`}
      </Toast>
    );
  } else if (
    discontinueStatus === "succeeded" &&
    justUndiscontinued.length > 0
  ) {
    discontinueToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetDiscontinue())}
      >
        {justUndiscontinued.length === 1
          ? `${cellIdToString(justUndiscontinued[0])} unmarked as Discontinued`
          : `${justUndiscontinued.length} cells unmarked as Discontinued`}
      </Toast>
    );
  }

  useEffect(() => {
    if (discontinueStatus === "succeeded") {
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    discontinueStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  let takeOffToast;
  if (takeOffStatus === "failed") {
    takeOffToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetTakeOff())}
      >
        {takeOffError}
      </Toast>
    );
  } else if (takeOffStatus === "succeeded") {
    takeOffToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetTakeOff())}
      >
        The selected cell(s) have been taken off test.
      </Toast>
    );
  }

  useEffect(() => {
    if (takeOffStatus === "succeeded") {
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(cellsSlices.ALL.resetCellListStatus());
      dispatch(cellsSlices.TESTING.resetCellListStatus());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    takeOffStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  let modifyToast;
  if (modifyStatus === "failed") {
    modifyToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetModify())}
      >
        {modifyError}
      </Toast>
    );
  } else if (modifyStatus === "succeeded") {
    modifyToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(cellsSlices[stateKey].resetModify())}
      >
        The selected cell(s) have been modified.
      </Toast>
    );
  }
  let testStandReservationToast;
  if (testStandResStatus === "succeeded") {
    testStandReservationToast = (
      <Toast
        open
        severity="success"
        onClose={() =>
          dispatch(cellsSlices[stateKey].resetTestStandReservation())
        }
      >
        Test stand(s) successfully reserved.
      </Toast>
    );
  }

  let cancelReservationToast;
  if (
    reservationCancellationStatus === "failed" ||
    testStandResCancelStatus === "failed"
  ) {
    cancelReservationToast = (
      <Toast
        open
        severity="error"
        onClose={() => {
          dispatch(cellsSlices[stateKey].resetReservationCancellation());
          dispatch(
            cellsSlices[stateKey].resetTestStandReservationCancellation()
          );
        }}
      >
        {reservationCancellationError || testStandResCancelError}
      </Toast>
    );
  } else if (
    reservationCancellationStatus === "succeeded" ||
    testStandResCancelStatus === "succeeded"
  ) {
    cancelReservationToast = (
      <Toast
        open
        severity="success"
        onClose={() => {
          dispatch(cellsSlices[stateKey].resetReservationCancellation());
          dispatch(
            cellsSlices[stateKey].resetTestStandReservationCancellation()
          );
        }}
      >
        The selected cell(s) have had their reservations cancelled.
      </Toast>
    );
  }

  useEffect(() => {
    if (modifyStatus === "succeeded") {
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    modifyStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  useEffect(() => {
    if (channelReservationStatus === "succeeded") {
      dispatch(cellsSlices[stateKey].resetChannelReservation());
      dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(
        cellsSlices[stateKey].listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [
    dispatch,
    stateKey,
    channelReservationStatus,
    reservationCancellationStatus,
    order,
    orderBy,
    filters,
    presetFilters,
  ]);

  /* Test modal */
  const [testModalOpen, setTestModalOpen] = useState(false);
  const testModal = presetStatus.includes("L") ? (
    <TestModal
      open={testModalOpen}
      onClose={() => {
        setTestModalOpen(false);
        dispatch(
          cellsSlices[stateKey].listCells({
            order,
            orderBy,
            filters,
            presetFilters,
            page: 0,
            pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
          })
        );
      }}
      selectedCells={selectedCells}
      reserved_channel={
        selectedCells.find((cell) => !!cell.reserved_channel.channel.fullname)
          ?.reserved_channel.channel.fullname || ""
      }
      executor={user}
      conditionIdsToCellIdsForMetadataFetch={selectedCells.reduce(
        (
          conditionIdsToRequestedCellIds: { [key: string]: string[] },
          selectedCell
        ) => {
          const cellConditionId = selectedCell.condition.cell_condition_id;
          if (!cellConditionId) {
            return conditionIdsToRequestedCellIds;
          }
          if (
            !Object.keys(conditionIdsToRequestedCellIds).includes(
              String(cellConditionId)
            )
          ) {
            conditionIdsToRequestedCellIds[cellConditionId] = [];
          }
          conditionIdsToRequestedCellIds[cellConditionId] = [
            ...conditionIdsToRequestedCellIds[cellConditionId],
            String(selectedCell.cell_id),
          ];
          return conditionIdsToRequestedCellIds;
        },
        {}
      )}
    />
  ) : null;

  const handleSortClick = (e: React.MouseEvent, property: FalconKey<Cell>) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      cellsSlices[stateKey].listCells({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        presetFilters,
        page: 0,
        pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      cellsSlices[stateKey].listCells({
        order,
        orderBy,
        filters,
        presetFilters,
        page: page + 1,
        pageSize: stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
      })
    );
  };

  const conditionGroupingEnabled = [
    "condition__specified",
    "condition__name",
    "condition__description",
    "experiment__exp_id",
  ].includes(orderBy);

  const handleToggleExpandAll = () => {
    if (!allGroupsExpanded && expandedGroups.length > 0) {
      dispatch(cellsSlices[stateKey].collapseAllGroups());
    } else if (allGroupsExpanded) {
      dispatch(cellsSlices[stateKey].collapseAllGroups());
    } else {
      dispatch(cellsSlices[stateKey].expandAllGroups());
    }
  };

  const handleSelectCells = (ids: number[], shiftClick = false) => {
    const allSelected = !ids.some((id) => !selected.includes(id));
    if (allSelected) {
      dispatch(cellsSlices[stateKey].deselectCells(ids));
    } else {
      let idsToSelect = shiftClickList(selected, ids, cells, shiftClick);
      dispatch(cellsSlices[stateKey].selectCells(idsToSelect));
    }
  };

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(cellsSlices[stateKey].deselectAllVisibleCells());
    } else {
      dispatch(cellsSlices[stateKey].selectAllVisibleCells());
    }
  };

  const handleToggleGroupingEnabled = () => {
    if (groupingEnabled) {
      dispatch(cellsSlices[stateKey].disableGrouping());
    } else {
      dispatch(cellsSlices[stateKey].enableGrouping());
    }
  };

  const handleToggleGrouping = (event: React.MouseEvent, value: any) => {
    event.stopPropagation();
    const index = expandedGroups.indexOf(value);
    if (index === -1) {
      dispatch(cellsSlices[stateKey].expandGroup(value));
    } else {
      dispatch(cellsSlices[stateKey].collapseGroup(value));
    }
  };

  const handleToggleConditionGrouping = (
    event: React.MouseEvent,
    value: number
  ) => {
    event.stopPropagation();
    const index = expandedConditions.indexOf(value);
    if (index === -1) {
      dispatch(cellsSlices[stateKey].expandCondition(value));
    } else {
      dispatch(cellsSlices[stateKey].collapseCondition(value));
    }
  };

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (visibleCells.length === 0 && status === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={CELL_LABELS.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No cells to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let lastValue: any;
    let lastConditionValue: any;
    let rowIndex = 0;

    const _orderBy = orderBy;
    tableBody = visibleCells
      .map((row, index) => {
        const res = _orderBy.split("__");
        const orderBy = res[0] as keyof Cell;

        const currentConditionValue = row.condition.cell_condition_id!;
        let prevConditionValue: string | number | null = null;
        let subConditionCells: Cell[] = [];

        let prevRow;
        if (index - 1 >= 0) {
          prevRow = visibleCells[index - 1];
        }

        if (conditionGroupingEnabled) {
          // Special handling for default ungrouped all cells view
          subConditionCells = visibleCells.filter(
            (cell) => cell.condition.cell_condition_id === currentConditionValue
          );

          if (prevRow) {
            prevConditionValue = prevRow.condition.cell_condition_id!;
          }
        }

        let currentValue: string | number;
        let subCells: Cell[] = [];

        if (_orderBy === "condition__specified") {
          currentValue = isoDateToDateString(row.condition.specified);
          subCells = visibleCells.filter(
            (cell) =>
              isoDateToDateString(cell.condition.specified) === currentValue
          );
        } else if (_orderBy === "staged") {
          currentValue = isoDateToDateString(row.staged);
          subCells = visibleCells.filter(
            (cell) => isoDateToDateString(cell.staged) === currentValue
          );
        } else if (_orderBy === "committed") {
          currentValue = isoDateToDateString(row.committed);
          subCells = visibleCells.filter(
            (cell) => isoDateToDateString(cell.committed) === currentValue
          );
        } else if (_orderBy === "test__created_time") {
          currentValue = isoDateToDateString(row.test.created_time);
          subCells = visibleCells.filter(
            (cell) =>
              isoDateToDateString(cell.test.created_time) === currentValue
          );
        } else if (_orderBy === "ready_off__completed_at") {
          currentValue = isoDateToDateString(row.ready_off.completed_at);
          subCells = visibleCells.filter(
            (cell) =>
              isoDateToDateString(cell.ready_off.completed_at) === currentValue
          );
        } else {
          const orderBySubkey = res[1];

          // TODO proper typing
          if (orderBySubkey) {
            currentValue = (row[orderBy] as any)[orderBySubkey];
            subCells = visibleCells.filter(
              (cell) => (cell[orderBy] as any)[orderBySubkey] === currentValue
            );
          } else {
            currentValue = row[orderBy] as string | number;
            subCells = visibleCells.filter(
              (cell) => (cell[orderBy] as string | number) === currentValue
            );
          }
        }

        const isSubRow = groupingEnabled && lastValue === currentValue;

        if (isSubRow && !expandedGroups.includes(lastValue)) {
          return null;
        }

        const isConditionSubRow =
          conditionGroupingEnabled &&
          lastConditionValue === currentConditionValue;

        if (
          isConditionSubRow &&
          !expandedConditions.includes(lastConditionValue)
        ) {
          return null;
        }

        if (
          (conditionGroupingEnabled && !isConditionSubRow) ||
          !conditionGroupingEnabled
        ) {
          rowIndex++;
        }

        const showGroupingHeader =
          groupingEnabled && lastValue !== currentValue;

        if (showGroupingHeader) {
          rowIndex = 0;
        }

        const showConditionGroupingHeader =
          conditionGroupingEnabled &&
          prevConditionValue !== currentConditionValue &&
          subConditionCells.length > 1;

        lastValue = currentValue;
        lastConditionValue = currentConditionValue;

        const isGroupExpanded = expandedGroups.includes(currentValue);
        const isConditionExpanded = expandedConditions.includes(
          currentConditionValue
        );

        let groupingRow;
        if (showGroupingHeader) {
          const indeterminate =
            subCells.some(({ cell_id }) => !selected.includes(cell_id)) &&
            subCells.some(({ cell_id }) => selected.includes(cell_id));

          const checked = !subCells.some(
            ({ cell_id }) => !selected.includes(cell_id)
          );

          const headerLabel = CELL_LABELS.find(({ id }) => id === _orderBy);

          groupingRow = (
            <TableRow
              key={`header-group-${row.cell_id}`}
              className="clickable"
              hover
              onClick={(e) => handleToggleGrouping(e, currentValue)}
            >
              <TableCell
                padding="checkbox"
                style={{
                  paddingTop: 12,
                  height: GROUPING_HEADER_ROW_HEIGHT - 12,
                }}
              >
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={checked}
                  disabled={channelUsageStatus === "loading"}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectCells(
                      subCells.map(({ cell_id }) => cell_id),
                      !!(e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox" style={{ paddingTop: "0.75rem" }}>
                <IconButton
                  size="small"
                  onClick={(e) => handleToggleGrouping(e, currentValue)}
                >
                  <ExpandIcon
                    style={
                      isGroupExpanded ? { transform: "rotate(90deg)" } : {}
                    }
                  />
                </IconButton>
              </TableCell>
              <TableCell
                colSpan={CELL_LABELS.length}
                scope="row"
                padding="none"
              >
                <Box pt={6} pb={2} display="flex" alignItems="center">
                  <Typography variant="h3">{headerLabel?.label}: </Typography>
                  <Box ml={3}>
                    <Typography variant="h3" style={{ fontWeight: 400 }}>
                      {_orderBy === "status" ? (
                        cellStatusToString(row.status) || "-"
                      ) : _orderBy === "cell_id" ? (
                        cellIdToString(row.cell_id) || "-"
                      ) : _orderBy === "priority" ? (
                        <PriorityChip label={row.priority} />
                      ) : _orderBy === "experiment__exp_id" ? (
                        <ExperimentLink
                          exp_id={row.experiment.exp_id}
                          link={row.experiment.link}
                          linkView
                        />
                      ) : (
                        currentValue || "-"
                      )}
                    </Typography>
                  </Box>
                  {_orderBy === "experiment__exp_id" &&
                  row.experiment.description ? (
                    <Box ml={3}>
                      <Typography style={{ fontWeight: 400 }}>
                        {row.experiment.description}
                      </Typography>
                    </Box>
                  ) : null}
                  <Box ml={3}>
                    <Typography style={{ fontWeight: 400 }}>
                      ({subCells.length} cell{subCells.length !== 1 ? "s" : ""})
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          );

          if (!isGroupExpanded && groupingRow) {
            rowHeights.push(GROUPING_HEADER_ROW_HEIGHT);
            return groupingRow;
          }
        }

        let conditionGroupingRow;
        if (conditionGroupingEnabled && showConditionGroupingHeader) {
          const indeterminate = isConditionExpanded
            ? false
            : subConditionCells.some(
                ({ cell_id }) => !selected.includes(cell_id)
              ) &&
              subConditionCells.some(({ cell_id }) =>
                selected.includes(cell_id)
              );

          const checked = isConditionExpanded
            ? selected.includes(row.cell_id)
            : !subConditionCells.some(
                ({ cell_id }) => !selected.includes(cell_id)
              );

          conditionGroupingRow = (
            <TableRow
              hover
              key={`header-condition-${row.condition.cell_condition_id}`}
              className={`clickable ${
                rowIndex % 2 !== 0 ? "alternating" : ""
              } ${checked ? "checked" : ""} ${
                isConditionSubRow ? "subRow" : ""
              }`}
              onClick={(e) =>
                isConditionExpanded
                  ? navigate(`/cells/${row.cell_id}`, { state: { stateKey } })
                  : handleToggleConditionGrouping(e, currentConditionValue)
              }
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={checked}
                  disabled={channelUsageStatus === "loading"}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectCells(
                      isConditionExpanded
                        ? [row.cell_id]
                        : [
                            row.cell_id,
                            ...subConditionCells.map(({ cell_id }) => cell_id),
                          ],
                      !!(e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox">
                <IconButton
                  size="small"
                  onClick={(e) =>
                    handleToggleConditionGrouping(e, currentConditionValue)
                  }
                >
                  <ExpandIcon
                    style={
                      isConditionExpanded ? { transform: "rotate(90deg)" } : {}
                    }
                  />
                </IconButton>
              </TableCell>

              {CELL_LABELS.map(({ id }, index) => (
                <CellTableCell
                  key={index}
                  dataKey={id}
                  cell={row}
                  isConditionExpanded={isConditionExpanded}
                  subConditionCells={subConditionCells}
                />
              ))}

              {presetExpId ? (
                <TableCell style={{ position: "relative" }}>
                  <ConditionMenu
                    exp_id={row.experiment.exp_id}
                    disabled={!!row.backfill}
                    cell_condition_id={row.condition.cell_condition_id!}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          );
        }

        let totalHeight = DATA_ROW_HEIGHT;
        if (groupingRow) totalHeight += GROUPING_HEADER_ROW_HEIGHT;
        rowHeights.push(totalHeight);

        return (
          <React.Fragment key={`grouping-${row.cell_id}`}>
            {groupingRow}
            {conditionGroupingRow}
            {conditionGroupingEnabled && conditionGroupingRow ? null : (
              <TableRow
                hover
                key={row.cell_id}
                className={`clickable ${
                  rowIndex % 2 !== 0 ? "alternating" : ""
                } ${selected.includes(row.cell_id) ? "checked" : ""} ${
                  isConditionSubRow ? "subRow" : ""
                }`}
                onClick={() =>
                  navigate(`/cells/${row.cell_id}`, { state: { stateKey } })
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    checked={selected.includes(row.cell_id)}
                    disabled={channelUsageStatus === "loading"}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      handleSelectCells(
                        [row.cell_id],
                        !!(e.nativeEvent as MouseEvent).shiftKey
                      )
                    }
                  />
                </TableCell>
                <TableCell padding="none" />
                {CELL_LABELS.map(({ id }, index) => (
                  <CellTableCell
                    key={index}
                    dataKey={id}
                    cell={row}
                    isConditionExpanded={true}
                    subConditionCells={[]}
                  />
                ))}
                {presetExpId ? (
                  <TableCell style={{ position: "relative" }}>
                    <ConditionMenu
                      exp_id={row.experiment.exp_id}
                      disabled={!!row.backfill}
                      cell_condition_id={row.condition.cell_condition_id!}
                    />
                  </TableCell>
                ) : null}
              </TableRow>
            )}
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  const cantStage: Record<string, string[]> = {};
  selectedCells
    .filter((cell) => !cell.can_stage.value)
    .forEach((cell) => {
      if (isArray(cantStage[cell.can_stage.reason])) {
        cantStage[cell.can_stage.reason].push(cellIdToString(cell.cell_id));
        cantStage[cell.can_stage.reason].sort();
      } else {
        cantStage[cell.can_stage.reason] = [cellIdToString(cell.cell_id)];
      }
    });

  const cantStageMessage = Object.keys(cantStage)
    .map((reason) => `${reason} (${cantStage[reason].join(", ")})`)
    .join(" ");

  const cantUnstage: Record<string, string[]> = {};
  selectedCells
    .filter((cell) => !cell.can_unstage.value)
    .forEach((cell) => {
      if (isArray(cantUnstage[cell.can_unstage.reason])) {
        cantUnstage[cell.can_unstage.reason].push(cellIdToString(cell.cell_id));
        cantUnstage[cell.can_unstage.reason].sort();
      } else {
        cantUnstage[cell.can_unstage.reason] = [cellIdToString(cell.cell_id)];
      }
    });

  const cantUnstageMessage = Object.keys(cantUnstage)
    .map((reason) => `${reason} (${cantUnstage[reason].join(", ")})`)
    .join(" ");

  const cantCommit: Record<string, string[]> = {};
  selectedCells
    .filter((cell) => !cell.can_commit.value)
    .forEach((cell) => {
      if (isArray(cantCommit[cell.can_commit.reason])) {
        cantCommit[cell.can_commit.reason].push(cellIdToString(cell.cell_id));
        cantCommit[cell.can_commit.reason].sort();
      } else {
        cantCommit[cell.can_commit.reason] = [cellIdToString(cell.cell_id)];
      }
    });

  const cantCommitMessage = Object.keys(cantCommit)
    .map((reason) => `${reason} (${cantCommit[reason].join(", ")})`)
    .join(" ");

  useEffect(() => {
    const disableCommit =
      selected.length === 0 ||
      !!cantCommitMessage ||
      commitStatus === "loading" ||
      status === "loading";
    setDisableCommitBtn(disableCommit);
  }, [selected, cantCommitMessage, commitStatus, status]);

  const uniqueCellCount = selected.length;
  const uniqueConditionCount = !conditionGroupingEnabled
    ? 0
    : uniq(selectedCells.map((cell) => cell.condition.cell_condition_id))
        .length;

  const hideGrouping = !CELL_LABELS.some(({ id }) => id === orderBy);
  const moduleCellSelected = selectedCells.some(
    (cell) => cell.module && !!cell.module.module_id
  );

  const selectedIncludesBackfilledCells = selectedCells.some(
    (cell) => !!cell.backfill
  );

  const fromEndpoint = `/cells${
    stateKey === "ALL" ? "" : `/${stateKey.toLowerCase()}`
  }`;

  return (
    <>
      {!hideControls ? (
        <>
          <Box mb={4} display="flex" alignItems="center">
            <ItemCountHeader itemCount={cells.length} />
            <Box mr={4}>
              <ButtonCellFilters
                labels={CELL_FILTER_LABELS}
                filterOptions={CELL_FILTERS}
                filters={filters}
                hiddenFilters={
                  presetExpId
                    ? ["experiment__exp_id"]
                    : presetStatus && presetStatus.length === 1
                    ? ["status"]
                    : []
                }
                visibleStatuses={presetStatus}
                onChange={(filters) =>
                  dispatch(
                    cellsSlices[stateKey].listCells({
                      order,
                      orderBy,
                      filters,
                      presetFilters,
                      page: 0,
                      pageSize:
                        stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
                    })
                  )
                }
                // only show filter button loading spinner if spinner at bottom
                // is likely below the fold.
                loadingCells={cells.length > 0 && status === "loading"}
              />
            </Box>
            <Box mr={4}>
              <ButtonSavedCellFilters
                stateKey={stateKey}
                onSelect={(filters) =>
                  dispatch(
                    cellsSlices[stateKey].listCells({
                      order,
                      orderBy,
                      filters,
                      presetFilters,
                      page: 0,
                      pageSize:
                        stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
                    })
                  )
                }
              />
            </Box>
            <Box mr={4}>
              <ButtonSort
                value={orderBy}
                items={CELL_LABELS}
                onChange={(orderBy) =>
                  dispatch(
                    cellsSlices[stateKey].listCells({
                      order,
                      orderBy,
                      filters,
                      presetFilters,
                      page: 0,
                      pageSize:
                        stateKey === "COMMITTED" ? LARGE_PAGE_SIZE : undefined,
                    })
                  )
                }
              />
            </Box>

            <Box mr={4} py={1} height="2rem">
              <Divider orientation="vertical" />
            </Box>

            {hideGrouping ? null : groupingEnabled ? (
              <Tooltip arrow title="Switch to list mode">
                <span>
                  <IconButton
                    size="small"
                    onClick={handleToggleGroupingEnabled}
                  >
                    <GroupIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip arrow title="Switch to group mode">
                <span>
                  <IconButton
                    size="small"
                    onClick={handleToggleGroupingEnabled}
                  >
                    <ListIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            <Box ml={hideGrouping ? 0 : 4}>
              <ButtonDownloadCsv
                viewName={`CELLS_${stateKey} ${
                  views.find(
                    ({ custom_view_id }) =>
                      selectedView?.custom_view_id === custom_view_id
                  )?.name || "Default View"
                }`}
                columns={CELL_LABELS}
                filterArgs={{
                  order,
                  orderBy,
                  filters,
                  presetFilters,
                }}
              />
            </Box>

            <Box ml={4}>
              <ButtonViews
                selected={selectedView}
                items={views.filter(
                  ({ table_id }) => table_id === `CELLS_${stateKey}`
                )}
                allColumns={CELL_LABELS_ALL}
                defaultColumns={CELL_LABELS_DEFAULT}
                table_id={`CELLS_${stateKey}`}
                onSelect={(view: CustomTableView<Cell> | null) =>
                  onSelectView(view)
                }
              />
            </Box>

            {selected.length > 0 && (
              <Box ml={4}>
                <ButtonLogEvent
                  itemType={"cell"}
                  objects={selectedCells}
                  shrinkButton={menuBarOverflow}
                />
              </Box>
            )}

            {selected.length > 0 && (
              <CreateSamplesButton
                from={fromEndpoint}
                cell_ids={selected.map((sel) => `${sel}`)}
                shrinkButton={menuBarOverflow}
              />
            )}

            {selected.length > 0 ? (
              <>
                {conditionGroupingEnabled ? (
                  <>
                    <Box className="small" ml="auto" mr={1}>
                      <b>Conditions:</b>
                    </Box>
                    <Box className="small" mr={4}>
                      {uniqueConditionCount}
                    </Box>
                  </>
                ) : null}
                <Box
                  className="small"
                  ml={conditionGroupingEnabled ? 0 : "auto"}
                  mr={1}
                >
                  <b>Cells:</b>
                </Box>
                <Box className="small" mr={4}>
                  {uniqueCellCount}
                </Box>
              </>
            ) : (
              <Box ml="auto" />
            )}

            <Box ml={2}>
              <Tooltip
                arrow
                placement="left"
                title="Note: Cell Priority will not be updated in any Asana tasks that have already been created for selected cell(s)"
                disableHoverListener={
                  presetStatus.includes("S") || presetStatus.includes("T")
                }
              >
                <span>
                  <ButtonPriority
                    disabled={selected.length === 0}
                    onSelect={(priority) =>
                      dispatch(
                        cellsSlices[stateKey].changePriority({
                          condition_ids: uniq(
                            cells
                              .filter(({ cell_id }) =>
                                selected.includes(cell_id)
                              )
                              .map(
                                ({ condition }) => condition.cell_condition_id!
                              )
                          ),
                          priority,
                        })
                      )
                    }
                    conditionNames={uniq(
                      cells
                        .filter(({ cell_id }) => selected.includes(cell_id))
                        .map(({ condition }) => condition.name)
                    )}
                    cellIds={uniq(
                      cells
                        .filter(({ cell_id }) => selected.includes(cell_id))
                        .flatMap(({ condition }) =>
                          condition.cells.map(({ cell_id }) => cell_id)
                        )
                    )}
                  />
                </span>
              </Tooltip>
            </Box>

            {presetStatus.includes("S") ? (
              <Box ml={2}>
                <Tooltip
                  arrow
                  title={cantStageMessage}
                  disableHoverListener={!cantStageMessage}
                >
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      type="button"
                      disabled={selected.length === 0 || !!cantStageMessage}
                      onClick={() =>
                        dispatch(
                          cellsSlices[stateKey].stageCells({
                            cell_ids: selected,
                            undo: false,
                          })
                        )
                      }
                    >
                      Stage
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ) : null}

            {presetStatus.includes("T") ? (
              <Box ml={2}>
                <Tooltip
                  arrow
                  title={cantUnstageMessage}
                  disableHoverListener={!cantUnstageMessage}
                >
                  <span>
                    <Button
                      size="small"
                      color="secondary"
                      type="button"
                      disabled={selected.length === 0 || !!cantUnstageMessage}
                      onClick={() =>
                        dispatch(
                          cellsSlices[stateKey].stageCells({
                            cell_ids: selected,
                            undo: true,
                          })
                        )
                      }
                    >
                      Unstage
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ) : null}

            {!user?.shared_account &&
            !impersonating &&
            (presetStatus.includes("S") || presetStatus.includes("T")) ? (
              <Box ml={2}>
                <Tooltip
                  arrow
                  title={cantCommitMessage}
                  disableHoverListener={!cantCommitMessage}
                >
                  <span>
                    <Button
                      size="small"
                      color={
                        presetStatus.includes("S") ? "secondary" : "primary"
                      }
                      type="button"
                      disabled={disableCommitBtn}
                      onClick={() =>
                        dispatch(cellsSlices[stateKey].commitCells(selected))
                      }
                    >
                      Commit
                      {commitStatus === "loading" && (
                        <CircularProgress
                          sx={{ ml: "8px" }}
                          color="inherit"
                          size={10}
                        />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ) : null}

            {presetStatus.includes("L") ? (
              <Box ml={2}>
                <Tooltip
                  arrow
                  title="Some selected cells are backfilled from TrackerWare. Deselect them to input metadata."
                  disableHoverListener={!selectedIncludesBackfilledCells}
                >
                  <span>
                    <Button
                      size="small"
                      color="tertiary"
                      type="button"
                      onClick={() => {
                        const endpoint = `/metadata/${selectedCells
                          .map(
                            (c) =>
                              `${c.condition.cell_condition_id}-${c.cell_id}`
                          )
                          .join(",")}`;

                        navigate(endpoint, {
                          state: {
                            from: `/cells${
                              stateKey === "ALL"
                                ? ""
                                : `/${stateKey.toLowerCase()}`
                            }`,
                          },
                        });
                      }}
                      disabled={
                        selectedIncludesBackfilledCells || selected.length === 0
                      }
                    >
                      <b>Input metadata</b>
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ) : null}

            {presetStatus.some((statusLetter) =>
              ["S", "T", "C", "N", "L"].includes(statusLetter)
            ) ? (
              <Box ml={2}>
                <ReadyOffMenu
                  stateKey={stateKey}
                  presetStatus={presetStatus}
                  disablePutOn={
                    moduleCellSelected ||
                    selectedCells.filter(
                      (cell) =>
                        !cell.cell_type.allow_batch_on_test ||
                        cell.cell_type.cell_assembly === FSC_BATTERY_MODULE ||
                        !["L", "F"].includes(cell.status) ||
                        cell.backfill ||
                        (selected[0] &&
                          cell.condition.cell_assembly !==
                            cells.find((_cell) => _cell.cell_id === selected[0])
                              ?.condition.cell_assembly)
                    ).length > 0
                  }
                  showAddReserveChannels={true}
                  overrideOnDisableMessage={
                    moduleCellSelected
                      ? "Cells belonging to a module need to be put On-test on their respective experiment details page."
                      : null
                  }
                  onPutOn={() => setTestModalOpen(true)}
                  disableTakeOff={
                    selectedCells.filter(
                      (cell) =>
                        !["P", "N"].includes(cell.status) || cell.backfill
                    ).length > 0
                  }
                  onTakeOff={() =>
                    dispatch(
                      cellsSlices[stateKey].takeOff({
                        cells: selectedCells,
                        user_id: user?.user_id,
                      })
                    )
                  }
                  selected={selectedCells.sort((a, b) =>
                    a.cell_id > b.cell_id ? 1 : -1
                  )}
                  onReadyOff={() =>
                    dispatch(
                      cellsSlices[stateKey].readyOff({
                        cells: selectedCells,
                        cancel: false,
                        user_id: user?.user_id,
                      })
                    )
                  }
                  onCancelReadyOff={() =>
                    dispatch(
                      cellsSlices[stateKey].readyOff({
                        cells: selectedCells,
                        cancel: true,
                        user_id: user?.user_id,
                      })
                    )
                  }
                  onDiscontinue={() =>
                    dispatch(
                      cellsSlices[stateKey].discontinueCells({
                        cells: selectedCells,
                        cancel: false,
                      })
                    )
                  }
                  onCancelDiscontinue={() =>
                    dispatch(
                      cellsSlices[stateKey].discontinueCells({
                        cells: selectedCells,
                        cancel: true,
                      })
                    )
                  }
                />
              </Box>
            ) : null}
          </Box>

          <Divider />
        </>
      ) : null}

      <TableBody
        rowHeights={rowHeights}
        tableBody={tableBody}
        selected={selected}
        hasMore={hasMore}
        cells={cells}
        disabled={channelUsageStatus === "loading"}
        handleToggleSelectAll={handleToggleSelectAll}
        groupingEnabled={groupingEnabled}
        conditionGroupingEnabled={conditionGroupingEnabled}
        handleToggleExpandAll={handleToggleExpandAll}
        allGroupsExpanded={allGroupsExpanded}
        CELL_LABELS={CELL_LABELS}
        presetExpId={presetExpId}
        orderBy={orderBy}
        order={order}
        handleSortClick={handleSortClick}
        handleLoadMoreClick={handleLoadMoreClick}
        hideControls={hideControls}
        status={status}
        errorToast={errorToast}
        stageToast={stageToast}
        commitToast={commitToast}
        priorityToast={priorityToast}
        readyOffToast={readyOffToast}
        modifyTeardownToast={modifyTeardownToast}
        takeOffToast={takeOffToast}
        discontinueToast={discontinueToast}
        modifyToast={modifyToast}
        cancelReservationToast={cancelReservationToast}
        testModal={testModal}
        testStandReservationToast={testStandReservationToast}
      />
    </>
  );
};

export default CellList;
