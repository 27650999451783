import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const TEST_CONDITION_UI = "TEST_CONDITION_UI";

export interface ConditionUIState {
  ui: null | ConditionUICellType[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: null | string;
}

const initialState: ConditionUIState = {
  ui: null,
  status: "idle",
  error: null,
};

export const getUIDefinition = createAsyncThunk(
  `${TEST_CONDITION_UI}/ui`,
  async () => {
    const response = await client.get(`meta/cell-conditions/spec`);
    return response.data;
  }
);

const uiSlice = createSlice({
  name: TEST_CONDITION_UI,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUIDefinition.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUIDefinition.fulfilled, (state, { payload }) => {
        state.ui = payload;
        state.status = "succeeded";
      })
      .addCase(getUIDefinition.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message as string;
      });
  },
});

export default uiSlice.reducer;
