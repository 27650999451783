import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Settings = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 18 18"
  >
    {children}
    <path
      d="M3.28558 4.99986C4.23235 4.99986 4.99986 4.23235 4.99986 3.28557C4.99986 2.3388 4.23235 1.57129 3.28558 1.57129C2.3388 1.57129 1.57129 2.3388 1.57129 3.28557C1.57129 4.23235 2.3388 4.99986 3.28558 4.99986Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 3.28564H16.4286"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99944 10.7142C9.94622 10.7142 10.7137 9.9467 10.7137 8.99993C10.7137 8.05316 9.94622 7.28564 8.99944 7.28564C8.05267 7.28564 7.28516 8.05316 7.28516 8.99993C7.28516 9.9467 8.05267 10.7142 8.99944 10.7142Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.57129 9H7.28558"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7139 9H16.4282"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7143 16.4286C15.6611 16.4286 16.4286 15.6611 16.4286 14.7143C16.4286 13.7675 15.6611 13 14.7143 13C13.7675 13 13 13.7675 13 14.7143C13 15.6611 13.7675 16.4286 14.7143 16.4286Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9999 14.7144H1.57129"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Settings;
