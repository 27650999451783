import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GreyBadge } from "./Badge";
import ChannelList from "../features/channels/List";
import ChannelSummary from "../features/channels/Summary";
import colors from "../theme/colors";
import TestStandsList from "../features/test-stands/TestStandsList";

type LabelProps = {
  text: string;
  count?: number;
};

const Label = ({ text, count }: LabelProps) =>
  isNaN(count as number) ? (
    <>{text}</>
  ) : (
    <GreyBadge badgeContent={`${count}`}>{text}</GreyBadge>
  );

type Props = {
  selectedTab: "CHANNELS" | "TEST_STANDS";
};

const InfrastructureTabs = ({ selectedTab }: Props) => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(min-width:600px)");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    switch (newValue) {
      case "CHANNELS":
        navigate("/infrastructure/channels");
        break;
      case "TEST_STANDS":
        navigate("/infrastructure/test-stands");
        break;
    }
  };

  let contents;
  switch (selectedTab) {
    case "CHANNELS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <ChannelSummary />
          <ChannelList />
        </Box>
      );
      break;
    case "TEST_STANDS":
      contents = (
        <Box mt={isTablet ? 6 : 0}>
          <TestStandsList />
        </Box>
      );
      break;
  }

  return (
    <Box
      py={6}
      px={isTablet ? 8 : 3}
      style={{
        backgroundColor: colors.body.light,
        minHeight: "calc(100vh - 48px - 60px)",
      }}
    >
      {isTablet ? (
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab value="CHANNELS" label={<Label text="Channels" />} />
          <Tab value="TEST_STANDS" label={<Label text="Test Stands" />} />
        </Tabs>
      ) : null}
      {contents}
    </Box>
  );
};

export default InfrastructureTabs;
