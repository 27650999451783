import React, { useCallback, useState, useEffect, useRef } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import colors from "../../theme/colors";
import { createEvent, resetCreateEventStatus } from "./slice";
import type {
  EventsState,
  DynamicEventField,
  ExperimentOwnerMap,
} from "./slice";
import type { RootState } from "../../store";

// MUI
import {
  Autocomplete,
  Box,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControl,
  Input,
  Link,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// Components
import Button from "../Button";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import client from "../../api";
import DeletableCard from "../DeletableCard";
import DynamicEventFieldInput from "./DynamicEventFieldInput";
import SmallChip from "../SmallChip";
import Toast from "../Toast";
import TakingPictureIcon from "../../icons/TakingPicture";
import MuiDateTimePicker from "@mui/lab/DateTimePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDebouncedCallback } from "use-debounce";

export const EVENT_FACILITIES = [
  "200 Inner Belt Lab 1",
  "30 Dane Full Scale",
  "30 Dane Sub Scale",
  "30 Dane Wet Lab",
  "Berkeley",
  "Somerville",
  "Thunderburgh",
];

type Props = {
  itemType: string;
  onClose: () => void;
  onSuccess: (link: string) => void;
  dynamicFields: DynamicEventField[];
  facility: string;
  experimentOwnerMap: ExperimentOwnerMap;
  setExperimentOwnerMap: (input: ExperimentOwnerMap) => void;
};

export interface EventBins {
  [key: string]: {
    [key: string]: string[];
  };
}

const EventForm = ({
  itemType,
  onClose,
  onSuccess,
  dynamicFields,
  facility,
  experimentOwnerMap,
  setExperimentOwnerMap,
}: Props) => {
  const severityLevels = [
    "1. Minor",
    "2. Mild",
    "3. Moderate",
    "4. Severe",
    "5. Critical",
  ];
  const [eventBins, setEventBins] = useState<EventBins>({});
  const [bin, setBin] = useState<string>("");
  const [secondaryBin, setSecondaryBin] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [includeStartDatetime, setIncludeStartDatetime] =
    useState<boolean>(false);
  const [activeExperiments, setActiveExperiments] = useState<number[]>(
    Object.keys(experimentOwnerMap).map((key) => parseInt(key))
  );
  const [ownersString, setOwnersString] = useState<string>(
    activeExperiments.map((exp) => experimentOwnerMap[exp]).join(", ")
  );
  const dispatch = useDispatch();
  const user = useSelector<RootState, null | User>(({ auth }) => auth.user);

  // Image-uploading consts
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  // Prevent MUI number Inputs from changing value on scroll
  const noScroll = Array.from(document.getElementsByClassName("disableScroll"));

  noScroll.forEach((el) => {
    el.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  });

  const { control, handleSubmit, setValue } = useForm<EventFormData>({
    defaultValues: {
      title: "",
      description: "",
      eventBinA: "",
      eventBinB: "",
      eventBinC: "",
      isHumanError: false,
      isResolved: false,
      cycleNumber: "",
      stepNumber: "",
      reporter: user,
      eventDatetimeStart: null,
      eventDatetime: null,
      facility: facility,
      usersToNotify: [],
      images: [],
    },
  });

  const {
    eventLink,
    status: { create: createStatus },
    warning: { create: createWarning },
    error: { create: createError },
  } = useSelector<RootState, EventsState>(({ events }) => events);

  const onSubmit = (event: EventFormData) => {
    const data = { eventType: itemType, ...event };

    // Disallow users from creating resolved events with any "Unknown" bins
    if (
      data["isResolved"] &&
      [data["eventBinA"], data["eventBinB"], data["eventBinC"]].includes(
        "Unknown"
      )
    ) {
      setError('Events can\'t be resolved with "Unknown" bins');
      return;
    }

    if ("cycleNumber" in data && data.cycleNumber === "") {
      delete data.cycleNumber;
    }

    if ("stepNumber" in data && data.stepNumber === "") {
      delete data.stepNumber;
    }

    // Ensure datetime formats are correct
    let eventDatetime: any = data["eventDatetime"];
    let eventDatetimeStart: any = includeStartDatetime
      ? data["eventDatetimeStart"]
      : null;
    if (
      isNaN(eventDatetime) ||
      (eventDatetimeStart && isNaN(eventDatetimeStart))
    ) {
      setError(
        "The event datetime format is incorrect, please use the " +
          'calendar functionality or "Now" button to correct it'
      );
      return;
    }
    if (!includeStartDatetime) data["eventDatetimeStart"] = null;

    dispatch(createEvent(data));
  };

  const _handleUserLookup = useCallback(
    async (useSearch = true) => {
      // useSearch is a boolean denoting whether this
      // lookup is based on the search value or
      // the experiment owners to notify
      if (useSearch && search === null) {
        return;
      }

      setLoading(true);

      try {
        if (useSearch) {
          const response = await client.get(
            `auth/users?name__contains=${search}`
          );
          setUsers(response.data);
        } else {
          if (Object.keys(experimentOwnerMap).length > 0) {
            let url =
              "auth/users?" +
              Object.values(experimentOwnerMap)
                .map((str) => `name=${str}`)
                .join("&");
            const response = await client.get(url);
            setValue("usersToNotify", response.data, { shouldDirty: true });
          }
        }
      } catch (err) {
        setUsers([]);
      }

      setLoading(false);
    },
    [search, experimentOwnerMap, setValue]
  );

  const handleUserLookup = useDebouncedCallback(_handleUserLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleUserLookup();
  }, [search, handleUserLookup]);

  useEffect(() => {
    let owners = activeExperiments.map((exp) => experimentOwnerMap[exp]);
    let uniqueOwners = Array.from(new Set<string>(owners));
    setOwnersString(uniqueOwners.join(", "));
  }, [activeExperiments, experimentOwnerMap]);

  useEffect(() => {
    if (createStatus === "succeeded") {
      onClose();
      onSuccess(eventLink);
      dispatch(resetCreateEventStatus());
    } else if (createStatus === "pending") {
      setWarning(createWarning);
    } else if (createStatus === "failed") {
      setError(createError);
    }
  }, [
    createStatus,
    eventLink,
    onClose,
    onSuccess,
    dispatch,
    createError,
    createWarning,
  ]);

  const getEventBins = useCallback(async () => {
    try {
      const response = await client.get(`meta/events/bins`);
      setEventBins(response);
    } catch (err) {
      setEventBins({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetCreateEventStatus());
    getEventBins();
    handleUserLookup(false);
  }, [dispatch, getEventBins, handleUserLookup]);

  const handleNowButtonClick = () => {
    setValue("eventDatetime", new Date(), { shouldValidate: true });
    if (includeStartDatetime) {
      setValue("eventDatetimeStart", new Date(), { shouldValidate: true });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Bins<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="eventBinA"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Select
                  value={value}
                  error={invalid}
                  onChange={(event) => {
                    setBin(event.target.value);
                    setSecondaryBin("");
                    setValue("eventBinB", "", { shouldDirty: true });
                    setValue("eventBinC", "", { shouldDirty: true });
                    onChange(event);
                  }}
                >
                  {Object.keys(eventBins).map((bin, index) => (
                    <MenuItem key={index} value={bin}>
                      {bin}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {!["", "Other", "Unknown"].includes(bin) && (
            <FormControl style={{ width: "100%", height: "100%" }}>
              <Controller
                control={control}
                name="eventBinB"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <Select
                    value={value}
                    error={invalid}
                    onChange={(event) => {
                      setSecondaryBin(event.target.value);
                      setValue("eventBinC", "", { shouldDirty: true });
                      onChange(event);
                    }}
                    sx={{ mt: 2 }}
                  >
                    {Object.keys(eventBins[bin]).map((bin, index) => (
                      <MenuItem key={index} value={bin}>
                        {bin}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}

          {!["", "Other", "Unknown"].includes(secondaryBin) && (
            <FormControl style={{ width: "100%", height: "100%" }}>
              <Controller
                control={control}
                name="eventBinC"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <Select
                    value={value}
                    error={invalid}
                    onChange={(val) => onChange(val)}
                    sx={{ mt: 2 }}
                  >
                    {eventBins[bin][secondaryBin].map((bin, index) => (
                      <MenuItem key={index} value={bin}>
                        {bin}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Title<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="title"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Input
                  className="small"
                  disableUnderline
                  inputRef={ref}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputProps={{ maxLength: 100 }}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Description<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Input
                  className="small"
                  disableUnderline
                  inputRef={ref}
                  inputComponent={TextareaAutosize}
                  inputProps={{ minRows: 3, maxLength: 4000 }}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Step Number
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="stepNumber"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Input
                  className="small disableScroll"
                  type="number"
                  disableUnderline
                  inputRef={ref}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputProps={{ min: 0, max: 999 }}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Cycle Number
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="cycleNumber"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Input
                  className="small disableScroll"
                  type="number"
                  disableUnderline
                  inputRef={ref}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputProps={{ min: 0, max: 999 }}
                />
              )}
            />
          </FormControl>
        </Box>

        {dynamicFields.map((field, index) => (
          <DynamicEventFieldInput
            key={index}
            control={control}
            field={field}
            ownersString={ownersString}
            experimentOwnerMap={experimentOwnerMap}
            setExperimentOwnerMap={setExperimentOwnerMap}
            setActiveExperiments={setActiveExperiments}
          />
        ))}

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Reporter<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="reporter"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.user_id === value.user_id
                  }
                  value={value}
                  onBlur={onBlur}
                  onOpen={() => setSearch("")}
                  onClose={() => setSearch(null)}
                  onChange={(e, data) => onChange(data)}
                  loading={loading}
                  noOptionsText={
                    search !== null && search.length > 0
                      ? "No results found."
                      : "Start typing..."
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      error={invalid}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        value: search,
                        onChange: (e) => setSearch(e.target.value),
                      }}
                    />
                  )}
                  renderOption={(props, opt) => (
                    <li {...props}>
                      <Checkbox
                        color="secondary"
                        checked={props["aria-selected"] === true}
                      />
                      <Box py={2} width="100%">
                        <Typography
                          color="textPrimary"
                          className={opt.email ? "small" : undefined}
                        >
                          {opt.name}
                        </Typography>
                        {opt.email ? (
                          <Typography color="textSecondary" className="tiny">
                            {opt.email}
                          </Typography>
                        ) : null}
                      </Box>
                    </li>
                  )}
                  renderTags={(values, getTagProps) =>
                    values.map((value, index) => (
                      <Box key={value?.user_id} mr={2} mb={1}>
                        <SmallChip
                          label={value?.name}
                          {...getTagProps({ index })}
                        />
                      </Box>
                    ))
                  }
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Is This Human Error?
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="isHumanError"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Checkbox
                  color="secondary"
                  sx={{ width: "fit-content", marginLeft: "-10px" }}
                  value={value}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="secondary">
            Severity<span style={{ color: colors.accent.red }}>* </span>
            <Link
              href="https://docs.google.com/spreadsheets/d/1hcVUT_oNSnUYwJDnn7rL7pc5g48pd7lQfA_pR27zKBI/edit#gid=455264956"
              target="_blank"
              rel="noreferrer"
            >
              More Info
            </Link>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="severity"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Select
                  value={value || ""}
                  error={invalid}
                  onChange={(val) => onChange(val)}
                >
                  {severityLevels.map((s, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Facility<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="facility"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Select
                  value={value}
                  error={invalid}
                  onChange={(val) => onChange(val)}
                >
                  {EVENT_FACILITIES.map((f, index) => (
                    <MenuItem key={index} value={f}>
                      {f}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Is This Resolved?
            <br />
            Checking this will automatically mark the Asana task as "Complete."
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="isResolved"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Checkbox
                  color="secondary"
                  sx={{ width: "fit-content", marginLeft: "-10px" }}
                  value={value}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Date & Time of Event
            <span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <Button
            color="tertiary"
            style={{
              padding: "0.25rem",
              borderColor: "unset",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onClick={handleNowButtonClick}
          >
            Now
          </Button>

          <Box>
            {includeStartDatetime ? (
              <FormControl style={{ width: "40%", height: "100%" }}>
                <Controller
                  control={control}
                  name="eventDatetimeStart"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <MuiDateTimePicker
                        renderInput={(props) => (
                          <TextField {...props} error={invalid} />
                        )}
                        value={value}
                        onChange={(val) => val && onChange(new Date(val))}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
            ) : null}

            {includeStartDatetime && (
              <span style={{ display: "inline-block", padding: "15px" }}>
                {" "}
                -{" "}
              </span>
            )}

            <FormControl
              style={{
                width: includeStartDatetime ? "40%" : "100%",
                height: "100%",
              }}
            >
              <Controller
                control={control}
                name="eventDatetime"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <MuiDateTimePicker
                      renderInput={(props) => (
                        <TextField {...props} error={invalid} />
                      )}
                      value={value}
                      onChange={(val) => val && onChange(new Date(val))}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                sx={{ width: "fit-content" }}
                checked={includeStartDatetime}
                onChange={() => setIncludeStartDatetime(!includeStartDatetime)}
              />
            }
            label={
              <Typography className="small" color="textSecondary">
                Record a range of times.
              </Typography>
            }
          />
        </Box>

        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Users to Notify<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="usersToNotify"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Autocomplete
                  multiple
                  options={users}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.user_id === value.user_id
                  }
                  value={value}
                  onBlur={onBlur}
                  onChange={(e, data) => onChange(data)}
                  onOpen={() => setSearch("")}
                  onClose={() => setSearch(null)}
                  loading={loading}
                  noOptionsText={
                    search !== null && search.length > 0
                      ? "No results found."
                      : "Start typing..."
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      error={invalid}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        value: search,
                        onChange: (e) => setSearch(e.target.value),
                      }}
                    />
                  )}
                  renderOption={(props, opt) => (
                    <li {...props}>
                      <Checkbox
                        color="secondary"
                        checked={props["aria-selected"] === true}
                      />
                      <Box py={2} width="100%">
                        <Typography
                          color="textPrimary"
                          className={opt.email ? "small" : undefined}
                        >
                          {opt.name}
                        </Typography>
                        {opt.email ? (
                          <Typography color="textSecondary" className="tiny">
                            {opt.email}
                          </Typography>
                        ) : null}
                      </Box>
                    </li>
                  )}
                  renderTags={(values, getTagProps) =>
                    values.map((value, index) => (
                      <Box key={value?.user_id} mr={2} mb={1}>
                        <SmallChip
                          label={value?.name}
                          {...getTagProps({ index })}
                        />
                      </Box>
                    ))
                  }
                />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={6}>
          <FormControl style={{ width: "100%", height: "100%" }}>
            <Controller
              control={control}
              name="images"
              render={({ field: { value, onChange, ref } }) => (
                <Box
                  ref={ref}
                  mb={4}
                  display={!value?.length ? "none" : "flex"}
                  flexWrap="wrap"
                >
                  {value.map(
                    (
                      { publicUrl }: EventFormData["images"][0],
                      index: number
                    ) => (
                      <Box key={index} mr={4} mb={2}>
                        <DeletableCard
                          onDelete={() => {
                            const newImages = [...value];
                            newImages.splice(index, 1);
                            onChange(newImages);
                          }}
                        >
                          <CardMedia
                            sx={{ width: "6rem", height: "6rem" }}
                            image={publicUrl}
                          />
                        </DeletableCard>
                      </Box>
                    )
                  )}
                  <ReactS3Uploader
                    multiple
                    style={{ display: "none" }}
                    accept="image/*"
                    getSignedUrl={client.presignS3Upload}
                    preprocess={(file, next) => {
                      setUploading(true);
                      next(file);
                    }}
                    onProgress={(percent) => setProgress(percent)}
                    onError={(message) => setUploadError(message)}
                    onFinish={(response) => {
                      if (uploadRef.current) {
                        uploadRef.current.value = "";
                      }
                      setUploadError(null);
                      setUploading(false);
                      if (value) {
                        onChange([...value, response]);
                      } else {
                        onChange([response]);
                      }
                    }}
                    inputRef={(input) => (uploadRef.current = input)}
                    uploadRequestHeaders={{}}
                  />
                </Box>
              )}
            />
          </FormControl>

          <Box display="flex" alignItems="center">
            <Button
              color="tertiary"
              style={{ padding: "0.25rem" }}
              disabled={uploading}
              onClick={() => uploadRef.current?.click()}
              startIcon={
                <TakingPictureIcon style={{ width: 24, height: 24 }} />
              }
            >
              <b>Attach photo</b>
            </Button>
            {uploading ? (
              <Box ml={3}>
                <CircularProgressWithLabel value={progress} />
              </Box>
            ) : null}
          </Box>

          {uploadError && (
            <Box mt={6} color={colors.accent.red} className="small">
              {uploadError}
            </Box>
          )}
        </Box>

        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={createStatus === "loading"}
          style={{ marginTop: "20px", width: "fit-content" }}
          endIcon={
            createStatus === "loading" ? (
              <CircularProgress color="inherit" size={20} />
            ) : null
          }
        >
          Submit
        </Button>
      </form>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}

      {warning && (
        <Toast open severity="warning" onClose={() => setWarning(null)}>
          {warning}
        </Toast>
      )}
    </>
  );
};

export default EventForm;
