import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import client from "../../api";
import { getChangeRequestDisplayValue } from "../../utils/conditions";
import { RootState } from "../../store";
import { isEmpty, uniq } from "lodash";

export const CONDITION = "CONDITION";
const MAX_CONDITIONS_TO_REQUEST = 6;

export interface ConditionState {
  conditions: (Condition | ConditionOverviewData)[];
  asyncConditionsCreationInProgress: string[] | null;
  pendingChanges: Record<string, Record<string, any>>;
  changeRequestPreview: ChangeRequest[];
  allAutoApproveFields: string[];
  createdConditionId: number | null;
  status: {
    autoCommit: "idle" | "loading" | "succeeded" | "failed";
    create: "idle" | "loading" | "succeeded" | "failed";
    duplicateFromCell: "idle" | "loading" | "succeeded" | "failed";
    copy: "idle" | "loading" | "succeeded" | "failed";
    delete: "idle" | "loading" | "succeeded" | "failed";
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
    preview: "idle" | "loading" | "succeeded" | "failed";
    specify: "idle" | "loading" | "succeeded" | "failed";
    asyncConditionCopy:
      | "idle"
      | "loading"
      | "succeeded"
      | "retry"
      | "timed_out"
      | "failed";
  };
  error: {
    autoCommit: null | string;
    create: null | string;
    duplicateFromCell: null | string;
    copy: null | string;
    delete: null | string;
    get: null | string;
    save: null | string;
    preview: null | string;
    specify: null | string;
    asyncConditionCopy: null | string;
  };
}

const initialState: ConditionState = {
  conditions: [],
  asyncConditionsCreationInProgress: null,
  pendingChanges: {},
  changeRequestPreview: [],
  allAutoApproveFields: [],
  createdConditionId: null,
  status: {
    autoCommit: "idle",
    create: "idle",
    duplicateFromCell: "idle",
    copy: "idle",
    delete: "idle",
    get: "idle",
    save: "idle",
    preview: "idle",
    specify: "idle",
    asyncConditionCopy: "idle",
  },
  error: {
    autoCommit: null,
    create: null,
    duplicateFromCell: null,
    copy: null,
    delete: null,
    get: null,
    save: null,
    preview: null,
    specify: null,
    asyncConditionCopy: null,
  },
};

interface GetConditionArgs {
  exp_id: number;
  condition_ids_requested?: (number | string)[] | null;
  greedyLoad?: boolean;
  createdConditionId?: number;
}

const getConditionsForExperiment = async (
  {
    exp_id,
    condition_ids_requested,
    greedyLoad,
    createdConditionId,
  }: GetConditionArgs,
  thunkApi?: { getState: () => unknown }
) => {
  const conditionIdsCopy = condition_ids_requested
    ? [...condition_ids_requested]
    : condition_ids_requested;
  if (
    greedyLoad &&
    !isEmpty(conditionIdsCopy) &&
    conditionIdsCopy!.length < MAX_CONDITIONS_TO_REQUEST &&
    !!thunkApi
  ) {
    // Maximize calls to backend by requesting full data for conditions near
    // cell_condition_id that UI or user has requested.
    const { condition: conditionState } = thunkApi.getState() as RootState;
    const primaryCondition = conditionState.conditions.find(
      ({ cell_condition_id }) =>
        cell_condition_id === Number(conditionIdsCopy![0])
    )!;
    // 1. Find cell_conditions that do not have full data.
    const conditionsWithPartialData = conditionState.conditions.filter(
      (condition_) => "basic_info" in condition_ && condition_.basic_info
    );
    // 2. Order those cell_conditions by proximity to cell_condition requested
    //  by UI/user.
    const sortedConditionsWithPartialData = conditionsWithPartialData.sort(
      (
        { cell_condition_id: conditionId1 },
        { cell_condition_id: conditionId2 }
      ) => {
        const diff1 = Math.abs(
          primaryCondition.cell_condition_id! - conditionId1!
        );
        const diff2 = Math.abs(
          primaryCondition.cell_condition_id! - conditionId2!
        );
        return diff1 - diff2;
      }
    );
    // 3. Iterate through grouped partial-data cell_conditions collection and group
    //  them by cell assembly, template_hash, and template_verison.
    //  - using .every() allows iteration to stop if a condition is met, in this case,
    //    if cell_conditions to request hits MAX_CONDITIONS_TO_REQUEST.
    sortedConditionsWithPartialData.every(
      ({
        template_hash,
        template_version,
        cell_assembly,
        cell_condition_id,
      }) => {
        if (
          template_hash === primaryCondition.template_hash &&
          cell_assembly === primaryCondition.cell_assembly &&
          template_version === primaryCondition.template_version &&
          !conditionIdsCopy!.includes(cell_condition_id!)
        ) {
          conditionIdsCopy!.push(cell_condition_id!);
        }
        if (conditionIdsCopy!.length === MAX_CONDITIONS_TO_REQUEST) {
          return false;
        }
        return true;
      }
    );

    // 4. If cell_conditions to request has not yet hit MAX_CONDITIONS_TO_REQUEST,
    //  continue to add cell_conditions from sorted collection until it does.
    sortedConditionsWithPartialData.every(({ cell_condition_id }) => {
      if (conditionIdsCopy!.length === MAX_CONDITIONS_TO_REQUEST) {
        return false;
      }
      if (!conditionIdsCopy!.includes(cell_condition_id!)) {
        conditionIdsCopy!.push(cell_condition_id!);
      }
      return true;
    });
  }
  const { data: conditions }: { data: (Condition | ConditionOverviewData)[] } =
    await client.get(
      `meta/experiments/${exp_id}/conditions${
        !!conditionIdsCopy && conditionIdsCopy.length > 0
          ? "?" +
            conditionIdsCopy
              .map(
                (conditionIdsCopy) =>
                  `condition_ids_requested=${conditionIdsCopy}`
              )
              .join("&")
          : ""
      }`
    );

  conditions.sort((a, b) =>
    a.cell_condition_id! > b.cell_condition_id!
      ? 1
      : b.cell_condition_id! > a.cell_condition_id!
      ? -1
      : 0
  );
  const conditionsWithFullData = conditions.filter(
    (condition) => !("basic_info" in condition) || !condition.basic_info
  );
  const components = conditionsWithFullData
    .map(({ items }) =>
      items!
        .flatMap((item) =>
          item.fields ? [item as ConditionUIStep] : item.items!
        )
        .map(({ entity_type, condition_component_id, test_condition_id }) => ({
          entity_type,
          condition_component_id,
          test_condition_id,
        }))
    )
    .flatMap((items) => items);

  const results: BatchResponse<ChangeRequest[]>[] = await client.post(
    "core/batch",
    [
      {
        method: "GET",
        path: `/api/v1/meta/change-requests?table=condition_component&status=P${components
          .filter(({ entity_type }) => entity_type === "condition_component")
          .map(
            ({ condition_component_id }) =>
              `&record_id=${condition_component_id}`
          )
          .join("")}`,
        data: {},
      },
      {
        method: "GET",
        path: `/api/v1/meta/change-requests?table=test_condition&status=P${components
          .filter(({ entity_type }) => entity_type === "test_condition")
          .map(({ test_condition_id }) => `&record_id=${test_condition_id}`)
          .join("")}`,
        data: {},
      },
    ]
  );

  const changeRequests = results.flatMap(({ body: { data } }) => data);
  const pendingChanges: Record<string, Record<string, any>> = {};

  changeRequests.forEach(
    ({ table, record_id, field, field_type, new_value }) => {
      pendingChanges[`${table}_${record_id}`] = {
        ...pendingChanges[`${table}_${record_id}`],
        [field]: getChangeRequestDisplayValue(new_value, field, field_type),
      };
    }
  );

  return Promise.resolve({
    conditions,
    pendingChanges,
    createdConditionId,
  });
};

export const getConditions = createAsyncThunk(
  `${CONDITION}/get`,
  async (conditionArgs: GetConditionArgs, thunkApi) =>
    getConditionsForExperiment(conditionArgs, thunkApi)
);

export const createCondition = createAsyncThunk(
  `${CONDITION}/create`,
  async (condition: Omit<Condition, "items" | "status">) => {
    const { data: createdCondition }: { data: Condition } = await client.post(
      `meta/cell-conditions`,
      condition
    );
    return { createdConditionId: createdCondition.cell_condition_id! };
  }
);

export const duplicateCondition = createAsyncThunk(
  `${CONDITION}/duplicate`,
  async (conditionId: number) => {
    const { data: newCondition }: { data: Condition } = await client.post(
      `meta/cell-conditions/${conditionId}/copy`,
      {}
    );

    return getConditionsForExperiment({
      exp_id: newCondition.exp_id,
      condition_ids_requested: [conditionId, newCondition.cell_condition_id!],
    });
  }
);

export const deleteCondition = createAsyncThunk(
  `${CONDITION}/delete`,
  async (conditionId: number) => {
    await client.delete(`meta/cell-conditions/${conditionId}`, {});
    return conditionId;
  }
);

export const getPendingConditionCopy = createAsyncThunk(
  `${CONDITION}/get-pending-copies`,
  async ({
    conditionNames,
    expId,
  }: {
    conditionNames: string[];
    expId: number | string;
  }) => {
    let fetchConditionCopyArgs = `exp_id=${expId}`;
    conditionNames.forEach((name_) => {
      fetchConditionCopyArgs += `&name=${name_}`;
    });
    const conditionData = await client.get(
      `meta/cell-conditions/advanced?${fetchConditionCopyArgs}`
    );
    if (
      conditionData.data &&
      conditionData.data.length === conditionNames.length
    ) {
      return Promise.resolve({ conditions: conditionData.data });
    }
    return Promise.resolve({ conditions: [] });
  }
);

export const duplicateConditionFromCell = createAsyncThunk(
  `${CONDITION}/copy-cell`,
  async ({
    cell_id,
    exp_id,
    newConditionNames,
    specifyConditions,
    cellReplicatesPerCondition,
  }: {
    cell_id: number;
    exp_id: string;
    newConditionNames: string[];
    specifyConditions: boolean;
    cellReplicatesPerCondition: number;
  }) => {
    const { data: newCondition, async: asyncConditionCopy } = await client.post(
      `meta/cells/${cell_id}/copy-condition`,
      {
        exp_id: parseInt(exp_id),
        condition_names_to_create: newConditionNames,
        specify_conditions: !!specifyConditions,
        replicates: cellReplicatesPerCondition,
      }
    );

    if (asyncConditionCopy) {
      return { asyncConditionsCreationInProgress: newConditionNames };
    } else {
      const conditionsData = await getConditionsForExperiment({
        exp_id: parseInt(exp_id),
        condition_ids_requested: [newCondition.cell_condition_id!],
      });
      return {
        ...conditionsData,
        createdConditionId: newCondition.cell_condition_id!,
      };
    }
  }
);

type SaveConditionsArgs = Omit<Condition, "values" | "items"> & {
  items: Record<string, any>;
};

export const saveConditions = createAsyncThunk(
  `${CONDITION}/save`,
  async ({
    conditions,
    exp_id,
    otherVisibleConditionIds,
  }: {
    conditions: SaveConditionsArgs[];
    exp_id: string;
    otherVisibleConditionIds?: number[];
  }) => {
    if (conditions.length === 0) {
      const data = await getConditionsForExperiment({
        exp_id: parseInt(exp_id),
      });
      return Promise.resolve({ ...data, errors: [] });
    }

    const updates = conditions.flatMap(
      ({
        name,
        description,
        replicates,
        plan_test_start_date,
        pool,
        cell_condition_id,
        build_config,
        build_phase,
        items = {},
      }) => {
        return [
          {
            method: "PUT",
            path: `/api/v1/meta/cell-conditions/${cell_condition_id}`,
            data: {
              name,
              description,
              replicates,
              plan_test_start_date,
              pool,
              build_config,
              build_phase,
            },
          },
          ...Object.keys(items).map((key: string) => ({
            method: "PUT",
            path: items[key]["api_path"],
            data: omit(items[key], "api_path"),
          })),
        ];
      }
    );

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    const data = await getConditionsForExperiment({
      exp_id: parseInt(exp_id),
      condition_ids_requested: [
        ...conditions.map((condition_) => condition_.cell_condition_id!),
        ...(otherVisibleConditionIds || []),
      ],
    });
    return Promise.resolve({ ...data, errors });
  }
);

export const specifyConditions = createAsyncThunk(
  `${CONDITION}/specify`,
  async ({
    conditionIds,
    exp_id,
  }: {
    conditionIds: number[];
    exp_id: string;
  }) => {
    const updates = conditionIds.map((conditionId) => ({
      method: "POST",
      path: `/api/v1/meta/cell-conditions/${conditionId}/specify`,
      data: {},
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);
    const data = await getConditionsForExperiment({
      exp_id: parseInt(exp_id),
      condition_ids_requested: conditionIds,
    });
    return Promise.resolve({ ...data, errors });
  }
);

export const autoCommitCells = createAsyncThunk(
  `${CONDITION}/commit`,
  async (autoCommitPayload: { cell_ids: number[]; exp_id: string }) => {
    const { cell_ids, exp_id } = autoCommitPayload;

    const updates = cell_ids.map((cell_id) => ({
      method: "POST",
      path: `/api/v1/meta/cells/${cell_id}/stage`,
      data: {},
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);
    if (errors.length > 0) {
      return Promise.resolve({ errors });
    }

    await client.post("meta/cells/commit", { cell_ids });

    return getConditionsForExperiment({
      exp_id: parseInt(exp_id),
      condition_ids_requested: uniq(
        results.map((result_) => result_.body.data.cell_condition_id)
      ),
    });
  }
);

export const previewConditionChanges = createAsyncThunk(
  `${CONDITION}/preview`,
  async ({ conditions }: { conditions: SaveConditionsArgs[] }) => {
    const updates = conditions.flatMap(({ items = {} }) => {
      return [
        ...Object.keys(items).map((key: string) => ({
          method: "PUT",
          path: items[key]["api_path"],
          data: {
            dry_run: true,
            ...omit(items[key], "api_path"),
          },
        })),
      ];
    });

    const result: BatchResponseWithoutData<{
      requests: ChangeRequest[];
      all_auto_approve_fields: string[];
    }>[] = await client.post("core/batch", updates);
    return Promise.resolve(
      result.flatMap(({ body }) => ({
        requests: body.requests,
        all_auto_approve_fields: body.all_auto_approve_fields,
      }))
    );
  }
);

const slice = createSlice({
  name: CONDITION,
  initialState,
  reducers: {
    setFetchConditionCopyStatusTimedOut: (state) => {
      state.status.asyncConditionCopy = "timed_out";
    },
    resetAutoCommit: (state) => {
      state.status.autoCommit = "idle";
      state.error.autoCommit = null;
    },
    resetDeleteCondition: (state) => {
      state.status.delete = "idle";
      state.error.delete = null;
    },
    resetCreateCondition: (state) => {
      state.status.create = "idle";
      state.error.create = null;
      state.createdConditionId = null;
    },
    resetDuplicateCondition: (state) => {
      state.status.copy = "idle";
      state.error.copy = null;
    },
    resetFetchConditionCopyStatus: (state) => {
      state.asyncConditionsCreationInProgress = null;
      state.status.asyncConditionCopy = "idle";
      state.error.asyncConditionCopy = null;
      state.status.copy = "idle";
      state.error.copy = null;
    },
    resetSaveCondition: (state) => {
      state.status.save = "idle";
      state.error.save = null;
    },
    resetSpecifyCondition: (state) => {
      state.status.specify = "idle";
      state.error.specify = null;
    },
    resetDuplicateConditionFromCell: (state) => {
      state.status.duplicateFromCell = "idle";
      state.error.duplicateFromCell = null;
      state.createdConditionId = null;
    },
    resetPreviewChangeRequests: (state) => {
      state.status.preview = "idle";
      state.error.preview = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCondition.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(
        createCondition.fulfilled,
        (state, { payload: { createdConditionId } }) => {
          state.status.create = "succeeded";
          state.createdConditionId = createdConditionId;
        }
      )
      .addCase(createCondition.rejected, (state, { error }) => {
        state.status.create = "failed";
        state.error.create = error.message as string;
      })
      .addCase(duplicateConditionFromCell.pending, (state) => {
        state.status.duplicateFromCell = "loading";
      })
      .addCase(duplicateConditionFromCell.fulfilled, (state, { payload }) => {
        if ("conditions" in payload) {
          state.status.duplicateFromCell = "succeeded";
          state.status.asyncConditionCopy = "succeeded";
          state.error.asyncConditionCopy = null;
          state.asyncConditionsCreationInProgress = null;
        }
        if ("pendingChanges" in payload) {
          state.pendingChanges = payload.pendingChanges as Record<
            string,
            Record<string, any>
          >;
        }
        if ("createdConditionId" in payload) {
          state.createdConditionId = payload.createdConditionId as number;
        }
        if ("asyncConditionsCreationInProgress" in payload) {
          state.asyncConditionsCreationInProgress =
            payload.asyncConditionsCreationInProgress as string[];
          state.status.asyncConditionCopy = "loading";
        }
      })
      .addCase(duplicateConditionFromCell.rejected, (state, { error }) => {
        state.status.duplicateFromCell = "failed";
        state.error.duplicateFromCell = error.message as string;
      })
      .addCase(getPendingConditionCopy.rejected, (state, { error }) => {
        state.status.asyncConditionCopy = "failed";
        state.error.asyncConditionCopy = error.message as string;
      })
      .addCase(getPendingConditionCopy.pending, (state) => {
        state.status.asyncConditionCopy = "loading";
      })
      .addCase(getPendingConditionCopy.fulfilled, (state, { payload }) => {
        // the payload should only have conditions if # of conditions
        // returned from API == # of names in asyncConditionsCreationInProgress
        if (payload.conditions && payload.conditions.length > 0) {
          state.status.asyncConditionCopy = "succeeded";
          state.status.duplicateFromCell = "succeeded";
          state.status.copy = "idle";
          state.asyncConditionsCreationInProgress = null;
        } else {
          state.status.asyncConditionCopy = "retry";
        }
      })
      .addCase(deleteCondition.pending, (state) => {
        state.status.delete = "loading";
      })
      .addCase(deleteCondition.fulfilled, (state, { payload: deletedId }) => {
        state.status.delete = "succeeded";
        state.conditions = state.conditions.filter(
          (c) => c.cell_condition_id !== deletedId
        );
      })
      .addCase(deleteCondition.rejected, (state, { error }) => {
        state.status.delete = "failed";
        state.error.delete = error.message as string;
      })
      .addCase(duplicateCondition.pending, (state) => {
        state.status.copy = "loading";
      })
      .addCase(
        duplicateCondition.fulfilled,
        (state, { payload: { conditions, pendingChanges } }) => {
          state.status.copy = "succeeded";
          state.conditions = conditions;
          state.pendingChanges = pendingChanges;
        }
      )
      .addCase(duplicateCondition.rejected, (state, { error }) => {
        state.status.copy = "failed";
        state.error.copy = error.message as string;
      })
      .addCase(getConditions.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(
        getConditions.fulfilled,
        (state, { payload: { conditions, pendingChanges } }) => {
          const stateConditionsCopy = state.conditions || [];
          state.conditions = conditions.reduce(
            (stateConditionsCopy_, condition_) => {
              if ("basic_info" in condition_ && condition_.basic_info) {
                const existingFullConditionObj = stateConditionsCopy_.find(
                  (dataObj) =>
                    dataObj.cell_condition_id ===
                      condition_.cell_condition_id &&
                    (!("basic_info" in dataObj) || !dataObj.basic_info)
                );
                if (existingFullConditionObj) {
                  // We already have full data for condition,
                  // prefer existing condition data over incoming basic_info item.
                  return stateConditionsCopy_;
                }
              }
              return [
                ...stateConditionsCopy_.filter(
                  (existingObj) =>
                    existingObj.cell_condition_id !==
                    condition_.cell_condition_id
                ),
                condition_,
              ];
            },
            stateConditionsCopy
          );

          state.status.get = "succeeded";
          state.pendingChanges = pendingChanges;
        }
      )
      .addCase(getConditions.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      .addCase(saveConditions.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(
        saveConditions.fulfilled,
        (state, { payload: { conditions, pendingChanges, errors } }) => {
          if (errors.length === 0) {
            state.status.save = "succeeded";
          } else {
            state.status.save = "failed";
            state.error.save = errors.join(" ");
          }
          state.conditions = conditions;
          state.pendingChanges = pendingChanges;
        }
      )
      .addCase(saveConditions.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      })
      .addCase(previewConditionChanges.pending, (state) => {
        state.status.preview = "loading";
      })
      .addCase(previewConditionChanges.fulfilled, (state, { payload }) => {
        state.status.preview = "succeeded";
        state.changeRequestPreview = payload.flatMap(
          ({ requests }) => requests
        );
        state.allAutoApproveFields = payload.flatMap(
          ({ all_auto_approve_fields }) => all_auto_approve_fields
        );
      })
      .addCase(previewConditionChanges.rejected, (state, { error }) => {
        state.status.preview = "failed";
        state.error.preview = error.message as string;
      })
      .addCase(autoCommitCells.pending, (state) => {
        state.status.autoCommit = "loading";
      })
      .addCase(autoCommitCells.fulfilled, (state, { payload }) => {
        if ("errors" in payload) {
          state.status.autoCommit = "failed";
          state.error.autoCommit = payload.errors.join(", ");
        } else {
          state.status.autoCommit = "succeeded";
          state.conditions = payload.conditions;
        }
      })
      .addCase(autoCommitCells.rejected, (state, { error }) => {
        state.status.autoCommit = "failed";
        state.error.autoCommit = error.message as string;
      })
      .addCase(specifyConditions.pending, (state) => {
        state.status.specify = "loading";
      })
      .addCase(
        specifyConditions.fulfilled,
        (state, { payload: { conditions, pendingChanges, errors } }) => {
          if (errors.length === 0) {
            state.status.specify = "succeeded";
          } else {
            state.status.specify = "failed";
            state.error.specify = errors.join(" ");
          }
          state.conditions = conditions;
          state.pendingChanges = pendingChanges;
        }
      )
      .addCase(specifyConditions.rejected, (state, { error }) => {
        state.status.specify = "failed";
        state.error.specify = error.message as string;
      });
  },
});

export const {
  setFetchConditionCopyStatusTimedOut,
  resetDeleteCondition,
  resetCreateCondition,
  resetDuplicateCondition,
  resetFetchConditionCopyStatus,
  resetSaveCondition,
  resetSpecifyCondition,
  resetDuplicateConditionFromCell,
  resetPreviewChangeRequests,
  resetAutoCommit,
} = slice.actions;

export default slice.reducer;
