import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const ConversionPath = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, width: "32px", height: "32px", fill: "black" }}
    viewBox="0 -960 960 960"
  >
    {children}
    <path
      d="M766-120q-41 0-71.5-24.5T656-204H443q-66 0-109.5-43.5T290-357q0-66 43.5-109.5T443-510h77q41 0 67-26t26-67q0-41-26-67t-67-26H304q-9 35-39 59.5T194-612q-48 0-81-33t-33-81q0-48 33-81t81-33q41 0 71 24.5t39 59.5h216q66 0 109.5 43.5T673-603q0 66-43.5 109.5T520-450h-77q-41 0-67 26t-26 67q0 41 26 67t67 26h213q9-35 39-59.5t71-24.5q48 0 81 33t33 81q0 48-33 81t-81 33ZM194-672q23 0 38.5-15.5T248-726q0-23-15.5-38.5T194-780q-23 0-38.5 15.5T140-726q0 23 15.5 38.5T194-672Z"
      stroke="currentColor"
      strokeWidth=".5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ConversionPath;
