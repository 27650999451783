import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";

import Banner from "../../../components/Banner";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import ArrowBackIcon from "../../../icons/ArrowBack";
import PaperWriteIcon from "../../../icons/PaperWrite";
import PencilWriteIcon from "../../../icons/PencilWrite";
import TimesIcon from "../../../icons/Times";
import { cellsSlices } from "../../cells/slice";
import flatten from "../../../utils/flatten";
import NoteListSidePanel from "../../notes/NoteListSidePanel";
import NoteFormSidePanel from "../../notes/NoteFormSidePanel";
import ButtonLogEvent from "../../../components/table/ButtonLogEvent";
import type { AuthState } from "../../../store/auth/slice";
import type { RootState } from "../../../store";
import CreateSamplesButton from "../../../components/CreateSamplesButton";
import { isEmpty } from "lodash";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 1,
  position: "fixed",
});

type Props = {
  hasPendingChanges: boolean;
  onSave: (callback?: () => void) => void;
  eventData: EventData[] | null;
  activeCellIds: number[];
};

const Header = ({
  hasPendingChanges,
  onSave,
  eventData,
  activeCellIds,
}: Props) => {
  const location = useLocation();
  const { from: _from } = (location.state as { from: string }) || { from: "" };
  const [from, setFrom] = useState<string>(_from);

  useEffect(() => {
    if (_from) {
      setFrom(_from);
    }
  }, [_from]);

  const [exiting, setExiting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { formState, getValues, trigger } = useFormContext() || {};
  const { isDirty } = formState || {};

  const [isSaving, setIsSaving] = useState(false);

  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const handleExit = useCallback(
    (skipDirty = false) => {
      if (isDirty && !skipDirty) {
        setExiting(true);
      } else {
        Object.keys(cellsSlices).forEach((key) => {
          dispatch(
            cellsSlices[key as keyof typeof cellsSlices].resetCellListStatus()
          );
        });
        navigate(from || "/cells/committed");
      }
    },
    [dispatch, from, navigate, isDirty]
  );

  const handleSave = useCallback(async () => {
    const flattenMetadata = (metadata: MetadataStepCategory, index: number) => {
      const { items } = metadata;
      const itemKeys = Object.keys(
        flatten(
          items!.map((item) => (!!item.completed?.completed_at ? item : null)),
          `metadata.${index}.items`,
          2
        )
      );

      return itemKeys.filter(
        (key) => !key.includes("completed") && !key.includes("api_path")
      );
    };

    const { metadata = [] } = getValues();
    const keysToValidate = metadata
      .flatMap(flattenMetadata)
      .filter((key: string[] | null) => key !== null);

    if (keysToValidate.length > 0) {
      const result = await trigger(keysToValidate);
      if (!result) {
        return;
      }
    }

    setIsSaving(true);
    onSave();
  }, [onSave, getValues, trigger]);

  useEffect(() => {
    if (!isDirty) {
      setIsSaving(false);
    }
  }, [isDirty]);

  const [showChangesWarning, setShowChangesWarning] = useState(false);
  useEffect(() => {
    setShowChangesWarning(hasPendingChanges);
  }, [hasPendingChanges]);

  const [noteListPanelOpen, setNoteListPanelOpen] = useState(false);
  const [noteFormPanelOpen, setNoteFormPanelOpen] = useState(false);
  const [cellIds, setCellIds] = useState<number[]>([]);

  const handleOpenNotesPanel = useCallback(
    (openPanel: () => void) => {
      setCellIds(activeCellIds);
      openPanel();
    },
    [activeCellIds]
  );

  const handleOpenPendingChangeRequests = useCallback(() => {
    const { cell_condition_id } = getValues();
    navigate(`/change-requests/${cell_condition_id}`);
  }, [getValues, navigate]);

  const canReviewChangeRequests = user?.role === "O";

  let pendingChangesBanner;
  if (showChangesWarning) {
    pendingChangesBanner = (
      <Box
        position="fixed"
        style={{ top: 74, left: 254, width: "calc(100vw - 302px)" }}
        m={4}
        zIndex={10}
      >
        <Banner
          severity="warning"
          style={{ cursor: canReviewChangeRequests ? "pointer" : "auto" }}
          onClick={
            canReviewChangeRequests
              ? handleOpenPendingChangeRequests
              : undefined
          }
          onClose={() => setShowChangesWarning(false)}
        >
          This condition has pending specification change requests.
        </Banner>
      </Box>
    );
  }

  return (
    <>
      <HeaderContainer variant="outlined" sx={{ zIndex: "3 !important" }}>
        <Box px={6} py={3} display="flex" alignItems="center">
          <IconButton size="large" onClick={() => handleExit()}>
            <ArrowBackIcon />
          </IconButton>
          <Box flexShrink={0}>
            <Typography variant="h2">Specifications and metadata</Typography>
          </Box>
          <Box ml="auto" display="flex" alignItems="center" flexShrink={0}>
            {!isEmpty(activeCellIds) && (
              <CreateSamplesButton
                from={location.pathname}
                cell_ids={activeCellIds.map((sel) => `${sel}`)}
              />
            )}
            {eventData && (
              <Box mx={2}>
                <ButtonLogEvent itemType={"cell"} objects={eventData} />
              </Box>
            )}
            <Box mx={2}>
              <IconButton
                size="small"
                onClick={() =>
                  handleOpenNotesPanel(() => setNoteFormPanelOpen(true))
                }
              >
                <PencilWriteIcon />
              </IconButton>
            </Box>
            <Box mx={2}>
              <IconButton
                size="small"
                onClick={() =>
                  handleOpenNotesPanel(() => setNoteListPanelOpen(true))
                }
              >
                <PaperWriteIcon />
              </IconButton>
            </Box>
            <Box mx={2}>
              <Button
                size="small"
                disabled={!isDirty || isSaving}
                color="primary"
                onClick={handleSave}
                endIcon={
                  isSaving ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                Save
              </Button>
            </Box>
            <Box mx={2}>
              <IconButton size="small" onClick={() => handleExit()}>
                <TimesIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </HeaderContainer>
      {exiting ? (
        <Modal open={exiting} onClose={() => setExiting(false)}>
          <>
            <Typography variant="h2">Save metadata ?</Typography>
            <Box mt={8} mb={2}>
              <Typography color="textSecondary">
                You have unsaved metadata for the cells currently visible. To
                preserve your metadata, please save before leaving this page.
              </Typography>
            </Box>
            <Box mt={8} display="flex" justifyContent="flex-end">
              <Box mr={4}>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => handleExit(true)}
                >
                  Don't save
                </Button>
              </Box>
              <Button
                color="primary"
                type="button"
                disabled={isSaving}
                endIcon={
                  isSaving ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                onClick={() => onSave(() => handleExit(true))}
              >
                Save
              </Button>
            </Box>
          </>
        </Modal>
      ) : null}

      <NoteFormSidePanel
        open={noteFormPanelOpen}
        onClose={() => setNoteFormPanelOpen(false)}
        resource="cell"
        options={cellIds}
      />

      <NoteListSidePanel
        open={noteListPanelOpen}
        onClose={() => setNoteListPanelOpen(false)}
        resource="cell"
        record_ids={cellIds}
      />

      {pendingChangesBanner}
    </>
  );
};

export default Header;
