import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import Button from "../../components/Button";
import ArrowBack from "../../icons/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  GridActionsCellItem,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { RootState } from "../../store";
import {
  BatchConditionsSpecificationsState,
  createCellConditions,
  getOverviewFormData,
  resetBatchConditionsSpecification,
  resetCreateConditions,
} from "./batchConditionsSpecificationsSlice";
import Loading from "../../layout/Loading";
import ConditionsInitialForm from "./ConditionsInitialForm";
import ConditionsCreationTable from "./ConditionsCreationTable";
import { BUILD_PHASES, CHANNEL_POOL_EXPLAINER } from "../../utils/labels";
import BatchFormMetaCell from "./BatchFormMetaCell";
import PlusCircle from "../../icons/PlusCircle";
import MinusCircle from "../../icons/MinusCircle";
import colors from "../../theme/colors";
import ConditionDatePicker from "../conditions/ConditionDatePicker";
import { DateTime } from "luxon";
import Toast from "../../components/Toast";
import {
  FeatureFlagsState,
  LAUNCH_BATCH_CONDITION_SPECIFICATION,
} from "../../store/featureFlags/slice";
import { Link } from "react-router-dom";

export const REQUIRED_FIELDS_FOR_CREATE = [
  "cell_assembly",
  "name",
  "replicates",
  "pool",
  "plan_test_start_date",
];

export type ConditionsInitialFormFields = {
  replicates: number;
  cell_type: string | null;
  cell_assembly: string | null;
  number_of_conditions: number | null;
  pool: string | null;
  plan_test_start_date: string | null;
  build_phase: string | null;
  build_config: string | null;
};

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 1,
});

const NewConditionsCreationForms = () => {
  const { exp_id = "" } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [activePage, setActivePage] = useState<
    | "conditions_definition"
    | "conditions_creation_table"
    | "async_create_in_progress"
  >("conditions_definition");
  const [conditionRows, setConditionRows] = useState<GridValidRowModel[]>([]);
  const [completedInitialForm, setCompletedInitialForm] =
    useState<ConditionsInitialFormFields | null>(null);
  const { enabledFlags } = useSelector<RootState, FeatureFlagsState>(
    ({ featureFlags }) => featureFlags
  );
  const {
    createdCellConditionIds,
    overviewFormOptions: { cell_types_to_assemblies },
    status: {
      getOverviewForm: getOverviewFormStatus,
      create: createConditionsStatus,
    },
    error: { create: createConditionsError },
  } = useSelector<RootState, BatchConditionsSpecificationsState>(
    ({ batchConditionsSpecification }) => batchConditionsSpecification
  );
  const handleAddRow = (
    idToAdd: GridRowId,
    beforeOrAfter: "before" | "after"
  ) => {
    const conditionRowsCopy = [...conditionRows];
    const sourceRowIndex = conditionRowsCopy.findIndex(
      (row_) => row_.id === idToAdd
    );
    const indexToAdd =
      beforeOrAfter === "after" ? sourceRowIndex + 1 : sourceRowIndex;
    const maxId = conditionRowsCopy.reduce(
      (prevId: number, { id: currentId }) => {
        return prevId > currentId ? prevId : currentId;
      },
      0
    );
    conditionRowsCopy.splice(indexToAdd, 0, {
      ...conditionRowsCopy[sourceRowIndex],
      name: null,
      id: maxId + 1,
    });
    setConditionRows(conditionRowsCopy);
  };
  const handleRemoveRow = (idToRemove: GridRowId) => {
    const conditionRowsCopy = [...conditionRows];
    const indexToDelete = conditionRowsCopy.findIndex(
      (row_) => row_.id === idToRemove
    );
    conditionRowsCopy.splice(indexToDelete, 1);
    setConditionRows(conditionRowsCopy);
  };
  const columns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      width: 52,
      getActions: (rowParams: GridRowParams) => [
        <GridActionsCellItem
          icon={<PlusCircle />}
          onClick={() => handleAddRow(rowParams.id, "before")}
          label="Add row above"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<PlusCircle />}
          onClick={() => handleAddRow(rowParams.id, "after")}
          label="Add row below"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<MinusCircle />}
          onClick={() => handleRemoveRow(rowParams.id)}
          label="Remove row"
          showInMenu
        />,
      ],
    },
    {
      field: "cell_assembly",
      headerName: "Cell assembly",
      renderHeader: () => (
        <>
          Cell assembly
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      width: 188,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      width: 360,
      editable: true,
      renderHeader: () => (
        <>
          Condition name
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
    },
    {
      field: "description",
      headerName: "Condition description",
      width: 360,
      editable: true,
    },
    {
      field: "replicates",
      renderHeader: () => (
        <>
          # cell replicates
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      type: "number",
      width: 140,
      headerAlign: "center",
      align: "center",
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !params.props.value;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "pool",
      renderHeader: () => (
        <>
          Channel pool
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      description: CHANNEL_POOL_EXPLAINER,
      align: "center",
      headerAlign: "center",
      width: 224,
      editable: true,
      type: "singleSelect",
      renderEditCell: ({ field, id, value }) => {
        return (
          <BatchFormMetaCell
            apiRef={apiRef}
            fieldName={field}
            fieldId={id}
            value={value || ""}
            endpoint="meta/channels/pool-ids"
            searchKey="pool"
          />
        );
      },
    },
    {
      field: "plan_test_start_date",
      renderHeader: () => (
        <>
          Planned on-test date
          <span style={{ color: colors.accent.red }}>*</span>
        </>
      ),
      align: "center",
      headerAlign: "center",
      width: 192,
      editable: true,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      renderEditCell: ({ field, id, value }) => {
        return (
          <ConditionDatePicker
            wrapInCell={false}
            name={field}
            value={value}
            onChange={(newVal) => {
              apiRef.current.setEditCellValue({
                id,
                field,
                value: newVal && new Date(newVal),
              });
            }}
          />
        );
      },
    },
    {
      field: "build_phase",
      headerName: "Build phase",
      align: "center",
      headerAlign: "center",
      width: 192,
      editable: true,
      type: "singleSelect",
      valueOptions: BUILD_PHASES,
    },
    {
      field: "build_config",
      headerName: "Build config",
      headerAlign: "center",
      width: 192,
      editable: true,
    },
  ];

  useEffect(() => {
    if (!cell_types_to_assemblies && getOverviewFormStatus === "idle") {
      dispatch(getOverviewFormData());
    }
  }, [getOverviewFormStatus, cell_types_to_assemblies, dispatch]);

  useEffect(() => {
    if (
      createConditionsStatus === "async" &&
      activePage !== "async_create_in_progress"
    ) {
      setActivePage("async_create_in_progress");
    }
    if (createConditionsStatus === "succeeded") {
      navigate(
        `/experiments/${exp_id}/batch-conditions/${createdCellConditionIds.join(
          ","
        )}`
      );
    }
  }, [
    activePage,
    createConditionsStatus,
    exp_id,
    createdCellConditionIds,
    navigate,
  ]);

  const disableConditionsCreate = useMemo(() => {
    if (
      !enabledFlags?.includes(LAUNCH_BATCH_CONDITION_SPECIFICATION) ||
      ["loading", "succeeded"].includes(createConditionsStatus) ||
      activePage === "conditions_definition"
    )
      return true;
    return conditionRows.some((conditionRow_) => {
      return REQUIRED_FIELDS_FOR_CREATE.some((requiredField_) => {
        return !conditionRow_[requiredField_];
      });
    });
  }, [activePage, conditionRows, createConditionsStatus, enabledFlags]);

  const handleCreateConditionsClick = () => {
    const createPayload = conditionRows.map(
      ({
        build_config,
        build_phase,
        cell_assembly,
        description,
        name,
        pool,
        replicates,
        plan_test_start_date,
      }) => {
        const testStartDate =
          plan_test_start_date instanceof Date
            ? DateTime.fromJSDate(plan_test_start_date)
                .toUTC()
                .toISO({ suppressMilliseconds: true })
            : plan_test_start_date;
        return {
          build_config,
          build_phase,
          cell_assembly,
          cell_type: completedInitialForm!.cell_type!,
          description,
          exp_id: Number(exp_id),
          name,
          plan_test_start_date: testStartDate,
          pool,
          replicates,
        };
      }
    );
    dispatch(createCellConditions(createPayload));
  };

  if (!cell_types_to_assemblies) {
    return <Loading fullscreen light />;
  }

  const activePageToComponent = {
    conditions_definition: () => (
      <ConditionsInitialForm
        cell_types_to_assemblies={cell_types_to_assemblies}
        onNext={(formData: ConditionsInitialFormFields) => {
          setCompletedInitialForm(formData);
          setConditionRows(
            Array.from({ length: formData.number_of_conditions || 1 }).map(
              (_, index_) =>
                columns.reduce(
                  (conditionRowData: { [key: string]: any }, column) => {
                    conditionRowData[column.field] =
                      formData[
                        column.field as keyof ConditionsInitialFormFields
                      ];
                    return conditionRowData;
                  },
                  { id: index_ }
                )
            )
          );
          setActivePage("conditions_creation_table");
        }}
        lastInitialFormState={completedInitialForm}
      />
    ),
    conditions_creation_table: () => (
      <ConditionsCreationTable
        exp_id={exp_id}
        apiRef={apiRef}
        conditionRows={conditionRows}
        columns={columns}
        onConditionRowsUpdate={(newRows) => setConditionRows(newRows)}
        cellType={completedInitialForm!.cell_type!}
      />
    ),
    async_create_in_progress: () => {
      return (
        <Grid item xs={12}>
          <Box my={6}>
            <Box display="flex" justifyContent="center">
              <Box>
                <Typography mb={4} variant="h2" textAlign="center">
                  Cell condition creation in progress...
                </Typography>
                <Typography textAlign="center">
                  Refresh the{` `}
                  <Link color="textPrimary" to={`/experiments/${exp_id}`}>
                    experiment details page
                  </Link>
                  {` `}in a couple minutes to see new cell conditions.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      );
    },
  };

  return (
    <Box>
      <HeaderContainer
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h2" style={{ marginLeft: "32px" }}>
            <IconButton
              size="large"
              onClick={() => {
                if (activePage === "async_create_in_progress") {
                  navigate(`/experiments/${exp_id}`);
                  return;
                }
                if (
                  window.confirm("Are you sure? Changes have not been saved.")
                ) {
                  dispatch(resetBatchConditionsSpecification());
                  activePage === "conditions_definition"
                    ? navigate(`/experiments/${exp_id}`)
                    : setActivePage("conditions_definition");
                  return;
                }
              }}
              sx={{ paddingTop: "8px" }}
            >
              <ArrowBack />
            </IconButton>
            Create cell conditions
          </Typography>
        </Box>
        <Box style={{ marginRight: "36px" }}>
          {activePage !== "async_create_in_progress" && (
            <Button
              color="tertiary"
              type="button"
              size="small"
              style={{
                padding: "0.5rem",
                marginRight: "8px",
              }}
              onClick={() => {
                if (
                  window.confirm("Are you sure? Changes have not been saved.")
                ) {
                  dispatch(resetBatchConditionsSpecification());
                  navigate(`/experiments/${exp_id}`);
                  return;
                }
              }}
            >
              <b>Cancel</b>
            </Button>
          )}
          {activePage === "conditions_creation_table" && (
            <Button
              color="primary"
              type="button"
              size="small"
              disabled={disableConditionsCreate}
              style={{
                padding: "0.5rem",
                marginRight: "8px",
              }}
              onClick={handleCreateConditionsClick}
            >
              <b>Create conditions</b>
            </Button>
          )}
        </Box>
      </HeaderContainer>
      <Box style={{ padding: "8px 36px 36px 36px" }}>
        <Paper
          variant="outlined"
          square
          sx={{ marginBottom: "12px", minHeight: "80vh" }}
        >
          <Box px={3} pt={3} pb={6}>
            <Grid container spacing={4}>
              {activePageToComponent[
                activePage as
                  | "conditions_definition"
                  | "conditions_creation_table"
                  | "async_create_in_progress"
              ]()}
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Toast
        severity="error"
        open={createConditionsStatus === "failed"}
        onClose={() => {
          dispatch(resetCreateConditions());
        }}
      >
        {createConditionsError || "Error creating conditions"}
      </Toast>
    </Box>
  );
};

export default NewConditionsCreationForms;
