import { useState } from "react";
import { useFormContext } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import type { RootState } from "../../../store";
import { completeMetadata, MetadataState } from "../slice";
import TeardownEditModal from "./TeardownEditModal";
import CompletedCell from "./CompletedCell";
import { useParams } from "react-router-dom";
import TeardownViewModal from "./TeardownViewModal";
import ClockIcon from "../../../icons/Clock";
import colors from "../../../theme/colors";
import parseCellIdString from "../../../utils/parseCellIdString";

type Props = {
  disabled: boolean;
  partial?: boolean;
  formField: FieldArrayWithId<MetadataRHFormFormat, "metadata">;
  teardownType?: string;
  teardownFields?: ConditionUIField[];
  teardownState?: Record<string, any>;
  executor: User | null;
  onSave: (callback?: () => void) => void;
};

const TeardownCell = ({
  disabled,
  partial = false,
  formField,
  teardownType,
  teardownFields,
  teardownState,
  executor,
  onSave,
}: Props) => {
  const { cell_id_string = "" } = useParams();

  const {
    formState: { isDirty },
  } = useFormContext();

  // Metadata state in redux
  const dispatch = useDispatch();
  const {
    status: { save: saveStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  /* Save modal */
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveModal = (
    <Modal open={saveModalOpen} onClose={() => setSaveModalOpen(false)}>
      <>
        <Typography variant="h2">Save inputs ?</Typography>
        <Box mt={8} mb={2}>
          <Typography color="textSecondary">
            You have unsaved inputs. You must save all inputs in order to
            continue.
          </Typography>
        </Box>
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button color="secondary" onClick={() => setSaveModalOpen(false)}>
              Cancel
            </Button>
          </Box>
          <Button
            color="primary"
            onClick={() =>
              onSave(() => {
                setSaveModalOpen(false);
                setEditModalOpen(true);
              })
            }
            endIcon={
              saveStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
            disabled={saveStatus === "loading"}
          >
            Save
          </Button>
        </Box>
      </>
    </Modal>
  );

  /* Form modal */
  const [editModalOpen, setEditModalOpen] = useState(false);
  const teardownEditModal = (
    <TeardownEditModal
      open={editModalOpen}
      onClose={() => setEditModalOpen(false)}
      formField={formField}
      executor={executor}
      teardownType={teardownType}
      teardownFields={teardownFields}
      teardownState={teardownState}
    />
  );

  /* Display modal */
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const teardownViewModal = (
    <TeardownViewModal
      open={viewModalOpen}
      onClose={() => setViewModalOpen(false)}
      formField={formField}
      executor={executor}
      teardownType={teardownType}
      teardownFields={teardownFields}
      teardownState={teardownState}
    />
  );

  const onUndo = async (component: any) => {
    const { conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string);

    dispatch(
      completeMetadata({
        components: [component],
        executor: null,
        conditionIdsToRequestedCellIds,
        undo: true,
      })
    );
  };

  return (
    <>
      {teardownState?.completed?.completed_at ? (
        <CompletedCell
          status={formField.status}
          minimumStatus="F"
          maximumStatus="U"
          onView={() => setViewModalOpen(true)}
          onEdit={() =>
            isDirty ? setSaveModalOpen(true) : setEditModalOpen(true)
          }
          onUndo={() => onUndo(teardownState)}
          completed_at={teardownState.completed.completed_at}
          completed_by={teardownState.completed.completed_by}
        />
      ) : (
        <Box p={2} display="flex" alignItems="center">
          <Tooltip
            arrow
            title="Previous steps must be completed first"
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
          >
            <span>
              <Button
                color="tertiary"
                type="button"
                size="small"
                disabled={disabled}
                style={{
                  padding: "0.5rem",
                }}
                onClick={() =>
                  isDirty ? setSaveModalOpen(true) : setEditModalOpen(true)
                }
              >
                <b>{partial ? "Finish " : ""}Teardown</b>
              </Button>
            </span>
          </Tooltip>
          {partial ? (
            <Tooltip arrow title="Teardown started but is incomplete">
              <span>
                <ClockIcon
                  style={{
                    color: colors.text.secondary,
                    width: 16,
                    height: 16,
                  }}
                />
              </span>
            </Tooltip>
          ) : null}
        </Box>
      )}

      {saveModal}
      {teardownEditModal}
      {teardownViewModal}
    </>
  );
};

export default TeardownCell;
