import React from "react";
import Dialog from "@mui/material/Dialog";
import type { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/styles/styled";
import TimesIcon from "../icons/Times";
import theme from "../theme";

const CloseButton = styled(IconButton)({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
});

const Modal = ({ children, onClose, open, ...props }: DialogProps) => {
  const customKeydownBehavior = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (open && event.key === "Escape" && onClose !== undefined) {
      onClose(event, "escapeKeyDown");
    }
    if (open && event.key === "Tab") {
      event.stopPropagation();
    }
  };

  return (
    <Dialog
      open={open}
      onKeyDown={customKeydownBehavior}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      {...props}
      disableAutoFocus
    >
      <Box p={6}>
        {onClose !== undefined ? (
          <CloseButton onClick={(event) => onClose(event, "escapeKeyDown")}>
            <TimesIcon />
          </CloseButton>
        ) : null}
        {children}
      </Box>
    </Dialog>
  );
};

export default Modal;
