import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import NewCondition from "./NewCondition";
import Wrapper from "./Wrapper";
import type { RootState } from "../../../store";
import type { ConditionUIState } from "../uiSlice";

type Props = {
  exp_id: string;
  onNext: () => void;
};

const NoConditionLayout = ({ exp_id, onNext }: Props) => {
  const { ui } = useSelector<RootState, ConditionUIState>(
    ({ conditionUI }) => conditionUI
  );

  const [cellType, setCellType] = useState(ui && ui[0] ? ui[0].cell_type : "");
  const [cellAssembly, setCellAssembly] = useState(
    ui && ui[0] ? ui[0].cell_assembly : ""
  );

  /* Sidebar layout */
  const sidebarLayout = useMemo(() => {
    const condition = ui?.find(
      ({ cell_type, cell_assembly }) =>
        cell_type === cellType && cell_assembly === cellAssembly
    );

    if (!condition) {
      return undefined;
    }

    let index = 0;
    const items = condition.items.map((item) => ({
      title: item.title,
      step: item.items ? -1 : index++,
      children: item.items?.map((item) => ({
        title: item.title,
        step: index++,
      })),
    }));

    return { items: [{ title: "Overview", step: -1 }, ...items] };
  }, [ui, cellType, cellAssembly]);

  const handleCombinationChange = (type: string, assembly: string) => {
    setCellType(type);
    setCellAssembly(assembly);
  };

  return (
    <Wrapper step={-1} sidebarLayout={sidebarLayout} started={false}>
      <NewCondition
        exp_id={exp_id}
        onNext={onNext}
        onCombinationChange={handleCombinationChange}
      />
    </Wrapper>
  );
};

export default NoConditionLayout;
