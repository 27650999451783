import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridValidRowModel, useGridApiRef } from "@mui/x-data-grid";
import { useBeforeunload } from "react-beforeunload";
import { useDebouncedCallback } from "use-debounce";
import client from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  ModuleSingleState,
  completePreTestModuleSteps,
  getSingleModule,
  resetCompletePreTestSteps,
  resetSaveModule,
  resetSingleModule,
  saveModuleIdentifiers,
} from "./moduleMetadataSlice";
import Loading from "../../layout/Loading";
import {
  SAS_MODULE,
  cellIdToString,
  cellStatusToString,
  expIdToString,
} from "../../utils/labels";
import Pencil from "../../icons/Pencil";
import Button from "../../components/Button";
import ArrowBack from "../../icons/ArrowBack";
import { isNull } from "lodash";
import Toast from "../../components/Toast";
import Undo from "../../icons/Undo";
import { hasPassedStatus } from "../../utils/statuses";
import AutocompleteEditInputCell from "../batch-external-data-entry/AutocompleteEditIndputCell";
import BatchExternalDataEntryGrid from "../batch-external-data-entry/BatchExternalDataEntryGrid";
import { handleMultiLinePaste } from "../batch-external-data-entry";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 1,
});

const ELECTRODE_FIELDS = [
  {
    field: "hca_id",
    headerName: "Anode 1 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_1",
    headerName: "Anode 1 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_2",
    headerName: "Anode 2 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_2",
    headerName: "Anode 2 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_3",
    headerName: "Anode 3 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_3",
    headerName: "Anode 3 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_4",
    headerName: "Anode 4 ID",
    width: 148,
    requiredForStepComplete: true,
  },
  {
    field: "anode_mass_4",
    headerName: "Anode 4 Mass (g)",
    width: 148,
    type: "number",
    requiredForStepComplete: true,
  },
  {
    field: "hca_id_5",
    headerName: "Anode 5 ID",
    width: 148,
  },
  {
    field: "anode_mass_5",
    headerName: "Anode 5 Mass (g)",
    width: 148,
    type: "number",
    requiredIfIDFieldPresent: "hca_id_5",
  },
  {
    field: "hca_id_6",
    headerName: "Anode 6 ID",
    width: 148,
  },
  {
    field: "anode_mass_6",
    headerName: "Anode 6 Mass (g)",
    width: 148,
    type: "number",
    requiredIfIDFieldPresent: "hca_id_6",
  },
  { field: "hot_pocket_id", headerName: "GDE 1 ID" },
  { field: "hot_pocket_2_id", headerName: "GDE 2 ID" },
  { field: "hot_pocket_3_id", headerName: "GDE 3 ID" },
  { field: "oee_1_serial_number", headerName: "OEE 1 ID" },
  { field: "oee_1b_serial_number", headerName: "OEE 1b ID" },
  { field: "oee_2_serial_number", headerName: "OEE 2 ID" },
  { field: "oee_2b_serial_number", headerName: "OEE 2b ID" },
  { field: "oee_3_serial_number", headerName: "OEE 3 ID" },
  { field: "oee_3b_serial_number", headerName: "OEE 3b ID" },
  { field: "oee_4_serial_number", headerName: "OEE 4 ID" },
  { field: "oee_4b_serial_number", headerName: "OEE 4b ID" },
  { field: "oee_5_serial_number", headerName: "OEE 5 ID" },
  { field: "oee_5b_serial_number", headerName: "OEE 5b ID" },
  { field: "oee_6_serial_number", headerName: "OEE 6 ID" },
  { field: "oee_6b_serial_number", headerName: "OEE 6b ID" },
];

const ModuleMetadataLayout = () => {
  const { module_id = "" } = useParams();
  const [serialNumberSearch, setSerialNumberSearch] = useState<string | null>(
    null
  );
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<
    ExternalIdentifierData | string | null
  >(null);
  const [loadingSerialNumbers, setLoadingSerialNumbers] = useState(false);
  const [serialNumberOptions, setSerialNumberOptions] = useState<
    ExternalIdentifierData[] | null
  >(null);
  const [constituentSerialNumberOptions, setConstituentSerialNumberOptions] =
    useState<ExternalIdentifierData[] | null>(null);
  const [isEditingModuleSN, setIsEditingModuleSN] = useState(false);
  const [hoveringOverSN, setHoveringOverSN] = useState(false);
  const [
    showCannotUpdateAnodeMassMessage,
    setShowCannotUpdateAnodeMassMessage,
  ] = useState(false);
  const [cellRows, setCellRows] = useState<GridValidRowModel[]>([]);

  const {
    module,
    status: {
      save: saveModuleMetadataStatus,
      completePretest: completePreTestStepsStatus,
    },
    error: {
      completePretest: completePretestError,
      save: saveModuleMetadataError,
    },
  } = useSelector<RootState, ModuleSingleState>(
    ({ moduleSingle }) => moduleSingle
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const editsMade = useMemo(() => {
    if (!module) return false;
    const entryFields = [
      "position_id",
      "serial_number",
      ...ELECTRODE_FIELDS.map((electrodeField) => electrodeField.field),
    ];
    const modifiedModuleSN = !module?.serial_number
      ? !!serialNumberSearch || selectedSerialNumber
      : selectedSerialNumber
      ? module.serial_number !== serialNumberSearch
      : false;
    return (
      modifiedModuleSN ||
      cellRows.some((cellRow_) => {
        const moduleCell = module.cells.find(
          (cell_) => cell_.cell_id === cellRow_.cell_id
        )!;
        return entryFields.some(
          (entryField) =>
            cellRow_[entryField] !== moduleCell[entryField as keyof ModuleCell]
        );
      })
    );
  }, [cellRows, selectedSerialNumber, serialNumberSearch, module]);

  const REQUIRED_FIELDS_FOR_STEP_COMPLETE = useCallback(
    () => [
      ...(!!module && module.allow_position_id_update ? ["position_id"] : []),
      ...ELECTRODE_FIELDS.filter(
        (electrodeField) =>
          electrodeField.requiredForStepComplete ||
          !!electrodeField.requiredIfIDFieldPresent
      ).map((electrodeField) => electrodeField.field),
    ],
    [module]
  );

  useBeforeunload((e) => {
    if (editsMade) {
      e.preventDefault();
    }
  });

  const columns: GridColDef[] = [
    { field: "display_cell_id", headerName: "Oak Cell ID", width: 150 },
    {
      field: "status",
      headerName: "Oak Status",
      width: 150,
      valueGetter: (val, row) => cellStatusToString(val),
    },
    {
      field: "cell_condition_name",
      headerName: "Cell Condition Name",
      width: 240,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 484,
      editable: true,
      renderEditCell: (params) => {
        return (
          <AutocompleteEditInputCell
            params={params}
            allElectrodeFields={ELECTRODE_FIELDS}
            value={params.value}
            options={constituentSerialNumberOptions || []}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.external_id
            }
          />
        );
      },
    },
    {
      field: "position_id",
      headerName: "Position ID",
      editable: !!module && module.allow_position_id_update,
      width: 84,
      type: "number",
      valueSetter: (newVal, row) => ({ ...row, position_id: newVal }),
    },
    ...(ELECTRODE_FIELDS.map((electrodeField) => ({
      ...electrodeField,
      editable: true,
      width: electrodeField.width || 172,
      headerAlign: "center",
      align: "center",
      type: electrodeField.type || "string",
      valueParser: (value) => value && handleMultiLinePaste(value),
      valueSetter: (newVal, row) => ({
        ...row,
        [electrodeField.field]: newVal && handleMultiLinePaste(newVal),
      }),
    })) as GridColDef[]),
  ];

  const handleNavigateToExpDetails = useCallback(() => {
    if (!module) {
      navigate("/experiments");
      return;
    }
    if (editsMade && saveModuleMetadataStatus !== "succeeded") {
      if (window.confirm("Are you sure? Changes have not been saved.")) {
        dispatch(resetSingleModule());
        navigate(`/experiments/${module.exp_id}`);
        return;
      }
    } else {
      dispatch(resetSingleModule());
      navigate(`/experiments/${module.exp_id}`);
    }
  }, [module, editsMade, dispatch, navigate, saveModuleMetadataStatus]);

  const _handleModuleLookup = useCallback(async () => {
    if (serialNumberSearch === null) {
      return;
    }

    setLoadingSerialNumbers(true);

    try {
      const response = await client.get(
        `meta/external-identifier-data?entity_type=module&removed_at__null=1&external_id__contains=${serialNumberSearch}`
      );
      setSerialNumberOptions(response.data);
    } catch (err) {
      setSerialNumberOptions([]);
    }

    setLoadingSerialNumbers(false);
  }, [serialNumberSearch]);

  const fetchConstituentExternalIdentifiers = useCallback(async () => {
    if (
      (!module || !module.external_identifier_id) &&
      (!selectedSerialNumber || typeof selectedSerialNumber === "string")
    ) {
      if (
        constituentSerialNumberOptions &&
        constituentSerialNumberOptions.length > 0
      ) {
        setConstituentSerialNumberOptions([]);
      }
      return;
    }

    try {
      const response = await client.get(
        `meta/external-identifier-data?removed_at__null=1&parent_external_entity_id=${
          selectedSerialNumber
            ? (selectedSerialNumber as ExternalIdentifierData).id
            : module!.external_identifier_id
        }`
      );
      const canSortByPositionNumber = response.data.every(
        (cellSNItem: ExternalIdentifierData) =>
          !!Number(cellSNItem.secondary_external_id)
      );
      canSortByPositionNumber &&
        response.data.sort(
          (a: ExternalIdentifierData, b: ExternalIdentifierData) =>
            Number(a.secondary_external_id) - Number(b.secondary_external_id)
        );
      setConstituentSerialNumberOptions(response.data);
    } catch (err) {
      setConstituentSerialNumberOptions([]);
    }
    // eslint-disable-next-line
  }, [selectedSerialNumber, module]);

  const handleModuleLookup = useDebouncedCallback(_handleModuleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleModuleLookup();
  }, [serialNumberSearch, handleModuleLookup]);

  useEffect(() => {
    fetchConstituentExternalIdentifiers();
    if (selectedSerialNumber) {
      setSerialNumberSearch("");
    }
  }, [selectedSerialNumber, fetchConstituentExternalIdentifiers]);

  useEffect(() => {
    if (!module && module_id) {
      dispatch(getSingleModule(module_id));
    }
  }, [module, dispatch, module_id]);

  useEffect(() => {
    if (module && module.cells.length > 0) {
      setCellRows(
        module.cells.map((modCell) => ({
          ...modCell,
          display_cell_id: cellIdToString(modCell.cell_id),
          cell_id: modCell.cell_id,
          id: modCell.cell_id,
        }))
      );
    }
  }, [module]);

  const handleSave = () => {
    dispatch(resetCompletePreTestSteps());
    const serialNumber = isNull(serialNumberSearch)
      ? undefined
      : !selectedSerialNumber
      ? serialNumberSearch
      : typeof selectedSerialNumber === "string"
      ? selectedSerialNumber
      : selectedSerialNumber.external_id;
    setShowCannotUpdateAnodeMassMessage(false);
    dispatch(
      saveModuleIdentifiers({
        module_id,
        cell_identifiers: cellRows.map((cellRow_) => ({
          cell_id: cellRow_.id,
          serial_number: cellRow_.serial_number,
          position_id: cellRow_.position_id,
          hca_id: cellRow_.hca_id,
          hca_id_2: cellRow_.hca_id_2,
          hca_id_3: cellRow_.hca_id_3,
          hca_id_4: cellRow_.hca_id_4,
          hca_id_5: cellRow_.hca_id_5,
          hca_id_6: cellRow_.hca_id_6,
          mass_actual: cellRow_.anode_mass_1,
          mass_actual_2: cellRow_.anode_mass_2,
          mass_actual_3: cellRow_.anode_mass_3,
          mass_actual_4: cellRow_.anode_mass_4,
          mass_actual_5: cellRow_.anode_mass_5,
          mass_actual_6: cellRow_.anode_mass_6,
          hot_pocket_id: cellRow_.hot_pocket_id,
          hot_pocket_2_id: cellRow_.hot_pocket_2_id,
          hot_pocket_3_id: cellRow_.hot_pocket_3_id,
          oee_1_serial_number: cellRow_.oee_1_serial_number,
          oee_1b_serial_number: cellRow_.oee_1b_serial_number,
          oee_2_serial_number: cellRow_.oee_2_serial_number,
          oee_2b_serial_number: cellRow_.oee_2b_serial_number,
          oee_3_serial_number: cellRow_.oee_3_serial_number,
          oee_3b_serial_number: cellRow_.oee_3b_serial_number,
          oee_4_serial_number: cellRow_.oee_4_serial_number,
          oee_4b_serial_number: cellRow_.oee_4b_serial_number,
          oee_5_serial_number: cellRow_.oee_5_serial_number,
          oee_5b_serial_number: cellRow_.oee_5b_serial_number,
          oee_6_serial_number: cellRow_.oee_6_serial_number,
          oee_6b_serial_number: cellRow_.oee_6b_serial_number,
        })),
        serial_number: serialNumber,
      })
    );
  };

  const handleCompletePreTestSteps = () => {
    dispatch(completePreTestModuleSteps(module!.module_id));
  };

  const savingModuleIdentifiers = saveModuleMetadataStatus === "loading";
  const completingPreTestSteps = completePreTestStepsStatus === "loading";

  const someCellsHaveSNs = useCallback(() => {
    if (!module || cellRows.length === 0) return false;
    return cellRows.some((cellRow_) => {
      return !!cellRow_.serial_number;
    });
  }, [module, cellRows]);
  const allCellsHaveSNs = useCallback(() => {
    if (!module || cellRows.length === 0) return false;
    return cellRows.every((cellRow_) => {
      return !!cellRow_.serial_number;
    });
  }, [module, cellRows]);
  const allCellsCommitted = useCallback(() => {
    if (!module || cellRows.length === 0) return false;
    return cellRows.every((cellRow_) => {
      return hasPassedStatus("C", cellRow_.status, true);
    });
  }, [module, cellRows]);
  const allCellsFillCompleted = useCallback(() => {
    if (!module || cellRows.length === 0) return false;
    return cellRows.every((cellRow_) => {
      return hasPassedStatus("L", cellRow_.status, true);
    });
  }, [module, cellRows]);
  const showCompleteMetadataStepsButton =
    module?.module_type === SAS_MODULE &&
    someCellsHaveSNs() &&
    !allCellsFillCompleted();

  const requiredFieldsForBatchCompletePresent = useCallback(() => {
    if (!module || cellRows.length === 0) return false;
    if (!allCellsCommitted() || !allCellsHaveSNs()) return false;
    return cellRows.every((cellRow_) => {
      return REQUIRED_FIELDS_FOR_STEP_COMPLETE().every((reqField) => {
        const conditionallyRequiredField = ELECTRODE_FIELDS.find(
          (elecField) =>
            !!elecField.requiredIfIDFieldPresent && reqField === elecField.field
        );
        if (conditionallyRequiredField) {
          const conditionalVal =
            cellRow_[
              conditionallyRequiredField.requiredIfIDFieldPresent as keyof CellExternalMetadataEntry
            ];
          return !conditionalVal || !!cellRow_[reqField];
        }
        return !!cellRow_[reqField];
      });
    });
  }, [
    module,
    cellRows,
    allCellsCommitted,
    allCellsHaveSNs,
    REQUIRED_FIELDS_FOR_STEP_COMPLETE,
  ]);

  if (!module) {
    return <Loading fullscreen light />;
  }

  return (
    <Box>
      <HeaderContainer
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h2" style={{ marginLeft: "32px" }}>
            <IconButton
              size="large"
              onClick={handleNavigateToExpDetails}
              sx={{ paddingTop: "8px" }}
            >
              <ArrowBack />
            </IconButton>
            Edit module metadata
          </Typography>
        </Box>
        <Box style={{ marginRight: "36px" }}>
          {showCompleteMetadataStepsButton && (
            <Tooltip
              arrow
              placement="left"
              title={
                !requiredFieldsForBatchCompletePresent()
                  ? "All cells must be committed and saved with serial numbers, position ID, Anode IDs, and Anode mass fields filled out to mark pre-test steps complete."
                  : "Save before marking pre-test steps complete."
              }
              disableFocusListener={
                requiredFieldsForBatchCompletePresent() && !editsMade
              }
              disableHoverListener={
                requiredFieldsForBatchCompletePresent() && !editsMade
              }
            >
              <span>
                <Button
                  color="tertiary"
                  type="button"
                  size="small"
                  disabled={
                    !!editsMade ||
                    !requiredFieldsForBatchCompletePresent() ||
                    completingPreTestSteps
                  }
                  style={{
                    padding: "0.5rem",
                    marginRight: "8px",
                  }}
                  onClick={handleCompletePreTestSteps}
                >
                  <b>Mark all pre-test steps complete</b>
                  <>
                    {completingPreTestSteps ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{ marginLeft: "8px" }}
                      />
                    ) : null}
                  </>
                </Button>
              </span>
            </Tooltip>
          )}
          <Button
            color="tertiary"
            type="button"
            size="small"
            disabled={!editsMade || savingModuleIdentifiers}
            style={{
              padding: "0.5rem",
              marginRight: "8px",
            }}
            onClick={handleNavigateToExpDetails}
          >
            <b>Cancel</b>
          </Button>
          <Button
            color="primary"
            type="button"
            size="small"
            disabled={!editsMade || savingModuleIdentifiers}
            style={{
              padding: "0.5rem",
            }}
            onClick={handleSave}
          >
            <b>Save</b>
            <>
              {savingModuleIdentifiers ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ marginLeft: "8px" }}
                />
              ) : null}
            </>
          </Button>
        </Box>
      </HeaderContainer>
      <Box style={{ padding: "12px 36px 36px 36px" }}>
        <Paper
          variant="outlined"
          square
          sx={{ marginBottom: "12px", minHeight: "36px" }}
        >
          <Box px={3} pt={3} pb={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box marginBottom={2}>
                      <Typography variant="h2">
                        {module.name || `MOD${module.module_id}`}
                      </Typography>
                    </Box>
                    <Typography color="textSecondary">
                      {`${module.module_type}, ${expIdToString(module.exp_id)}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography color="textSecondary">Serial #</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {isEditingModuleSN || !module.serial_number ? (
                      <FormControl
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <Autocomplete
                          sx={{ flexGrow: 5 }}
                          freeSolo
                          options={serialNumberOptions || []}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.external_id
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          value={selectedSerialNumber}
                          // onOpen={() => setSerialNumberSearch("")}
                          onChange={(e, data) => setSelectedSerialNumber(data)}
                          onInputChange={(event, val, reason) => {
                            reason === "clear" && setSerialNumberSearch("");
                          }}
                          loading={loadingSerialNumbers}
                          noOptionsText={
                            serialNumberOptions
                              ? "No options found."
                              : "Start typing..."
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingSerialNumbers ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                                value: serialNumberSearch,
                                onChange: (e) =>
                                  setSerialNumberSearch(e.target.value),
                              }}
                            />
                          )}
                          renderOption={(props, opt) => (
                            <li {...props}>
                              <Box p={2} width="100%">
                                <Typography
                                  color="textPrimary"
                                  className={
                                    opt.external_id ? "small" : undefined
                                  }
                                >
                                  {opt.external_id}
                                </Typography>
                                {opt.secondary_external_id ? (
                                  <Typography
                                    color="textSecondary"
                                    className="tiny"
                                  >
                                    {opt.secondary_external_id}
                                  </Typography>
                                ) : null}
                              </Box>
                            </li>
                          )}
                        />
                        {!!module.serial_number ? (
                          <IconButton
                            onClick={() => {
                              setSerialNumberSearch(null);
                              setIsEditingModuleSN(false);
                            }}
                          >
                            <Undo />
                          </IconButton>
                        ) : null}
                      </FormControl>
                    ) : (
                      <div
                        style={{ display: "flex" }}
                        onMouseEnter={() => setHoveringOverSN(true)}
                        onMouseLeave={() => setHoveringOverSN(false)}
                      >
                        <Typography color="textSecondary">
                          {module.serial_number}
                        </Typography>
                        <Box>
                          {hoveringOverSN ? (
                            <IconButton
                              className="pencilButton"
                              size="small"
                              style={{
                                background: "transparent",
                                padding: 0,
                                marginLeft: "1rem",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setHoveringOverSN(false);
                                setIsEditingModuleSN(true);
                              }}
                            >
                              <Pencil style={{ width: 16, height: 16 }} />
                            </IconButton>
                          ) : null}
                        </Box>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <BatchExternalDataEntryGrid
          apiRef={apiRef}
          cellRows={cellRows}
          columns={columns}
          onCellRowsUpdate={(newRows) => setCellRows(newRows)}
        />
      </Box>
      <Toast
        severity="success"
        open={saveModuleMetadataStatus === "succeeded"}
        onClose={() => {
          dispatch(resetSaveModule());
        }}
      >
        Module metadata saved.
      </Toast>
      <Toast
        severity="error"
        open={saveModuleMetadataStatus === "failed"}
        onClose={() => {
          dispatch(resetSaveModule());
        }}
      >
        {saveModuleMetadataError || "Error saving module metadata."}
      </Toast>
      <Toast
        severity="warning"
        open={showCannotUpdateAnodeMassMessage}
        onClose={() => {
          setShowCannotUpdateAnodeMassMessage(false);
        }}
      >
        Anode masses cannot be updated once a cell has gone on-test.
      </Toast>
      <Toast
        severity="success"
        open={completePreTestStepsStatus === "succeeded"}
        onClose={() => {
          dispatch(resetCompletePreTestSteps());
        }}
      >
        Cell statuses updated. Go to experiment details page to put module
        on-test.
      </Toast>
      <Toast
        severity="error"
        open={completePreTestStepsStatus === "failed"}
        onClose={() => {
          dispatch(resetCompletePreTestSteps());
        }}
      >
        {completePretestError || "Error completing pre-test steps."}
      </Toast>
    </Box>
  );
};

export default ModuleMetadataLayout;
