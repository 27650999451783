import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import ModifyFaultConditionsModal from "./ModifyFaultConditionsModal";
import Toast from "../../components/Toast";

import Box from "@mui/material/Box";

import type { RootState } from "../../store";
import type { SignalMapState } from "./slice";

const FaultConditionModifiers = () => {
  const [mode, setMode] = useState<"add" | "edit" | "delete">("add");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);

  const {
    selected: { faultConditions: selected },
    status: { update: updateStatus, delete: deleteStatus },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  // "Update" response handler
  useEffect(() => {
    if (updateStatus === "succeeded") {
      setModalOpen(false);
      setSuccess("Fault condition successfully updated");
    } else {
      setSuccess(null);
    }
  }, [updateStatus]);

  // "Delete" response handler
  useEffect(() => {
    if (deleteStatus === "succeeded") {
      setModalOpen(false);
      setSuccess("Fault condition(s) successfully deleted");
    } else {
      setSuccess(null);
    }
  }, [deleteStatus]);

  return (
    <>
      <Box
        py={6}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          color="secondary"
          size="small"
          type="submit"
          disabled={selected.length === 0}
          style={{
            width: "fit-content",
            marginRight: "5px",
          }}
          onClick={() => {
            setMode("delete");
            setModalOpen(true);
          }}
        >
          Delete
        </Button>

        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={selected.length === 0}
          style={{
            width: "fit-content",
            margin: "auto 5px",
          }}
          onClick={() => {
            setMode("edit");
            setModalOpen(true);
          }}
        >
          Edit
        </Button>

        <Button
          color="primary"
          size="small"
          type="submit"
          style={{
            width: "fit-content",
            marginLeft: "5px",
          }}
          onClick={() => {
            setMode("add");
            setModalOpen(true);
          }}
        >
          Add Hazelnut Fault Condition
        </Button>
      </Box>

      <ModifyFaultConditionsModal
        mode={mode}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />

      {success && (
        <Toast open severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Toast>
      )}
    </>
  );
};

export default FaultConditionModifiers;
