import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Dot = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style, fill: "none" }} viewBox="0 0 8 8">
    {children}
    <circle cx="4" cy="4" r="4" fill="currentColor" />
  </SvgIcon>
);

export default Dot;
