import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const TEST_STAND_SINGLE = "TEST_STAND_SINGLE";

export interface TestStandSingleState {
  testStand: TestStand | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    testStandReservationCancellation:
      | "idle"
      | "loading"
      | "succeeded"
      | "failed";
  };
  error: {
    get: null | string;
    testStandReservationCancellation: null | string;
  };
}

const initialState: TestStandSingleState = {
  testStand: null,
  status: {
    get: "idle",
    testStandReservationCancellation: "idle",
  },
  error: {
    get: null,
    testStandReservationCancellation: null,
  },
};

export const getSingleTestStand = createAsyncThunk(
  `${TEST_STAND_SINGLE}/get`,
  async (test_stand_id: number) => {
    const response = await client.get(`meta/test-stands/${test_stand_id}`);
    return response;
  }
);

export const cancelTestStandReservation = createAsyncThunk(
  `${TEST_STAND_SINGLE}/cancelTestStandReservation`,
  async (cellId: number) => {
    return client.post("meta/test-stands/reserve", {
      data: { [cellId]: null },
    });
  }
);

const filtersSlice = createSlice({
  name: TEST_STAND_SINGLE,
  initialState,
  reducers: {
    resetSingleTestStand: (state) => Object.assign(state, initialState),
    resetCancelReservation: (state) => {
      state.status.testStandReservationCancellation = "idle";
      state.error.testStandReservationCancellation = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleTestStand.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSingleTestStand.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.testStand = payload;
      })
      .addCase(getSingleTestStand.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      .addCase(cancelTestStandReservation.pending, (state) => {
        state.status.testStandReservationCancellation = "loading";
      })
      .addCase(cancelTestStandReservation.fulfilled, (state) => {
        state.status.testStandReservationCancellation = "succeeded";
      })
      .addCase(cancelTestStandReservation.rejected, (state, { error }) => {
        state.status.testStandReservationCancellation = "failed";
        state.error.testStandReservationCancellation = error.message as string;
      });
  },
});

export const { resetSingleTestStand, resetCancelReservation } =
  filtersSlice.actions;

export default filtersSlice.reducer;
