import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import logoLight from "../../images/logo.png";
import logoDark from "../../images/logo-black.png";
import colors from "../../theme/colors";
import { Description } from "./ExperimentField";
import { useEffect, useState } from "react";

type Props = {
  light?: boolean;
  fullscreen?: boolean;
  error?: string;
};

const loadStates = [
  "Building Experiment",
  "Creating Google Drive",
  "Generating Google Doc",
  "Sending Email",
  "Saving to Database",
  "Wrangling bits",
  "Herding cats",
  "Fun Fact: Oak trees are more likely to get struck by lightning than other trees",
  "Fun Fact: European Oak is harder than American Oak",
  "Fun Fact: Oak is a symbol of an 80th wedding anniversary!",
  "Sorry I'm just a dumb loading message, and I don't actually know how long this will take 🤞",
];

const ExperimentLoading = ({ light, fullscreen, error }: Props) => {
  const [index, setIndex] = useState(0);
  const [loadInterval, setLoadInterval] = useState(2500);
  useEffect(() => {
    const interval = setInterval(() => {
      switch (index) {
        case 4:
          setLoadInterval(5000);
          break;
        case 9:
          setLoadInterval(10000);
          break;
      }
      index > 9 ? setIndex(0) : setIndex(index + 1);
    }, loadInterval);

    return () => clearInterval(interval);
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        background: light ? "#FFF" : colors.text.primary,
        width: fullscreen ? "100vw" : "100%",
        height: fullscreen ? "100vh" : "100%",
      }}
    >
      <Box mb={6}>
        {light ? (
          <img
            src={logoDark}
            alt="Form Energy Logo"
            style={{ maxWidth: 120 }}
          />
        ) : (
          <img
            src={logoLight}
            alt="Form Energy Logo"
            style={{ maxWidth: 120 }}
          />
        )}
      </Box>

      <Box sx={{ width: 1 / 3 }}>
        <LinearProgress />
        <Description color="textSecondary" textAlign="center" marginTop="10px">
          {loadStates[index]}
        </Description>
      </Box>
    </Box>
  );
};

export default ExperimentLoading;
