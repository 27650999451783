import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";

const flatten = (
  o: any,
  prefix: string = "",
  maxLevel: number = -1,
  level: number = 0,
  result?: any
): string[] => {
  result = result ? result : {};

  if (
    isString(o) ||
    isNumber(o) ||
    isBoolean(o) ||
    (maxLevel >= 0 && level === maxLevel)
  ) {
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref = `${pref}[${i}]`;
      } else {
        if (isEmpty(prefix)) {
          pref = i;
        } else {
          pref = `${pref}[${i}]`;
        }
      }

      flatten(o[i], pref, maxLevel, level + 1, result);
    }
    return result;
  }

  return result;
};

export default flatten;
