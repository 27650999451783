import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  resetChannelListStatus,
  listChannels,
  selectView,
  initialState,
  enableGrouping,
  disableGrouping,
  expandGroup,
  collapseGroup,
  expandAllGroups,
  collapseAllGroups,
  selectChannels,
  deselectChannels,
  selectAllVisibleChannels,
  deselectAllVisibleChannels,
  ChannelListState,
  changeStatus,
  resetChannelChangeStatus,
  CHANNELS,
} from "./slice";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import colors from "../../theme/colors";
import {
  CHANNEL_LABELS_ALL,
  CHANNEL_FILTER_LABELS,
  channelStatusToString,
  isoDateToDateString,
  channelInfraStatusToString,
  CHANNEL_LABELS_DEFAULT,
} from "../../utils/labels";
import { CHANNEL_FILTERS } from "../../utils/filters";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import ButtonChannelFilters from "../../components/table/ButtonChannelFilters";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import ButtonSort from "../../components/table/ButtonSort";
import DotIcon from "../../icons/Dot";
import ExpandIcon from "../../icons/Expand";
import GroupIcon from "../../icons/Group";
import ListIcon from "../../icons/List";
import SearchBarIcon from "../../icons/SearchBar";
import ExperimentLink from "../../components/ExperimentLink";
import ButtonSavedChannelFilters from "../../components/table/ButtonSavedChannelFilters";
import Checkbox from "@mui/material/Checkbox";
import InfraStatusMenu from "./InfraStatusMenu";
import ButtonViews from "../../components/table/ButtonViews";
import {
  CustomViewState,
  getCustomViews,
  resetAddViewStatus,
  resetEditViewStatus,
  resetListViewsStatus,
} from "../custom-views/slice";
import VirtualTable from "../../components/table/VirtualTable";
import {
  vhToPx,
  getVirtualTableHeight,
  EMPTY_STATE_ROW_HEIGHT,
  GROUPING_HEADER_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import ChannelTableCell from "./ChannelTableCell";
import ItemCountHeader from "../../components/table/ItemCountHeader";
import { shiftClickList } from "../../utils/shiftClick";

const ChannelList = () => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const {
    channels,
    selected,
    args: { order, orderBy, filters, page },
    grouping: { groupingEnabled, allGroupsExpanded, expandedGroups },
    selectedView,
    hasMore,
    status: { list: listStatus, changeStatus: changeStatusStatus },
    error: { list: listError, changeStatus: changeStatusError },
  } = useSelector<RootState, ChannelListState>(
    ({ channelList }) => channelList
  );

  const CHANNEL_LABELS = selectedView
    ? selectedView.columns.map(
        (id) => CHANNEL_LABELS_ALL.find(({ id: allId }) => id === allId)!
      )
    : CHANNEL_LABELS_DEFAULT;

  const {
    views,
    viewsQueried,
    justEdited: justEditedView,
    status: { list: listViewsStatus, add: addViewStatus, edit: editViewStatus },
    error: { list: listViewsError },
  } = useSelector<RootState, CustomViewState>(({ customViews }) => customViews);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetChannelListStatus());
  }, [dispatch]);

  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(
        listChannels({
          order,
          orderBy,
          filters,
          page,
          refresh: true,
        })
      );
    }
  }, [dispatch, listStatus, order, orderBy, filters, page]);

  const [viewsLoaded, setViewsLoaded] = useState(false);

  useEffect(() => {
    if (
      listViewsStatus === "idle" ||
      (listViewsStatus === "succeeded" && !viewsQueried.includes(CHANNELS))
    ) {
      dispatch(getCustomViews(CHANNELS));
    }
  }, [dispatch, listViewsStatus, viewsQueried]);

  useEffect(() => {
    setViewsLoaded(false);
    dispatch(resetListViewsStatus());
  }, [dispatch, user]);

  const onSelectView = useCallback(
    (view: CustomTableView<Channel> | null) => {
      if (view && view.table_id !== "CHANNELS") {
        return;
      }

      dispatch(selectView(view));

      if (view) {
        if (view.grouped) {
          dispatch(enableGrouping());
        } else {
          dispatch(disableGrouping());
        }
      } else {
        dispatch(disableGrouping());
      }

      dispatch(
        listChannels({
          order: view ? view.order : initialState.args.order,
          orderBy: view ? view.order_by : initialState.args.orderBy,
          filters,
          page: 0,
        })
      );
    },
    [dispatch, filters]
  );

  useEffect(() => {
    if (listViewsStatus === "succeeded" && !viewsLoaded) {
      const defaultView = views.find(
        ({ is_default, table_id }) => is_default && table_id === "CHANNELS"
      );

      if (defaultView) {
        onSelectView(defaultView);
        setViewsLoaded(true);
      }
    }
  }, [views, viewsLoaded, listViewsStatus, onSelectView]);

  useEffect(() => {
    if (editViewStatus === "succeeded" || addViewStatus === "succeeded") {
      onSelectView(justEditedView!);
      dispatch(resetAddViewStatus());
      dispatch(resetEditViewStatus());
    }
  }, [addViewStatus, editViewStatus, onSelectView, dispatch, justEditedView]);

  let errorToast;
  if (listStatus === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(listChannels({ order, orderBy, filters, page: 0 }))
        }
      >
        {listError}
      </Toast>
    );
  } else if (listViewsStatus === "failed") {
    errorToast = (
      <Toast open severity="error">
        {listViewsError}
      </Toast>
    );
  }

  let changeStatusToast;
  if (changeStatusStatus === "failed") {
    changeStatusToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetChannelChangeStatus())}
      >
        {changeStatusError}
      </Toast>
    );
  }

  useEffect(() => {
    if (changeStatusStatus === "succeeded") {
      dispatch(
        listChannels({
          order,
          orderBy,
          filters,
          page: 0,
          refresh: true,
        })
      );
    }
  }, [dispatch, changeStatusStatus, order, orderBy, filters]);

  const handleSortClick = (
    e: React.MouseEvent,
    property: FalconKey<Channel>
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      listChannels({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        page: 0,
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      listChannels({
        order,
        orderBy,
        filters,
        page: page + 1,
      })
    );
  };

  const handleToggleExpandAll = () => {
    if (!allGroupsExpanded && expandedGroups.length > 0) {
      dispatch(collapseAllGroups());
    } else if (allGroupsExpanded) {
      dispatch(collapseAllGroups());
    } else {
      dispatch(expandAllGroups());
    }
  };

  const handleSelectChannels = (fullNames: string[], shiftClick = false) => {
    const allSelected = !fullNames.some(
      (fullname) => !selected.includes(fullname)
    );
    if (allSelected) {
      dispatch(deselectChannels(fullNames));
    } else {
      let namesToSelect = shiftClickList(
        selected,
        fullNames,
        channels,
        shiftClick
      );
      dispatch(selectChannels(namesToSelect));
    }
  };

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(deselectAllVisibleChannels());
    } else {
      dispatch(selectAllVisibleChannels());
    }
  };

  const handleToggleGroupingEnabled = () => {
    if (groupingEnabled) {
      dispatch(disableGrouping());
    } else {
      dispatch(enableGrouping());
    }
  };

  const handleToggleGrouping = (event: React.MouseEvent, value: any) => {
    event.stopPropagation();
    const index = expandedGroups.indexOf(value);
    if (index === -1) {
      dispatch(expandGroup(value));
    } else {
      dispatch(collapseGroup(value));
    }
  };

  const shouldGroup = orderBy !== "channel__fullname";

  const hideGrouping = !CHANNEL_LABELS.some(({ id }) => id === orderBy);

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (channels.length === 0 && listStatus === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={CHANNEL_LABELS.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No channels to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let lastValue: any;
    let rowIndex = 0;

    const _orderBy = orderBy;
    tableBody = channels
      .map((row) => {
        const res = _orderBy.split("__");
        const orderBy = res[0] as keyof Channel;

        let currentValue: string | number;
        let subChannels: Channel[] = [];

        if (_orderBy === "test_meta__ready_off__completed_at") {
          currentValue = isoDateToDateString(
            row.test_meta.ready_off__completed_at
          );
          subChannels = channels.filter(
            (channel) =>
              isoDateToDateString(channel.test_meta.ready_off__completed_at) ===
              currentValue
          );
        } else {
          const orderBySubkey = res[1];
          // TODO proper typing
          if (orderBySubkey) {
            currentValue = (row[orderBy] as any)[orderBySubkey];
            subChannels = channels.filter(
              (channel) =>
                (channel[orderBy] as any)[orderBySubkey] === currentValue
            );
          } else {
            currentValue = row[orderBy] as string | number;
            subChannels = channels.filter(
              (channel) =>
                (channel[orderBy] as string | number) === currentValue
            );
          }
        }

        const isSubRow =
          groupingEnabled && shouldGroup && lastValue === currentValue;
        if (isSubRow && !expandedGroups.includes(lastValue)) {
          return null;
        }

        if (!groupingEnabled && !isSubRow) {
          rowIndex++;
        }

        const showGroupingHeader = lastValue !== currentValue;

        lastValue = currentValue;

        const isExpanded = expandedGroups.includes(currentValue);

        let groupingRow;
        if (shouldGroup && groupingEnabled && showGroupingHeader) {
          const indeterminate =
            subChannels.some(
              ({ channel }) => !selected.includes(channel.fullname)
            ) &&
            subChannels.some(({ channel }) =>
              selected.includes(channel.fullname)
            );

          const checked = !subChannels.some(
            ({ channel }) => !selected.includes(channel.fullname)
          );

          const headerLabel = CHANNEL_LABELS.find(({ id }) => id === _orderBy);

          groupingRow = (
            <TableRow
              key={`header-group-${row.channel.channel_id}`}
              className="clickable"
              hover
              onClick={(e) => handleToggleGrouping(e, currentValue)}
            >
              <TableCell
                padding="checkbox"
                style={{
                  paddingTop: 12,
                  height: GROUPING_HEADER_ROW_HEIGHT - 12,
                }}
              >
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={checked}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() =>
                    handleSelectChannels(
                      subChannels.map(({ channel }) => channel.fullname)
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox">
                <IconButton
                  size="small"
                  onClick={(e) => handleToggleGrouping(e, currentValue)}
                >
                  <ExpandIcon
                    style={isExpanded ? { transform: "rotate(90deg)" } : {}}
                  />
                </IconButton>
              </TableCell>
              <TableCell
                colSpan={CHANNEL_LABELS.length}
                scope="row"
                padding="none"
              >
                <Box pt={3} pb={4} display="flex" alignItems="center">
                  <Typography variant="h3">{headerLabel?.label}: </Typography>
                  <Box ml={3}>
                    <Typography variant="h3" style={{ fontWeight: 400 }}>
                      {_orderBy === "channel__status" ? (
                        <Box display="flex" alignItems="center">
                          <DotIcon
                            style={{
                              width: 12,
                              height: 12,
                              color:
                                row.channel.status === "O"
                                  ? colors.accent.cta
                                  : row.channel.status === "I"
                                  ? colors.accent.green
                                  : colors.accent.red,
                            }}
                          />
                          <Box ml={2}>
                            {channelStatusToString(row.channel.status)}
                          </Box>
                        </Box>
                      ) : _orderBy === "experiment__exp_id" ? (
                        <ExperimentLink
                          exp_id={row.experiment.exp_id}
                          link={row.experiment.link}
                          linkView
                        />
                      ) : _orderBy === "channel__infra_status" ? (
                        channelInfraStatusToString(row.channel.infra_status)
                      ) : (
                        currentValue || "-"
                      )}
                    </Typography>
                  </Box>
                  {_orderBy === "experiment__exp_id" &&
                  row.experiment.description ? (
                    <Box ml={3}>
                      <Typography style={{ fontWeight: 400 }}>
                        {row.experiment.description}
                      </Typography>
                    </Box>
                  ) : null}
                  <Box ml={3}>
                    <Typography style={{ fontWeight: 400 }}>
                      ({subChannels.length} channel
                      {subChannels.length !== 1 ? "s" : ""})
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          );

          if (!isExpanded) {
            rowHeights.push(GROUPING_HEADER_ROW_HEIGHT);
            return groupingRow;
          }

          rowHeights.push(GROUPING_HEADER_ROW_HEIGHT + DATA_ROW_HEIGHT);
        } else {
          rowHeights.push(DATA_ROW_HEIGHT);
        }

        return (
          <React.Fragment key={`grouping-${row.channel.fullname}`}>
            {groupingRow}
            <TableRow
              hover
              className={`clickable ${
                rowIndex % 2 === 0 ? "" : "alternating"
              } ${selected.includes(row.channel.fullname) ? "checked" : ""}`}
              onClick={() =>
                navigate(`/infrastructure/channels/${row.channel.channel_id}`)
              }
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  checked={selected.includes(row.channel.fullname)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectChannels(
                      [row.channel.fullname],
                      (e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox" />

              {CHANNEL_LABELS.map(({ id }, index) => (
                <ChannelTableCell key={index} dataKey={id} channel={row} />
              ))}
            </TableRow>
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <ItemCountHeader itemCount={channels.length} />
        <Box mr={4}>
          <ButtonChannelFilters
            labels={CHANNEL_FILTER_LABELS}
            filterOptions={CHANNEL_FILTERS}
            filters={filters}
            onChange={(filters) =>
              dispatch(listChannels({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4}>
          <ButtonSavedChannelFilters
            onSelect={(filters) =>
              dispatch(listChannels({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4}>
          <ButtonSort
            value={orderBy}
            items={CHANNEL_LABELS}
            onChange={(orderBy) =>
              dispatch(listChannels({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>

        <Box mr={4} py={1} height="2rem">
          <Divider orientation="vertical" />
        </Box>

        {groupingEnabled ? (
          <Tooltip arrow title="Switch to list mode">
            <span>
              <IconButton
                disabled={!shouldGroup}
                size="small"
                onClick={handleToggleGroupingEnabled}
              >
                <GroupIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip arrow title="Switch to group mode">
            <span>
              <IconButton
                disabled={!shouldGroup}
                size="small"
                onClick={handleToggleGroupingEnabled}
              >
                <ListIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}

        <Box ml={hideGrouping ? 0 : 4}>
          <ButtonViews
            selected={selectedView}
            items={views.filter(({ table_id }) => table_id === "CHANNELS")}
            allColumns={CHANNEL_LABELS_ALL}
            defaultColumns={CHANNEL_LABELS_DEFAULT}
            table_id="CHANNELS"
            onSelect={(view: CustomTableView<Channel> | null) =>
              onSelectView(view)
            }
          />
        </Box>

        {selected.length > 0 && (
          <Box ml={4}>
            <ButtonLogEvent
              itemType={"channel"}
              objects={channels.filter(({ channel }) =>
                selected.includes(channel.fullname)
              )}
            />
          </Box>
        )}

        <Box ml="auto">
          <InfraStatusMenu
            selected={selected}
            onChange={(infra_status: ChannelInfraStatus) =>
              dispatch(
                changeStatus({
                  channels: channels.filter(({ channel }) =>
                    selected.includes(channel.fullname)
                  ),
                  infra_status,
                })
              )
            }
          />
        </Box>
      </Box>

      <Divider />

      <VirtualTable
        height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220)}
        width="100%"
        itemCount={tableBody.length}
        itemSize={(index) => rowHeights[index]}
        header={
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={
                    selected.length > 0 &&
                    (hasMore || selected.length < channels.length)
                  }
                  checked={
                    channels.length > 0 &&
                    selected.length === channels.length &&
                    !hasMore
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleToggleSelectAll}
                />
              </TableCell>
              <TableCell padding="checkbox">
                {shouldGroup && groupingEnabled ? (
                  <IconButton size="small" onClick={handleToggleExpandAll}>
                    <ExpandIcon
                      style={
                        allGroupsExpanded ? { transform: "rotate(90deg)" } : {}
                      }
                    />
                  </IconButton>
                ) : null}
              </TableCell>
              {CHANNEL_LABELS.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding={
                    headCell.id === "experiment__exp_id" ? "none" : "normal"
                  }
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) => handleSortClick(e, headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        row={({ index }) => tableBody[index] || null}
      />

      <Box mt={6} display="flex" alignItems="center">
        {listStatus === "loading" && channels.length === 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>
        ) : channels.length > 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Viewing {channels.length}
          </Typography>
        ) : null}

        {hasMore ? (
          <Box ml={4}>
            <Button
              color="secondary"
              size="small"
              onClick={handleLoadMoreClick}
            >
              Load more
            </Button>
          </Box>
        ) : null}

        {listStatus === "loading" ? (
          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        ) : null}
      </Box>
      {errorToast}
      {changeStatusToast}
    </>
  );
};

export default ChannelList;
