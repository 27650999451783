import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Pencil = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0383 1.96171C13.6306 1.55573 13.0775 1.32953 12.5022 1.33342C11.9268 1.33732 11.3769 1.57099 10.9747 1.98245L2.38235 10.5748L1.3335 14.6665L5.42522 13.6171L14.0176 5.02473C14.4291 4.62273 14.6629 4.07286 14.6668 3.49754C14.6707 2.92223 14.4444 2.36925 14.0383 1.96171Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7295 2.22839L13.7718 5.27067"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.38281 10.5742L5.42805 13.6141"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Pencil;
