import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type { AuthState } from "../../store/auth/slice";
import type { RootState } from "../../store";
import ImpersonationUserSearch from "./ImpersonationUserSearch";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { impersonate } from "../../store/auth/slice";

const ImpersonationModal = () => {
  const dispatch = useDispatch();
  const { user, impersonating } = useSelector<RootState, AuthState>(
    ({ auth }) => auth
  );
  const { control, handleSubmit } = useForm();

  const onImpersonate = ({ user }: { user: User }) => {
    dispatch(impersonate(user));
  };

  return (
    <Modal open={!!user?.shared_account && !impersonating}>
      <form onSubmit={handleSubmit(onImpersonate)}>
        <Typography variant="h2">Lab Account</Typography>
        <Box mt={8} mb={4}>
          This is a shared lab account, please select your name below.
        </Box>
        <Controller
          name="user"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { invalid },
          }) => (
            <ImpersonationUserSearch
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={invalid}
            />
          )}
        />
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Button color="primary" type="submit">
            Done
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ImpersonationModal;
