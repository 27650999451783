import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { readCSVFromClipboardData } from "../../utils/batchEntryUtils";
import Toast from "../../components/Toast";

interface BatchExternalDataEntryGridProps {
  columns: GridColDef[];
  apiRef: React.MutableRefObject<GridApi>;
  cellRows: GridValidRowModel[];
  onCellRowsUpdate: (newRows: GridValidRowModel[]) => void;
}

const BatchExternalDataEntryGrid = ({
  apiRef,
  cellRows,
  columns,
  onCellRowsUpdate,
}: BatchExternalDataEntryGridProps) => {
  // Bug where pasting in a column's worth of values while a cell is in edit mode
  // will set the active cell's value to the entire pasted value when the cell is
  // blurred. Here, store the intended value in firstPastedFieldAndVal of the first
  // cell via handleKeyPressOnCell, and clear it once processRowUpdate has completed.
  const [firstPastedFieldAndVal, setFirstPastedFieldAndVal] = useState<{
    [key: string]: string | number;
  } | null>(null);
  const [activeCell, setActiveCell] = useState<GridCellParams | null>(null);
  const [showPasteInstructions, setShowPasteInstructions] = useState(false);

  const processRowUpdate = (updatedRow: GridValidRowModel) => {
    const activeFirstPastedField = firstPastedFieldAndVal || {};
    const updatedRow_ = {
      ...updatedRow,
      ...activeFirstPastedField,
    };
    const newRows = cellRows.map((cellRow_) => {
      if (updatedRow.id === cellRow_.id) {
        return updatedRow_;
      }
      return cellRow_;
    });
    onCellRowsUpdate(newRows);
    if (firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
    return updatedRow_;
  };
  const handleCellClick: GridEventListener<"cellClick"> = (clickedCell) => {
    if (
      !activeCell ||
      activeCell.id !== clickedCell.id ||
      activeCell.field !== clickedCell.field
    ) {
      setActiveCell(clickedCell);
    }
  };
  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      if (!!activeCell && activeCell.isEditable) {
        var splitTextFromClipboard: null | string[] = readCSVFromClipboardData(
          event,
          /\s/g
        );
        if (splitTextFromClipboard && splitTextFromClipboard.length > 0) {
          const clickedRowIndex = cellRows.findIndex(
            (row_) => row_.id === activeCell.id
          );
          let clipboardIndex = 0;
          const newRows = cellRows.map((cellRow_, rowIndex) => {
            if (
              rowIndex < clickedRowIndex ||
              clipboardIndex >= splitTextFromClipboard!.length
            )
              return cellRow_;
            const valFromClipboard = splitTextFromClipboard![clipboardIndex];
            clipboardIndex += 1;
            return {
              ...cellRow_,
              [activeCell.field]: valFromClipboard,
            };
          });
          setFirstPastedFieldAndVal({
            [activeCell.field]: splitTextFromClipboard[0],
          });
          apiRef.current.setEditCellValue({
            id: activeCell.id,
            field: activeCell.field,
            value: splitTextFromClipboard[0],
          });
          onCellRowsUpdate(newRows);
        }
      } else {
        if (!activeCell) setShowPasteInstructions(true);
        if (!!firstPastedFieldAndVal) setFirstPastedFieldAndVal(null);
      }
    },
    [activeCell, apiRef, cellRows, firstPastedFieldAndVal, onCellRowsUpdate]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, [handlePaste]);

  return (
    <>
      <DataGrid
        apiRef={apiRef}
        rows={cellRows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onCellClick={handleCellClick}
        autoHeight
        hideFooter
      />
      <Toast
        severity="warning"
        open={showPasteInstructions}
        onClose={() => {
          setShowPasteInstructions(false);
        }}
      >
        To paste, click the first cell in the column you'd like to paste, then
        try again.
      </Toast>
    </>
  );
};

export default BatchExternalDataEntryGrid;
