import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";
import Chip from "../Chip";
import PriorityChip from "../PriorityChip";
import Modal from "../Modal";
import Button from "../Button";
import CaretBottomIcon from "../../icons/CaretBottom";
import { cellIdToString } from "../../utils/labels";

export const StyledChip = styled(Chip)({
  flexDirection: "row-reverse",
});

type Props = {
  disabled: boolean;
  onSelect: (priority: number) => void;
  conditionNames: string[];
  cellIds: number[];
};

const ButtonPriority = ({
  disabled,
  onSelect,
  conditionNames,
  cellIds,
}: Props) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  const [priority, setPriority] = useState<number | null>(null);

  return (
    <>
      <StyledChip
        clickable
        disabled={disabled}
        onClick={handleButtonClick}
        icon={<CaretBottomIcon />}
        rightIcon
        label="Priority"
      />

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setPriority(1);
            handleButtonClose();
          }}
        >
          <PriorityChip label={1} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPriority(2);
            handleButtonClose();
          }}
        >
          <PriorityChip label={2} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPriority(3);
            handleButtonClose();
          }}
        >
          <PriorityChip label={3} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPriority(4);
            handleButtonClose();
          }}
        >
          <PriorityChip label={4} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPriority(5);
            handleButtonClose();
          }}
        >
          <PriorityChip label={5} />
        </MenuItem>
      </Menu>

      <Modal open={priority !== null} onClose={() => setPriority(null)}>
        <>
          <Typography variant="h2">Change priority ?</Typography>
          <Box mt={8} mb={6}>
            <Typography color="textPrimary">
              Please note this will change priority for all cells in the
              condition{conditionNames.length > 0 ? "s" : ""} listed below.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography
              color="textSecondary"
              className="small"
              fontWeight={500}
            >
              Condition{conditionNames.length > 0 ? "s" : ""}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography color="textPrimary">
              {conditionNames.join(", ")}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography
              color="textSecondary"
              className="small"
              fontWeight={500}
            >
              Cells
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography color="textPrimary">
              {cellIds.map((cell_id) => cellIdToString(cell_id)).join(", ")}
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                size="small"
                onClick={() => setPriority(null)}
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              size="small"
              onClick={() => {
                onSelect(priority!);
                setPriority(null);
              }}
            >
              Continue
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default ButtonPriority;
