import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Eye = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 3.5008C5.31267 3.45547 2.53334 5.33347 0.786007 7.2568C0.406129 7.67854 0.406129 8.31907 0.786007 8.7408C2.49534 10.6235 5.26667 12.5448 8.00001 12.4988C10.7333 12.5448 13.5053 10.6235 15.216 8.7408C15.5959 8.31907 15.5959 7.67854 15.216 7.2568C13.4667 5.33347 10.6873 3.45547 8.00001 3.5008Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 8.00067C10.4996 9.38129 9.38018 10.5002 7.99956 10.5C6.61893 10.4998 5.49988 9.3804 5.5 7.99978C5.50012 6.61915 6.61937 5.5 8 5.5C8.66321 5.49982 9.2993 5.76325 9.7682 6.23227C10.2371 6.7013 10.5004 7.33746 10.5 8.00067V8.00067Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Eye;
