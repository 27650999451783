// React
import React, { useCallback, useState, useEffect } from "react";

// MUI
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Components
import client from "../api";
import Modal from "./Modal";

type Props = {
  resourceType: string;
  resource_id: number;
};

type Event = {
  title: string;
  href: string;
  isCompleted: boolean;
  event_datetime: string;
  initial_event_bin?: string;
  event_bin_a: string; // Deprecated
  images: string[];
  completed_at: string | null;
  cycle_number: number | null;
  is_human_error?: string | null;
  final_event_bin?: string;
  final_bin: string; // Deprecated
};

const EventSummary = ({ resourceType, resource_id }: Props) => {
  const isTablet = useMediaQuery("(min-width:760px)");
  const [events, setEvents] = useState<Event[] | null>(null);
  const [viewingPhoto, setViewingPhoto] = useState("");

  const getAssociatedEvents = useCallback(
    async (resourceType: string, resource_id: number) => {
      try {
        const response = await client.get(
          `meta/events/${resourceType}/${resource_id}`
        );
        setEvents(response.events);
      } catch (err) {
        setEvents([]);
      }
    },
    []
  );

  useEffect(() => {
    getAssociatedEvents(resourceType, resource_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box px={isTablet ? 6 : 0} my={4}>
        <Paper variant="outlined" square>
          <Box p={isTablet ? 6 : 3}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h3">Event Summary</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  {events ? (
                    events.length > 0 ? (
                      events.map((event, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={6}>
                            <Typography color="textSecondary">
                              <a
                                href={event.href}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {event.title}
                              </a>
                            </Typography>
                            {event.images.length > 0 && (
                              <Grid item xs={12} sx={{ display: "flex" }}>
                                {event.images.map((url, index) => (
                                  <Card
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    sx={{
                                      width: "fit-content",
                                      height: "fit-content",
                                      m: "10px",
                                    }}
                                    onClick={() => setViewingPhoto(url)}
                                  >
                                    <CardMedia
                                      sx={{ width: "7.5rem", height: "7.5rem" }}
                                      image={url}
                                    />
                                  </Card>
                                ))}
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12}>
                              <Typography color="textSecondary">
                                Status:{" "}
                                {event.isCompleted ? "Completed" : "Incomplete"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography color="textSecondary">
                                Initial Bin:{" "}
                                {event.initial_event_bin || event.event_bin_a}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography color="textSecondary">
                                Final Bin:{" "}
                                {"final_event_bin" in event
                                  ? event.final_event_bin
                                  : event.final_bin !== ""
                                  ? event.final_bin
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography color="textSecondary">
                                Date Logged: {event.event_datetime}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography color="textSecondary">
                                Date Resolved: {event.completed_at || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography color="textSecondary">
                                Cycle Number: {event.cycle_number || "-"}
                              </Typography>
                            </Grid>
                            {event.is_human_error && (
                              <Grid item xs={12} mt={2}>
                                <Typography color="textSecondary">
                                  Is This Human Error?{" "}
                                  {event.is_human_error === "1" ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography color="textSecondary">
                          No Associated Events
                        </Typography>
                      </Grid>
                    )
                  ) : (
                    <CircularProgress color="inherit" size={20} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Modal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto("")}
        maxWidth={false}
      >
        <a href={viewingPhoto} target="_blank" rel="noreferrer">
          <img
            src={viewingPhoto}
            alt="Event Attachment"
            style={{ width: "100%" }}
          />
        </a>
      </Modal>
    </>
  );
};

export default EventSummary;
