import { useState } from "react";
import { DateTime } from "luxon";
import isNull from "lodash/isNull";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CaretBottomIcon from "../../../icons/CaretBottom";
import CheckCircleIcon from "../../../icons/CheckCircle";
import NavigationMenuHorizontalIcon from "../../../icons/NavigationMenuHorizontal";
import colors from "../../../theme/colors";
import {
  getInitialsForUser,
  isoDateToDateTimeString,
} from "../../../utils/labels";
import ConditionCell from "../../../components/forms/ConditionCell";
import InputCell from "../../../components/forms/InputCell";
import ReferenceCalibrationModal from "./ReferenceCalibrationModal";
import AddSingleRefCalCell from "./AddSingleRefCalCell";

type Props = {
  cell_id: number;
  disabled: boolean;
  validating: boolean;
  className?: string;
  value?: CellReferenceCalibration;
  executor: User | null;
  onSave: (callback?: () => void) => void;
};

const ReferenceCalibrationSingleCell = ({
  cell_id,
  disabled,
  validating,
  className,
  value,
  executor,
  onSave,
}: Props) => {
  const [checkButtonEl, setCheckButtonEl] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const handleCheckButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setCheckButtonEl(event.currentTarget);
  };
  const handleCheckButtonClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setCheckButtonEl(null);
  };

  const [menuButtonEl, setMenuButtonEl] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setMenuButtonEl(event.currentTarget);
  };
  const handleMenuButtonClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setMenuButtonEl(null);
  };

  const [modalOpen, setModalOpen] = useState(false);

  if (!value || isNull(value.measurement)) {
    return (
      <ConditionCell>
        <AddSingleRefCalCell
          cell_id={cell_id}
          disabled={false}
          validating={validating}
          executor={executor}
          onSave={onSave}
        />
      </ConditionCell>
    );
  }

  const {
    preferred_executor,
    preferred_executor_name,
    reference_electrode_type,
    measurement,
    reference_voltage_assumed,
    measured_at: measured_at_unix,
    mmo_identifier,
  } = value;
  const measured_at = DateTime.fromSeconds(measured_at_unix).toISO();

  const endAdornment = (
    <Box ml="auto" display="flex" alignItems="center">
      <IconButton size="small" onClick={handleCheckButtonClick}>
        <CheckCircleIcon
          style={{
            width: 20,
            height: 20,
            color: colors.accent.green,
          }}
        />
        <CaretBottomIcon
          style={{
            marginLeft: "0.5rem",
            width: 16,
            height: 16,
            color: colors.text.secondary,
            transform: !checkButtonEl ? "rotate(-90deg)" : undefined,
          }}
        />
      </IconButton>

      <IconButton size="small" onClick={handleMenuButtonClick}>
        <NavigationMenuHorizontalIcon
          style={{
            width: 20,
            height: 20,
          }}
        />
      </IconButton>
    </Box>
  );

  // MMO identifiers are only present on RCs created by the MMOTracker.
  // We want these RCs to be read-only to prevent users writing them and having
  // their data overwritten by the scheduled MMOTracker sync task.
  const isReadOnlyReferenceCalibration = !!mmo_identifier;
  return (
    <>
      <InputCell
        className={`${className} textPrimary componentCompleted`}
        disabled={true}
        value={measurement}
        endAdornment={endAdornment}
      />

      <Menu
        anchorEl={checkButtonEl}
        open={!!checkButtonEl}
        onClose={handleCheckButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ListItem>
          <Box display="flex" alignItems="center">
            <Avatar className="bg-cta">
              {getInitialsForUser({ name: preferred_executor_name } as User)}
            </Avatar>
            <Box ml={3} mr={2}>
              {preferred_executor_name}
            </Box>
            <Box color={colors.text.secondary}>
              {isoDateToDateTimeString(measured_at)}
            </Box>
          </Box>
        </ListItem>
      </Menu>

      <Menu
        anchorEl={menuButtonEl}
        open={!!menuButtonEl}
        onClose={handleMenuButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleMenuButtonClose(e);
            setModalOpen(true);
          }}
        >
          {isReadOnlyReferenceCalibration
            ? "View measurement"
            : "Edit measurement"}
        </MenuItem>
      </Menu>

      <ReferenceCalibrationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        cell_id={cell_id}
        executor_id={preferred_executor}
        type={reference_electrode_type}
        measurement={measurement}
        reference_voltage_assumed={reference_voltage_assumed}
        measured_at={measured_at_unix}
        mmo_identifier={mmo_identifier}
      />
    </>
  );
};

export default ReferenceCalibrationSingleCell;
