import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import isEmpty from "lodash/isEmpty";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import colors from "../../../theme/colors";
import Modal from "../../../components/Modal";
import CalendarIcon from "../../../icons/Calendar";
import type { RootState } from "../../../store";
import * as _ from "lodash";
import {
  addInitialReferenceCalibration,
  MetadataState,
  resetAddInitialReferenceCalibration,
} from "../slice";
import {
  addOrEditReferenceCalibration,
  resetAddOrEditReferenceCalibration,
} from "../slice";
import parseCellIdString from "../../../utils/parseCellIdString";

type Props = {
  open: boolean;
  onClose: () => void;
  test_meta_id?: number;
  calibration_id?: number;
  cell_id?: number;
  executor_id: number | null;
  type?: string | null;
  measurement?: number;
  reference_voltage_assumed?: boolean;
  measured_at?: number;
  mmo_identifier?: string;
};

const MAX_INPUT = 50.0;

const ReferenceCalibrationModal = ({
  open,
  onClose,
  test_meta_id,
  calibration_id,
  cell_id,
  executor_id,
  type: _type,
  measurement: _measurement,
  reference_voltage_assumed,
  measured_at,
  mmo_identifier,
}: Props) => {
  const { cell_id_string = "" } = useParams();
  const { ui } = useSelector<RootState, MetadataState>(
    ({ metadata }) => metadata
  );

  // Metadata state in redux
  const dispatch = useDispatch();
  const {
    status: { addEditRefCal: addEditStatus, initialRefCal: initialStatus },
    error: { addEditRefCal: addEditError, initialRefCal: initialError },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  // Measurement fields
  const [type, setType] = useState<string | null>(null);
  useEffect(() => setType(_type ? _type : null), [_type]);

  const [measurement, setMeasurement] = useState<number | null>(null);
  useEffect(
    () =>
      setMeasurement(
        !isNaN(parseFloat(`${_measurement}`)) ? _measurement! : null
      ),
    [_measurement]
  );

  const [voltageAssumed, setVoltageAssumed] = useState<boolean>(
    _.isNil(reference_voltage_assumed) ? false : reference_voltage_assumed
  );

  // MMO identifiers are only present on RCs created by the MMOTracker.
  // We want these RCs to be read-only to prevent users writing them and having
  // their data overwritten by the scheduled MMOTracker sync task.
  const isReadOnlyReferenceCalibration = !!mmo_identifier;

  const [date, setDate] = useState<DateTime | null>(null);
  const [time, setTime] = useState<DateTime | null>(null);
  useEffect(() => {
    if (measured_at) {
      const date = DateTime.fromSeconds(measured_at);
      setDate(date.isValid ? date : DateTime.now());
      setTime(date.isValid ? date : DateTime.now());
    } else {
      setDate(DateTime.now());
      setTime(DateTime.now());
    }
  }, [measured_at]);

  // Electrode type selector
  const [options, setOptions] = useState<string[]>([]);
  useEffect(() => {
    if (isEmpty(options) && ui) {
      const refCalOptions = ui!.find(
        ({ ref_cal_options }) => !!ref_cal_options
      )?.ref_cal_options;
      if (refCalOptions) setOptions(refCalOptions);
    }
  }, [ui, options]);

  // Measurement workflow
  const onSubmit = () => {
    const { conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string);

    const measured_at = DateTime.local(
      date!.year,
      date!.month,
      date!.day,
      time!.hour,
      time!.minute,
      time!.second
    );

    if (!cell_id) {
      dispatch(
        addOrEditReferenceCalibration({
          test_meta_id,
          calibration_id,
          measurement: measurement!,
          reference_voltage_assumed: voltageAssumed,
          measured_at: measured_at.toSeconds(),
          reference_electrode_type: type!,
          executor_id,
          conditionIdsToRequestedCellIds,
          mmo_identifier,
        })
      );
    } else {
      dispatch(
        addInitialReferenceCalibration({
          cell_id: cell_id!,
          measurement: measurement!,
          reference_voltage_assumed: voltageAssumed,
          measured_at: measured_at.toSeconds(),
          reference_electrode_type: type!,
          executor_id,
          conditionIdsToRequestedCellIds,
          mmo_identifier,
        })
      );
    }
  };

  useEffect(() => {
    if (!open) {
      dispatch(resetAddOrEditReferenceCalibration());
      dispatch(resetAddInitialReferenceCalibration());
    } else {
      setType(_type ? _type : null);
      setMeasurement(
        !isNaN(parseFloat(`${_measurement}`)) ? _measurement! : null
      );

      if (measured_at) {
        const date = DateTime.fromSeconds(measured_at);
        setDate(date.isValid ? date : DateTime.now());
        setTime(date.isValid ? date : DateTime.now());
      } else {
        setDate(DateTime.now());
        setTime(DateTime.now());
      }
    }
  }, [open, dispatch, _type, _measurement, measured_at]);

  useEffect(() => {
    if (addEditStatus === "succeeded" || initialStatus === "succeeded") {
      onClose();
    }
  }, [addEditStatus, initialStatus, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Typography variant="h2">Calibration voltage measurement</Typography>
        {isReadOnlyReferenceCalibration && (
          <Box mt={6} mb={2}>
            Synced from{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1PkwRLsQRdFnkj9lVZgJ3okFyFTWeLMHhZBDY0rOJ9fI/edit#gid=1031860138"
              target="_blank"
              rel="noreferrer"
            >
              Koslow MMO Calibration Input sheet
            </a>
            . This reference calibration cannot be edited in Oak. Please edit
            the MMO Calibration Input sheet instead.
          </Box>
        )}
        <Box mt={6} mb={2} display="flex" alignItems="flex-end">
          <Typography color="textSecondary" className="small">
            Electrode Type <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <FormControl fullWidth>
          <Autocomplete
            options={options}
            multiple={false}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={type}
            disabled={isReadOnlyReferenceCalibration}
            onChange={(e, data) => setType(data)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                color="secondary"
              />
            )}
            renderOption={(props, value: any) => (
              <li {...props}>
                <Box px={4} py={2} width={208}>
                  {value}
                </Box>
              </li>
            )}
          />
        </FormControl>

        <Box mt={6} mb={2} display="flex" alignItems="flex-end">
          <Typography color="textSecondary" className="small">
            Calibration Voltage (volts){" "}
            <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <FormControl fullWidth>
          <Input
            disableUnderline
            disabled={isReadOnlyReferenceCalibration}
            type="number"
            inputProps={{
              min: 0,
              max: MAX_INPUT,
              onWheel: (e) => e.currentTarget.blur(),
            }}
            error={
              measurement ? measurement < 0 || measurement > MAX_INPUT : false
            }
            value={isNaN(parseFloat(`${measurement}`)) ? "" : measurement}
            onChange={(e) => setMeasurement(parseFloat(e.currentTarget.value))}
          />
        </FormControl>

        <Box mt={6} mb={2} display="flex" alignItems="flex-end">
          <Typography color="textSecondary" className="small">
            Reference Voltage Assumed, Not Measured
          </Typography>
        </Box>
        <FormControl fullWidth>
          <Checkbox
            color="secondary"
            defaultChecked={voltageAssumed}
            onChange={(v) => setVoltageAssumed(v.target.checked)}
            style={{ marginRight: "auto", paddingLeft: "0", paddingTop: "0" }}
            disabled={isReadOnlyReferenceCalibration}
          />
        </FormControl>
        {/* Only display MMO Identifier box for MMO calibrations */}
        {type === "MMO" && isReadOnlyReferenceCalibration && (
          <>
            <Box mt={6} mb={2} display="flex" alignItems="flex-end">
              <Typography color="textSecondary" className="small">
                MMO Identifier
              </Typography>
            </Box>
            <FormControl fullWidth>
              <Input
                disableUnderline
                type="text"
                disabled
                value={mmo_identifier || ""}
              />
            </FormControl>
          </>
        )}
        <Box mt={6} mb={2} display="flex" alignItems="flex-end">
          <Typography color="textSecondary" className="small">
            Date measured <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <FormControl fullWidth>
          <DatePicker
            disabled={isReadOnlyReferenceCalibration}
            inputFormat="yyyy-MM-dd"
            mask="____-__-__"
            InputAdornmentProps={{ position: "start", style: { margin: 12 } }}
            renderInput={(props) => (
              <TextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  style: { padding: "0.5rem 0" },
                  placeholder: "YYYY-MM-DD",
                }}
                InputProps={{
                  ...props.InputProps,
                  disableUnderline: true,
                }}
                variant="standard"
              />
            )}
            components={{
              OpenPickerIcon: () => (
                <CalendarIcon
                  fontSize="small"
                  style={{
                    color: colors.text.secondary,
                    paddingTop: 3,
                  }}
                />
              ),
            }}
            openTo="day"
            views={["year", "month", "day"]}
            value={date}
            onChange={(d) => setDate(d)}
          />
        </FormControl>

        <Box mt={6} mb={2} display="flex" alignItems="flex-end">
          <Typography color="textSecondary" className="small">
            Time measured <span style={{ color: colors.accent.red }}>*</span>
          </Typography>
        </Box>
        <FormControl fullWidth>
          <TimePicker
            disabled={isReadOnlyReferenceCalibration}
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            disableOpenPicker
            disableMaskedInput={false}
            renderInput={(props) => (
              <TextField
                {...props}
                InputProps={{
                  ...props.InputProps,
                  disableUnderline: true,
                }}
                inputProps={{
                  ...props.inputProps,
                  placeholder: "HH:MM:SS (24-hour format)",
                }}
                variant="standard"
              />
            )}
            value={time}
            onChange={(d) => setTime(d)}
          />
        </FormControl>

        {!isReadOnlyReferenceCalibration && (
          <Box mt={6} display="flex" justifyContent="space-between">
            <div className="small" style={{ color: colors.accent.red }}>
              {addEditError}
              {initialError}
            </div>
            <Button
              size="small"
              color="primary"
              onClick={onSubmit}
              endIcon={
                addEditStatus === "loading" || initialStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={
                !type ||
                measurement === null ||
                isNaN(measurement) ||
                measurement < 0 ||
                measurement > MAX_INPUT ||
                !date?.isValid ||
                !time?.isValid ||
                addEditStatus === "loading" ||
                initialStatus === "loading"
              }
            >
              Submit
            </Button>
          </Box>
        )}
      </LocalizationProvider>
    </Modal>
  );
};

export default ReferenceCalibrationModal;
