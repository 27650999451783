import React from "react";
import Chip from "@mui/material/Chip";
import styled from "@mui/styles/styled";
import colors, { addAlpha } from "../theme/colors";

const StyledChip = styled(Chip)({
  borderRadius: "50rem",
  "& .MuiChip-label": {
    paddingLeft: 12,
    paddingRight: 12,
  },
});

type Props = {
  label: number;
};

const PriorityChip = ({ label }: Props) => {
  switch (label) {
    case 1:
      return (
        <StyledChip
          size="small"
          style={{ backgroundColor: addAlpha(colors.accent.red, 0.5) }}
          label={label}
        />
      );
    case 2:
      return (
        <StyledChip
          size="small"
          style={{ backgroundColor: addAlpha(colors.accent.orange, 0.5) }}
          label={label}
        />
      );
    case 3:
      return (
        <StyledChip
          size="small"
          style={{ backgroundColor: addAlpha(colors.accent.yellow, 0.5) }}
          label={label}
        />
      );
    case 4:
      return (
        <StyledChip
          size="small"
          style={{ backgroundColor: addAlpha(colors.accent.green, 0.5) }}
          label={label}
        />
      );
    case 5:
      return (
        <StyledChip
          size="small"
          style={{ backgroundColor: colors.rules }}
          label={label}
        />
      );
    default:
      return <StyledChip size="small" label={label} />;
  }
};

export default PriorityChip;
