import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import type { TooltipProps } from "@mui/material/Tooltip";

type Props = {
  wrap?: boolean;
} & TooltipProps;

const OverflowTooltip = ({ children, wrap, ...props }: Props) => {
  const [hover, setHover] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }
    const compare =
      ref.scrollWidth > ref.clientWidth || ref.scrollHeight > ref.clientHeight;
    setHover(compare);
  }, [ref, children]);

  return (
    <Tooltip arrow {...props} disableHoverListener={!hover}>
      <div
        ref={setRef}
        className={wrap ? "overflow-wrap-ellipsis" : "overflow-ellipsis"}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
