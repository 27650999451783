import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useBeforeunload } from "react-beforeunload";
import { InView } from "react-intersection-observer";
import uniq from "lodash/uniq";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Section from "./layout/Section";
import ConnectedSection from "./layout/ConnectedSection";
import InfoBlock from "./layout/InfoBlock";
import Wrapper from "./layout/Wrapper";
import LoadingLayout from "../../layout/Loading";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";
import EventSummary from "../../components/EventSummary";
import type { RootState } from "../../store";
import {
  MetadataState,
  resetMetadataState,
  resetUpdateTestConditionTemplates,
  updateTestConditionTemplates,
} from "./slice";
import {
  getMetadata,
  saveMetadata,
  resetSaveMetadata,
  resetGetMetadata,
  resetCompleteMetadata,
} from "./slice";
import colors from "../../theme/colors";
import { getDirtyValues } from "../../utils/forms";
import client from "../../api";
import parseCellIdString from "../../utils/parseCellIdString";
import Banner from "../../components/Banner";

const UNSAVED_INPUTS_ERROR =
  "You have unsaved inputs. You must save all inputs before refreshing the teardown template.";

const MetadataLayout = () => {
  const { cell_id_string = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [
    conditionIdWithNewTemplateAvailable,
    setConditionIdWithNewTemplateAvailable,
  ] = useState<null | number>(null);
  const [teardownTemplateRefreshSuccess, setTeardownTemplateRefreshSuccess] =
    useState(false);
  const [templateRefreshError, setTemplateRefreshError] = useState(false);
  const [visibleCells, setVisibleCells] = useState<number[]>([]);

  // Metadata state in redux
  const {
    ui,
    pendingChanges,
    status: {
      get: getStatus,
      save: saveStatus,
      complete: completeStatus,
      updateTestConditionTemplates: updateTestConditionTemplatesStatus,
    },
    error: { get: getError, save: saveError, complete: completeError },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  useEffect(() => {
    if (visibleCells.length > 0) {
      const {
        hiddenConditionIds,
        visibleConditionIds,
        conditionIdsToRequestedCellIds,
      } = parseCellIdString(cell_id_string, visibleCells);
      const getFreshData = !ui || teardownTemplateRefreshSuccess;
      // only request full data for visible conditions for which we don't yet
      // have full data.
      const conditionIds = visibleConditionIds.filter(
        (cellConditionId_) =>
          getFreshData ||
          ui.every(
            (uiItem) =>
              uiItem.cell_condition_id !== cellConditionId_ ||
              ("basic_info" in uiItem && uiItem.basic_info)
          )
      );
      if (conditionIds.length > 0) {
        dispatch(
          getMetadata({ conditionIdsToRequestedCellIds, hiddenConditionIds })
        );
        dispatch(resetSaveMetadata());
        dispatch(resetCompleteMetadata());
        dispatch(resetGetMetadata());
      }
    }
    // eslint-disable-next-line
  }, [dispatch, cell_id_string, teardownTemplateRefreshSuccess, visibleCells]);

  useEffect(() => {
    return () => {
      dispatch(resetMetadataState());
    };
  }, [dispatch]);

  /* UI scrolling management */
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const visibleSteps = useRef<number[]>([]);
  const [step, setStep] = useState(-1);

  const handleInView = useCallback((inView: boolean, step: number) => {
    const { current } = visibleSteps;
    const index = current.indexOf(step);
    let newSteps = [...current];
    if (!inView) {
      if (index > -1) {
        newSteps.splice(index, 1);
        setStep(newSteps.sort((a, b) => a - b)[0]);
        visibleSteps.current = newSteps;
      }
    } else if (index === -1) {
      newSteps.push(step);
      setStep(newSteps.sort((a, b) => a - b)[0]);
      visibleSteps.current = newSteps;
    }
  }, []);

  /* Clean data to pass to "Log Event" button */
  const [eventData, setEventData] = useState<EventData[] | null>(null);
  const [viewCellEvents, setViewCellEvents] = useState<null | number>(null);
  const [eventMap, setEventMap] = useState<EventMap>({});

  const cleanEventData = () => {
    let eventData: any[] = [];
    let _eventMap: EventMap = {};
    ui?.forEach((obj) => {
      if ("owner" in obj) {
        let exp_id = obj.exp_id;
        let owner = obj.owner;

        obj.cells.forEach((cell) => {
          let conditionData = {
            ...cell,
            experiment: { exp_id: exp_id },
            owner: owner,
          };

          if (cell.hasEvents) {
            let entry: EventMap = {};
            entry[cell.cell_id] = (
              <EventSummary resourceType={"cell"} resource_id={cell.cell_id} />
            );
            _eventMap = {
              ..._eventMap,
              ...entry,
            };
          }
          eventData.push(conditionData);
        });
      }
    });

    setEventData(eventData);
    setEventMap(_eventMap);
  };

  useEffect(() => {
    if (ui && visibleCells.length > 0) {
      const visibleConditionWithAvailableUpdate = ui.find(
        (conditionMetadata) =>
          "can_refresh" in conditionMetadata &&
          conditionMetadata.can_refresh &&
          conditionMetadata.cells.some((cell_) =>
            visibleCells.includes(cell_.cell_id)
          )
      );
      if (visibleConditionWithAvailableUpdate) {
        setConditionIdWithNewTemplateAvailable(
          visibleConditionWithAvailableUpdate.cell_condition_id
        );
      } else {
        if (!!conditionIdWithNewTemplateAvailable) {
          setConditionIdWithNewTemplateAvailable(null);
        }
      }
      cleanEventData();
    }
    // eslint-disable-next-line
  }, [ui, visibleCells]);

  /* Visible cells */
  useEffect(() => {
    const { allIds } = parseCellIdString(cell_id_string);
    const firstConditionId = allIds[0][0];
    setVisibleCells(
      uniq(
        allIds
          .filter((ids_) => ids_[0].startsWith(firstConditionId))
          .map((ids_) => parseInt(ids_[1]))
      )
    );
  }, [cell_id_string]);

  /* Warn when closing tab */
  useBeforeunload((e) => {
    if (formState.isDirty) {
      e.preventDefault();
    }
  });

  const handleRefreshTeardownTemplateClick = async () => {
    if (formState.isDirty) {
      alert(UNSAVED_INPUTS_ERROR);
      return;
    }
    try {
      const response = await client.post("meta/cells/refresh-teardown-modes", {
        cell_ids: ui?.flatMap(({ cells }) =>
          cells.map(({ cell_id }) => cell_id)
        ),
      });
      if (response) {
        setTeardownTemplateRefreshSuccess(true);
        setConditionIdWithNewTemplateAvailable(null);
      }
    } catch (err) {
      setTemplateRefreshError(true);
    }
  };

  const handleRefreshComponentTemplateClick = async () => {
    if (formState.isDirty) {
      alert(UNSAVED_INPUTS_ERROR);
      return;
    }
    const { hiddenConditionIds, conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string, visibleCells);
    dispatch(resetMetadataState());
    dispatch(
      updateTestConditionTemplates({
        conditionId: conditionIdWithNewTemplateAvailable!,
        conditionIdsToRequestedCellIds,
        hiddenConditionIds,
      })
    );
  };

  /* Sidebar cell layout */
  const cellLayout = useMemo<MetadataSidebarCellLayout>(() => {
    const layout: MetadataSidebarCellLayout["items"] = {};

    ui?.forEach(({ exp_id, cell_condition_id, name, cells, group_number }) => {
      const key = `EXP: ${`${exp_id}`.padStart(6, "0")} - ${name}`;
      if (!layout[key]) {
        layout[key] = {
          title: key,
          group_number,
          items: [],
        };
      }

      cells.forEach(({ cell_id }) => {
        if (!cell_id_string.includes(`${cell_condition_id}-${cell_id}`)) {
          return;
        }
        layout[key].items.push({
          name: `Cell ID ${`${cell_id}`.padStart(6, "0")}`,
          cell_id,
          active: visibleCells.includes(cell_id),
        });
      });
    });

    return { items: layout };
  }, [ui, cell_id_string, visibleCells]);

  /* Memoize the current spec */
  const layout: ConditionMetadata | null = useMemo(() => {
    const layouts = ui?.filter(({ cells }) =>
      cells.some(({ cell_id }) => visibleCells.includes(cell_id))
    );

    if (!layouts || layouts.length === 0) {
      return null;
    }

    // We'll never have more than one layout per condition
    const { cells, fields, cell_condition_id, ...layout } =
      layouts[0] as ConditionMetadata;

    return {
      ...layout,
      cell_condition_id,
      cells: cells.filter(({ cell_id }) => visibleCells.includes(cell_id)),
      fields: fields
        ? fields.filter(({ cell_id }) => visibleCells.includes(cell_id))
        : [],
    };
  }, [ui, visibleCells]);

  const flatSpec: ConditionUIStep[] = useMemo(() => {
    if (!layout || !layout.spec) {
      return [];
    }

    return layout.spec.flatMap((item) =>
      item.items ? item.items : [item as ConditionUIStep]
    );
  }, [layout]);

  const flatLabels: ConditionMetadataLabel[] = useMemo(() => {
    if (!layout || !layout.labels) {
      return [];
    }

    return layout.labels.flatMap((item) =>
      item.items ? item.items : [item as ConditionMetadataLabel]
    );
  }, [layout]);

  /* Sidebar layout */
  const sidebarLayout = useMemo(() => {
    if (!layout || !layout.spec) {
      return { spec: [] };
    }

    const getFlagForFields = (itemIndex: number, childIndex?: number) => {
      const values =
        layout.spec[itemIndex].items && childIndex !== undefined
          ? layout.spec[itemIndex].items![childIndex].values
          : layout.spec[itemIndex].values!;

      return Object.keys(values).some((id) => {
        if (id.includes("engineer_flag")) {
          return values[id];
        }
        return false;
      });
    };

    const getSopDeviationsForFields = (
      itemIndex: number,
      childIndex?: number
    ) => {
      const values =
        layout.spec[itemIndex].items && childIndex !== undefined
          ? layout.spec[itemIndex].items![childIndex].values
          : layout.spec[itemIndex].values!;

      return values["deviations_from_sop"] as string | null | undefined;
    };

    const getCompletedForAllCells = (
      itemIndex: number,
      childIndex?: number
    ) => {
      return !layout.fields.some((field) =>
        field.items[itemIndex].items && childIndex !== undefined
          ? !field.items[itemIndex].items![childIndex].completed?.completed_at
          : !field.items[itemIndex].completed?.completed_at
      );
    };

    let index = 0;
    const spec = layout.spec.map((item, itemIndex) => ({
      title: item.title,
      step: item.items ? -1 : index++,
      flag: item.items ? false : getFlagForFields(itemIndex),
      sopDeviations: item.items ? null : getSopDeviationsForFields(itemIndex),
      complete: item.items ? false : getCompletedForAllCells(itemIndex),
      children: item.items?.map((item, childIndex) => ({
        title: item.title,
        step: index++,
        flag: getFlagForFields(itemIndex, childIndex),
        sopDeviations: getSopDeviationsForFields(itemIndex, childIndex),
        complete: getCompletedForAllCells(itemIndex, childIndex),
      })),
    }));

    return {
      spec: [
        {
          title: "Overview",
          step: -1,
          flag: layout.engineer_flag,
          complete: false,
        },
        ...spec,
      ],
    };
  }, [layout]);

  /* Get condition state values and defaults in react-hook-form format */
  const defaultValues = useMemo(() => {
    const flattenedItems = layout?.fields.map((item) => {
      const items = item.items.flatMap((item) =>
        item.items ? item.items : [item as MetadataStep]
      );
      return items;
    });

    return flattenedItems?.map((metadata, index) => ({
      ...layout?.fields[index],
      items: metadata.map(({ values, api_path, completed, test_meta_id }) => ({
        api_path,
        completed,
        test_meta_id,
        ...values,
      })),
    }));
  }, [layout]);

  /* Get the teardown UI fields */
  const teardownFields = useMemo(
    () =>
      layout?.fields.map(({ items }, cellIndex) => {
        const flatFields = items.flatMap((item) =>
          item.items ? item.items : [item as MetadataStep]
        );

        return (
          flatFields.find((field) => field.fields_by_modes)?.fields_by_modes ||
          {}
        );
      }),
    [layout]
  );
  const samples = useMemo(
    () =>
      flatSpec.find((specObj) =>
        specObj.hasOwnProperty("characterization_samples")
      )?.characterization_samples,
    [flatSpec]
  );
  const teardownTemplateRefreshAvailable = useMemo(
    () =>
      flatSpec.find((specObj) =>
        specObj.hasOwnProperty("teardownTemplateRefreshAvailable")
      )?.teardownTemplateRefreshAvailable,
    [flatSpec]
  );

  /* Form management */
  const formMethods = useForm<MetadataRHFormFormat>({
    mode: "onBlur",
    defaultValues: { metadata: defaultValues },
  });

  const { control, reset, formState, register, getValues } = formMethods;

  const { fields, remove } = useFieldArray({
    control,
    name: "metadata",
  });

  const resetWithMetadata = useCallback(
    (layout: ConditionMetadata | null) => {
      if (!layout) {
        return reset({ metadata: [] });
      }

      const flattenedItems = layout.fields.map((item) => {
        const items = item.items.flatMap((item) =>
          item.items ? item.items : [item as MetadataStep]
        );
        return items;
      });

      const newValues = flattenedItems.map((metadata, index) => ({
        ...layout.fields[index],
        items: metadata.map(
          ({ values, api_path, completed, test_meta_id }) => ({
            api_path,
            completed,
            test_meta_id,
            ...values,
          })
        ),
      }));

      reset({ metadata: newValues });
    },
    [reset]
  );

  useEffect(() => resetWithMetadata(layout), [layout, resetWithMetadata]);

  /* Toggling cells visible */
  const toggleCell = useCallback(
    (cell_id: number, bypassDirty = false) => {
      const { allIds, allCellIds } = parseCellIdString(
        cell_id_string,
        visibleCells
      );

      if (!allCellIds.includes(cell_id)) {
        return;
      }

      const spec = ui?.find((c) =>
        c.cells.some((cell) => cell.cell_id === cell_id)
      );
      if (!spec) {
        return;
      }

      const visibleCombination = allIds.find((combination) =>
        visibleCells.includes(parseInt(combination[1]))
      );
      const visibleConditionId = visibleCombination![0];
      const thisCombination = allIds.find(
        (combination) => parseInt(combination[1]) === cell_id
      );
      const thisConditionId = thisCombination![0];

      // We are keeping the same condition id
      if (thisConditionId === visibleConditionId) {
        // We are removing a condition of the same type
        if (formState.isDirty && !bypassDirty) {
          setTogglingCellId(cell_id);
          return;
        }

        const index = visibleCells.findIndex((c) => c === cell_id);

        // We are adding a new cell within the same condition
        if (index === -1) {
          const newCells = spec.cells
            .filter(
              (sc) =>
                visibleCells.some((c) => sc.cell_id === c) ||
                sc.cell_id === cell_id
            )
            .map(({ cell_id }) => cell_id);
          remove();
          setVisibleCells(newCells);
          setTogglingCellId(null);
          return;
        }

        // We are trying to hide the last cell
        if (visibleCells.length === 1) {
          alert("Cannot remove the last cell.");
          return;
        }

        // We are hiding a cell within the same condition
        const newCells = visibleCells.filter((c) => c !== cell_id);

        remove();
        setVisibleCells(newCells);
        setTogglingCellId(null);
        return;
      }

      // We are changing conditionId
      if (formState.isDirty && !bypassDirty) {
        setTogglingCellId(cell_id);
        return;
      }

      const newCells = allIds
        .filter((combination) => combination[0] === thisConditionId)
        .map((combination) => parseInt(combination[1]));

      remove();
      setVisibleCells(newCells);
      setTogglingCellId(null);
    },
    [cell_id_string, visibleCells, ui, formState, remove]
  );

  const [togglingCellId, setTogglingCellId] = useState<number | null>(null);

  const onSave = useCallback(
    async (callback?: () => void) => {
      const values = getValues();
      const dirtyValues = getDirtyValues(formState.dirtyFields, values);
      const flatMetadata = Object.keys(values.metadata)
        .filter(
          (key) =>
            dirtyValues.metadata && dirtyValues.metadata[key] !== undefined
        )
        .flatMap((key: string) => dirtyValues.metadata[key]);
      const { conditionIdsToRequestedCellIds } =
        parseCellIdString(cell_id_string);

      await dispatch(
        saveMetadata({ metadata: flatMetadata, conditionIdsToRequestedCellIds })
      );

      if (typeof callback === "function") {
        callback();
      }
    },
    [dispatch, getValues, formState, cell_id_string]
  );

  const [copiedTestId, setCopiedTestId] = useState(false);

  const isMd = useMediaQuery("(min-width:1360px)");
  const isLg = useMediaQuery("(min-width:1440px)");
  const isXl = useMediaQuery("(min-width:1560px)");
  const labelWidth = isXl ? 560 : isLg ? 460 : isMd ? 360 : 254;

  if (
    updateTestConditionTemplatesStatus === "loading" ||
    (!layout && !["succeeded", "failed"].includes(getStatus))
  ) {
    return <LoadingLayout light fullscreen error={getError || ""} />;
  }

  /* Overview */
  const allSopDeviations = flatSpec
    .flatMap(({ values }) =>
      Object.keys(values).map((id) =>
        id.includes("deviations_from_sop") && values[id] ? `${values[id]}` : ""
      )
    )
    .filter((dev) => dev !== "");

  let toasts = (
    <>
      <Toast
        severity="error"
        open={completeStatus === "failed" || !!completeError}
        onClose={() => dispatch(resetCompleteMetadata())}
      >
        {completeError}
      </Toast>
      <Toast
        severity="error"
        open={getStatus === "failed" || !!getError}
        onClose={() => dispatch(resetGetMetadata())}
      >
        {getError}
      </Toast>
      <Toast
        severity="success"
        open={copiedTestId}
        onClose={() => setCopiedTestId(false)}
      >
        Test file name copied to clipboard.
      </Toast>
      <Toast
        severity="success"
        open={updateTestConditionTemplatesStatus === "succeeded"}
        onClose={() => dispatch(resetUpdateTestConditionTemplates())}
      >
        Template updates successfully applied.
      </Toast>
      <Toast
        severity="error"
        open={templateRefreshError}
        onClose={() => setTemplateRefreshError(false)}
      >
        Error applying template updates.
      </Toast>
    </>
  );

  let saveErrorBanner = !!saveError ? (
    <Box
      position="fixed"
      style={{ top: 74, left: 254, width: "calc(100vw - 302px)" }}
      m={4}
      zIndex={10}
    >
      <Banner severity="error" onClose={() => dispatch(resetSaveMetadata())}>
        {saveError}
      </Banner>
    </Box>
  ) : null;

  const hasPendingChanges = flatLabels
    .map(
      (label, stepId) =>
        `${flatSpec[stepId].entity_type}_${
          isNaN(flatSpec[stepId].condition_component_id!)
            ? flatSpec[stepId].test_condition_id
            : flatSpec[stepId].condition_component_id
        }`
    )
    .some((key) => Object.keys(pendingChanges[key] || {}).length > 0);

  return (
    <FormProvider {...formMethods}>
      <Wrapper
        rootRef={rootRef}
        step={step}
        onSave={onSave}
        cellLayout={cellLayout}
        onToggleCell={toggleCell}
        sidebarLayout={sidebarLayout}
        hasPendingChanges={hasPendingChanges}
        eventData={eventData}
        activeCellIds={visibleCells}
      >
        {rootRef.current && layout ? (
          <Box
            pb={6}
            position="relative"
            style={{
              background: colors.body.light,
              minWidth: `calc(2.5rem + ${
                labelWidth + 172 + 236 * fields.length
              }px)`,
            }}
          >
            <form>
              <InView key={-1} onChange={(inView) => handleInView(inView, -1)}>
                {({ ref }) => (
                  <Section
                    ref={ref}
                    step={-1}
                    activeStep={step}
                    title="Overview"
                    flag={layout.engineer_flag}
                    executor={layout.preferred_executor}
                  >
                    {allSopDeviations!.length > 0 ? (
                      <InfoBlock
                        items={[
                          {
                            title: "All Deviations from SOP",
                            contents: [
                              <div>{allSopDeviations.join(", ")}</div>,
                            ],
                            warning: true,
                          },
                        ]}
                      />
                    ) : null}
                    {conditionIdWithNewTemplateAvailable && (
                      <>
                        <Box my={4}>
                          <Typography sx={{ fontStyle: "italic" }}>
                            Note: There are updated test condition templates
                            available for this assembly type. Click below to
                            apply the updates.
                          </Typography>
                        </Box>
                        <Box
                          my={4}
                          pb={4}
                          style={{
                            borderBottom: `1px solid ${colors.rules}`,
                          }}
                        >
                          <Button
                            className="qa-apply-template-updates-btn"
                            color="primary"
                            size="small"
                            onClick={handleRefreshComponentTemplateClick}
                          >
                            Apply Condition Template Updates
                          </Button>
                        </Box>
                      </>
                    )}
                    <InfoBlock
                      items={[
                        { title: "Cell Type", contents: [layout.cell_type] },
                        {
                          title: "Cell Assembly",
                          contents: [layout.cell_assembly],
                        },
                        {
                          title: "Group Number",
                          contents: [layout.group_number],
                        },
                      ]}
                    />
                    <InfoBlock
                      items={[
                        {
                          title: "Description",
                          contents: [layout.description],
                        },
                        {
                          title: "Condition",
                          contents: [
                            <Button
                              color="tertiary"
                              style={{
                                padding: 0,
                              }}
                              onClick={() => {
                                navigate(
                                  `/experiments/${layout.exp_id}/conditions/${layout.cell_condition_id}`,
                                  {
                                    state: {
                                      from: pathname,
                                    },
                                  }
                                );
                              }}
                            >
                              {layout.name}
                            </Button>,
                          ],
                        },

                        {
                          title: "Experiment",
                          contents: [
                            <Button
                              color="tertiary"
                              style={{
                                padding: 0,
                              }}
                              onClick={() => {
                                navigate(`/experiments/${layout.exp_id}`, {
                                  state: {
                                    from: pathname,
                                  },
                                });
                              }}
                            >
                              {`EXP${layout.exp_id}`}
                            </Button>,
                          ],
                        },
                      ]}
                    />
                    <InfoBlock
                      items={[
                        {
                          title: "Build Phase",
                          contents: [layout.build_phase || "-"],
                        },
                        {
                          title: "Build Config",
                          contents: [layout.build_config || "-"],
                        },
                      ]}
                    />
                  </Section>
                )}
              </InView>
              {flatLabels.map(({ title, fields: labelFields }, stepId) => (
                <ConnectedSection
                  key={stepId}
                  title={title}
                  step={stepId}
                  activeStep={step}
                  onRefreshTemplateClick={handleRefreshTeardownTemplateClick}
                  onSave={onSave}
                  onInView={handleInView}
                  onCopy={() => {
                    if (title === "On-Test") {
                      setCopiedTestId(true);
                    }
                  }}
                  eventMap={eventMap}
                  onViewEvents={(cell_id: number) => setViewCellEvents(cell_id)}
                  specFields={labelFields.filter(
                    ({ meta_field }) => !meta_field
                  )}
                  specValues={flatSpec[stepId] ? flatSpec[stepId].values : {}}
                  changeRequestKey={`${flatSpec[stepId].entity_type}_${
                    isNaN(flatSpec[stepId].condition_component_id!)
                      ? flatSpec[stepId].test_condition_id
                      : flatSpec[stepId].condition_component_id
                  }`}
                  teardownFields={
                    title === "Teardown" ? teardownFields : undefined
                  }
                  teardownTemplateRefreshAvailable={
                    title === "Teardown" && teardownTemplateRefreshAvailable
                  }
                  samples={title === "Teardown" ? samples : undefined}
                  uiFields={labelFields.filter(
                    ({ meta_field }) => !!meta_field
                  )}
                  formFields={fields}
                />
              ))}
              {fields.map((field, fieldIndex) => (
                <Fragment key={`hidden-${field.id}`}>
                  <input
                    {...register(`metadata.${fieldIndex}.cell_id`)}
                    type="hidden"
                    defaultValue={field.cell_id}
                  />
                  <input
                    {...register(`metadata.${fieldIndex}.reserved_channel`)}
                    type="hidden"
                    defaultValue={field.reserved_channel}
                  />
                </Fragment>
              ))}
              <input
                {...register("cell_condition_id")}
                type="hidden"
                defaultValue={layout.cell_condition_id}
              />
              <div style={{ height: "40vh" }} />
            </form>
          </Box>
        ) : null}
        {saveErrorBanner}
        {toasts}
        <Modal
          open={togglingCellId !== null && togglingCellId !== -1}
          onClose={() => setTogglingCellId(null)}
        >
          <>
            <Typography variant="h2">Save metadata ?</Typography>
            <Box mt={8} mb={2}>
              <Typography color="textSecondary">
                You have unsaved metadata for the cells currently visible. To
                preserve your metadata, please save before toggling visible
                cells or navigating to a different test condition.
              </Typography>
            </Box>
            <Box mt={8} display="flex" justifyContent="flex-end">
              <Box mr={4}>
                <Button
                  color="secondary"
                  onClick={() => toggleCell(togglingCellId!, true)}
                >
                  Don't save
                </Button>
              </Box>
              <Button
                color="primary"
                onClick={() => onSave(() => toggleCell(togglingCellId!, true))}
                endIcon={
                  saveStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                disabled={saveStatus === "loading"}
              >
                Save
              </Button>
            </Box>
          </>
        </Modal>

        <Modal
          open={viewCellEvents !== null}
          onClose={() => setViewCellEvents(null)}
        >
          {viewCellEvents && eventMap[viewCellEvents]}
        </Modal>
      </Wrapper>
    </FormProvider>
  );
};

export default MetadataLayout;
