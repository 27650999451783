import { useNavigate } from "react-router";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../../components/Button";
import CaretBottomIcon from "../../icons/CaretBottom";
import DotIcon from "../../icons/Dot";
import {
  cellIdToString,
  expIdToString,
  getInitialsForUser,
  isoDateToHumanizedString,
} from "../../utils/labels";

const ListComponent = ({
  cell_condition_id,
  name,
  exp_id,
  cells,
  change_requests,
}: ConditionWithChangeRequests) => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(min-width:760px)");

  const pendingCount = change_requests.filter(
    ({ status }) => status === "P"
  ).length;
  const totalCount = change_requests.filter(
    ({ status }) => status !== "P"
  ).length;

  const completed = pendingCount === 0;

  const orderedReqs = orderBy(change_requests, ["requested_at"], ["desc"]);
  const { requested_by, requested_at } = orderedReqs[0];

  const extraRequesters =
    uniq(orderedReqs.map(({ requested_by }) => requested_by.user_id)).length -
    1;

  return (
    <Box my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h3">Specification Change Request</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                mt={isTablet ? 0 : 4}
                display="flex"
                justifyContent={isTablet ? "flex-end" : "flex-start"}
                alignItems="center"
              >
                <Avatar>{getInitialsForUser(requested_by)}</Avatar>
                <Box mx={2}>
                  {requested_by.name}
                  {extraRequesters > 0 ? ` +${extraRequesters}` : ""}
                </Box>
                <Typography color="textSecondary" className="small">
                  {isoDateToHumanizedString(requested_at)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Box display="flex" mt={6}>
                <Box className="small">
                  <Typography color="textSecondary" fontWeight={500} paragraph>
                    Condition
                  </Typography>
                  <Typography color="textSecondary" fontWeight={500} paragraph>
                    EXP ID
                  </Typography>
                  <Typography color="textSecondary" fontWeight={500}>
                    Cell ID(s)
                  </Typography>
                </Box>
                <Box ml={6}>
                  <Typography paragraph>{name}</Typography>
                  <Typography paragraph>{expIdToString(exp_id)}</Typography>
                  <Typography>
                    {cells
                      .map(({ cell_id }) => cellIdToString(cell_id))
                      .join(", ")}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={5} mt="auto">
              <Box
                display="flex"
                justifyContent={isTablet ? "flex-end" : "flex-start"}
                alignItems="flex-end"
                mt={isTablet ? 0 : 6}
              >
                <Button
                  color="tertiary"
                  className="small"
                  onClick={() =>
                    navigate(`/change-requests/${cell_condition_id}`)
                  }
                  style={{ fontWeight: 600, padding: "0 0 0 0.25rem" }}
                  startIcon={
                    !completed && <DotIcon style={{ width: 8, height: 8 }} />
                  }
                  endIcon={
                    <CaretBottomIcon style={{ transform: "rotate(270deg)" }} />
                  }
                >
                  View (
                  {completed
                    ? totalCount
                    : `${pendingCount}/${change_requests.length}`}
                  ) {!completed ? "pending" : ""} changes
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ListComponent;
