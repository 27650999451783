import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import authReducer from "./auth/slice";
import batchCellExternalMetadataReducer from "../features/batch-external-data-entry/batchCellExternalMetadataEntrySlice";
import batchConditionsSpecificationsReducer from "../features/batch-conditions/batchConditionsSpecificationsSlice";
import featureFlagsReducer from "./featureFlags/slice";
import experimentListReducer from "../features/experiments/slice";
import experimentSingleReducer from "../features/experiments/singleSlice";
import experimentFiltersReducer from "../features/experiments/filtersSlice";
import { cellsSlices } from "../features/cells/slice";
import cellSingleReducer from "../features/cells/singleSlice";
import modifyTeardownSpecsReducer from "../features/cells/modifyTeardownSpecsSlice";
import channelSingleReducer from "../features/channels/singleSlice";
import characterizationReducer from "../features/characterization/slice";
import cellFiltersReducer from "../features/cells/filtersSlice";
import conditionUIReducer from "../features/conditions/uiSlice";
import conditionReducer from "../features/conditions/slice";
import metadataReducer from "../features/metadata/slice";
import moduleSingleReducer from "../features/module-metadata/moduleMetadataSlice";
import changeRequestsReducer from "../features/change-requests/slice";
import channelListReducer from "../features/channels/slice";
import channelFiltersReducer from "../features/channels/filtersSlice";
import projectListReducer from "../features/experiments/projectSlice";
import notesReducer from "../features/notes/slice";
import eventsReducer from "../components/forms/slice";
import modulesReducer from "../features/modules/slice";
import customViewReducer from "../features/custom-views/slice";
import signalMapReducer from "../features/signal-maps/slice";
import cyclingProtocolsReducer from "../features/cycling-protocols/slice";
import testStandListReducer from "../features/test-stands/testStandsSlice";
import testStandSingleReducer from "../features/test-stands/testStandSingleSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// The overall Redux state of the application. Specific state applicable
// to one page may be pulled out with useSelector.
const combinedReducer = combineReducers({
  auth: authReducer,
  experimentList: experimentListReducer,
  experimentSingle: experimentSingleReducer,
  experimentFilters: experimentFiltersReducer,
  featureFlags: featureFlagsReducer,
  conditionUI: conditionUIReducer,
  condition: conditionReducer,
  metadata: metadataReducer,
  batchCellExternalMetadata: batchCellExternalMetadataReducer,
  batchConditionsSpecification: batchConditionsSpecificationsReducer,
  cellList_EXPERIMENT: cellsSlices.EXPERIMENT.slice.reducer,
  cellList_ALL: cellsSlices.ALL.slice.reducer,
  cellList_SPECIFIED: cellsSlices.SPECIFIED.slice.reducer,
  cellList_STAGED: cellsSlices.STAGED.slice.reducer,
  cellList_COMMITTED: cellsSlices.COMMITTED.slice.reducer,
  cellList_TESTING: cellsSlices.TESTING.slice.reducer,
  cellSingle: cellSingleReducer,
  cellFilters: cellFiltersReducer,
  changeRequests: changeRequestsReducer,
  channelList: channelListReducer,
  channelFilters: channelFiltersReducer,
  channelSingle: channelSingleReducer,
  characterization: characterizationReducer,
  cyclingProtocols: cyclingProtocolsReducer,
  projectList: projectListReducer,
  notes: notesReducer,
  events: eventsReducer,
  modules: modulesReducer,
  moduleSingle: moduleSingleReducer,
  teardownSpecs: modifyTeardownSpecsReducer,
  customViews: customViewReducer,
  signalMapSingle: signalMapReducer,
  testStandList: testStandListReducer,
  testStandSingle: testStandSingleReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "AUTH/logOut") {
    state = undefined;
  }
  if (action.type === "AUTH/impersonate") {
    state = {
      auth: state.auth,
      metadata: state.metadata,
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
