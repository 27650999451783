import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingLayout from "../../layout/Loading";
import ArrowBackIcon from "../../icons/ArrowBack";
import type { RootState } from "../../store";
import {
  cellIdToString,
  channelInfraStatusToString,
  expIdToString,
} from "../../utils/labels";
import MuiLink from "@mui/material/Link";
import {
  TestStandSingleState,
  cancelTestStandReservation,
  getSingleTestStand,
  resetCancelReservation,
  resetSingleTestStand,
} from "./testStandSingleSlice";
import colors from "../../theme/colors";
import { List, ListItem } from "@mui/material";
import Times from "../../icons/Times";
import Toast from "../../components/Toast";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import EventSummary from "../../components/EventSummary";

const SingleTestStandLayout = () => {
  const { test_stand_id = "" } = useParams();
  const isTablet = useMediaQuery("(min-width:760px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    testStand,
    status: { testStandReservationCancellation: cancelReservationStatus },
    error: {
      get: testStandError,
      testStandReservationCancellation: cancelReservationError,
    },
  } = useSelector<RootState, TestStandSingleState>(
    ({ testStandSingle }) => testStandSingle
  );
  const { from } = (location.state as {
    from?: string;
  }) || { from: "" };

  useEffect(() => {
    dispatch(resetSingleTestStand());
    dispatch(getSingleTestStand(parseInt(test_stand_id)));
  }, [test_stand_id, dispatch]);

  useEffect(() => {
    if (cancelReservationStatus === "succeeded") {
      dispatch(getSingleTestStand(parseInt(test_stand_id)));
    }
  }, [test_stand_id, dispatch, cancelReservationStatus]);

  if (!testStand) {
    return (
      <Box p={32} style={{ background: "white" }}>
        <LoadingLayout light error={testStandError || ""} />
      </Box>
    );
  }

  let cancelReservationErrorToast;
  if (cancelReservationStatus === "failed" || !!cancelReservationError) {
    cancelReservationErrorToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetCancelReservation())}
      >
        {cancelReservationError}
      </Toast>
    );
  }

  let cancelReservationSuccessToast;
  if (cancelReservationStatus === "succeeded") {
    cancelReservationSuccessToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetCancelReservation())}
      >
        Test stand reservation cancelled.
      </Toast>
    );
  }

  return (
    <>
      <Paper variant="outlined" square>
        <Box
          px={isTablet ? 6 : 0}
          py={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton
              size="large"
              onClick={() => navigate(from || "/infrastructure/test-stands")}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h2">
              Test stand:{" "}
              <span style={{ fontWeight: 400 }}>{testStand.name}</span>
            </Typography>
          </Box>
          <Box>
            <ButtonLogEvent itemType={"testStand"} objects={[testStand]} />
          </Box>
        </Box>
      </Paper>

      <Grid container>
        <Grid item xs={12} md={6}>
          <TestStandInfo testStand={testStand} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TestStandReservationInfo
            reservations={testStand.active_reservations}
            testStandId={test_stand_id}
          />
        </Grid>
        <Grid item xs={12}>
          <ChannelsInfo
            channels={testStand.channels}
            testStandId={test_stand_id}
          />
        </Grid>
        <Grid item xs={12}>
          <EventSummary
            resourceType={"test_stand"}
            resource_id={testStand.test_stand_id}
          />
        </Grid>
      </Grid>
      {cancelReservationErrorToast}
      {cancelReservationSuccessToast}
    </>
  );
};

type TestStandInfoProps = {
  testStand: TestStand;
};

const TestStandInfo = ({ testStand }: TestStandInfoProps) => {
  const isTablet = useMediaQuery("(min-width:760px)");

  return (
    <Box px={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Test Stand Info</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Box ml={isTablet ? 5 : 0} width="100%">
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Identifier
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {testStand.identifier || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {testStand.channels.find(
                          (channel_) => channel_.channel.location
                        )?.channel.location || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Incubator
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {testStand.incubator.name || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Postion ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {testStand.position.name || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Box ml={isTablet ? 5 : 0} width="100%">
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography color="textSecondary" paragraph>
                        Temp. range
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography paragraph>
                        {testStand.temperature_range || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography color="textSecondary" paragraph>
                        GDE air range
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography paragraph>
                        {testStand.gde_air_range || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography color="textSecondary" paragraph>
                        Sweep air range
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography paragraph>
                        {testStand.sweep_air_range || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography color="textSecondary" paragraph>
                        Max current limit
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography paragraph>
                        {testStand.max_current_limit
                          ? `${testStand.max_current_limit} A`
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography color="textSecondary" paragraph>
                        DAQ Channels
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography paragraph>
                        {testStand.daq_channels || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

const TestStandReservationInfo = ({
  reservations,
  testStandId,
}: {
  reservations: TestStand["active_reservations"];
  testStandId: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const from = `/infrastructure/test-stands/${testStandId}`;

  const renderReservationQueueItem = (
    reservation: TestStandReservation,
    index: number
  ) => {
    const listNum = `${index + 1}.`;
    const cellString = cellIdToString(`${reservation.cell_id}`);
    return (
      <ListItem
        key={reservation.test_stand_reservation_id}
        sx={{
          display: "flex",
          border: `1px ${colors.rules} solid`,
          width: "50%",
          marginTop: 2,
          marginLeft: 8,
        }}
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={async () => {
              if (
                window.confirm(
                  `Cancel test stand reservation for ${cellString}?`
                )
              ) {
                dispatch(cancelTestStandReservation(reservation.cell_id));
              }
            }}
          >
            <Times />
          </IconButton>
        }
      >
        {listNum}
        <MuiLink
          color="secondary"
          style={{ cursor: "pointer", marginLeft: 4, marginTop: 1 }}
          onClick={() =>
            navigate(`/cells/${reservation.cell_id}`, {
              state: {
                from,
              },
            })
          }
        >
          {cellString}
        </MuiLink>
      </ListItem>
    );
  };
  return (
    <Box px={6} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Reservations</Typography>
            </Grid>
            <List></List>
            {reservations.length > 0 ? (
              reservations.map(renderReservationQueueItem)
            ) : (
              <Box
                style={{
                  display: "flex",
                  margin: "16px 24px",
                  padding: 8,
                }}
              >
                No active reservations.
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

type ChannelsInfoProps = {
  channels: TestStand["channels"];
  testStandId: string;
};

const ChannelsInfo = ({ channels, testStandId }: ChannelsInfoProps) => {
  const navigate = useNavigate();
  const from = `/infrastructure/test-stands/${testStandId}`;

  const renderChannelSection = ({
    channel: {
      infra_status: infraStatus,
      fullname,
      tester: { tester_name },
      channel_id,
    },
    cell,
    experiment: { exp_id, project, owner },
  }: TestStandChannel) => {
    return (
      <Box
        key={channel_id}
        style={{
          display: "flex",
          margin: "16px 24px",
          border: `1px ${colors.rules} solid`,
          padding: 8,
          flexGrow: 1,
        }}
      >
        <Grid item xs={12} md={6} key={fullname}>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Channel name
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                <Typography paragraph>
                  <MuiLink
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/infrastructure/channels/${channel_id}`)
                    }
                  >
                    {fullname}
                  </MuiLink>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Infrastructure Status
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                <Typography paragraph>
                  {channelInfraStatusToString(
                    infraStatus as ChannelInfraStatus
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Tester
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography paragraph>{tester_name || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                {`On-Test ${cell.module.name ? "Module Name" : "Cell ID"}`}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                {cell.module.name ? (
                  <Typography paragraph>{cell.module.name}</Typography>
                ) : cell.cell_id ? (
                  <MuiLink
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/cells/${cell.cell_id}`, {
                        state: {
                          from,
                        },
                      })
                    }
                  >
                    {cellIdToString(cell.cell_id)}
                  </MuiLink>
                ) : (
                  "-"
                )}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Experiment ID
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                {exp_id ? (
                  <MuiLink
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/experiments/${exp_id}`, {
                        state: {
                          from,
                        },
                      })
                    }
                  >
                    {expIdToString(exp_id)}
                  </MuiLink>
                ) : (
                  "-"
                )}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Project
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                <Typography paragraph>{project || "-"}</Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography color="textSecondary" paragraph>
                Owner
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center">
                <Typography paragraph>{owner.name || "-"}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box px={6} my={4}>
      <Paper variant="outlined" square>
        <Box p={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Channel Info</Typography>
            </Grid>
            {channels.length > 0 && channels.map(renderChannelSection)}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SingleTestStandLayout;
