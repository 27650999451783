import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import type { PaperProps } from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import SettingsIcon from "../../icons/Settings";
import SettingsActiveIcon from "../../icons/SettingsActive";
import colors from "../../theme/colors";
import { TestStandOptionFilters } from "./TestStandDropdown";
import ChannelAttributeFilterButton from "../channels/ChannelAttributeFilterButton";
import { dropdownFilterStyles } from "../channels/ChannelDropdownFilters";

const H2_SENSOR_OPTIONS = ["Draeger", "RC"];

type TestStandDropdownFiltersProps = {
  value: TestStandOptionFilters | null;
  onChange: (value: TestStandOptionFilters | null) => void;
} & Omit<PaperProps, "onChange">;

const TestStandDropdownFilters = ({
  children,
  value,
  onChange,
  style,
  ...props
}: TestStandDropdownFiltersProps) => {
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState(value);

  useEffect(() => {
    if (filtering) {
      setFilters(value);
    }
  }, [filtering, value]);

  if (!filtering) {
    return (
      <Paper {...props} style={{ ...style, minWidth: 400 }}>
        {children}
        <Divider />
        <Box
          px={4}
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: 4, marginBottom: 4 }}
        >
          <IconButton
            size="small"
            style={{
              color: colors.text.secondary,
              visibility: !!filters?.cell_ids?.length ? "hidden" : "visible",
            }}
            onClick={() => setFiltering(true)}
          >
            {value ? (
              <SettingsActiveIcon style={{ width: 18, height: 18 }} />
            ) : (
              <SettingsIcon style={{ width: 18, height: 18 }} />
            )}
          </IconButton>
        </Box>
      </Paper>
    );
  }

  const renderButtonGroup = (filterFieldKey: keyof TestStandOptionFilters) => {
    return (
      <Grid item xs={5}>
        <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
          <ChannelAttributeFilterButton
            value={filters?.[filterFieldKey] as "0" | "1"}
            onChange={(value) => {
              const { [filterFieldKey]: _filterFieldToModify, ...others } =
                filters || {};
              if (!value) {
                setFilters(others);
                return;
              }

              setFilters({
                ...others,
                [filterFieldKey]: value,
              });
            }}
          />
        </Box>
      </Grid>
    );
  };

  const renderDropdown = (
    filterFieldKey: keyof TestStandOptionFilters,
    dropdownOptions: string[]
  ) => {
    return (
      <Grid item xs={5}>
        <Box pl={4} pr={2} py={1} display="flex" justifyContent="flex-end">
          <Select
            className="tiny"
            size="small"
            variant="filled"
            disableUnderline
            style={dropdownFilterStyles.dropdown}
            value={filters?.[filterFieldKey] || "Any"}
            onChange={(e) => {
              const { [filterFieldKey]: _filterFieldToModify, ...others } =
                filters || {};

              if (e.target.value === "Any") {
                setFilters(others);
                return;
              }

              setFilters({
                ...others,
                [filterFieldKey]: e.target.value as "0" | "1",
              });
            }}
          >
            <MenuItem
              className="small"
              value="Any"
              disabled={!filters?.[filterFieldKey]}
            >
              Any
            </MenuItem>
            {dropdownOptions.map((option) => (
              <MenuItem
                key={`${option}-item`}
                className="small"
                value={option}
                disabled={filters?.[filterFieldKey] === option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
    );
  };

  return (
    <Paper {...props} style={{ ...style, minWidth: 500 }}>
      <Box py={2} px={4}>
        <Typography className="small" fontWeight={700}>
          Test stand attributes to match with cell
        </Typography>
      </Box>
      <Divider />
      <Box py={1}>
        <Grid
          container
          className="small"
          style={{ maxHeight: 250, overflowY: "auto" }}
        >
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Anode current sensors
            </Box>
          </Grid>
          {renderButtonGroup("anode_current_sensors")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              H2 sensor type
            </Box>
          </Grid>
          {renderDropdown("h2_sensor_type", H2_SENSOR_OPTIONS)}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Dual OEE
            </Box>
          </Grid>
          {renderButtonGroup("dual_oee")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              1 Half Cell
            </Box>
          </Grid>
          {renderButtonGroup("one_half_cell")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              2 Half Cells
            </Box>
          </Grid>
          {renderButtonGroup("two_half_cells")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Proto 1
            </Box>
          </Grid>
          {renderButtonGroup("proto_1")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Proto 2
            </Box>
          </Grid>
          {renderButtonGroup("proto_2")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              EVT
            </Box>
          </Grid>
          {renderButtonGroup("evt")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              Megasus
            </Box>
          </Grid>
          {renderButtonGroup("megasus")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              FlexCell
            </Box>
          </Grid>
          {renderButtonGroup("flex_cell")}
          <Grid item xs={7}>
            <Box pl={4} pr={2} py={1}>
              303
            </Box>
          </Grid>
          {renderButtonGroup("vehicle_303")}
        </Grid>
      </Box>
      <Divider />
      <Box py={2} px={4} display="flex" justifyContent="space-between">
        <Button
          disabled={!filters}
          color="tertiary"
          size="small"
          onClick={() => onChange(null)}
        >
          <b>Reset</b>
        </Button>
        <Box ml="auto" mr={2}>
          <Button
            color="secondary"
            size="small"
            onClick={() => setFiltering(false)}
          >
            Cancel
          </Button>
        </Box>
        <Button color="primary" size="small" onClick={() => onChange(filters)}>
          Apply
        </Button>
      </Box>
    </Paper>
  );
};

export default TestStandDropdownFilters;
