import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Download = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style, fill: "transparent" }}>
    {children}
    <g transform="matrix(1.7142857142857142,0,0,1.7142857142857142,0,0)">
      <g>
        <path
          d="M.5,10.5v1a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2v-1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <polyline
          points="4 6 7 9.5 10 6"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></polyline>
        <line
          x1="7"
          y1="9.5"
          x2="7"
          y2="0.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
      </g>
    </g>
  </SvgIcon>
);

export default Download;
