import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const EyeSlash = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 17 16"
  >
    {children}
    <path
      d="M1.85547 14L14.3555 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.97607 12.7006C6.63128 12.9041 7.31401 13.005 8.00007 12.9999C10.7334 13.0459 13.5054 11.1246 15.2161 9.24192C15.596 8.82019 15.596 8.17966 15.2161 7.75792C14.5963 7.07806 13.9098 6.46229 13.1667 5.91992"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.60855 4.18878C9.08252 4.05788 8.54192 3.9947 7.99989 4.00078C5.31255 3.95611 2.53322 5.83545 0.785885 7.75745C0.406007 8.17918 0.406007 8.81971 0.785885 9.24145C1.31717 9.82305 1.89704 10.3583 2.51922 10.8414"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 8.5C5.49982 7.8369 5.76316 7.20092 6.23204 6.73204C6.70092 6.26316 7.3369 5.99982 8 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 8.5V8.5C10.5002 9.16316 10.2369 9.79921 9.768 10.2682C9.29914 10.7372 8.66316 11.0007 8 11.0007"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default EyeSlash;
