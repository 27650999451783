import React from "react";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import colors from "../../theme/colors";
import {
  getCombinedLabel,
  cellIdToString,
  cellStatusToString,
  isoDateToDateString,
  isoDateToDateTimeString,
  testTypeToString,
  findLabelForFlag,
} from "../../utils/labels";
import ExperimentLink from "../../components/ExperimentLink";
import FlagTableCell from "../../components/table/FlagTableCell";
import CheckIcon from "../../icons/Check";
import SyncWarningIcon from "../../icons/SyncWarning";
import TimesIcon from "../../icons/Times";
import PriorityChip from "../../components/PriorityChip";
import { isBoolean, uniq } from "lodash";

type Props = {
  dataKey: FalconKey<Cell>;
  cell: Cell;
  isConditionExpanded: boolean;
  subConditionCells: Cell[];
};

export const REF_FIELD_KEY_TO_COMPONENT_KEY = {
  ref_anode__reference_electrode_type: "ref_anode",
  ref_gde__reference_electrode_type: "ref_gde",
  ref_oee__reference_electrode_type: "ref_oee",
};

const CellTableCell = ({
  dataKey,
  cell,
  isConditionExpanded,
  subConditionCells,
}: Props) => {
  switch (dataKey) {
    case "cell_id":
      return (
        <TableCell scope="row" padding="none" className="qa-cell-id">
          {cellIdToString(cell.cell_id)}
          {!isConditionExpanded ? ` + ${subConditionCells.length - 1}` : ""}
        </TableCell>
      );

    case "experiment__exp_id":
      return (
        <TableCell padding="none">
          <ExperimentLink
            exp_id={cell.experiment.exp_id}
            link={cell.experiment.link}
            description={cell.experiment.description}
          />
        </TableCell>
      );

    case "condition__name":
      return <TableCell>{cell.condition.name}</TableCell>;

    case "condition__description":
      return <TableCell>{cell.condition.description}</TableCell>;

    case "condition__group_number":
      return <TableCell>{cell.condition.group_number}</TableCell>;

    case "tester__name":
      return <TableCell>{cell.tester.name}</TableCell>;

    case "incubator__name":
      return <TableCell>{cell.incubator.name}</TableCell>;

    case "on_test__temperature_setpoint":
      return <TableCell>{cell.on_test.temperature_setpoint}</TableCell>;

    case "on_test__air_flow_rate_setpoint":
      return <TableCell>{cell.on_test.air_flow_rate_setpoint}</TableCell>;

    case "on_test__h2_sweep_gas_flow_rate":
      return <TableCell>{cell.on_test.h2_sweep_gas_flow_rate}</TableCell>;

    case "on_test__adapter_cable_type":
      return <TableCell>{cell.on_test.adapter_cable_type}</TableCell>;

    case "test_meta__h2_sweep_gas_flow_rate":
      return <TableCell>{cell.test_meta__h2_sweep_gas_flow_rate}</TableCell>;

    case "test_meta__adapter_cable_type":
      return <TableCell>{cell.test_meta__adapter_cable_type}</TableCell>;

    case "on_test__eis_testing_beginning_of_life":
      return (
        <TableCell>
          {cell.on_test.eis_testing_beginning_of_life ? "Yes" : "No"}
        </TableCell>
      );

    case "off_test__eis_testing_end_of_life":
      return (
        <TableCell>
          {cell.off_test.eis_testing_end_of_life ? "Yes" : "No"}
        </TableCell>
      );

    case "owner__name":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(subConditionCells.map((cell) => cell.owner.name))
            : cell.owner.name}
        </TableCell>
      );

    case "experiment__project":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.experiment.project.name)
              )
            : cell.experiment.project.name}
        </TableCell>
      );

    case "status":
      const determineToolTipText = () => {
        if (cell.test.status !== "O") return null;
        if (!isConditionExpanded) {
          return getCombinedLabel(
            subConditionCells.map((cell) =>
              testTypeToString(cell.test_meta__eis_test_type)
            )
          );
        }
        return testTypeToString(cell.test_meta__eis_test_type);
      };
      const toolTipText = determineToolTipText();
      const cellTextToRender = !isConditionExpanded
        ? getCombinedLabel(
            subConditionCells.map((cell) => cellStatusToString(cell.status))
          )
        : cellStatusToString(cell.status);
      const renderTooltip = () => {
        return (
          <Tooltip arrow title={toolTipText!}>
            <span>{cellTextToRender}</span>
          </Tooltip>
        );
      };
      return (
        <TableCell>
          {toolTipText ? renderTooltip() : cellTextToRender}
        </TableCell>
      );

    case "gde__gde_ids":
    case "hca__hca_ids":
    case "oee__oee_ids":
      const keyToComponentIds = {
        gde__gde_ids: cell.gde.gde_ids,
        hca__hca_ids: cell.hca.hca_ids,
        oee__oee_ids: cell.oee.oee_ids,
      };
      const component_ids = keyToComponentIds[dataKey];
      const determineIDToolTipText = () => {
        if (!component_ids || component_ids.length < 2) return null;
        return component_ids.slice(1).join(", ");
      };
      const tooltipText = determineIDToolTipText();
      const groupedIDText =
        !isConditionExpanded &&
        getCombinedLabel(
          subConditionCells.flatMap((cell) => component_ids || [])
        );
      return (
        <TableCell>
          {groupedIDText ? (
            <Tooltip
              arrow
              title={subConditionCells
                .flatMap((cell) => component_ids || [])
                .filter((component_id) => !groupedIDText.includes(component_id))
                .join(", ")}
            >
              <span>{groupedIDText}</span>
            </Tooltip>
          ) : tooltipText ? (
            <Tooltip arrow title={tooltipText!}>
              <span>{getCombinedLabel(component_ids!)}</span>
            </Tooltip>
          ) : (
            <span>{component_ids ? getCombinedLabel(component_ids) : "-"}</span>
          )}
        </TableCell>
      );

    case "priority":
      return (
        <TableCell padding="none">
          <Box px={4}>
            <PriorityChip label={cell.priority} />
          </Box>
        </TableCell>
      );

    case "channel__fullname":
      return (
        <TableCell padding="none">
          <Box px={4}>
            {!isConditionExpanded
              ? getCombinedLabel(
                  subConditionCells.map((cell) => cell.channel.fullname)
                )
              : cell.channel.fullname || "-"}
          </Box>
        </TableCell>
      );

    case "position__shelf":
      return (
        <TableCell scope="row" padding="none">
          {cell.position.shelf || "-"}
        </TableCell>
      );

    case "reserved_test_stand__name":
      return (
        <TableCell scope="row" padding="none">
          {cell.reserved_test_stand.name || "-"}
        </TableCell>
      );

    case "reserved_channel__fullname":
      const checkIcon =
        cell.reserved_channel.channel.status === "O" &&
        cell.reserved_channel.channel.infra_status === "O";

      const takeOffIcon =
        cell.reserved_channel.channel.status === "I" &&
        !!cell.reserved_channel.ready_off__completed_at &&
        cell.reserved_channel.channel.infra_status === "O";

      return (
        <TableCell padding="none">
          <Box px={4}>
            {!isConditionExpanded ? (
              getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.reserved_channel.channel.fullname
                )
              )
            ) : cell.reserved_channel.fullname ? (
              <Box display="flex" alignItems="center">
                {checkIcon ? (
                  <CheckIcon
                    style={{
                      width: 16,
                      height: 16,
                      color: colors.accent.darkGreen,
                    }}
                  />
                ) : takeOffIcon ? (
                  <SyncWarningIcon
                    style={{
                      width: 16,
                      height: 16,
                      color: colors.accent.violet,
                    }}
                  />
                ) : (
                  <TimesIcon
                    style={{
                      width: 16,
                      height: 16,
                      color: colors.accent.red,
                    }}
                  />
                )}
                <Box ml={2}>{cell.reserved_channel.fullname}</Box>
              </Box>
            ) : (
              "-"
            )}
          </Box>
        </TableCell>
      );

    case "condition__cell_assembly":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.condition.cell_assembly)
              )
            : cell.condition.cell_assembly}
        </TableCell>
      );

    case "thermocouples_attached":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.full.thermocouples_attached || "-"
                )
              )
            : cell.full.thermocouples_attached || "-"}
        </TableCell>
      );

    case "thermocouples_specified":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.full.thermocouples_specified || "-"
                )
              )
            : cell.full.thermocouples_specified || "-"}
        </TableCell>
      );

    case "full__serial_number":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.full.serial_number || "-")
              )
            : cell.full.serial_number || "-"}
        </TableCell>
      );

    case "condition__pool":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.condition.pool || "-")
              )
            : cell.condition.pool || "-"}
        </TableCell>
      );

    case "full__build_test_location":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.full.build_test_location || "-"
                )
              )
            : cell.full.build_test_location || "-"}
        </TableCell>
      );

    case "anode__assembly_type":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.anode.assembly_type || "-")
              )
            : cell.anode.assembly_type || "-"}
        </TableCell>
      );

    case "anode__qc_targets":
      const qcTargets = cell.anode.qc_targets;
      const determineQCToolTipText = () => {
        if (!qcTargets || qcTargets.length < 2) return null;
        return qcTargets.slice(1).join(", ");
      };
      const tooltipQCText = determineQCToolTipText();
      const groupedQCText =
        !isConditionExpanded &&
        getCombinedLabel(subConditionCells.flatMap((cell) => qcTargets || []));
      return (
        <TableCell>
          {groupedQCText ? (
            <Tooltip
              arrow
              title={subConditionCells
                .flatMap((cell) => qcTargets || [])
                .filter((qcTarget) => !groupedQCText.includes(qcTarget))
                .join(", ")}
            >
              <span>{groupedQCText}</span>
            </Tooltip>
          ) : tooltipQCText ? (
            <Tooltip arrow title={tooltipQCText!}>
              <span>{getCombinedLabel(qcTargets!)}</span>
            </Tooltip>
          ) : (
            <span>{qcTargets ? getCombinedLabel(qcTargets) : "-"}</span>
          )}
        </TableCell>
      );

    case "anode__part_number":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.anode.part_number_description
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.anode.part_number)
                )}
              </span>
            </Tooltip>
          ) : cell.anode.part_number ? (
            <Tooltip arrow title={cell.anode.part_number_description}>
              <span>{cell.anode.part_number || "-"}</span>
            </Tooltip>
          ) : (
            cell.anode.part_number || "-"
          )}
        </TableCell>
      );

    case "anode__icm_pcm":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map((cell) => cell.anode.icm_pcm_description)
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.anode.icm_pcm)
                )}
              </span>
            </Tooltip>
          ) : cell.anode.icm_pcm ? (
            <Tooltip arrow title={cell.anode.icm_pcm_description}>
              <span>{cell.anode.icm_pcm || "-"}</span>
            </Tooltip>
          ) : (
            cell.anode.icm_pcm || "-"
          )}
        </TableCell>
      );

    case "anode__additive_icm_pcm":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.anode.additive_icm_pcm_description
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.anode.additive_icm_pcm)
                )}
              </span>
            </Tooltip>
          ) : cell.anode.additive_icm_pcm ? (
            <Tooltip arrow title={cell.anode.additive_icm_pcm_description}>
              <span>{cell.anode.additive_icm_pcm || "-"}</span>
            </Tooltip>
          ) : (
            cell.anode.additive_icm_pcm || "-"
          )}
        </TableCell>
      );

    case "anode__nominal_mass":
    case "anode__mass_actual":
    case "anode__mass_actual_2":
    case "anode__mass_actual_3":
    case "anode__mass_actual_4":
    case "anode__mass_actual_5":
    case "anode__mass_actual_6":
    case "anode__mass_actual_total":
      const [massKey] = dataKey.split("__").slice(-1);
      const anodeKey = massKey as
        | "nominal_mass"
        | "mass_actual"
        | "mass_actual_2"
        | "mass_actual_3"
        | "mass_actual_4"
        | "mass_actual_5"
        | "mass_actual_6"
        | "mass_actual_total";
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) =>
                    `${!cell.anode[anodeKey] ? "" : cell.anode[anodeKey]}`
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map(
                    (cell) =>
                      `${!cell.anode[anodeKey] ? "" : cell.anode[anodeKey]}`
                  )
                )}
              </span>
            </Tooltip>
          ) : cell.anode[anodeKey] ? (
            <Tooltip arrow title={cell.anode[anodeKey]!}>
              <span>{cell.anode[anodeKey] || "-"}</span>
            </Tooltip>
          ) : (
            cell.anode[anodeKey] || "-"
          )}
        </TableCell>
      );

    case "ref_anode__reference_electrode_type":
    case "ref_gde__reference_electrode_type":
    case "ref_oee__reference_electrode_type":
      const componentKey = REF_FIELD_KEY_TO_COMPONENT_KEY[dataKey] as
        | "ref_anode"
        | "ref_gde"
        | "ref_oee";
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) =>
                    `${
                      !cell[componentKey].reference_electrode_type
                        ? ""
                        : cell[componentKey].reference_electrode_type
                    }`
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map(
                    (cell) =>
                      `${
                        !cell[componentKey].reference_electrode_type
                          ? ""
                          : cell[componentKey].reference_electrode_type
                      }`
                  )
                )}
              </span>
            </Tooltip>
          ) : cell[componentKey].reference_electrode_type ? (
            <Tooltip arrow title={cell[componentKey].reference_electrode_type}>
              <span>{cell[componentKey].reference_electrode_type || "-"}</span>
            </Tooltip>
          ) : (
            cell[componentKey].reference_electrode_type || "-"
          )}
        </TableCell>
      );

    case "electrolyte__icm_pcm":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.electrolyte.icm_pcm_description
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.electrolyte.icm_pcm)
                )}
              </span>
            </Tooltip>
          ) : cell.electrolyte.icm_pcm ? (
            <Tooltip arrow title={cell.electrolyte.icm_pcm_description}>
              <span>{cell.electrolyte.icm_pcm || "-"}</span>
            </Tooltip>
          ) : (
            cell.electrolyte.icm_pcm || "-"
          )}
        </TableCell>
      );

    case "fill__nominal_volume":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map(
                  (cell) =>
                    `${
                      !cell.fill.nominal_volume ? "" : cell.fill.nominal_volume
                    }`
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map(
                    (cell) =>
                      `${
                        !cell.fill.nominal_volume
                          ? ""
                          : cell.fill.nominal_volume
                      }`
                  )
                )}
              </span>
            </Tooltip>
          ) : cell.fill.nominal_volume ? (
            <Tooltip arrow title={cell.fill.nominal_volume}>
              <span>{cell.fill.nominal_volume || "-"}</span>
            </Tooltip>
          ) : (
            cell.fill.nominal_volume || "-"
          )}
        </TableCell>
      );

    case "gde__icm_pcm":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map((cell) => cell.gde.icm_pcm_description)
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.gde.icm_pcm)
                )}
              </span>
            </Tooltip>
          ) : cell.gde.icm_pcm ? (
            <Tooltip arrow title={cell.gde.icm_pcm_description}>
              <span>{cell.gde.icm_pcm || "-"}</span>
            </Tooltip>
          ) : (
            cell.gde.icm_pcm || "-"
          )}
        </TableCell>
      );

    case "oee__icm_pcm":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map((cell) => cell.oee.icm_pcm_description)
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) => cell.oee.icm_pcm)
                )}
              </span>
            </Tooltip>
          ) : cell.oee.icm_pcm ? (
            <Tooltip arrow title={cell.oee.icm_pcm_description}>
              <span>{cell.oee.icm_pcm || "-"}</span>
            </Tooltip>
          ) : (
            cell.oee.icm_pcm || "-"
          )}
        </TableCell>
      );

    case "condition__plan_test_start_date":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) =>
                  isoDateToDateString(cell.condition.plan_test_start_date)
                )
              )
            : isoDateToDateString(cell.condition.plan_test_start_date)}
        </TableCell>
      );
    case "condition__build_phase":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells
                  .filter((cell) => !!cell.condition.build_phase)
                  .map((cell) => cell.condition.build_phase!)
              )
            : cell.condition.build_phase}
        </TableCell>
      );
    case "condition__build_config":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells
                  .filter((cell) => !!cell.condition.build_config)
                  .map((cell) => cell.condition.build_config!)
              )
            : cell.condition.build_config}
        </TableCell>
      );

    case "condition__specified":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) =>
                  isoDateToDateString(cell.condition.specified)
                )
              )
            : isoDateToDateString(cell.condition.specified)}
        </TableCell>
      );

    case "staged":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) =>
                  isoDateToDateString(cell.staged)
                )
              )
            : isoDateToDateString(cell.staged)}
        </TableCell>
      );

    case "committed":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) =>
                  isoDateToDateString(cell.committed)
                )
              )
            : isoDateToDateString(cell.committed)}
        </TableCell>
      );

    case "test__created_time":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) =>
                  isoDateToDateString(cell.on_test.completed_at)
                )
              )
            : isoDateToDateString(cell.on_test.completed_at)}
        </TableCell>
      );

    case "current_ref_cal__measurement":
      return (
        <TableCell>
          {!isConditionExpanded ? (
            <Tooltip
              arrow
              title={getCombinedLabel(
                subConditionCells.map((cell) =>
                  cell.current_ref_cal.measurement
                    ? isoDateToDateTimeString(
                        DateTime.fromSeconds(
                          cell.current_ref_cal.measured_at
                        ).toISO()
                      )
                    : ""
                )
              )}
            >
              <span>
                {getCombinedLabel(
                  subConditionCells.map((cell) =>
                    cell.current_ref_cal.measurement
                      ? `${cell.current_ref_cal.measurement}`
                      : ""
                  )
                )}
              </span>
            </Tooltip>
          ) : cell.current_ref_cal.measurement ? (
            <Tooltip
              arrow
              title={isoDateToDateTimeString(
                DateTime.fromSeconds(cell.current_ref_cal.measured_at).toISO()
              )}
            >
              <span>{cell.current_ref_cal.measurement || "-"}</span>
            </Tooltip>
          ) : (
            cell.current_ref_cal.measurement || "-"
          )}
        </TableCell>
      );

    case "on_test__cycling_protocol_id":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map(
                  (cell) => cell.on_test.cycling_protocol_id || "-"
                )
              )
            : cell.on_test.cycling_protocol_id || "-"}
        </TableCell>
      );

    case "anode__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.anode.non_standard)
              : [cell.anode.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.anode.deviations_from_sop)
              : [cell.anode.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.anode._icon)
              : [cell.anode._icon]
          }
        />
      );

    case "gde__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.gde.non_standard)
              : [cell.gde.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.gde.deviations_from_sop)
              : [cell.gde.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.gde._icon)
              : [cell.gde._icon]
          }
        />
      );

    case "oee__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.oee.non_standard)
              : [cell.oee.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.oee.deviations_from_sop)
              : [cell.oee.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.oee._icon)
              : [cell.oee._icon]
          }
        />
      );

    case "counter__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.counter.non_standard)
              : [cell.counter.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.counter.deviations_from_sop
                )
              : [cell.counter.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.counter._icon)
              : [cell.counter._icon]
          }
        />
      );

    case "ref_anode__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_anode.non_standard)
              : [cell.ref_anode.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.ref_anode.deviations_from_sop
                )
              : [cell.ref_anode.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_anode._icon)
              : [cell.ref_anode._icon]
          }
        />
      );

    case "ref_gde__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_gde.non_standard)
              : [cell.ref_gde.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.ref_gde.deviations_from_sop
                )
              : [cell.ref_gde.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_gde._icon)
              : [cell.ref_gde._icon]
          }
        />
      );

    case "ref_oee__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_oee.non_standard)
              : [cell.ref_oee.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.ref_oee.deviations_from_sop
                )
              : [cell.ref_oee.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ref_oee._icon)
              : [cell.ref_oee._icon]
          }
        />
      );

    case "full__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.full.non_standard)
              : [cell.full.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.full.deviations_from_sop)
              : [cell.full.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.full._icon)
              : [cell.full._icon]
          }
        />
      );

    case "electrolyte__mix_task__completed":
    case "electrolyte__qa_task__completed":
      const elyteField: keyof CellStepStatus =
        dataKey === "electrolyte__mix_task__completed"
          ? "elyte_mix_task_completed"
          : "elyte_qa_task_completed";
      const computeAsanaTaskIcons = (): ("na" | "check" | "times")[] => {
        if (isBoolean(cell.electrolyte[elyteField])) {
          return cell.electrolyte[elyteField] ? ["check"] : ["times"];
        }
        return ["na"];
      };
      return <FlagTableCell icons={computeAsanaTaskIcons()} />;

    case "electrolyte__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.electrolyte.non_standard)
              : [cell.electrolyte.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.electrolyte.deviations_from_sop
                )
              : [cell.electrolyte.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.electrolyte._icon)
              : [cell.electrolyte._icon]
          }
        />
      );

    case "fill__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.fill.non_standard)
              : [cell.fill.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.fill.deviations_from_sop)
              : [cell.fill.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.fill._icon)
              : [cell.fill._icon]
          }
        />
      );

    case "on_test__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.on_test.non_standard)
              : [cell.on_test.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.on_test.deviations_from_sop
                )
              : [cell.on_test.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.on_test._icon)
              : [cell.on_test._icon]
          }
        />
      );

    case "ready_off__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ready_off.non_standard)
              : [cell.ready_off.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.ready_off.deviations_from_sop
                )
              : [cell.ready_off.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.ready_off._icon)
              : [cell.ready_off._icon]
          }
        />
      );

    case "off_test__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.off_test.non_standard)
              : [cell.off_test.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.off_test.deviations_from_sop
                )
              : [cell.off_test.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.off_test._icon)
              : [cell.off_test._icon]
          }
        />
      );

    case "teardown__completed_at":
      return (
        <FlagTableCell
          nonStandard={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.teardown.non_standard)
              : [cell.teardown.non_standard]
          }
          sopDeviations={
            !isConditionExpanded
              ? subConditionCells.map(
                  (cell) => cell.teardown.deviations_from_sop
                )
              : [cell.teardown.deviations_from_sop]
          }
          icons={
            !isConditionExpanded
              ? subConditionCells.map((cell) => cell.teardown._icon)
              : [cell.teardown._icon]
          }
        />
      );

    case "teardown__type":
      return (
        <TableCell>
          {!isConditionExpanded
            ? getCombinedLabel(
                subConditionCells.map((cell) => cell.teardown.type)
              )
            : cell.teardown.type}
        </TableCell>
      );

    case "teardown__flags":
      const teardownFlags = cell.teardown.flags;
      const determineFlagToolTipText = () => {
        if (!teardownFlags || uniq(teardownFlags).length < 2) return null;
        return uniq(teardownFlags)
          .slice(1)
          .map((flagId) => findLabelForFlag(flagId))
          .join(", ");
      };
      const flagTooltipText = determineFlagToolTipText();
      const groupedFlagText =
        !isConditionExpanded &&
        getCombinedLabel(
          subConditionCells
            .flatMap((cell) => cell.teardown.flags || [])
            .map((flagKey) => findLabelForFlag(flagKey))
        );

      return (
        <TableCell>
          {groupedFlagText && flagTooltipText ? (
            <Tooltip arrow title={flagTooltipText}>
              <span>{groupedFlagText}</span>
            </Tooltip>
          ) : flagTooltipText ? (
            <Tooltip arrow title={flagTooltipText!}>
              <span>
                {getCombinedLabel(
                  teardownFlags!.map((flagKey) => findLabelForFlag(flagKey))
                )}
              </span>
            </Tooltip>
          ) : (
            <span>
              {teardownFlags && teardownFlags.length > 0
                ? getCombinedLabel(
                    teardownFlags!.map((flagKey) => findLabelForFlag(flagKey))
                  )
                : "-"}
            </span>
          )}
        </TableCell>
      );

    default:
      return <TableCell>{dataKey} not implemented</TableCell>;
  }
};

export default CellTableCell;
