import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const CYCLING_PROTOCOLS = "CYCLING_PROTOCOLS";

// Redux state for test script pages.
export interface CyclingProtocolsState {
  // Plain text of the fact sheet.
  factSheet: CyclingProtocolFactSheet | null;
  // Plain text of the protobuf text formatted test script.
  cyclingProtocol: string | null;
  cyclingProtocolsList: CyclingProtocol[];
  // Standard status and error indicators for each of the RPCs we define here.
  // These are set in reducers below, as in status.get = "failed".
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    list: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    list: null | string;
  };
};

const initialState: CyclingProtocolsState = {
  factSheet: null,
  cyclingProtocol: null,
  cyclingProtocolsList: [],
  status: {
    get: "idle",
    list: "idle",
  },
  error: {
    get: null,
    list: null,
  },
};

// Request definition to look up a test script by cycling_protocol_id.
// Ex: "28.0612". Issues a GET request.
export const getCyclingProtocol = createAsyncThunk(
  `${CYCLING_PROTOCOLS}/get/cycling-protocol-id`,
  async (obj: {cycling_protocol_id: string}) => {
    const response = await client.get(
      `meta/cycling-protocols/fact-sheet/${obj["cycling_protocol_id"]}`
    );
    return response;
  }
);

// Request definition to list all test scripts for an index page.
export const getAllCyclingProtocols = createAsyncThunk(
  `${CYCLING_PROTOCOLS}/get`,
  async () => {
    const response = await client.get(
      `meta/cycling-protocols/`
    );
    return response;
  }
);

// Standard Redux Slice definition for processing responses.
const slice = createSlice({
  name: CYCLING_PROTOCOLS,
  initialState,
  reducers: {
    resetState: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      // Get one test script data
      .addCase(getCyclingProtocol.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getCyclingProtocol.fulfilled, (state, { payload }) => {
        // When the getCyclingProtocol request comes back, unpack its
        // "fact_sheet" and "test_script" JSON keys into state fields.
        state.status.get = "succeeded";
        state.factSheet = payload.fact_sheet;
        state.cyclingProtocol = payload.cycling_protocol;
      })
      .addCase(getCyclingProtocol.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Get list of test scripts
      .addCase(getAllCyclingProtocols.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(getAllCyclingProtocols.fulfilled, (state, { payload }) => {
        // When the getAllCyclingProtocols request comes back, copy its list into
        // the associated state field.
        state.status.list = "succeeded";
        state.cyclingProtocolsList = payload;
      })
      .addCase(getAllCyclingProtocols.rejected, (state, { error }) => {
        state.status.list = "failed";
        state.error.list = error.message as string;
      });
  },
});

export const {
  resetState,
} = slice.actions;

export default slice.reducer;
