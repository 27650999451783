import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const SettingsActive = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 20 20"
  >
    {children}
    <path
      d="M15.2866 3.57129H2.71512C2.08394 3.57129 1.57227 4.08296 1.57227 4.71415V17.2856C1.57227 17.9168 2.08394 18.4284 2.71512 18.4284H15.2866C15.9177 18.4284 16.4294 17.9168 16.4294 17.2856V4.71415C16.4294 4.08296 15.9177 3.57129 15.2866 3.57129Z"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.42969 8.14258H10.144"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8588 9.85679C12.8056 9.85679 13.5731 9.08928 13.5731 8.14251C13.5731 7.19573 12.8056 6.42822 11.8588 6.42822C10.912 6.42822 10.1445 7.19573 10.1445 8.14251C10.1445 9.08928 10.912 9.85679 11.8588 9.85679Z"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5731 13.8569H10.1445"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.7154 13.8569H4.42969"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.42913 15.5712C9.3759 15.5712 10.1434 14.8036 10.1434 13.8569C10.1434 12.9101 9.3759 12.1426 8.42913 12.1426C7.48236 12.1426 6.71484 12.9101 6.71484 13.8569C6.71484 14.8036 7.48236 15.5712 8.42913 15.5712Z"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="14"
      cy="6"
      r="4.5"
      fill="#B60B02"
      stroke="white"
      strokeWidth="3"
    />
  </SvgIcon>
);

export default SettingsActive;
