import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import colors from "../../theme/colors";
import { addToModule, resetAddToModuleStatus } from "./slice";
import type { ModuleState } from "./slice";
import type { RootState } from "../../store";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";
import MetaFilter from "../../components/table/MetaFilter";

// MUI
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  Input,
  // Select,
  Typography,
} from "@mui/material";

// Components
import Button from "../../components/Button";

type Props = {
  selected: Cell[];
  exp_id: string;
  modalOpen: boolean;
  onClose: () => void;
};

const AddCellsToModule = ({
  selected,
  exp_id,
  modalOpen = false,
  onClose,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [addToExisting, setAddToExisting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, watch } =
    useForm<AddToModuleFormData>({
      defaultValues: {
        addToExisting: addToExisting,
        name: "",
        serial_number: "",
        module: null,
      },
    });
  const moduleVal = watch("module");

  // Default form values are rendered with initial values, so update cell IDs
  // every time selected changes
  useEffect(() => {
    setValue(
      "cell_ids",
      selected.flatMap((cell) => cell.cell_id),
      { shouldDirty: true }
    );
  }, [selected, setValue]);

  const {
    status: { update: updateStatus },
    error: { update: updateError },
  } = useSelector<RootState, ModuleState>(({ modules }) => modules);

  const onSubmit = (form: AddToModuleFormData) => {
    const { module, ...formData } = form;
    const data = { module_id: module?.module_id, ...formData, exp_id: exp_id };
    dispatch(addToModule(data));
  };

  useEffect(() => {
    if (updateStatus === "succeeded") {
      onClose();
      // Reset the form
      setAddToExisting(false);
      setValue("name", "", { shouldDirty: true });
      setValue("serial_number", "", { shouldDirty: true });
    } else if (updateStatus === "failed") {
      setError(updateError);
    }
  }, [updateStatus, setValue, exp_id, onClose, dispatch, updateError]);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => {
          setAddToExisting(false);
          onClose();
        }}
      >
        <Typography variant="h2">Add to Module</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={6}>
            <Typography className="small" color="textSecondary">
              Cell(s)
            </Typography>

            <FormControl style={{ width: "100%", height: "100%" }}>
              <Controller
                control={control}
                name="cell_ids"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <div>
                    {value.flatMap((cell_id) => `CEL${cell_id}`).join(", ")}
                  </div>
                )}
              />
            </FormControl>
          </Box>

          <Box mt={6}>
            <Typography className="small" color="textSecondary">
              Add to an existing module?
            </Typography>

            <FormControl style={{ width: "100%", height: "100%" }}>
              <Controller
                control={control}
                name="addToExisting"
                rules={{ required: false }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <Checkbox
                    color="secondary"
                    sx={{ width: "fit-content", marginLeft: "-10px" }}
                    value={value}
                    onChange={(e, val) => {
                      setAddToExisting(val);
                      onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>

          {addToExisting ? (
            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                Module Name<span style={{ color: colors.accent.red }}>*</span>
                <br />
                Only modules associated with this experiment will show up.
              </Typography>

              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="module"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <MetaFilter
                      key={"module"}
                      multiple={false}
                      endpoint="meta/modules/search"
                      valueKey="name"
                      descriptionKey="serial_number"
                      displayBackupVal={(opt) =>
                        `${opt["module_type"] || "Unnamed module"} Oak ID ${
                          opt["module_id"]
                        }`
                      }
                      value={value}
                      queryParameters={{ exp_id: exp_id }}
                      onChange={(val) => {
                        setValue("module", val, { shouldDirty: true });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          ) : (
            <>
              <Box mt={6}>
                <Typography className="small" color="textSecondary">
                  Module Name
                </Typography>

                <FormControl style={{ width: "100%", height: "100%" }}>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid },
                    }) => (
                      <Input
                        className="small"
                        disableUnderline
                        inputRef={ref}
                        error={invalid}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputProps={{ maxLength: 100 }}
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <Box mt={6}>
                <Typography className="small" color="textSecondary">
                  Serial Number
                  <br />
                  Modules without serial numbers will be considered serial HPHCs
                </Typography>

                <FormControl style={{ width: "100%", height: "100%" }}>
                  <Controller
                    control={control}
                    name="serial_number"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid },
                    }) => (
                      <Input
                        className="small"
                        disableUnderline
                        inputRef={ref}
                        error={invalid}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputProps={{ maxLength: 100 }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </>
          )}

          <Button
            color="primary"
            size="small"
            type="submit"
            disabled={
              (addToExisting && !moduleVal) || updateStatus === "loading"
            }
            style={{ marginTop: "20px", width: "fit-content" }}
            endIcon={
              updateStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
          >
            Submit
          </Button>
        </form>
      </Modal>

      {updateStatus === "succeeded" && (
        <Toast
          open
          severity="success"
          onClose={() => dispatch(resetAddToModuleStatus())}
        >
          Cell{selected.length > 1 && "s"} successfully added to module
        </Toast>
      )}

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default AddCellsToModule;
