import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import styled from "@mui/styles/styled";
import Typography from "@mui/material/Typography";
import FilterIcon from "../../icons/Filter";
import PlusCircleIcon from "../../icons/PlusCircle";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import { useSelector } from "react-redux";
import Button from "../Button";
import Chip from "../Chip";
import SaveCellFilterModal from "./SaveCellFilterModal";
import DateFilter from "./DateFilter";
import EngineerFlagFilter from "./EngineerFlagFilter";
import MetaFilter from "./MetaFilter";
import OptionFilter from "./OptionFilter";
import TextFilter from "./TextFilter";
import UserFilter from "./UserFilter";
import ProjectFilter from "./ProjectFilter";
import { CircularProgress } from "@mui/material";

const MineLabel = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 500,
});

const DEFAULT_FILTERS: (keyof CellFilters)[] = [
  "condition__specified",
  "committed",
  "test__created_time",
  "cell_id",
  "experiment__exp_id",
  "experiment__project",
  "engineer_flag",
  "deviations_from_sop",
];

type Props = {
  labels: DataLabel<Cell>[];
  filterOptions: FilterOptions<CellFilters>;
  filters: CellFilters;
  hiddenFilters: (keyof CellFilters)[];
  visibleStatuses: CellStatus[];
  onChange: (filters: CellFilters) => void;
  loadingCells: boolean;
};

const ButtonCellFilters = ({
  labels,
  filterOptions,
  filters,
  hiddenFilters,
  visibleStatuses,
  onChange,
  loadingCells,
}: Props) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Local filter state
  const [filterState, setFilterState] = useState(filters);
  useEffect(() => setFilterState(filters), [filters]);

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  // Add filter button
  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAddFilterAnchorEl(event.currentTarget);
  const handleAddFilterClose = () => setAddFilterAnchorEl(null);

  // UI state
  const [visibleFilters, setVisibleFilters] = useState<(keyof CellFilters)[]>(
    DEFAULT_FILTERS.filter((key) => !hiddenFilters.includes(key))
  );
  const availableFilters = (
    Object.keys(filterOptions) as Array<keyof CellFilters>
  ).filter(
    (key) => !visibleFilters.includes(key) && !hiddenFilters.includes(key)
  );

  useEffect(() => {
    if (!!buttonEl) {
      return;
    }

    const newKeys = Object.keys(filters).map((key) => key as keyof CellFilters);
    const changed = newKeys.filter((key) => visibleFilters.indexOf(key) === -1);

    if (changed.length > 0) {
      setVisibleFilters([...visibleFilters, ...changed]);
    }
  }, [buttonEl, filters, visibleFilters]);

  // Remove filter
  const handleRemoveFilter = (key: keyof CellFilters) => {
    const newState = { ...filterState };
    delete newState[key];
    const index = visibleFilters.indexOf(key);
    visibleFilters.splice(index, 1);
    setFilterState(newState);
    setVisibleFilters([...visibleFilters]);
  };

  // Reset action
  const handleResetClick = () => {
    setFilterState({});
    setVisibleFilters(DEFAULT_FILTERS);
  };

  const getCleanFilters = () => {
    const finalFilters = { ...filterState };
    if (finalFilters["engineer_flag"]) {
      finalFilters["engineer_flag"] = finalFilters["engineer_flag"].filter(
        (_filter) => _filter[0] && _filter[1]
      );
      if (finalFilters["engineer_flag"].length === 0) {
        delete finalFilters["engineer_flag"];
      }
    }

    if (finalFilters["deviations_from_sop"]) {
      finalFilters["deviations_from_sop"] = finalFilters[
        "deviations_from_sop"
      ].filter((_filter) => _filter[0] && _filter[1]);
      if (finalFilters["deviations_from_sop"].length === 0) {
        delete finalFilters["deviations_from_sop"];
      }
    }

    for (const key in finalFilters) {
      const typedKey = key as keyof CellFilters;
      if (finalFilters[typedKey]?.length === 0) {
        delete finalFilters[typedKey];
      }
    }
    return finalFilters;
  };

  // Save action
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const onAfterSave = () => {
    handleApplyClick();
    setSaveModalOpen(false);
  };

  // Apply action
  const handleApplyClick = () => {
    const finalFilters = getCleanFilters();
    onChange(finalFilters);
    setButtonEl(null);
  };

  // Mine checkbox state
  const handleMineChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setFilterState({
        ...filterState,
        experiment__owner: [],
      });
    } else {
      if (!visibleFilters.includes("experiment__owner")) {
        setVisibleFilters(["experiment__owner", ...visibleFilters]);
      }
      setFilterState({
        ...filterState,
        experiment__owner: user ? [user] : [],
      });
    }
  };

  return (
    <>
      <Chip
        count={Object.keys(filters).length}
        clickable
        onClick={handleButtonClick}
        onDelete={() => onChange({})}
        icon={
          loadingCells ? (
            <CircularProgress style={{ width: 20, height: 20 }} />
          ) : (
            <FilterIcon />
          )
        }
        label="Filter"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={6} pt={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    !!filterState.experiment__owner?.some(
                      (filter) => filter.user_id === user?.user_id
                    )
                  }
                  onChange={handleMineChecked}
                />
              }
              label={<MineLabel color="textSecondary">My Cells</MineLabel>}
            />
          </FormGroup>

          <Box my={4}>
            <Divider />
          </Box>

          <table>
            <tbody>
              {visibleFilters.map((key) => {
                if (!filterOptions[key]) {
                  return null;
                }

                const expLabel = labels.find((label) => label.id === key);
                const { type, options } = filterOptions[key]!;

                let control;
                switch (key) {
                  case "condition__specified":
                  case "committed":
                  case "test__created_time":
                  case "condition__plan_test_start_date":
                    control = (
                      <DateFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={([start, end]) => {
                          if (!start && !end) {
                            const { ...newState } = filterState;
                            delete newState[key];
                            setFilterState(newState);
                          } else {
                            setFilterState({
                              ...filterState,
                              [key]: [start, end],
                            });
                          }
                        }}
                      />
                    );
                    break;
                  case "cell_id":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/ids"
                        prefix="CEL"
                        value={filterState.cell_id || []}
                        onChange={(cell_id) =>
                          setFilterState({ ...filterState, cell_id })
                        }
                      />
                    );
                    break;
                  case "experiment__exp_id":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/experiments/ids"
                        prefix="EXP"
                        value={filterState.experiment__exp_id || []}
                        onChange={(experiment__exp_id) =>
                          setFilterState({ ...filterState, experiment__exp_id })
                        }
                      />
                    );
                    break;
                  case "experiment__project":
                    control = (
                      <ProjectFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState.experiment__project || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "condition__cell_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/cell-types"
                        value={filterState.condition__cell_type || []}
                        onChange={(condition__cell_type) =>
                          setFilterState({
                            ...filterState,
                            condition__cell_type,
                          })
                        }
                      />
                    );
                    break;
                  case "condition__cell_assembly":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/cell-assemblies"
                        value={filterState.condition__cell_assembly || []}
                        onChange={(condition__cell_assembly) =>
                          setFilterState({
                            ...filterState,
                            condition__cell_assembly,
                          })
                        }
                      />
                    );
                    break;
                  case "condition__build_phase":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cell-conditions/build-phases"
                        value={filterState.condition__build_phase || []}
                        onChange={(condition__build_phase) =>
                          setFilterState({
                            ...filterState,
                            condition__build_phase,
                          })
                        }
                      />
                    );
                    break;
                  case "condition__pool":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cell-conditions/pools"
                        value={filterState.condition__pool || []}
                        onChange={(condition__pool) =>
                          setFilterState({
                            ...filterState,
                            condition__pool,
                          })
                        }
                      />
                    );
                    break;
                  case "condition__build_config":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cell-conditions/build-configs"
                        value={filterState.condition__build_config || []}
                        onChange={(condition__build_config) =>
                          setFilterState({
                            ...filterState,
                            condition__build_config,
                          })
                        }
                      />
                    );
                    break;

                  case "experiment__owner":
                    control = (
                      <UserFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;

                  case "full__serial_number":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cell-components/serial-numbers"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "full__build_test_location":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/condition-components/build-test-locations"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "anode__assembly_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/condition-components/assembly-types"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "anode__qc_targets":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/condition-components/qc-targets"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "anode__part_number":
                  case "anode__icm_pcm":
                  case "anode__additive_icm_pcm":
                  case "electrolyte__icm_pcm":
                  case "gde__icm_pcm":
                  case "oee__icm_pcm":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/materials"
                        valueKey="material_id"
                        descriptionKey="description"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                        queryParameters={{ include_disabled: true }}
                      />
                    );
                    break;
                  case "reserved_channel__fullname":
                  case "channel__fullname":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/channels"
                        value={filterState[key] || []}
                        valueKey="channel_fullname"
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "position__shelf":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/positions/shelves"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "incubator__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/incubators/names"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "tester__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/testers"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "on_test__cycling_protocol":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cycling-protocols/spec"
                        valueKey="cycling_protocol_id"
                        descriptionKey="description"
                        value={filterState.on_test__cycling_protocol || []}
                        onChange={(on_test__cycling_protocol) =>
                          setFilterState({
                            ...filterState,
                            on_test__cycling_protocol,
                          })
                        }
                      />
                    );
                    break;

                  case "on_test__adapter_cable_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/test-conditions/adapter-cable-types"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "test_meta__adapter_cable_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/test-metas/adapter-cable-types"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "oee__oee_ids":
                  case "gde__gde_ids":
                  case "hca__hca_ids":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint={`meta/cell-components/${key
                          .split("__")[1]
                          .replace("_", "-")}/existing`}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "status":
                    control = (
                      <OptionFilter
                        multiple
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={
                          visibleStatuses.length > 0 && options
                            ? options.filter(({ id }) =>
                                visibleStatuses.includes(id as CellStatus)
                              )
                            : options || []
                        }
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value as CellStatus[],
                          })
                        }
                      />
                    );
                    break;

                  case "priority":
                  case "electrolyte__mix_task__completed":
                  case "electrolyte__qa_task__completed":
                  case "anode__completed_at__null":
                  case "separator__completed_at__null":
                  case "gde__completed_at__null":
                  case "oee__completed_at__null":
                  case "counter__completed_at__null":
                  case "ref_anode__completed_at__null":
                  case "ref_gde__completed_at__null":
                  case "ref_oee__completed_at__null":
                  case "full__completed_at__null":
                  case "thermocouples_attached":
                  case "thermocouples_specified":
                  case "electrolyte__completed_at__null":
                  case "fill__completed_at__null":
                  case "on_test__completed_at__null":
                  case "on_test__eis_testing_beginning_of_life":
                  case "ready_off__completed_at__null":
                  case "off_test__completed_at__null":
                  case "off_test__eis_testing_end_of_life":
                  case "teardown__completed_at__null":
                  case "teardown__flags":
                  case "teardown__type":
                    control = (
                      <OptionFilter
                        key={key}
                        multiple={[
                          "priority",
                          "teardown__type",
                          "teardown__flags",
                        ].includes(key)}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={options || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "engineer_flag":
                  case "deviations_from_sop":
                    control = (
                      <EngineerFlagFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;

                  default:
                    control = (
                      <TextFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        onClear={() =>
                          setFilterState({
                            ...filterState,
                            [key]: [],
                          })
                        }
                        value={
                          filterState[key] ? filterState[key]![0] || "" : ""
                        }
                        onChange={(e) =>
                          setFilterState({
                            ...filterState,
                            [key]: [e.target.value],
                          })
                        }
                      />
                    );
                    break;
                }

                return (
                  <tr key={key}>
                    <Box
                      pr={4}
                      pb={4}
                      component="td"
                      style={
                        type === "options_componentflag"
                          ? { verticalAlign: "top", paddingTop: 12 }
                          : {}
                      }
                    >
                      <MineLabel color="textSecondary">
                        {expLabel?.label}
                      </MineLabel>
                    </Box>
                    <Box pb={4} component="td">
                      {control}
                    </Box>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>

        {availableFilters.length > 0 ? (
          <Box pb={3} px={3}>
            <Button
              color="tertiary"
              size="small"
              startIcon={<PlusCircleIcon />}
              onClick={handleAddFilterClick}
            >
              Add Filter
            </Button>
            <Menu
              anchorEl={addFilterAnchorEl}
              open={!!addFilterAnchorEl}
              onClose={handleAddFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {availableFilters.map((key) => {
                const cellLabel = labels.find((label) => label.id === key);
                return (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      setVisibleFilters([...visibleFilters, key]);
                      handleAddFilterClose();
                    }}
                  >
                    {cellLabel?.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        ) : null}

        {visibleFilters.length > 0 ? (
          <Box pt={3} pb={6} px={6} display="flex">
            <Button
              color="tertiary"
              type="button"
              size="small"
              onClick={handleResetClick}
            >
              <b>Reset</b>
            </Button>
            <Box ml="auto">
              <Button
                color="secondary"
                size="small"
                disabled={Object.keys(getCleanFilters()).length === 0}
                onClick={() => setSaveModalOpen(true)}
              >
                Save
              </Button>
            </Box>
            <Box ml={3}>
              <Button
                color="primary"
                type="button"
                size="small"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        ) : null}
      </Popover>

      <SaveCellFilterModal
        open={saveModalOpen}
        data={getCleanFilters()}
        onClose={() => setSaveModalOpen(false)}
        onAfterSave={onAfterSave}
      />
    </>
  );
};

export default ButtonCellFilters;
