import { forwardRef, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";
import uniq from "lodash/uniq";
import SmallChip from "../SmallChip";
import ExclamationCircleOutlinedIcon from "../../icons/ExclamationCircleOutlined";
import client from "../../api";
import ConditionCell from "./ConditionCell";
import useAutocompleteStyles from "./useAutocompleteStyles";
import ClockIcon from "../../icons/Clock";
import colors from "../../theme/colors";
import { toString } from "lodash";
import { useDebouncedCallback } from "use-debounce";

const MetaCellInput = styled(TextField)({
  "& .pendingChange": {
    textDecoration: "line-through",
    "& .pendingIconContainer": {
      width: 24,
      height: 20,
      color: colors.text.primary,
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
  },
});

type Props = {
  noTable?: boolean;
  style?: React.CSSProperties;
  className?: string;
  multiple?: boolean;
  dropdownTooltipText?: string;
  freeSolo?: boolean;
  endpoint: string;
  searchKey?: string;
  prefix?: string;
  descriptionKey?: string;
  valueKey?: string;
  value: any;
  pendingChange?: any;
  onBlur: () => void;
  onChange: (id: any) => void;
  disabled?: boolean;
  error?: boolean;
  showLoading?: boolean;
};

const MetaCell = forwardRef(
  (
    {
      noTable = false,
      style,
      className,
      multiple,
      freeSolo,
      endpoint,
      descriptionKey,
      dropdownTooltipText,
      searchKey = "",
      valueKey = "",
      prefix = "",
      value,
      pendingChange,
      onBlur,
      onChange,
      disabled,
      error,
      showLoading,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    searchKey = searchKey || valueKey;

    const classes = useAutocompleteStyles();
    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState<string[]>([]);
    const [search, setSearch] = useState("");

    const _handleLookup = useCallback(async () => {
      setLoading(true);

      try {
        const response = await client.get(
          `${endpoint}?${
            searchKey ? `${searchKey}__contains` : "search"
          }=${search.replace(prefix, "")}`
        );
        setOptions(response.data);
      } catch (err) {
        setOptions([]);
      }

      setLoading(false);
    }, [search, endpoint, searchKey, prefix]);

    const handleLookup = useDebouncedCallback(_handleLookup, 400, {
      leading: true,
      trailing: true,
    });

    useEffect(() => {
      if (search !== "") {
        handleLookup();
      }
    }, [search, handleLookup]);

    useEffect(() => {
      setLoading(!!showLoading);
    }, [showLoading]);

    const control = (
      <FormControl style={{ width: "100%", height: "100%" }}>
        <Autocomplete
          ref={ref}
          className={classes.root}
          disabled={disabled}
          options={options}
          multiple={multiple}
          filterOptions={(options, params) => {
            if (freeSolo) {
              const inputValue = parseInt(params["inputValue"]);
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (!!inputValue && !isExisting) {
                options.push(inputValue);
              }
            }
            return options;
          }}
          getOptionLabel={(option: any) =>
            searchKey && valueKey
              ? `${prefix}${option[searchKey]}`
              : toString(option)
          }
          isOptionEqualToValue={(option: any, value: any) =>
            valueKey
              ? option[valueKey] === value[valueKey]
              : (prefix ? `${prefix}${option}` : option) === value
          }
          value={value}
          onChange={(e, data) => {
            if (!multiple || !data) {
              onChange(data);
            } else {
              onChange(
                data.map((val: any) => {
                  if (freeSolo && typeof val === "string") {
                    return valueKey
                      ? {
                          [valueKey]: val,
                        }
                      : val;
                  } else {
                    return val;
                  }
                })
              );
            }
          }}
          loading={loading}
          noOptionsText={
            search.length > 0 ? "No results found." : "Start typing..."
          }
          renderInput={(params) => (
            <MetaCellInput
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                className: `qa-meta-cell-input ${
                  params.InputProps.className || ""
                } ${pendingChange !== undefined ? "pendingChange" : ""}`,
                endAdornment: (
                  <>
                    {pendingChange !== undefined ? (
                      <Tooltip
                        arrow
                        title={`Pending specification change to ${pendingChange}`}
                      >
                        <div className="pendingIconContainer">
                          <ClockIcon />
                        </div>
                      </Tooltip>
                    ) : null}
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : dropdownTooltipText || (value && descriptionKey) ? (
                      <Tooltip
                        arrow
                        title={
                          dropdownTooltipText
                            ? dropdownTooltipText
                            : value[descriptionKey!]
                        }
                      >
                        <div
                          style={{
                            height: 20,
                            width: 20,
                            marginRight: dropdownTooltipText && !value ? 4 : 0,
                          }}
                        >
                          <ExclamationCircleOutlinedIcon
                            style={{
                              color: colors.text.primary,
                              width: 20,
                              height: 20,
                              transform: "rotate(180deg)",
                            }}
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                    {params.InputProps.endAdornment ? (
                      <div style={disabled ? { height: 20, width: 28 } : {}}>
                        {params.InputProps.endAdornment}
                      </div>
                    ) : null}
                  </>
                ),
                value: search,
                onChange: (e) => setSearch(e.target.value),
                error,
              }}
            />
          )}
          renderOption={(props, value: any) => (
            <li {...props} key={valueKey ? value[valueKey] : value}>
              <Box className="qa-autofill-option" px={3} py={2} width={208}>
                <Typography
                  color="textPrimary"
                  className={descriptionKey ? "small" : undefined}
                >
                  {prefix + (searchKey && valueKey ? value[searchKey] : value)}
                </Typography>
                {descriptionKey ? (
                  <Typography color="textSecondary" className="tiny">
                    {value[descriptionKey]}
                  </Typography>
                ) : null}
              </Box>
            </li>
          )}
          renderTags={(values, getTagProps) => {
            const uniques = uniq(
              values.map((value) =>
                searchKey && valueKey ? value[searchKey] : value
              )
            );

            return uniques.map((value: string, index) => (
              <Box key={value} mr={2} mb={1}>
                <SmallChip label={value} {...getTagProps({ index })} />
              </Box>
            ));
          }}
          freeSolo={freeSolo}
          clearOnBlur={freeSolo}
          onBlur={onBlur}
        />
      </FormControl>
    );

    return noTable ? (
      <div style={style} className={className || ""}>
        {control}
      </div>
    ) : (
      <ConditionCell style={style} className={className || ""}>
        {control}
      </ConditionCell>
    );
  }
);

export default MetaCell;
