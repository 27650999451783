import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@mui/styles/styled";
import LoadingLayout from "../../layout/Loading";
import Button from "../../components/Button";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import ButtonAccessSignalMap from "../../features/signal-maps/ButtonAccessSignalMap";
import Link from "../../components/Link";
import Toast from "../../components/Toast";
import ArrowBackIcon from "../../icons/ArrowBack";
import ArrowBackCircleIcon from "../../icons/ArrowBackCircle";
import ArrowForwardCircleIcon from "../../icons/ArrowForwardCircle";
import ExpandIcon from "../../icons/Expand";
import ClockIcon from "../../icons/Clock";
import PaperWriteIcon from "../../icons/PaperWrite";
import PencilWriteIcon from "../../icons/PencilWrite";
import LayoutHeadlineIcon from "../../icons/LayoutHeadline";
import EventSummary from "../../components/EventSummary";
import {
  FSC_BATTERY_MODULE,
  cellIdToString,
  cellStatusToString,
  expIdToString,
} from "../../utils/labels";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import type { CellListState } from "./slice";
import type { CellSingleState } from "./singleSlice";
import { getSingleCell, resetSingleCell } from "./singleSlice";
import colors, { addAlpha } from "../../theme/colors";
import { cellsSlices } from "./slice";
import NoteFormSidePanel from "../notes/NoteFormSidePanel";
import NoteListSidePanel from "../notes/NoteListSidePanel";
import ReadyOffMenu from "./ReadyOffMenu";
import { editConditionPath } from "../../utils/urlPathHelpers";

const ShortDivider = styled(Divider)({
  height: "2rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  marginTop: "auto",
  marginBottom: "auto",
});

type Props = {
  notesOpen?: boolean;
};

const CellLayout = ({ notesOpen = false }: Props) => {
  const { cell_id = "", note_id = "" } = useParams();

  const navigate = useNavigate();

  const isTablet = useMediaQuery("(min-width:760px)");

  const location = useLocation();
  const { stateKey = "ALL", from = "" } = (location.state as {
    from?: string;
    stateKey?: keyof typeof cellsSlices;
  }) || { stateKey: "ALL", from: "" };

  const dispatch = useDispatch();
  const [mobileTab, setMobileTab] = useState<"CELL" | "AUDIT" | "NOTES">(
    "CELL"
  );

  const [noteFormPanelOpen, setNoteFormPanelOpen] = useState(false);
  const [noteListPanelOpen, setNoteListPanelOpen] = useState(notesOpen);
  useEffect(() => {
    setNoteListPanelOpen(notesOpen);
  }, [notesOpen]);

  const {
    cell,
    status: { get: cellStatus },
    error: { get: cellError },
  } = useSelector<RootState, CellSingleState>(({ cellSingle }) => cellSingle);

  const {
    cells,
    status: { readyOff: readyOffStatus, discontinue: discontinueStatus },
    error: { readyOff: readyOffError, discontinue: discontinueError },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  useEffect(() => {
    dispatch(resetSingleCell());
    dispatch(getSingleCell(parseInt(cell_id)));
    setMobileTab("CELL");
  }, [cell_id, user, dispatch]);

  useEffect(() => {
    if (readyOffStatus === "succeeded") {
      dispatch(resetSingleCell());
      dispatch(getSingleCell(parseInt(cell_id)));
    }
  }, [cell_id, dispatch, readyOffStatus]);

  useEffect(() => {
    if (discontinueStatus === "succeeded") {
      dispatch(resetSingleCell());
      dispatch(getSingleCell(parseInt(cell_id)));
    }
  }, [cell_id, dispatch, discontinueStatus]);

  if (cellStatus !== "succeeded" || !cell) {
    return (
      <Box p={32} style={{ background: "white" }}>
        <LoadingLayout light error={cellError || ""} />
      </Box>
    );
  }

  let readyOffErrorToast;
  if (readyOffStatus === "failed") {
    readyOffErrorToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetReadyOff())}
      >
        {readyOffError}
      </Toast>
    );
  }

  let discontinueErrorToast;
  if (discontinueStatus === "failed") {
    discontinueErrorToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(cellsSlices[stateKey].resetDiscontinue())}
      >
        {discontinueError}
      </Toast>
    );
  }

  const prevCellIndex =
    cells.findIndex(({ cell_id }) => cell.cell_id === cell_id) - 1;
  const prevCell = cells[prevCellIndex < 0 ? cells.length - 1 : prevCellIndex];

  const nextCellIndex =
    cells.findIndex(({ cell_id }) => cell.cell_id === cell_id) + 1;
  const nextCell = cells[nextCellIndex >= cells.length ? 0 : nextCellIndex];

  const navButtons = (
    <>
      <IconButton
        size="small"
        onClick={() =>
          navigate(`/cells/${prevCell?.cell_id}`, { state: { stateKey } })
        }
        disabled={cells.length === 0}
      >
        <ArrowBackCircleIcon />
      </IconButton>
      <ShortDivider orientation="vertical" flexItem />
      <IconButton
        size="small"
        onClick={() =>
          navigate(`/cells/${nextCell?.cell_id}`, { state: { stateKey } })
        }
        disabled={cells.length === 0}
      >
        <ArrowForwardCircleIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <Paper variant="outlined" square>
        <Box
          px={isTablet ? 6 : 0}
          py={isTablet ? 3 : 1}
          display="flex"
          alignItems="center"
        >
          <IconButton
            size="large"
            onClick={() =>
              from
                ? navigate(from)
                : !stateKey || stateKey === "ALL"
                ? navigate("/cells")
                : stateKey === "EXPERIMENT"
                ? navigate(`/experiments/${cell.experiment.exp_id}`)
                : navigate(`/cells/${stateKey.toLowerCase()}`)
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h2">
            Cell ID:{" "}
            <span style={{ fontWeight: 400 }}>
              {cellIdToString(cell.cell_id)}
            </span>
          </Typography>
          <Box ml="auto" display="flex" alignItems="center">
            {!cell.has_signal_map && (
              <ButtonAccessSignalMap dut_id={cell_id} dutType="cell" />
            )}
            <ButtonLogEvent itemType={"cell"} objects={[cell]} />

            {isTablet ? (
              <Box mr={2}>
                <IconButton onClick={() => setNoteFormPanelOpen(true)}>
                  <PencilWriteIcon />
                </IconButton>
              </Box>
            ) : null}
            {isTablet ? (
              <Box mr={2}>
                <IconButton onClick={() => setNoteListPanelOpen(true)}>
                  <PaperWriteIcon />
                </IconButton>
              </Box>
            ) : null}
            <Box mr={isTablet ? 2 : 0}>
              <CellMenu stateKey={stateKey} cell={cell} />
            </Box>
            {isTablet ? navButtons : null}
          </Box>
        </Box>
        {!isTablet ? (
          <Box display="flex" alignItems="center">
            <IconButton
              size="large"
              onClick={() => setMobileTab("CELL")}
              style={
                mobileTab === "CELL"
                  ? {
                      color: colors.accent.primary,
                      background: addAlpha(colors.accent.primary, 0.1),
                    }
                  : {}
              }
            >
              <LayoutHeadlineIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={() => setMobileTab("AUDIT")}
              style={
                mobileTab === "AUDIT"
                  ? {
                      color: colors.accent.primary,
                      background: addAlpha(colors.accent.primary, 0.1),
                    }
                  : {}
              }
            >
              <ClockIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={() => setNoteListPanelOpen(true)}
              style={
                noteListPanelOpen
                  ? {
                      color: colors.accent.primary,
                      background: addAlpha(colors.accent.primary, 0.1),
                    }
                  : {}
              }
            >
              <PaperWriteIcon />
            </IconButton>
            <Box
              py={isTablet ? 3 : 1}
              ml="auto"
              display="flex"
              alignItems="center"
            >
              {navButtons}
            </Box>
          </Box>
        ) : null}
      </Paper>

      <Grid container>
        <Grid item xs={12} lg={8}>
          {mobileTab === "CELL" || isTablet ? <CellView cell={cell} /> : null}
        </Grid>

        <Grid item xs={12} lg={4}>
          {mobileTab === "AUDIT" || isTablet ? (
            <CellAuditLogView cell={cell} />
          ) : null}
        </Grid>

        {mobileTab === "NOTES" && !isTablet ? (
          <Grid item xs={12} lg={4}>
            <CellAuditLogView cell={cell} />
          </Grid>
        ) : null}
      </Grid>

      <NoteFormSidePanel
        open={noteFormPanelOpen}
        onClose={() => setNoteFormPanelOpen(false)}
        resource="cell"
        options={[parseInt(cell_id)]}
      />

      <NoteListSidePanel
        open={noteListPanelOpen}
        onCreate={() => {
          setNoteListPanelOpen(false);
          setNoteFormPanelOpen(true);
        }}
        noteId={note_id ? parseInt(note_id) : undefined}
        onClose={() => setNoteListPanelOpen(false)}
        resource="cell"
        record_ids={[cell.cell_id]}
      />

      {discontinueErrorToast}
      {readyOffErrorToast}
    </>
  );
};

type CellViewProps = {
  cell: Cell | CellSingle;
};

const CellView = ({ cell }: CellViewProps) => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(min-width:760px)");

  const [expanded, setExpanded] = useState(true);
  const [specsMetadataLinkDestination, setSpecsMetadataLinkDestination] =
    useState<"DISABLED" | "EDIT_SPECIFICATION" | "METADATA_ENTRY">(
      "METADATA_ENTRY"
    );
  const TOOLTIP_TEXT_PER_DESTINATION = {
    DISABLED: "This cell is backfilled from TrackerWare.",
    EDIT_SPECIFICATION: "Cell must be committed to input metadata.",
  };
  const PATH_PER_DESTINATION = {
    EDIT_SPECIFICATION: `/experiments/${cell.condition.exp_id}/conditions/${cell.condition.cell_condition_id}`,
    METADATA_ENTRY: `/metadata/${cell.condition.cell_condition_id}-${cell.cell_id}`,
  };
  const showTooltip = Object.keys(TOOLTIP_TEXT_PER_DESTINATION).includes(
    specsMetadataLinkDestination
  );
  useEffect(() => {
    // Cell interface type for backfill is "0 | 1", so we need a unary
    // + operator to both satisfy truth value and avoid a typing error.
    if (+cell.backfill === 1) {
      setSpecsMetadataLinkDestination("DISABLED");
      return;
    }
    if (!cell.committed) {
      setSpecsMetadataLinkDestination("EDIT_SPECIFICATION");
    }
  }, [cell]);

  return (
    <>
      <Box px={isTablet ? 6 : 0} my={4}>
        <Paper variant="outlined" square>
          <Box px={isTablet ? 6 : 3} pt={3} pb={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h3" display="inline">
                      EXP ID:
                    </Typography>
                    <Link
                      color="secondary"
                      variant="h3"
                      to={`/experiments/${cell.experiment.exp_id}`}
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: 400,
                      }}
                    >
                      {expIdToString(cell.experiment.exp_id)}
                    </Link>
                  </Box>
                  <IconButton
                    size={!isTablet ? "small" : "large"}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <ExpandIcon
                      style={expanded ? { transform: "rotate(90deg)" } : {}}
                    />
                  </IconButton>
                </Box>
                <Typography>{cell.experiment.description}</Typography>
              </Grid>

              {expanded ? (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4} md={2} lg={1}>
                      <Typography color="textSecondary" paragraph>
                        Owner
                      </Typography>
                    </Grid>

                    <Grid item xs={8} md={10} lg={11}>
                      <Typography paragraph>
                        {cell.experiment.owner?.name || "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} md={2} lg={1}>
                      <Typography color="textSecondary">Project</Typography>
                    </Grid>
                    <Grid item xs={8} md={10} lg={11}>
                      <Typography>
                        {cell.experiment.project.name || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box px={isTablet ? 6 : 0} my={4}>
        <Paper variant="outlined" square>
          <Box
            m={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box m={isTablet ? 3 : 0}>
              <Typography variant="h3" display="inline">
                Specifications and metadata
              </Typography>
            </Box>
            <Tooltip
              arrow
              placement="top"
              title={
                showTooltip &&
                TOOLTIP_TEXT_PER_DESTINATION[
                  specsMetadataLinkDestination as
                    | "DISABLED"
                    | "EDIT_SPECIFICATION"
                ]
              }
              disableHoverListener={!showTooltip}
              disableFocusListener={!showTooltip}
            >
              <span>
                <Button
                  color="text"
                  variant="text"
                  className="small"
                  disabled={specsMetadataLinkDestination === "DISABLED"}
                  style={{ color: colors.text.primary }}
                  endIcon={<ExpandIcon />}
                  onClick={() =>
                    navigate(
                      specsMetadataLinkDestination === "EDIT_SPECIFICATION"
                        ? PATH_PER_DESTINATION["EDIT_SPECIFICATION"]
                        : PATH_PER_DESTINATION["METADATA_ENTRY"],
                      {
                        state: { from: `/cells/${cell.cell_id}` },
                      }
                    )
                  }
                >
                  {!isTablet ? (
                    ""
                  ) : (
                    <b>
                      {specsMetadataLinkDestination === "EDIT_SPECIFICATION"
                        ? "View/edit specification"
                        : "Input metadata"}
                    </b>
                  )}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Box>

      {cell.has_signal_map && (
        <Box px={isTablet ? 6 : 0} my={4}>
          <Paper variant="outlined" square>
            <Box
              m={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box m={isTablet ? 3 : 0}>
                <Typography variant="h3" display="inline">
                  Signal Map
                </Typography>
              </Box>
              <Button
                color="text"
                variant="text"
                className="small"
                style={{ color: colors.text.primary }}
                endIcon={<ExpandIcon />}
                onClick={() =>
                  navigate(`/signal-maps/cells/${cell.cell_id}`, {
                    state: { from: `/cells/${cell.cell_id}` },
                  })
                }
              >
                {isTablet ? <b>Edit</b> : ""}
              </Button>
            </Box>
          </Paper>
        </Box>
      )}

      <CellDetails cell={cell} />
      <TestInfrastructure cell={cell} />
      <EventSummary resourceType={"cell"} resource_id={cell.cell_id} />
    </>
  );
};

type CellAuditLogViewProps = {
  cell: Cell | CellSingle;
};

const CellAuditLogView = ({ cell }: CellAuditLogViewProps) => {
  const isTablet = useMediaQuery("(min-width:760px)");

  return (
    <Box pr={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box px={isTablet ? 6 : 3} pt={6} pb={4}>
          <Typography variant="h3">
            Status:{" "}
            <span style={{ fontWeight: 400 }}>
              {cellStatusToString(cell.status)}
            </span>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

type CellDetailsProps = {
  cell: Cell | CellSingle;
};

const CellDetails = ({ cell }: CellDetailsProps) => {
  const isTablet = useMediaQuery("(min-width:760px)");

  return (
    <Box px={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box p={isTablet ? 6 : 3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Cell Details</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography color="textSecondary" paragraph>
                    Condition
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph>{cell.condition.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" paragraph>
                    Cell Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph>{cell.condition.cell_type}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Cell Assembly</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph>
                    {cell.condition.cell_assembly}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Serial #</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{cell.full.serial_number || "-"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                {isTablet ? <Divider orientation="vertical" flexItem /> : null}
                <Box ml={isTablet ? 5 : 0} width="100%">
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Anode ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.anode.icm_pcm ? (
                          <Tooltip arrow title={cell.anode.icm_pcm_description}>
                            <span>{cell.anode.icm_pcm}</span>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Electrolyte ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.electrolyte.icm_pcm ? (
                          <Tooltip
                            arrow
                            title={cell.electrolyte.icm_pcm_description}
                          >
                            <span>{cell.electrolyte.icm_pcm}</span>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        GDE ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.gde.icm_pcm ? (
                          <Tooltip arrow title={cell.gde.icm_pcm_description}>
                            <span>{cell.gde.icm_pcm}</span>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary">OEE ID</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {cell.oee.icm_pcm ? (
                        <Tooltip arrow title={cell.oee.icm_pcm_description}>
                          <span>
                            <Typography>{cell.oee.icm_pcm}</Typography>
                          </span>
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

type TestInfrastructureProps = {
  cell: Cell | CellSingle;
};

const TestInfrastructure = ({ cell }: TestInfrastructureProps) => {
  const navigate = useNavigate();
  const from = `/cells/${cell.cell_id}`;
  const isTablet = useMediaQuery("(min-width:760px)");

  return (
    <Box px={isTablet ? 6 : 0} my={4}>
      <Paper variant="outlined" square>
        <Box p={isTablet ? 6 : 3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Test Infrastructure</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography color="textSecondary" paragraph>
                    Test File Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph>
                    {cell.single.test_file_name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" paragraph>
                    Tester
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph>{cell.single.tester || "-"}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" paragraph>
                    Cycling Protocol
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {cell.single.cycling_protocol_id ? (
                    <Tooltip
                      arrow
                      title={cell.single.cycling_protocol_description || ""}
                    >
                      <Typography paragraph>
                        {cell.single.cycling_protocol_id}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography paragraph>-</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Channel ID</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {cell.channel ? (
                      <MuiLink
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/infrastructure/channels/${
                              cell.channel!.channel_id
                            }`,
                            {
                              state: {
                                from,
                                stateKey: "ALL",
                              },
                            }
                          )
                        }
                      >
                        {cell.channel.fullname}
                      </MuiLink>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                {isTablet ? <Divider orientation="vertical" flexItem /> : null}
                <Box ml={isTablet ? 5 : 0} width="100%">
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        Incubator
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.single.incubator || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary" paragraph>
                        POS ID
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.single.position || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary">Test Stand</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.test_stand && cell.test_stand.test_stand_id
                          ? `${cell.test_stand.name}`
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color="textSecondary">
                        Airflow Rate (sccm)
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography paragraph>
                        {cell.single.air_flow_rate !== null
                          ? `${cell.single.air_flow_rate}`
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

type CellMenuProps = {
  cell: Cell | CellSingle;
  stateKey?: keyof typeof cellsSlices;
};

const CellMenu = ({ cell, stateKey = "ALL" }: CellMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  return (
    <ReadyOffMenu
      large
      onEditCondition={() =>
        navigate(
          editConditionPath(
            cell.experiment.exp_id,
            cell.condition.cell_condition_id!
          )
        )
      }
      showTakeOff={cell.backfill === 1}
      disableTakeOff={
        !["P", "N"].includes(cell.status) ||
        cell.cell_type.cell_assembly === FSC_BATTERY_MODULE ||
        cell.module?.module_id !== null
      }
      onTakeOff={() =>
        dispatch(
          cellsSlices[stateKey].takeOff({
            cells: [cell],
            user_id: user?.user_id,
          })
        )
      }
      selected={[cell]}
      onReadyOff={() =>
        dispatch(
          cellsSlices[stateKey].readyOff({
            cells: [cell],
            cancel: false,
            user_id: user?.user_id,
          })
        )
      }
      onCancelReadyOff={() =>
        dispatch(
          cellsSlices[stateKey].readyOff({
            cells: [cell],
            cancel: true,
            user_id: user?.user_id,
          })
        )
      }
      onDiscontinue={() =>
        dispatch(
          cellsSlices[stateKey].discontinueCells({
            cells: [cell],
            cancel: false,
          })
        )
      }
      onCancelDiscontinue={() =>
        dispatch(
          cellsSlices[stateKey].discontinueCells({
            cells: [cell],
            cancel: true,
          })
        )
      }
    />
  );
};

export default CellLayout;
