import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { DateTime } from "luxon";

import ConditionCell from "../../components/forms/ConditionCell";
import { LocalizationProvider } from "@mui/lab";
import AdapterLuxon from "@mui/lab/AdapterLuxon";

type Props = {
  disabled?: boolean;
  wrapInCell?: boolean;
  disablePast?: boolean;
  fieldIndex?: number;
  onBlur?: () => void;
  onChange: (val?: string) => void;
  invalid?: boolean;
  name: string;
  value: string | null;
};

const ConditionDatePicker = forwardRef(
  (
    {
      disabled = false,
      disablePast = true,
      wrapInCell = true,
      fieldIndex,
      onBlur,
      onChange,
      invalid,
      name,
      value,
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const datePicker = (
      <DatePicker
        disabled={disabled}
        disablePast={disablePast}
        onChange={(timeVal: DateTime | null) => {
          // set to midday of selected day in an attempt to avoid timezone issues
          onChange(
            timeVal
              ?.set({ hour: 12, minute: 0 })
              .toUTC()
              .toISO({ suppressMilliseconds: true })
          );
        }}
        value={value || ""}
        InputAdornmentProps={{ style: { margin: 12 } }}
        renderInput={(props) => (
          <TextField
            {...props}
            inputProps={{
              ...props.inputProps,
              placeholder: "mm/dd/yyyy",
              style: { padding: "14px 12px" },
            }}
            InputProps={{
              ...props.InputProps,
              error: invalid,
              disableUnderline: true,
              style: invalid
                ? undefined
                : {
                    border: 0,
                    borderRadius: 0,
                  },
            }}
            name={name}
            onBlur={onBlur}
            ref={ref}
            variant="standard"
          />
        )}
      />
    );
    return wrapInCell ? (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ConditionCell key={fieldIndex || name} style={{ padding: 0 }}>
          {datePicker}
        </ConditionCell>
      </LocalizationProvider>
    ) : (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {datePicker}
      </LocalizationProvider>
    );
  }
);

export default ConditionDatePicker;
