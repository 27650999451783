import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const LayoutHeadline = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 25"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 3.24701C0.75 2.41858 1.42157 1.74701 2.25 1.74701H21.75C22.5784 1.74701 23.25 2.41858 23.25 3.24701V4.74701C23.25 5.57544 22.5784 6.24701 21.75 6.24701H2.25C1.42157 6.24701 0.75 5.57544 0.75 4.74701V3.24701Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 12.247C0.75 11.4186 1.42157 10.747 2.25 10.747H21.75C22.5784 10.747 23.25 11.4186 23.25 12.247V13.747C23.25 14.5754 22.5784 15.247 21.75 15.247H2.25C1.42157 15.247 0.75 14.5754 0.75 13.747V12.247Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 21.247C0.75 20.4186 1.42157 19.747 2.25 19.747H21.75C22.5784 19.747 23.25 20.4186 23.25 21.247V22.747C23.25 23.5754 22.5784 24.247 21.75 24.247H2.25C1.42157 24.247 0.75 23.5754 0.75 22.747V21.247Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default LayoutHeadline;
