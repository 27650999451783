import MuiTableRow from "@mui/material/TableRow";
import type { TableRowProps as MuiTableRowProps } from "@mui/material/TableRow";

type Props = {
  disabled?: boolean;
} & MuiTableRowProps;

export const BaseTableRow = ({ style, hidden, disabled, ...props }: Props) => (
  <MuiTableRow
    style={
      disabled
        ? {
            opacity: 0.5,
            pointerEvents: "none",
            ...style,
          }
        : style
    }
    {...props}
  />
);

export default BaseTableRow;
