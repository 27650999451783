import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Control, Controller } from "react-hook-form";

import type { RootState } from "../../store";

import colors from "../../theme/colors";

import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import type { SignalMapState } from "./slice";

type Props = {
  index: number;
  control: Control<FaultConditionFormData, object>;
  getValues: any;
  setValue: any;
  allSignalGroups: { [key: string]: number | string }[];
};

const FCVariableInput = ({
  index,
  control,
  getValues,
  setValue,
  allSignalGroups,
}: Props) => {
  const [isGroupOperand, setIsGroupOperand] = useState<boolean>(false);
  const ALL_VARIABLES = ["x", "y", "z", "a", "b", "c", "m", "n"];
  const { signalMap } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  useMemo(() => {
    // Custom change to isGroupOperand that is
    // triggered by setValue calls
    setIsGroupOperand(getValues(`isGroupOperand${index}`));
    // eslint-disable-next-line
  }, [getValues(`isGroupOperand${index}`)]);

  return (
    <>
      <Box style={{ width: "100%" }} display="flex" mt={5}>
        <Box style={{ width: "30%", textAlign: "left" }}>
          <Typography className="small" color="textSecondary">
            Variable<span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          <FormControl style={{ width: "100%" }}>
            <Controller
              control={control}
              name={`variableString${index}` as keyof FaultConditionFormData}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Select value={value} error={invalid} onChange={onChange}>
                  {ALL_VARIABLES.map((v, i) => (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <Box style={{ width: "25%" }} ml={5}>
          <Typography className="small" color="textSecondary">
            Signal Group Operand?
          </Typography>

          <FormControl style={{ width: "100%" }}>
            <Controller
              control={control}
              name={`isGroupOperand${index}` as keyof FaultConditionFormData}
              rules={{ required: false }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid },
              }) => (
                <Box alignItems="left">
                  <Checkbox
                    color="secondary"
                    id={`isGroupOperand${index}`}
                    checked={!!value}
                    style={{ paddingLeft: "0" }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setIsGroupOperand(!value);
                      setValue(`signal_id_${index}`, "");
                      setValue(`signal_group_id_${index}`, "");
                      onChange(e);
                    }}
                  />
                </Box>
              )}
            />
          </FormControl>
        </Box>

        <Box style={{ width: "45%" }}>
          <Typography className="small" color="textSecondary">
            {isGroupOperand ? "Signal Group" : "Signal Name"}
            <span style={{ color: colors.accent.red }}>*</span>
          </Typography>

          {isGroupOperand ? (
            <FormControl style={{ width: "100%" }}>
              <Controller
                control={control}
                name={
                  `signal_group_id_${index}` as keyof FaultConditionFormData
                }
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <Select value={value} error={invalid} onChange={onChange}>
                    {allSignalGroups.map((sg, i) => (
                      <MenuItem key={i} value={sg.signal_group_id}>
                        {sg.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          ) : (
            <FormControl style={{ width: "100%" }}>
              <Controller
                control={control}
                name={`signal_id_${index}` as keyof FaultConditionFormData}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <Select value={value} error={invalid} onChange={onChange}>
                    {signalMap?.signals.map((signal, i) => (
                      <MenuItem key={i} value={signal.signal_id}>
                        {signal.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FCVariableInput;
