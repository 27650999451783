import { useDispatch, useSelector } from "react-redux";

import colors from "../../theme/colors";
import FaultConditionModifiers from "./FaultConditionModifiers";
import SearchBarIcon from "../../icons/SearchBar";
import VirtualTable from "../../components/table/VirtualTable";

import {
  Box,
  Checkbox,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import type { RootState } from "../../store";
import {
  vhToPx,
  getVirtualTableHeight,
  DATA_ROW_HEIGHT,
  EMPTY_STATE_ROW_HEIGHT,
} from "../../utils/ui";
import { FAULT_CONDITION_LABELS_LIST } from "../../utils/labels";
import { shiftClickList } from "../../utils/shiftClick";
import {
  selectFaultConditions,
  deselectFaultConditions,
  selectAllFaultConditions,
  deselectAllFaultConditions,
  sortFaultConditions,
} from "./slice";
import type { SignalMapState } from "./slice";

const FaultConditionsListView = () => {
  const dispatch = useDispatch();
  const {
    signalMap,
    faultConditions,
    selected: { faultConditions: selected },
    order: { faultCondition: order },
    orderBy: { faultCondition: orderBy },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(deselectAllFaultConditions());
    } else {
      dispatch(selectAllFaultConditions());
    }
  };

  const handleSelectFaultConditions = (ids: number[], shiftClick = false) => {
    const allSelected = !ids.some((_id) => !selected.includes(_id));
    if (allSelected) {
      dispatch(deselectFaultConditions(ids));
    } else {
      let idsToSelect = shiftClickList(
        selected,
        ids,
        faultConditions,
        shiftClick
      );
      dispatch(selectFaultConditions(idsToSelect));
    }
  };

  const handleSortClick = (
    e: React.MouseEvent,
    property: FalconKey<FaultCondition>
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      sortFaultConditions({
        order: switching ? "desc" : "asc",
        key: property,
      })
    );
  };

  let tableBody: (JSX.Element | null)[] =
    signalMap && faultConditions.length > 0
      ? faultConditions.map((fc, index) => (
          <TableRow
            key={index}
            hover
            className={`clickable ${index % 2 === 0 ? "" : "alternating"} ${
              selected.includes(fc.fault_condition_id) ? "checked" : ""
            }`}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="secondary"
                checked={selected.includes(fc.fault_condition_id)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleSelectFaultConditions(
                    [fc.fault_condition_id],
                    (e.nativeEvent as MouseEvent).shiftKey
                  )
                }
              />
            </TableCell>

            {FAULT_CONDITION_LABELS_LIST.map(({ id }, i) => (
              <TableCell key={i} scope="row">
                {fc[id as keyof FaultCondition]}
              </TableCell>
            ))}
          </TableRow>
        ))
      : [
          <TableRow key={0}>
            <TableCell
              colSpan={FAULT_CONDITION_LABELS_LIST.length + 2}
              style={{ textAlign: "center", borderBottom: 0 }}
            >
              <Box p={10} style={{ color: colors.text.secondary }}>
                <SearchBarIcon style={{ width: 48, height: 48 }} />
                <p>No fault conditions to show</p>
              </Box>
            </TableCell>
          </TableRow>,
        ];
  let rowHeights: number[] =
    signalMap && faultConditions.length > 0
      ? Array(faultConditions.length).fill(DATA_ROW_HEIGHT)
      : [EMPTY_STATE_ROW_HEIGHT];

  return (
    <>
      <Paper sx={{ boxShadow: "none" }} square>
        <Box display="flex" alignItems="center" justifyContent="right">
          {signalMap && <FaultConditionModifiers />}
        </Box>

        <VirtualTable
          height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220)}
          width="100%"
          itemCount={tableBody.length}
          itemSize={(index) => rowHeights[index]}
          header={
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    indeterminate={selected.length > 0}
                    checked={false}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleToggleSelectAll}
                  />
                </TableCell>

                {FAULT_CONDITION_LABELS_LIST.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(e) => handleSortClick(e, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          row={({ index }) => tableBody[index] || null}
        />
      </Paper>
    </>
  );
};

export default FaultConditionsListView;
