import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useChannelCellStyles } from "./ReserveChannelsModal";
import colors from "../../theme/colors";
import { ModifyTeardownTypesTableProps } from "./ModifyTeardownTypesTable";
import ModifyTeardownFlagCell from "./ModifyTeardownFlagCell";
import { TEARDOWN_FLAG_LABELS } from "../../utils/labels";

// when pressed, helper key allows copying of value in cell across table row.
const HELPER_KEY = "Meta";

type ModifyTeardownFlagsConditionInfo = {
  teardownTypeByCellId: { [key: string]: string };
  teardown_flags: { [key: string]: any };
  cellCondition: Condition;
  component_meta_modes: string[];
  cell_statuses_for_condition: string[];
  teardown_condition_component_fields: ConditionUIField[];
};

const ModifyTeardownFlagsTable = ({
  teardownSpecsByCondition,
  teardownFlagsToUpdate,
  handleChangeTeardownFlags,
}: ModifyTeardownTypesTableProps) => {
  const [helperKeyPressed, setHelperKeyPressed] = useState(false);
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === HELPER_KEY) {
      setHelperKeyPressed(true);
    }
  }, []);
  const onKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === HELPER_KEY) {
      setHelperKeyPressed(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  const classes = useChannelCellStyles();
  const teardownFlags = Object.keys(
    teardownSpecsByCondition[Object.keys(teardownSpecsByCondition!)[0]]
      .teardown_flags
  );
  const cellConditionIdsToDisable = Object.keys(
    teardownSpecsByCondition
  ).filter((cellConditionId) =>
    teardownSpecsByCondition[cellConditionId].cell_statuses_for_condition.some(
      (cellStatus) => ["F", "O"].includes(cellStatus)
    )
  );
  return (
    <Table
      className={`dataTable ${classes.root}`}
      size="small"
      style={{ background: colors.striping, width: "auto" }}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ background: "white" }} />
          {Object.keys(teardownSpecsByCondition).map(
            (str_cell_condition_id, index) => (
              <Tooltip
                arrow
                placement="left"
                title={`Flag modifications disabled because another cell in this condition is either off-test or torn down. To make changes to teardown specs, submit a change request.`}
                disableHoverListener={
                  !cellConditionIdsToDisable.includes(str_cell_condition_id)
                }
                disableFocusListener={
                  !cellConditionIdsToDisable.includes(str_cell_condition_id)
                }
                key={`${str_cell_condition_id}-header`}
              >
                <TableCell
                  style={{
                    background: "white",
                    width: "260px",
                    maxWidth: "260px",
                    minWidth: "224px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: cellConditionIdsToDisable.includes(
                      str_cell_condition_id
                    )
                      ? colors.accent.red
                      : undefined,
                  }}
                >
                  {
                    teardownSpecsByCondition[str_cell_condition_id]
                      .cellCondition.name
                  }
                </TableCell>
              </Tooltip>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {teardownFlags.map((flag_: string) => {
          const conditionIdWithFlagInfo = Object.keys(
            teardownSpecsByCondition
          ).find((conditionId_) => {
            return teardownSpecsByCondition[
              conditionId_
            ].teardown_condition_component_fields.some(
              (field_) => field_.id === flag_
            );
          });
          let flagInfo =
            (conditionIdWithFlagInfo &&
              teardownSpecsByCondition[
                conditionIdWithFlagInfo
              ].teardown_condition_component_fields.find(
                ({ id }) => id === flag_
              )) ||
            TEARDOWN_FLAG_LABELS.find(({ id }) => id === flag_);
          const labelForFlag = flagInfo?.label || `NO LABEL FOR ${flag_}`;

          return (
            <TableRow key={flag_}>
              <TableCell variant="head">{labelForFlag}</TableCell>
              {Object.keys(teardownSpecsByCondition!).map(
                (cellConditionId: string, index) => {
                  const teardownSpecObj: ModifyTeardownFlagsConditionInfo =
                    teardownSpecsByCondition[cellConditionId];
                  const value_ =
                    teardownFlagsToUpdate.hasOwnProperty(cellConditionId) &&
                    teardownFlagsToUpdate[cellConditionId].hasOwnProperty(flag_)
                      ? teardownFlagsToUpdate[cellConditionId][flag_]
                      : teardownSpecObj.teardown_flags[flag_];
                  const type_ = flagInfo?.type || "boolean";
                  let options_ = undefined;
                  if (type_ === "options") {
                    options_ = (flagInfo as ConditionUIField)?.options;
                  }
                  const disabled =
                    cellConditionIdsToDisable.includes(cellConditionId);
                  const handleSingleValueUpdate = (
                    newValue: string | boolean | User | null,
                    persistEmpty = false
                  ) => {
                    handleChangeTeardownFlags(
                      [cellConditionId],
                      flag_,
                      newValue,
                      persistEmpty
                    );
                  };
                  const handleCopyValueAcrossRow = (
                    newValue?: User | string | null,
                    persistEmpty = false
                  ) => {
                    handleChangeTeardownFlags(
                      Object.keys(teardownSpecsByCondition).filter(
                        (cellConditionId_) =>
                          !cellConditionIdsToDisable.includes(cellConditionId_)
                      ),
                      flag_,
                      newValue || value_,
                      persistEmpty
                    );
                  };
                  return (
                    <ModifyTeardownFlagCell
                      flag={flag_}
                      value={value_}
                      options={options_}
                      disabled={disabled}
                      type={type_}
                      handleSingleValueUpdate={handleSingleValueUpdate}
                      handleCopyButtonPress={handleCopyValueAcrossRow}
                      helperKeyPressed={helperKeyPressed}
                      key={`tablecell-${cellConditionId}-${index}`}
                    />
                  );
                }
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ModifyTeardownFlagsTable;
