import { useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactS3Uploader from "react-s3-uploader";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../../components/Button";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import SmallChip from "../../components/SmallChip";
import Toast from "../../components/Toast";
import TimesIcon from "../../icons/Times";
import ArrowBackIcon from "../../icons/ArrowBack";
import colors from "../../theme/colors";
import type { RootState } from "../../store";
import {
  createNote,
  updateNote,
  resetCreateNoteStatus,
  resetUpdateNoteStatus,
} from "./slice";
import type { NotesState } from "./slice";
import NoteAlertDropdown from "./NoteAlertDropdown";
import NoteTagDropdown from "./NoteTagDropdown";
import {
  getNoteLabelForResource,
  getNoteItemLabelForResource,
} from "../../utils/labels";
import client from "../../api";
import TakingPictureIcon from "../../icons/TakingPicture";
import DeletableCard from "../../components/DeletableCard";

type Props = {
  resource: NoteItemRelation["resource"];
  note?: Note;
  options: number[];
  open: boolean;
  onClose: () => void;
};

type FormData = Note & {
  records: { id: number; label: string }[];
  alert: boolean;
};

const NoteFormSidePanel = ({
  resource,
  options,
  note,
  open,
  onClose,
}: Props) => {
  const isTablet = useMediaQuery("(min-width:760px)");
  const label = getNoteLabelForResource(resource);

  const formattedOptions = useMemo(
    () =>
      options.map((id) => ({
        id,
        label: getNoteItemLabelForResource(resource, id),
      })),
    [options, resource]
  );

  const { control, handleSubmit, register, reset } = useForm<FormData>({
    defaultValues: {
      records: formattedOptions,
      description: "",
      images: [],
      note_tags: [],
      note_followers: [],
      alert: false,
      note_id: undefined,
    },
  });

  useEffect(() => {
    if (note) {
      const { description, note_tags, note_followers, images, note_id } = note;
      reset({
        records: formattedOptions,
        description,
        note_tags,
        note_followers,
        images,
        alert: true,
        note_id,
      });
    } else {
      reset();
    }
  }, [note, formattedOptions, reset]);

  // Note state in redux
  const {
    justCreated,
    status: { create: createStatus, update: updateStatus },
    error: { create: createError, update: updateError },
  } = useSelector<RootState, NotesState>(({ notes }) => notes);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(resetCreateNoteStatus());
      dispatch(resetUpdateNoteStatus());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (createStatus === "succeeded") {
      onClose();
    }
  }, [createStatus, onClose]);

  useEffect(() => {
    if (updateStatus === "succeeded") {
      onClose();
    }
  }, [updateStatus, onClose]);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = ({ records, ...newNote }: FormData) => {
    if (newNote.note_id) {
      dispatch(
        updateNote({
          ...newNote,
          note_items: note?.note_items || [],
        })
      );
    } else {
      dispatch(
        createNote({
          ...newNote,
          note_items: records.map(({ id }) => ({
            resource,
            record_id: id,
          })),
        })
      );
    }
  };

  let successToast;
  if (createStatus === "succeeded") {
    successToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetCreateNoteStatus())}
      >
        <Typography className="small">Note created</Typography>
        {justCreated && justCreated.note_followers.length > 0 ? (
          <Typography color="textSecondary" className="small">
            {justCreated?.note_followers.map(({ name }) => name).join(", ")}{" "}
            will be alerted
          </Typography>
        ) : null}
      </Toast>
    );
  }

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop
        open={open}
        onClose={onClose}
        PaperProps={{ style: isTablet ? { width: 400 } : { width: "100%" } }}
      >
        <Box p={6}>
          <Box display="flex" alignItems="center" width="100%">
            {note ? (
              <>
                <Box mr={2}>
                  <IconButton size="small" onClick={onClose}>
                    <ArrowBackIcon style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Box>
                <Typography variant="h3">Edit Note</Typography>
              </>
            ) : (
              <>
                <Typography variant="h3">Log Note</Typography>
                <Box ml="auto">
                  <IconButton size="small" onClick={onClose}>
                    <TimesIcon style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            {note ? (
              <Box mt={6} mb={4} display="flex" alignItems="center">
                <Typography className="small" color="textSecondary">
                  {label}
                  {note.note_items.length !== 1 ? "s" : ""}:
                </Typography>
                <Box ml={2} className="small">
                  {note.note_items
                    .map(({ record_id }) =>
                      getNoteItemLabelForResource(resource, record_id)
                    )
                    .join(", ")}
                </Box>
              </Box>
            ) : (
              <>
                <Box mt={6}>
                  <Typography className="small" color="textSecondary">
                    {label}s<span style={{ color: colors.accent.red }}>*</span>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <FormControl style={{ width: "100%", height: "100%" }}>
                    <Controller
                      control={control}
                      name="records"
                      rules={{
                        validate: (value) =>
                          (value && value.length > 0) ||
                          `Please select a ${label}.`,
                      }}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { invalid },
                      }) => (
                        <Autocomplete
                          ref={ref}
                          multiple
                          options={formattedOptions}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          value={value}
                          onBlur={onBlur}
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              error={invalid}
                            />
                          )}
                          renderOption={(props, opt) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                checked={props["aria-selected"] === true}
                              />
                              <Box py={2} width="100%">
                                {opt?.label}
                              </Box>
                            </li>
                          )}
                          renderTags={(values, getTagProps) =>
                            values.map((value, index) => (
                              <Box key={value?.id} mr={2} mb={1}>
                                <SmallChip
                                  label={value?.label}
                                  {...getTagProps({ index })}
                                />
                              </Box>
                            ))
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </>
            )}
            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                Notes<span style={{ color: colors.accent.red }}>*</span>
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="description"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <Input
                      className="small"
                      disableUnderline
                      inputRef={ref}
                      inputComponent={TextareaAutosize}
                      inputProps={{ minRows: 3 }}
                      error={invalid}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                Tags
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="note_tags"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <NoteTagDropdown
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={invalid}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                {note ? "Followers:" : "Alert the following:"}
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="note_followers"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <NoteAlertDropdown
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      error={invalid}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {note ? (
              <Box mt={2}>
                <Controller
                  control={control}
                  name="alert"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          ref={ref}
                          name={name}
                          color="secondary"
                          checked={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label={
                        <Typography className="small">
                          Alert followers of changes
                        </Typography>
                      }
                    />
                  )}
                />
              </Box>
            ) : null}
            <Box mt={8}>
              <Controller
                control={control}
                name="images"
                render={({ field: { value, onChange, ref } }) => (
                  <Box
                    ref={ref}
                    mb={4}
                    display={!value?.length ? "none" : "flex"}
                    flexWrap="wrap"
                  >
                    {value.map(
                      ({ publicUrl }: FormData["images"][0], index: number) => (
                        <Box key={index} mr={4} mb={2}>
                          <DeletableCard
                            onDelete={() => {
                              const newImages = [...value];
                              newImages.splice(index, 1);
                              onChange(newImages);
                            }}
                          >
                            <CardMedia
                              sx={{ width: "6rem", height: "6rem" }}
                              image={publicUrl}
                            />
                          </DeletableCard>
                        </Box>
                      )
                    )}
                    <ReactS3Uploader
                      multiple
                      style={{ display: "none" }}
                      accept="image/*"
                      getSignedUrl={client.presignS3Upload}
                      preprocess={(file, next) => {
                        setUploading(true);
                        next(file);
                      }}
                      onProgress={(percent) => setProgress(percent)}
                      onError={(message) => setUploadError(message)}
                      onFinish={(response) => {
                        if (uploadRef.current) {
                          uploadRef.current.value = "";
                        }
                        setUploadError(null);
                        setUploading(false);
                        if (value) {
                          onChange([...value, response]);
                        } else {
                          onChange([response]);
                        }
                      }}
                      inputRef={(input) => (uploadRef.current = input)}
                      uploadRequestHeaders={{}}
                    />
                  </Box>
                )}
              />

              <Box display="flex" alignItems="center">
                <Button
                  color="tertiary"
                  style={{ padding: "0.25rem" }}
                  disabled={uploading}
                  onClick={() => uploadRef.current?.click()}
                  startIcon={
                    <TakingPictureIcon style={{ width: 24, height: 24 }} />
                  }
                >
                  <b>Attach photo</b>
                </Button>
                {uploading ? (
                  <Box ml={3}>
                    <CircularProgressWithLabel value={progress} />
                  </Box>
                ) : null}
              </Box>
            </Box>
            {createError || updateError || uploadError ? (
              <Box mt={6} color={colors.accent.red} className="small">
                {createError}
                {updateError}
                {uploadError}
              </Box>
            ) : null}
            {note ? (
              <input
                type="hidden"
                {...register("note_id")}
                value={note.note_id}
              />
            ) : null}
            <Box
              mt={createError || updateError ? 2 : 6}
              mb={8}
              textAlign="right"
            >
              {note ? (
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={updateStatus === "loading"}
                  style={!isTablet ? { width: "100%" } : undefined}
                  endIcon={
                    updateStatus === "loading" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  Save
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={createStatus === "loading"}
                  style={!isTablet ? { width: "100%" } : undefined}
                  endIcon={
                    createStatus === "loading" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  Submit
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Drawer>
      {successToast}
    </>
  );
};

export default NoteFormSidePanel;
