import { Fragment, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { InView } from "react-intersection-observer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Section from "./Section";
import TableRow from "../../../components/forms/TableRow";
import MultiDefaultsHelperTable from "./MultiDefaultsHelperTable";
import { isNull, isNumber, isEqual, isEmpty } from "lodash";
import { getKeyForItemIndex } from "../../../utils/conditions";
import { matchingDefaultIndexForSubassemblyOfAllConditions } from "../../../utils/multiDefaultsUtils";

export const FIELDS_TO_IGNORE_FOR_DEFAULTS = [
  "engineer_flag",
  "confirmation_message",
  "deviation_types",
  "deviations_from_sop",
  "sop_link",
  "sopdeviation_options",
  "link",
];

type Props = {
  title: ConditionUIStepCategory["title"];
  formFields: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
  uiFields: ConditionUIStepCategory["fields"];
  step: number;
  activeStep: number;
  diffView: boolean;
  editMode: boolean;
  keysToWatchByFullAssembly?: { fieldKey: string; default: any }[];
  defaultIndexForCondition: number | null;
  onInView: (inView: boolean, stepId: number) => void;
  onDuplicate: (id: number) => void;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onChangeDefaultIndexForCondition?: (idx: number | null) => void;
  onChangeConfirmationMessages: (fieldKey: string, val: string | null) => void;
};

const ConnectedSection = ({
  title,
  formFields,
  uiFields,
  step,
  activeStep,
  diffView,
  editMode,
  keysToWatchByFullAssembly,
  defaultIndexForCondition,
  onInView,
  onDuplicate,
  onEdit,
  onDelete,
  onChangeDefaultIndexForCondition,
  onChangeConfirmationMessages,
}: Props) => {
  const onshapeRev = uiFields?.find((uiField) =>
    uiField.id.includes("subassembly_rev")
  );
  const { register, setValue, getValues, control } =
    useFormContext<ConditionRHFormFormat>();
  const [overrideDisabled, setOverrideDisabled] = useState<null | boolean>(
    null
  );
  const [localActiveDefaultIndex, setLocalActiveDefaultIndex] = useState<
    number | null
  >(null);
  const activeDefaultIndex =
    isNumber(defaultIndexForCondition) &&
    !isEmpty(uiFields) &&
    uiFields!.some(
      (uiField_) => !!uiField_.manage_defaults_across_subassemblies
    )
      ? defaultIndexForCondition
      : localActiveDefaultIndex;
  let fieldsToIgnore = [...FIELDS_TO_IGNORE_FOR_DEFAULTS];

  // Only ignore non-standard defaults if the default value is False,
  // or if the default values are all False
  if (
    !!uiFields?.find(
      (uiField) =>
        uiField.id === "non_standard" &&
        (typeof uiField.default === "boolean" ||
          (Array.from(new Set(uiField.default as boolean[])).length === 1 &&
            Array.from(new Set(uiField.default as boolean[]))[0] === false))
    )
  ) {
    fieldsToIgnore.push("non_standard");
  }

  const fieldsWithMultiDefaults = useMemo(() => {
    return uiFields?.filter(
      (uiField) =>
        Array.isArray(uiField.default) &&
        !fieldsToIgnore.includes(uiField.id) &&
        !(uiField.default as any[]).every((defaultVal) => isNull(defaultVal))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiFields]);

  // watch state of "component_used" fields (if present)
  // to determine if MultiDefaultsHelperTable should be shown
  const componentUsedWatchFields = useMemo(() => {
    return formFields
      .filter((formField_) => {
        return (
          !!formField_.items[step] && "component_used" in formField_.items[step]
        );
      })
      .map((formField_) => {
        return getKeyForItemIndex(
          "component_used",
          formFields.findIndex((_formField) => _formField.id === formField_.id),
          formField_.items[step],
          step
        );
      });
  }, [formFields, step]);
  const componentUsedState = useWatch({
    control,
    name: componentUsedWatchFields,
  });
  const multiDefaultFieldsVisible = useMemo(() => {
    if (
      !fieldsWithMultiDefaults ||
      fieldsWithMultiDefaults.length === 0 ||
      !uiFields
    )
      return false;
    if (!componentUsedState || componentUsedState.length === 0) return true;
    return componentUsedState.some(
      (componentUsedIsSelected) => componentUsedIsSelected
    );
  }, [fieldsWithMultiDefaults, componentUsedState, uiFields]);

  useMemo(() => {
    // If this subassembly has multiple defaults and the values of the fields with defaults
    // for all conditions match the same default index, set that default index.
    const matchingDefaultIndex =
      matchingDefaultIndexForSubassemblyOfAllConditions(
        formFields,
        step,
        fieldsWithMultiDefaults
      );
    setLocalActiveDefaultIndex(matchingDefaultIndex);
    // eslint-disable-next-line
  }, [fieldsWithMultiDefaults, formFields]);

  const handleChangeActiveDefaultIndex = (newIndex: number | null) => {
    onChangeDefaultIndexForCondition
      ? onChangeDefaultIndexForCondition(newIndex)
      : setLocalActiveDefaultIndex(newIndex);
  };

  useEffect(() => {
    if (isNumber(activeDefaultIndex) && fieldsWithMultiDefaults!.length > 0) {
      formFields.forEach((formField, formFieldIndex) => {
        const thisField = formField.items[step];
        fieldsWithMultiDefaults!.forEach((field) => {
          const defaults = field.default as any[];
          const key = getKeyForItemIndex(
            field.id,
            formFieldIndex,
            thisField,
            step
          );
          if (!isEqual(getValues(key), defaults[activeDefaultIndex])) {
            setValue(key, defaults[activeDefaultIndex], {
              shouldDirty: true,
            });
          }
        });

        const nonStandardKey = getKeyForItemIndex(
          "non_standard",
          formFieldIndex,
          thisField,
          step
        );
        if (!fieldsToIgnore.includes("non_standard")) {
          // If non-standard varies with defaults,
          // override disabled logic for non-standard field
          setOverrideDisabled(getValues(nonStandardKey));
        } else if (
          !thisField.deviations_from_sop &&
          isEmpty(thisField.deviation_types)
        ) {
          setValue(nonStandardKey, false, {
            shouldDirty: !!thisField.non_standard,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDefaultIndex, setValue, fieldsWithMultiDefaults, formFields, step]);

  return (
    <InView onChange={(inView) => onInView(inView, step)}>
      {({ ref }) => (
        <Section
          ref={ref}
          step={step}
          activeStep={activeStep}
          title={title}
          fields={formFields}
          onDuplicate={onDuplicate}
          onEdit={onEdit}
          onDelete={onDelete}
        >
          {fieldsWithMultiDefaults &&
            fieldsWithMultiDefaults.length > 0 &&
            multiDefaultFieldsVisible && (
              <MultiDefaultsHelperTable
                activeDefaultIndex={activeDefaultIndex}
                onChangeActiveDefaultIndex={handleChangeActiveDefaultIndex}
                fieldsWithMultiDefaults={fieldsWithMultiDefaults}
              />
            )}
          <Table size="small" style={{ width: "auto" }}>
            <TableBody className="small">
              {uiFields?.map((uiField) => {
                let otherUiField;
                if (uiField.id.includes("subassembly_partno")) {
                  otherUiField = onshapeRev;
                } else if (uiField.id.includes("subassembly_rev")) {
                  // Merged above
                  return null;
                }

                return (
                  <TableRow
                    key={uiField.id}
                    fields={formFields}
                    stepId={step}
                    uiField={uiField}
                    otherUiField={otherUiField}
                    onChangeActiveDefaultIndex={handleChangeActiveDefaultIndex}
                    onChangeConfirmationMessages={onChangeConfirmationMessages}
                    allUiFields={uiFields}
                    diffView={diffView}
                    editMode={editMode}
                    keysToWatchByFullAssembly={keysToWatchByFullAssembly}
                    overrideDisabled={
                      uiField.id === "non_standard" ? overrideDisabled : null
                    }
                    activeDefaultIndex={activeDefaultIndex}
                  />
                );
              })}
            </TableBody>
          </Table>
          {formFields.map((field, fieldIndex) => (
            <Fragment key={`hidden-${field.id}`}>
              <input
                {...register(`conditions.${fieldIndex}.items.${step}.api_path`)}
                type="hidden"
                defaultValue={field.items[step].api_path}
              />
              {!isNaN(field.items[step].full_dry_assembly_index) ? (
                <input
                  {...register(
                    `conditions.${fieldIndex}.items.${step}.full_dry_assembly_index`
                  )}
                  type="hidden"
                  defaultValue={field.items[step].full_dry_assembly_index}
                />
              ) : null}
            </Fragment>
          ))}
        </Section>
      )}
    </InView>
  );
};

export default ConnectedSection;
