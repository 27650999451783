import type { FieldArrayWithId, FieldPath } from "react-hook-form";

export const getKeyForItemIndex = (
  key: string,
  index: number,
  item: any,
  itemIndex: number
) => {
  if (Object.keys(item).includes(`on_test__${key}`)) {
    return `conditions.${index}.items.${itemIndex}.on_test__${key}` as FieldPath<ConditionRHFormFormat>;
  }
  if (Object.keys(item).includes(`during_test__${key}`)) {
    return `conditions.${index}.items.${itemIndex}.during_test__${key}` as FieldPath<ConditionRHFormFormat>;
  }
  if (Object.keys(item).includes(`off_test__${key}`)) {
    return `conditions.${index}.items.${itemIndex}.off_test__${key}` as FieldPath<ConditionRHFormFormat>;
  }
  return `conditions.${index}.items.${itemIndex}.${key}` as FieldPath<ConditionRHFormFormat>;
};

export const getExecutorKeysForIndex = (
  fields: FieldArrayWithId<ConditionRHFormFormat, "conditions">[],
  index: number,
  key: string,
  itemIndex?: number
) => {
  const field = fields[index];

  if (itemIndex !== undefined && !isNaN(itemIndex)) {
    return getKeyForItemIndex(key, index, field.items[itemIndex], itemIndex);
  }

  const keys = field.items.map((item: any, itemIndex: number) =>
    getKeyForItemIndex(key, index, item, itemIndex)
  );
  return keys;
};

export const getChangeRequestDisplayValue = (
  raw: string,
  field: string,
  type: ConditionUIField["type"]
) => {
  try {
    const obj = JSON.parse(raw);
    const val: any = obj[field];
    if (val === null) {
      return "blank";
    }
    switch (type) {
      case "number":
        return val as number;
      case "boolean":
      case "bool":
        return Boolean(Number(val));
      case "string":
      case "button":
      case "text":
      case "options":
        return val as string;
      case "sopdeviation_options":
        if ((val as SOPDeviation[])?.length === 0) {
          return null;
        }
        return (val as SOPDeviation[])
          ?.map(({ deviation_type_name }) => deviation_type_name)
          .join(", ");
      case "channel_options":
        return (val as ChannelOption)?.channel_id;
      case "user_options":
        return (val as User)?.name;
      case "icmpcm_options":
        return (val as Material)?.material_id;
      case "icmpcmlot_options":
        return val as string;
      case "cyclingprotocol_options":
        return (val as CyclingProtocol)?.cycling_protocol_id;
      default:
        return JSON.stringify(val);
    }
  } catch (e) {
    return raw;
  }
};

export const getConditionIndexFromKey = (
  conditionKey: FieldPath<ConditionRHFormFormat>
) =>
  conditionKey
    .split(".")
    .find((conditionKeyField) => Number.isInteger(Number(conditionKeyField)));
