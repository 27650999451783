import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const SyncWarning = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 20"
  >
    {children}
    <path
      d="M18.707 4.24513C16.219 1.46459 12.2733 0.507544 8.78792 1.83918C5.30249 3.17082 3.00014 6.51498 3 10.2461V10.9991"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.29297 16.249C7.78084 19.0294 11.7262 19.9865 15.2116 18.6551C18.6969 17.3237 20.9994 13.98 21 10.249V9.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 11.7461L21 9.49609L23.25 11.7461"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 8.74609L3 10.9961L0.75 8.74609"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4.99609V10.9961"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14C11.7929 14 11.625 14.1679 11.625 14.375C11.625 14.5821 11.7929 14.75 12 14.75C12.2071 14.75 12.375 14.5821 12.375 14.375C12.375 14.1679 12.2071 14 12 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SyncWarning;
