import { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import type { ChannelListState } from "./slice";
import type { RootState } from "../../store";
import colors from "../../theme/colors";
import CaretBottomIcon from "../../icons/CaretBottom";

const ChannelSummary = () => {
  const { channels: allChannels, selected } = useSelector<
    RootState,
    ChannelListState
  >(({ channelList }) => channelList);

  const [expanded, setExpanded] = useState(true);

  if (selected.length === 0) {
    return null;
  }

  const header = (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h3" style={{ fontWeight: 600 }}>
        Summary
      </Typography>
      <IconButton
        size="small"
        style={{ padding: 0, color: colors.text.secondary }}
        onClick={() => setExpanded(!expanded)}
      >
        <CaretBottomIcon
          style={!expanded ? { transform: "rotate(-90deg)" } : {}}
        />
      </IconButton>
    </Box>
  );

  const channels = allChannels.filter(({ channel }) =>
    selected.includes(channel.fullname)
  );

  const total = channels.length;

  const empty = channels.filter(
    ({ channel: { status } }) => status === "O"
  ).length;

  const unavailable = channels.filter(
    ({ channel: { status, infra_status } }) =>
      status === "O" && infra_status !== "O"
  ).length;

  const inUse = channels.filter(
    ({ channel: { status }, test_meta }) =>
      status === "I" && !test_meta.ready_off__completed_at
  ).length;

  const takeOff = channels.filter(
    ({ channel: { status }, test_meta }) =>
      status === "I" && !!test_meta.ready_off__completed_at
  ).length;

  const content = (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Total</TableCell>
          <TableCell>Empty</TableCell>
          <TableCell>In Use</TableCell>
          <TableCell>Unavailable</TableCell>
          <TableCell>Take Off</TableCell>
          <TableCell style={{ width: "100%" }} />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Channels / % of total</TableCell>
          <TableCell>{total}</TableCell>
          <TableCell>
            {empty}{" "}
            <span style={{ color: colors.text.secondary }}>
              ({Math.round((empty / total) * 100)}
              %)
            </span>
          </TableCell>
          <TableCell>
            {inUse}{" "}
            <span style={{ color: colors.text.secondary }}>
              ({Math.round((inUse / total) * 100)}
              %)
            </span>
          </TableCell>
          <TableCell>
            {unavailable}{" "}
            <span style={{ color: colors.text.secondary }}>
              ({Math.round((unavailable / total) * 100)}
              %)
            </span>
          </TableCell>
          <TableCell>
            {takeOff}{" "}
            <span style={{ color: colors.text.secondary }}>
              ({Math.round((takeOff / total) * 100)}
              %)
            </span>
          </TableCell>
          <TableCell style={{ width: "100%" }} />
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <>
      <Box mb={6} p={6} style={{ backgroundColor: colors.striping }}>
        {header}
        {expanded ? <Box mt={6}>{content}</Box> : null}
      </Box>
    </>
  );
};

export default ChannelSummary;
