import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { useBeforeunload } from "react-beforeunload";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import NewCondition from "./NewCondition";
import ConnectedSection from "./ConnectedSection";
import Wrapper from "./Wrapper";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Section from "./Section";
import colors from "../../../theme/colors";
import OverviewEdit from "../step-overview/Edit";
import {
  saveConditions,
  duplicateCondition,
  deleteCondition,
  resetDuplicateCondition,
  getConditions,
} from "../slice";
import type { ConditionState } from "../slice";
import type { RootState } from "../../../store";
import { getDirtyValues } from "../../../utils/forms";
import { uiFieldHasDefaults } from "../../../utils/multiDefaultsUtils";
import { isEmpty } from "lodash";
import confirmationMessagesReducer, {
  RESET_CONFIRMATION_MESSAGES,
  RESET_CURRENT_CONFIRMATION_MESSAGE,
  SET_ALL_MESSAGES_CONFIRMED,
  SET_CURRENT_CONFIRMATION_MESSAGE,
  SWITCH_TO_NEXT_CONFIRMATION_MESSAGE,
  UPDATE_CONFIRMATION_MESSAGES,
  initialConfirmMessagesState,
} from "../confirmationMessagesReducer";
import Loading from "../../../layout/Loading";

type Props = {
  exp_id: string;
  newViewInit: boolean;
};

type PartialCellObjCollection = (
  | { cell_id: any }
  | Pick<Cell, "cell_id" | "backfill" | "status">
)[];

const ExistingConditionLayout = ({ exp_id, newViewInit }: Props) => {
  const { cell_condition_id = "" } = useParams();

  const {
    conditions: allConditions,
    status: {
      save: saveStatus,
      copy: copyStatus,
      get: getConditionsStatus,
      create: createConditionStatus,
      save: saveConditionStatus,
      autoCommit: autoCommitStatus,
      specify: specifyStatus,
    },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  const dispatch = useDispatch();

  const [diffView, setDiffView] = useState(false);
  const [newView, setNewView] = useState(newViewInit);
  const [defaultIndexForCondition, setDefaultIndexForCondition] = useState<
    number | null
  >(null);
  const [loadingFullConditionData, setLoadingFullConditionData] =
    useState(false);

  // State for confirmation message(s) handling
  const [confirmationMessagesState, dispatchConfirmationMessagesStateChange] =
    useReducer(confirmationMessagesReducer, initialConfirmMessagesState);
  const {
    confirmationMessagesByKey,
    currentConfirmationMessage,
    confirmationStatus,
  } = confirmationMessagesState;
  const multipleConfirmationMessages =
    Object.keys(confirmationMessagesByKey).length > 1;

  /* Get conditions for the type + assembly selected */
  const requestedCondition = useMemo(() => {
    if (cell_condition_id) {
      return allConditions.find(
        (c) => c.cell_condition_id === parseInt(cell_condition_id || "-1")
      );
    }
  }, [cell_condition_id, allConditions]);

  // Think of 'visibleConditionIds' being modified by user interactions (eg, toggling
  //  between conditions on the condition overview page). 'visibleConditions' listens
  //  to changes in 'visibleConditionIds', as well as changes from responses from the server.
  //  Hooks should therefore generally subscribe to changes in 'visibleConditions', unless
  //  we want to change which conditions are being viewed entirely.
  const [visibleConditionIds, setVisibleConditionIds] = useState<number[]>(
    requestedCondition && requestedCondition.cell_condition_id
      ? [requestedCondition.cell_condition_id]
      : []
  );
  const [visibleConditions, setVisibleConditions] = useState<
    (Condition | ConditionOverviewData)[]
  >([]);

  useEffect(() => {
    if (
      !newView &&
      [
        getConditionsStatus,
        createConditionStatus,
        saveConditionStatus,
        autoCommitStatus,
        specifyStatus,
      ].every((status_) => ["succeeded", "idle"].includes(status_))
    ) {
      if (
        isEmpty(visibleConditionIds) &&
        !!requestedCondition?.cell_condition_id
      ) {
        const conditionToDisplay = allConditions.find(
          (condition_) =>
            condition_.cell_condition_id ===
            requestedCondition.cell_condition_id
        );
        if (conditionToDisplay) {
          setVisibleConditionIds([conditionToDisplay.cell_condition_id!]);
        }
      } else {
        setVisibleConditions(
          allConditions.filter(
            (condition_) =>
              condition_.cell_condition_id &&
              visibleConditionIds.includes(condition_.cell_condition_id)
          )
        );
      }
    }
  }, [
    visibleConditionIds,
    getConditionsStatus,
    createConditionStatus,
    saveConditionStatus,
    autoCommitStatus,
    specifyStatus,
    allConditions,
    newView,
    requestedCondition,
  ]);

  const bypassCommitForVisibleCells = useMemo(
    () =>
      !isEmpty(visibleConditions) &&
      visibleConditions.every((condition_) => !!condition_.bypass_commit),
    [visibleConditions]
  );

  const selectedTemplate = visibleConditions[0]
    ? visibleConditions[0].template_hash
    : allConditions.find(
        (condition_) => !("basic_info" in condition_) || !condition_.basic_info
      )
    ? allConditions.find(
        (condition_) => !("basic_info" in condition_) || !condition_.basic_info
      )!.template_hash
    : "null";

  // Edit mode
  const [editMode, setEditMode] = useState(false);

  // Ref for visible conditions
  const viewState = useRef<{
    template?: string;
    allIds: number[];
    visibleIds: number[];
  }>({
    template: selectedTemplate,
    allIds: allConditions.map((c) => c.cell_condition_id!),
    visibleIds: requestedCondition
      ? [requestedCondition.cell_condition_id!]
      : [],
  });

  useEffect(() => {
    const allConditionsOfType = allConditions.filter(
      (c) => c.template_hash === selectedTemplate
    );

    // Remove deleted IDs
    viewState.current.visibleIds = viewState.current.visibleIds.filter(
      (cell_condition_id) =>
        allConditions.some((ac) => ac.cell_condition_id === cell_condition_id)
    );

    const { template, visibleIds } = viewState.current;

    if (template !== selectedTemplate) {
      // Template changed
      viewState.current.template = selectedTemplate;
      viewState.current.visibleIds = visibleConditionIds;
    } else if (visibleIds.length === 0 && !cell_condition_id) {
      // No IDs are visible. Show all of type.
      // This should align with the backend's logic for which condition to provide
      // full data for in cases when no specific cell_condition_id is requested.
      setVisibleConditionIds([
        Math.max(
          ...allConditionsOfType.map(
            (condition_) => condition_.cell_condition_id!
          )
        ),
      ]);
    }

    // Store new condition IDs
    viewState.current.allIds = allConditions.map((c) => c.cell_condition_id!);
  }, [selectedTemplate, allConditions, visibleConditionIds, cell_condition_id]);

  useEffect(() => {
    viewState.current.visibleIds = visibleConditionIds;
  }, [visibleConditionIds]);

  /* Build assembly layout */
  const assemblyLayout = useMemo<ConditionSidebarAssemblyLayout>(() => {
    const layout: ConditionSidebarAssemblyLayout["items"] = {};

    allConditions.forEach(
      ({
        cell_type,
        cell_assembly,
        template_hash,
        template_version,
        name,
        cell_condition_id,
      }) => {
        if (!cell_condition_id) {
          return;
        }

        if (!layout[template_hash!]) {
          layout[template_hash!] = {
            title: `${cell_type} - ${cell_assembly}`,
            version: template_version!,
            conditions: [],
          };
        }
        layout[template_hash!].conditions.push({
          name,
          cell_condition_id,
          active: visibleConditions.some(
            (c) => !newView && c.cell_condition_id === cell_condition_id
          ),
        });
      }
    );

    return { items: layout };
  }, [visibleConditions, allConditions, newView]);

  /* Sidebar layout */
  const sidebarLayout = useMemo(() => {
    if (!visibleConditions || visibleConditions.length === 0) {
      return undefined;
    }

    let index = 0;
    const items =
      "items" in visibleConditions[0]
        ? visibleConditions[0].items.map((item) => ({
            title: item.title,
            step: item.items ? -1 : index++,
            children: item.items?.map((item) => ({
              title: item.title,
              step: index++,
            })),
          }))
        : [];

    return { items: [{ title: "Overview", step: -1 }, ...items] };
  }, [visibleConditions]);

  /* UI scrolling management */
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const visibleSteps = useRef<number[]>([]);
  const [step, setStep] = useState(-1);

  const handleConfirmationMessagesChange = (
    fieldKey: string,
    value: string | null
  ) => {
    dispatchConfirmationMessagesStateChange({
      type: UPDATE_CONFIRMATION_MESSAGES,
      payload: { value, fieldKey },
    });
  };

  const handleInView = useCallback((inView: boolean, step: number) => {
    const { current } = visibleSteps;
    const index = current.indexOf(step);
    let newSteps = [...current];
    if (!inView) {
      if (index > -1) {
        newSteps.splice(index, 1);
        setStep(newSteps.sort((a, b) => a - b)[0]);
        visibleSteps.current = newSteps;
      }
    } else if (index === -1) {
      newSteps.push(step);
      setStep(newSteps.sort((a, b) => a - b)[0]);
      visibleSteps.current = newSteps;
    }
  }, []);

  /* Get condition state values and defaults in react-hook-form format */
  const defaultValues = useMemo(() => {
    const flattenedItems = visibleConditions.map(
      ({ items, cells, ...condition }) => {
        return {
          ...condition,
          cells,
          backfill: (cells as PartialCellObjCollection).some(
            (cell_) => "backfill" in cell_ && cell_.backfill === 1
          ),
          items: items
            ? items.flatMap((item) =>
                item.fields ? [item as ConditionUIStep] : item.items!
              )
            : [],
        };
      }
    );
    return flattenedItems.map(({ items, ...condition }) => ({
      ...condition,
      items: items.map(
        ({
          values,
          api_path,
          test_condition_id,
          condition_component_id,
          entity_type,
        }) => ({
          api_path,
          full_dry_assembly_index: items.findIndex(
            ({ title }) => title === "Full Dry Assembly"
          ),
          test_condition_id,
          condition_component_id,
          entity_type,
          ...values,
        })
      ),
    }));
  }, [visibleConditions]);

  /* Form management */
  const formMethods = useForm<ConditionRHFormFormat>({
    mode: "onBlur",
    defaultValues: { conditions: defaultValues },
  });

  const { control, register, reset, getValues, formState } = formMethods;

  const { fields, remove } = useFieldArray({
    control,
    name: "conditions",
  });

  const resetWithConditions = useCallback(
    (conditions: (Condition | ConditionOverviewData)[]) => {
      const flattenedItems = conditions.map(
        ({ items, cells, ...condition }) => ({
          ...condition,
          cells,
          backfill: (cells as PartialCellObjCollection).some(
            (cell_) => "backfill" in cell_ && cell_.backfill === 1
          ),
          items: items
            ? items.flatMap((item) =>
                item.fields ? [item as ConditionUIStep] : item.items!
              )
            : [],
        })
      );

      const newValues = flattenedItems.map(({ items, ...condition }) => ({
        ...condition,
        items: items.map(
          ({
            values,
            api_path,
            test_condition_id,
            condition_component_id,
            entity_type,
          }) => ({
            api_path,
            full_dry_assembly_index: items.findIndex(
              ({ title }) => title === "Full Dry Assembly"
            ),
            test_condition_id,
            condition_component_id,
            entity_type,
            ...values,
          })
        ),
      }));

      reset({ conditions: newValues });
    },
    [reset]
  );

  useEffect(
    () => resetWithConditions(visibleConditions),
    [visibleConditions, resetWithConditions]
  );

  /* Warn when closing tab */
  useBeforeunload((e) => {
    if (formState.isDirty) {
      e.preventDefault();
    }
  });

  const [togglingConditionId, setTogglingConditionId] = useState<number | null>(
    null
  );
  /* Toggling conditions visible */
  const toggleCondition = useCallback(
    async (cell_condition_id: number, bypassDirty = false) => {
      const condition = allConditions.find(
        (c) => c.cell_condition_id === cell_condition_id
      );
      if (!condition) {
        return;
      }
      let hiddenConditionIndex: number | null = null;

      // We are keeping the same types/assemblies
      if (!newView && condition.template_hash === selectedTemplate) {
        const index = visibleConditions.findIndex(
          (condition_) => condition_.cell_condition_id! === cell_condition_id
        );

        // The form is dirty, and we're not bypassing
        if (formState.isDirty && !bypassDirty) {
          setTogglingConditionId(cell_condition_id);
          return;
        }

        if (index === -1) {
          hiddenConditionIndex = allConditions.findIndex(
            (c) => c.cell_condition_id === cell_condition_id
          );
          if (hiddenConditionIndex === -1) {
            // We are adding a new condition of the same type.
            const newConditionIds = allConditions
              .filter(
                (ac) =>
                  visibleConditions.some(
                    (c) => ac.cell_condition_id === c.cell_condition_id
                  ) || ac.cell_condition_id === cell_condition_id
              )
              .map((cc_) => cc_.cell_condition_id!);
            remove();
            setVisibleConditionIds(newConditionIds);
            setEditMode(false);
            setTogglingConditionId(null);
            return;
          }
          if ("basic_info" in condition && condition.basic_info) {
            // load conditions that we don't have full data for from backend.
            await dispatch(
              getConditions({
                exp_id: parseInt(exp_id),
                condition_ids_requested: [condition.cell_condition_id],
                greedyLoad: true,
              })
            );
          }
          setVisibleConditionIds([...visibleConditionIds, cell_condition_id]);
          return;
        }

        if (
          visibleConditions.length === 1 &&
          visibleConditions[0].cell_condition_id === cell_condition_id
        ) {
          // We are trying to hide the last condition
          alert("Cannot remove the last condition.");
          return;
        }

        if (
          visibleConditions.some(
            (cc_) => cc_.cell_condition_id === cell_condition_id
          )
        ) {
          // We are hiding a condition of the same type
          const newConditionIds = allConditions
            .filter(
              (ac) =>
                visibleConditions.some(
                  (c) => ac.cell_condition_id === c.cell_condition_id
                ) && ac.cell_condition_id !== cell_condition_id
            )
            .map((cc_) => cc_.cell_condition_id!);

          remove();
          setVisibleConditionIds(newConditionIds);
          setEditMode(false);
          setTogglingConditionId(null);
          return;
        }
      }

      // We are changing types/assemblies
      if (!newView && formState.isDirty && !bypassDirty) {
        setTogglingConditionId(cell_condition_id);
        return;
      }

      // We are exiting out of the new view without having created a new condition
      if (newView) {
        setNewView(false);
      }
      if ("basic_info" in condition && condition.basic_info) {
        // load conditions that we don't have full data for from backend.
        setLoadingFullConditionData(true);
        await dispatch(
          getConditions({
            exp_id: parseInt(exp_id),
            condition_ids_requested: [condition.cell_condition_id],
            greedyLoad: true,
          })
        );
        setLoadingFullConditionData(false);
      }
      remove();
      setVisibleConditionIds([condition.cell_condition_id!]);
      setEditMode(false);
      setTogglingConditionId(null);
    },
    [
      formState,
      newView,
      selectedTemplate,
      allConditions,
      visibleConditionIds,
      visibleConditions,
      remove,
      dispatch,
      exp_id,
    ]
  );

  const steps: ConditionUIStep[] = useMemo(
    () =>
      visibleConditions.length > 0 && "items" in visibleConditions[0]
        ? visibleConditions[0]?.items!.flatMap((item) =>
            item.fields ? [item as ConditionUIStep] : item.items!
          )
        : [],
    [visibleConditions]
  );
  const [duplicatingConditionId, setDuplicatingConditionId] = useState<
    number | null
  >(null);
  const [deletingConditionId, setDeletingConditionId] = useState<number | null>(
    null
  );
  const [editingConditionId, setEditingConditionId] = useState<number | null>(
    null
  );
  const [stagedCellsWarning, setStagedCellsWarning] = useState<{
    labels: string[];
    callback?: () => void;
  }>({ labels: [] });

  const onAdd = useCallback(
    (bypassDirty = false) => {
      if (newView) {
        setTogglingConditionId(null);
        return;
      }

      if (formState.isDirty && !bypassDirty) {
        setTogglingConditionId(-1);
        return;
      }

      setTogglingConditionId(null);
      remove();
      setStep(-1);
      setNewView(true);
    },
    [formState, newView, remove]
  );

  const onDuplicate = useCallback(
    (id: number, bypassDirty = false) => {
      if (formState.isDirty && !bypassDirty) {
        setDuplicatingConditionId(id);
        return;
      }

      setDuplicatingConditionId(null);
      dispatch(duplicateCondition(id));
    },
    [formState, dispatch]
  );

  useEffect(() => {
    if (copyStatus === "succeeded") {
      toggleCondition(
        allConditions[allConditions.length - 1].cell_condition_id!
      );
      dispatch(resetDuplicateCondition());
    }
  }, [copyStatus, allConditions, dispatch, toggleCondition]);

  const onEdit = useCallback(
    (id: number, bypassDirty = false) => {
      if (formState.isDirty && !bypassDirty) {
        setEditingConditionId(id);
        return;
      }

      setEditingConditionId(null);
      setEditMode(true);
      setVisibleConditionIds(
        allConditions
          .filter(({ cell_condition_id }) => cell_condition_id! === id!)
          .map(({ cell_condition_id }) => cell_condition_id!)
      );
    },
    [formState, allConditions]
  );

  const onCancelEdit = useCallback(() => {
    setEditMode(false);
    resetWithConditions(visibleConditions);
  }, [visibleConditions, resetWithConditions]);

  const onDelete = useCallback(
    (id: number, bypassDirty = false) => {
      if (formState.isDirty && !bypassDirty) {
        setDeletingConditionId(id);
        return;
      }

      setDeletingConditionId(null);
      dispatch(deleteCondition(id));
    },
    [formState, dispatch]
  );

  const onSave = useCallback(
    async (callback?: () => void, bypassStaged?: boolean) => {
      const values = getValues();
      const dirtyValues = getDirtyValues(formState.dirtyFields, values);
      const flatConditions = Object.keys(values.conditions)
        .filter(
          (key) =>
            dirtyValues.conditions && dirtyValues.conditions[key] !== undefined
        )
        .flatMap((key: string) => dirtyValues.conditions[key]);

      if (!bypassStaged && dirtyValues.conditions) {
        const dirtyStagedKeys = Object.keys(dirtyValues.conditions).filter(
          (key: string) => values.conditions[parseInt(key)].status === "T"
        );

        const labels = dirtyStagedKeys.map(
          (key) => values.conditions[parseInt(key)].name
        );

        if (labels.length > 0) {
          setStagedCellsWarning({
            labels,
            callback,
          });
          return;
        }

        if (Object.keys(confirmationMessagesByKey).length > 0) {
          dispatchConfirmationMessagesStateChange({
            type: SET_CURRENT_CONFIRMATION_MESSAGE,
          });
          return;
        }
      }

      await dispatch(
        saveConditions({
          conditions: flatConditions,
          exp_id,
          otherVisibleConditionIds: visibleConditionIds.filter(
            (cellConditionId) =>
              !flatConditions
                .map((cellCondition) => cellCondition.cell_condition_id)
                .includes(cellConditionId)
          ),
        })
      );

      if (typeof callback === "function") {
        callback();
      }

      setStagedCellsWarning({ labels: [] });
      setEditMode(false);
    },
    [
      dispatch,
      exp_id,
      getValues,
      formState,
      confirmationMessagesByKey,
      visibleConditionIds,
    ]
  );

  useEffect(() => {
    if (confirmationStatus === "completed") {
      onSave(() =>
        dispatchConfirmationMessagesStateChange({
          type: RESET_CONFIRMATION_MESSAGES,
        })
      );
    }
  }, [confirmationStatus, onSave]);

  const handleConfirmClick = () => {
    const type = multipleConfirmationMessages
      ? SWITCH_TO_NEXT_CONFIRMATION_MESSAGE
      : SET_ALL_MESSAGES_CONFIRMED;
    dispatchConfirmationMessagesStateChange({ type });
  };

  const onViewDifferences = useCallback(
    () => setDiffView(!diffView),
    [diffView]
  );

  const keysToWatchByFullAssembly = useMemo(() => {
    const dryBuildSteps = steps.filter(
      ({ build_stage, title }) =>
        build_stage === "Dry Build" && title !== "Full Dry Assembly"
    );

    const keysWithDefaults = dryBuildSteps.flatMap(({ fields }, stepId) =>
      fields.filter(uiFieldHasDefaults).map((uiField) => ({
        fieldKey: `items.${stepId}.${uiField.id}`,
        default: uiField.id.includes("non_standard") ? false : uiField.default,
      }))
    );

    return keysWithDefaults;
  }, [steps]);

  if (loadingFullConditionData) {
    return <Loading fullscreen light />;
  }

  return (
    <FormProvider {...formMethods}>
      <Wrapper
        rootRef={rootRef}
        onAdd={onAdd}
        step={step}
        started={true}
        onSave={onSave}
        saveVisible={!newView}
        specifyVisible={!newView}
        bypassCommit={bypassCommitForVisibleCells}
        diffView={diffView}
        diffViewVisible={!newView && visibleConditionIds.length > 1}
        editMode={editMode}
        onViewDifferences={onViewDifferences}
        assemblyLayout={assemblyLayout}
        onToggleCondition={toggleCondition}
        onCancelEdit={onCancelEdit}
        sidebarLayout={sidebarLayout}
        fields={fields}
        confirmationMessageStatus={confirmationStatus}
      >
        {rootRef.current ? (
          newView ? (
            <NewCondition
              exp_id={exp_id}
              onNext={() => {
                setVisibleConditionIds([]);
                setNewView(false);
              }}
            />
          ) : (
            <Box
              pb={6}
              position="relative"
              style={{
                background: colors.body.light,
                minWidth: 254 + 236 * fields.length,
              }}
            >
              <form>
                <InView
                  key={-1}
                  onChange={(inView) => handleInView(inView, -1)}
                >
                  {({ ref }) => (
                    <Section
                      ref={ref}
                      step={-1}
                      activeStep={step}
                      title="Overview"
                      fields={fields}
                      onDuplicate={onDuplicate}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    >
                      <OverviewEdit editMode={editMode} fields={fields} />
                    </Section>
                  )}
                </InView>
                {steps!.map(({ title, fields: uiFields }, stepId) => (
                  <ConnectedSection
                    key={stepId}
                    title={title}
                    formFields={fields}
                    uiFields={uiFields}
                    step={stepId}
                    activeStep={step}
                    diffView={diffView && visibleConditionIds.length > 1}
                    keysToWatchByFullAssembly={
                      title === "Full Dry Assembly"
                        ? keysToWatchByFullAssembly
                        : undefined
                    }
                    editMode={editMode}
                    defaultIndexForCondition={defaultIndexForCondition}
                    onInView={handleInView}
                    onDuplicate={onDuplicate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onChangeDefaultIndexForCondition={
                      uiFields.some(
                        (_uiField) =>
                          _uiField.manage_defaults_across_subassemblies
                      )
                        ? (newIndex) => setDefaultIndexForCondition(newIndex)
                        : undefined
                    }
                    onChangeConfirmationMessages={
                      handleConfirmationMessagesChange
                    }
                  />
                ))}
                {fields.map((field, fieldIndex) => (
                  <Fragment key={`hidden-${field.id}`}>
                    <input
                      {...register(
                        `conditions.${fieldIndex}.cell_condition_id`
                      )}
                      type="hidden"
                      defaultValue={field.cell_condition_id}
                    />
                    <input
                      {...register(`conditions.${fieldIndex}.status`)}
                      type="hidden"
                      defaultValue={field.status}
                    />
                    <input
                      {...register(`conditions.${fieldIndex}.min_replicates`)}
                      type="hidden"
                      defaultValue={field.min_replicates}
                    />
                  </Fragment>
                ))}
                <div style={{ height: "40vh" }} />
              </form>
            </Box>
          )
        ) : null}
      </Wrapper>
      <Modal
        open={togglingConditionId !== null && togglingConditionId !== -1}
        onClose={() => setTogglingConditionId(null)}
      >
        <>
          <Typography variant="h2">Save specifications ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved specifications for the conditions currently
              visible. To preserve your specifications, please save before
              toggling visible conditions or navigating to a different cell
              assembly name.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                onClick={() => toggleCondition(togglingConditionId!, true)}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              onClick={() =>
                onSave(() => toggleCondition(togglingConditionId!, true))
              }
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={togglingConditionId === -1}
        onClose={() => setTogglingConditionId(null)}
      >
        <>
          <Typography variant="h2">Save specifications ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved specifications for the conditions currently
              visible. To preserve your specifications, please save before
              creating a new condition.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() => onAdd(true)}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={() => onSave(() => onAdd(true))}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={duplicatingConditionId !== null}
        onClose={() => setDuplicatingConditionId(null)}
      >
        <>
          <Typography variant="h2">Save specifications ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved specifications for the conditions currently
              visible. To preserve your specifications, please save before
              duplicating a condition.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() => onDuplicate(duplicatingConditionId!, true)}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={() =>
                onSave(() => onDuplicate(duplicatingConditionId!, true))
              }
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={deletingConditionId !== null}
        onClose={() => setDeletingConditionId(null)}
      >
        <>
          <Typography variant="h2">Save specifications ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved specifications for the conditions currently
              visible. To preserve your specifications, please save before
              deleting a condition.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() => onDelete(deletingConditionId!, true)}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={() => onSave(() => onDelete(deletingConditionId!, true))}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={editingConditionId !== null}
        onClose={() => setEditingConditionId(null)}
      >
        <>
          <Typography variant="h2">Save specifications ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved specifications for the conditions currently
              visible. To preserve your specifications, please save before
              editing a commmitted condition.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() => onEdit(editingConditionId!, true)}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={() => onSave(() => onEdit(editingConditionId!, true))}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={stagedCellsWarning.labels.length > 0}
        onClose={() => setStagedCellsWarning({ labels: [] })}
      >
        <>
          <Typography variant="h2">
            Save changes for staged conditions?
          </Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              The condition{stagedCellsWarning.labels.length > 1 ? "s" : ""}{" "}
              {stagedCellsWarning.labels
                .map((label) => `"${label}"`)
                .join(", ")}{" "}
              {stagedCellsWarning.labels.length > 1 ? "have" : "has"} been
              staged. Are you sure you want to save changes?
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() => {
                  if (typeof stagedCellsWarning.callback === "function") {
                    stagedCellsWarning.callback();
                  } else {
                    resetWithConditions(visibleConditions);
                  }
                  setStagedCellsWarning({ labels: [] });
                }}
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={() => onSave(stagedCellsWarning.callback, true)}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal open={!!currentConfirmationMessage}>
        <>
          <Typography variant="h2">Warning: Confirm before saving</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              {currentConfirmationMessage}
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={4}>
              <Button
                color="secondary"
                type="button"
                onClick={() =>
                  dispatchConfirmationMessagesStateChange({
                    type: RESET_CURRENT_CONFIRMATION_MESSAGE,
                  })
                }
              >
                Don't save
              </Button>
            </Box>
            <Button
              color="primary"
              type="button"
              onClick={handleConfirmClick}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              {multipleConfirmationMessages ? "Next" : "Save"}
            </Button>
          </Box>
        </>
      </Modal>
    </FormProvider>
  );
};

export default ExistingConditionLayout;
