import type { FieldArrayWithId } from "react-hook-form";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import colors from "../../../theme/colors";
import Modal from "../../../components/Modal";
import { cellIdToString } from "../../../utils/labels";
import isNull from "lodash/isNull";
import { Fragment, useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  formField: FieldArrayWithId<MetadataRHFormFormat, "metadata">;
  teardownType?: string;
  teardownFields?: ConditionUIField[];
  teardownState?: Record<string, any>;
  executor: User | null;
};

const TeardownViewModal = ({
  open,
  onClose,
  formField,
  teardownType,
  teardownFields = [],
  teardownState = {},
}: Props) => {
  const [viewingPhoto, setViewingPhoto] = useState("");

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Typography variant="h2">Teardown</Typography>

        <Box my={6} display="flex" alignItems="center">
          <Typography color="textSecondary" fontWeight={500} className="small">
            Cell ID:
          </Typography>
          <Box ml={2} mr={30}>
            <Typography color="textPrimary">
              {cellIdToString(formField.cell_id)}
            </Typography>
          </Box>
          <Box mr={2}>
            <Typography
              color="textSecondary"
              fontWeight={500}
              className="small"
            >
              Type:
            </Typography>
          </Box>
          <Typography color="textPrimary">{teardownType}</Typography>
        </Box>

        <Divider />

        {teardownFields.map((field, index) =>
          !isNull(teardownState[field.id]) ? (
            <Fragment key={index}>
              <Box mt={6} mb={2} display="flex" alignItems="flex-end">
                <Typography color="textSecondary" className="small">
                  {field.label}{" "}
                  {field.required ? (
                    <span style={{ color: colors.accent.red }}>*</span>
                  ) : null}
                </Typography>
              </Box>
              {field.type === "photo" && teardownState[field.id] ? (
                <Box display="inline-block">
                  <Card
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setViewingPhoto(teardownState[field.id].publicUrl)
                    }
                  >
                    <CardMedia
                      sx={{ width: "7.5rem", height: "7.5rem" }}
                      image={teardownState[field.id].publicUrl}
                    />
                  </Card>
                </Box>
              ) : field.type === "bool" || field.type === "boolean" ? (
                <Typography color="textPrimary">
                  {teardownState[field.id] === true ? "Yes" : "-"}
                </Typography>
              ) : (
                <Typography color="textPrimary">
                  {teardownState[field.id] || "-"}
                </Typography>
              )}
            </Fragment>
          ) : null
        )}
      </Modal>

      <Modal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto("")}
        maxWidth={false}
      >
        <a href={viewingPhoto} target="_blank" rel="noreferrer">
          <img
            src={viewingPhoto}
            alt="Teardown Attachment"
            style={{ width: "100%" }}
          />
        </a>
      </Modal>
    </>
  );
};

export default TeardownViewModal;
