import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Ellipsis = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 9.5C2.94036 9.5 3.5 8.94036 3.5 8.25C3.5 7.55964 2.94036 7 2.25 7C1.55964 7 1 7.55964 1 8.25C1 8.94036 1.55964 9.5 2.25 9.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 9.5C14.4404 9.5 15 8.94036 15 8.25C15 7.55964 14.4404 7 13.75 7C13.0596 7 12.5 7.55964 12.5 8.25C12.5 8.94036 13.0596 9.5 13.75 9.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9.5C8.69036 9.5 9.25 8.94036 9.25 8.25C9.25 7.55964 8.69036 7 8 7C7.30964 7 6.75 7.55964 6.75 8.25C6.75 8.94036 7.30964 9.5 8 9.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Ellipsis;
