import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const NavigationMenuHorizontal = ({
  style,
  children,
  ...props
}: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 14C4.32843 14 5 13.3284 5 12.5C5 11.6716 4.32843 11 3.5 11C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.75 14C21.5784 14 22.25 13.3284 22.25 12.5C22.25 11.6716 21.5784 11 20.75 11C19.9216 11 19.25 11.6716 19.25 12.5C19.25 13.3284 19.9216 14 20.75 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.125 14C12.9534 14 13.625 13.3284 13.625 12.5C13.625 11.6716 12.9534 11 12.125 11C11.2966 11 10.625 11.6716 10.625 12.5C10.625 13.3284 11.2966 14 12.125 14Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default NavigationMenuHorizontal;
