import Tooltip from "@mui/material/Tooltip";
import { GreyBadge } from "./Badge";

type Props = {
  value?: string;
  label: string;
};

const NumberBadge = ({ value, label }: Props) => (
  <Tooltip arrow title={label}>
    <GreyBadge badgeContent={`${value}`.padStart(2, "0")} />
  </Tooltip>
);

export default NumberBadge;
