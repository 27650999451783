import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const BATCH_CELL_EXTERNAL_METADATA_ENTRY =
  "BATCH_CELL_EXTERNAL_METADATA_ENTRY";

export interface BatchCellExternalMetadataEntryState {
  cells: CellExternalMetadataEntry[] | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    save: null | string;
  };
}

const initialState: BatchCellExternalMetadataEntryState = {
  cells: null,
  status: {
    get: "idle",
    save: "idle",
  },
  error: {
    get: null,
    save: null,
  },
};

export const getExternalMetadataForCells = createAsyncThunk(
  `${BATCH_CELL_EXTERNAL_METADATA_ENTRY}/get`,
  async (cell_ids: string[]) => {
    const cellIdsAsParams = cell_ids.map((cell_id_) => `cell_ids=${cell_id_}`);
    const response = await client.get(
      `meta/external-identifier-data/for-cells?${cellIdsAsParams.join("&")}`
    );
    return response;
  }
);

export const saveCellIdentifiers = createAsyncThunk(
  `${BATCH_CELL_EXTERNAL_METADATA_ENTRY}/update`,
  async (newCellIdentifiers: CellExternalMetadataEntry[]) => {
    const response = await client.put(
      `meta/external-identifier-data/for-cells`,
      {
        cell_identifiers: newCellIdentifiers,
      }
    );
    return response.data;
  }
);

const batchCellExternalMetadataEntrySlice = createSlice({
  name: BATCH_CELL_EXTERNAL_METADATA_ENTRY,
  initialState,
  reducers: {
    resetBatchCellExternalMetadataEntry: (state) =>
      Object.assign(state, initialState),
    resetSaveIdentifiersForCells: (state) => {
      state.status.save = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get single module
      .addCase(getExternalMetadataForCells.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getExternalMetadataForCells.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.cells = payload;
      })
      .addCase(getExternalMetadataForCells.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Update Serial Numbers/IDs for cells
      .addCase(saveCellIdentifiers.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(saveCellIdentifiers.fulfilled, (state, { payload }) => {
        state.status.save = "succeeded";
        state.cells = payload;
      })
      .addCase(saveCellIdentifiers.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      });
  },
});

export const {
  resetBatchCellExternalMetadataEntry,
  resetSaveIdentifiersForCells,
} = batchCellExternalMetadataEntrySlice.actions;

export default batchCellExternalMetadataEntrySlice.reducer;
