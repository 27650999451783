import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import styled from "@mui/styles/styled";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import FilterIcon from "../../icons/Filter";
import PlusCircleIcon from "../../icons/PlusCircle";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import { useSelector } from "react-redux";
import Button from "../Button";
import Chip from "../Chip";
import MetaFilter from "./MetaFilter";
import TextFilter from "./TextFilter";
import UserFilter from "./UserFilter";
import OptionFilter from "./OptionFilter";
import SaveChannelFilterModal from "./SaveChannelFilterModal";
import ProjectFilter from "./ProjectFilter";
import { isEmpty } from "lodash";

const MineLabel = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 500,
});

type Props = {
  labels: DataLabel<Channel>[];
  filterOptions: FilterOptions<ChannelFilters>;
  filters: ChannelFilters;
  onChange: (filters: ChannelFilters) => void;
};

const DEFAULT_FILTERS: (keyof ChannelFilters)[] = [
  "position__name",
  "channel__fullname",
  "channel__status",
  "cell__cell_id",
  "reserved_cell__cell_id",
  "reserved_cell__cell_id__null",
  "cell_condition__cell_type",
  "cell_condition__cell_assembly",
  "tester__name",
];

const ButtonChannelFilters = ({
  labels,
  filterOptions,
  filters,
  onChange,
}: Props) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Local filter state
  const [filterState, setFilterState] = useState(filters);
  useEffect(() => setFilterState(filters), [filters]);

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  // Add filter button
  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAddFilterAnchorEl(event.currentTarget);
  const handleAddFilterClose = () => setAddFilterAnchorEl(null);

  // UI state
  const [visibleFilters, setVisibleFilters] =
    useState<(keyof ChannelFilters)[]>(DEFAULT_FILTERS);
  const availableFilters = (
    Object.keys(filterOptions) as Array<keyof ChannelFilters>
  ).filter((key) => !visibleFilters.includes(key));

  useEffect(() => {
    if (!!buttonEl) {
      return;
    }

    const newKeys = Object.keys(filters).map(
      (key) => key as keyof ChannelFilters
    );
    const changed = newKeys.filter((key) => visibleFilters.indexOf(key) === -1);

    if (changed.length > 0) {
      setVisibleFilters([...visibleFilters, ...changed]);
    }
  }, [buttonEl, filters, visibleFilters]);

  // Remove filter
  const handleRemoveFilter = (key: keyof ChannelFilters) => {
    const newState = { ...filterState };
    delete newState[key];
    const index = visibleFilters.indexOf(key);
    visibleFilters.splice(index, 1);
    setFilterState(newState);
    setVisibleFilters([...visibleFilters]);
  };

  // Reset action
  const handleResetClick = () => {
    setFilterState({});
    setVisibleFilters(DEFAULT_FILTERS);
  };

  const getCleanFilters = () => {
    const finalFilters = { ...filterState };
    for (const key in finalFilters) {
      const typedKey = key as keyof ChannelFilters;
      if (finalFilters[typedKey]?.length === 0) {
        delete finalFilters[typedKey];
      }
    }
    return finalFilters;
  };

  // Save action
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const onAfterSave = () => {
    handleApplyClick();
    setSaveModalOpen(false);
  };

  // Apply action
  const handleApplyClick = () => {
    const finalFilters = getCleanFilters();
    onChange(finalFilters);
    setButtonEl(null);
  };

  // Mine checkbox state
  const handleMineChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setFilterState({
        ...filterState,
        experiment__owner: [],
      });
    } else {
      if (!visibleFilters.includes("experiment__owner")) {
        setVisibleFilters(["experiment__owner", ...visibleFilters]);
      }
      setFilterState({
        ...filterState,
        experiment__owner: user ? [user] : [],
      });
    }
  };

  return (
    <>
      <Chip
        count={Object.keys(filters).length}
        clickable
        onClick={handleButtonClick}
        onDelete={() => onChange({})}
        icon={<FilterIcon />}
        label="Filter"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={6} pt={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    !!filterState.experiment__owner?.some(
                      (filter) => filter.user_id === user?.user_id
                    )
                  }
                  onChange={handleMineChecked}
                />
              }
              label={
                <MineLabel color="textSecondary">
                  My Channels (on-test)
                </MineLabel>
              }
            />
          </FormGroup>

          <Box my={4}>
            <Divider />
          </Box>

          <table>
            <tbody>
              {visibleFilters.map((key) => {
                if (!filterOptions[key]) {
                  return null;
                }

                const expLabel = labels.find((label) => label.id === key);
                const { options } = filterOptions[key]!;

                let control;
                switch (key) {
                  case "position__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/positions"
                        value={filterState.position__name || []}
                        onChange={(position__name) =>
                          setFilterState({
                            ...filterState,
                            position__name,
                          })
                        }
                      />
                    );
                    break;
                  case "position__shelf":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/positions/shelves"
                        value={filterState.position__shelf || []}
                        onChange={(position__shelf) =>
                          setFilterState({
                            ...filterState,
                            position__shelf,
                          })
                        }
                      />
                    );
                    break;
                  case "tester__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/testers"
                        value={filterState.tester__name || []}
                        onChange={(tester__name) =>
                          setFilterState({
                            ...filterState,
                            tester__name,
                          })
                        }
                      />
                    );
                    break;
                  case "test_stand__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/test-stands/names"
                        value={filterState.test_stand__name || []}
                        onChange={(test_stand__name) =>
                          setFilterState({
                            ...filterState,
                            test_stand__name,
                          })
                        }
                      />
                    );
                    break;
                  case "incubator__name":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/incubators/names"
                        value={filterState.incubator__name || []}
                        onChange={(incubator__name) =>
                          setFilterState({
                            ...filterState,
                            incubator__name,
                          })
                        }
                      />
                    );
                    break;
                  case "incubator__bay":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/incubators/bays"
                        value={filterState.incubator__bay || []}
                        onChange={(incubator__bay) =>
                          setFilterState({
                            ...filterState,
                            incubator__bay,
                          })
                        }
                      />
                    );
                    break;

                  case "channel__preferred_test_vehicle":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/channels/preferred-test-vehicles"
                        value={
                          filterState.channel__preferred_test_vehicle || []
                        }
                        onChange={(channel__preferred_test_vehicle) =>
                          setFilterState({
                            ...filterState,
                            channel__preferred_test_vehicle,
                          })
                        }
                      />
                    );
                    break;
                  case "channel__fullname":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/channels"
                        value={filterState.channel__fullname || []}
                        valueKey="channel_fullname"
                        onChange={(channel__fullname) =>
                          setFilterState({
                            ...filterState,
                            channel__fullname,
                          })
                        }
                      />
                    );
                    break;
                  case "channel__status":
                  case "channel__infra_status":
                    control = (
                      <OptionFilter
                        multiple
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={options || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value as ChannelStatus[],
                          })
                        }
                      />
                    );
                    break;
                  case "experiment__exp_id":
                  case "res__exp_id":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/experiments/ids"
                        prefix="EXP"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "experiment__project":
                    control = (
                      <ProjectFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState.experiment__project || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "experiment__owner":
                  case "res__owner":
                    control = (
                      <UserFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "reserved_cell__cell_id":
                  case "cell__cell_id":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/ids"
                        prefix="CEL"
                        value={filterState[key] || []}
                        onChange={(value) => {
                          let newFilterState = filterState;
                          if (
                            key === "reserved_cell__cell_id" &&
                            !isEmpty(value)
                          ) {
                            let {
                              reserved_cell__cell_id__null,
                              ...removedChannelsWithoutResFilter
                            } = newFilterState;
                            newFilterState = removedChannelsWithoutResFilter;
                          }
                          setFilterState({ ...newFilterState, [key]: value });
                        }}
                      />
                    );
                    break;
                  case "cell_condition__cell_type":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/cell-types"
                        value={filterState.cell_condition__cell_type || []}
                        onChange={(cell_condition__cell_type) =>
                          setFilterState({
                            ...filterState,
                            cell_condition__cell_type,
                          })
                        }
                      />
                    );
                    break;
                  case "full__cell_component__serial_number":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cell-components/serial-numbers"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value,
                          })
                        }
                      />
                    );
                    break;
                  case "cell_condition__cell_assembly":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/cells/cell-assemblies"
                        value={filterState.cell_condition__cell_assembly || []}
                        onChange={(cell_condition__cell_assembly) =>
                          setFilterState({
                            ...filterState,
                            cell_condition__cell_assembly,
                          })
                        }
                      />
                    );
                    break;
                  case "channel__pool":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/channels/pool-ids"
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "test_meta__ready_off__completed_at__null":
                  case "channel__cathode_switching":
                  case "channel__co2_scrubbing":
                  case "channel__hazelnut_installed":
                  case "channel__subscale_h2_interlock_installed":
                  case "channel__humidity_control":
                  case "channel__hydrogen_monitoring":
                  case "channel__aux_voltage_monitoring":
                  case "channel__eis_functionality":
                  case "channel__thermal_uniformity_fans_installed":
                    control = (
                      <OptionFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={options || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value as ["0" | "1"],
                          })
                        }
                      />
                    );
                    break;

                  case "reserved_cell__cell_id__null":
                    control = (
                      <Checkbox
                        color="secondary"
                        checked={!!filterState.reserved_cell__cell_id__null}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (!e.target.checked) {
                            const {
                              reserved_cell__cell_id__null,
                              ...newFilterState
                            } = filterState;
                            setFilterState({
                              ...newFilterState,
                            });
                          } else {
                            const {
                              reserved_cell__cell_id,
                              ...newFilterState
                            } = filterState;
                            setFilterState({
                              ...newFilterState,
                              reserved_cell__cell_id__null: ["1"],
                            });
                          }
                        }}
                      />
                    );
                    break;
                  default:
                    control = (
                      <TextFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        onClear={() =>
                          setFilterState({
                            ...filterState,
                            [key]: [],
                          })
                        }
                        value={
                          filterState[key] ? filterState[key]![0] || "" : ""
                        }
                        onChange={(e) =>
                          setFilterState({
                            ...filterState,
                            [key]: [e.target.value],
                          })
                        }
                      />
                    );
                    break;
                }

                return (
                  <tr key={key}>
                    <Box pr={4} pb={4} component="td">
                      <MineLabel color="textSecondary">
                        {expLabel?.filterLabel || expLabel?.label}
                      </MineLabel>
                    </Box>
                    <Box pb={4} component="td">
                      {control}
                    </Box>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>

        {availableFilters.length > 0 ? (
          <Box pb={3} px={3}>
            <Button
              color="tertiary"
              size="small"
              startIcon={<PlusCircleIcon />}
              onClick={handleAddFilterClick}
            >
              Add Filter
            </Button>
            <Menu
              anchorEl={addFilterAnchorEl}
              open={!!addFilterAnchorEl}
              onClose={handleAddFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {availableFilters.map((key) => {
                const channelLabel = labels.find((label) => label.id === key);
                return (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      setVisibleFilters([...visibleFilters, key]);
                      handleAddFilterClose();
                    }}
                  >
                    {channelLabel?.filterLabel || channelLabel?.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        ) : null}

        {visibleFilters.length > 0 ? (
          <Box pt={3} pb={6} px={6} display="flex">
            <Button
              color="tertiary"
              type="button"
              size="small"
              onClick={handleResetClick}
            >
              <b>Reset</b>
            </Button>
            <Box ml="auto">
              <Button
                color="secondary"
                size="small"
                disabled={Object.keys(getCleanFilters()).length === 0}
                onClick={() => setSaveModalOpen(true)}
              >
                Save
              </Button>
            </Box>
            <Box ml={3}>
              <Button
                color="primary"
                type="button"
                size="small"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        ) : null}
      </Popover>

      <SaveChannelFilterModal
        open={saveModalOpen}
        data={getCleanFilters()}
        onClose={() => setSaveModalOpen(false)}
        onAfterSave={onAfterSave}
      />
    </>
  );
};

export default ButtonChannelFilters;
