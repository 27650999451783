import type {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import styled from "@mui/styles/styled";
import { forwardRef } from "react";
import ConditionCell from "./ConditionCell";
import useAutocompleteStyles from "./useAutocompleteStyles";
import ClockIcon from "../../icons/Clock";
import colors from "../../theme/colors";

const MetaCellInput = styled(TextField)({
  "& .pendingChange": {
    textDecoration: "line-through",
    "& .pendingIconContainer": {
      width: 24,
      height: 20,
      color: colors.text.primary,
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
  },
});

type Props = Omit<
  AutocompleteProps<string, boolean, boolean, boolean>,
  "renderInput" | "onChange"
> & {
  noTable?: boolean;
  pendingChange?: string | null;
  onChange: (value: null | string | string[]) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  error?: boolean;
  multiple?: boolean;
  options?: string[];
};

export const SelectCell = forwardRef(
  (
    {
      noTable = false,
      multiple = false,
      style,
      className,
      onChange,
      error,
      pendingChange,
      options,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const classes = useAutocompleteStyles();

    const control = (
      <FormControl style={{ width: "100%", height: "100%" }}>
        <Autocomplete
          ref={ref}
          multiple={multiple}
          options={options}
          className={noTable ? undefined : classes.root}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(e, data) => onChange(multiple ? (data === [] ? null : data) : data as string)}
          renderInput={(params) => (
            <MetaCellInput
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                className: `${params.InputProps.className || ""} ${
                  pendingChange !== undefined ? "pendingChange" : ""
                }`,
                endAdornment: (
                  <>
                    {pendingChange !== undefined ? (
                      <Tooltip
                        arrow
                        title={`Pending specification change to ${pendingChange}`}
                      >
                        <div className="pendingIconContainer">
                          <ClockIcon />
                        </div>
                      </Tooltip>
                    ) : null}
                    {params.InputProps.endAdornment ? (
                      <div style={{ height: 20, width: 24 }}>
                        {params.InputProps.endAdornment}
                      </div>
                    ) : null}
                  </>
                ),
                error,
              }}
            />
          )}
          renderOption={(props, opt: string) => (
            <li {...props}>
              {multiple && (
                <Checkbox
                  color="secondary"
                  checked={props["aria-selected"] === true}
                />
              )}
              <Box px={4} py={2}>
                {opt as string}
              </Box>
            </li>
          )}
          {...props}
        />
      </FormControl>
    );

    return noTable ? (
      <div style={style} className={className || ""}>
        {control}
      </div>
    ) : (
      <ConditionCell style={style} className={className || ""}>
        {control}
      </ConditionCell>
    );
  }
);

export default SelectCell;
