import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import GroupIcon from "../../icons/Group";
import ListIcon from "../../icons/List";
import Modal from "../../components/Modal";
import CustomViewDndGrid from "./CustomViewDndGrid";
import ButtonSort from "../../components/table/ButtonSort";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomView,
  CustomViewState,
  deleteCustomView,
  editCustomView,
  resetAddViewStatus,
  resetDeleteViewStatus,
  resetEditViewStatus,
} from "./slice";
import { RootState } from "../../store";
import colors from "../../theme/colors";
import TrashIcon from "../../icons/Trash";
import { Menu, MenuItem } from "@mui/material";
import { StyledChip } from "../../components/table/ButtonPriority";
import CaretBottom from "../../icons/CaretBottom";
import UtilityChip from "./UtilityChip";

type Props<T> = {
  open: boolean;
  table_id: string;
  view?: CustomTableView<T>;
  allColumns: DataLabel<T>[];
  defaultColumns: DataLabel<T>[];
  onClose: () => void;
};

type FormData<T> = {
  custom_view_id?: number;
  name: string;
  order: "asc" | "desc";
  orderBy: FalconKey<T> | string | null;
  grouped: boolean;
  columns: (FalconKey<T> | string)[];
  utility: string | null;
};

const CustomViewModal = <T extends Cell | Experiment | Channel>({
  open,
  table_id,
  view,
  allColumns,
  defaultColumns,
  onClose,
}: Props<T>) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);
  const defaultValues = useMemo(
    () =>
      ({
        name: "",
        order: "desc",
        orderBy: defaultColumns[0].id,
        grouped: false,
        columns: defaultColumns.map(({ id }) => id),
        utility: "",
      } as FormData<T>),
    [defaultColumns]
  );

  const { control, reset, register, setValue, handleSubmit } = useForm<
    FormData<T>
  >({ defaultValues });

  const { columns, order, orderBy } = useWatch({
    control,
  });

  useEffect(() => {
    if (!columns || columns.length === 0) {
      setValue("orderBy", null);
    } else if (!columns.some((id) => id === orderBy)) {
      setValue("orderBy", columns[0]);
    }
  }, [columns, orderBy, setValue]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(resetAddViewStatus());
      dispatch(resetEditViewStatus());
      dispatch(resetDeleteViewStatus());
    }

    if (open && view) {
      const {
        custom_view_id,
        name,
        columns,
        order,
        order_by,
        grouped,
        utility: utility_,
      } = view;
      reset({
        custom_view_id,
        name,
        grouped,
        order: order,
        orderBy: order_by as FalconKey<T>,
        columns,
        utility: utility_ ? String(utility_) : "",
      });
    } else if (open) {
      reset(defaultValues);
    }
  }, [open, reset, view, allColumns, dispatch, defaultValues]);

  const {
    status: { add: addStatus, edit: editStatus, delete: deleteStatus },
    error: { add: addError, edit: editError, delete: deleteError },
  } = useSelector<RootState, CustomViewState>(({ customViews }) => customViews);

  useEffect(() => {
    if (
      addStatus === "succeeded" ||
      editStatus === "succeeded" ||
      deleteStatus === "succeeded"
    ) {
      onClose();
    }
  }, [addStatus, editStatus, deleteStatus, onClose]);

  const onSubmit = async ({
    orderBy: order_by,
    columns,
    custom_view_id,
    utility,
    ...data
  }: FormData<T>) => {
    reset({ orderBy: order_by, custom_view_id, columns, utility, ...data });
    const utility_ = (utility as string | null) || null;
    if (custom_view_id) {
      dispatch(
        editCustomView({
          order_by: order_by as FalconKey<T>,
          columns: columns as FalconKey<T>[],
          table_id,
          custom_view_id: parseInt(`${custom_view_id}`),
          utility: utility_,
          ...data,
        })
      );
    } else {
      dispatch(
        addCustomView({
          order_by: order_by as FalconKey<T>,
          columns: columns as FalconKey<T>[],
          table_id,
          custom_view_id,
          utility: utility_,
          ...data,
        })
      );
    }
  };

  const onOrderChange = useCallback(
    (value: "asc" | "desc") => setValue("order", value),
    [setValue]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        style: { maxWidth: 720 },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2">
          {view ? "Edit View" : "Add Custom View"}
        </Typography>
        <Box my={6}>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <FormControl fullWidth>
                    <Input
                      ref={ref}
                      disableUnderline
                      name={name}
                      placeholder="View name"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={invalid}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} my="auto">
              <Controller
                control={control}
                name="orderBy"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) =>
                  columns && columns.length > 0 ? (
                    <ButtonSort
                      items={columns.map(
                        (id) =>
                          allColumns.find(({ id: allId }) => id === allId)!
                      )}
                      value={value as any}
                      onChange={onChange}
                    />
                  ) : (
                    <div />
                  )
                }
              />
              <Controller
                control={control}
                name="grouped"
                render={({ field: { onChange, value } }) =>
                  value ? (
                    <Tooltip arrow title="Switch to list mode">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => onChange(!value)}
                        >
                          <GroupIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip arrow title="Switch to group mode">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => onChange(!value)}
                        >
                          <ListIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                }
              />
              <Controller
                control={control}
                name="utility"
                render={({ field: { onChange, value } }) => (
                  <>
                    <StyledChip
                      clickable
                      onClick={handleButtonClick}
                      icon={<CaretBottom />}
                      rightIcon
                      label={
                        <>
                          {`Utility${value ? ": " : ""}`}
                          <>
                            {value ? (
                              <UtilityChip label={Number(value)} />
                            ) : null}
                          </>
                        </>
                      }
                    />

                    <Menu
                      anchorEl={buttonEl}
                      open={!!buttonEl}
                      onClose={handleButtonClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      {[5, 4, 3, 2, 1].map((num_) => {
                        return (
                          <MenuItem
                            key={`utility-${num_}`}
                            onClick={() => {
                              onChange(String(num_));
                              handleButtonClose();
                            }}
                          >
                            <UtilityChip label={num_} />
                            {num_ === 5 ? (
                              <em style={{ marginLeft: 2 }}>(high)</em>
                            ) : num_ === 1 ? (
                              <em style={{ marginLeft: 2 }}>(low)</em>
                            ) : null}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} mb={2}>
          <Typography>
            Drag columns to the right to add them to your view, to the left to
            remove them.
          </Typography>
        </Box>
        <Controller
          control={control}
          name="columns"
          rules={{ validate: (val) => val && val.length > 0 }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <CustomViewDndGrid
              allColumns={allColumns}
              value={value as any}
              onChange={onChange}
              order={order}
              onOrderChange={onOrderChange}
              orderBy={orderBy as FalconKey<T>}
              error={invalid}
            />
          )}
        />
        <input type="hidden" {...register("order")} />
        <input type="hidden" {...register("custom_view_id")} />
        {addError || editError || deleteError ? (
          <Box mt={4}>
            <Typography color={colors.accent.red}>
              {addError}
              {editError}
              {deleteError}
            </Typography>
          </Box>
        ) : null}
        <Box mt={4} display="flex" justifyContent="flex-end">
          {view ? (
            <Box mr="auto">
              <Button
                size="small"
                color="tertiary"
                type="button"
                startIcon={<TrashIcon />}
                onClick={() => dispatch(deleteCustomView(view))}
                endIcon={
                  deleteStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                disabled={deleteStatus === "loading"}
              >
                Delete
              </Button>
            </Box>
          ) : null}
          <Button size="small" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              size="small"
              color="primary"
              type="submit"
              endIcon={
                addStatus === "loading" || editStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={addStatus === "loading" || editStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default CustomViewModal;
