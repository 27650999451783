import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GreyBadge } from "../../components/Badge";
import type { RootState } from "../../store";
import type { ChangeRequestState } from "./slice";
import List from "./List";

type LabelProps = {
  text: string;
  count?: number;
};

const Label = ({ text, count }: LabelProps) =>
  isNaN(count as number) ? (
    <>{text}</>
  ) : (
    <GreyBadge badgeContent={`${count}`}>{text}</GreyBadge>
  );

type Props = {
  selectedTab: "PENDING" | "COMPLETE";
};

const ChangeRequestTabs = ({ selectedTab }: Props) => {
  const navigate = useNavigate();

  const { pendingChangeRequestCount } = useSelector<
    RootState,
    ChangeRequestState
  >(({ changeRequests }) => changeRequests);

  const isTablet = useMediaQuery("(min-width:760px)");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    switch (newValue) {
      case "PENDING":
        navigate("/change-requests");
        break;
      case "COMPLETE":
        navigate("/change-requests/complete");
        break;
    }
  };

  let contents;
  switch (selectedTab) {
    case "PENDING":
      contents = (
        <Box mt={isTablet ? 6 : 4}>
          <List />
        </Box>
      );
      break;
    case "COMPLETE":
      contents = (
        <Box mt={isTablet ? 6 : 4}>
          <List completed />
        </Box>
      );
      break;
  }

  return (
    <Box
      py={isTablet ? 6 : 4}
      px={isTablet ? 8 : 0}
      mx="auto"
      style={{
        maxWidth: 760,
      }}
    >
      <Box px={isTablet ? 0 : 4}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            value="PENDING"
            label={<Label text="Pending" count={pendingChangeRequestCount} />}
          />
          <Tab value="COMPLETE" label={<Label text="Complete" />} />
        </Tabs>
      </Box>
      {contents}
    </Box>
  );
};

export default ChangeRequestTabs;
