import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";
import Banner from "../../../components/Banner";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import ArrowBackIcon from "../../../icons/ArrowBack";
import TimesIcon from "../../../icons/Times";
import flatten from "../../../utils/flatten";
import SpecifyModal from "./SpecifyModal";
import type { RootState } from "../../../store";
import { ExperimentSingleState } from "../../experiments/singleSlice";
import { cellsSlices } from "../../cells/slice";
import {
  ConditionState,
  previewConditionChanges,
  resetPreviewChangeRequests,
} from "../slice";
import { getDirtyValues } from "../../../utils/forms";
import DiffBlock from "../../change-requests/DiffBlock";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  height: 72,
  zIndex: 1,
  position: "fixed",
});

type Props = {
  started?: boolean;
  diffView?: boolean;
  diffViewVisible?: boolean;
  editMode?: boolean;
  onSave?: (callback?: () => void) => void;
  onCancelEdit?: () => void;
  saveVisible?: boolean;
  specifyVisible?: boolean;
  bypassCommit?: boolean;
  onViewDifferences?: () => void;
  confirmationMessageStatus?: string;
};

const Header = ({
  started,
  diffView,
  diffViewVisible,
  editMode,
  onSave = () => {},
  onCancelEdit = () => {},
  saveVisible,
  specifyVisible,
  bypassCommit,
  onViewDifferences = () => {},
  confirmationMessageStatus,
}: Props) => {
  const [exiting, setExiting] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { experiment } = useSelector<RootState, ExperimentSingleState>(
    ({ experimentSingle }) => experimentSingle
  );
  const { exp_id } = experiment || {};

  const location = useLocation();
  const { from } = (location.state as {
    from?: string;
  }) || { from: "" };

  const {
    changeRequestPreview,
    status: {
      preview: previewStatus,
      get: getConditionsStatus,
      copy: copyConditionStatus,
      save: saveStatus,
    },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  const { trigger, formState, getValues } = useFormContext() || {};
  const { isDirty, errors } = formState || { errors: {} };

  const [isSaving, setIsSaving] = useState(false);
  const [saveBlocked, setSaveBlocked] = useState(false);
  const [isSpecifying, setIsSpecifying] = useState(false);

  const handleExit = useCallback(
    (skipDirty = false) => {
      if (isDirty && !skipDirty) {
        setExiting(true);
      } else {
        Object.keys(cellsSlices).forEach((key) => {
          dispatch(
            cellsSlices[key as keyof typeof cellsSlices].resetCellListStatus()
          );
        });
        navigate(from || `/experiments/${exp_id}`);
      }
    },
    [dispatch, navigate, isDirty, exp_id, from]
  );

  const handleCancelEdit = useCallback(
    (skipDirty = false) => {
      if (isDirty && !skipDirty) {
        setCanceling(true);
      } else {
        onCancelEdit();
      }
    },
    [isDirty, onCancelEdit]
  );

  const handleSave = useCallback(
    async (preview: boolean) => {
      const flattenCondition = (condition: Condition, index: number) => {
        const { items, ...c } = condition;
        const itemKeys = Object.keys(
          flatten(items, `conditions.${index}.items`, 2)
        );
        const otherKeys = Object.keys(flatten(c, `conditions.${index}`, 1));

        return itemKeys.concat(otherKeys);
      };

      const { conditions = [] } = getValues();
      const keysToValidate = conditions
        .flatMap((c: Condition, index: number) =>
          c.status !== "I" ? flattenCondition(c, index) : null
        )
        .filter((key: string[] | null) => key !== null);

      if (keysToValidate.length > 0) {
        const result = await trigger(keysToValidate);
        if (!result) {
          setSaveBlocked(true);
          return;
        }
      }
      setIsSaving(true);

      if (preview) {
        const dirtyValues = getDirtyValues(formState.dirtyFields, {
          conditions,
        });

        const flatConditions = Object.keys(conditions)
          .filter(
            (key) =>
              dirtyValues.conditions &&
              dirtyValues.conditions[key] !== undefined
          )
          .flatMap((key: string) => dirtyValues.conditions[key]);

        dispatch(previewConditionChanges({ conditions: flatConditions }));
      } else {
        onSave();
      }
    },
    [onSave, getValues, trigger, dispatch, formState]
  );

  useEffect(() => {
    if (!isDirty) {
      setIsSaving(false);
      setCanceling(false);
      setSaveBlocked(false);
      dispatch(resetPreviewChangeRequests());
    } else {
      setIsSpecifying(false);
    }
  }, [isDirty, dispatch]);

  useEffect(() => {
    if (previewStatus !== "loading") {
      setIsSaving(false);
    }
  }, [previewStatus]);

  useEffect(() => {
    if (!isSaving && saveStatus === "loading") {
      setIsSaving(true);
    }
  }, [isSaving, saveStatus]);

  useEffect(() => {
    if (
      isSaving &&
      [saveStatus, getConditionsStatus].every((status_) =>
        ["idle", "succeeded"].includes(status_)
      ) &&
      (!confirmationMessageStatus ||
        ["idle", "cancelled"].includes(confirmationMessageStatus))
    ) {
      setIsSaving(false);
    }
  }, [isSaving, confirmationMessageStatus, getConditionsStatus, saveStatus]);

  useEffect(() => {
    if (isSpecifying) {
      trigger();
    }
  }, [isSpecifying, trigger]);

  let errorBanner;
  if (saveBlocked) {
    const { conditions = [] } = getValues();

    const specConditions = Object.keys(errors["conditions"] || {}).filter(
      (index) => conditions[index].status !== "I"
    ).length;
    let missingFields = 0;

    if (specConditions > 0) {
      errors["conditions"].forEach(
        ({ items = [], ...keys }: any, index: number) => {
          if (conditions[index].status === "I") {
            return;
          }
          if (items.length === 0) {
            missingFields += Object.keys(keys).length;
          } else {
            items.forEach((item: any) => {
              missingFields += Object.keys(item).length;
            });
          }
        }
      );

      errorBanner = (
        <Box
          position="fixed"
          style={{ top: 74, left: 254, width: "calc(100vw - 302px)" }}
          m={4}
          zIndex={10}
        >
          <Banner severity="error" onClose={() => setSaveBlocked(false)}>
            {specConditions} condition
            {specConditions !== 1 ? "s have" : " has"} {missingFields} required
            field{missingFields !== 1 ? "s" : ""} that must be filled in order
            to save.
          </Banner>
        </Box>
      );
    }
  }

  const approvalReqs = changeRequestPreview.filter(
    ({ status }) => status === "P"
  );
  const autoReqs = changeRequestPreview.filter(({ status }) => status !== "P");
  const showLoadingSpinner =
    isSaving &&
    (!confirmationMessageStatus ||
      !["cancelled", "in_progress"].includes(confirmationMessageStatus));

  return (
    <>
      <HeaderContainer variant="outlined">
        <Box px={6} py={3} display="flex" alignItems="center">
          <IconButton
            size="large"
            onClick={() => (editMode ? handleCancelEdit() : handleExit())}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box flexShrink={0}>
            <Typography variant="h2">
              {saveVisible ? "Edit" : "Add"} test condition
            </Typography>
          </Box>
          <Box mx={6} height={24} display="flex" alignItems="center">
            <Divider orientation="vertical" flexItem />
          </Box>
          <Box mx={2} flexShrink={0}>
            <b>EXP ID:</b> {experiment?.exp_id}
          </Box>
          <Box mx={2} flexShrink={1} className="overflow-ellipsis">
            {experiment?.description}
          </Box>
          <Box ml="auto" display="flex" alignItems="center" flexShrink={0}>
            {started && diffViewVisible ? (
              <Box mx={2}>
                <Button
                  size="small"
                  color="tertiary"
                  onClick={onViewDifferences}
                  className={diffView ? "active" : undefined}
                  endIcon={diffView ? <TimesIcon /> : null}
                >
                  <b>View differences</b>
                </Button>
              </Box>
            ) : null}
            {editMode ? (
              <Box mx={2}>
                <Button
                  size="small"
                  disabled={!isDirty || isSaving}
                  color="primary"
                  onClick={() => handleSave(true)}
                  endIcon={
                    showLoadingSpinner ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  Submit Changes
                </Button>
              </Box>
            ) : saveVisible && !editMode ? (
              <Box mx={2}>
                <Button
                  size="small"
                  disabled={!isDirty || isSaving}
                  color="primary"
                  onClick={() => handleSave(false)}
                  endIcon={
                    showLoadingSpinner ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  Save
                </Button>
              </Box>
            ) : null}
            {editMode ? (
              <Box mx={2}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </Button>
              </Box>
            ) : null}
            {specifyVisible && !editMode ? (
              <Box mx={2}>
                <Button
                  size="small"
                  color="cta"
                  disabled={isDirty}
                  onClick={() => setIsSpecifying(true)}
                >
                  {bypassCommit ? "Specify & Commit" : "Specify"}
                </Button>
              </Box>
            ) : null}
            {[getConditionsStatus, copyConditionStatus].includes("loading") ? (
              <CircularProgress
                color="inherit"
                size={24}
                sx={{ marginLeft: 8 }}
              />
            ) : !editMode ? (
              <Box mx={2}>
                <IconButton size="small" onClick={() => handleExit()}>
                  <TimesIcon />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Box>
      </HeaderContainer>
      {specifyVisible ? (
        <SpecifyModal
          open={isSpecifying && !isDirty}
          exp_id={experiment?.exp_id}
          onClose={() => setIsSpecifying(false)}
          bypassCommit={bypassCommit}
        />
      ) : null}
      {errorBanner}
      {exiting ? (
        <Modal open={exiting} onClose={() => setExiting(false)}>
          <>
            <Typography variant="h2">Save specifications ?</Typography>
            <Box mt={8} mb={2}>
              <Typography color="textSecondary">
                You have unsaved specifications for the conditions currently
                visible. To preserve your specifications, please save before
                leaving this page.
              </Typography>
            </Box>
            <Box mt={8} display="flex" justifyContent="flex-end">
              <Box mr={4}>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => handleExit(true)}
                >
                  Don't save
                </Button>
              </Box>
              <Button
                color="primary"
                type="button"
                disabled={isSaving}
                endIcon={
                  showLoadingSpinner ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                onClick={() => onSave(() => handleExit(true))}
              >
                Save
              </Button>
            </Box>
          </>
        </Modal>
      ) : null}
      {canceling ? (
        <Modal open={canceling} onClose={() => setCanceling(false)}>
          <>
            <Typography variant="h2">Save specifications ?</Typography>
            <Box mt={8} mb={2}>
              <Typography color="textSecondary">
                You have unsaved specifications for the conditions currently
                visible. To preserve your specifications, please save before
                exiting edit mode.
              </Typography>
            </Box>
            <Box mt={8} display="flex" justifyContent="flex-end">
              <Box mr={4}>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => handleCancelEdit(true)}
                >
                  Don't save
                </Button>
              </Box>
              <Button
                color="primary"
                type="button"
                disabled={isSaving}
                endIcon={
                  showLoadingSpinner ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                onClick={() => onSave(() => handleCancelEdit(true))}
              >
                Save
              </Button>
            </Box>
          </>
        </Modal>
      ) : null}
      <Modal
        open={previewStatus === "succeeded"}
        onClose={() => dispatch(resetPreviewChangeRequests())}
      >
        <>
          <Typography variant="h2">Submit Changes</Typography>

          {autoReqs.length > 0 ? (
            <>
              <Box mt={8} mb={4}>
                <Typography color="textPrimary">
                  The following changes do not require approval and will be
                  applied immediately:
                </Typography>
              </Box>
              <Divider />
              {autoReqs.map((req, index) => (
                <DiffBlock key={index} request={req} />
              ))}
            </>
          ) : null}

          {approvalReqs.length > 0 ? (
            <>
              <Box mt={8} mb={4}>
                <Typography color="textPrimary">
                  The following changes will require approval from Tech Ops:
                </Typography>
              </Box>
              <Divider />
              {approvalReqs.map((req, index) => (
                <DiffBlock key={index} request={req} />
              ))}
            </>
          ) : null}

          <Box mt={8} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              type="button"
              disabled={isSaving}
              endIcon={
                showLoadingSpinner ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              onClick={() => handleSave(false)}
            >
              Submit
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default Header;
