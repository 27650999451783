import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  resetExperimentListStatus,
  listExperiments,
  enableGrouping,
  disableGrouping,
  expandGroup,
  collapseGroup,
  expandAllGroups,
  collapseAllGroups,
  ExperimentListState,
} from "./slice";
import type { RootState } from "../../store";
import colors from "../../theme/colors";
import {
  EXPERIMENT_LABELS,
  EXPERIMENT_FILTER_LABELS,
  expIdToString,
  expStatusToString,
  isoDateToDateString,
} from "../../utils/labels";
import { EXPERIMENT_FILTERS } from "../../utils/filters";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import ButtonExperimentFilters from "../../components/table/ButtonExperimentFilters";
import ButtonSavedExperimentFilters from "../../components/table/ButtonSavedExperimentFilters";
import ButtonSort from "../../components/table/ButtonSort";
import ExpandIcon from "../../icons/Expand";
import GroupIcon from "../../icons/Group";
import ListIcon from "../../icons/List";
import SearchBarIcon from "../../icons/SearchBar";
import VirtualTable from "../../components/table/VirtualTable";
import {
  vhToPx,
  getVirtualTableHeight,
  EMPTY_STATE_ROW_HEIGHT,
  GROUPING_HEADER_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import ItemCountHeader from "../../components/table/ItemCountHeader";

const ExperimentList = () => {
  const {
    experiments,
    args: { order, orderBy, filters, page },
    grouping: { groupingEnabled, allGroupsExpanded, expandedGroups },
    hasMore,
    status,
    error,
  } = useSelector<RootState, ExperimentListState>(
    ({ experimentList }) => experimentList
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetExperimentListStatus());
  }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        listExperiments({
          order,
          orderBy,
          filters,
          page,
          refresh: true,
        })
      );
    }
  }, [dispatch, status, order, orderBy, filters, page]);

  let errorToast;
  if (status === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(listExperiments({ order, orderBy, filters, page: 0 }))
        }
      >
        {error}
      </Toast>
    );
  }

  const handleSortClick = (
    e: React.MouseEvent,
    property: FalconKey<Experiment>
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      listExperiments({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        page: 0,
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      listExperiments({
        order,
        orderBy,
        filters,
        page: page + 1,
      })
    );
  };

  const handleToggleExpandAll = () => {
    if (!allGroupsExpanded && expandedGroups.length > 0) {
      dispatch(collapseAllGroups());
    } else if (allGroupsExpanded) {
      dispatch(collapseAllGroups());
    } else {
      dispatch(expandAllGroups());
    }
  };

  const handleToggleGroupingEnabled = () => {
    if (groupingEnabled) {
      dispatch(disableGrouping());
    } else {
      dispatch(enableGrouping());
    }
  };

  const handleToggleGrouping = (event: React.MouseEvent, value: any) => {
    event.stopPropagation();
    const index = expandedGroups.indexOf(value);
    if (index === -1) {
      dispatch(expandGroup(value));
    } else {
      dispatch(collapseGroup(value));
    }
  };

  const shouldGroup = orderBy !== "exp_id";

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (experiments.length === 0 && status === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={EXPERIMENT_LABELS.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No experiments to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let lastValue: any;
    let rowIndex = 0;

    const _orderBy = orderBy;
    tableBody = experiments
      .map((row) => {
        const res = _orderBy.split("__");
        const orderBy = res[0] as keyof Experiment;

        let currentValue: string | number;
        let subExperiments: Experiment[] = [];

        const orderBySubkey = res[1];
        // TODO proper typing
        if (orderBySubkey) {
          currentValue = (row[orderBy] as any)[orderBySubkey];
          subExperiments = experiments.filter(
            (experiment) =>
              (experiment[orderBy] as any)[orderBySubkey] === currentValue
          );
        } else {
          currentValue = row[orderBy] as string | number;
          subExperiments = experiments.filter(
            (experiment) =>
              (experiment[orderBy] as string | number) === currentValue
          );
        }

        const isSubRow =
          groupingEnabled && shouldGroup && lastValue === currentValue;
        if (isSubRow && !expandedGroups.includes(lastValue)) {
          return null;
        }

        if (!groupingEnabled && !isSubRow) {
          rowIndex++;
        }

        const showGroupingHeader = lastValue !== currentValue;

        lastValue = currentValue;

        const isExpanded = expandedGroups.includes(currentValue);

        let groupingRow;
        if (shouldGroup && groupingEnabled && showGroupingHeader) {
          const headerLabel = EXPERIMENT_LABELS.find(
            ({ id }) => id === _orderBy
          );

          groupingRow = (
            <TableRow
              key={`header-group-${row.exp_id}`}
              className="clickable"
              hover
              onClick={(e) => handleToggleGrouping(e, currentValue)}
            >
              <TableCell
                padding="checkbox"
                style={{
                  paddingTop: 12,
                  height: GROUPING_HEADER_ROW_HEIGHT - 12,
                }}
              >
                <IconButton
                  size="small"
                  onClick={(e) => handleToggleGrouping(e, currentValue)}
                >
                  <ExpandIcon
                    style={isExpanded ? { transform: "rotate(90deg)" } : {}}
                  />
                </IconButton>
              </TableCell>
              <TableCell
                colSpan={EXPERIMENT_LABELS.length}
                scope="row"
                padding="none"
              >
                <Box pt={3} pb={4} display="flex" alignItems="center">
                  <Typography variant="h3">{headerLabel?.label}: </Typography>
                  <Box ml={3}>
                    <Typography variant="h3" style={{ fontWeight: 400 }}>
                      {_orderBy === "status"
                        ? expStatusToString(row.status)
                        : _orderBy === "exp_id"
                        ? expIdToString(row.exp_id)
                        : currentValue}
                    </Typography>
                  </Box>
                  <Box ml={3}>
                    <Typography style={{ fontWeight: 400 }}>
                      ({subExperiments.length} experiment
                      {subExperiments.length !== 1 ? "s" : ""})
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          );

          if (!isExpanded) {
            rowHeights.push(GROUPING_HEADER_ROW_HEIGHT);
            return groupingRow;
          }

          rowHeights.push(GROUPING_HEADER_ROW_HEIGHT + DATA_ROW_HEIGHT);
        } else {
          rowHeights.push(DATA_ROW_HEIGHT);
        }

        return (
          <React.Fragment key={`grouping-${row.exp_id}`}>
            {groupingRow}
            <TableRow
              hover
              className={
                rowIndex % 2 === 0 ? "clickable" : "clickable alternating"
              }
              onClick={() => navigate(`/experiments/${row.exp_id}`)}
            >
              <TableCell padding="checkbox" />
              <TableCell scope="row" padding="none" className="qa-exp-id">
                {expIdToString(row.exp_id)}
              </TableCell>
              <TableCell>{row.owner.name || "-"}</TableCell>
              <TableCell>{row.project.name || "-"}</TableCell>
              <TableCell>{row.cell_count}</TableCell>
              <TableCell>{isoDateToDateString(row.last_commit)}</TableCell>
              <TableCell>{expStatusToString(row.status)}</TableCell>
              <TableCell>{row.description || "-"}</TableCell>
            </TableRow>
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <ItemCountHeader itemCount={experiments.length} />
        <Box mr={4}>
          <ButtonExperimentFilters
            labels={EXPERIMENT_FILTER_LABELS}
            filterOptions={EXPERIMENT_FILTERS}
            filters={filters}
            onChange={(filters) =>
              dispatch(listExperiments({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4}>
          <ButtonSavedExperimentFilters
            onSelect={(filters) =>
              dispatch(listExperiments({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4}>
          <ButtonSort
            value={orderBy}
            items={EXPERIMENT_LABELS}
            onChange={(orderBy) =>
              dispatch(listExperiments({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>

        <Box mr={4} py={1} height="2rem">
          <Divider orientation="vertical" />
        </Box>

        {groupingEnabled ? (
          <Tooltip arrow title="Switch to list mode">
            <span>
              <IconButton
                disabled={!shouldGroup}
                size="small"
                onClick={handleToggleGroupingEnabled}
              >
                <GroupIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip arrow title="Switch to group mode">
            <span>
              <IconButton
                disabled={!shouldGroup}
                size="small"
                onClick={handleToggleGroupingEnabled}
              >
                <ListIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>

      <Divider />

      <VirtualTable
        height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220)}
        width="100%"
        itemCount={tableBody.length}
        itemSize={(index) => rowHeights[index]}
        header={
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {shouldGroup && groupingEnabled ? (
                  <IconButton size="small" onClick={handleToggleExpandAll}>
                    <ExpandIcon
                      style={
                        allGroupsExpanded ? { transform: "rotate(90deg)" } : {}
                      }
                    />
                  </IconButton>
                ) : null}
              </TableCell>
              {EXPERIMENT_LABELS.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding={headCell.id === "exp_id" ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) => handleSortClick(e, headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        row={({ index }) => tableBody[index] || null}
      />

      <Box mt={6} display="flex" alignItems="center">
        {status === "loading" && experiments.length === 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>
        ) : experiments.length > 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Viewing {experiments.length}
          </Typography>
        ) : null}

        {hasMore ? (
          <Box ml={4}>
            <Button
              color="secondary"
              size="small"
              onClick={handleLoadMoreClick}
            >
              Load more
            </Button>
          </Box>
        ) : null}

        {status === "loading" ? (
          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        ) : null}
      </Box>
      {errorToast}
    </>
  );
};

export default ExperimentList;
