import type { FieldArrayWithId } from "react-hook-form";
import Box from "@mui/material/Box";
import Header from "./Header";
import Sidebar from "./sidebar";
import colors from "../../../theme/colors";

type Props = {
  started: boolean;
  step: number;
  rootRef?: React.Ref<HTMLDivElement>;
  onAdd?: () => void;
  onSave?: () => void;
  saveVisible?: boolean;
  specifyVisible?: boolean;
  bypassCommit?: boolean;
  diffView?: boolean;
  diffViewVisible?: boolean;
  editMode?: boolean;
  onViewDifferences?: () => void;
  assemblyLayout?: ConditionSidebarAssemblyLayout;
  onToggleCondition?: (id: number) => void;
  onCancelEdit?: () => void;
  sidebarLayout?: ConditionSidebarLayout;
  children: React.ReactNode;
  fields?: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
  confirmationMessageStatus?: string;
};

const Wrapper = ({
  started,
  step,
  rootRef,
  onAdd,
  onSave,
  saveVisible,
  specifyVisible,
  bypassCommit,
  diffView,
  diffViewVisible,
  editMode,
  onViewDifferences,
  assemblyLayout,
  onToggleCondition,
  onCancelEdit,
  sidebarLayout,
  children,
  fields,
  confirmationMessageStatus,
}: Props) => (
  <Box display="flex" flexDirection="column">
    <Header
      started={started}
      onSave={onSave}
      onCancelEdit={onCancelEdit}
      saveVisible={saveVisible}
      specifyVisible={specifyVisible}
      diffView={diffView}
      diffViewVisible={diffViewVisible}
      editMode={editMode}
      onViewDifferences={onViewDifferences}
      bypassCommit={bypassCommit}
      confirmationMessageStatus={confirmationMessageStatus}
    />

    <Sidebar
      assemblyLayout={assemblyLayout}
      layout={sidebarLayout}
      started={started}
      step={step}
      onAdd={onAdd}
      onToggleCondition={onToggleCondition}
      fields={fields}
      editMode={editMode}
    />

    <div
      ref={rootRef}
      style={{
        paddingTop: 74,
        paddingLeft: 256,
        height: "calc(100vh - 74px)",
        background: colors.body.light,
      }}
    >
      {children}
    </div>
  </Box>
);

export default Wrapper;
