import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const PencilWrite = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6671 14.137L8.95508 14.668L9.48508 10.955L19.0311 1.40901C19.9098 0.530329 21.3344 0.530329 22.2131 1.40901C23.0918 2.2877 23.0918 3.71233 22.2131 4.59101L12.6671 14.137Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1221 14.25V21.75C19.1221 22.5784 18.4505 23.25 17.6221 23.25H2.62207C1.79364 23.25 1.12207 22.5784 1.12207 21.75V6.75C1.12207 5.92157 1.79364 5.25 2.62207 5.25H10.1221"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PencilWrite;
