import React from "react";
import uniq from "lodash/uniq";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "../../icons/Check";
import EllipsisIcon from "../../icons/Ellipsis";
import FlagIcon from "../../icons/Flag";
import TimesIcon from "../../icons/Times";
import WarningIcon from "../../icons/Warning";
import colors from "../../theme/colors";

type Props = {
  icons: ("empty" | "flag" | "check" | "times" | "na")[];
  nonStandard?: boolean[];
  sopDeviations?: string[];
};

const FlagTableCell = ({
  icons,
  nonStandard: _nonStandard = [],
  sopDeviations: _sopDeviations = [],
}: Props) => {
  const uniques = uniq(icons);
  const nonStandard = uniq(_nonStandard);

  const sopDeviations = uniq(
    _sopDeviations
      .map((deviation) => deviation?.trim())
      .filter((deviation) => !!deviation)
  );

  const icon =
    uniques.length > 1 || nonStandard.length > 1 ? "ellipsis" : icons[0];

  const deviationIcon = nonStandard.some((val) => val) ? (
    <Tooltip
      arrow
      title={
        sopDeviations.length > 0
          ? sopDeviations.join(" • ")
          : "No deviations from SOP were specified"
      }
    >
      <span>
        <WarningIcon
          style={{
            color: colors.accent.warningRed,
            width: 16,
            marginTop: 4,
            marginLeft: icon !== "empty" && icon !== "na" ? 12 : 0,
          }}
        />
      </span>
    </Tooltip>
  ) : null;

  switch (icon) {
    case "ellipsis":
      return (
        <TableCell
          className="iconCell"
          padding="checkbox"
          style={{ color: colors.text.secondary }}
        >
          <EllipsisIcon
            style={{
              width: 16,
              marginTop: 4,
            }}
          />
          {deviationIcon}
        </TableCell>
      );
    case "na":
      return (
        <TableCell
          className="iconCell"
          style={{ color: colors.text.secondary }}
        >
          NA
        </TableCell>
      );
    case "flag":
      return (
        <TableCell className="iconCell iconCellFlag" padding="checkbox">
          <Tooltip arrow title="Engineer will complete this step">
            <span>
              <FlagIcon
                style={{
                  color: colors.accent.burntYellow,
                  width: 16,
                  marginTop: 4,
                }}
              />
            </span>
          </Tooltip>
          {deviationIcon}
        </TableCell>
      );
    case "check":
      return (
        <TableCell
          className="iconCell iconCellCheck"
          padding="checkbox"
          style={{
            color: colors.accent.darkGreen,
          }}
        >
          <CheckIcon
            style={{
              width: 20,
              marginTop: 4,
            }}
          />
          {deviationIcon}
        </TableCell>
      );
    case "times":
      return (
        <TableCell
          className="iconCell iconCellTimes"
          padding="checkbox"
          style={{
            color: colors.accent.red,
          }}
        >
          <TimesIcon
            style={{
              width: 20,
              marginTop: 4,
            }}
          />
          {deviationIcon}
        </TableCell>
      );
    default:
      return (
        <TableCell
          className={!deviationIcon ? "iconCell " : "iconCell iconCellFlag"}
          padding={deviationIcon ? "checkbox" : undefined}
        >
          {deviationIcon}
        </TableCell>
      );
  }
};

export default FlagTableCell;
