import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowBack = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8 19.8C20.8 20.4627 20.2627 21 19.6 21H5.2C4.53726 21 4 20.4627 4 19.8V4.2C4 3.53726 4.53726 3 5.2 3H17.236C17.5492 2.99982 17.8501 3.12213 18.0744 3.3408L20.4376 5.6472C20.6693 5.87303 20.8 6.18286 20.8 6.5064V19.8Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63623 9H17.2362"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63623 12.6001H17.2362"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63623 16.2002H12.4362"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ArrowBack;
