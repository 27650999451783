import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Group = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 22 22"
  >
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6665 2.5C7.6665 1.67157 8.33808 1 9.1665 1H19.4998C20.3283 1 20.9998 1.67157 20.9998 2.5V3.5C20.9998 4.32843 20.3283 5 19.4998 5H9.1665C8.33808 5 7.6665 4.32843 7.6665 3.5V2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6665 10.5C7.6665 9.67157 8.33808 9 9.1665 9H19.4998C20.3283 9 20.9998 9.67157 20.9998 10.5V11.5C20.9998 12.3284 20.3283 13 19.4998 13H9.1665C8.33808 13 7.6665 12.3284 7.6665 11.5V10.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6665 18.5C7.6665 17.6716 8.33808 17 9.1665 17H19.4998C20.3283 17 20.9998 17.6716 20.9998 18.5V19.5C20.9998 20.3284 20.3283 21 19.4998 21H9.1665C8.33808 21 7.6665 20.3284 7.6665 19.5V18.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.5C1 1.67157 1.67157 1 2.5 1H3.5C4.32843 1 5 1.67157 5 2.5V3.5C5 4.32843 4.32843 5 3.5 5H2.5C1.67157 5 1 4.32843 1 3.5V2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 10.5C1 9.67157 1.67157 9 2.5 9H3.5C4.32843 9 5 9.67157 5 10.5V11.5C5 12.3284 4.32843 13 3.5 13H2.5C1.67157 13 1 12.3284 1 11.5V10.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 18.5C1 17.6716 1.67157 17 2.5 17H3.5C4.32843 17 5 17.6716 5 18.5V19.5C5 20.3284 4.32843 21 3.5 21H2.5C1.67157 21 1 20.3284 1 19.5V18.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Group;
