import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import type { LinkProps as MuiLinkProps } from "@mui/material/Link";

type Props = MuiLinkProps & RouterLinkProps;

const Link = forwardRef<any, Props>((props, ref) => (
  <MuiLink component={RouterLink} ref={ref} {...props} />
));

export default Link;
