import Box from "@mui/material/Box";
import type { DateRange } from "@mui/lab";
import { DateTime } from "luxon";
import IconButton from "@mui/material/IconButton";
import MinusCircleIcon from "../../icons/MinusCircle";
import DateRangePicker from "../DateRangePicker";

type DateFilterProps = {
  value: string[];
  removable?: boolean;
  onRemove?: () => void;
  onChange: (value: string[]) => void;
};

const DateFilter = ({
  value,
  removable,
  onRemove,
  onChange,
}: DateFilterProps) => {
  const start = DateTime.fromFormat(value[0] || "", "yyyy-MM-dd");
  const end = DateTime.fromFormat(value[1] || "", "yyyy-MM-dd");

  const valueAsDates: DateRange<DateTime> = [
    start.isValid ? start : null,
    end.isValid ? end : null,
  ];

  const onChangeAsDate = ([start, end]: DateRange<unknown>) => {
    if (!start && !end) {
      onChange([]);
    } else {
      onChange([
        (start as DateTime)?.toFormat("yyyy-MM-dd") || "",
        (end as DateTime)?.toFormat("yyyy-MM-dd") || "",
      ]);
    }
  };

  return (
    <Box display="flex" style={{ width: 450 }}>
      <DateRangePicker value={valueAsDates} onChange={onChangeAsDate} />
      {removable ? (
        <IconButton size="small" onClick={onRemove}>
          <MinusCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default DateFilter;
