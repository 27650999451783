import makeStyles from "@mui/styles/makeStyles";

const useAutocompleteStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      "&:not(.Mui-focused):not(.Mui-error)": {
        border: "1px solid transparent",
      },
      paddingTop: 12,
      paddingBottom: 12,
      borderRadius: 0,
    },
  },
});

export default useAutocompleteStyles;
