import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull } from "lodash";

import Button from "../../components/Button";
import ConversionPath from "../../icons/ConversionPath";
import Chip from "../../components/Chip";
import MetaFilter from "../../components/table/MetaFilter";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import type { RootState } from "../../store";
import { createSignalMap } from "./slice";
import type { SignalMapState } from "./slice";

type Props = {
  dut_id: string;
  dutType: "cell" | "module";
  has_signal_map?: boolean;
  shrinkButton?: boolean;
};

const ButtonAccessSignalMap = ({
  dut_id,
  dutType,
  has_signal_map = false,
  shrinkButton = false,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dutToCopy, setDutToCopy] = useState<any | null>(null);
  const [templateName, setTemplateName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    status: { create: createStatus },
    error: { create: createError },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  const handleSubmit = () => {
    let data: any = {
      dut_id: dut_id,
      dut_type: dutType,
    };

    if (isNull(dutToCopy) && isNull(templateName)) {
      setError("Please make a selection before submitting.");
      return;
    } else if (!isNull(dutToCopy) && !isNull(templateName)) {
      setError("Please only select one option.");
      return;
    } else if (isNull(dutToCopy)) {
      // Duplicate from template
      data["template_name"] = templateName;
    } else {
      // Duplicate from most recent test of DUT
      data["dut_id_to_copy"] =
        dutType === "cell" ? dutToCopy : dutToCopy["module_id"];
    }

    dispatch(createSignalMap(data));
  };

  useEffect(() => {
    if (createStatus === "failed") {
      setError(createError);
    } else {
      setError(null);
    }
  }, [createStatus, createError]);

  return (
    <>
      {shrinkButton ? (
        <Tooltip
          arrow
          title={`${has_signal_map ? "View" : "Create"} Signal Map`}
        >
          <span>
            <IconButton
              size="small"
              onClick={() =>
                has_signal_map
                  ? navigate(`/signal-maps/${dutType}s/${dut_id}`)
                  : setModalOpen(true)
              }
            >
              <ConversionPath />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Chip
          clickable
          onClick={() =>
            has_signal_map
              ? navigate(`/signal-maps/${dutType}s/${dut_id}`)
              : setModalOpen(true)
          }
          icon={<ConversionPath />}
          label={`${has_signal_map ? "View" : "Create"} Signal Map`}
        />
      )}

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Typography variant="h2">Create Signal Map</Typography>

        <Box my={6}>
          <Typography color="textSecondary">
            Copy from most recent test of {dutType}
          </Typography>

          <MetaFilter
            key={`${dutType}_id`}
            valueKey={dutType === "module" ? "module_id" : undefined}
            multiple={false}
            removable={false}
            endpoint="meta/signal-maps/dut-ids"
            prefix={dutType === "cell" ? "CEL" : "MOD"}
            descriptionKey={dutType === "module" ? "name" : undefined}
            value={dutToCopy || ""}
            onChange={(obj) => setDutToCopy(obj)}
            queryParameters={{ dut_type: dutType }}
          />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-evenly">
          <Divider sx={{ width: "45%" }} />
          <Typography color="textSecondary">or</Typography>
          <Divider sx={{ width: "45%" }} />
        </Box>

        <Box my={6}>
          <Typography color="textSecondary">
            Choose from existing template
          </Typography>

          <MetaFilter
            key={"template_name"}
            multiple={false}
            removable={false}
            endpoint="meta/signal-maps/templates"
            value={templateName || ""}
            onChange={(template) => setTemplateName(template)}
            queryParameters={{ dut_type: dutType }}
          />
        </Box>

        <Button
          color="primary"
          size="small"
          type="submit"
          onClick={handleSubmit}
          disabled={
            (isNull(dutToCopy) && isNull(templateName)) ||
            createStatus === "loading"
          }
          style={{ marginTop: "20px", width: "fit-content" }}
          endIcon={
            createStatus === "loading" ? (
              <CircularProgress color="inherit" size={20} />
            ) : null
          }
        >
          Submit
        </Button>
      </Modal>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default ButtonAccessSignalMap;
