import { forwardRef } from "react";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MuiInput from "@mui/material/Input";
import type { InputProps } from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import ConditionCell from "./ConditionCell";
import InputCellInput from "./InputCellInput";
import ClockIcon from "../../icons/Clock";

type Props = {
  noTable?: boolean;
  pendingChange?: any;
} & InputProps;

const InputCell = forwardRef(
  (
    {
      noTable = false,
      style,
      className,
      pendingChange,
      endAdornment,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const Input = noTable ? MuiInput : InputCellInput;

    const control = (
      <FormControl style={{ width: "100%", height: "100%" }}>
        <Input
          disableUnderline
          inputRef={ref}
          inputComponent={TextareaAutosize}
          inputProps={{ maxRows: 3 }}
          className={pendingChange !== undefined ? "pendingChange" : undefined}
          endAdornment={
            <>
              {endAdornment}
              {pendingChange !== undefined ? (
                <Tooltip
                  arrow
                  title={`Pending specification change to ${pendingChange}`}
                >
                  <div className="pendingIconContainer">
                    <ClockIcon />
                  </div>
                </Tooltip>
              ) : null}
            </>
          }
          {...props}
        />
      </FormControl>
    );

    return noTable ? (
      <div style={style} className={className || ""}>
        {control}
      </div>
    ) : (
      <ConditionCell style={style} className={className || ""}>
        {control}
      </ConditionCell>
    );
  }
);

export default InputCell;
