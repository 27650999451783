import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigationMenuHorizontalIcon from "../../icons/NavigationMenuHorizontal";

type ConditionMenuProps = {
  onDuplicate?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

const ConditionMenu = ({
  onDuplicate,
  onEdit,
  onDelete,
}: ConditionMenuProps) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLButtonElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  return (
    <>
      <IconButton size="small" onClick={handleButtonClick}>
        <NavigationMenuHorizontalIcon style={{ width: 16, height: 16 }} />
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {onDuplicate ? (
          <MenuItem
            onClick={() => {
              onDuplicate();
              setButtonEl(null);
            }}
          >
            Duplicate condition
          </MenuItem>
        ) : null}
        {onEdit ? (
          <MenuItem
            onClick={() => {
              onEdit();
              setButtonEl(null);
            }}
          >
            Edit condition
          </MenuItem>
        ) : null}
        {onDelete ? (
          <MenuItem
            onClick={() => {
              onDelete();
              setButtonEl(null);
            }}
          >
            Delete condition
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default ConditionMenu;
