import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const CheckCircle = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style }} viewBox="0 0 20 20">
    {children}
    <circle cx="10" cy="10" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1359 6.10699C14.4911 6.3201 14.6063 6.7808 14.3932 7.13599L9.89315 14.636C9.78273 14.82 9.59873 14.948 9.38775 14.9874C9.17676 15.0268 8.959 14.9739 8.78958 14.8421L4.28958 11.3421C3.96262 11.0878 3.90372 10.6166 4.15802 10.2897C4.41232 9.9627 4.88353 9.9038 5.21049 10.1581L9.04238 13.1385L13.1069 6.36424C13.32 6.00906 13.7807 5.89388 14.1359 6.10699Z"
      fill="white"
    />
  </SvgIcon>
);

export default CheckCircle;
