import { Draggable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import colors, { addAlpha } from "../../theme/colors";
import ArrowForwardIcon from "../../icons/ArrowForward";
import DragIcon from "../../icons/Drag";

const useStyles = makeStyles({
  root: {
    cursor: "move",
    borderRadius: 3,
    "&:not(:hover) .dragIcon": {
      visibility: "hidden",
    },
    "&.left": {
      color: colors.text.secondary,
      background: addAlpha(colors.rules, 0.2),
      border: `1px solid ${colors.text.secondary}`,
    },
    "&.right": {
      color: colors.accent.primary,
      border: `1px solid ${colors.accent.primary}`,
    },
  },
});

type Props = {
  id: string;
  index: number;
  label: string;
  className: string;
  order?: "asc" | "desc";
  onOrderChange: (order: "asc" | "desc") => void;
  orderBy: boolean;
};

const CustomViewDndCard = ({
  id,
  index,
  label,
  className,
  order,
  onOrderChange,
  orderBy,
}: Props) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Box
          mr={4}
          mb={2}
          px={4}
          py={2}
          display="flex"
          alignItems="center"
          className={`${className} ${classes.root}`}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          {label}

          {orderBy ? (
            <Box ml={1}>
              <IconButton
                size="small"
                style={{ padding: 4 }}
                onClick={() => onOrderChange(order === "desc" ? "asc" : "desc")}
              >
                <ArrowForwardIcon
                  style={{
                    width: 12,
                    height: 12,
                    transform:
                      order === "desc" ? "rotate(90deg)" : "rotate(-90deg)",
                    color: colors.text.secondary,
                  }}
                />
              </IconButton>
            </Box>
          ) : null}

          <Box
            className="dragIcon"
            ml="auto"
            display="flex"
            alignItems="center"
          >
            <DragIcon />
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default CustomViewDndCard;
