import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  TeardownSpecificationsState,
  getTeardownInfo,
  updateTeardownTypes,
  updateTeardownFlags,
  resetTeardownModal,
  resetUpdateTeardownTypes,
  resetGetTeardownSpecs,
} from "./modifyTeardownSpecsSlice";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { intersection, isNull } from "lodash";
import ModifyTeardownTypesTable, {
  OFF_TEST_REASONS,
} from "./ModifyTeardownTypesTable";
import ModifyTeardownFlagsTable from "./ModifyTeardownFlagsTable";
import colors from "../../theme/colors";
import { DISCARD } from "../../utils/labels";
import client from "../../api";
import Check from "../../icons/Check";

export const useChannelCellStyles = makeStyles({
  root: {
    "& .MuiTableCell-root": {
      "& .thCellInner": {},
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        border: 0,
      },
      "&.MuiTableCell-head": {
        background: "white",
      },
    },
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  onReadyOff?: () => void;
  selected: Cell[];
};

const ModifyTeardownSpecsModal = ({
  open,
  onClose,
  onReadyOff,
  selected,
}: Props) => {
  const dispatch = useDispatch();
  const markingReadyOff = !!onReadyOff;

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const onFirstScreen = useMemo(
    () => currentPageIndex === 0,
    [currentPageIndex]
  );
  const [teardownTypesToUpdate, setTeardownTypesToUpdate] = useState<{
    [key: string]: string;
  }>({});
  const [teardownTemplateRefreshError, setTeardownTemplateRefreshError] =
    useState<string | null>(null);
  const handleTeardownTypeChange = (
    cellIds: string[],
    newTeardownType: string | null
  ) => {
    setTeardownTypesToUpdate((prevTeardownTypesToUpdate) => {
      if (!!newTeardownType) {
        const newTeardownTypes = { ...prevTeardownTypesToUpdate };
        cellIds.forEach((cellId_) => {
          newTeardownTypes[cellId_] = newTeardownType;
        });
        return newTeardownTypes;
      } else {
        const newTeardownTypes = Object.assign({}, prevTeardownTypesToUpdate);
        cellIds.forEach((cellId_) => {
          delete newTeardownTypes[cellId_];
        });
        return newTeardownTypes;
      }
    });
  };
  const [offTestReasonsToUpdate, setOffTestReasonsToUpdate] = useState<{
    [key: string]: string | null;
  }>({});
  const handleOffTestReasonChange = (
    cellIds: string[],
    newOffTestReason: string | null
  ) => {
    setOffTestReasonsToUpdate((prevOffTestReasonsToUpdate) => {
      if (!!newOffTestReason) {
        const newOffTestReasons = { ...prevOffTestReasonsToUpdate };
        cellIds.forEach((cellId_) => {
          newOffTestReasons[cellId_] = newOffTestReason;
        });
        return newOffTestReasons;
      } else {
        const newOffTestReasons = Object.assign({}, prevOffTestReasonsToUpdate);
        cellIds.forEach((cellId_) => {
          delete newOffTestReasons[cellId_];
        });
        return newOffTestReasons;
      }
    });
  };
  const [teardownFlagsToUpdate, setTeardownFlagsToUpdate] = useState<{
    [key: string]: { [key: string]: string | boolean | User | null };
  }>({});
  const handleTeardownFlagsChange = (
    conditionIds: string[],
    flag: string,
    value: string | boolean | User | null,
    persistEmpty = false
  ) => {
    setTeardownFlagsToUpdate((prevTeardownFlagsToUpdate) => {
      const newFlags = { ...prevTeardownFlagsToUpdate };
      if (!isNull(value) || persistEmpty) {
        conditionIds.forEach((conditionId_) => {
          if (newFlags.hasOwnProperty(conditionId_)) {
            newFlags[conditionId_][flag] = value;
          } else {
            newFlags[conditionId_] = { [flag]: value };
          }
        });
      } else {
        conditionIds.forEach((conditionId_) => {
          if (newFlags.hasOwnProperty(conditionId_)) {
            delete newFlags[conditionId_][flag];
          }
        });
      }
      return newFlags;
    });
  };

  const handleClose = useCallback(() => {
    dispatch(resetTeardownModal());
    onClose();
  }, [dispatch, onClose]);

  const handleGoBack = () => {
    setTeardownFlagsToUpdate({});
    dispatch(resetTeardownModal());
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const {
    teardownSpecifications,
    status: {
      get: getTeardownInfoStatus,
      updateTeardownTypes: updateTeardownTypesStatus,
      updateTeardownFlags: updateTeardownFlagsStatus,
    },
    error: { get: getTeardownInfoError },
  } = useSelector<RootState, TeardownSpecificationsState>(
    ({ teardownSpecs }) => teardownSpecs
  );

  useEffect(() => {
    if (open && updateTeardownFlagsStatus === "succeeded") {
      if (markingReadyOff) {
        onReadyOff();
      }
      handleClose();
    }
  }, [
    open,
    updateTeardownFlagsStatus,
    handleClose,
    onReadyOff,
    markingReadyOff,
  ]);

  useEffect(() => {
    if (!open && currentPageIndex !== 0) {
      setCurrentPageIndex(0);
      setTeardownTypesToUpdate({});
      setTeardownFlagsToUpdate({});
    }
  }, [open, currentPageIndex]);

  useEffect(() => {
    if (open && getTeardownInfoStatus === "idle" && !teardownSpecifications) {
      dispatch(
        getTeardownInfo(selected.map((selectedCell) => selectedCell.cell_id))
      );
    }
  }, [dispatch, open, selected, teardownSpecifications, getTeardownInfoStatus]);

  useEffect(() => {
    if (open && updateTeardownTypesStatus === "succeeded" && onFirstScreen) {
      setCurrentPageIndex(currentPageIndex + 1);
      setTeardownTypesToUpdate({});
      dispatch(resetUpdateTeardownTypes());
      dispatch(resetGetTeardownSpecs());
    }
  }, [
    dispatch,
    open,
    currentPageIndex,
    updateTeardownTypesStatus,
    onFirstScreen,
  ]);

  const teardownSpecsByCondition = useMemo(() => {
    if (!teardownSpecifications) return null;
    return teardownSpecifications.reduce(
      (
        specsByCondition_: {
          [key: string]: {
            teardownTypeByCellId: { [key: string]: string };
            offTestReasonByCellId: { [key: string]: string | null };
            teardown_flags: { [key: string]: any };
            cellCondition: Condition;
            component_meta_modes: string[];
            cell_statuses_for_condition: string[];
            teardown_condition_component_fields: ConditionUIField[];
          };
        },
        teardownSpec
      ) => {
        const strCellConditionId = `${teardownSpec.cell_condition_id}`;
        const strCellId = `${teardownSpec.cell_id}`;
        if (Object.keys(specsByCondition_).includes(strCellConditionId)) {
          specsByCondition_[strCellConditionId].teardownTypeByCellId = {
            ...specsByCondition_[teardownSpec.cell_condition_id]
              .teardownTypeByCellId,
            [strCellId]: teardownSpec.component_meta_mode,
          };
          specsByCondition_[strCellConditionId].offTestReasonByCellId = {
            ...specsByCondition_[teardownSpec.cell_condition_id]
              .offTestReasonByCellId,
            [strCellId]: teardownSpec.off_test_reason,
          };
          return specsByCondition_;
        }
        return {
          ...specsByCondition_,
          [strCellConditionId]: {
            cellCondition: teardownSpec.cell_condition,
            teardownTypeByCellId: {
              [strCellId]: teardownSpec.component_meta_mode,
            },
            offTestReasonByCellId: {
              [strCellId]: teardownSpec.off_test_reason,
            },
            component_meta_modes: markingReadyOff
              ? [...teardownSpec.component_meta_modes, DISCARD]
              : teardownSpec.component_meta_modes,
            teardown_flags: teardownSpec.teardown_flags,
            teardown_condition_component_fields:
              teardownSpec.teardown_condition_component_fields,
            cell_statuses_for_condition:
              teardownSpec.cell_statuses_for_condition,
          },
        };
      },
      {}
    );
  }, [teardownSpecifications, markingReadyOff]);
  const TEARDOWN_PAGE_ORDER = [
    ModifyTeardownTypesTable,
    ModifyTeardownFlagsTable,
  ];
  const TableComponent = TEARDOWN_PAGE_ORDER[currentPageIndex];
  const teardownSpecsLoaded =
    !!teardownSpecsByCondition &&
    Object.keys(teardownSpecsByCondition).length > 0;
  const teardownTypeOptions =
    teardownSpecsLoaded &&
    intersection(
      ...Object.keys(teardownSpecsByCondition).map(
        (_conditionId) =>
          teardownSpecsByCondition[_conditionId].component_meta_modes
      )
    );

  const handleRefreshTeardownTemplatesClick = async () => {
    const cell_ids = teardownSpecifications!
      .filter(
        (teardownSpec_) => teardownSpec_.teardown_template_refresh_available
      )
      .map((teardownSpec_) => teardownSpec_.cell_id);
    try {
      const response = await client.post("meta/cells/refresh-teardown-modes", {
        cell_ids,
      });
      if (response) {
        setTeardownTemplateRefreshError(null);
        dispatch(resetTeardownModal());
      }
    } catch (err) {
      setTeardownTemplateRefreshError("Error refreshing teardown templates.");
    }
  };

  const renderResetButton = () => {
    return (
      <Box ml="auto" mr={2}>
        <Button
          color="secondary"
          size="small"
          onClick={() =>
            onFirstScreen
              ? setTeardownTypesToUpdate({})
              : setTeardownFlagsToUpdate({})
          }
          disabled={
            onFirstScreen
              ? Object.keys(teardownTypesToUpdate).length === 0
              : Object.keys(teardownFlagsToUpdate).length === 0
          }
        >
          Reset
        </Button>
      </Box>
    );
  };
  const renderConfirmUpdateButton = () => {
    const haveTeardownTypesToUpdate =
      Object.keys(teardownTypesToUpdate).length > 0;
    const haveTeardownFlagsToUpdate =
      Object.keys(teardownFlagsToUpdate).length > 0;

    const handleTeardownScreenContinueClick = () => {
      if (onFirstScreen) {
        if (haveTeardownTypesToUpdate) {
          dispatch(
            updateTeardownTypes({
              teardownTypesToUpdate,
              offTestReasonsToUpdate,
            })
          );
        } else {
          setCurrentPageIndex(currentPageIndex + 1);
        }
        setTeardownTemplateRefreshError(null);
        return;
      } else {
        dispatch(updateTeardownFlags(teardownFlagsToUpdate));
      }
    };
    const determineButtonText = () => {
      if (onFirstScreen) {
        return `${
          haveTeardownTypesToUpdate ? "Update" : "Confirm"
        } teardown types & continue`;
      }
      return `${
        haveTeardownFlagsToUpdate ? "Update" : "Confirm"
      } teardown flags & complete`;
    };
    return (
      <Button
        color="cta"
        size="small"
        disabled={
          !!getTeardownInfoError ||
          [updateTeardownTypesStatus, updateTeardownFlagsStatus].some(
            (status_) => ["loading", "failed"].includes(status_)
          )
        }
        onClick={() => handleTeardownScreenContinueClick()}
      >
        {determineButtonText()}
      </Button>
    );
  };

  const renderCancelOrBackButton = () => {
    return (
      <Box mr={2}>
        <Button
          color="secondary"
          size="small"
          onClick={onFirstScreen ? () => handleClose() : () => handleGoBack()}
        >
          {onFirstScreen ? "Cancel" : "Back"}
        </Button>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={() => handleClose()} maxWidth={false}>
      <Box>
        <Typography variant="h2" marginBottom={4}>
          {`${
            markingReadyOff ? "Please review" : "Modify"
          } teardown specifications`}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" marginBottom={4}>
            {onFirstScreen
              ? "Step 1: Confirm or Update Teardown Types"
              : "Step 2: Confirm or Update Teardown Flags"}
          </Typography>
        </Box>
        {currentPageIndex === 1 ? (
          <Box marginBottom={4}>
            {selected.length > 1 && (
              <Typography color="textSecondary">
                Tip: Hold Command/Alt button to enable copying of cell values
                across table row.
              </Typography>
            )}
          </Box>
        ) : !!teardownTypeOptions ? (
          <Box
            marginBottom={8}
            paddingTop={2}
            paddingBottom={4}
            px={4}
            sx={{ backgroundColor: colors.striping }}
            borderRadius="2px"
            width="90%"
            display="flex"
            justifyContent="space-between"
          >
            {selected.length === 1 ? (
              <Box />
            ) : (
              <Box>
                <Typography color="textSecondary" marginRight={2} my={4}>
                  Set for all cells:
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex">
                    <Typography
                      className="small"
                      color="textSecondary"
                      margin={2}
                    >
                      Teardown type
                    </Typography>
                    <Autocomplete
                      sx={{ width: 240 }}
                      size="small"
                      options={teardownTypeOptions}
                      onChange={(_event, newVal) => {
                        handleTeardownTypeChange(
                          teardownSpecifications!.map(({ cell_id }) =>
                            String(cell_id)
                          ),
                          newVal
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} color="secondary" />
                      )}
                      renderOption={(props, teardownType) => (
                        <li {...props}>
                          <Box px={4} py={2}>
                            {teardownType}
                          </Box>
                        </li>
                      )}
                      value={
                        Object.keys(teardownTypesToUpdate).length ===
                          Object.keys(teardownSpecifications!).length &&
                        Object.keys(teardownTypesToUpdate).every(
                          (cellId_) =>
                            teardownTypesToUpdate[cellId_] ===
                            teardownTypesToUpdate[
                              Object.keys(teardownTypesToUpdate)[0]
                            ]
                        )
                          ? teardownTypesToUpdate[
                              Object.keys(teardownTypesToUpdate)[0]
                            ]
                          : null
                      }
                    />
                  </Box>
                  {markingReadyOff && (
                    <Box display="flex">
                      <Typography
                        className="small"
                        color="textSecondary"
                        margin={2}
                        marginLeft={4}
                      >
                        Off-test reason
                      </Typography>
                      <Autocomplete
                        sx={{ width: 296 }}
                        size="small"
                        options={OFF_TEST_REASONS}
                        onChange={(_event, newVal) => {
                          handleOffTestReasonChange(
                            teardownSpecifications!.map(({ cell_id }) =>
                              String(cell_id)
                            ),
                            newVal
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} color="secondary" />
                        )}
                        renderOption={(props, reason) => (
                          <li {...props}>
                            <Box px={4} py={2}>
                              {reason}
                            </Box>
                          </li>
                        )}
                        value={
                          Object.keys(offTestReasonsToUpdate).length ===
                            Object.keys(teardownSpecifications!).length &&
                          Object.keys(offTestReasonsToUpdate).every(
                            (cellId_) =>
                              offTestReasonsToUpdate[cellId_] ===
                              offTestReasonsToUpdate[
                                Object.keys(offTestReasonsToUpdate)[0]
                              ]
                          )
                            ? offTestReasonsToUpdate[
                                Object.keys(offTestReasonsToUpdate)[0]
                              ]
                            : null
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <Box
              ml={4}
              style={{
                alignSelf: "flex-end",
                marginTop: selected.length === 1 ? "8px" : undefined,
              }}
            >
              {teardownSpecifications!.some(
                (teardownSpec_) =>
                  teardownSpec_.teardown_template_refresh_available
              ) ? (
                <Tooltip
                  arrow
                  title={`Click to refresh teardown type templates for cells ${teardownSpecifications!
                    .filter(
                      (teardownSpec_) =>
                        teardownSpec_.teardown_template_refresh_available
                    )
                    .map((teardownSpec_) => teardownSpec_.cell_id)
                    .join(", ")}`}
                >
                  <span>
                    <Button
                      color="secondary"
                      onClick={handleRefreshTeardownTemplatesClick}
                    >
                      Refresh teardown templates
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button color="secondary" disabled>
                  Teardown templates up-to-date
                  <Check />
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        {open && getTeardownInfoStatus === "loading" && <CircularProgress />}
        {open && !!getTeardownInfoError && (
          <Box mt={8}>
            <Typography color="error">{`Error fetching teardown specification data: ${getTeardownInfoError}`}</Typography>
          </Box>
        )}
        {open && !!teardownTemplateRefreshError && (
          <Box mt={8}>
            <Typography color="error">
              {teardownTemplateRefreshError}
            </Typography>
          </Box>
        )}
      </Box>

      {teardownSpecsLoaded && (
        <TableContainer>
          <TableComponent
            teardownSpecsByCondition={teardownSpecsByCondition}
            handleChangeTeardownTypes={handleTeardownTypeChange}
            handleChangeTeardownFlags={handleTeardownFlagsChange}
            handleChangeOffTestReasons={handleOffTestReasonChange}
            teardownTypesToUpdate={teardownTypesToUpdate}
            teardownFlagsToUpdate={teardownFlagsToUpdate}
            offTestReasonsToUpdate={offTestReasonsToUpdate}
            showOffTestReasons={markingReadyOff}
          />
        </TableContainer>
      )}

      <Box mt={6} display="flex">
        {open &&
          [updateTeardownFlagsStatus, updateTeardownTypesStatus].includes(
            "failed"
          ) && (
            <Box>
              <Typography color="error">{`Error updating teardown ${
                updateTeardownTypesStatus === "failed"
                  ? "types for cell(s)"
                  : "flags"
              }`}</Typography>
            </Box>
          )}
        {renderResetButton()}
        {renderCancelOrBackButton()}
        {renderConfirmUpdateButton()}
      </Box>
    </Modal>
  );
};

export default ModifyTeardownSpecsModal;
