import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";
import SyncWarningIcon from "../../../icons/SyncWarning";
import colors from "../../../theme/colors";

type Props = {
  cell_id: number;
  hasEvents?: boolean;
  isReadyOff?: boolean;
  onViewEvents?: (cell_id: number) => void;
};

const CellHeader = ({
  cell_id,
  isReadyOff,
  hasEvents,
  onViewEvents,
}: Props) => (
  <TableCell>
    <Box display="flex">
      Cell ID {`${cell_id}`.padStart(6, "0")}
      {isReadyOff ? (
        <Tooltip arrow placement="top" title="Ready to take off test">
          <div style={{ height: "1.5rem" }}>
            <SyncWarningIcon
              style={{
                marginLeft: "0.5rem",
                color: colors.accent.violet,
              }}
            />
          </div>
        </Tooltip>
      ) : null}
      {hasEvents && onViewEvents && (
        <MuiLink
          color="secondary"
          onClick={() => onViewEvents(cell_id)}
          sx={{ ml: 2, cursor: "pointer" }}
        >
          View Events
        </MuiLink>
      )}
    </Box>
  </TableCell>
);

export default CellHeader;
