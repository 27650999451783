import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const TimesCircle = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style }} viewBox="0 0 20 20">
    {children}
    <circle cx="10" cy="10" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="currentColor"
    />
    <path
      d="M6.24835 13.75L13.7475 6.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7483 13.75L6.2475 6.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TimesCircle;
