import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import CaretBottomIcon from "../../../icons/CaretBottom";
import CheckCircleIcon from "../../../icons/CheckCircle";
import colors from "../../../theme/colors";
import {
  FSC_BATTERY_MODULE,
  getInitialsForUser,
  isoDateToDateTimeString,
} from "../../../utils/labels";
import Modal from "../../../components/Modal";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import TestModal from "./TestModal";
import ExclamationCircleOutlinedIcon from "../../../icons/ExclamationCircleOutlined";
import { useParams } from "react-router-dom";
import parseCellIdString from "../../../utils/parseCellIdString";

const NO_QUALITY_CHECK_WARNING =
  "Quality checks have not been completed for this cell.";

type Props = {
  cell_id: number;
  cell_condition_id: number;
  cell_assembly: string;
  module?: {
    module_id: string;
    serial_number: string;
  };
  reserved_channel: string;
  reserved_test_stand?: string;
  enable_standard_tests: boolean; // Enable normal tests based on cell status
  disabled_prev_steps: boolean; // Disabled based on missing prior steps
  disabled_pre_committed: boolean; // Disabled based on being pre-committed
  disabled_module: boolean; // Disabled bc the cell is part of a module
  executor: User | null;
  completed_at: string | null;
  completed_by: User | null;
  onSave: (callback?: () => void) => void;
  warnNoQCCheck?: boolean;
  isFullScale: boolean;
};

const TestCell = ({
  cell_id,
  cell_assembly,
  cell_condition_id,
  module: mod,
  reserved_channel,
  reserved_test_stand,
  enable_standard_tests = true,
  disabled_prev_steps,
  disabled_pre_committed,
  disabled_module,
  executor,
  completed_at,
  completed_by,
  warnNoQCCheck,
  onSave,
  isFullScale = false,
}: Props) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const { cell_id_string = "" } = useParams();

  // Metadata state in redux
  const {
    status: { save: saveStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  /* Completed Menu UI */
  const [checkButtonEl, setCheckButtonEl] = useState<null | HTMLButtonElement>(
    null
  );
  const handleCheckButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setCheckButtonEl(event.currentTarget);
  };
  const handleCheckButtonClose = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setCheckButtonEl(null);
  };

  const [disableOnTest, setDisableOnTest] = useState<boolean>(false);
  const [tooltipMessage, setTooltipMessage] = useState<string>("");

  useEffect(() => {
    if (disabled_prev_steps) {
      setDisableOnTest(true);
      setTooltipMessage("Previous steps must be completed first");
    } else if (disabled_pre_committed) {
      setDisableOnTest(true);
      setTooltipMessage("Cells must be committed to generate any test.");
    } else if (disabled_module) {
      let sn_str = mod?.serial_number ? ` (SN: ${mod?.serial_number})` : "";
      setDisableOnTest(true);
      setTooltipMessage(
        `This cell is part of module MOD${mod?.module_id}${sn_str}, so ` +
          "an individual cell test cannot be generated. Either remove this cell from " +
          "the module before generating a new test, or put the entire module on-test " +
          "from the experiment details page."
      );
    } else if (cell_assembly === FSC_BATTERY_MODULE) {
      setDisableOnTest(true);
      setTooltipMessage(
        `${FSC_BATTERY_MODULE} cells must be put on-test with a module. ` +
          "Please add this cell to a new/existing module and put the entire module on-test " +
          "from the experiment details page."
      );
    }
  }, [
    disabled_module,
    disabled_prev_steps,
    disabled_pre_committed,
    cell_assembly,
    mod,
  ]);

  useEffect(() => {
    if (warnNoQCCheck && !tooltipMessage) {
      setTooltipMessage(NO_QUALITY_CHECK_WARNING);
    }
    if (!warnNoQCCheck && tooltipMessage === NO_QUALITY_CHECK_WARNING) {
      setTooltipMessage("");
    }
  }, [warnNoQCCheck, tooltipMessage]);

  /* Save modal */
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveModal = (
    <Modal open={saveModalOpen} onClose={() => setSaveModalOpen(false)}>
      <>
        <Typography variant="h2">Save inputs ?</Typography>
        <Box mt={8} mb={2}>
          <Typography color="textSecondary">
            You have unsaved inputs. You must save all inputs in order to
            continue.
          </Typography>
        </Box>
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button color="secondary" onClick={() => setSaveModalOpen(false)}>
              Cancel
            </Button>
          </Box>
          <Button
            color="primary"
            onClick={() =>
              onSave(() => {
                setSaveModalOpen(false);
                setTestModalOpen(true);
              })
            }
            endIcon={
              saveStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
            disabled={saveStatus === "loading"}
          >
            Save
          </Button>
        </Box>
      </>
    </Modal>
  );

  /* Test modal */
  const [testModalOpen, setTestModalOpen] = useState(false);
  const { conditionIdsToRequestedCellIds } = parseCellIdString(cell_id_string);
  const testModal = (
    <TestModal
      open={testModalOpen}
      onClose={() => setTestModalOpen(false)}
      cell_ids={[cell_id]}
      conditionIdsToCellIdsForMetadataFetch={conditionIdsToRequestedCellIds}
      reserved_channel={reserved_channel}
      reserved_test_stand={reserved_test_stand}
      executor={executor}
      enable_standard_tests={enable_standard_tests}
      isFullScale={isFullScale}
    />
  );

  if (!completed_at) {
    return (
      <>
        <Box p={2}>
          <Tooltip
            arrow
            title={tooltipMessage}
            disableFocusListener={!disableOnTest}
            disableHoverListener={!disableOnTest}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <Button
                color="tertiary"
                type="button"
                size="small"
                disabled={disableOnTest}
                style={{
                  padding: "0.5rem",
                }}
                onClick={() =>
                  isDirty ? setSaveModalOpen(true) : setTestModalOpen(true)
                }
              >
                <b>Generate Test</b>
              </Button>
              {warnNoQCCheck && (
                <ExclamationCircleOutlinedIcon
                  style={{
                    color: colors.accent.warningRed,
                    width: 16,
                    height: 16,
                    transform: "rotate(180deg)",
                  }}
                />
              )}
            </span>
          </Tooltip>
        </Box>
        {saveModal}
        {testModal}
      </>
    );
  }

  return (
    <>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton size="small" onClick={handleCheckButtonClick}>
          <CheckCircleIcon
            style={{
              width: 20,
              height: 20,
              color: colors.accent.green,
            }}
          />
          <CaretBottomIcon
            style={{
              marginLeft: "0.5rem",
              width: 16,
              height: 16,
              color: colors.text.secondary,
              transform: !checkButtonEl ? "rotate(-90deg)" : undefined,
            }}
          />
        </IconButton>

        <Tooltip
          arrow
          title={tooltipMessage}
          disableFocusListener={!disableOnTest}
          disableHoverListener={!disableOnTest}
        >
          <span>
            <Button
              color="tertiary"
              type="button"
              size="small"
              disabled={disableOnTest}
              style={{
                padding: "0.5rem",
              }}
              onClick={() =>
                isDirty ? setSaveModalOpen(true) : setTestModalOpen(true)
              }
            >
              <b>Generate new test</b>
            </Button>
          </span>
        </Tooltip>

        <Menu
          anchorEl={checkButtonEl}
          open={!!checkButtonEl}
          onClose={handleCheckButtonClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ListItem>
            <Box display="flex" alignItems="center">
              <Avatar className="bg-cta">
                {getInitialsForUser(completed_by)}
              </Avatar>
              <Box ml={3} mr={2}>
                {completed_by?.name}
              </Box>
              <Box color={colors.text.secondary}>
                {isoDateToDateTimeString(completed_at)}
              </Box>
            </Box>
          </ListItem>
        </Menu>
      </Box>
      {saveModal}
      {testModal}
    </>
  );
};

export default TestCell;
