import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../../components/Button";
import Link from "../../components/Link";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";
import ArrowBackIcon from "../../icons/ArrowBack";
import {
  cellIdToString,
  expIdToString,
  getInitialsForUser,
  isoDateToDateTimeString,
} from "../../utils/labels";
import DiffBlock from "./DiffBlock";
import {
  approveChangeRequests,
  ChangeRequestState,
  getSingleChangeRequest,
  rejectChangeRequests,
  resetApproveChangeRequests,
  resetGetSingleChangeRequest,
  resetRejectChangeRequests,
  resetUndoChangeRequests,
} from "./slice";
import { RootState } from "../../store";

const ChangeRequestLayout = () => {
  const navigate = useNavigate();
  const { cell_condition_id = "" } = useParams();
  const isTablet = useMediaQuery("(min-width:760px)");

  const dispatch = useDispatch();
  const {
    singleChangeRequest,
    status: {
      single: singleStatus,
      approve: approveStatus,
      reject: rejectStatus,
      undo: undoStatus,
    },
    error: {
      single: singleError,
      approve: approveError,
      reject: rejectError,
      undo: undoError,
    },
  } = useSelector<RootState, ChangeRequestState>(
    ({ changeRequests }) => changeRequests
  );

  const [rejecting, setRejecting] = useState<ChangeRequest[]>([]);
  const [rejectingReason, setRejectingReason] = useState("");

  useEffect(() => {
    dispatch(resetGetSingleChangeRequest());
    dispatch(getSingleChangeRequest(cell_condition_id));
  }, [dispatch, cell_condition_id]);

  useEffect(() => {
    if (approveStatus === "succeeded") {
      dispatch(getSingleChangeRequest(cell_condition_id));
      dispatch(resetApproveChangeRequests());
    }
  }, [dispatch, cell_condition_id, approveStatus]);

  useEffect(() => {
    if (rejectStatus === "succeeded") {
      dispatch(getSingleChangeRequest(cell_condition_id));
      dispatch(resetRejectChangeRequests());
      setRejecting([]);
      setRejectingReason("");
    }
  }, [dispatch, cell_condition_id, rejectStatus]);

  useEffect(() => {
    if (undoStatus === "succeeded") {
      dispatch(getSingleChangeRequest(cell_condition_id));
      dispatch(resetUndoChangeRequests());
    }
  }, [dispatch, cell_condition_id, undoStatus]);

  let singleToast;
  if (singleStatus === "failed") {
    singleToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetGetSingleChangeRequest())}
      >
        {singleError}
      </Toast>
    );
  }

  let approveToast;
  if (approveStatus === "failed") {
    approveToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetApproveChangeRequests())}
      >
        {approveError}
      </Toast>
    );
  }

  let rejectToast;
  if (rejectStatus === "failed") {
    rejectToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetRejectChangeRequests())}
      >
        {rejectError}
      </Toast>
    );
  }

  let undoToast;
  if (undoStatus === "failed") {
    undoToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetUndoChangeRequests())}
      >
        {undoError}
      </Toast>
    );
  }

  const { name, exp_id, cells, change_requests } = singleChangeRequest || {};

  const onRejectAll = () => {
    if (!change_requests) {
      return;
    }

    setRejecting(change_requests.filter(({ status }) => status === "P"));
  };

  const onApproveAll = () => {
    if (!change_requests) {
      return;
    }

    dispatch(
      approveChangeRequests(
        change_requests
          .filter(({ status }) => status === "P")
          .map(({ change_request_id }) => change_request_id)
      )
    );
  };

  const onReject = () => {
    dispatch(
      rejectChangeRequests({
        change_request_ids: rejecting.map(
          ({ change_request_id }) => change_request_id
        ),
        reason: rejectingReason,
      })
    );
  };

  return (
    <>
      <Box
        py={isTablet ? 6 : 0}
        px={isTablet ? 8 : 0}
        mx="auto"
        style={{
          maxWidth: 760,
        }}
      >
        <Box my={isTablet ? 4 : 0}>
          <Paper variant="outlined" square>
            <Box
              px={isTablet ? 6 : 0}
              pt={isTablet ? 6 : 0}
              pb={isTablet ? 6 : 4}
            >
              <Grid container>
                <Grid item xs={12} lg={8} display="flex" alignItems="center">
                  <IconButton
                    size="large"
                    onClick={() => navigate("/change-requests")}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant={isTablet ? "h2" : "h3"}>
                    Specification Change Request
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={4} my="auto">
                  <Box
                    display="flex"
                    justifyContent={isTablet ? "flex-end" : "flex-start"}
                    alignItems="center"
                    mt={isTablet ? 0 : 6}
                    px={isTablet ? 0 : 4}
                  >
                    <Button
                      disabled={
                        !change_requests?.some(({ status }) => status === "P")
                      }
                      onClick={onRejectAll}
                      className="small"
                      color="secondary"
                    >
                      Reject All
                    </Button>
                    <Box ml={4}>
                      <Button
                        disabled={
                          !change_requests?.some(({ status }) => status === "P")
                        }
                        onClick={onApproveAll}
                        className="small"
                        color="primary"
                      >
                        Approve All
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" mt={6} px={isTablet ? 0 : 4}>
                    <Box className="small">
                      <Typography
                        color="textSecondary"
                        fontWeight={500}
                        paragraph
                      >
                        Condition
                      </Typography>
                      <Typography
                        color="textSecondary"
                        fontWeight={500}
                        paragraph
                      >
                        EXP ID
                      </Typography>
                      <Typography color="textSecondary" fontWeight={500}>
                        Cell ID(s)
                      </Typography>
                    </Box>
                    <Box ml={6}>
                      <Typography paragraph>{name}</Typography>
                      <Typography paragraph>
                        <Link color="textPrimary" to={`/experiments/${exp_id}`}>
                          {expIdToString(`${exp_id}`)}
                        </Link>
                      </Typography>
                      <Typography>
                        {cells
                          ?.map<React.ReactNode>(({ cell_id }) => (
                            <Link color="textPrimary" to={`/cells/${cell_id}`}>
                              {cellIdToString(`${cell_id}`)}
                            </Link>
                          ))
                          .reduce((prev, curr) => [prev, ", ", curr])}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {change_requests?.map((changeRequest, index) => (
                  <Fragment key={index}>
                    <Grid item xs={7}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt={6}
                        mb={4}
                        pl={isTablet ? 0 : 4}
                      >
                        <Avatar>
                          {getInitialsForUser(changeRequest.requested_by)}
                        </Avatar>
                        <Box ml={2}>{changeRequest.requested_by.name}</Box>
                      </Box>
                    </Grid>

                    <Grid item xs={5} my="auto" textAlign="right">
                      <Box mt={6} mb={4} pr={isTablet ? 0 : 4}>
                        <Typography color="textSecondary" className="small">
                          {isoDateToDateTimeString(changeRequest.requested_at)}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box px={isTablet ? 0 : 4}>
                        <Divider />
                        <DiffBlock
                          showActions
                          request={changeRequest}
                          onReject={() => setRejecting([changeRequest])}
                        />
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Modal open={rejecting.length > 0} onClose={() => setRejecting([])}>
        <Typography variant="h2">Reject change request</Typography>
        <Box mt={6}>
          <Typography className="small" color="textSecondary">
            Add note
          </Typography>
        </Box>
        <Box mt={2}>
          <FormControl style={{ width: "100%", height: "100%" }}>
            <Input
              disableUnderline
              inputComponent={TextareaAutosize}
              inputProps={{ minRows: 2 }}
              value={rejectingReason}
              onChange={(e) => setRejectingReason(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mt={6} textAlign="right">
          <Button onClick={onReject} className="small" color="secondary">
            Reject
          </Button>
        </Box>
      </Modal>
      {singleToast}
      {approveToast}
      {rejectToast}
      {undoToast}
    </>
  );
};

export default ChangeRequestLayout;
