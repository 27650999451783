import React, {
  useState,
  useRef,
  useContext,
  createContext,
  forwardRef,
} from "react";
import { VariableSizeList } from "react-window";
import type { VariableSizeListProps } from "react-window";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

type VirtualTableContext = {
  top: number;
  setTop: (top: number) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

const context = createContext<VirtualTableContext>({
  top: 0,
  setTop: (value: number) => {},
  header: null,
  footer: null,
});

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  row: VariableSizeListProps["children"];
} & Omit<VariableSizeListProps, "children" | "innerElementType">;

const VirtualTable = ({ row, header, footer, ...props }: Props) => {
  const listRef = useRef<VariableSizeList | null>();
  const [top, setTop] = useState(0);

  return (
    <context.Provider value={{ top, setTop, header, footer }}>
      <VariableSizeList
        {...props}
        innerElementType={PartialTable}
        onItemsRendered={(params) => {
          const style =
            listRef.current &&
            // @ts-ignore private method access
            listRef.current._getItemStyle(params.overscanStartIndex);
          setTop((style && style.top) || 0);

          // Call the original callback
          props.onItemsRendered && props.onItemsRendered(params);
        }}
        ref={(el) => (listRef.current = el)}
      >
        {row}
      </VariableSizeList>
    </context.Provider>
  );
};

const PartialTable = forwardRef<HTMLDivElement>(
  ({ children, ...props }, ref) => {
    const { header, footer, top } = useContext(context);

    return (
      <div {...props} ref={ref}>
        <Table
          stickyHeader
          className="dataTable"
          size="small"
          style={{ top, position: "absolute", width: "100%" }}
        >
          {header}
          <TableBody>{children}</TableBody>
          {footer}
        </Table>
      </div>
    );
  }
);

export default VirtualTable;
