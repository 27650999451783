import Card from "@mui/material/Card";
import type { CardProps } from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/styles/styled";
import TimesIcon from "../icons/Times";
import colors from "../theme/colors";

const CardCloseButton = styled(IconButton)({
  position: "absolute",
  borderRadius: "50rem",
  padding: "0.25rem",
  background: "white",
  border: `1px solid ${colors.rules}`,
  top: "-0.5rem",
  right: "-0.5rem",
  "& .MuiSvgIcon-root": {
    width: "1rem",
    height: "1rem",
    color: colors.text.secondary,
  },
});

type Props = {
  disabled?: boolean;
  onDelete: () => void;
} & CardProps;

const DeletableCard = ({ onDelete, disabled, ...props }: Props) => (
  <div style={{ position: "relative" }}>
    <CardCloseButton size="small" onClick={onDelete} disabled={disabled}>
      <TimesIcon />
    </CardCloseButton>

    <Card {...props} />
  </div>
);

export default DeletableCard;
