import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isNull from "lodash/isNull";
import uniq from "lodash/uniq";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import TableBody from "../cells/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  resetCellListStatus,
  listCells,
  enableGrouping,
  resetPriority,
  resetReadyOff,
  resetDiscontinue,
  resetTakeOff,
  collapseAllGroups,
  expandAllGroups,
  expandGroup,
  collapseGroup,
  expandCondition,
  collapseCondition,
} from "./slice";
import ModCellOptions from "./CellOptions";
import type { ModuleState } from "./slice";
import type { AuthState } from "../../store/auth/slice";
import type { RootState } from "../../store";
import colors from "../../theme/colors";
import {
  CELL_LABELS_DEFAULT,
  CELL_LABELS_EXPERIMENT,
  SAS_MODULE,
  cellIdToString,
  cellStatusToString,
  isoDateToDateString,
} from "../../utils/labels";
import ConditionMenu from "../cells/ConditionMenu";
import ExperimentLink from "../../components/ExperimentLink";
import CellTableCell from "../cells/CellTableCell";
import Toast from "../../components/Toast";
import ExpandIcon from "../../icons/Expand";
import SearchBarIcon from "../../icons/SearchBar";
import PriorityChip from "../../components/PriorityChip";
import {
  EMPTY_STATE_ROW_HEIGHT,
  GROUPING_HEADER_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import TestModal from "../metadata/layout/TestModal";
import { shiftClickList } from "../../utils/shiftClick";

type Props = {
  module: Module | ExpModule;
  onCellLoad?: (cells: Cell[]) => void;
  hideControls?: boolean;
  exp_id?: string;
  status?: CellStatus[];
};

const ModuleCellList = ({
  module: _module,
  onCellLoad,
  hideControls = false,
  exp_id: presetExpId,
  status: presetStatus = [],
}: Props) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const {
    args: { order, orderBy, filters, presetFilters, page },
    grouping: {
      groupingEnabled,
      allGroupsExpanded,
      expandedGroups,
      expandedConditions,
    },
    justFlaggedReadyOff,
    justUnflaggedReadyOff,
    justDiscontinued,
    justUndiscontinued,
    hasMore,
    status: {
      list: status,
      priority: priorityStatus,
      readyOff: readyOffStatus,
      takeOff: takeOffStatus,
      discontinue: discontinueStatus,
      channelUsage: channelUsageStatus,
    },
    error: {
      list: error,
      priority: priorityError,
      readyOff: readyOffError,
      takeOff: takeOffError,
      discontinue: discontinueError,
    },
  } = useSelector<RootState, ModuleState>(
    (state) => state.modules as ModuleState
  );
  const cells = _module.cells!;
  const module_id = _module.module_id;
  const [selected, setSelected] = useState<number[]>([]);
  const selectedCells = cells.filter((cell) => selected.includes(cell.cell_id));
  const stateKey = "EXPERIMENT";

  useEffect(() => {
    onCellLoad && onCellLoad(cells);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells]);

  const CELL_LABELS = presetExpId
    ? CELL_LABELS_EXPERIMENT
    : CELL_LABELS_DEFAULT;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetCellListStatus());
  }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      const presetFilters: CellFilters = {};
      if (presetExpId) {
        presetFilters.experiment__exp_id = [`${presetExpId}`];
      }
      if (module_id) {
        presetFilters.module__module_id = [`${module_id}`];
      }
      if (presetStatus.length > 0) {
        presetFilters.status = presetStatus;
      }

      dispatch(
        listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page,
          pageSize: undefined,
          refresh: true,
          useV2API: true,
          columns: CELL_LABELS.map(({ id }) => id),
        })
      );

      if (presetStatus.length > 0) {
        dispatch(enableGrouping(cells));
      }
    }
  }, [
    cells,
    presetExpId,
    module_id,
    presetStatus,
    dispatch,
    status,
    order,
    orderBy,
    filters,
    presetFilters,
    page,
    CELL_LABELS,
  ]);

  let errorToast;
  if (status === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(
            listCells({
              order,
              orderBy,
              filters,
              presetFilters,
              page: 0,
              pageSize: undefined,
              useV2API: true,
              columns: CELL_LABELS.map(({ id }) => id),
            })
          )
        }
      >
        {error}
      </Toast>
    );
  }

  let priorityToast;
  if (priorityStatus === "failed") {
    priorityToast = (
      <Toast open severity="error" onClose={() => dispatch(resetPriority())}>
        {priorityError}
      </Toast>
    );
  }

  useEffect(() => {
    if (priorityStatus === "succeeded") {
      dispatch(
        listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          pageSize: undefined,
          useV2API: true,
          columns: CELL_LABELS.map(({ id }) => id),
        })
      );
    }
  }, [
    dispatch,
    priorityStatus,
    order,
    orderBy,
    filters,
    presetFilters,
    CELL_LABELS,
  ]);

  let readyOffToast;
  if (readyOffStatus === "failed") {
    readyOffToast = (
      <Toast open severity="error" onClose={() => dispatch(resetReadyOff())}>
        {readyOffError}
      </Toast>
    );
  } else if (readyOffStatus === "succeeded" && justFlaggedReadyOff.length > 0) {
    readyOffToast = (
      <Toast open severity="success" onClose={() => dispatch(resetReadyOff())}>
        {justFlaggedReadyOff.length === 1
          ? `${cellIdToString(justFlaggedReadyOff[0])} flagged as Ready off`
          : `${justFlaggedReadyOff.length} cells marked as Ready off`}
      </Toast>
    );
  } else if (
    readyOffStatus === "succeeded" &&
    justUnflaggedReadyOff.length > 0
  ) {
    readyOffToast = (
      <Toast open severity="success" onClose={() => dispatch(resetReadyOff())}>
        {justUnflaggedReadyOff.length === 1
          ? `Ready off flag removed for ${cellIdToString(
              justUnflaggedReadyOff[0]
            )}`
          : `Ready off flag removed for ${justUnflaggedReadyOff.length} cells`}
      </Toast>
    );
  }

  useEffect(() => {
    if (readyOffStatus === "succeeded") {
      dispatch(
        listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
          useV2API: true,
          columns: CELL_LABELS.map(({ id }) => id),
        })
      );
    }
  }, [
    dispatch,
    readyOffStatus,
    order,
    orderBy,
    filters,
    presetFilters,
    CELL_LABELS,
  ]);

  let discontinueToast;
  if (discontinueStatus === "failed") {
    discontinueToast = (
      <Toast open severity="error" onClose={() => dispatch(resetDiscontinue())}>
        {discontinueError}
      </Toast>
    );
  } else if (discontinueStatus === "succeeded" && justDiscontinued.length > 0) {
    discontinueToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetDiscontinue())}
      >
        {justDiscontinued.length === 1
          ? `${cellIdToString(justDiscontinued[0])} marked as Discontinued`
          : `${justDiscontinued.length} cells marked as Discontinued`}
      </Toast>
    );
  } else if (
    discontinueStatus === "succeeded" &&
    justUndiscontinued.length > 0
  ) {
    discontinueToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetDiscontinue())}
      >
        {justUndiscontinued.length === 1
          ? `${cellIdToString(justUndiscontinued[0])} unmarked as Discontinued`
          : `${justUndiscontinued.length} cells unmarked as Discontinued`}
      </Toast>
    );
  }

  useEffect(() => {
    if (discontinueStatus === "succeeded") {
      // dispatch(cellsSlices.ALL.getCellTotals());
      dispatch(
        listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
          useV2API: true,
          columns: CELL_LABELS.map(({ id }) => id),
        })
      );
    }
  }, [
    dispatch,
    discontinueStatus,
    order,
    orderBy,
    filters,
    presetFilters,
    CELL_LABELS,
  ]);

  let takeOffToast;
  if (takeOffStatus === "failed") {
    takeOffToast = (
      <Toast open severity="error" onClose={() => dispatch(resetTakeOff())}>
        {takeOffError}
      </Toast>
    );
  } else if (takeOffStatus === "succeeded") {
    takeOffToast = (
      <Toast open severity="success" onClose={() => dispatch(resetTakeOff())}>
        The selected cell(s) have been taken off test.
      </Toast>
    );
  }

  useEffect(() => {
    if (takeOffStatus === "succeeded") {
      dispatch(
        listCells({
          order,
          orderBy,
          filters,
          presetFilters,
          page: 0,
          refresh: true,
          useV2API: true,
          columns: CELL_LABELS.map(({ id }) => id),
        })
      );
    }
  }, [
    dispatch,
    takeOffStatus,
    order,
    orderBy,
    filters,
    presetFilters,
    CELL_LABELS,
  ]);

  /* Test modal */
  const [testModalOpen, setTestModalOpen] = useState(false);
  const testModal = presetStatus.includes("L") ? (
    <TestModal
      open={testModalOpen}
      onClose={() => {
        setTestModalOpen(false);
        dispatch(
          listCells({
            order,
            orderBy,
            filters,
            presetFilters,
            page: 0,
            pageSize: undefined,
            useV2API: true,
            columns: CELL_LABELS.map(({ id }) => id),
          })
        );
      }}
      reserved_channel={
        selectedCells.find((cell) => !!cell.reserved_channel.channel.fullname)
          ?.reserved_channel.channel.fullname || ""
      }
      executor={user}
      conditionIdsToCellIdsForMetadataFetch={selectedCells.reduce(
        (
          conditionIdsToRequestedCellIds: { [key: string]: string[] },
          selectedCell
        ) => {
          const cellConditionId = selectedCell.condition.cell_condition_id;
          if (!cellConditionId) {
            return conditionIdsToRequestedCellIds;
          }
          if (
            !Object.keys(conditionIdsToRequestedCellIds).includes(
              String(cellConditionId)
            )
          ) {
            conditionIdsToRequestedCellIds[cellConditionId] = [];
          }
          conditionIdsToRequestedCellIds[cellConditionId] = [
            ...conditionIdsToRequestedCellIds[cellConditionId],
            String(selectedCell.cell_id),
          ];
          return conditionIdsToRequestedCellIds;
        },
        {}
      )}
      selectedCells={selectedCells}
    />
  ) : null;

  const handleSortClick = (e: React.MouseEvent, property: FalconKey<Cell>) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      listCells({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        presetFilters,
        page: 0,
        pageSize: undefined,
        useV2API: true,
        columns: CELL_LABELS.map(({ id }) => id),
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      listCells({
        order,
        orderBy,
        filters,
        presetFilters,
        page: page + 1,
        pageSize: undefined,
        useV2API: true,
        columns: CELL_LABELS.map(({ id }) => id),
      })
    );
  };

  const conditionGroupingEnabled = [
    "condition__specified",
    "condition__name",
    "condition__description",
    "experiment__exp_id",
  ].includes(orderBy);

  const handleToggleExpandAll = () => {
    if (!allGroupsExpanded && expandedGroups.length > 0) {
      dispatch(collapseAllGroups());
    } else if (allGroupsExpanded) {
      dispatch(collapseAllGroups());
    } else {
      dispatch(expandAllGroups(cells));
    }
  };

  const handleSelectCells = (ids: number[], shiftClick = false) => {
    const allSelected = !ids.some((id) => !selected.includes(id));
    if (allSelected) {
      const _selected = [...selected];
      ids.forEach((id) => {
        const index = _selected.indexOf(id);
        if (index !== -1) {
          _selected.splice(index, 1);
        }
      });
      setSelected(uniq(_selected));
    } else {
      let idsToSelect = shiftClickList(selected, ids, cells, shiftClick);
      setSelected(uniq([...selected, ...idsToSelect]));
    }
  };

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(cells.map((cell) => cell.cell_id));
    }
  };

  const handleToggleGrouping = (event: React.MouseEvent, value: any) => {
    event.stopPropagation();
    const index = expandedGroups.indexOf(value);
    if (index === -1) {
      dispatch(expandGroup(value));
    } else {
      dispatch(collapseGroup({ value: value, cells: cells }));
    }
  };

  const handleToggleConditionGrouping = (
    event: React.MouseEvent,
    value: number
  ) => {
    event.stopPropagation();
    const index = expandedConditions.indexOf(value);
    if (index === -1) {
      dispatch(expandCondition(value));
    } else {
      dispatch(collapseCondition(value));
    }
  };

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (cells.length === 0 && status === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={CELL_LABELS.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No cells to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let lastValue: any;
    let lastConditionValue: any;
    let rowIndex = 0;

    const _orderBy = orderBy;
    tableBody = cells
      .map((row, index) => {
        const res = _orderBy.split("__");
        const orderBy = res[0] as keyof Cell;

        const currentConditionValue = row.condition.cell_condition_id!;
        let prevConditionValue: string | number | null = null;
        let subConditionCells: Cell[] = [];

        let prevRow;
        if (index - 1 >= 0) {
          prevRow = cells[index - 1];
        }

        if (conditionGroupingEnabled) {
          // Special handling for default ungrouped all cells view
          subConditionCells = cells.filter(
            (cell) => cell.condition.cell_condition_id === currentConditionValue
          );

          if (prevRow) {
            prevConditionValue = prevRow.condition.cell_condition_id!;
          }
        }

        let currentValue: string | number;
        let subCells: Cell[] = [];

        if (_orderBy === "condition__specified") {
          currentValue = isoDateToDateString(row.condition.specified);
          subCells = cells.filter(
            (cell) =>
              isoDateToDateString(cell.condition.specified) === currentValue
          );
        } else if (_orderBy === "staged") {
          currentValue = isoDateToDateString(row.staged);
          subCells = cells.filter(
            (cell) => isoDateToDateString(cell.staged) === currentValue
          );
        } else if (_orderBy === "committed") {
          currentValue = isoDateToDateString(row.committed);
          subCells = cells.filter(
            (cell) => isoDateToDateString(cell.committed) === currentValue
          );
        } else if (_orderBy === "test__created_time") {
          currentValue = isoDateToDateString(row.test.created_time);
          subCells = cells.filter(
            (cell) =>
              isoDateToDateString(cell.test.created_time) === currentValue
          );
        } else if (_orderBy === "ready_off__completed_at") {
          currentValue = isoDateToDateString(row.ready_off.completed_at);
          subCells = cells.filter(
            (cell) =>
              isoDateToDateString(cell.ready_off.completed_at) === currentValue
          );
        } else {
          const orderBySubkey = res[1];

          // TODO proper typing
          if (orderBySubkey) {
            currentValue = (row[orderBy] as any)[orderBySubkey];
            subCells = cells.filter(
              (cell) => (cell[orderBy] as any)[orderBySubkey] === currentValue
            );
          } else {
            currentValue = row[orderBy] as string | number;
            subCells = cells.filter(
              (cell) => (cell[orderBy] as string | number) === currentValue
            );
          }
        }

        const isSubRow = groupingEnabled && lastValue === currentValue;

        if (isSubRow && !expandedGroups.includes(lastValue)) {
          return null;
        }

        const isConditionSubRow =
          conditionGroupingEnabled &&
          lastConditionValue === currentConditionValue;

        if (
          isConditionSubRow &&
          !expandedConditions.includes(lastConditionValue)
        ) {
          return null;
        }

        if (
          (conditionGroupingEnabled && !isConditionSubRow) ||
          !conditionGroupingEnabled
        ) {
          rowIndex++;
        }

        const showGroupingHeader =
          groupingEnabled && lastValue !== currentValue;

        if (showGroupingHeader) {
          rowIndex = 0;
        }

        const showConditionGroupingHeader =
          conditionGroupingEnabled &&
          prevConditionValue !== currentConditionValue &&
          subConditionCells.length > 1;

        lastValue = currentValue;
        lastConditionValue = currentConditionValue;

        const isGroupExpanded = expandedGroups.includes(currentValue);
        const isConditionExpanded = expandedConditions.includes(
          currentConditionValue
        );

        let groupingRow;
        if (showGroupingHeader) {
          const indeterminate =
            subCells.some(({ cell_id }) => !selected.includes(cell_id)) &&
            subCells.some(({ cell_id }) => selected.includes(cell_id));

          const checked = !subCells.some(
            ({ cell_id }) => !selected.includes(cell_id)
          );

          const headerLabel = CELL_LABELS.find(({ id }) => id === _orderBy);

          groupingRow = (
            <TableRow
              key={`header-group-${row.cell_id}`}
              className="clickable"
              hover
              onClick={(e) => handleToggleGrouping(e, currentValue)}
            >
              <TableCell
                padding="checkbox"
                style={{
                  paddingTop: 12,
                  height: GROUPING_HEADER_ROW_HEIGHT - 12,
                }}
              >
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={checked}
                  disabled={channelUsageStatus === "loading"}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectCells(
                      subCells.map(({ cell_id }) => cell_id),
                      !!(e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox" style={{ paddingTop: "0.75rem" }}>
                <IconButton
                  size="small"
                  onClick={(e) => handleToggleGrouping(e, currentValue)}
                >
                  <ExpandIcon
                    style={
                      isGroupExpanded ? { transform: "rotate(90deg)" } : {}
                    }
                  />
                </IconButton>
              </TableCell>
              <TableCell
                colSpan={CELL_LABELS.length}
                scope="row"
                padding="none"
              >
                <Box pt={6} pb={2} display="flex" alignItems="center">
                  <Typography variant="h3">{headerLabel?.label}: </Typography>
                  <Box ml={3}>
                    <Typography variant="h3" style={{ fontWeight: 400 }}>
                      {_orderBy === "status" ? (
                        cellStatusToString(row.status) || "-"
                      ) : _orderBy === "cell_id" ? (
                        cellIdToString(row.cell_id) || "-"
                      ) : _orderBy === "priority" ? (
                        <PriorityChip label={row.priority} />
                      ) : _orderBy === "experiment__exp_id" ? (
                        <ExperimentLink
                          exp_id={row.experiment.exp_id}
                          link={row.experiment.link}
                          linkView
                        />
                      ) : (
                        currentValue || "-"
                      )}
                    </Typography>
                  </Box>
                  {_orderBy === "experiment__exp_id" &&
                  row.experiment.description ? (
                    <Box ml={3}>
                      <Typography style={{ fontWeight: 400 }}>
                        {row.experiment.description}
                      </Typography>
                    </Box>
                  ) : null}
                  <Box ml={3}>
                    <Typography style={{ fontWeight: 400 }}>
                      ({subCells.length} cell{subCells.length !== 1 ? "s" : ""})
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          );

          if (!isGroupExpanded && groupingRow) {
            rowHeights.push(GROUPING_HEADER_ROW_HEIGHT);
            return groupingRow;
          }
        }

        let conditionGroupingRow;
        if (conditionGroupingEnabled && showConditionGroupingHeader) {
          const indeterminate = isConditionExpanded
            ? false
            : subConditionCells.some(
                ({ cell_id }) => !selected.includes(cell_id)
              ) &&
              subConditionCells.some(({ cell_id }) =>
                selected.includes(cell_id)
              );

          const checked = isConditionExpanded
            ? selected.includes(row.cell_id)
            : !subConditionCells.some(
                ({ cell_id }) => !selected.includes(cell_id)
              );

          conditionGroupingRow = (
            <TableRow
              hover
              key={`header-condition-${row.condition.cell_condition_id}`}
              className={`clickable ${
                rowIndex % 2 !== 0 ? "alternating" : ""
              } ${checked ? "checked" : ""} ${
                isConditionSubRow ? "subRow" : ""
              }`}
              onClick={(e) =>
                isConditionExpanded
                  ? navigate(`/cells/${row.cell_id}`, { state: { stateKey } })
                  : handleToggleConditionGrouping(e, currentConditionValue)
              }
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={checked}
                  disabled={channelUsageStatus === "loading"}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectCells(
                      isConditionExpanded
                        ? [row.cell_id]
                        : [
                            row.cell_id,
                            ...subConditionCells.map(({ cell_id }) => cell_id),
                          ],
                      !!(e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox">
                <IconButton
                  size="small"
                  onClick={(e) =>
                    handleToggleConditionGrouping(e, currentConditionValue)
                  }
                >
                  <ExpandIcon
                    style={
                      isConditionExpanded ? { transform: "rotate(90deg)" } : {}
                    }
                  />
                </IconButton>
              </TableCell>

              {CELL_LABELS.map(({ id }, index) => (
                <CellTableCell
                  key={index}
                  dataKey={id}
                  cell={row}
                  isConditionExpanded={isConditionExpanded}
                  subConditionCells={subConditionCells}
                />
              ))}

              {presetExpId ? (
                <TableCell style={{ position: "relative" }}>
                  <ConditionMenu
                    exp_id={row.experiment.exp_id}
                    disabled={!!row.backfill}
                    cell_condition_id={row.condition.cell_condition_id!}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          );
        }

        let totalHeight = DATA_ROW_HEIGHT;
        if (groupingRow) totalHeight += GROUPING_HEADER_ROW_HEIGHT;
        rowHeights.push(totalHeight);

        return (
          <React.Fragment key={`grouping-${row.cell_id}`}>
            {groupingRow}
            {conditionGroupingRow}
            {conditionGroupingEnabled && conditionGroupingRow ? null : (
              <TableRow
                hover
                key={row.cell_id}
                className={`clickable ${
                  rowIndex % 2 !== 0 ? "alternating" : ""
                } ${selected.includes(row.cell_id) ? "checked" : ""} ${
                  isConditionSubRow ? "subRow" : ""
                }`}
                onClick={() =>
                  navigate(`/cells/${row.cell_id}`, { state: { stateKey } })
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    checked={selected.includes(row.cell_id)}
                    disabled={channelUsageStatus === "loading"}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      handleSelectCells(
                        [row.cell_id],
                        !!(e.nativeEvent as MouseEvent).shiftKey
                      )
                    }
                  />
                </TableCell>
                <TableCell padding="none" />
                {CELL_LABELS.map(({ id }, index) => (
                  <CellTableCell
                    key={index}
                    dataKey={id}
                    cell={row}
                    isConditionExpanded={true}
                    subConditionCells={[]}
                  />
                ))}
                {presetExpId ? (
                  <TableCell style={{ position: "relative" }}>
                    <ConditionMenu
                      exp_id={row.experiment.exp_id}
                      disabled={!!row.backfill}
                      cell_condition_id={row.condition.cell_condition_id!}
                    />
                  </TableCell>
                ) : null}
              </TableRow>
            )}
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  return (
    <>
      {/*Table Header*/}
      <Box p={6} display="flex" alignItems="center">
        <Typography variant="h3">
          {_module.module_type?.includes("Virtual") && "Virtual "}Module
          {_module.name && ` ${_module.name}`}:{" "}
          <span style={{ fontWeight: 400 }}>
            MOD{module_id}
            {_module.serial_number && ` (Serial Num: ${_module.serial_number})`}
          </span>
          {"data_source" in _module && !!_module.data_source && (
            <div>
              <span
                style={{ fontWeight: 400, color: "gray", fontSize: "15px" }}
              >
                Data Source Filename: {_module.data_source}
              </span>
            </div>
          )}
        </Typography>
        <Box ml="auto">
          <ModCellOptions
            exp_id={`${_module.exp_id!}`}
            module_id={`${module_id!}`}
            has_signal_map={
              "has_signal_map" in _module && !!_module.has_signal_map
            }
            isBAM={_module.module_type === SAS_MODULE}
            bamSN={_module.serial_number}
            cells={cells}
            selected={selected}
          />
        </Box>
      </Box>

      <TableBody
        rowHeights={rowHeights}
        tableBody={tableBody}
        selected={selected}
        hasMore={hasMore}
        cells={cells}
        disabled={channelUsageStatus === "loading"}
        handleToggleSelectAll={handleToggleSelectAll}
        groupingEnabled={groupingEnabled}
        conditionGroupingEnabled={conditionGroupingEnabled}
        handleToggleExpandAll={handleToggleExpandAll}
        allGroupsExpanded={allGroupsExpanded}
        CELL_LABELS={CELL_LABELS}
        presetExpId={presetExpId}
        orderBy={orderBy}
        order={order}
        handleSortClick={handleSortClick}
        handleLoadMoreClick={handleLoadMoreClick}
        hideControls={hideControls}
        status={status}
        errorToast={errorToast}
        priorityToast={priorityToast}
        readyOffToast={readyOffToast}
        takeOffToast={takeOffToast}
        discontinueToast={discontinueToast}
        testModal={testModal}
      />
    </>
  );
};

export default ModuleCellList;
