import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import client, { BACKEND_HOST } from "../api";

const FRESH_TOKEN_LIFETIME = { minutes: 30 };
const AUTH_STORE = "authStore";
export const AUTH_TOKENS = `${AUTH_STORE}/authTokens`;
export const CURRENT_USER = `${AUTH_STORE}/currentUser`;

let refreshTokenFails = 0;

type AuthTokenServerResponse = {
  granted_at: string;
  access_token: string;
  id_token?: string | null;
};

const getAuthTokens = () => {
  const storedAuthTokens = localStorage.getItem(AUTH_TOKENS);
  return storedAuthTokens && JSON.parse(storedAuthTokens);
};

const hasAuthTokens = () => {
  const authTokens = getAuthTokens();
  return !!authTokens && Object.keys(authTokens).length > 0;
};

const getCurrentUser = () => {
  const currentUser = localStorage.getItem(CURRENT_USER);
  return currentUser && JSON.parse(currentUser);
};

const setAuthTokens = (tokensObj: AuthTokenServerResponse) => {
  let newTokens = {
    ...getAuthTokens(),
  };
  Object.keys(tokensObj).forEach((tokenKey) => {
    const tokenKey_ = tokenKey as keyof AuthTokenServerResponse;
    if (tokensObj[tokenKey_]) {
      newTokens[tokenKey] = tokensObj[tokenKey_];
    }
  });
  localStorage.setItem(AUTH_TOKENS, JSON.stringify(newTokens));
};

const setCurrentUser = (userObj: User) => {
  localStorage.setItem(CURRENT_USER, JSON.stringify(userObj));
};

const clearSessionTokens = () => {
  localStorage.removeItem(AUTH_TOKENS);
  localStorage.removeItem(CURRENT_USER);
};

const logIn = async (authCode: string) => {
  const tokenResponse = await fetch(`${BACKEND_HOST}/api/v1/auth/users/login`, {
    method: "POST",
    body: JSON.stringify({ data: authCode }),
    headers: { "Content-Type": "application/json" },
  });
  const loginData = await tokenResponse.json();
  setAuthTokens(loginData);
  return Promise.resolve();
};

const logOut = async () => {
  try {
    await client.post("auth/users/logout", {
      user_id: getCurrentUser().user_id,
    });
    return Promise.resolve();
  } finally {
    clearSessionTokens();
  }
};

const refreshTokens = async () => {
  try {
    const freshTokensResp = await client.get("auth/users/refresh-token");
    if (isEmpty(freshTokensResp)) {
      throw new Error("Unable to refresh session.");
    }
    setAuthTokens(freshTokensResp);
    return Promise.resolve();
  } catch (err: any) {
    if (refreshTokenFails > 1) {
      logOut();
      window.location.reload();
    } else {
      refreshTokenFails += 1;
      return Promise.reject();
    }
  }
};

const shouldRefreshTokens = () => {
  const authTokens = getAuthTokens();
  if (isEmpty(authTokens)) return false;

  const { granted_at } = authTokens;
  const grantedAtUTC = DateTime.fromISO(granted_at, { zone: "utc" });
  const nowUTC = DateTime.utc();
  return grantedAtUTC.plus(FRESH_TOKEN_LIFETIME) < nowUTC;
};

const authManager = {
  clearSessionTokens,
  getAuthTokens,
  getCurrentUser,
  hasAuthTokens,
  logIn,
  logOut,
  refreshTokens,
  setAuthTokens,
  setCurrentUser,
  shouldRefreshTokens,
};

export default authManager;
