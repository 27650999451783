import { Box, Input, TextareaAutosize } from "@mui/material";
import React, { useCallback, useState } from "react";
import colors from "../../theme/colors";
import SelectCell from "../../components/forms/SelectCell";
import RightTurnOutOfBox from "../../icons/RightTurnOutOfBox";
import CheckboxCell from "../../components/forms/CheckboxCell";
import UserCell from "../../components/forms/UserCell";
import ConditionCell from "../../components/forms/ConditionCell";
import ConditionDatePicker from "../conditions/ConditionDatePicker";

type TeardownFlagCellProps = {
  flag: string;
  type: string;
  helperKeyPressed: boolean;
  disabled: boolean;
  value: string | boolean | User | null;
  options?: string[];
  handleSingleValueUpdate: (
    newValue: string | boolean | User | null,
    persistEmpty?: boolean
  ) => void;
  handleCopyButtonPress: (
    newValue?: User | string | null,
    persistEmpty?: boolean
  ) => void;
};

const ModifyTeardownFlagCell = ({
  flag,
  value,
  type,
  handleSingleValueUpdate,
  helperKeyPressed,
  handleCopyButtonPress,
  disabled,
  options,
}: TeardownFlagCellProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = useCallback((event: React.MouseEvent) => {
    setIsHovering(true);
  }, []);
  const handleMouseLeave = useCallback((event: React.MouseEvent) => {
    setIsHovering(false);
  }, []);
  const copyAllIcon =
    !disabled && helperKeyPressed && isHovering ? (
      <Box ml="auto" my="auto">
        <div
          style={{ height: 20, width: 24 }}
          onClick={() => handleCopyButtonPress()}
        >
          <RightTurnOutOfBox
            style={{ width: 20, height: 20, cursor: "pointer" }}
          />
        </div>
      </Box>
    ) : null;
  const highlightedBottomBorder = `1px solid ${colors.accent.cta}`;
  switch (type) {
    case "bool":
    case "boolean":
      return (
        <CheckboxCell
          color="secondary"
          checked={!!value}
          onChange={() => handleSingleValueUpdate(!value)}
          endIcon={copyAllIcon}
          tableCellProps={{
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          }}
          disabled={disabled}
        />
      );
    case "date":
      return (
        <ConditionDatePicker
          name={flag}
          value={value as string}
          onChange={(date?: string) => {
            if (helperKeyPressed) {
              handleCopyButtonPress(date || null, true);
            } else {
              handleSingleValueUpdate(date || null, true);
            }
          }}
        />
      );
    case "user_options":
      let val = value;
      if (Number.isInteger(value)) {
        // Strange bug happens when the form to update flags is submitted, as
        // somehow the value in the teardownFlagsToUpdate state object transforms
        // from a User object to simply the user_id, which causes some errors
        // in the console and the value in the cell to display 'undefined'.
        // The user-selected values from the dropdown are properly sent in the update,
        // but this patch just means the value in the dropdown will go blank when the
        // request is in progress and before the modal closes.
        val = null;
      }
      return (
        <UserCell
          clearable
          value={(val as User) || null}
          style={
            helperKeyPressed
              ? {
                  borderBottom: highlightedBottomBorder,
                  borderRight: highlightedBottomBorder,
                }
              : undefined
          }
          onChange={(user: User | null) => {
            if (helperKeyPressed) {
              handleCopyButtonPress(user, true);
            } else {
              handleSingleValueUpdate(user, true);
            }
          }}
          disabled={disabled}
        />
      );
    case "options":
      return (
        <SelectCell
          options={options!}
          disabled={disabled}
          style={
            helperKeyPressed
              ? {
                  borderBottom: highlightedBottomBorder,
                  borderRight: highlightedBottomBorder,
                }
              : undefined
          }
          onChange={(newVal) => {
            const newVal_ = newVal as string | null;
            if (helperKeyPressed) {
              handleCopyButtonPress(newVal_, true);
            } else {
              handleSingleValueUpdate(newVal_, true);
            }
          }}
          value={value as string | null}
        />
      );
    case "number":
    case "text":
    default:
      return (
        <ConditionCell
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box display="flex">
            <Input
              type={type === "number" ? "number" : "text"}
              inputComponent={type === "text" ? TextareaAutosize : undefined}
              onChange={(event_) =>
                handleSingleValueUpdate(event_.target.value)
              }
              disableUnderline
              value={value || ""}
              disabled={disabled}
            />
            {copyAllIcon}
          </Box>
        </ConditionCell>
      );
  }
};

export default ModifyTeardownFlagCell;
