import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import client from "../../api";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";

import { Box, CircularProgress, Typography } from "@mui/material";

import type { RootState } from "../../store";
import type { SignalMapState } from "./slice";
import ModuleSignalMapPreDownloadForm from "./ModuleSignalMapPreDownloadForm";
import CellTestScriptPreDownloadForm from "./CellTestScriptPreDownloadForm";
import { isNull, isNil } from "lodash";

const DownloadJSONButtons = ({
  dut_id,
  onError,
}: {
  dut_id: string;
  onError: (err: string) => void;
}) => {
  const { signalMap, signals, dutType } = useSelector<
    RootState,
    SignalMapState
  >(({ signalMapSingle }) => signalMapSingle);

  // Signal map JSON fetch loading
  const [SMLoading, setSMLoading] = useState<boolean>(false);
  // Test script JSON fetch loading
  const [TSLoading, setTSLoading] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [inputsOpen, setInputsOpen] = useState<boolean>(false);
  const [RTDWarningOpen, setRTDWarningOpen] = useState<boolean>(false);
  const [signalCheckWarningOpen, setSignalCheckWarningOpen] =
    useState<boolean>(false);
  const [postDownloadWarningOpen, setPostDownloadWarningOpen] =
    useState<boolean>(false);
  const [skipInputs, setSkipInputs] = useState<boolean>(false);
  const isModule = dutType === "module";
  const hasRTD = useMemo(
    () =>
      signals.filter((s) => s.name.toLowerCase().includes("rtd")).length > 0,
    [signals]
  );
  const [signalWarnings, setSignalWarnings] = useState<string[]>([]);

  // Scrub signals and add warnings that the config is missing
  // data that pipeline relies on
  useEffect(() => {
    let warnings: string[] = [];
    let maxCellPosID = 0;

    if (isModule) {
      // Module signal warnings (not including serial HPHCs)
      let GENERAL_CHECKS: { [key: string]: number } = {
        // "signal_name,measurement_type": count,
        "bdps_current,amps": 0,
        "charge_ah,capacity": 0,
        "discharge_ah,capacity": 0,
        "bdps_voltage,voltage": 0,
        "charge_wh,energy": 0,
        "discharge_wh,energy": 0,
      };
      let CHILD_CELL_CHECKS: { [key: string]: number } = {
        // "sensor_type_raw": count
        anode_to_cell_plus: 0,
        anode_to_gde: 0,
        anode_to_oee: 0,
      };

      signals.forEach((s) => {
        let sensorType = s.sensor_type_raw;
        if (sensorType in CHILD_CELL_CHECKS) {
          CHILD_CELL_CHECKS[sensorType] += 1;
        }

        let measurementType = s.measurement_type;
        let key = `${s.name},${measurementType}`;
        if (key in GENERAL_CHECKS) {
          GENERAL_CHECKS[key] += 1;
        }

        let posID = s.cell_position_id;
        if (!isNil(posID)) {
          maxCellPosID = Math.max(posID, maxCellPosID);
        }
      });

      Object.entries(GENERAL_CHECKS).forEach(([key, val]) => {
        if (val === 0) {
          let [name, sensorType] = key.split(",");
          warnings.push(
            `Missing a signal with name="${name}" and sensor_type="${sensorType}"`
          );
        }
      });

      Object.entries(CHILD_CELL_CHECKS).forEach(([key, val]) => {
        if (val !== maxCellPosID) {
          warnings.push(
            `Found ${val} entries for sensor_type="${key}" for ${maxCellPosID} children cells`
          );
        }
      });
    } else if (dutType === "cell") {
      // Standalone cell and serial HPHC signal warnings
      let aux2Present = false;
      let aux3Present = false;

      signals.forEach((s) => {
        if (s.device_class === "aux") {
          let numericAddress = !isNull(s.device_address)
            ? parseInt(s.device_address as string)
            : -1;
          if (numericAddress === 2) {
            aux2Present = true;
          } else if (numericAddress === 3) {
            aux3Present = true;
          }
        }
      });

      if (!aux2Present) {
        warnings.push('No signal with device_class="aux" and device_address=2');
      }

      if (!aux3Present) {
        warnings.push('No signal with device_class="aux" and device_address=3');
      }
    }

    setSignalWarnings(warnings);
    // eslint-disable-next-line
  }, [signals]);

  const fetchLatestJSON = async (
    resourceType: "signal-map" | "test-script",
    formData?: any
  ) => {
    try {
      let params = "";
      if (resourceType === "test-script" && !isModule) {
        let loggingPeriod = formData["loggingPeriod"];
        let notes = formData["notes"];
        params = `&logging_period=${loggingPeriod}${
          !!notes ? `&notes=${notes}` : ""
        }`;
      } else if (resourceType === "signal-map" && isModule && !!formData) {
        // Only pass in over protection variables for modules if there is form data to parse
        let ovp = formData["ovp"];
        let ocp = formData["ocp"];
        let opp = formData["opp"];
        let ows = formData["oneWireSlots"];
        let res = formData["rtdResistance"];
        params = `&ovp=${ovp}&ocp=${ocp}&opp=${opp}&rtdResistance=${res}&skipInputs=${skipInputs}${
          ows.length > 0
            ? `&oneWireSlots=${encodeURIComponent(ows.join(","))}`
            : ""
        }`;
      }
      const response = await client.get(
        `meta/${resourceType}s/${dut_id}/json?dut_type=${dutType}${params}&test_id=${signalMap?.test_id}`
      );
      return response;
    } catch (err: any) {
      onError(err);
      return false;
    }
  };

  const moduleOnSubmit = (data: any) => {
    handleDownloadClick("signal-map", data);
  };

  const cellOnSubmit = (data: any) => {
    handleDownloadClick("test-script", data);
  };

  const handleDownloadClick = async (
    resourceType: "signal-map" | "test-script",
    formData?: any
  ) => {
    const isSignalMap: boolean = resourceType === "signal-map";
    isSignalMap ? setSMLoading(true) : setTSLoading(true);
    setInputsOpen(false);
    const response = await fetchLatestJSON(resourceType, formData);
    if (response === false) {
      isSignalMap ? setSMLoading(false) : setTSLoading(false);
      return;
    }

    try {
      const json = response.json;
      const blob = new Blob([json], { type: "text/json" });
      const url = window.URL.createObjectURL(blob);
      const filename = `${response.test_file_name}_${resourceType.replace(
        "-",
        "_"
      )}.json`;

      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = url;
      tempLink.download = filename;

      document.body.appendChild(tempLink);
      tempLink.click();
      window.URL.revokeObjectURL(url);
    } catch (err: any) {
      onError(err.message);
    }
    isSignalMap ? setSMLoading(false) : setTSLoading(false);

    // Reset skipInputs
    setSkipInputs(true);

    // Display warning modal the download was a module signal map
    if (isSignalMap && dutType === "module") {
      setPostDownloadWarningOpen(true);
    }
    return {};
  };

  // Skip the over protection variable modal if applicable
  useEffect(() => {
    if (!isNull(signalMap)) {
      let ovp = signalMap.over_voltage_protection;
      let ocp = signalMap.over_current_protection;
      let opp = signalMap.over_power_protection;
      // Skip the input modal if all over protection values are preset
      setSkipInputs(!isNull(ovp) && !isNull(ocp) && !isNull(opp));
    }
  }, [signalMap]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={SMLoading}
          style={{
            width: "fit-content",
            marginRight: "5px",
          }}
          onClick={() => {
            if (!hasRTD) {
              // For both FSCs and modules, if there's no RTD signal,
              // open the RTD modal
              setRTDWarningOpen(true);
            } else {
              // If the DUT is a module...
              if (isModule) {
                if (signalWarnings.length > 0) {
                  // ...check that all the module signal checks passed.
                  // Open the signal check warning modal if not
                  setSignalCheckWarningOpen(true);
                } else if (!skipInputs) {
                  // ...then check that you can skip the inputs. Open the inputs
                  // modal if they're missing
                  setInputsOpen(true);
                } else {
                  // If the module has BDPS, RTD, and the required inputs, dowload
                  handleDownloadClick("signal-map");
                }
              } else {
                // DUT is not a module and has RTD. Check that there are no signal
                // warnings
                if (dutType === "cell" && signalWarnings.length > 0) {
                  setSignalCheckWarningOpen(true);
                } else {
                  handleDownloadClick("signal-map");
                }
              }
            }
          }}
          endIcon={
            SMLoading ? <CircularProgress color="inherit" size={20} /> : null
          }
        >
          Download Signal Map
        </Button>

        {!isModule && (
          <Button
            color="primary"
            size="small"
            type="submit"
            disabled={TSLoading}
            style={{
              width: "fit-content",
              marginRight: "5px",
            }}
            onClick={() => setInputsOpen(true)}
            endIcon={
              TSLoading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Download Test Script
          </Button>
        )}
      </Box>

      {/*Pre-download variables*/}
      {!isModule ? (
        <Modal open={inputsOpen} onClose={() => setInputsOpen(false)}>
          <CellTestScriptPreDownloadForm
            onSubmit={cellOnSubmit}
            loading={TSLoading}
          />
        </Modal>
      ) : (
        <Modal open={inputsOpen} onClose={() => setInputsOpen(false)}>
          <ModuleSignalMapPreDownloadForm
            onSubmit={moduleOnSubmit}
            loading={SMLoading}
          />
        </Modal>
      )}

      {/* RTD Warning Modal */}
      <Modal open={RTDWarningOpen} onClose={() => setRTDWarningOpen(false)}>
        <Typography variant="h2" mb={2} textAlign="center">
          There is no signal with the substring "RTD"
        </Typography>
        <Typography className="small" mb={5} textAlign="center">
          The cycler commands may require manual edits as a result. Are you sure
          you want to continue with the download?
        </Typography>

        <Box style={{ display: "flex", justifyContent: "center" }} my={5}>
          <Button
            color="secondary"
            size="small"
            type="submit"
            style={{
              width: "fit-content",
              margin: "10px",
            }}
            onClick={() => setRTDWarningOpen(false)}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            size="small"
            type="submit"
            style={{
              width: "fit-content",
              margin: "10px",
            }}
            onClick={() => {
              if (isModule && !skipInputs) {
                setInputsOpen(true);
              } else {
                handleDownloadClick("signal-map");
              }
              setRTDWarningOpen(false);
            }}
          >
            Continue
          </Button>
        </Box>
      </Modal>

      {/* Signal Check Warning Modal */}
      <Modal
        open={signalCheckWarningOpen}
        onClose={() => setSignalCheckWarningOpen(false)}
      >
        <Typography variant="h2" mb={2} textAlign="center">
          The following warnings have been detected:
        </Typography>

        {signalWarnings.map((warning) => (
          <Typography className="small" textAlign="center">
            {warning}
          </Typography>
        ))}

        <Typography className="h3" mb={5} mt={5} textAlign="center">
          Snowflake data may be inaccurate as a result. Troubleshooting tips can
          be found{" "}
          <a
            href="https://wiki.formenergy.com/display/BD/106.+Signal+Mapping+in+Oak#106.SignalMappinginOak-troubleshoot"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Typography>

        <Typography className="h3" mb={5} mt={5} textAlign="center">
          Are you sure you want to continue?
        </Typography>

        <Box style={{ display: "flex", justifyContent: "center" }} my={5}>
          <Button
            color="secondary"
            size="small"
            type="submit"
            style={{
              width: "fit-content",
              margin: "10px",
            }}
            onClick={() => setSignalCheckWarningOpen(false)}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            size="small"
            type="submit"
            style={{
              width: "fit-content",
              margin: "10px",
            }}
            onClick={() => {
              if (isModule && !skipInputs) {
                setInputsOpen(true);
              } else {
                handleDownloadClick("signal-map");
              }
              setSignalCheckWarningOpen(false);
            }}
          >
            Continue
          </Button>
        </Box>
      </Modal>

      {/* Post-Download Warning Modal */}
      <Modal
        open={postDownloadWarningOpen}
        onClose={() => setPostDownloadWarningOpen(false)}
      >
        <Typography variant="h2" mb={2} textAlign="center">
          This config is only compatible with Hazelnut v3.6.0
        </Typography>
        <Typography className="small" mb={5} textAlign="center">
          If you are on a stand with an earlier version, manually generate a
          config with{" "}
          <a
            href="https://wiki.formenergy.com/display/TI/Complete+Guide+to+Working+with+the+Hazelnut+Schemas#CompleteGuidetoWorkingwiththeHazelnutSchemas-%22modbus%22(HMP110RelativeHumiditySensor)(Hazelnutversion3.5.0-)Legacy"
            target="_blank"
            rel="noreferrer"
          >
            this
          </a>
          .
        </Typography>

        <Box style={{ display: "flex", justifyContent: "center" }} my={5}>
          <Button
            color="primary"
            size="small"
            type="submit"
            style={{
              width: "fit-content",
              margin: "10px",
            }}
            onClick={() => setPostDownloadWarningOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default DownloadJSONButtons;
