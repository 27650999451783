import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import client from "../../api";
import authManager from "../../services/authManager";
export const AUTH = "AUTH";

export interface AuthState {
  user: null | User;
  impersonating: boolean;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: null | string;
}

const initialState: AuthState = {
  user: authManager.getCurrentUser() || null,
  impersonating: false,
  status: "idle",
  error: null,
};

export const logIn = createAsyncThunk(
  `${AUTH}/me`,
  async (authResponse: AuthCodeSuccessResponse) => {
    await authManager.logIn(authResponse.code);
    const response = await client.get(`auth/users/me`);
    if (response && response.auth_user) {
      return {
        ...response.auth_user,
        // avatarUrl: profile.getImageUrl(),
      };
    } else {
      return null;
    }
  }
);

export const logInTestBot = createAsyncThunk(`${AUTH}/me-e2e`, async () => {
  const response = await client.get(`auth/users/me-e2e`);

  return {
    ...response.auth_user,
  };
});

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    impersonate: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      state.impersonating = true;
      client.setImpersonatingUserId(payload.user_id);
      Sentry.setUser({
        id: `${payload.user_id}`,
        username: payload.name,
        email: payload.email,
        impersonating: true,
      });
    },
    logOut: (state) => {
      state = Object.assign(state, {
        ...initialState,
        user: null,
      });
      client.setImpersonatingUserId(null);
      Sentry.setUser(null);
      authManager.logOut();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logIn.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        if (!state.impersonating) {
          state.user = payload;
          authManager.setCurrentUser(payload);
          Sentry.setUser({
            id: `${payload.user_id}`,
            username: payload.name,
            email: payload.email,
            impersonating: false,
          });
        }
      })
      .addCase(logIn.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message as string;
        state.user = null;
        state.impersonating = false;
      })
      .addCase(logInTestBot.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.user = payload;
        state.impersonating = false;
        client.setImpersonatingUserId(null);
      });
  },
});

export const { impersonate, logOut } = authSlice.actions;

export default authSlice.reducer;
