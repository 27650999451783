import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import type { AuthState } from "./store/auth/slice";
import type { RootState } from "./store";
import AuthLayout from "./layout/Auth";
import LoadingLayout from "./layout/Loading";
import MainLayout from "./layout/Main";
import ImpersonationModal from "./features/impersonation/ImpersonationModal";
import { FeatureFlagsState, getEnabledFlags } from "./store/featureFlags/slice";

const RequireAuth = ({ children }: React.PropsWithChildren<{}>) => {
  const user = useSelector<RootState, null | User>(({ auth }) => auth.user);
  const location = useLocation();

  return user ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: location } as LocationState} />
  );
};

const App = () => {
  const { user, status, error } = useSelector<RootState, AuthState>(
    ({ auth }) => auth
  );
  const { enabledFlags } = useSelector<RootState, FeatureFlagsState>(
    ({ featureFlags }) => featureFlags
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Array.isArray(enabledFlags) && user) {
      dispatch(getEnabledFlags());
    }
  }, [dispatch, enabledFlags, user]);

  if (!user && status !== "idle") {
    return <LoadingLayout fullscreen error={error || ""} />;
  }

  return (
    <Routes>
      <Route path="login" element={<AuthLayout />} />
      <Route
        path="*"
        element={
          <RequireAuth>
            <MainLayout />
            <ImpersonationModal />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default App;
