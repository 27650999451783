import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import OverflowTooltip from "../../../../components/OverflowTooltip";
import ExpandIcon from "../../../../icons/Expand";
import CellTreeCondition from "./CellTreeCondition";
import colors from "../../../../theme/colors";
import NumberBadge from "../../../../components/NumberBadge";

type Props = {
  layout?: MetadataSidebarCellLayout;
  onToggleCell?: (id: number) => void;
};

const CellTree = ({ layout, onToggleCell }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const layoutKeys = Object.keys(layout?.items || {});
  layoutKeys.sort();

  const startedTitle = layoutKeys.find((key) =>
    layout?.items[key].items.some((c) => c.active)
  );

  const header = (
    <Box px={1} mt={3} display="flex" alignItems="center">
      <IconButton
        size="small"
        color="primary"
        onClick={() => setExpanded(!expanded)}
      >
        <ExpandIcon style={expanded ? { transform: "rotate(90deg)" } : {}} />
      </IconButton>
      <Box ml={3} mr="auto" width={194}>
        {expanded ? (
          <Typography color="textSecondary" variant="inherit">
            EXP ID - Condition
          </Typography>
        ) : (
          <Box display="flex" alignItems="center">
            <div style={{ maxWidth: 155 }}>
              <OverflowTooltip title={`${startedTitle}`}>
                <b>{startedTitle}</b>
              </OverflowTooltip>
            </div>
            <NumberBadge
              value={`${layout?.items[`${startedTitle}`]?.group_number}`}
              label="Group number"
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Collapse
      in={expanded}
      collapsedSize={60}
      className="small"
      style={{
        background: colors.striping,
        borderBottom: `2px solid ${colors.rules}`,
      }}
    >
      <>
        {header}
        <Box pl={3}>
          {layoutKeys.map((title, index) => (
            <CellTreeCondition
              key={index}
              title={title}
              group={layout?.items[title].group_number}
              items={layout?.items[title].items}
              expanded={title === startedTitle}
              onToggleCell={onToggleCell}
            />
          ))}
        </Box>
      </>
    </Collapse>
  );
};

export default CellTree;
