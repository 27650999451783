import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import OverviewNew from "../step-overview/New";
import { createCondition } from "../slice";

type Props = {
  exp_id: string;
  onNext?: () => void;
  onCombinationChange?: (type: string, assembly: string) => void;
};

const NewCondition = ({ exp_id, onNext, onCombinationChange }: Props) => {
  const dispatch = useDispatch();

  const handleStart = (condition: Omit<Condition, "items" | "status">) => {
    dispatch(createCondition(condition));
    if (onNext) {
      onNext();
    }
  };

  return (
    <Box px={4} pb={6} position="relative">
      <OverviewNew
        exp_id={parseInt(exp_id)}
        onNext={handleStart}
        onCombinationChange={onCombinationChange}
      />
    </Box>
  );
};

export default NewCondition;
