import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import CheckCircleIcon from "../../icons/CheckCircle";
import TimesCircleIcon from "../../icons/TimesCircle";
import colors from "../../theme/colors";
import { getChangeRequestDisplayValue } from "../../utils/conditions";
import { approveChangeRequests, undoChangeRequests } from "./slice";

type Props = {
  request: ChangeRequest;
  showActions?: boolean;
  onReject?: () => void;
};

const DiffBlock = ({ request, showActions, onReject }: Props) => {
  const isTablet = useMediaQuery("(min-width:760px)");
  const dispatch = useDispatch();

  const onApprove = () => {
    dispatch(approveChangeRequests([request.change_request_id]));
  };

  const onUndo = () => {
    dispatch(undoChangeRequests([request.change_request_id]));
  };

  const buttons =
    showActions && request.status === "P" ? (
      <Box
        ml={isTablet ? "auto" : 0}
        mb={isTablet ? 0 : 6}
        display="flex"
        alignItems="center"
      >
        <Button onClick={onReject} className="small" color="secondary">
          Reject
        </Button>
        <Box ml={4}>
          <Button onClick={onApprove} className="small" color="primary">
            Approve
          </Button>
        </Box>
      </Box>
    ) : showActions && request.status !== "P" ? (
      <Box
        ml={isTablet ? "auto" : 0}
        mb={isTablet ? 0 : 6}
        display="flex"
        alignItems="center"
        className="small"
      >
        {request.status === "A" ? (
          <>
            <CheckCircleIcon
              style={{ color: colors.accent.green, width: 20, height: 20 }}
            />
            <Box ml={2} mr={4}>
              Approved
            </Box>
          </>
        ) : (
          <>
            <TimesCircleIcon
              style={{ color: colors.accent.red, width: 20, height: 20 }}
            />
            <Box ml={2} mr={4}>
              Rejected
            </Box>
          </>
        )}
        <Divider orientation="vertical" flexItem />
        <Button
          color="tertiary"
          type="button"
          size="small"
          className="small"
          style={{
            padding: "0.25rem",
            color: colors.text.secondary,
          }}
          onClick={onUndo}
        >
          <b>Undo</b>
        </Button>
      </Box>
    ) : null;

  const oldVal = getChangeRequestDisplayValue(
    request.old_value,
    request.field,
    request.field_type
  );
  const newVal = getChangeRequestDisplayValue(
    request.new_value,
    request.field,
    request.field_type
  );

  return (
    <>
      <Box mt={4} mb={3} display="flex" alignItems="flex-start">
        <div>
          <Box mb={1}>
            <Typography color="textPrimary" fontWeight={700}>
              {request.component_name}
            </Typography>
          </Box>
          <Typography color="textPrimary" fontWeight={500}>
            {request.label}
          </Typography>
        </div>
        {isTablet ? buttons : null}
      </Box>
      <Box mt={4} mb={2} display="flex">
        <div style={{ width: "50%" }}>
          <Typography color="textSecondary" className="small" fontWeight={500}>
            Specified
          </Typography>
        </div>
        <div style={{ width: "50%" }}>
          <Typography color="textSecondary" className="small" fontWeight={500}>
            Updated
          </Typography>
        </div>
      </Box>
      <Box mt={2} mb={4} display="flex">
        <div style={{ width: "50%" }}>
          <Typography color="textPrimary">
            {oldVal === null ? (
              <em>blank</em>
            ) : oldVal === true ? (
              <em>checked</em>
            ) : oldVal === false ? (
              <em>unchecked</em>
            ) : (
              oldVal
            )}
          </Typography>
        </div>
        <div style={{ width: "50%" }}>
          <Typography color="textPrimary">
            {newVal === null ? (
              <em>blank</em>
            ) : newVal === true ? (
              <em>checked</em>
            ) : newVal === false ? (
              <em>unchecked</em>
            ) : (
              newVal
            )}
          </Typography>
        </div>
      </Box>
      {request.reason ? (
        <>
          <Box mt={4} mb={2}>
            <Typography
              color="textSecondary"
              className="small"
              fontWeight={500}
            >
              Note
            </Typography>
          </Box>
          <Box mt={2} mb={4}>
            {request.reason}
          </Box>
        </>
      ) : null}
      {!isTablet ? buttons : null}
      <Divider />
    </>
  );
};

export default DiffBlock;
