import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import type { RootState } from "../../store";
import colors from "../../theme/colors";
import { sampleIdToString, SAMPLE_LABELS_ALL } from "../../utils/labels";
import { SAMPLE_FILTERS } from "../../utils/filters";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import SearchBarIcon from "../../icons/SearchBar";
import Checkbox from "@mui/material/Checkbox";
import VirtualTable from "../../components/table/VirtualTable";
import {
  vhToPx,
  getVirtualTableHeight,
  EMPTY_STATE_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import {
  CharacterizationState,
  listSamples,
  resetSamples,
  selectSamples,
  selectAllVisibleSamples,
  deselectAllVisibleSamples,
  deselectSamples,
  resetGetMaterials,
} from "./slice";
import ButtonSampleFilters from "./ButtonSampleFilters";
import SampleTableCell from "./SampleTableCell";
import CreateSamplesButton from "../../components/CreateSamplesButton";
import SamplesOverflowMenu from "./SamplesOverflowMenu";
import Clipboard from "../../icons/Clipboard";
import ItemCountHeader from "../../components/table/ItemCountHeader";
import { shiftClickList } from "../../utils/shiftClick";

const SamplesList = () => {
  const {
    samples,
    selected,
    args: { order, filters, page, orderBy },
    hasMore,
    status: { listSamples: listSamplesStatus },
    error: { listSamples: listSamplesErrors },
  } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCopyMsg, setShowCopyMsg] = useState(false);

  useEffect(() => {
    dispatch(resetSamples());
    dispatch(resetGetMaterials());
  }, [dispatch]);

  useEffect(() => {
    if (listSamplesStatus === "idle") {
      dispatch(
        listSamples({
          order,
          orderBy,
          filters,
          page,
          refresh: true,
        })
      );
    }
  }, [dispatch, listSamplesStatus, order, orderBy, filters, page]);

  let errorToast;
  if (listSamplesStatus === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(listSamples({ order, orderBy, filters, page: 0 }))
        }
      >
        {listSamplesErrors}
      </Toast>
    );
  }
  const copyMsg = showCopyMsg ? (
    <Toast open severity="success" onClose={() => setShowCopyMsg(false)}>
      Sample ID copied to clipboard.
    </Toast>
  ) : null;

  const handleSortClick = (
    e: React.MouseEvent,
    property: keyof SampleFilters
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      listSamples({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        page: 0,
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      listSamples({
        order,
        orderBy,
        filters,
        page: page + 1,
      })
    );
  };

  const handleSelectSamples = (selectedIds: string[], shiftClick = false) => {
    const allSelected = !selectedIds.some((id) => !selected.includes(id));
    if (allSelected) {
      dispatch(deselectSamples(selectedIds));
    } else {
      let namesToSelect = shiftClickList(
        selected,
        selectedIds,
        samples,
        shiftClick
      );
      dispatch(selectSamples(namesToSelect));
    }
  };

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(deselectAllVisibleSamples());
    } else {
      dispatch(selectAllVisibleSamples());
    }
  };

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (samples.length === 0 && listSamplesStatus === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={SAMPLE_LABELS_ALL.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No samples to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let rowIndex = 0;

    tableBody = samples
      .map((row) => {
        rowIndex++;
        rowHeights.push(DATA_ROW_HEIGHT);

        return (
          <React.Fragment key={`grouping-${row.sample_id}`}>
            <TableRow
              hover
              className={`clickable ${
                rowIndex % 2 === 0 ? "" : "alternating"
              } ${selected.includes(String(row.sample_id)) ? "checked" : ""}`}
              onClick={() =>
                navigate(`/characterization/samples?sample_id=${row.sample_id}`)
              }
            >
              <TableCell padding="checkbox">
                <Box
                  style={{ paddingTop: 4 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(
                      sampleIdToString(row.sample_id!)
                    );
                    setShowCopyMsg(true);
                  }}
                >
                  <Clipboard />
                </Box>
              </TableCell>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  checked={selected.includes(String(row.sample_id))}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectSamples(
                      [String(row.sample_id)],
                      (e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox" />

              {SAMPLE_LABELS_ALL.map(({ id }, index) => (
                <SampleTableCell
                  key={index}
                  dataKey={id as keyof SampleFilters}
                  sample={row}
                />
              ))}
            </TableRow>
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <ItemCountHeader itemCount={samples.length} />
        <Box mr={4}>
          <ButtonSampleFilters
            labels={SAMPLE_LABELS_ALL}
            filterOptions={SAMPLE_FILTERS}
            filters={filters}
            onChange={(filters) =>
              dispatch(listSamples({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4} />
        <Box mr={4} />

        <Box ml="auto">
          <CreateSamplesButton from="/characterization" />
          <SamplesOverflowMenu />
        </Box>
      </Box>

      <Divider />

      <VirtualTable
        height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220)}
        width="100%"
        itemCount={tableBody.length}
        itemSize={(index) => rowHeights[index]}
        header={
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={
                    selected.length > 0 &&
                    (hasMore || selected.length < samples.length)
                  }
                  checked={
                    samples.length > 0 &&
                    selected.length === samples.length &&
                    !hasMore
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleToggleSelectAll}
                />
              </TableCell>
              <TableCell padding="checkbox" />
              {SAMPLE_LABELS_ALL.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="normal"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    disabled={
                      !Object.keys(SAMPLE_FILTERS).includes(headCell.id)
                    }
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) =>
                      handleSortClick(e, headCell.id as keyof SampleFilters)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        row={({ index }) => tableBody[index] || null}
      />

      <Box mt={6} display="flex" alignItems="center">
        {listSamplesStatus === "loading" && samples.length === 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>
        ) : samples.length > 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Viewing {samples.length}
          </Typography>
        ) : null}

        {hasMore ? (
          <Box ml={4}>
            <Button
              color="secondary"
              size="small"
              onClick={handleLoadMoreClick}
            >
              Load more
            </Button>
          </Box>
        ) : null}

        {listSamplesStatus === "loading" ? (
          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        ) : null}
      </Box>
      {errorToast}
      {copyMsg}
    </>
  );
};

export default SamplesList;
