import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MinusCircleIcon from "../../icons/MinusCircle";
import { engineerFlagToString } from "../../utils/labels";
import SmallChip from "../SmallChip";

type EngineerFlagFilterProps = {
  value?: EngineerFlagFilter;
  removable?: boolean;
  onRemove?: () => void;
  onChange: (selected: EngineerFlagFilter) => void;
};

const EngineerFlagFilter = ({
  value = [],
  removable,
  onRemove,
  onChange,
}: EngineerFlagFilterProps) => {
  const componentOptions: { id: EngineerFlagKey; label: string }[] = [
    {
      id: "all_engineer_flag",
      label: engineerFlagToString("all_engineer_flag"),
    },
    {
      id: "anode__engineer_flag",
      label: engineerFlagToString("anode__engineer_flag"),
    },
    {
      id: "gde__engineer_flag",
      label: engineerFlagToString("gde__engineer_flag"),
    },
    {
      id: "oee__engineer_flag",
      label: engineerFlagToString("oee__engineer_flag"),
    },
    {
      id: "ref_anode__engineer_flag",
      label: engineerFlagToString("ref_anode__engineer_flag"),
    },
    {
      id: "ref_gde__engineer_flag",
      label: engineerFlagToString("ref_gde__engineer_flag"),
    },
    {
      id: "ref_oee__engineer_flag",
      label: engineerFlagToString("ref_oee__engineer_flag"),
    },
    {
      id: "counter__engineer_flag",
      label: engineerFlagToString("counter__engineer_flag"),
    },
    {
      id: "full__engineer_flag",
      label: engineerFlagToString("full__engineer_flag"),
    },
    {
      id: "electrolyte__engineer_flag",
      label: engineerFlagToString("electrolyte__engineer_flag"),
    },
    {
      id: "fill__engineer_flag",
      label: engineerFlagToString("fill__engineer_flag"),
    },
    {
      id: "on_test__engineer_flag",
      label: engineerFlagToString("on_test__engineer_flag"),
    },
    {
      id: "during_test__engineer_flag",
      label: engineerFlagToString("during_test__engineer_flag"),
    },
    {
      id: "off_test__engineer_flag",
      label: engineerFlagToString("off_test__engineer_flag"),
    },
    {
      id: "teardown__engineer_flag",
      label: engineerFlagToString("teardown__engineer_flag"),
    },
  ];

  const flaggedChecked = value.some((filter) => filter[1] === "1");
  const flaggedValues = value
    .filter((filter) => filter[1] === "1")
    .map((filter) => filter[0]);

  const unflaggedChecked = value.some((filter) => filter[1] === "0");
  const unflaggedValues = value
    .filter((filter) => filter[1] === "0")
    .map((filter) => filter[0]);

  return (
    <Box display="flex" style={{ width: 450 }}>
      <Box display="flex-column" style={{ width: "100%" }}>
        <Box display="flex">
          <FormControlLabel
            style={{
              width: 150,
              flexShrink: 0,
              marginRight: "0.5rem",
              marginBottom: "0.5rem",
            }}
            control={
              <Checkbox
                color="secondary"
                checked={flaggedChecked}
                onChange={(e) =>
                  e.target.checked
                    ? onChange([...value, [null, "1"]])
                    : onChange(value.filter((filter) => filter[1] !== "1"))
                }
              />
            }
            label="Flagged"
          />
          <FormControl style={{ flexGrow: 1, marginLeft: "0.5rem" }}>
            <Autocomplete
              value={componentOptions.filter(({ id }) =>
                flaggedValues.includes(id)
              )}
              multiple
              disabled={!flaggedChecked}
              options={componentOptions}
              disableClearable
              onChange={(e, newValue, reason) => {
                const existingAllChecked = value.some(
                  (filter) =>
                    filter[0] === "all_engineer_flag" && filter[1] === "1"
                );
                const newAllChecked = newValue.some(
                  ({ id }) => id === "all_engineer_flag"
                );

                if (newAllChecked && !existingAllChecked) {
                  onChange([
                    ...value.filter((filter) => filter[1] !== "1"),
                    ["all_engineer_flag", "1"],
                  ]);
                } else {
                  onChange([
                    ...value.filter((filter) => filter[1] !== "1"),
                    ...newValue
                      .filter(({ id }) => id !== "all_engineer_flag")
                      .map(({ id }) => [id, "1"] as LegacyEngineerFlagFilter),
                  ]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  color="secondary"
                />
              )}
              renderOption={(props, opt) => (
                <li {...props}>
                  {opt.id !== "all_engineer_flag" ? (
                    <Checkbox
                      color="secondary"
                      checked={props["aria-selected"] === true}
                    />
                  ) : null}
                  <Box padding={3}>{opt.label}</Box>
                </li>
              )}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => (
                  <Box key={value.id} mr={2} mb={1}>
                    <SmallChip
                      label={value.label}
                      {...getTagProps({ index })}
                    />
                  </Box>
                ))
              }
            />
          </FormControl>
        </Box>
        <Box display="flex">
          <FormControlLabel
            style={{
              width: 150,
              flexShrink: 0,
              marginRight: "0.5rem",
              marginBottom: "0.5rem",
            }}
            control={
              <Checkbox
                color="secondary"
                checked={unflaggedChecked}
                onChange={(e) =>
                  e.target.checked
                    ? onChange([...value, [null, "0"]])
                    : onChange(value.filter((filter) => filter[1] !== "0"))
                }
              />
            }
            label="Not Flagged"
          />
          <FormControl style={{ flexGrow: 1, marginLeft: "0.5rem" }}>
            <Autocomplete
              value={componentOptions.filter(({ id }) =>
                unflaggedValues.includes(id)
              )}
              multiple
              disabled={!unflaggedChecked}
              options={componentOptions}
              disableClearable
              onChange={(e, newValue, reason) => {
                const existingAllChecked = value.some(
                  (filter) =>
                    filter[0] === "all_engineer_flag" && filter[1] === "0"
                );
                const newAllChecked = newValue.some(
                  ({ id }) => id === "all_engineer_flag"
                );

                if (newAllChecked && !existingAllChecked) {
                  onChange([
                    ...value.filter((filter) => filter[1] !== "0"),
                    ["all_engineer_flag", "0"],
                  ]);
                } else {
                  onChange([
                    ...value.filter((filter) => filter[1] !== "0"),
                    ...newValue
                      .filter(({ id }) => id !== "all_engineer_flag")
                      .map(({ id }) => [id, "0"] as LegacyEngineerFlagFilter),
                  ]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  color="secondary"
                />
              )}
              renderOption={(props, opt) => (
                <li {...props}>
                  {opt.id !== "all_engineer_flag" ? (
                    <Checkbox
                      color="secondary"
                      checked={props["aria-selected"] === true}
                    />
                  ) : null}
                  <Box padding={3}>{opt.label}</Box>
                </li>
              )}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => (
                  <Box key={value.id} mr={2} mb={1}>
                    <SmallChip
                      label={value.label}
                      {...getTagProps({ index })}
                    />
                  </Box>
                ))
              }
            />
          </FormControl>
        </Box>
      </Box>
      {removable ? (
        <Box mb="auto" mt={1}>
          <IconButton size="small" onClick={onRemove}>
            <MinusCircleIcon />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default EngineerFlagFilter;
