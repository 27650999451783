const addAlpha = (hexColor: string, alpha: number) => {
  const hex = hexColor.replace("#", "");

  const initial = {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };

  const composed = {
    r: Math.round((alpha * (initial.r / 255) + (1 - alpha)) * 255).toString(16),
    g: Math.round((alpha * (initial.g / 255) + (1 - alpha)) * 255).toString(16),
    b: Math.round((alpha * (initial.b / 255) + (1 - alpha)) * 255).toString(16),
  };

  return `#${composed.r}${composed.g}${composed.b}`;
};

const colors = {
  text: {
    primary: "#1F1F1F",
    secondary: "#6D6D6D",
    tertiary: "#64717E",
  },
  accent: {
    cta: "#F7A63A",
    primary: "#1E5471",
    green: "#70A9A1",
    burntYellow: "#CC8930",
    darkGreen: "#3D5C57",
    red: "#B60B02",
    purple: "#61304B",
    pink: "#B68CB8",
    violet: "#6F73D2",
    gray: "#C7C7C7",
    orange: "#F76939",
    warningRed: "#800801",
    yellow: "#F7CE39",
  },
  rules: "#E7E7E7",
  striping: addAlpha("#E7E7E7", 0.2),
  body: {
    light: "#FFFFFF",
  },
};

export default colors;
export { addAlpha };
