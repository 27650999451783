import colors, { addAlpha } from "./colors";

type ButtonThemes = {
  [key: string]: {
    text: string;
    border?: string;
    background: {
      active: string;
      hover: string;
      focus: string;
    };
  };
};

const theme: ButtonThemes = {
  cta: {
    text: colors.text.primary,
    background: {
      active: colors.accent.cta,
      hover: "#F59414",
      focus: "#F8AE4F",
    },
  },
  primary: {
    text: "#FFFFFF",
    background: {
      active: colors.accent.primary,
      hover: "#153C51",
      focus: "#266C92",
    },
  },
  secondary: {
    text: colors.text.secondary,
    border: colors.text.secondary,
    background: {
      active: "transparent",
      hover: addAlpha(colors.rules, 0.7),
      focus: "transparent",
    },
  },
  tertiary: {
    text: colors.accent.primary,
    background: {
      active: "transparent",
      hover: addAlpha(colors.accent.primary, 0.1),
      focus: "transparent",
    },
  },
  text: {
    text: colors.text.secondary,
    background: {
      active: "transparent",
      hover: addAlpha(colors.accent.primary, 0.1),
      focus: "transparent",
    },
  },
};

export default theme;
