import React, { useEffect } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import styled from "@mui/styles/styled";
import ArrowBack from "../../../icons/ArrowBack";
import colors from "../../../theme/colors";
import CharacterizationSidebar from "./characterizationSidebar";
import SampleSection from "./characterizationSidebar/SampleSection";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  CharacterizationState,
  getSampleData,
  getSpecimenFormFields,
  resetSamples,
  resetSaveSpecimens,
  resetCharacterizationRequest,
} from "../slice";
import { useQuery } from "../../../utils/router";
import Loading from "../../../layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../../../components/Toast";
import { uniq } from "lodash";
import { sampleIdToString } from "../../../utils/labels";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  zIndex: 1,
  position: "fixed",
});

const ExistingSamplesLayout = () => {
  const {
    samples,
    emptySpecimen,
    updatedSampleIds,
    status: { saveSpecimens, createCharacterizationRequest: c14RequestStatus },
  } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );
  const query = useQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = (location.state as LocationState) || {};

  useEffect(() => {
    dispatch(getSpecimenFormFields());

    const idParams = query.get("sample_id");
    if (idParams) {
      dispatch(resetSamples());
      dispatch(getSampleData({ sampleIds: idParams }));
    }
  }, [dispatch, query]);

  let saveSpecimenToast;
  if (saveSpecimens === "succeeded") {
    saveSpecimenToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetSaveSpecimens())}
      >
        {`Specimen(s) for ${uniq(updatedSampleIds)
          .map((_sampleId) => sampleIdToString(_sampleId))
          .join(", ")} saved.`}
      </Toast>
    );
  }

  let c14nRequestToast;
  if (c14RequestStatus === "succeeded") {
    c14nRequestToast = (
      <Toast
        open
        severity="success"
        onClose={() => dispatch(resetCharacterizationRequest())}
      >
        Characterization request successfully created. It may take some time to
        show up in Asana.
      </Toast>
    );
  }

  return (
    <>
      {samples.length === 0 || emptySpecimen.fields.length === 0 ? (
        <Loading light fullscreen />
      ) : (
        <>
          <HeaderContainer variant="outlined">
            <Box px={18} py={3} display="flex" justifyContent="space-between">
              <Box flexShrink={0} display="flex">
                <IconButton
                  onClick={() => navigate(from || "/characterization")}
                >
                  <ArrowBack style={{ marginRight: 12 }} />
                </IconButton>
                <Typography variant="h2" style={{ marginTop: 4 }}>
                  {`Sample${samples.length > 1 ? "s" : ""} Overview`}
                </Typography>
              </Box>
            </Box>
          </HeaderContainer>
          <Box display="flex">
            <CharacterizationSidebar samples={samples} />
            <Box
              pb={6}
              style={{
                background: colors.body.light,
                marginTop: 72,
              }}
            >
              {samples.map(
                (sample: CharacterizationSample, sampleIndex: number) => (
                  <SampleSection
                    key={sample.sample_id}
                    sample={sample}
                    sampleIndex={sampleIndex}
                    specimenTemplate={emptySpecimen}
                    allSampleIds={samples.map((_sample) => _sample.sample_id!)}
                  />
                )
              )}
            </Box>
          </Box>
          {saveSpecimenToast}
          {c14nRequestToast}
        </>
      )}
    </>
  );
};

export default ExistingSamplesLayout;
