import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Table,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseTableRow from "../../components/forms/BaseTableRow";
import ThCell from "../../components/forms/ThCell";
import ConditionCell from "../../components/forms/ConditionCell";
import useAutocompleteStyles from "../../components/forms/useAutocompleteStyles";
import InputCell from "../../components/forms/InputCell";
import MetaCell from "../../components/forms/MetaCell";
import { BUILD_PHASES, CHANNEL_POOL_EXPLAINER } from "../../utils/labels";
import ConditionDatePicker from "../conditions/ConditionDatePicker";
import SelectCell from "../../components/forms/SelectCell";
import Button from "../../components/Button";
import { ConditionsInitialFormFields } from "./NewConditionsCreationForms";

type ConditionsInitialFormProps = {
  cell_types_to_assemblies: { [key: string]: string[] };
  lastInitialFormState: ConditionsInitialFormFields | null;
  onNext: (initialFormData: ConditionsInitialFormFields) => void;
};

const ConditionsInitialForm = ({
  cell_types_to_assemblies,
  lastInitialFormState,
  onNext,
}: ConditionsInitialFormProps) => {
  const acClasses = useAutocompleteStyles();
  // Form management
  const { control, handleSubmit, setValue } =
    useForm<ConditionsInitialFormFields>({
      defaultValues: lastInitialFormState || {
        replicates: 1,
        cell_type: null,
        cell_assembly: null,
        number_of_conditions: 1,
        pool: null,
        plan_test_start_date: null,
        build_config: null,
        build_phase: null,
      },
    });
  const state = useWatch({
    control,
  });
  return (
    <Grid item xs={12}>
      <Box my={6}>
        <Box>
          <Typography variant="h2">
            Set 'Overview' values for all cell conditions
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontStyle: "italic", marginTop: 2 }}>
            Cell type and assembly must be same for all conditions of the batch,
            other fields can be modified per condition on the next page.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <form onSubmit={handleSubmit((data) => onNext(data))}>
          <Table size="small" style={{ width: "auto" }}>
            <TableBody className="small">
              <BaseTableRow>
                <ThCell required>Cell type</ThCell>
                <Controller
                  name="cell_type"
                  defaultValue={state.cell_type}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid },
                  }) => (
                    <ConditionCell>
                      <FormControl fullWidth>
                        <Autocomplete
                          className={acClasses.root}
                          options={Object.keys(cell_types_to_assemblies).sort()}
                          getOptionLabel={(option) => option}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          value={value}
                          onBlur={onBlur}
                          onChange={(e, val) => {
                            setValue("cell_assembly", null, {
                              shouldDirty: true,
                            });
                            onChange(val || null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              InputProps={{
                                ...params.InputProps,
                                className: params.InputProps.className,
                                error: invalid,
                              }}
                            />
                          )}
                          renderOption={(props, label) => (
                            <li {...props}>
                              <Box px={4} py={2}>
                                {label}
                              </Box>
                            </li>
                          )}
                        />
                      </FormControl>
                    </ConditionCell>
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell required>Cell assembly</ThCell>
                <Controller
                  name="cell_assembly"
                  defaultValue={state.cell_assembly}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { invalid },
                  }) => (
                    <ConditionCell>
                      <FormControl fullWidth>
                        <Autocomplete
                          className={acClasses.root}
                          options={
                            state.cell_type
                              ? cell_types_to_assemblies[state.cell_type]
                              : []
                          }
                          getOptionLabel={(option) => option}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          value={value}
                          onBlur={onBlur}
                          onChange={(e, selectedAssembly) => {
                            onChange(selectedAssembly || null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              InputProps={{
                                ...params.InputProps,
                                className: params.InputProps.className,
                                error: invalid,
                              }}
                            />
                          )}
                          renderOption={(props, label) => (
                            <li {...props}>
                              <Box px={4} py={2}>
                                {label}
                              </Box>
                            </li>
                          )}
                        />
                      </FormControl>
                    </ConditionCell>
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell required>Cells per condition</ThCell>
                <Controller
                  name="replicates"
                  defaultValue={state.replicates}
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <InputCell
                      ref={ref}
                      name={name}
                      type="number"
                      inputProps={{
                        min: 1,
                        onWheel: (e) => e.currentTarget.blur(),
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) =>
                        onChange(e.target.value ? parseInt(e.target.value) : "")
                      }
                      error={invalid}
                    />
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell required>Channel pool</ThCell>
                <Controller
                  name="pool"
                  defaultValue={state.pool}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid },
                  }) => (
                    <MetaCell
                      ref={ref}
                      endpoint="meta/channels/pool-ids"
                      searchKey="pool"
                      dropdownTooltipText={CHANNEL_POOL_EXPLAINER}
                      error={invalid}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell required>Planned on-test date</ThCell>
                <Controller
                  name="plan_test_start_date"
                  defaultValue={state.plan_test_start_date}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: fieldProps, fieldState: { invalid } }) => (
                    <ConditionDatePicker invalid={invalid} {...fieldProps} />
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell required>Number of cell conditions</ThCell>
                <Controller
                  name="number_of_conditions"
                  defaultValue={state.number_of_conditions}
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <InputCell
                      ref={ref}
                      name={name}
                      type="number"
                      inputProps={{
                        min: 1,
                        onWheel: (e) => e.currentTarget.blur(),
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) =>
                        onChange(e.target.value ? parseInt(e.target.value) : "")
                      }
                      error={invalid}
                    />
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell>Build phase</ThCell>
                <Controller
                  name="build_phase"
                  defaultValue={state.build_phase}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <SelectCell
                      ref={ref}
                      options={BUILD_PHASES}
                      error={invalid}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </BaseTableRow>
              <BaseTableRow>
                <ThCell>Build config</ThCell>
                <Controller
                  name="build_config"
                  defaultValue={state.build_config}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <InputCell
                      ref={ref}
                      name={name}
                      value={value || ""}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value || null)}
                      error={invalid}
                    />
                  )}
                />
              </BaseTableRow>
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" mt={4}>
            <Button
              color="primary"
              size="small"
              type="submit"
              style={{
                padding: "0.5rem",
              }}
              disabled={!state.cell_assembly || !state.cell_type}
            >
              <b>Next</b>
            </Button>
          </Box>
        </form>
      </Box>
    </Grid>
  );
};

export default ConditionsInitialForm;
