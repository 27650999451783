import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const CheckSquare = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      d="M6.75 9.00002L10.044 13.611C10.3188 13.9957 10.7564 14.2314 11.2289 14.249C11.7014 14.2666 12.1553 14.0642 12.458 13.701L23.25 0.749023"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 10.5V20.25C21.75 21.9069 20.4069 23.25 18.75 23.25H3.75C2.09315 23.25 0.75 21.9069 0.75 20.25V5.25C0.75 3.59315 2.09315 2.25 3.75 2.25H16.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CheckSquare;
