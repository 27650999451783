import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";

import colors from "../../theme/colors";

const Description = styled(Typography)({
  fontSize: "0.875rem",
  marginBottom: "0.75rem",
});

const Example = styled(Typography)({
  fontSize: "0.775rem",
  marginBottom: "0.75rem",
});

const Header = styled(Description)({
  fontWeight: 700,
  marginBottom: "0.5rem",
});

const Error = styled(Description)({
  color: colors.accent.red,
  marginTop: "0.5rem",
});

interface Props {
  value: string;
  title: string;
  description: string;
  updateText: (word: string) => void;
  maxLen: number;
  example?: string;
}

const ExperimentField = ({
  value,
  title,
  description,
  updateText,
  maxLen,
  example,
}: Props) => {
  return (
    <>
      <Header color="textSecondary">{title}</Header>

      <Description color="textSecondary">{description}</Description>

      <FormControl fullWidth>
        <Input
          autoFocus
          disableUnderline
          placeholder="Start typing..."
          inputProps={{ maxLength: maxLen }}
          value={value}
          onChange={(e) => updateText(e.target.value)}
        />
      </FormControl>
      <Box mb={3}></Box>
      {example && <Example color="textSecondary">Example: {example}</Example>}
      <Box mb={10}></Box>
    </>
  );
};

export { ExperimentField, Description, Header, Error };
