import React from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarProps } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import type { AlertProps } from "@mui/material/Alert";
import styled from "@mui/styles/styled";

import CheckCircleOutlinedIcon from "../icons/CheckCircleOutlined";
import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import TimesIcon from "../icons/Times";
import colors from "../theme/colors";

type Props = {
  open: boolean;
  noIcon?: boolean;
  onClose?: SnackbarProps["onClose"];
} & AlertProps;

const getStyle = (color: string) => ({
  color: colors.text.primary,
  background: colors.body.light,
  border: "none",
  borderLeft: `3px solid ${color}`,
  "& .MuiAlert-message": {
    marginTop: "auto",
    marginBottom: "auto",
  },
  "& .MuiAlert-icon": {
    color,
    marginTop: "auto",
    marginBottom: "auto",
  },
});

const SuccessAlert = styled(Alert)(getStyle(colors.accent.green));
const WarningAlert = styled(Alert)(getStyle(colors.accent.yellow));
const ErrorAlert = styled(Alert)(getStyle(colors.accent.red));

const CloseButton = styled(IconButton)({
  "& .MuiSvgIcon-root": {
    color: colors.text.secondary,
  },
});

const Toast = ({ open, noIcon, ...props }: Props) => {
  const { onClose, severity } = props;

  let alert;
  switch (severity) {
    case "success":
      alert = (
        <SuccessAlert
          icon={noIcon ? null : <CheckCircleOutlinedIcon fontSize="small" />}
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    case "warning":
      alert = (
        <WarningAlert
          icon={
            noIcon ? null : <ExclamationCircleOutlinedIcon fontSize="small" />
          }
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    case "error":
      alert = (
        <ErrorAlert
          icon={
            noIcon ? null : <ExclamationCircleOutlinedIcon fontSize="small" />
          }
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    default:
      alert = <Alert elevation={4} variant="outlined" {...props} />;
      break;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={severity === "warning" ? 10000 : 6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={onClose}
    >
      {alert}
    </Snackbar>
  );
};

export default Toast;
