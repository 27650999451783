import type { S3Response } from "react-s3-uploader";
import authManager from "../services/authManager";

export const PAGE_SIZE = 100;
export const LARGE_PAGE_SIZE = 250;
export const GATEWAY_TIMEOUT_ERROR = "Gateway Timeout";
export const GATEWAY_TIMEOUT_RESPONSE =
  "This request is taking longer than normal. Please refresh the page after a few minutes to see if it succeeded.";
export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

let impersonatingUserId: number | null = null;
let fetchingTokens: boolean = false;

const setImpersonatingUserId = (id: number | null) => {
  impersonatingUserId = id;
};

const setFetchingTokens = (currentlyFetchingTokens: boolean) => {
  fetchingTokens = currentlyFetchingTokens;
};

type ClientConfig = {
  body?: Object;
  apiVersion?: string;
} & Omit<RequestInit, "body">;

const _client = async (
  endpoint: string,
  { body, headers, method, apiVersion = "v1", ...customConfig }: ClientConfig
) => {
  if (authManager.shouldRefreshTokens() && !fetchingTokens) {
    setFetchingTokens(true);
    await authManager.refreshTokens().then(() => {
      setFetchingTokens(false);
    });
  }

  const authTokens = authManager.getAuthTokens() || {};
  const { access_token, id_token } = authTokens;
  const authorizationToken = id_token || process.env.REACT_APP_JWT_TOKEN;

  let impersonationHeaders = {};
  if (impersonatingUserId !== null) {
    impersonationHeaders = {
      "Impersonate-User-Id": impersonatingUserId,
    };
  }

  const config: RequestInit = {
    ...customConfig,
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      ...headers,
      ...impersonationHeaders,
      // Including the following header to skip a
      // confirmation page when user testing with ngrok
      "ngrok-skip-browser-warning": "True",
      Authorization: `Bearer ${authorizationToken}`,
      "Access-Token": access_token,
      "Content-Type": "application/json",
    },
  };

  const controller = new AbortController();
  const abortTimeoutId = setTimeout(
    () => controller.abort(),
    2 * 60 * 1000 /* 2 minutes */
  );

  let data: any;
  try {
    const response = await fetch(
      `${BACKEND_HOST}/api/${apiVersion}/${endpoint}`,
      {
        ...config,
        signal: controller.signal,
      }
    );

    clearTimeout(abortTimeoutId);

    try {
      data = await response.json();
    } catch (err) {
      if (![201, 204].includes(response.status)) {
        throw new Error("Invalid response format.");
      }
    }

    if (response.ok) {
      return data;
    } else if (data) {
      let error;
      if (data.occupied_channels) {
        const { occupied_channels } = data;
        error = `Channel${
          occupied_channels.length > 1 ? "s" : ""
        } ${occupied_channels.join(", ")} already occupied`;
      } else {
        error = data.description || data.title || data;
      }

      if (response.status === 504) {
        // Using this as a temporary stopgap for queuing errors
        throw new Error(GATEWAY_TIMEOUT_ERROR);
      } else if (
        response.status === 401 ||
        error?.includes("Invalid `id_token` value")
      ) {
        authManager.clearSessionTokens();
        window.location.reload();
      } else {
        throw new Error(error);
      }
    } else {
      throw new Error(response.statusText);
    }
  } catch (err: any) {
    return Promise.reject<string>(err.message ? err.message : data);
  }
};

const get = (endpoint: string, customConfig = {}) =>
  _client(endpoint, { ...customConfig, method: "GET" });

const post = (endpoint: string, body: Object, customConfig = {}) =>
  _client(endpoint, { ...customConfig, method: "POST", body });

const put = (endpoint: string, body: Object, customConfig = {}) =>
  _client(endpoint, { ...customConfig, method: "PUT", body });

const _delete = (endpoint: string, customConfig = {}) =>
  _client(endpoint, { ...customConfig, method: "DELETE" });

const presignS3Upload = async (
  file: File,
  callback: (params: S3Response) => void
) => {
  const data = await post("meta/presigned-images", {
    format: file.name.split(".").pop(),
  });
  callback(data);
};

const client = {
  get,
  post,
  put,
  delete: _delete,
  setImpersonatingUserId,
  presignS3Upload,
};

export default client;
