/* A (i) icon with an informative tooltip. */

import Tooltip from "@mui/material/Tooltip";

import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import colors from "../theme/colors";

type Props = {
  title: string;
  size: number;
};

const InfoTooltip = ({ title, size, ...props }: Props) => {
  return (
    <Tooltip
      arrow
      title={title}
    >
      <div
        style={{
          height: 20,
          width: 20,
          display: "inline-block",
          marginLeft: "1ex",
        }}
      >
        <ExclamationCircleOutlinedIcon
          style={{
            color: colors.text.primary,
            width: size,
            height: size,
            transform: "rotate(180deg)",
          }}
        />
      </div>
    </Tooltip>
  );
};

export default InfoTooltip;
