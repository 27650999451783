import React from "react";
import { Box } from "@mui/material";
import styled from "@mui/styles/styled";
import SidebarButton from "../../components/SidebarButton";
import colors from "../../theme/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { BatchConditionsSpecificationsState } from "./batchConditionsSpecificationsSlice";
import OverflowTooltip from "../../components/OverflowTooltip";
import { RedBadge } from "../../components/Badge";
import { OVERVIEW_SCREEN } from "./BatchCellConditionEdit";

const SidebarContainer = styled(Box)({
  width: 224,
  zIndex: 1,
  height: "calc(100vh - 74px)",
  borderRight: `1px solid ${colors.rules}`,
  background: colors.body.light,
  position: "fixed",
  paddingTop: "72px",
  overflow: "auto",
});

type BatchCellConditionEditSidebarProps = {
  onClickSubassembly: (subassemblyName: string) => void;
  activeSubassembly: string;
  numErrorsBySubassembly: { [subassemblyName: string]: number };
};

const BatchCellConditionEditSidebar = ({
  activeSubassembly,
  numErrorsBySubassembly,
  onClickSubassembly,
}: BatchCellConditionEditSidebarProps) => {
  const { editFormTemplate } = useSelector<
    RootState,
    BatchConditionsSpecificationsState
  >(({ batchConditionsSpecification }) => batchConditionsSpecification);

  return (
    <SidebarContainer>
      <Box my={2}>
        <SidebarButton
          className={`overflow-ellipsis ${
            activeSubassembly === OVERVIEW_SCREEN ? "active" : ""
          }`}
          onClick={() => onClickSubassembly(OVERVIEW_SCREEN)}
          style={{ fontSize: 14 }}
          color="text"
          endIcon={
            Object.keys(numErrorsBySubassembly).includes(OVERVIEW_SCREEN) &&
            numErrorsBySubassembly[OVERVIEW_SCREEN] > 0 ? (
              <RedBadge
                badgeContent={`${numErrorsBySubassembly[OVERVIEW_SCREEN]}`}
              />
            ) : null
          }
        >
          Overview
        </SidebarButton>
        {editFormTemplate &&
          editFormTemplate.map((formSection) => {
            const subassemblyIdentifier =
              formSection.component_type || formSection.name;
            var displayStr = subassemblyIdentifier;
            // Ref. electrodes have distinguishing text (eg. 'Anode', 'OEE') at end,
            // and so are hidden by ellipses. So, put that text in front for display
            // purposes
            if (
              displayStr
                .toLowerCase()
                .includes("reference electrode subassembly")
            ) {
              const refElectrodeType = displayStr.split(", ")[1];
              displayStr = refElectrodeType
                ? `${refElectrodeType} ref. electrode subassembly`
                : displayStr;
            }
            return (
              <SidebarButton
                key={formSection.name}
                className={`overflow-ellipsis ${
                  activeSubassembly === subassemblyIdentifier ? "active" : ""
                }`}
                style={{
                  fontSize: 14,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="text"
                onClick={() => onClickSubassembly(subassemblyIdentifier)}
                endIcon={
                  Object.keys(numErrorsBySubassembly).includes(
                    subassemblyIdentifier
                  ) && numErrorsBySubassembly[subassemblyIdentifier] > 0 ? (
                    <RedBadge
                      badgeContent={`${numErrorsBySubassembly[subassemblyIdentifier]}`}
                    />
                  ) : null
                }
              >
                <OverflowTooltip title={displayStr}>
                  <span>{displayStr}</span>
                </OverflowTooltip>
              </SidebarButton>
            );
          })}
      </Box>
    </SidebarContainer>
  );
};

export default BatchCellConditionEditSidebar;
