import Box from "@mui/material/Box";
import type { TableCellProps } from "@mui/material/TableCell";
import OverflowTooltip from "../OverflowTooltip";
import colors from "../../theme/colors";
import ConditionCell from "./ConditionCell";

type Props = TableCellProps & {
  title?: string;
  required?: boolean;
  largeWidth?: boolean;
  smallWidth?: boolean;
  overridePaddingStyle?: {
    padding: string;
  };
};

export const ThCell = ({
  children,
  title = "",
  required = false,
  style,
  overridePaddingStyle,
  ...props
}: Props) => (
  <ConditionCell component="th" {...props}>
    <Box
      p={overridePaddingStyle ? undefined : 4}
      display="flex"
      className="thCellInner"
      style={overridePaddingStyle}
    >
      {!props.smallWidth ? (
        <OverflowTooltip wrap={props.largeWidth} title={title}>
          <span className="small">{children}</span>
        </OverflowTooltip>
      ) : (
        children
      )}
      {required ? (
        <span style={{ color: colors.accent.red }}>&nbsp;*</span>
      ) : null}
    </Box>
  </ConditionCell>
);

export default ThCell;
