import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { uniq } from "lodash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import CheckSquareIcon from "../../../icons/CheckSquare";
import { hasPassedStatus } from "../../../utils/statuses";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import type { MetadataState } from "../slice";
import { completeMetadata } from "../slice";
import CellHeader from "./CellHeader";
import CompletedCell from "./CompletedCell";
import { cellStatusToString } from "../../../utils/labels";
import parseCellIdString from "../../../utils/parseCellIdString";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  minimumStatus: CellStatus;
  maximumStatus: CellStatus;
  editingCells: number[];
  step: number;
  executor?: User | null;
  showReadyOff?: boolean;
  validateInitialRefCal?: boolean;
  onSave: (callback?: () => void) => void;
  onEdit: (cellIds: number[]) => void;
  eventMap: EventMap;
  onViewEvents: (cell_id: number) => void;
  isOffTest: boolean;
};

const CompleteSubSection = ({
  formFields,
  minimumStatus,
  maximumStatus,
  editingCells,
  step,
  executor: preferredExecutor,
  showReadyOff,
  validateInitialRefCal,
  onSave,
  onEdit,
  eventMap,
  onViewEvents,
  isOffTest,
}: Props) => {
  const { cell_id_string = "" } = useParams();

  // Metadata state in redux
  const {
    status: { save: saveStatus, complete: completeStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);
  const dispatch = useDispatch();

  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );
  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);

  const {
    trigger,
    setValue,
    formState: { isDirty },
  } = useFormContext();

  const [unsavedInputsModalOpen, setUnsavedInputsModalOpen] = useState(false);
  const [confirmCellsOffTestModalOpen, setConfirmCellsOffTestModalOpen] =
    useState(false);
  const [completing, setCompleting] = useState<number[]>([]);

  const onComplete = useCallback(
    async (checkedCells: number[]) => {
      setUnsavedInputsModalOpen(false);
      setConfirmCellsOffTestModalOpen(false);
      setCompleting(checkedCells);

      const keysToValidate = formFields
        .filter(({ cell_id }) => checkedCells.includes(cell_id))
        .flatMap((field) =>
          Object.keys(field.items[step])
            .filter((key) => key !== "completed")
            .flatMap((key) => {
              const fieldIndex = formFields.findIndex(
                ({ cell_id }) => cell_id === field.cell_id
              );

              return validateInitialRefCal
                ? [
                    `metadata.${fieldIndex}.items.${step}.${key}`,
                    `metadata.${fieldIndex}.ref_cal_initial`,
                  ]
                : [`metadata.${fieldIndex}.items.${step}.${key}`];
            })
        );

      const result = await trigger(keysToValidate);
      if (!executor || !result) {
        setCompleting([]);
        return;
      }

      const components = formFields
        .filter(({ cell_id }) => checkedCells.includes(cell_id))
        .map((field) => field.items[step]);

      const { conditionIdsToRequestedCellIds } =
        parseCellIdString(cell_id_string);

      await dispatch(
        completeMetadata({
          components,
          executor,
          conditionIdsToRequestedCellIds,
        })
      );
      setUnsavedInputsModalOpen(false);
      setConfirmCellsOffTestModalOpen(false);
      setCompleting([]);
    },
    [
      formFields,
      step,
      executor,
      dispatch,
      trigger,
      cell_id_string,
      validateInitialRefCal,
    ]
  );

  const onUndo = async (component: any) => {
    const { conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string);

    dispatch(
      completeMetadata({
        components: [component],
        executor: null,
        conditionIdsToRequestedCellIds,
        undo: true,
      })
    );
  };

  const onEditCancel = (fieldIndex: number) => {
    const field = formFields[fieldIndex];
    const component = field.items[step];
    Object.keys(component).forEach((key) => {
      setValue(`metadata.${fieldIndex}.items.${step}.${key}`, component[key]);
    });
    onEdit(editingCells.filter((_cell_id) => _cell_id !== field.cell_id));
  };

  const someNotCompleted = formFields.some(
    (field) =>
      !field.items[step].completed?.completed_at &&
      !(
        !hasPassedStatus(minimumStatus, field.status) ||
        hasPassedStatus(maximumStatus, field.status, false)
      )
  );
  const confirmMessage =
    completing.length > 1
      ? "Confirm that these cells have been physically removed from their channel"
      : "Confirm that this cell has been physically removed from its channel";

  return (
    <>
      {!validateInitialRefCal ? (
        <TableHead>
          <BaseTableRow>
            <TableCell>EXECUTOR</TableCell>
            <TableCell>ACTIONS</TableCell>
            {formFields.map((field, fieldIndex) => (
              <CellHeader
                key={fieldIndex}
                cell_id={field.cell_id}
                isReadyOff={
                  showReadyOff &&
                  field.status === "N" &&
                  !!field.ready_off?.ready_off__completed_at
                }
                hasEvents={field.cell_id in eventMap}
                onViewEvents={onViewEvents}
              />
            ))}
          </BaseTableRow>
        </TableHead>
      ) : null}
      <TableBody>
        <BaseTableRow>
          <UserCell
            largeWidth
            clearable={false}
            error={!executor}
            value={executor}
            onChange={setExecutor}
            disabled={!someNotCompleted}
          />
          <ConditionCell smallWidth>
            <Button
              color="tertiary"
              type="button"
              size="small"
              disabled={!someNotCompleted || completeStatus === "loading"}
              onClick={() => {
                const cellIds = formFields
                  .filter(
                    (field) =>
                      hasPassedStatus(minimumStatus, field.status) &&
                      !hasPassedStatus(maximumStatus, field.status, false) &&
                      !field.items[step].completed?.completed_at
                  )
                  .map(({ cell_id }) => cell_id);

                setCompleting(cellIds);
                isDirty
                  ? setUnsavedInputsModalOpen(true)
                  : isOffTest
                  ? setConfirmCellsOffTestModalOpen(true)
                  : onComplete(cellIds);
              }}
            >
              <b>Mark all complete</b>
            </Button>
          </ConditionCell>
          {formFields.map((field, fieldIndex) =>
            field.items[step].completed?.completed_at ? (
              <ConditionCell
                key={fieldIndex}
                className={
                  !editingCells.includes(field.cell_id)
                    ? "componentCompleted"
                    : ""
                }
              >
                {editingCells.includes(field.cell_id) ? (
                  <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      color="tertiary"
                      type="button"
                      size="small"
                      style={{
                        padding: "0.5rem",
                      }}
                      onClick={() => onEditCancel(fieldIndex)}
                    >
                      <b>Cancel</b>
                    </Button>
                  </Box>
                ) : (
                  <CompletedCell
                    status={field.status}
                    minimumStatus={minimumStatus}
                    maximumStatus={maximumStatus}
                    onEdit={() =>
                      onEdit(uniq([...editingCells, field.cell_id]))
                    }
                    onUndo={() => onUndo(field.items[step])}
                    completed_at={field.items[step].completed?.completed_at}
                    completed_by={field.items[step].completed?.completed_by}
                  />
                )}
              </ConditionCell>
            ) : completeStatus === "loading" &&
              completing.includes(field.cell_id) ? (
              <ConditionCell key={fieldIndex}>
                <Box px={4} py={2}>
                  <CircularProgress
                    color="secondary"
                    style={{ width: 24, height: 24 }}
                  />
                </Box>
              </ConditionCell>
            ) : (
              <ConditionCell key={fieldIndex}>
                <Box px={4} py={2}>
                  <Tooltip
                    arrow
                    title={
                      !hasPassedStatus(minimumStatus, field.status)
                        ? `This step cannot be marked as complete as the cell hasn't reached "${cellStatusToString(
                            minimumStatus
                          )}"`
                        : hasPassedStatus(maximumStatus, field.status, false)
                        ? `This step cannot be undone as the cell is beyond "${cellStatusToString(
                            maximumStatus
                          )}"`
                        : ""
                    }
                    disableFocusListener={
                      !hasPassedStatus(minimumStatus, field.status) ||
                      hasPassedStatus(maximumStatus, field.status, false)
                    }
                    // Disable hover on the opposite condition of disabling the checkbox
                    // so the tooltip always shows in the disabled state
                    disableHoverListener={
                      hasPassedStatus(minimumStatus, field.status) &&
                      !hasPassedStatus(maximumStatus, field.status, false)
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          completeStatus === "loading" ||
                          !hasPassedStatus(minimumStatus, field.status) ||
                          hasPassedStatus(maximumStatus, field.status, false)
                        }
                        onClick={() => {
                          setCompleting([field.cell_id]);
                          isDirty
                            ? setUnsavedInputsModalOpen(true)
                            : isOffTest
                            ? setConfirmCellsOffTestModalOpen(true)
                            : onComplete([field.cell_id]);
                        }}
                      >
                        <CheckSquareIcon style={{ width: 22, height: 22 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </ConditionCell>
            )
          )}
        </BaseTableRow>
      </TableBody>
      <Modal
        open={unsavedInputsModalOpen}
        onClose={() => setUnsavedInputsModalOpen(false)}
      >
        <>
          <Typography variant="h2">Save inputs ?</Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary">
              You have unsaved inputs. You must save all inputs in order to
              continue.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="flex-end">
            <Box mr={1}>
              <Button
                color="secondary"
                onClick={() => setUnsavedInputsModalOpen(false)}
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              onClick={() => onSave(() => onComplete(completing))}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Save
            </Button>
          </Box>
        </>
      </Modal>
      <Modal
        open={confirmCellsOffTestModalOpen}
        onClose={() => setConfirmCellsOffTestModalOpen(false)}
      >
        <>
          <Typography variant="h2" textAlign="center">
            {confirmMessage}
          </Typography>
          <Box mt={8} mb={2}>
            <Typography color="textSecondary" textAlign="center">
              If you would like Tech Ops to remove the cell for you, please use
              the ready off test option instead.
            </Typography>
          </Box>
          <Box mt={8} display="flex" justifyContent="center">
            <Box mr={1}>
              <Button
                color="secondary"
                onClick={() => setConfirmCellsOffTestModalOpen(false)}
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              onClick={() => onComplete(completing)}
              endIcon={
                saveStatus === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={saveStatus === "loading"}
            >
              Cell(s) have been removed from their channel
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default CompleteSubSection;
