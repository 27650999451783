import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState, getCyclingProtocol } from "./slice";
import type { CyclingProtocolsState } from "./slice";

import InfoTooltip from "../../components/InfoTooltip";
import type { RootState } from "../../store";

import makeStyles from "@mui/styles/makeStyles";
import {
    Box,
    CircularProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";

// Round floating point values to 1 decimal. This matches the required precision
// for fact sheet display, and hides slight imprecision from TPV simulation where
// we want for example a lower voltage limit (LVL) of 0.3852056 to show as 0.4V.
function floatFmt(x: number | undefined, precision: number = 1): string {
  if (x === undefined) {
    return "";
  }
  return x.toFixed(precision);
}

// Style overrides for this component.
export const useSingleCyclingProtocolStyles = makeStyles({
  root: {
    // Do not uppercase the column labels where they would obscure units,
    // for example we want mW/cm2 not MW/CM2.
    "& .MuiTableCell-root": {
      textTransform: "none",
    },
  },
});

const SingleCyclingProtocolLayout = () => {
  const { cycling_protocol_id = "" } = useParams();
  const isTablet = useMediaQuery("(min-width:760px)");
  const [cyclingProtocolVisible, setCyclingProtocolVisible] =
    useState<boolean>(false);
  // Test script state from Redux.
  const { factSheet, cyclingProtocol, status, error } = useSelector<
    RootState,
    CyclingProtocolsState
  >(({ cyclingProtocols }) => cyclingProtocols);
  const dispatch = useDispatch();
  const classes = useSingleCyclingProtocolStyles();

  // Issue a request based on the cycling_protocol_id URL element,
  // the result of which will be made available in Redux state.
  useEffect(() => {
    dispatch(resetState());
    dispatch(
      getCyclingProtocol({
        cycling_protocol_id: cycling_protocol_id,
      })
    );
  }, [cycling_protocol_id, dispatch]);

  // JSX template for the page body.
  return (
    <>
      {/*Header*/}
      <Paper variant="outlined" square>
        <Box
          px={isTablet ? 6 : 0}
          py={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h2" gutterBottom>
              TPV {cycling_protocol_id} Fact Sheet
            </Typography>

            <Typography paragraph>
              This test protocol (TPV) fact sheet is derived from parsing the underlying Arbin .sdx
              file. Variables are directly extracted from steps that set them. Formulas are pulled
              directly as well, and show their initial value based on initial variable values.
            </Typography>
            <Typography paragraph>
              The cycling summary is generated by simulating Arbin test execution and cell behavior.
              Values which are directly evaluated, such as current on constant-current steps or
              lower voltage limits have high accuracy. However degradation, intermediate voltage
              and SOC, etc are estimated more loosely and so derived values (such as those from
              cycles using power steps), or the simulated timing in elapsed days, are less
              accurate. <Link
                href="https://wiki.formenergy.com/display/BD/107.+Cycling+Protocols+in+Oak"
                target="_blank"
              >
                More info
              </Link>.
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box
        px={isTablet ? 6 : 0}
        my={4}
        style={{ minHeight: "calc(100vh - 48px - 60px)" }}
      >
        <Paper variant="outlined" square>
          <Box px={isTablet ? 6 : 0} my={4}>
            {!(factSheet || error.get) && (
                <Box display="flex" alignItems="center">
                  <Typography style={{textTransform: "capitalize" }}>{status.get}...</Typography>
                  <Box ml={4}>
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  </Box>
                </Box>
            )}
            {error.get && (
              <Box>
                <Typography>Error: {error.get}</Typography>
              </Box>
            )}

            {factSheet && "error_message" in factSheet
              ? factSheet.error_message.map((message) => (
                  <Typography>{message}</Typography>
                ))
              : ""
            }

            {factSheet && "cycle" in factSheet ? (
              <TableContainer component={Paper}>
                <h2>Cycling Summary for {cycling_protocol_id}</h2>
                <Table
                  className={`dataTable ${classes.root}`}
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="formulas"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Cycle</TableCell>
                      <TableCell>
                        Label
                        <InfoTooltip
                          title="Label of the first non-global step in the cycle."
                          size={15}
                        />
                      </TableCell>
                      <TableCell>
                        Charge<br /> mA/cm2
                        <InfoTooltip
                          title={`Max charge rate during the cycle, excluding diagnostic steps. This is dependent on the active area of ${factSheet.active_area_cm2}cm2, verify against cell loading.`}
                          size={15}
                        />
                      </TableCell>
                      <TableCell>
                        mA/g
                        <InfoTooltip
                          title={`This is dependent on the anode mass of ${factSheet.anode_mass_g}g, verify against cell loading.`}
                          size={15}
                        />
                      </TableCell>
                      <TableCell>
                        mW/cm2
                        <InfoTooltip
                          title="Power values are only calculated when the cycle uses a power step."
                          size={15}
                        />
                      </TableCell>
                      <TableCell>mW/g</TableCell>
                      <TableCell>Discharge<br />mA/cm2</TableCell>
                      <TableCell>mA/g</TableCell>
                      <TableCell>mW/cm2</TableCell>
                      <TableCell>mW/g</TableCell>
                      <TableCell>
                        Charge Limit mAh/g
                        <InfoTooltip
                          title="Final charge capacity at the end of the cycle."
                          size={15}
                        />
                      </TableCell>
                      <TableCell>
                        Discharge Limit V
                        <InfoTooltip
                          title="The simulated voltage at the end of the last discharge step of the cycle."
                          size={15}
                        />
                      </TableCell>
                      <TableCell>Cycle has DCR</TableCell>
                      <TableCell>
                        Comment
                        <InfoTooltip
                          title="Cycles are considered Looping once the cycle counter is reset."
                          size={15}
                        />
                      </TableCell>
                      <TableCell>
                        Elapsed Days
                        <InfoTooltip
                          title="Simulated time from start of test to end of cycle, rounded to the nearest day."
                          size={15}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {factSheet.cycle.map((row) => (
                      <TableRow>
                        <TableCell>{row.cycle_num}</TableCell>
                        <TableCell>
                          {row.label}
                          {row.help_message ? (
                            <InfoTooltip title={row.help_message} size={15} />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>{floatFmt(row.charge_mA_cm2)}</TableCell>
                        <TableCell>{floatFmt(row.charge_mA_g)}</TableCell>
                        <TableCell>{floatFmt(row.charge_mW_cm2)}</TableCell>
                        <TableCell>{floatFmt(row.charge_mW_g)}</TableCell>
                        <TableCell>{floatFmt(row.discharge_mA_cm2)}</TableCell>
                        <TableCell>{floatFmt(row.discharge_mA_g)}</TableCell>
                        <TableCell>{floatFmt(row.discharge_mW_cm2)}</TableCell>
                        <TableCell>{floatFmt(row.discharge_mW_g)}</TableCell>
                        <TableCell>{floatFmt(row.charge_limit_mAh_g, 0)}</TableCell>
                        <TableCell>{floatFmt(row.discharge_limit_v)}</TableCell>
                        <TableCell>{row.is_dcr ? "DCR" : ""}</TableCell>
                        <TableCell>
                          {row.is_looping ? "Loop" : "Conditioning"}
                        </TableCell>
                        <TableCell>{floatFmt(row.total_elapsed_days, 0)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}

            {factSheet && "variable" in factSheet ? (
              <TableContainer component={Paper} style={{ marginTop: "15px" }}>
                <h2 style={{ marginLeft: "15px" }}>
                  Variables for {cycling_protocol_id}
                </h2>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="variables"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Step Label</TableCell>
                      <TableCell>Units</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Expression (if different)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {factSheet.variable.map((row) => (
                      <TableRow key={row["name"]}>
                        <TableCell>{row["name"]}</TableCell>
                        <TableCell>{row["step_label"]}</TableCell>
                        <TableCell>{row["units"]}</TableCell>
                        <TableCell>{floatFmt(row["value"])}</TableCell>
                        <TableCell>{row["expression"]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}

            {factSheet && "formula" in factSheet ? (
              <TableContainer component={Paper} style={{ marginTop: "15px" }}>
                <h2 style={{ marginLeft: "15px" }}>
                  Formulas for {cycling_protocol_id}
                </h2>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="formulas"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Expression</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {factSheet.formula.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.expression}</TableCell>
                        <TableCell>{floatFmt(row.value)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}

            {cyclingProtocol !== null && (
              <>
                <TableContainer
                  component={Paper}
                  style={{ marginTop: "15px", padding: "10px" }}
                >
                  <Typography
                    onClick={() =>
                      setCyclingProtocolVisible(!cyclingProtocolVisible)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Parsed Test Script for {cycling_protocol_id}
                  </Typography>

                  <Table
                    className={`dataTable ${classes.root}`}
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="formulas"
                  >
                    {cyclingProtocolVisible && (
                      <Typography>
                        <pre>{cyclingProtocol}</pre>
                      </Typography>
                    )}
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default SingleCyclingProtocolLayout;
