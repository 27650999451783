import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useWatch } from "react-hook-form";
import { createModule, resetCreateModuleStatus } from "./slice";
import type { ModuleState } from "./slice";
import type { RootState } from "../../store";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";

// MUI
import {
  Box,
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

// Components
import Button from "../../components/Button";
import { MODULE_TYPE_OPTIONS } from "../../utils/labels";

type Props = {
  exp_id: string;
  modalOpen: boolean;
  onClose: () => void;
};

const CreateModule = ({ exp_id, modalOpen = false, onClose }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, reset } =
    useForm<CreateModuleFormData>({
      defaultValues: {
        name: "",
        module_type: "",
        addToExisting: false,
      },
    });

  const requiredFieldVals = useWatch({
    control,
    name: ["name", "module_type"],
  });

  const {
    status: { create: createStatus },
    error: { create: createError },
  } = useSelector<RootState, ModuleState>(({ modules }) => modules);

  const onSubmit = (form: CreateModuleFormData) => {
    const data = { ...form, exp_id: exp_id };
    dispatch(createModule(data));
  };

  useEffect(() => {
    if (createStatus === "succeeded") {
      onClose();
      // Reset the form
      setValue("name", "", { shouldDirty: true });
      setValue("module_type", "", { shouldDirty: true });
    } else if (createStatus === "failed") {
      setError(createError);
    }
  }, [createStatus, setValue, exp_id, onClose, dispatch, createError]);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          onClose();
          reset();
        }}
      >
        <Typography variant="h2">Create Module</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                Module Name
              </Typography>

              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: false }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <Input
                      className="small"
                      disableUnderline
                      inputRef={ref}
                      error={invalid}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputProps={{ maxLength: 100 }}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box mt={6}>
              <Typography className="small" color="textSecondary">
                Module type
              </Typography>

              <FormControl style={{ width: "100%", height: "100%" }}>
                <Controller
                  control={control}
                  name="module_type"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <Select
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%", marginBottom: "20px" }}
                    >
                      {MODULE_TYPE_OPTIONS.map((opt, index) => (
                        <MenuItem key={`${opt}-${index}`} value={opt}>
                          {opt}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Box>
          </>

          <Button
            color="primary"
            size="small"
            type="submit"
            disabled={
              !modalOpen ||
              createStatus === "loading" ||
              requiredFieldVals.some((requiredFieldVal) => !requiredFieldVal)
            }
            style={{ marginTop: "20px", width: "fit-content" }}
            endIcon={
              createStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
          >
            Create
          </Button>
        </form>
      </Modal>

      {createStatus === "succeeded" && (
        <Toast
          open
          severity="success"
          onClose={() => {
            dispatch(resetCreateModuleStatus());
          }}
        >
          Module created.
        </Toast>
      )}

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default CreateModule;
