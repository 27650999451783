import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Flag = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 16 16"
  >
    {children}
    <path
      d="M2.375 13.625V2.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.375 10.8537L3.952 10.3687C5.27356 9.96186 6.70633 10.1441 7.884 10.8687C9.03261 11.5767 10.4259 11.7694 11.7235 11.3997L13.217 10.9727C13.4585 10.9037 13.6251 10.6829 13.625 10.4317V4.56823C13.6248 4.39178 13.542 4.22561 13.4011 4.11934C13.2603 4.01306 13.0777 3.97896 12.908 4.02723L11.7235 4.36573C10.4263 4.73625 9.03306 4.54466 7.884 3.83773C6.70633 3.11306 5.27356 2.93086 3.952 3.33773L2.375 3.82223"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Flag;
