import React, { useState, useCallback, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import SmallChip from "./SmallChip";
import client from "../api/index";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty } from "lodash";

type Props = {
  onChange: (data: null | User | User[]) => void;
  onBlur: () => void;
  value: User;
  invalid: boolean;
  multiple: boolean;
};

const UserSearch = ({
  onChange,
  onBlur,
  value,
  invalid,
  multiple = false,
}: Props) => {
  const [search, setSearch] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const _handleUserLookup = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get(`auth/users?name__contains=${search}`);
      setUsers(response.data);
    } catch (err) {
      setUsers([]);
    }

    setLoading(false);
  }, [search]);

  const handleUserLookup = useDebouncedCallback(_handleUserLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleUserLookup();
  }, [search, handleUserLookup]);

  return (
    <Autocomplete
      multiple={multiple}
      options={users}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
      value={value}
      onBlur={onBlur}
      onOpen={() => setSearch("")}
      onClose={() => setSearch(null)}
      onChange={(e, data) => {
        if (isEmpty(data)) {
          data = null;
        }
        onChange(data);
      }}
      loading={loading}
      noOptionsText={
        search !== null && search.length > 0
          ? "No results found."
          : "Start typing..."
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          color="secondary"
          error={invalid}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            value: search,
            onChange: (e) => setSearch(e.target.value),
          }}
        />
      )}
      renderOption={(props, opt) => (
        <li {...props}>
          <Checkbox
            color="secondary"
            checked={props["aria-selected"] === true}
          />
          <Box py={2} width="100%">
            <Typography
              color="textPrimary"
              className={opt.email ? "small" : undefined}
            >
              {opt.name}
            </Typography>
            {opt.email ? (
              <Typography color="textSecondary" className="tiny">
                {opt.email}
              </Typography>
            ) : null}
          </Box>
        </li>
      )}
      renderTags={(values, getTagProps) =>
        values.map((value, index) => (
          <Box key={value?.user_id} mr={2} mb={1}>
            <SmallChip label={value?.name} {...getTagProps({ index })} />
          </Box>
        ))
      }
    />
  );
};

export default UserSearch;
