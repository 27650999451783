// Read clipboard, and if possible, return array of strings from CSV value.
//  otherwise, return null.
// Note this is only currently build to handle a CSV of a single column of data.
const readCSVFromClipboard = async (splitTextChars: RegExp) => {
  var splitTextFromClipboard: null | string[] = null;
  const clipboardContents = await navigator.clipboard.read();

  for (const item of clipboardContents) {
    for (const mimeType of item.types) {
      if (mimeType === "text/plain") {
        const blob = await item.getType("text/plain");
        const blobText = await blob.text();
        splitTextFromClipboard = blobText
          .split(splitTextChars)
          .map((textItem) => textItem.trim().replaceAll('"', ""));
      }
    }
  }
  return splitTextFromClipboard;
};

const readCSVFromClipboardData = (
  event: ClipboardEvent,
  splitTextChars: RegExp
) => {
  if (!event.clipboardData) return null;
  const text = event.clipboardData.getData("text");
  return text
    .split(splitTextChars)
    .map((textItem) => textItem.trim().replaceAll('"', ""));
};

export { readCSVFromClipboard, readCSVFromClipboardData };
