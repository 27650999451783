import Box from "@mui/material/Box";
import Header from "./Header";
import Sidebar from "./sidebar";
import colors from "../../../theme/colors";

type Props = {
  step: number;
  rootRef: React.Ref<HTMLDivElement>;
  onSave: () => void;
  cellLayout: MetadataSidebarCellLayout;
  onToggleCell: (id: number) => void;
  sidebarLayout: MetadataSidebarLayout;
  children: React.ReactNode;
  hasPendingChanges: boolean;
  eventData: EventData[] | null;
  activeCellIds: number[];
};

const Wrapper = ({
  step,
  rootRef,
  onSave,
  cellLayout,
  onToggleCell,
  activeCellIds,
  sidebarLayout,
  children,
  hasPendingChanges,
  eventData,
}: Props) => (
  <Box display="flex" flexDirection="column">
    <Header
      hasPendingChanges={hasPendingChanges}
      onSave={onSave}
      eventData={eventData}
      activeCellIds={activeCellIds}
    />

    <Sidebar
      cellLayout={cellLayout}
      layout={sidebarLayout}
      step={step}
      onToggleCell={onToggleCell}
    />

    <div
      ref={rootRef}
      style={{
        paddingTop: 74,
        paddingLeft: 256,
        height: "calc(100vh - 74px)",
        background: colors.body.light,
      }}
    >
      {children}
    </div>
  </Box>
);

export default Wrapper;
