import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableCell, TableContainer, TableHead } from "@mui/material";
import { SxProps } from "@mui/system";
import colors from "../../../theme/colors";
import Button from "../../../components/Button";
import { isBoolean, capitalize } from "lodash";

const LONG_HEADER_STYLES: SxProps = {
  whiteSpace: "normal",
  wordWrap: "break-word",
  maxWidth: 160,
  padding: "6px",
};

type Props = {
  activeDefaultIndex: number | null;
  fieldsWithMultiDefaults: ConditionUIStepCategory["fields"];
  onChangeActiveDefaultIndex: (index: number) => void;
};

const MultiDefaultsHelperTable = ({
  activeDefaultIndex,
  fieldsWithMultiDefaults,
  onChangeActiveDefaultIndex,
}: Props) => {
  return (
    <TableContainer sx={{ marginBottom: 8, marginTop: 4 }}>
      <Table
        className="qa-multi-defaults-table"
        size="small"
        style={{ width: "auto" }}
      >
        <TableHead>
          <TableRow style={{ background: colors.striping }}>
            <TableCell align="center">Auto-fill Defaults</TableCell>
            {fieldsWithMultiDefaults!.map(({ id, label }, index) => (
              <TableCell
                key={`head-${id}-${index}`}
                align="center"
                sx={label.length > 18 ? LONG_HEADER_STYLES : { padding: "6px" }}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="small">
          {(fieldsWithMultiDefaults![0].default as any[]).map(
            (defaultVal, index) => (
              <TableRow
                key={`row-${defaultVal?.material_id || defaultVal}-${index}`}
                selected={activeDefaultIndex === index}
              >
                <TableCell align="center">
                  <Button
                    className="qa-fill-defaults"
                    color="tertiary"
                    type="button"
                    size="small"
                    onClick={() => onChangeActiveDefaultIndex(index)}
                  >
                    <b>Fill defaults</b>
                  </Button>
                </TableCell>
                {fieldsWithMultiDefaults!.map((field, idx) => {
                  const defaults = field.default as any[];
                  const rawVal = defaults[index];
                  const displayVal = isBoolean(rawVal)
                    ? capitalize(String(rawVal))
                    : !rawVal || rawVal === "none"
                    ? "-"
                    : rawVal.material_id || rawVal;
                  return (
                    <TableCell
                      key={`col-${field.id}-${displayVal}-${idx}`}
                      align="center"
                    >
                      {displayVal}
                    </TableCell>
                  );
                })}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MultiDefaultsHelperTable;
