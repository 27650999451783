import React, { useCallback, useState, useEffect, useMemo } from "react";
import type { DynamicEventField, ExperimentOwnerMap } from "./slice";
import client from "../../api";
import colors from "../../theme/colors";

import { Controller } from "react-hook-form";

// MUI
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

// Components
import SmallChip from "../SmallChip";
import ButtonCellIdentifierSelection, {
  CELL_IDENTIFIER_OPTIONS,
  CellIdentifierObj,
} from "./ButtonCellIdentifierSelection";
import { useDebouncedCallback } from "use-debounce";

// Object with data for the api call.
// Value data is in the format [endpoint, valueKey]
const API_DATA = {
  cell: ["meta/cells/all-ids", null],
  channel: ["meta/channels", "channel_fullname"],
  experiment: ["meta/experiments/ids", null],
  incubator: ["meta/incubators/names", null],
  test_stand: ["meta/test-stands/names", null],
  tester: ["meta/testers", null],
};

type Props = {
  control: any;
  field: DynamicEventField;
  ownersString: string;
  experimentOwnerMap: ExperimentOwnerMap;
  setExperimentOwnerMap: (input: ExperimentOwnerMap) => void;
  setActiveExperiments: (input: number[]) => void;
};

const DynamicEventFieldInput = ({
  control,
  field,
  ownersString,
  experimentOwnerMap,
  setExperimentOwnerMap,
  setActiveExperiments,
}: Props) => {
  const itemType = field.itemType;
  const [endpoint, valueKey] = API_DATA[itemType];
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [options, setOptions] = useState<DynamicItem[]>(field.items);
  const [cellIdentifier, setCellIdentifier] = useState<
    "cell" | "cell_serial_number" | "position_id" | null
  >(null);

  useEffect(() => {
    // If this is the cell input, detect if the cell identifier should be
    // 1. position_id or 2. cell_serial_number. If neither, default to cell_id.
    if (!cellIdentifier && itemType === "cell" && field.items.length > 0) {
      if (
        field.items.every(
          (selectedCell) =>
            !!selectedCell["module_name"] && !!selectedCell["position_id"]
        )
      ) {
        setCellIdentifier("position_id");
      } else if (
        field.items.every(
          (selectedCell) => !!selectedCell["cell_serial_number"]
        )
      ) {
        setCellIdentifier("cell_serial_number");
      } else {
        setCellIdentifier("cell");
      }
    }
  }, [cellIdentifier, itemType, field.items]);

  const [moduleId, moduleName] = useMemo<(null | number | string)[]>(() => {
    if (cellIdentifier === "position_id") {
      const moduleInfo = field.items.find(
        (fieldItem) => !!fieldItem.module_id && !!fieldItem.module_name
      );
      if (moduleInfo) {
        return [moduleInfo.module_id!, moduleInfo.module_name!];
      }
    }
    return [null, null];
  }, [cellIdentifier, field.items]);

  const identifierObj = useMemo<CellIdentifierObj | null>(() => {
    if (cellIdentifier) {
      return CELL_IDENTIFIER_OPTIONS.find(({ id }) => id === cellIdentifier)!;
    }
    return null;
  }, [cellIdentifier]);

  const isCellInput = !!cellIdentifier && !!identifierObj;

  const _formatResponseData = (data: any) => {
    switch (itemType) {
      case "cell":
        return data.map(
          ({
            id,
            position_id,
            cell_serial_number,
            module_id,
            module_name,
            exp_id,
          }: DynamicItem) => ({
            id,
            position_id,
            cell_serial_number,
            module_id,
            module_name,
            exp_id,
          })
        );

      case "experiment":
        return data.map((obj: [number, string]) => ({
          id: obj[0],
          alt_id: obj[1],
        }));

      case "channel":
        return data.map(
          ({
            channel_fullname,
            channel_id,
          }: {
            channel_fullname: string;
            channel_id: number;
          }) => ({
            id: channel_id,
            alt_id: channel_fullname,
          })
        );

      case "test_stand":
      case "tester":
      case "incubator":
        return data.map((obj: [string, number]) => ({
          id: obj[1],
          alt_id: obj[0],
        }));
    }
  };

  const _handleLookup = useCallback(async () => {
    if (search === null || (!!cellIdentifier && !search)) {
      return;
    }

    setLoading(true);

    try {
      let completeEndpoint = `${endpoint}?`;
      if (isCellInput) {
        switch (cellIdentifier) {
          case "position_id":
            completeEndpoint += `module__cell__position_id=${search}`;
            if (!!moduleId) {
              completeEndpoint += `&cell__module_id=${moduleId}`;
            }
            break;
          case "cell_serial_number":
            completeEndpoint += `full__cell__serial_number=${search}`;
            break;
          case "cell":
          default:
            completeEndpoint += `cell__cell_id=${search
              .toUpperCase()
              .replace(field.prefix, "")}`;
        }
      } else {
        completeEndpoint += `${
          valueKey ? `${valueKey}__contains` : "search"
        }=${search.toUpperCase().replace(field.prefix, "")}`;

        if (["tester", "incubator", "test_stand"].includes(itemType)) {
          completeEndpoint += `&return-fields=${itemType}_id`;
        } else if (itemType === "experiment") {
          completeEndpoint += "&return-fields=owner__name";
        } else if (!!moduleId) {
          completeEndpoint += `&module_id=${moduleId}`;
        }
      }

      const response = await client.get(completeEndpoint);

      setOptions(_formatResponseData(response.data));
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [search, valueKey, field.prefix, endpoint]);

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleLookup();
  }, [search, handleLookup]);

  const renderItemWithPrefix = (opt: any) => {
    if (isCellInput) {
      const valForIdentifier =
        opt[identifierObj.identifierKey] ||
        `N/A (${
          opt.cell_serial_number
            ? `SN: ${opt.cell_serial_number}`
            : `CEL${opt.id}`
        })`;
      return identifierObj.prefix + valForIdentifier;
    }
    return field.prefix && field.prefix !== ""
      ? field.prefix + opt.id
      : opt.alt_id;
  };

  return (
    <Box key={field.title} mt={6}>
      <Box display="flex">
        <Typography className="small" color="textSecondary">
          {field.title}
          <span style={{ color: colors.accent.red }}>*</span>

          {itemType === "experiment" && (
            <>
              <br />
              Owner(s): {ownersString}
            </>
          )}
        </Typography>
        {cellIdentifier && (
          <ButtonCellIdentifierSelection
            cellIdentifier={cellIdentifier}
            moduleName={moduleName as string | null}
            onSelectCellIdentifier={(newCellIdentifier) =>
              setCellIdentifier(
                newCellIdentifier as
                  | "cell"
                  | "cell_serial_number"
                  | "position_id"
              )
            }
          />
        )}
      </Box>

      <FormControl style={{ width: "100%", height: "100%" }}>
        <Controller
          control={control}
          name={`${itemType}s`}
          rules={{ required: true }}
          defaultValue={field.items}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Autocomplete
              ref={ref}
              multiple
              disableCloseOnSelect
              options={options}
              getOptionLabel={(option) => {
                if (isCellInput) {
                  return String(option[identifierObj.identifierKey]);
                }
                if (field.prefix && field.prefix !== "") {
                  return field.prefix + String(option.id);
                } else {
                  return String(option.alt_id);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={value}
              onBlur={onBlur}
              freeSolo={isCellInput}
              forcePopupIcon
              onOpen={() => setSearch("")}
              onClose={() => setSearch(null)}
              loading={loading}
              onChange={(e, data) => {
                if (itemType === "experiment") {
                  // Collect experiment-owner data for experiments
                  // that weren't passed in initially and add them to
                  // experimentOwnerMap
                  let newMappings = { ...experimentOwnerMap };
                  data
                    .filter((obj) => "alt_id" in obj)
                    .forEach((obj) => {
                      newMappings[obj.id] = obj.alt_id;
                    });
                  setExperimentOwnerMap({
                    ...newMappings,
                  });
                  setActiveExperiments(data.map((obj) => obj.id));
                }
                onChange(data);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    value: search,
                    onChange: (e) => setSearch(e.target.value.toUpperCase()),
                  }}
                  error={invalid}
                />
              )}
              renderOption={(props, opt) => (
                <li {...props} key={opt.id}>
                  <Checkbox
                    color="secondary"
                    checked={props["aria-selected"] === true}
                  />
                  <Box py={2} width="100%">
                    {renderItemWithPrefix(opt)}
                    {isCellInput &&
                    !!identifierObj["secondaryIdentifierKey"] ? (
                      <Typography color="textSecondary" className="tiny">
                        {opt[identifierObj.secondaryIdentifierKey]}
                      </Typography>
                    ) : null}
                  </Box>
                </li>
              )}
              renderTags={(values, getTagProps) =>
                values.map((value, index) => (
                  <Box key={index} mr={2} mb={1}>
                    <SmallChip
                      label={renderItemWithPrefix(value)}
                      {...getTagProps({ index })}
                      size="medium"
                      sx={{
                        "& .MuiChip-label": {
                          overflow: "visible",
                        },
                      }}
                    />
                  </Box>
                ))
              }
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default DynamicEventFieldInput;
