import { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import client from "../../api/index";
import { useDebouncedCallback } from "use-debounce";

const useNavbarAutocompleteStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      paddingTop: 4,
      paddingBottom: 4,
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
        background: "white",
      },
    },
    "&:not(.Mui-focused)": {
      "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
        color: "white",
      },
    },
  },
});

type Props = {
  value: User | undefined;
  onChange: (user: User) => void;
  error?: boolean;
  onBlur?: () => void;
  navMode?: boolean;
};

const ImpersonationUserSearch = ({
  value,
  onChange,
  error,
  onBlur,
  navMode,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<User[]>([]);
  const [search, setSearch] = useState("");
  const navStyles = useNavbarAutocompleteStyles();

  const _handleLookup = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get(`auth/users?name__contains=${search}`);
      setOptions(response.data);
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
  }, [search]);

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    if (search !== "") {
      handleLookup();
    }
  }, [search, handleLookup]);

  return (
    <FormControl style={{ width: navMode ? 300 : "100%" }}>
      <Autocomplete
        className={navMode ? navStyles.root : undefined}
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) =>
          option.user_id === value.user_id
        }
        value={value}
        onBlur={onBlur}
        onChange={(e, data) => {
          if (data) {
            onChange(data);
          }
        }}
        loading={loading}
        noOptionsText={
          search.length > 0 ? "No results found." : "Start typing..."
        }
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="outlined"
            size="small"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              error,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
        )}
        renderOption={(props, { name, email }) => (
          <li {...props}>
            <Box px={4} py={2}>
              <Typography
                color="textPrimary"
                component="span"
                className={navMode ? "small" : ""}
              >
                {name}
              </Typography>{" "}
              <Typography
                color="textSecondary"
                component="span"
                className={navMode ? "small" : ""}
              >
                {email}
              </Typography>
            </Box>
          </li>
        )}
      />
    </FormControl>
  );
};

export default ImpersonationUserSearch;
