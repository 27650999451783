import { forwardRef, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import SmallChip from "../../components/SmallChip";
import client from "../../api";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  value: string[];
  onBlur: () => void;
  onChange: (tags: string[]) => void;
  error?: boolean;
};

const NoteTagDropdown = forwardRef(
  (
    { value, onBlur, onChange, error }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState<string[]>([]);
    const [search, setSearch] = useState<string | null>(null);

    const _handleLookup = useCallback(async () => {
      if (search === null) {
        return;
      }

      setLoading(true);

      try {
        const response: { data: string[] } = await client.get(
          `meta/note-tags?search=${search}`
        );
        setOptions(response.data);
      } catch (err) {
        setOptions([]);
      }

      setLoading(false);
    }, [search]);

    const handleLookup = useDebouncedCallback(_handleLookup, 400, {
      leading: true,
      trailing: true,
    });

    useEffect(() => {
      handleLookup();
    }, [search, handleLookup]);

    return (
      <FormControl style={{ width: "100%" }}>
        <Autocomplete
          ref={ref}
          multiple
          freeSolo
          options={options}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          value={value}
          onBlur={onBlur}
          onOpen={() => setSearch("")}
          onClose={() => setSearch(null)}
          onChange={(e, data) => onChange(data)}
          loading={loading}
          noOptionsText={
            search !== null && search.length > 0
              ? "No results found."
              : "Start typing..."
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                value: search,
                onChange: (e) => setSearch(e.target.value),
                error,
              }}
            />
          )}
          renderOption={(props, opt) => (
            <li {...props}>
              <Checkbox
                color="secondary"
                checked={props["aria-selected"] === true}
              />
              <Box py={2} width="100%">
                {opt}
              </Box>
            </li>
          )}
          renderTags={(values, getTagProps) =>
            values.map((value, index) => (
              <Box key={value} mr={2} mb={1}>
                <SmallChip label={value} {...getTagProps({ index })} />
              </Box>
            ))
          }
        />
      </FormControl>
    );
  }
);

export default NoteTagDropdown;
