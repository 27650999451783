import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import client from "../../api";
import { getChangeRequestDisplayValue } from "../../utils/conditions";

export const METADATA = "METADATA";

type OnTestResponse = {
  data: {
    test_id: string;
    exp_id: string;
    cell_id: string;
    data_source: string;
  }[];
};

type QualityCheckResponse = {
  cell_id: number;
  quality_check_completed_at: string | null;
  quality_check_completed_by: User | null;
};

const setQCResponseInState = (
  uiCopy: (ConditionMetadata | ConditionOverviewData)[],
  qcResponse: QualityCheckResponse
) => {
  const {
    cell_id: cellId,
    quality_check_completed_at,
    quality_check_completed_by,
  } = qcResponse;
  if (!("fields" in uiCopy[0])) return uiCopy;
  const fieldIndexToUpdate = uiCopy[0].fields.findIndex(
    ({ cell_id }) => cell_id === cellId
  );
  const cellDataToUpdate = uiCopy[0].fields[fieldIndexToUpdate];
  if (cellDataToUpdate) {
    uiCopy[0].fields[fieldIndexToUpdate].quality_check = {
      quality_check_completed_at,
      quality_check_completed_by,
    };
  }
  return uiCopy;
};

export interface MetadataState {
  ui: null | (ConditionMetadata | ConditionOverviewData)[];
  pendingChanges: Record<string, Record<string, any>>;
  fileNamesRecentlyGenerated: string[];
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
    complete: "idle" | "loading" | "succeeded" | "failed";
    onTest: "idle" | "loading" | "succeeded" | "failed";
    addEditRefCal: "idle" | "loading" | "succeeded" | "failed";
    delRefCal: "idle" | "loading" | "succeeded" | "failed";
    initialRefCal: "idle" | "loading" | "succeeded" | "failed";
    updateTestConditionTemplates: "idle" | "loading" | "succeeded" | "failed";
    qualityCheckUpdate: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    save: null | string;
    complete: null | string;
    onTest: null | string;
    addEditRefCal: null | string;
    delRefCal: null | string;
    initialRefCal: null | string;
    updateTestConditionTemplates: null | string;
    qualityCheckUpdate: null | string;
  };
}

const initialState: MetadataState = {
  ui: null,
  pendingChanges: {},
  fileNamesRecentlyGenerated: [],
  status: {
    get: "idle",
    save: "idle",
    complete: "idle",
    onTest: "idle",
    addEditRefCal: "idle",
    delRefCal: "idle",
    initialRefCal: "idle",
    updateTestConditionTemplates: "idle",
    qualityCheckUpdate: "idle",
  },
  error: {
    get: null,
    save: null,
    complete: null,
    onTest: null,
    addEditRefCal: null,
    delRefCal: null,
    initialRefCal: null,
    updateTestConditionTemplates: null,
    qualityCheckUpdate: null,
  },
};

const getMetadataForConditionIds = async ({
  conditionIdsToRequestedCellIds,
  hiddenConditionIds = [],
}: {
  conditionIdsToRequestedCellIds: { [key: string]: string[] };
  hiddenConditionIds?: number[];
}) => {
  const requests = Object.keys(conditionIdsToRequestedCellIds).map(
    (condition_id) => {
      const cellIds = conditionIdsToRequestedCellIds[condition_id];
      return {
        method: "GET",
        path: `/api/v1/meta/cell-conditions/${condition_id}/meta${
          cellIds && cellIds.length > 0
            ? `?${cellIds
                .map((cellId) => `requested_cell_ids=${cellId}`)
                .join("&")}`
            : ""
        }`,
      };
    }
  );
  const overviewDataRequests = hiddenConditionIds
    .filter(
      (conditionId) =>
        !Object.keys(conditionIdsToRequestedCellIds).includes(
          String(conditionId)
        )
    )
    .map((condition_id) => ({
      method: "GET",
      path: `/api/v1/meta/cell-conditions/${condition_id}/meta-basic`,
    }));

  const results: BatchResponse<ConditionMetadata | ConditionOverviewData>[] =
    await client.post("core/batch", [...requests, ...overviewDataRequests]);

  const meta = results
    .filter(({ status }) => status < 300)
    .map(({ body: { data } }) => data);

  const errors = results
    .filter(({ status }) => status >= 300)
    .map(({ body: { title } }) => title);

  const fullConditionMetadata: ConditionMetadata[] = meta.reduce(
    (fullConditionMetadatas: ConditionMetadata[], conditionData) => {
      if (
        Object.keys(conditionIdsToRequestedCellIds).includes(
          String(conditionData.cell_condition_id)
        )
      ) {
        return [...fullConditionMetadatas, conditionData as ConditionMetadata];
      } else {
        return fullConditionMetadatas;
      }
    },
    []
  );

  const components = fullConditionMetadata
    .flatMap(({ spec }) =>
      spec
        .flatMap((item) =>
          item.fields ? [item as ConditionUIStep] : item.items!
        )
        .map(({ entity_type, condition_component_id, test_condition_id }) => ({
          entity_type,
          condition_component_id,
          test_condition_id,
        }))
    )
    .flatMap((items) => items);

  const changeRequestResults: BatchResponse<ChangeRequest[]>[] =
    await client.post("core/batch", [
      {
        method: "GET",
        path: `/api/v1/meta/change-requests?table=condition_component&status=P${components
          .filter(({ entity_type }) => entity_type === "condition_component")
          .map(
            ({ condition_component_id }) =>
              `&record_id=${condition_component_id}`
          )
          .join("")}`,
        data: {},
      },
      {
        method: "GET",
        path: `/api/v1/meta/change-requests?table=test_condition&status=P${components
          .filter(({ entity_type }) => entity_type === "test_condition")
          .map(({ test_condition_id }) => `&record_id=${test_condition_id}`)
          .join("")}`,
        data: {},
      },
    ]);

  const changeRequests = changeRequestResults.flatMap(
    ({ body: { data } }) => data
  );
  const pendingChanges: Record<string, Record<string, any>> = {};

  changeRequests.forEach(
    ({ table, record_id, field, field_type, new_value }) => {
      pendingChanges[`${table}_${record_id}`] = {
        ...pendingChanges[`${table}_${record_id}`],
        [field]: getChangeRequestDisplayValue(new_value, field, field_type),
      };
    }
  );

  return Promise.resolve({ meta, pendingChanges, errors });
};

export const getMetadata = createAsyncThunk(
  `${METADATA}/get`,
  getMetadataForConditionIds
);

type SaveMetadataArgs = {
  items: Record<string, any>;
};

export const saveMetadata = createAsyncThunk(
  `${METADATA}/save`,
  async ({
    metadata,
    conditionIdsToRequestedCellIds,
  }: {
    metadata: SaveMetadataArgs[];
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
  }) => {
    if (metadata.length === 0) {
      return getMetadataForConditionIds({ conditionIdsToRequestedCellIds });
    }

    const updates = metadata.flatMap(({ items = {} }) =>
      Object.keys(items).map((key: string) => ({
        method: "PUT",
        path: items[key]["api_path"],
        data: omit(items[key], "api_path"),
      }))
    );

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    const {
      meta,
      pendingChanges,
      errors: getErrors,
    } = await getMetadataForConditionIds({ conditionIdsToRequestedCellIds });

    return Promise.resolve({
      meta,
      pendingChanges,
      errors: [...errors, ...getErrors],
    });
  }
);

export const completeMetadata = createAsyncThunk(
  `${METADATA}/complete`,
  async ({
    components,
    executor,
    conditionIdsToRequestedCellIds,
    undo = false,
  }: {
    components: Pick<MetadataStep, "completed">[];
    executor: User | null;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
    undo?: boolean;
  }) => {
    if (components.length === 0) {
      return getMetadataForConditionIds({ conditionIdsToRequestedCellIds });
    }

    const updates = components.map(({ completed: { api_path } }) => ({
      method: "PATCH",
      path: !undo ? api_path.do : api_path.undo,
      data: !undo ? { user_id: executor?.user_id } : {},
    }));

    const results: BatchResponse<any>[] = await client.post(
      "core/batch",
      updates
    );
    const errors = results
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    const {
      meta,
      pendingChanges,
      errors: getErrors,
    } = await getMetadataForConditionIds({ conditionIdsToRequestedCellIds });

    return Promise.resolve({
      meta,
      pendingChanges,
      errors: [...errors, ...getErrors],
    });
  }
);

export const generateTest = createAsyncThunk(
  `${METADATA}/onTest`,
  async ({
    cell_ids,
    module_id,
    channel,
    testStand,
    executor,
    conditionIdsToRequestedCellIds,
    test_type,
    adapter_cable_type,
    h2GasFlowRate,
  }: {
    cell_ids: number[];
    module_id?: string;
    channel: Channel | null;
    testStand: TestStand | null;
    executor: User | null;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
    test_type: { id: string; label: string };
    adapter_cable_type: string | null;
    h2GasFlowRate: string;
  }) => {
    if (testStand && (channel || module_id)) {
      const message = channel
        ? "Cannot generate a test using both a channel and test stand."
        : "Cannot generate a test on a test stand for a module cell.";
      return Promise.reject({ message });
    }

    const response: OnTestResponse = await client.post(`meta/cells/tests`, {
      cell_ids,
      module_id,
      test_type,
      adapter_cable_type,
      channel_id: channel?.channel.channel_id,
      test_stand_id: testStand?.test_stand_id,
      user_id: executor?.user_id,
      h2_sweep_gas_flow_rate: h2GasFlowRate || null,
    });
    const fileNames = response.data.map((test) => test.data_source);

    if (module_id) {
      // Avoid the metadata call when putting modules on test
      return { module_id: module_id, fileNames: fileNames };
    } else {
      const { meta, pendingChanges, errors } = await getMetadataForConditionIds(
        {
          conditionIdsToRequestedCellIds,
        }
      );
      return Promise.resolve({ meta, pendingChanges, errors, fileNames });
    }
  }
);

export const markCellQualityCheckComplete = createAsyncThunk(
  `${METADATA}/completeQualityCheck`,
  async ({ cellId, executor }: { cellId: number; executor: User | null }) => {
    const qualityCheckResponse: QualityCheckResponse = await client.post(
      `meta/cells/${cellId}/complete-quality-check`,
      {
        user_id: executor?.user_id,
      }
    );

    return Promise.resolve({ qualityCheckResponse });
  }
);

export const undoMarkCellQualityCheckComplete = createAsyncThunk(
  `${METADATA}/undoQualityCheck`,
  async ({ cellId }: { cellId: number }) => {
    const undoQualityCheckResponse: QualityCheckResponse = await client.post(
      `meta/cells/${cellId}/undo-quality-check`,
      {}
    );

    return Promise.resolve({ undoQualityCheckResponse });
  }
);

export const addOrEditReferenceCalibration = createAsyncThunk(
  `${METADATA}/addOrEditRefCal`,
  async ({
    test_meta_id,
    calibration_id,
    measurement,
    reference_voltage_assumed,
    measured_at,
    executor_id,
    reference_electrode_type,
    conditionIdsToRequestedCellIds,
    mmo_identifier,
  }: {
    test_meta_id?: number;
    calibration_id?: number;
    measurement: number;
    reference_voltage_assumed: boolean;
    measured_at: number;
    executor_id: number | null;
    reference_electrode_type: string;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
    mmo_identifier?: string;
  }) => {
    const body = {
      test_meta_id,
      measurement,
      reference_voltage_assumed,
      measured_at,
      reference_electrode_type,
      preferred_executor: executor_id,
      mmo_identifier,
    };

    if (calibration_id) {
      await client.put(`meta/reference-calibration/${calibration_id}`, body);
    } else {
      await client.post(`meta/reference-calibration`, body);
    }

    const { meta, pendingChanges, errors } = await getMetadataForConditionIds({
      conditionIdsToRequestedCellIds,
    });

    return Promise.resolve({ meta, pendingChanges, errors });
  }
);

export const addInitialReferenceCalibration = createAsyncThunk(
  `${METADATA}/addInitialRefCal`,
  async ({
    cell_id,
    measurement,
    reference_voltage_assumed,
    measured_at,
    executor_id,
    reference_electrode_type,
    conditionIdsToRequestedCellIds,
    mmo_identifier,
  }: {
    cell_id: number;
    measurement: number;
    reference_voltage_assumed: boolean;
    measured_at: number;
    executor_id: number | null;
    reference_electrode_type: string;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
    mmo_identifier?: string;
  }) => {
    const body = {
      measurement,
      reference_voltage_assumed,
      measured_at,
      reference_electrode_type,
      preferred_executor: executor_id,
      mmo_identifier,
    };

    await client.put(`meta/cells/${cell_id}/ref-cal-initial`, body);

    const { meta, pendingChanges, errors } = await getMetadataForConditionIds({
      conditionIdsToRequestedCellIds,
    });

    return Promise.resolve({ meta, pendingChanges, errors });
  }
);

export const deleteReferenceCalibration = createAsyncThunk(
  `${METADATA}/deleteRefCal`,
  async ({
    reference_calibration_id,
    conditionIdsToRequestedCellIds,
  }: {
    reference_calibration_id: number;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
  }) => {
    await client.delete(
      `meta/reference-calibration/${reference_calibration_id}`,
      {}
    );

    const { meta, pendingChanges, errors } = await getMetadataForConditionIds({
      conditionIdsToRequestedCellIds,
    });

    return Promise.resolve({ meta, pendingChanges, errors });
  }
);

export const updateTestConditionTemplates = createAsyncThunk(
  `${METADATA}/updateTestConditionTemplates`,
  async ({
    conditionId,
    conditionIdsToRequestedCellIds,
    hiddenConditionIds = [],
  }: {
    conditionId: number;
    conditionIdsToRequestedCellIds: { [key: string]: string[] };
    hiddenConditionIds?: number[];
  }) => {
    await client.post(
      `meta/cell-conditions/${conditionId}/refresh-templates`,
      {}
    );
    const { meta, pendingChanges, errors } = await getMetadataForConditionIds({
      conditionIdsToRequestedCellIds,
      hiddenConditionIds,
    });

    return Promise.resolve({ meta, pendingChanges, errors });
  }
);

const slice = createSlice({
  name: METADATA,
  initialState,
  reducers: {
    resetMetadataState: (state) => Object.assign(state, initialState),
    resetGetMetadata: (state) => {
      state.status.get = "idle";
      state.error.get = null;
    },
    resetSaveMetadata: (state) => {
      state.status.save = "idle";
      state.error.save = null;
    },
    resetCompleteMetadata: (state) => {
      state.status.complete = "idle";
      state.error.complete = null;
    },
    resetGenerateTest: (state) => {
      state.status.onTest = "idle";
      state.fileNamesRecentlyGenerated = [];
      state.error.onTest = null;
    },
    resetAddOrEditReferenceCalibration: (state) => {
      state.status.addEditRefCal = "idle";
      state.error.addEditRefCal = null;
    },
    resetAddInitialReferenceCalibration: (state) => {
      state.status.initialRefCal = "idle";
      state.error.initialRefCal = null;
    },
    resetDeleteReferenceCalibration: (state) => {
      state.status.delRefCal = "idle";
      state.error.delRefCal = null;
    },
    resetUpdateTestConditionTemplates: (state) => {
      state.status.updateTestConditionTemplates = "idle";
      state.error.updateTestConditionTemplates = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetadata.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getMetadata.fulfilled, (state, { payload }) => {
        const stateUiCopy = state.ui || [];
        state.ui = payload.meta.reduce((stateUiCopy, metaItem) => {
          if ("basic_info" in metaItem && metaItem.basic_info) {
            const existingFullDataObj = stateUiCopy.find(
              (dataObj) =>
                dataObj.cell_condition_id === metaItem.cell_condition_id &&
                (!("basic_info" in dataObj) || !dataObj.basic_info)
            );
            if (existingFullDataObj) {
              // Prefer existing full data item over incoming basic_info item.
              return stateUiCopy;
            }
          }
          return [
            ...stateUiCopy.filter(
              (existingObj) =>
                existingObj.cell_condition_id !== metaItem.cell_condition_id
            ),
            metaItem,
          ];
        }, stateUiCopy);
        state.pendingChanges = payload.pendingChanges;
        state.error.get = payload.errors.join();
        state.status.get = "succeeded";
      })
      .addCase(getMetadata.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      .addCase(saveMetadata.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(saveMetadata.fulfilled, (state, { payload }) => {
        state.ui = payload.meta;
        state.pendingChanges = payload.pendingChanges;
        state.error.save = payload.errors.join();
        state.status.save = "succeeded";
      })
      .addCase(saveMetadata.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      })
      .addCase(completeMetadata.pending, (state) => {
        state.status.complete = "loading";
      })
      .addCase(completeMetadata.fulfilled, (state, { payload }) => {
        state.ui = payload.meta;
        state.pendingChanges = payload.pendingChanges;
        state.error.complete = payload.errors.join();
        state.status.complete = "succeeded";
      })
      .addCase(completeMetadata.rejected, (state, { error }) => {
        state.status.complete = "failed";
        state.error.complete = error.message as string;
      })
      .addCase(generateTest.pending, (state) => {
        state.status.onTest = "loading";
      })
      .addCase(generateTest.fulfilled, (state, { payload }) => {
        if (!("module_id" in payload)) {
          state.ui = payload.meta;
          state.pendingChanges = payload.pendingChanges;
          state.error.get = payload.errors.join();
        }
        state.fileNamesRecentlyGenerated = payload.fileNames;
        state.status.onTest = "succeeded";
      })
      .addCase(generateTest.rejected, (state, { error }) => {
        state.status.onTest = "failed";
        state.error.onTest = error.message as string;
      })
      .addCase(markCellQualityCheckComplete.pending, (state) => {
        state.status.qualityCheckUpdate = "loading";
      })
      .addCase(
        markCellQualityCheckComplete.fulfilled,
        (state, { payload: { qualityCheckResponse } }) => {
          let uiStateCopy = state.ui;
          if (uiStateCopy && uiStateCopy[0]) {
            uiStateCopy = setQCResponseInState(
              uiStateCopy,
              qualityCheckResponse
            );
            state.ui = uiStateCopy;
          }
          state.status.qualityCheckUpdate = "succeeded";
        }
      )
      .addCase(markCellQualityCheckComplete.rejected, (state, { error }) => {
        state.status.qualityCheckUpdate = "failed";
        state.error.qualityCheckUpdate = error.message as string;
      })
      .addCase(undoMarkCellQualityCheckComplete.pending, (state) => {
        state.status.qualityCheckUpdate = "loading";
      })
      .addCase(
        undoMarkCellQualityCheckComplete.fulfilled,
        (state, { payload: { undoQualityCheckResponse } }) => {
          let uiStateCopy = state.ui;
          if (uiStateCopy && uiStateCopy[0]) {
            uiStateCopy = setQCResponseInState(
              uiStateCopy,
              undoQualityCheckResponse
            );
            state.ui = uiStateCopy;
          }
          state.status.qualityCheckUpdate = "succeeded";
        }
      )
      .addCase(
        undoMarkCellQualityCheckComplete.rejected,
        (state, { error }) => {
          state.status.qualityCheckUpdate = "failed";
          state.error.qualityCheckUpdate = error.message as string;
        }
      )
      .addCase(addOrEditReferenceCalibration.pending, (state) => {
        state.status.addEditRefCal = "loading";
      })
      .addCase(
        addOrEditReferenceCalibration.fulfilled,
        (state, { payload }) => {
          state.ui = payload.meta;
          state.pendingChanges = payload.pendingChanges;
          state.error.get = payload.errors.join();
          state.status.addEditRefCal = "succeeded";
        }
      )
      .addCase(addOrEditReferenceCalibration.rejected, (state, { error }) => {
        state.status.addEditRefCal = "failed";
        state.error.addEditRefCal = error.message as string;
      })
      .addCase(addInitialReferenceCalibration.pending, (state) => {
        state.status.initialRefCal = "loading";
      })
      .addCase(
        addInitialReferenceCalibration.fulfilled,
        (state, { payload }) => {
          state.ui = payload.meta;
          state.pendingChanges = payload.pendingChanges;
          state.error.get = payload.errors.join();
          state.status.initialRefCal = "succeeded";
        }
      )
      .addCase(addInitialReferenceCalibration.rejected, (state, { error }) => {
        state.status.initialRefCal = "failed";
        state.error.initialRefCal = error.message as string;
      })
      .addCase(deleteReferenceCalibration.pending, (state) => {
        state.status.delRefCal = "loading";
      })
      .addCase(deleteReferenceCalibration.fulfilled, (state, { payload }) => {
        state.ui = payload.meta;
        state.pendingChanges = payload.pendingChanges;
        state.error.get = payload.errors.join();
        state.status.delRefCal = "succeeded";
      })
      .addCase(deleteReferenceCalibration.rejected, (state, { error }) => {
        state.status.delRefCal = "failed";
        state.error.delRefCal = error.message as string;
      })
      .addCase(updateTestConditionTemplates.pending, (state) => {
        state.status.updateTestConditionTemplates = "loading";
      })
      .addCase(updateTestConditionTemplates.fulfilled, (state, { payload }) => {
        state.ui = payload.meta;
        state.pendingChanges = payload.pendingChanges;
        state.status.updateTestConditionTemplates = "succeeded";
      })
      .addCase(updateTestConditionTemplates.rejected, (state, { error }) => {
        state.status.updateTestConditionTemplates = "failed";
        state.error.updateTestConditionTemplates = error.message as string;
      });
  },
});

export const {
  resetMetadataState,
  resetGetMetadata,
  resetCompleteMetadata,
  resetSaveMetadata,
  resetGenerateTest,
  resetAddOrEditReferenceCalibration,
  resetAddInitialReferenceCalibration,
  resetDeleteReferenceCalibration,
  resetUpdateTestConditionTemplates,
} = slice.actions;

export default slice.reducer;
