import React from "react";
import MuiButton from "@mui/material/Button";
import MuiButtonGroup from "@mui/material/ButtonGroup";
import styled from "@mui/styles/styled";
import colors from "../../theme/colors";

const ButtonGroup = styled(MuiButtonGroup)({
  "& .MuiButton-root": {
    fontSize: "0.75rem",
    border: `1px solid ${colors.rules}`,
    color: colors.text.primary,
    fontWeight: 600,
    background: "#FFFFFF",
    "&.active": {
      background: colors.rules,
    },
  },
});

type Props = {
  buttonOptions?: string[];
  value?: "0" | "1" | number;
  onChange: (value?: "0" | "1") => void;
};

const ChannelAttributeFilterButton = ({
  buttonOptions,
  value,
  onChange,
}: Props) => (
  <ButtonGroup size="small" variant="outlined">
    <MuiButton
      disableRipple
      className={value === "1" ? "active" : ""}
      color="inherit"
      onClick={() => onChange("1")}
    >
      {buttonOptions ? buttonOptions[0] : "Yes"}
    </MuiButton>
    <MuiButton
      disableRipple
      className={!value ? "active" : ""}
      color="inherit"
      onClick={() => onChange(undefined)}
    >
      {buttonOptions ? buttonOptions[1] : "Any"}
    </MuiButton>
    <MuiButton
      disableRipple
      className={value === "0" ? "active" : ""}
      color="inherit"
      onClick={() => onChange("0")}
    >
      {buttonOptions ? buttonOptions[2] : "No"}
    </MuiButton>
  </ButtonGroup>
);

export default ChannelAttributeFilterButton;
