export const getDirtyValues = (dirtyFields: any, allValues: any): any => {
  // `dirtyFields` is `true` for leaves.
  if (dirtyFields === true) {
    return allValues;
  }

  const dirtyValues = Object.fromEntries(
    Object.keys(dirtyFields).map((key: string) => [
      key,
      getDirtyValues(dirtyFields[key], allValues[key]),
    ])
  );

  // These are used to build out the batch calls.
  if (allValues.cell_condition_id) {
    dirtyValues.cell_condition_id = allValues.cell_condition_id;
  }

  // These are used to build out the batch calls.
  if (allValues.api_path) {
    dirtyValues.api_path = allValues.api_path;
  }

  return dirtyValues;
};
