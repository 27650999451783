import React from "react";
import Chip from "@mui/material/Chip";
import styled from "@mui/styles/styled";
import { UTILITY_TO_BG_COLOR } from "../../components/table/ButtonViews";

const StyledChip = styled(Chip)({
  borderRadius: "50rem",
  "& .MuiChip-label": {
    paddingLeft: 12,
    paddingRight: 12,
  },
});

type Props = {
  label: number;
};

const UtilityChip = ({ label }: Props) => {
  return (
    <StyledChip
      size="small"
      style={
        label in UTILITY_TO_BG_COLOR
          ? { backgroundColor: UTILITY_TO_BG_COLOR[label as 1 | 2 | 3 | 4 | 5] }
          : undefined
      }
      label={label}
    />
  );
};

export default UtilityChip;
