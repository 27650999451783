import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";
import { isObject } from "lodash";

export const TEARDOWN_SPECIFICATION_INFO = "TEARDOWN_SPECIFICATION_INFO";

export interface TeardownSpecificationsState {
  teardownSpecifications: TeardownSpecificationSummary[] | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    updateTeardownTypes: "idle" | "loading" | "succeeded" | "failed";
    updateTeardownFlags: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    updateTeardownTypes: null | string;
    updateTeardownFlags: null | string;
  };
}

const initialState: TeardownSpecificationsState = {
  teardownSpecifications: null,
  status: {
    get: "idle",
    updateTeardownTypes: "idle",
    updateTeardownFlags: "idle",
  },
  error: {
    get: null,
    updateTeardownTypes: null,
    updateTeardownFlags: null,
  },
};

export const getTeardownInfo = createAsyncThunk(
  `${TEARDOWN_SPECIFICATION_INFO}/get`,
  async (cellIds: (string | number)[]) => {
    const response = await client.get(
      `meta/cells/teardown-info?${cellIds
        .map((cellId_) => `cell_ids=${cellId_}`)
        .join("&")}`
    );
    return response.data;
  }
);

export const updateTeardownTypes = createAsyncThunk(
  `${TEARDOWN_SPECIFICATION_INFO}/updateTeardownTypes`,
  async ({
    teardownTypesToUpdate,
    offTestReasonsToUpdate,
  }: {
    offTestReasonsToUpdate: { [key: string]: string | null };
    teardownTypesToUpdate: { [key: string]: string };
  }) => {
    const response = await client.put("meta/cells/teardown-info", {
      teardown_types_per_cell: teardownTypesToUpdate,
      off_test_reason_per_cell: offTestReasonsToUpdate,
    });
    return response.data;
  }
);

export const updateTeardownFlags = createAsyncThunk(
  `${TEARDOWN_SPECIFICATION_INFO}/updateTeardownFlags`,
  async (teardownFlagUpdatesByCellConditionId: {
    [key: string]: { [key: string]: string | boolean | User | number | null };
  }) => {
    const updateFlagsPayload = Object.assign(
      teardownFlagUpdatesByCellConditionId,
      {
        ...teardownFlagUpdatesByCellConditionId,
      }
    );
    Object.keys(updateFlagsPayload).forEach((conditionId_) => {
      Object.keys(updateFlagsPayload[conditionId_]).forEach((flagName_) => {
        const val_ = updateFlagsPayload[conditionId_][flagName_];
        if (isObject(val_) && val_.hasOwnProperty("user_id")) {
          updateFlagsPayload[conditionId_][flagName_] = val_.user_id;
        }
      });
    });
    return await client.put("meta/cell-conditions/teardown-flags", {
      teardown_updates: updateFlagsPayload,
    });
  }
);

const teardownSlice = createSlice({
  name: TEARDOWN_SPECIFICATION_INFO,
  initialState,
  reducers: {
    resetTeardownModal: (state) => {
      state.teardownSpecifications = null;
      state.status.get = "idle";
      state.error.get = null;
      state.status.updateTeardownTypes = "idle";
      state.error.updateTeardownTypes = null;
    },
    resetGetTeardownSpecs: (state) => {
      state.status.get = "idle";
      state.error.get = null;
    },
    resetUpdateTeardownTypes: (state) => {
      state.status.updateTeardownTypes = "idle";
      state.error.updateTeardownTypes = null;
    },
    resetUpdateTeardownFlags: (state) => {
      state.status.updateTeardownFlags = "idle";
      state.error.updateTeardownFlags = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch teardown specifications
      .addCase(getTeardownInfo.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getTeardownInfo.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";

        state.teardownSpecifications = payload;
      })
      .addCase(getTeardownInfo.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Update teardown types
      .addCase(updateTeardownTypes.pending, (state) => {
        state.status.updateTeardownTypes = "loading";
      })
      .addCase(updateTeardownTypes.fulfilled, (state) => {
        state.status.updateTeardownTypes = "succeeded";
      })
      .addCase(updateTeardownTypes.rejected, (state, { error }) => {
        state.status.updateTeardownTypes = "failed";
        state.error.updateTeardownTypes = error.message as string;
      })
      // Update teardown flags
      .addCase(updateTeardownFlags.pending, (state) => {
        state.status.updateTeardownFlags = "loading";
      })
      .addCase(updateTeardownFlags.fulfilled, (state) => {
        state.status.updateTeardownFlags = "succeeded";
      })
      .addCase(updateTeardownFlags.rejected, (state, { error }) => {
        state.status.updateTeardownFlags = "failed";
        state.error.updateTeardownFlags = error.message as string;
      });
  },
});

export const {
  resetTeardownModal,
  resetUpdateTeardownTypes,
  resetGetTeardownSpecs,
  resetUpdateTeardownFlags,
} = teardownSlice.actions;

export default teardownSlice.reducer;
