import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  GATEWAY_TIMEOUT_ERROR,
  GATEWAY_TIMEOUT_RESPONSE,
} from "../../api";

export const EVENTS = "EVENTS";

export interface EventsState {
  eventLink: string;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    create: "idle" | "loading" | "succeeded" | "pending" | "failed";
    update: "idle" | "loading" | "succeeded" | "failed";
  };
  warning: {
    get: null | string;
    create: null | string;
    update: null | string;
  };
  error: {
    get: null | string;
    create: null | string;
    update: null | string;
  };
}

export interface DynamicEventField {
  title: string;
  itemType:
    | "cell"
    | "experiment"
    | "channel"
    | "incubator"
    | "tester"
    | "test_stand";
  prefix: string;
  items: DynamicItem[];
}

const initialState: EventsState = {
  eventLink: "",
  status: {
    get: "idle",
    create: "idle",
    update: "idle",
  },
  warning: {
    get: null,
    create: null,
    update: null,
  },
  error: {
    get: null,
    create: null,
    update: null,
  },
};

export interface ExperimentOwnerMap {
  [key: number]: string;
}

export const createEvent = createAsyncThunk(
  `${EVENTS}/list`,
  async (data: any) => {
    const response = await client.post(`meta/events`, data);
    return { ...response };
  }
);

const eventsSlice = createSlice({
  name: EVENTS,
  initialState,
  reducers: {
    resetCreateEventStatus(state) {
      state.status.create = "idle";
      state.error.create = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.status.create = "succeeded";
        state.eventLink = payload.link;
      })
      .addCase(createEvent.rejected, (state, { error }) => {
        if (error.message === GATEWAY_TIMEOUT_ERROR) {
          state.status.create = "pending";
          state.warning.create = GATEWAY_TIMEOUT_RESPONSE;
        } else {
          state.status.create = "failed";
          state.error.create = error.message as string;
        }
      });
  },
});

export const { resetCreateEventStatus } = eventsSlice.actions;

export default eventsSlice.reducer;
