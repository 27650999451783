import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";
import styled from "@mui/styles/styled";
import colors from "../theme/colors";

import TimesIcon from "../icons/Times";

const StyledChip = styled(Chip)({
  backgroundColor: colors.rules,
  borderRadius: "1rem",
  height: 24,
  margin: 0,
  width: "100%",
  maxWidth: "100%",
});

const SmallChip = (props: ChipProps) => (
  <StyledChip
    deleteIcon={<TimesIcon style={{ width: 16, height: 16 }} />}
    {...props}
  />
);

export default SmallChip;
