import { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MinusCircleIcon from "../../icons/MinusCircle";
import SmallChip from "../SmallChip";
import client from "../../api";
import { useDebouncedCallback } from "use-debounce";

type UserFilterProps = {
  value: User[];
  removable?: boolean;
  style?: React.CSSProperties;
  onRemove?: () => void;
  onChange: (users: User[]) => void;
  disabled?: boolean;
  error?: boolean;
};

const UserFilter = ({
  value,
  removable,
  onRemove,
  onChange,
  disabled,
  style,
  error,
}: UserFilterProps) => {
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<User[]>([]);
  const [search, setSearch] = useState<string | null>(null);

  const _handleLookup = useCallback(async () => {
    if (search === null) {
      return;
    }

    setLoading(true);

    try {
      const response = await client.get(`auth/users?name__contains=${search}`);
      setOptions(response.data);
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
  }, [search]);

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleLookup();
  }, [search, handleLookup]);

  return (
    <Box display="flex" style={{ width: 450, ...style }}>
      <FormControl fullWidth>
        <Autocomplete
          disabled={disabled}
          multiple
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.user_id === value.user_id
          }
          value={value}
          onOpen={() => setSearch("")}
          onClose={() => setSearch(null)}
          onChange={(e, data) => onChange(data)}
          loading={loading}
          noOptionsText={
            search !== null && search.length > 0
              ? "No results found."
              : "Start typing..."
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                value: search,
                onChange: (e) => setSearch(e.target.value),
                error,
              }}
            />
          )}
          renderOption={(props, opt) => (
            <li {...props}>
              <Checkbox
                color="secondary"
                checked={props["aria-selected"] === true}
              />
              <Box py={2} width="100%">
                <Typography
                  color="textPrimary"
                  className={opt.email ? "small" : undefined}
                >
                  {opt.name}
                </Typography>
                {opt.email ? (
                  <Typography color="textSecondary" className="tiny">
                    {opt.email}
                  </Typography>
                ) : null}
              </Box>
            </li>
          )}
          renderTags={(values, getTagProps) =>
            values.map((value, index) => (
              <Box key={value?.user_id} mr={2} mb={1}>
                <SmallChip label={value?.name} {...getTagProps({ index })} />
              </Box>
            ))
          }
        />
      </FormControl>
      {removable ? (
        <IconButton size="small" onClick={onRemove}>
          <MinusCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default UserFilter;
