import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { flatten, isEmpty, uniq, uniqBy } from "lodash";
import client from "../../api";
import assembleCellsQuery from "../../utils/assembleCellsQuery";
import { CellFilterArgs } from "../cells/slice";

export const CELL_ID_LOOKUP_ID = "cell_id";
export const CHARACTERIZATION = "CHARACTERIZATION";
export const DATE_SAMPLE_HARVESTED_LOOKUP_ID = "date_sample_harvested";
export const ELYTE_LOOKUP_ID = "elyte";
export const ELYTE_STATE_LOOKUP_ID = "elyte_state";
export const EXP_ID_LOOKUP_ID = "exp_id";
export const HOT_POCKET_LOOKUP_ID = "hot_pocket";
export const ICM_PCM_LOOKUP_ID = "icm_pcm";
export const LOCATION_LOOKUP_ID = "location";
export const LAB_LOCATION_LOOKUP_ID = "lab_location";
export const ANALYSIS_TYPE_LOOKUP_ID = "analysis_type";
export const ASANA_TASK_TEMPLATE_ID_LOOKUP_ID = "asana_task_template_id"
export const QUANTITY_UNIT_LOOKUP_ID = "quantity_unit";
export const SAMPLE_OWNER_LOOKUP_ID = "sample_owner_id";
export const SAMPLE_TYPE_LOOKUP_ID = "sample_type";
export const STATUS_FIELD_ID = "status";
export const TECHNIQUE_REQUESTED_LOOKUP_ID = "technique_requested";
export const CREATE_ASANA_TASK_LOOKUP_ID = "create_asana_task";
const STATE_KEY_FOR_LOOKUP_ID = {
  [SAMPLE_OWNER_LOOKUP_ID]: "sample_owners",
};
export const ALTERNATE_MATERIAL_KEYS_FOR_SAMPLE_TYPE = {
  anode: "anode_pcm",
  elyte: "elyte_pcm",
};

type Args = Omit<
  APIListArgs<CharacterizationSample, SampleFilters>,
  "orderBy"
> & {
  orderBy: keyof SampleFilters;
};

export interface CharacterizationState {
  createdSampleIdsToCellIds: {
    [sampleId: number]: number | null;
  };
  updatedSampleIds: string[];
  emptySample: CharacterizationSample;
  emptySpecimen: Specimen;
  samples: CharacterizationSample[];
  hasMore: boolean;
  retrievedMaterialsForSampleIndex:
    | {
        material_id: string;
        description: string;
        sampleIndex: number;
        lookupId: string;
      }[]
    | null;
  selected: string[];
  args: Args;
  grouping: {
    expandedGroups: (string | number)[];
  };
  status: {
    listSamples: "idle" | "loading" | "succeeded" | "failed";
    saveSamples: "idle" | "loading" | "succeeded" | "failed";
    saveSpecimens: "idle" | "loading" | "succeeded" | "failed";
    getSampleData: "idle" | "loading" | "succeeded" | "failed";
    getMaterials: "idle" | "loading" | "succeeded" | "failed";
    createCharacterizationRequest: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    listSamples: null | string;
    saveSamples: null | string;
    saveSpecimens: null | string;
    getSampleData: null | string;
    getMaterials: null | string;
    getSampleFormFields: null | string;
    createCharacterizationRequest: null | string;
  };
}

export const emptySample = {
  sample_id: null,
  created_time: null,
  retrievedMaterialsForSampleIndex: null,
  sample__cell__condition__name: null,
  sample_owners: [],
  specimens: [],
  fields: [],
};

export const emptySpecimen = {
  specimen_id: null,
  tasks: [],
  fields: [],
};

const initialState: CharacterizationState = {
  createdSampleIdsToCellIds: {},
  samples: [],
  updatedSampleIds: [],
  emptySample,
  emptySpecimen,
  hasMore: false,
  selected: [],
  grouping: {
    expandedGroups: [],
  },
  args: {
    orderBy: "sample_id",
    order: "desc",
    page: 0,
    filters: {},
  },
  retrievedMaterialsForSampleIndex: null,
  status: {
    listSamples: "idle",
    saveSamples: "idle",
    saveSpecimens: "idle",
    getMaterials: "idle",
    getSampleData: "idle",
    createCharacterizationRequest: "idle",
  },
  error: {
    listSamples: null,
    saveSamples: null,
    saveSpecimens: null,
    getMaterials: null,
    getSampleData: null,
    getSampleFormFields: null,
    createCharacterizationRequest: null,
  },
};

export const getSampleFormFields = createAsyncThunk(
  `${CHARACTERIZATION}/fetchSampleFormFields`,
  async () => {
    const response: { data: CharacterizationFormField[] } = await client.get(
      "meta/sample-form"
    );
    return response.data;
  }
);

export const getSpecimenFormFields = createAsyncThunk(
  `${CHARACTERIZATION}/fetchSpecimenFormFields`,
  async () => {
    const response: { data: CharacterizationFormField[] } = await client.get(
      "meta/specimen-form"
    );
    return response.data;
  }
);

export const getSampleData = createAsyncThunk(
  `${CHARACTERIZATION}/fetchSampleDataForIds`,
  async ({
    partialUpdate = false,
    sampleIds,
  }: {
    partialUpdate?: boolean;
    sampleIds: string;
  }) => {
    const results: { data: CharacterizationSample[] } = await client.get(
      `meta/samples?sample_id=${sampleIds}`
    );
    const sampleIdToUpdate = partialUpdate && sampleIds;

    // migrate selections from multi-selects (sent from API as fields) to their state fields
    const resultsData = results.data.map((sample) => {
      const fieldsWithNullifiedMultiSelects = sample.fields.map(
        (sampleField) => ({
          ...sampleField,
          value: Object.keys(STATE_KEY_FOR_LOOKUP_ID).includes(
            sampleField.field_lookup_id
          )
            ? null
            : sampleField.value,
        })
      );
      const multiSelectionsToStateKey = sample.fields.reduce(
        (valsByStateKey: { sample_owners?: User[] }, field) => {
          // keeping this around in case we introduce any more
          // fields that can have multiple selections.
          if (
            Object.keys(STATE_KEY_FOR_LOOKUP_ID).includes(field.field_lookup_id)
          ) {
            const lookupId = field.field_lookup_id as "sample_owner_id";
            const stateKey = STATE_KEY_FOR_LOOKUP_ID[
              lookupId
            ] as "sample_owners";
            const existingCollection = valsByStateKey[stateKey] || [];
            return {
              ...valsByStateKey,
              [stateKey]: [
                ...existingCollection,
                ...(field.value ? [field.value as User] : []),
              ],
            };
          }
          return valsByStateKey;
        },
        {}
      );

      return {
        ...sample,
        ...multiSelectionsToStateKey,
        // there will be one 'field' object per selection from multiselects, remove those dupes.
        fields: uniqBy(fieldsWithNullifiedMultiSelects, "field_lookup_id"),
      };
    });
    return Promise.resolve({
      response: resultsData,
      errors: [],
      sampleIdToUpdate,
    });
  }
);

export const getMaterials = createAsyncThunk(
  `${CHARACTERIZATION}/fetchIcmPcmForSample`,
  async ({
    sample,
    sampleIndex,
  }: {
    sample: CharacterizationSample;
    sampleIndex: number;
  }) => {
    const sampleType = sample.fields.find(
      ({ field_lookup_id }) => field_lookup_id === SAMPLE_TYPE_LOOKUP_ID
    )!.value as string;
    const icmPcmField = sample.fields.find(
      ({ field_lookup_id }) => field_lookup_id === ICM_PCM_LOOKUP_ID
    );
    const lookupId =
      icmPcmField &&
      icmPcmField.conditional_for_sample_types!.includes(sampleType as string)
        ? ICM_PCM_LOOKUP_ID
        : (sampleType as string) in ALTERNATE_MATERIAL_KEYS_FOR_SAMPLE_TYPE
        ? ALTERNATE_MATERIAL_KEYS_FOR_SAMPLE_TYPE[
            sampleType as "anode" | "elyte"
          ]
        : null;
    if (lookupId) {
      const cellId = sample.fields.find(
        ({ field_lookup_id }) => field_lookup_id === CELL_ID_LOOKUP_ID
      )!.value as string;
      const cellArgs = {
        order: "desc",
        orderBy: "cell_id",
        page: 0,
        pageSize: 1,
        filters: {
          cell_id: [cellId],
        },
      };
      const response: { data: Cell[] } = await client.get(
        `meta/cells?${assembleCellsQuery(cellArgs as CellFilterArgs)}`
      );
      const subassemblyKey =
        sampleType === ELYTE_LOOKUP_ID ? "electrolyte" : sampleType;
      const retrievedMaterialObjs = response.data.map((cellObj) => {
        const subassemblyObj = cellObj[
          subassemblyKey as keyof Cell
        ] as CellStepStatus;
        return {
          material_id: subassemblyObj?.icm_pcm,
          description: subassemblyObj?.icm_pcm_description,
          sampleIndex,
          lookupId,
        };
      });
      return Promise.resolve({
        data: retrievedMaterialObjs.filter(({ material_id }) => !!material_id),
      });
    }
    return Promise.resolve({ data: null });
  }
);

export const saveSamples = createAsyncThunk(
  `${CHARACTERIZATION}/saveSamples`,
  async (characterizationSamples: CharacterizationSamples) => {
    const updates = characterizationSamples.samples.map((sample) => {
      const sampleId = sample.sample_id;
      const method = sampleId ? "PUT" : "POST";
      const path = `/api/v1/meta/samples/${sampleId || ""}`;

      // transform multi-select fields into same shape as other SampleFields
      const sampleFormFields = sample.fields.filter(
        ({ field_lookup_id }) =>
          !Object.keys(STATE_KEY_FOR_LOOKUP_ID).includes(field_lookup_id)
      );
      const formFieldsForMultiSelects = Object.keys(
        STATE_KEY_FOR_LOOKUP_ID
      ).map((lookupId) => {
        return sample.fields.find(
          ({ field_lookup_id }) => field_lookup_id === lookupId
        );
      });
      const multiSelectsToFields = Object.values(STATE_KEY_FOR_LOOKUP_ID).map(
        (stateKey, index) => {
          const multiSelectCollection = sample[stateKey as "sample_owners"];
          if (isEmpty(multiSelectCollection)) {
            return {
              field_lookup_id:
                formFieldsForMultiSelects[index]!.field_lookup_id!,
              field_label: formFieldsForMultiSelects[index]!.field_label!,
              value: null,
            };
          }
          return multiSelectCollection.map(
            (formValue: string | User | null) => ({
              field_lookup_id:
                formFieldsForMultiSelects[index]!.field_lookup_id!,
              field_label: formFieldsForMultiSelects[index]!.field_label!,
              value: formValue,
            })
          );
        }
      );
      const sampleFields = [
        ...sampleFormFields,
        ...flatten(multiSelectsToFields),
      ];

      return {
        method,
        path,
        data: {
          fields: sampleFields.map((sampleField) => {
            const fieldValue = sampleField.value as any;
            return {
              ...sampleField,
              value:
                fieldValue?.material_id ||
                fieldValue?.user_id ||
                fieldValue?.id ||
                sampleField.value,
            };
          }),
        },
      };
    });

    const response: BatchResponse<{
      sample_id: number;
      cell_id: number | null;
      errors: string[];
    }>[] = await client.post("core/batch", updates);
    const errors = response
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    const updatedSampleCreationState = response.reduce(
      (existingObj: { [sampleId: number]: number | null }, responseObj) => {
        const { cell_id, sample_id } = responseObj.body.data;
        existingObj[sample_id] = cell_id;
        return existingObj;
      },
      {}
    );

    return Promise.resolve({ results: updatedSampleCreationState, errors });
  }
);

export const saveSpecimens = createAsyncThunk(
  `${CHARACTERIZATION}/saveSpecimen`,
  async ({
    sampleId,
    specimens,
  }: {
    sampleId: number;
    specimens: Specimen[];
  }) => {
    const updates = specimens.map((specimen) => {
      const specimenId = specimen.specimen_id;
      const method = specimenId ? "PUT" : "POST";
      const path = `/api/v1/meta/samples/${sampleId}/specimens/${
        specimenId || ""
      }`;
      return {
        method,
        path,
        data: specimen,
      };
    });

    const response: BatchResponse<{
      sample_id: string;
      errors: string[];
    }>[] = await client.post("core/batch", updates);
    const errors = response
      .filter(({ status }) => status >= 300)
      .map(({ body: { title } }) => title);

    const updatedSpecimensResponse = response.reduce(
      (existingObj: string[], responseObj) => {
        existingObj = [...existingObj, responseObj.body.data.sample_id];
        return existingObj;
      },
      []
    );
    return Promise.resolve({ results: updatedSpecimensResponse, errors });
  }
);

export const listSamples = createAsyncThunk(
  `${CHARACTERIZATION}/list`,
  async ({ pageSize = 100, refresh, ...args }: Args) => {
    const pageArg = refresh
      ? `&__offset=0&__limit=${(args.page + 1) * pageSize}`
      : `&__offset=${args.page * pageSize}&__limit=${pageSize}`;
    const sortArg = `&__sort=${args.order === "desc" ? "-" : ""}${
      args.orderBy
    }`;

    let filterArg = "";

    for (let key of Object.keys(args.filters)) {
      const selected =
        (args.filters[key as keyof SampleFilters] as string[] | User[]) || [];

      if (key === "sample_field__sample_owner") {
        filterArg += (selected as User[])
          .map((sel) => `&${key}=${sel.user_id}`)
          .join("");
        continue;
      }

      filterArg += (selected as string[])
        .map((sel) => `&${key}=${sel}`)
        .join("");
    }

    const response = await client.get(
      `meta/samples/advanced?${sortArg}${pageArg}${filterArg}`
    );

    return { response, args: { pageSize, ...args }, refresh };
  }
);

export const createCharacterizationRequest = createAsyncThunk(
  `${CHARACTERIZATION}/createRequest`,
  async ({
    sample_id,
    specimen_id,
  }: {
    sample_id: number;
    specimen_id: number;
  }) => {
    const results: { data: any } = await client.post(
      `meta/samples/${sample_id}/characterization`,
      { specimen_id: specimen_id }
    );
    return Promise.resolve({
      results: results.data,
      errors: [],
    });
  }
);

const slice = createSlice({
  name: CHARACTERIZATION,
  initialState,
  reducers: {
    resetGetMaterials: (state, action?: PayloadAction<number | undefined>) => {
      state.status.getMaterials = "idle";
      state.error.getMaterials = null;
      if (action?.payload && state.retrievedMaterialsForSampleIndex) {
        const updatedMaterialsCollection =
          state.retrievedMaterialsForSampleIndex.filter(
            (materialObj) => materialObj.sampleIndex !== action.payload
          );
        state.retrievedMaterialsForSampleIndex = isEmpty(
          updatedMaterialsCollection
        )
          ? null
          : updatedMaterialsCollection;
      } else {
        state.retrievedMaterialsForSampleIndex =
          initialState.retrievedMaterialsForSampleIndex;
      }
    },
    resetGetSampleData: (state) => {
      state.status.getSampleData = "idle";
    },
    resetSaveSamples: (state) => {
      state.status.saveSamples = "idle";
      state.createdSampleIdsToCellIds = {};
      state.error.saveSamples = null;
    },
    resetSaveSpecimens: (state) => {
      state.status.saveSpecimens = "idle";
      state.error.saveSpecimens = null;
      state.updatedSampleIds = [];
    },
    resetSamples: (state) => {
      state.samples = initialState.samples;
      state.status.listSamples = "idle";
      state.error.listSamples = null;
    },
    resetCharacterizationRequest: (state) => {
      state.status.createCharacterizationRequest = "idle";
      state.error.createCharacterizationRequest = null;
      state.updatedSampleIds = [];
    },
    selectSamples(state, action: PayloadAction<string[]>) {
      state.selected = uniq([...state.selected, ...action.payload]);
    },
    deselectSamples(state, action: PayloadAction<string[]>) {
      const selected = [...state.selected];
      action.payload.forEach((fullanme) => {
        const index = selected.indexOf(fullanme);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      });
      state.selected = uniq(selected);
    },
    selectAllVisibleSamples(state) {
      state.selected = state.samples.map((sample) => `${sample.sample_id!}`);
    },
    deselectAllVisibleSamples(state) {
      state.selected = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSamples.pending, (state) => {
        state.status.listSamples = "loading";
      })
      .addCase(listSamples.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status.listSamples = "failed";
          state.error.listSamples = "Unknown error";
          return;
        }

        state.hasMore = payload.response.meta.more;

        state.args = payload.args;
        state.samples = payload.response.data;
        state.status.listSamples = "succeeded";
      })
      .addCase(listSamples.rejected, (state, { error }) => {
        state.status.listSamples = "failed";
        state.error.listSamples = error.message as string;
      })
      .addCase(getMaterials.pending, (state) => {
        state.status.getMaterials = "loading";
      })
      .addCase(getMaterials.fulfilled, (state, { payload: { data } }) => {
        state.status.getMaterials = "succeeded";
        state.retrievedMaterialsForSampleIndex = data;
      })
      .addCase(getSampleFormFields.fulfilled, (state, { payload }) => {
        state.emptySample.fields = payload.map((sampleFormField) => ({
          field_lookup_id: sampleFormField.field_lookup_id,
          field_label: sampleFormField.field_label,
          required: sampleFormField.required,
          conditional_for_sample_types:
            sampleFormField.conditional_for_sample_types,
          value: null,
        }));
      })
      .addCase(getSampleFormFields.rejected, (state, { error }) => {
        state.error.getSampleFormFields = error.message as string;
      })
      .addCase(getSpecimenFormFields.fulfilled, (state, { payload }) => {
        state.emptySpecimen.fields = payload.map((specimenFormField) => ({
          field_id: specimenFormField.field_id,
          field_lookup_id: specimenFormField.field_lookup_id,
          field_label: specimenFormField.field_label,
          required: specimenFormField.required,
          conditional_for_techniques:
            specimenFormField.conditional_for_techniques,
          value: null,
        }));
      })
      .addCase(getSampleData.pending, (state) => {
        state.status.getSampleData = "loading";
      })
      .addCase(
        getSampleData.fulfilled,
        (state, { payload: { response, errors, sampleIdToUpdate } }) => {
          if (errors && errors.length > 0) {
            state.status.getSampleData = "failed";
            state.error.getSampleData = errors.join(" ");
          } else {
            state.status.getSampleData = "succeeded";
            if (sampleIdToUpdate) {
              state.samples = state.samples.map((_sample) => {
                if (parseInt(sampleIdToUpdate) === _sample.sample_id) {
                  _sample = response[0];
                }
                return _sample;
              });
            } else {
              state.samples = response;
            }
          }
        }
      )
      .addCase(getSampleData.rejected, (state, { error }) => {
        state.status.getSampleData = "failed";
        state.error.getSampleData = error.message as string;
      })
      .addCase(saveSamples.pending, (state) => {
        state.status.saveSamples = "loading";
      })
      .addCase(
        saveSamples.fulfilled,
        (state, { payload: { results, errors } }) => {
          if (errors && errors.length > 0) {
            state.status.saveSamples = "failed";
            state.error.saveSamples = errors.join(" ");
          } else {
            state.status.saveSamples = "succeeded";
            state.createdSampleIdsToCellIds = results;
          }
        }
      )
      .addCase(saveSamples.rejected, (state, { error }) => {
        state.status.saveSamples = "failed";
        state.error.saveSamples = error.message as string;
      })
      .addCase(saveSpecimens.pending, (state) => {
        state.status.saveSpecimens = "loading";
      })
      .addCase(
        saveSpecimens.fulfilled,
        (state, { payload: { results, errors } }) => {
          if (errors && errors.length > 0) {
            state.status.saveSpecimens = "failed";
            state.error.saveSpecimens = errors.join(" ");
          } else {
            state.status.saveSpecimens = "succeeded";
            state.updatedSampleIds = results;
          }
        }
      )
      .addCase(saveSpecimens.rejected, (state, { error }) => {
        state.status.saveSpecimens = "failed";
        state.error.saveSpecimens = error.message as string;
      })
      .addCase(createCharacterizationRequest.pending, (state) => {
        state.status.createCharacterizationRequest = "loading";
      })
      .addCase(
        createCharacterizationRequest.fulfilled,
        (state, { payload: { results, errors } }) => {
          if (errors && errors.length > 0) {
            state.status.createCharacterizationRequest = "failed";
            state.error.createCharacterizationRequest = errors.join(" ");
          } else {
            state.status.createCharacterizationRequest = "succeeded";
            state.updatedSampleIds = [results.sample_id];
          }
        }
      )
      .addCase(createCharacterizationRequest.rejected, (state, { error }) => {
        state.status.createCharacterizationRequest = "failed";
        state.error.createCharacterizationRequest = error.message as string;
      });
  },
});

export const {
  resetGetMaterials,
  resetSaveSamples,
  resetSaveSpecimens,
  resetSamples,
  resetGetSampleData,
  resetCharacterizationRequest,
  selectSamples,
  selectAllVisibleSamples,
  deselectSamples,
  deselectAllVisibleSamples,
} = slice.actions;

export default slice.reducer;
