import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import ReferenceCalibrationModal from "./ReferenceCalibrationModal";

type Props = {
  disabled: boolean;
  test_meta_id: number;
  executor: User | null;
  onSave: (callback?: () => void) => void;
};

const AddRefCalCell = ({ disabled, test_meta_id, executor, onSave }: Props) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  // Metadata state in redux
  const {
    status: { save: saveStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  /* Save modal */
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveModal = (
    <Modal open={saveModalOpen} onClose={() => setSaveModalOpen(false)}>
      <>
        <Typography variant="h2">Save inputs ?</Typography>
        <Box mt={8} mb={2}>
          <Typography color="textSecondary">
            You have unsaved inputs. You must save all inputs in order to
            continue.
          </Typography>
        </Box>
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button color="secondary" onClick={() => setSaveModalOpen(false)}>
              Cancel
            </Button>
          </Box>
          <Button
            color="primary"
            onClick={() =>
              onSave(() => {
                setSaveModalOpen(false);
                setMeasurementModalOpen(true);
              })
            }
            endIcon={
              saveStatus === "loading" ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
            disabled={saveStatus === "loading"}
          >
            Save
          </Button>
        </Box>
      </>
    </Modal>
  );

  /* Measurement modal */
  const [measurementModalOpen, setMeasurementModalOpen] = useState(false);
  const measurementModal = (
    <ReferenceCalibrationModal
      open={measurementModalOpen}
      onClose={() => setMeasurementModalOpen(false)}
      test_meta_id={test_meta_id}
      executor_id={executor?.user_id || null}
    />
  );

  return (
    <>
      <Box p={2}>
        <Tooltip
          arrow
          title="Previous steps must be completed first"
          disableFocusListener={!disabled}
          disableHoverListener={!disabled}
        >
          <span>
            <Button
              color="tertiary"
              type="button"
              size="small"
              disabled={disabled}
              style={{
                padding: "0.5rem",
              }}
              onClick={() =>
                isDirty ? setSaveModalOpen(true) : setMeasurementModalOpen(true)
              }
            >
              <b>Add Measurement</b>
            </Button>
          </span>
        </Tooltip>
      </Box>
      {saveModal}
      {measurementModal}
    </>
  );
};

export default AddRefCalCell;
