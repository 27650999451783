import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "../Chip";
import CogIcon from "../../icons/Cog";
import PlusCircleIcon from "../../icons/PlusCircle";
import PencilIcon from "../../icons/Pencil";
import StarIcon from "../../icons/Star";
import colors, { addAlpha } from "../../theme/colors";
import CustomViewModal from "../../features/custom-views/CustomViewModal";
import {
  CustomViewState,
  starCustomView,
  unstarCustomView,
  resetStarViewStatus,
} from "../../features/custom-views/slice";
import { RootState } from "../../store";
import Toast from "../Toast";

export const UTILITY_TO_BG_COLOR = {
  1: colors.rules,
  2: addAlpha(colors.accent.green, 0.2),
  3: addAlpha(colors.accent.yellow, 0.2),
  4: addAlpha(colors.accent.orange, 0.2),
  5: addAlpha(colors.accent.red, 0.2),
};

type Props<T> = {
  table_id: string;
  selected: CustomTableView<T> | null;
  items: CustomTableView<T>[];
  allColumns: DataLabel<T>[];
  defaultColumns: DataLabel<T>[];
  onSelect: (view: CustomTableView<T> | null) => void;
};

const useMenuItemStyles = makeStyles({
  root: {
    "& .pencilButton": {
      visibility: "hidden",
    },
    "& .starButton": {
      visibility: "hidden",
      color: colors.accent.gray,
    },
    "&:hover": {
      "& .pencilButton": {
        visibility: "visible",
      },
      "& .starButton": {
        visibility: "visible",
      },
    },
  },
  default: {
    "& .starButton": {
      visibility: "visible",
      color: colors.accent.cta,
    },
  },
});

const ButtonViews = <T extends Cell | Experiment | Channel>({
  table_id,
  selected,
  items,
  allColumns,
  defaultColumns,
  onSelect,
}: Props<T>) => {
  const [buttonEl, setButtonEl] = useState<
    null | HTMLDivElement | HTMLButtonElement
  >(null);
  const handleButtonClick = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (
    event: React.MouseEvent<HTMLLIElement | HTMLDivElement | HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setButtonEl(null);
  };

  const classes = useMenuItemStyles();

  const [editing, setEditing] = useState<number | undefined | null>(null);
  const [editingView, setEditingView] = useState<
    CustomTableView<T> | undefined
  >();

  const dispatch = useDispatch();

  const {
    status: { star: starStatus },
    error: { star: starError },
  } = useSelector<RootState, CustomViewState>(({ customViews }) => customViews);

  let errorToast;
  if (starStatus === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetStarViewStatus())}
      >
        {starError}
      </Toast>
    );
  }

  return (
    <>
      <Chip
        clickable
        onClick={handleButtonClick}
        icon={<CogIcon />}
        label={
          <Box ml={1}>
            {items.find(
              ({ custom_view_id }) =>
                selected?.custom_view_id === custom_view_id
            )?.name || "Default View"}
          </Box>
        }
      />

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          selected={!selected?.custom_view_id}
          className={classes.root}
          onClick={(e) => {
            onSelect(null);
            handleButtonClose(e);
          }}
        >
          <ListItemText>Default View</ListItemText>
        </MenuItem>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            selected={item.custom_view_id === selected?.custom_view_id}
            className={`${classes.root} ${
              item.is_default ? classes.default : ""
            }`}
            onClick={(e) => {
              onSelect(item);
              handleButtonClose(e);
            }}
            sx={{
              backgroundColor: item.utility
                ? UTILITY_TO_BG_COLOR[Number(item.utility) as 1 | 2 | 3 | 4 | 5]
                : null,
            }}
          >
            <ListItemText>{item.name}</ListItemText>
            <IconButton
              className="pencilButton"
              size="small"
              style={{
                background: "transparent",
                padding: 0,
                marginLeft: "1rem",
                marginTop: "auto",
                marginBottom: "auto",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setEditing(item.custom_view_id);
                setEditingView(item);
                handleButtonClose(e);
              }}
            >
              <PencilIcon
                style={{ width: 16, height: 16, paddingTop: "0.25rem" }}
              />
            </IconButton>
            <Tooltip
              arrow
              placement="bottom"
              title={
                item.is_default
                  ? "Unstar view as default"
                  : "Star view as default"
              }
            >
              <span>
                <IconButton
                  className="starButton"
                  size="small"
                  onClick={(e) => {
                    item.is_default
                      ? dispatch(unstarCustomView(item))
                      : dispatch(starCustomView(item));
                  }}
                  style={{
                    background: "transparent",
                    padding: 0,
                    marginLeft: "0.5rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <StarIcon style={{ width: 16, height: 16 }} />
                </IconButton>
              </span>
            </Tooltip>
          </MenuItem>
        ))}

        {items.length > 0 ? <Divider /> : null}

        <MenuItem
          onClick={(e) => {
            setEditing(undefined);
            setEditingView(undefined);
            handleButtonClose(e);
          }}
        >
          <ListItemIcon>
            <PlusCircleIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          <ListItemText>Add custom view</ListItemText>
        </MenuItem>
      </Menu>

      <CustomViewModal
        open={editing !== null}
        view={editingView}
        table_id={table_id}
        allColumns={allColumns}
        defaultColumns={defaultColumns}
        onClose={() => setEditing(null)}
      />

      {errorToast}
    </>
  );
};

export default ButtonViews;
