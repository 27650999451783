import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Refresh = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 20 20"
  >
    {children}
    <path
      d="M6.625 12.2517H1V17.8767"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1255 13.3512C16.9005 16.9311 13.4234 19.2415 9.64856 18.9838C5.8737 18.7262 2.74274 15.9648 2.0155 12.2517"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.375 7.75171H19V2.12671"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.87451 6.65219C3.09949 3.07234 6.5766 0.761919 10.3515 1.01955C14.1263 1.27718 17.2573 4.0386 17.9845 7.75169"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Refresh;
