import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import colors from "../../theme/colors";
import CustomViewDndCard from "./CustomViewDndCard";

type Column<T> = { id: "left" | "right"; items: DataLabel<T>[] };

type Props<T> = {
  allColumns: DataLabel<T>[];
  value: FalconKey<T>[];
  onChange: (columns: FalconKey<T>[]) => void;
  order?: "asc" | "desc";
  onOrderChange: (order: "asc" | "desc") => void;
  orderBy?: FalconKey<T>;
  error?: boolean;
};

const CustomViewDndGrid = <T extends Cell | Experiment | Channel>({
  allColumns: _allColumns,
  value,
  onChange,
  order,
  onOrderChange,
  orderBy,
  error,
}: Props<T>) => {
  const allColumns = useMemo(() => _allColumns, [_allColumns]);
  const [columns, setColumns] = useState<{ left: Column<T>; right: Column<T> }>(
    {
      left: {
        id: "left",
        items: allColumns.filter(
          ({ id: allId }) => !value.some((id) => id === allId)
        ),
      },
      right: {
        id: "right",
        items: value.map(
          (id) => allColumns.find(({ id: allId }) => id === allId)!
        ),
      },
    }
  );

  useEffect(() => {
    setColumns((state) => ({
      ...state,
      right: {
        id: "right",
        items: value.map(
          (id) => allColumns.find(({ id: allId }) => id === allId)!
        ),
      },
    }));
  }, [allColumns, value]);

  useEffect(() => {
    const selectedIds = columns.right.items.map(({ id }) => id);

    const compareOrderedArrays = (a: any[], b: any[]) =>
      b.reduce(
        (r, c, i) => {
          if (a[i] === c) {
            r.intersection.push(c);
          } else {
            r.difference.push(c);
          }
          return r;
        },
        { intersection: [], difference: [] }
      );

    const { difference } = compareOrderedArrays(selectedIds, value);

    if (selectedIds.length !== value.length || difference.length > 0) {
      onChange(selectedIds);
    }
  }, [columns.right.items, value, onChange]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return null;
    }

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    const start = columns[source.droppableId as "left" | "right"];
    const end = columns[destination.droppableId as "left" | "right"];

    const newStartList = start.items.filter(
      (item, index) => index !== source.index
    );

    const newStartCol: Column<T> = {
      id: start.id,
      items: newStartList,
    };

    if (start === end) {
      newStartList.splice(destination.index, 0, start.items[source.index]);
      setColumns({ ...columns, [newStartCol.id]: newStartCol });
      return;
    }

    const newEndList = [...end.items];
    newEndList.splice(destination.index, 0, start.items[source.index]);

    const newEndCol: Column<T> = {
      id: end.id,
      items: newEndList,
    };

    setColumns({
      ...columns,
      [newStartCol.id]: newStartCol,
      [newEndCol.id]: newEndCol,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid
        container
        columnSpacing={4}
        style={{
          border: `1px solid ${error ? colors.accent.red : "transparent"}`,
        }}
      >
        <Grid item xs={6} style={{ borderRight: `1px solid ${colors.rules}` }}>
          <Box
            pt={2}
            mb={4}
            style={{
              fontSize: "0.75rem",
              color: colors.text.secondary,
              fontWeight: 600,
            }}
          >
            AVAILABLE COLUMNS
          </Box>
          <ColColumn
            orderBy={orderBy}
            order={order}
            onOrderChange={onOrderChange}
            {...columns.left}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            pt={2}
            mb={4}
            style={{
              fontSize: "0.75rem",
              color: colors.text.secondary,
              fontWeight: 600,
            }}
          >
            SELECTED COLUMNS
          </Box>
          <ColColumn
            orderBy={orderBy}
            order={order}
            onOrderChange={onOrderChange}
            {...columns.right}
          />
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

type ColColumnProps<T> = {
  order?: "asc" | "desc";
  onOrderChange: (order: "asc" | "desc") => void;
  orderBy?: FalconKey<T>;
} & Column<T>;

const ColColumn = <T extends Cell | Experiment | Channel>({
  id: colId,
  items,
  order,
  onOrderChange,
  orderBy,
}: ColColumnProps<T>) => (
  <Droppable droppableId={colId}>
    {(provided) => (
      <div
        style={{ height: 480, maxHeight: 480, overflow: "auto" }}
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        {items.map(({ id, label }, index) => (
          <CustomViewDndCard
            key={id}
            id={id}
            index={index}
            label={label}
            order={order}
            onOrderChange={onOrderChange}
            orderBy={orderBy === id}
            className={colId}
          />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default CustomViewDndGrid;
