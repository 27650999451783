import { useEffect, useState } from "react";
import type { FieldArrayWithId } from "react-hook-form";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import CellHeader from "./CellHeader";
import AddRefCalCell from "./AddRefCalCell";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  step: number;
  executor?: User | null;
  onSave: (callback?: () => void) => void;
  eventMap: EventMap;
  onViewEvents: (cell_id: number) => void;
};

const ReferenceCalibrationSubSection = ({
  formFields,
  step,
  executor: preferredExecutor,
  onSave,
  eventMap,
  onViewEvents,
}: Props) => {
  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );
  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);

  return (
    <>
      <TableHead>
        <BaseTableRow>
          <TableCell>EXECUTOR</TableCell>
          <TableCell />
          {formFields.map((field, fieldIndex) => (
            <CellHeader
              key={fieldIndex}
              cell_id={field.cell_id}
              hasEvents={field.cell_id in eventMap}
              onViewEvents={onViewEvents}
            />
          ))}
        </BaseTableRow>
      </TableHead>
      <TableBody>
        <BaseTableRow>
          <UserCell
            clearable={false}
            style={{ width: 254, minWidth: 254 }}
            error={!executor}
            value={executor}
            onChange={setExecutor}
          />
          <ConditionCell smallWidth>
            <Box pl={4}>
              <Typography color="textSecondary" className="small">
                Reference Calibration
              </Typography>
            </Box>
          </ConditionCell>
          {formFields.map((field, fieldIndex) => (
            <ConditionCell key={fieldIndex}>
              <AddRefCalCell
                disabled={!field.items[step].test_meta_id}
                executor={executor}
                test_meta_id={field.items[step].test_meta_id}
                onSave={onSave}
              />
            </ConditionCell>
          ))}
        </BaseTableRow>
      </TableBody>
    </>
  );
};

export default ReferenceCalibrationSubSection;
