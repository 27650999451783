import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import colors from "../../theme/colors";

import {
  Checkbox,
  FormControl,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import type { RootState } from "../../store";
import type { SignalMapState } from "./slice";
import { isNull } from "lodash";

interface ModuleForm {
  ovp: number | null;
  ocp: number | null;
  opp: number | null;
  oneWireSlots: number[];
  rtdResistance: number | null;
}

const ModuleSignalMapPreDownloadForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (data: any) => void;
  loading: boolean;
}) => {
  const { signalMap } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  const ONE_WIRE_SLOT_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];
  const RTD_RESISTANCE_OPTIONS = [100, 1000];

  const { control, handleSubmit, setValue } = useForm<ModuleForm>({
    defaultValues: {
      ovp: signalMap?.over_voltage_protection,
      ocp: signalMap?.over_current_protection,
      opp: signalMap?.over_power_protection,
      oneWireSlots: [],
      rtdResistance: signalMap?.rtd_resistance,
    },
  });

  useEffect(() => {
    if (!isNull(signalMap)) {
      setValue("ovp", signalMap.over_voltage_protection);
      setValue("ocp", signalMap.over_current_protection);
      setValue("opp", signalMap.over_power_protection);
      setValue(
        "rtdResistance",
        isNull(signalMap.rtd_resistance) ? 100 : signalMap.rtd_resistance
      );
      let ows = signalMap.one_wire_slots;
      setValue(
        "oneWireSlots",
        !isNull(ows) ? ows.split(",").flatMap((slot) => parseInt(slot)) : []
      );
    }
  }, [signalMap, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2" mb={0}>
          Please enter the following for the bdps device.
        </Typography>
        <Typography className="small" mb={5}>
          These are only set once per test, so please ensure that the values are
          correct.
        </Typography>

        <Typography className="small" color="textSecondary">
          Over Voltage Protection (volts)
          <span style={{ color: colors.accent.red }}>*</span>
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="ovp"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Input
                className="small"
                disableUnderline
                inputRef={ref}
                error={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormControl>

        <Typography className="small" color="textSecondary" mt={5}>
          Over Current Protection (amps)
          <span style={{ color: colors.accent.red }}>*</span>
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="ocp"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Input
                className="small"
                disableUnderline
                inputRef={ref}
                error={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormControl>

        <Typography className="small" color="textSecondary" mt={5}>
          Over Power Protection (watts)
          <span style={{ color: colors.accent.red }}>*</span>
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="opp"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Input
                className="small"
                disableUnderline
                inputRef={ref}
                error={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormControl>

        <Typography className="small" color="textSecondary" mt={5} mb={0}>
          1-Wire Slots
        </Typography>
        <Typography color="textSecondary" mb={5} fontSize="12px">
          These slots will be configured to have 80 channels.
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="oneWireSlots"
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Select
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={onChange}
                multiple
                value={value}
                renderValue={(val) => val.join(",")}
              >
                {ONE_WIRE_SLOT_OPTIONS.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    <Checkbox
                      color="secondary"
                      checked={value.indexOf(opt) > -1}
                    />
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Typography className="small" color="textSecondary" mt={5} mb={0}>
          RTD Resistance
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="rtdResistance"
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Select
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={onChange}
                value={value}
                // renderValue={(val) => val.join(",")}
              >
                {RTD_RESISTANCE_OPTIONS.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={loading}
          style={{
            width: "fit-content",
            float: "left",
            marginTop: "20px",
          }}
        >
          Download
        </Button>
      </form>
    </>
  );
};

export default ModuleSignalMapPreDownloadForm;
