export const EMPTY_STATE_ROW_HEIGHT = 197;
export const TABLE_HEADER_HEIGHT = 72.75;
export const GROUPING_HEADER_ROW_HEIGHT = 60;
export const DATA_ROW_HEIGHT = 37.02;

export const vhToPx = (vh: number) =>
  Math.round(window.innerHeight * 0.01 * vh);

export const getVirtualTableHeight = (
  rowHeights: number[],
  maxHeight: number
) =>
  Math.min(
    maxHeight,
    rowHeights.length === 0
      ? TABLE_HEADER_HEIGHT
      : rowHeights.reduce((a, b) => a + b) + TABLE_HEADER_HEIGHT
  );
