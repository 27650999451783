import { isEmpty } from "lodash";
import uniq from "lodash/uniq";

export const shiftClickList = (
  oldSelectedItems: (string | number)[],
  newSelectedItems: (string | number)[],
  itemList: (
    Channel |
    CharacterizationSample |
    TestStand |
    Cell |
    Signal |
    FaultCondition
  )[],
  shiftClick: boolean
): any => {
  let itemsToSelect: (string | number)[] = newSelectedItems;
  if (shiftClick && !isEmpty(newSelectedItems)) {
    itemsToSelect = [];
    let selectedItemsAccountedFor = 0;
    const activelySelected = [...oldSelectedItems, ...uniq(newSelectedItems)];
    // .every() will stop iteration upon first false returned
    itemList.every((item) => {
      // assign identifier based on item type
      let key =
        "reserved_cell" in item
          ? item.channel.fullname
          : "sample_id" in item
          ? String(item.sample_id)
          : "test_stand_id" in item
          ? String(item.test_stand_id)
          : "signal_id" in item
          ? item.signal_id
          : "cell_id" in item
          ? item.cell_id
          : "";
      if (selectedItemsAccountedFor > 0 || activelySelected.includes(key)) {
        itemsToSelect.push(key);
        if (activelySelected.includes(key)) {
          selectedItemsAccountedFor += 1;
        }
      }
      return selectedItemsAccountedFor !== activelySelected.length;
    });
  }
  return itemsToSelect;
};
