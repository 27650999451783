import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const NOTES = "NOTES ";

export interface NotesState {
  notes: NoteItem[];
  justCreated: Note | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    create: "idle" | "loading" | "succeeded" | "failed";
    update: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    create: null | string;
    update: null | string;
  };
}

const initialState: NotesState = {
  notes: [],
  justCreated: null,
  status: {
    get: "idle",
    create: "idle",
    update: "idle",
  },
  error: {
    get: null,
    create: null,
    update: null,
  },
};

export const getNotes = createAsyncThunk(
  `${NOTES}/list`,
  async (note_items: NoteItemRelation[]) => {
    if (note_items.length === 0) {
      return [];
    }

    const args = note_items
      .map(
        ({ resource, record_id }) =>
          `&resource=${resource}&record_id=${record_id}`
      )
      .join("");

    const response = await client.get(
      `meta/note-items?__sort=-created_time${args}`
    );
    return response.data;
  }
);

export const createNote = createAsyncThunk(
  `${NOTES}/create`,
  async ({ note_followers, images, ...note }: Note) => {
    const response = await client.post(`meta/notes`, {
      ...note,
      alert: true,
      images: images.map(({ filename }) => filename),
      note_followers: note_followers.map(({ user_id }) => user_id),
    });
    return { ...response, note_followers };
  }
);

export const updateNote = createAsyncThunk(
  `${NOTES}/update`,
  async ({ note_followers, images, ...note }: Note) => {
    const response = await client.put(`meta/notes/${note.note_id}`, {
      ...note,
      images: images.map(({ filename }) => filename),
      note_followers: note_followers.map(({ user_id }) => user_id),
    });
    return response;
  }
);

const notesSlice = createSlice({
  name: NOTES,
  initialState,
  reducers: {
    resetGetNotesStatus(state) {
      state.status.get = "idle";
      state.error.get = null;
    },
    resetCreateNoteStatus(state) {
      state.justCreated = null;
      state.status.create = "idle";
      state.error.create = null;
    },
    resetUpdateNoteStatus(state) {
      state.status.update = "idle";
      state.error.update = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotes.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getNotes.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.notes = payload;
      })
      .addCase(getNotes.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      .addCase(createNote.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(createNote.fulfilled, (state, { payload }) => {
        state.status.create = "succeeded";
        state.justCreated = payload;
      })
      .addCase(createNote.rejected, (state, { error }) => {
        state.status.create = "failed";
        state.error.create = error.message as string;
      })
      .addCase(updateNote.pending, (state) => {
        state.status.update = "loading";
      })
      .addCase(updateNote.fulfilled, (state, { payload }) => {
        state.status.update = "succeeded";
        const newNotes = [...state.notes];
        const index = newNotes.findIndex(
          ({ note }) => note.note_id === payload.note_id
        );
        if (newNotes[index]) {
          newNotes[index].note = payload;
        }
        state.notes = newNotes;
      })
      .addCase(updateNote.rejected, (state, { error }) => {
        state.status.update = "failed";
        state.error.update = error.message as string;
      });
  },
});

export const {
  resetGetNotesStatus,
  resetCreateNoteStatus,
  resetUpdateNoteStatus,
} = notesSlice.actions;

export default notesSlice.reducer;
