import React from "react";
import SidebarButtonBase from "../../../../components/SidebarButton";

type Props = {
  title: string;
};

const SidebarButton = ({ title }: Props) => {
  return (
    <SidebarButtonBase style={{ height: 44 }} color="text">
      {title}
    </SidebarButtonBase>
  );
};

export default SidebarButton;
