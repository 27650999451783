import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import Button from "../../components/Button";
import EventSummary from "../../components/EventSummary";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import Modal from "../../components/Modal";
import Toast from "../../components/Toast";
import LoadingLayout from "../../layout/Loading";
import ArrowBackIcon from "../../icons/ArrowBack";
import CommonFileTextIcon from "../../icons/CommonFileText";
import ExpandIcon from "../../icons/Expand";
import CaretBottomIcon from "../../icons/CaretBottom";
import ExpCellOptions from "./CellOptions";

import type { RootState } from "../../store";
// import type { AuthState } from "../../store/auth/slice";
import { Field, useAutocompleteStyles, useButtonStyles } from "./EditField";
import {
  ExperimentSingleState,
  getConditionInfoForCell,
  getSingleExperiment,
  resetConditionInfoForCell,
  resetSingleExperiment,
  saveSingleExperiment,
} from "./singleSlice";
import {
  ConditionState,
  duplicateConditionFromCell,
  resetCreateCondition,
  resetDuplicateConditionFromCell,
  resetDuplicateCondition,
  getPendingConditionCopy,
  setFetchConditionCopyStatusTimedOut,
  resetFetchConditionCopyStatus,
} from "../conditions/slice";
import {
  expIdToString,
  expStatusToString,
  isoDateToDateString,
} from "../../utils/labels";
import CellList from "../cells/List";
import ModuleCellList from "../modules/CellList";
import { cellsSlices } from "../cells/slice";
import CreateModule from "../modules/CreateModule";
import { isEmpty } from "lodash";
import MetaFilter from "../../components/table/MetaFilter";
import { Checkbox, CircularProgress } from "@mui/material";
import { ModuleState } from "../modules/slice";
import { AuthState } from "../../store/auth/slice";
import {
  FeatureFlagsState,
  LAUNCH_BATCH_CONDITION_SPECIFICATION,
} from "../../store/featureFlags/slice";

const DAYS_TO_ALLOW_AUTO_SPECIFY_ON_CONDITION_DUPLICATE = 14;
const AUTO_SPECIFIED_DISABLED_MESSAGE =
  `Source cell condition must have been specified within the last ${DAYS_TO_ALLOW_AUTO_SPECIFY_ON_CONDITION_DUPLICATE} days in order to auto-specify. ` +
  "If you would like to create a large number of cells from duplication, create a single condition, specify that, then copy the rest of your conditions using this modal.";

const ExperimentLayout = () => {
  const { exp_id = "" } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isTablet = useMediaQuery("(min-width:760px)");
  const [cells, setCells] = useState<null | Cell[]>(null);
  const [modules, setModules] = useState<(Module | ExpModule)[]>([]);
  const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false);

  const parseCells = (cells: Cell[] | null) => {
    if (!!cells && cells.length > 0 && !isEmpty(experiment!.modules)) {
      let exp_modules: ExpModule[] = [];
      experiment!.modules!.forEach((mod_) => {
        exp_modules.push({ ...mod_, cells: [] });
      });
      const moduleInfo: (Module | ExpModule)[] = exp_modules.map(
        (_module_overview) => {
          let collectedModuleInfo = _module_overview;
          cells.forEach((cell) => {
            if (collectedModuleInfo.cell_ids.includes(cell.cell_id)) {
              if (
                cell.module &&
                cell.module.module_id === _module_overview.module_id
              ) {
                collectedModuleInfo = {
                  ...cell.module,
                  ...collectedModuleInfo,
                  cells: [...(collectedModuleInfo.cells || []), cell],
                };
              }
            }
          });
          return collectedModuleInfo;
        }
      );
      setModules(moduleInfo);
    }

    setCells(cells);
  };

  const location = useLocation();
  const { from } = (location.state as {
    from?: string;
  }) || { from: "" };

  const {
    experiment,
    status: { get: expStatus },
    error: { get: expError },
  } = useSelector<RootState, ExperimentSingleState>(
    ({ experimentSingle }) => experimentSingle
  );

  const {
    status: {
      copy: duplicateConditionStatus,
      asyncConditionCopy: asyncConditionCopyStatus,
    },
    error: { copy: duplicateConditionError },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  const {
    status: { create: createModuleStatus, update: updateModuleStatus },
  } = useSelector<RootState, ModuleState>(({ modules }) => modules);
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  useEffect(() => {
    parseCells(null);
    dispatch(resetSingleExperiment());
    dispatch(cellsSlices.EXPERIMENT.resetCellListState());
    dispatch(getSingleExperiment(parseInt(exp_id)));
    // eslint-disable-next-line
  }, [exp_id, user, dispatch]);

  useEffect(() => {
    if (
      [
        asyncConditionCopyStatus,
        createModuleStatus,
        updateModuleStatus,
      ].includes("succeeded")
    ) {
      dispatch(cellsSlices.EXPERIMENT.resetCellListState());
      dispatch(getSingleExperiment(parseInt(exp_id)));
    }
    // eslint-disable-next-line
  }, [
    exp_id,
    dispatch,
    asyncConditionCopyStatus,
    createModuleStatus,
    updateModuleStatus,
  ]);

  if (expStatus !== "succeeded" || !experiment) {
    return (
      <Box p={6}>
        <LoadingLayout light error={expError || ""} />
      </Box>
    );
  }

  return (
    <>
      <Paper variant="outlined" square>
        <Box px={isTablet ? 6 : 0} py={3} display="flex" alignItems="center">
          <IconButton
            size="large"
            onClick={() => (from ? navigate(from) : navigate("/experiments"))}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h2">
            EXP ID:{" "}
            <span style={{ fontWeight: 400 }}>
              {expIdToString(experiment.exp_id)}
            </span>
          </Typography>
          <Box ml="auto" display="flex" alignItems="center">
            {cells && experiment && (
              <ButtonLogEvent
                itemType={"experiment"}
                objects={[
                  {
                    ...experiment,
                    cells: cells,
                  },
                ]}
              />
            )}

            {experiment.link ? (
              <Button
                color="tertiary"
                href={experiment.link}
                target="_blank"
                startIcon={<CommonFileTextIcon />}
              >
                {isTablet ? "Documentation" : ""}
              </Button>
            ) : null}

            <Button
              color="secondary"
              onClick={() => setCreateModuleModalOpen(true)}
            >
              Add module
            </Button>
          </Box>
        </Box>
      </Paper>

      <Box px={isTablet ? 6 : 0} my={4}>
        <Paper variant="outlined" square>
          <Box p={6}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Box display="flex">
                  <Typography variant="h3">Status:</Typography>
                  <Box ml={2} mt="3px" width={200}>
                    <Status
                      value={experiment.status}
                      onChange={(status) =>
                        dispatch(
                          saveSingleExperiment({
                            exp_id: experiment.exp_id,
                            status,
                          })
                        )
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} lg={5}>
                {experiment.description}
              </Grid>
              <Grid
                item
                sm={12}
                md="auto"
                style={isTablet ? { marginLeft: "auto" } : {}}
              >
                <Box display="flex">
                  {isTablet ? (
                    <Divider orientation="vertical" flexItem />
                  ) : null}
                  <Box ml={isTablet ? 5 : 0}>
                    <Typography color="textSecondary" paragraph>
                      Owner
                    </Typography>
                    <Typography color="textSecondary">Project</Typography>
                  </Box>
                  <Box ml={6}>
                    <Box mb={3}>
                      <Field
                        value={experiment.owner}
                        experiment={experiment}
                        link="auth/users"
                      />
                    </Box>
                    <Box>
                      <Field
                        value={experiment.project}
                        experiment={experiment}
                        link="meta/experiments/projects"
                        extraParamString="&archived_at__null=1"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md="auto">
                <Box display="flex">
                  {isTablet ? (
                    <Divider orientation="vertical" flexItem />
                  ) : null}
                  <Box ml={isTablet ? 5 : 0}>
                    <Typography color="textSecondary" paragraph>
                      Total Cells
                    </Typography>
                    <Typography color="textSecondary">Commit Date</Typography>
                  </Box>
                  <Box ml={6}>
                    <Typography paragraph>{experiment.cell_count}</Typography>
                    <Typography>
                      {experiment.last_commit ? (
                        <Tooltip
                          arrow
                          title="The most recent date of the cells committed"
                        >
                          <span>
                            {isoDateToDateString(experiment.last_commit)}
                          </span>
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box px={isTablet ? 6 : 0} my={4}>
        <Paper variant="outlined" square>
          <Box p={6} display="flex" alignItems="center">
            <Typography variant="h3">Cells</Typography>
            <Box ml="auto">
              <ExpCellOptions exp_id={exp_id} />
            </Box>
            <Box ml={2}>
              <ConditionMenu
                exp_id={exp_id}
                onNew={() => {
                  dispatch(resetCreateCondition());
                  navigate(`/experiments/${exp_id}/conditions/new`);
                }}
                onDuplicate={(id) => {
                  dispatch(resetCreateCondition());
                  navigate(
                    `/experiments/${exp_id}/conditions${(id && `/${id}`) || ""}`
                  );
                }}
              />
            </Box>
          </Box>
          <CellList
            stateKey="EXPERIMENT"
            onCellLoad={(cells) => parseCells(cells)}
            hideControls
            exp_id={exp_id}
          />
        </Paper>
      </Box>

      {modules.length > 0 && (
        <>
          {modules.flatMap((mod, i) => (
            <Box px={isTablet ? 6 : 0} my={4} key={i}>
              <Paper variant="outlined" square>
                <ModuleCellList
                  module={mod as Module}
                  hideControls
                  exp_id={exp_id}
                />
              </Paper>
            </Box>
          ))}
        </>
      )}

      <EventSummary
        resourceType={"experiment"}
        resource_id={parseInt(exp_id)}
      />

      <Toast
        severity="error"
        open={duplicateConditionStatus === "failed"}
        onClose={() => dispatch(resetDuplicateCondition())}
      >
        {duplicateConditionError}
      </Toast>
      <Toast
        severity="success"
        open={asyncConditionCopyStatus === "succeeded"}
        onClose={() => {
          dispatch(resetConditionInfoForCell());
          dispatch(resetFetchConditionCopyStatus());
        }}
      >
        Condition(s) created.
      </Toast>
      <CreateModule
        exp_id={exp_id}
        modalOpen={createModuleModalOpen}
        onClose={() => setCreateModuleModalOpen(false)}
      />
    </>
  );
};

type ConditionMenuProps = {
  exp_id: string;
  onNew: () => void;
  onDuplicate: (id?: number) => void;
};

const ConditionMenu = ({ exp_id, onNew, onDuplicate }: ConditionMenuProps) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLButtonElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  const MAX_CONDITION_COPIES = 100;
  const MAX_CELL_REPLICATES = 99;
  const SECONDS_TO_WAIT_FOR_CONDITION_COPY_RESOLUTION = 20; // should be evenly divisible by CONDITION_COPY_RESOLUTION_CHECK_FREQUENCY_SECS
  const CONDITION_COPY_RESOLUTION_CHECK_FREQUENCY_SECS = 4;
  const MAX_CONDITION_COPY_STATUS_RETRIES =
    SECONDS_TO_WAIT_FOR_CONDITION_COPY_RESOLUTION /
    CONDITION_COPY_RESOLUTION_CHECK_FREQUENCY_SECS;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [cellIdToDuplicate, setCellIdToDuplicate] = useState<number | null>(
    null
  );
  const [numCopies, setNumCopies] = useState<string>("1");
  const numberCopies = Number(numCopies);
  const [numCellReplicates, setNumCellReplicates] = useState<string>("1");
  const numberCellReplicates = Number(numCellReplicates);
  const [newConditionNames, setNewConditionNames] = useState<string>("");
  const [disableSpecifyBox, setDisableSpecifyBox] = useState(false);
  const [specifyCopiedCellConditions, setSpecifyCopiedCellConditions] =
    useState(false);
  // Once we change from <Input> for a single condition name to textbox for multiple
  // condition names, we don't want to change back to single <Input>
  const [changedToNamesTextBox, setChangedToNamesTextBox] = useState(false);
  const [fetchConditionCopyStatusRetries, setFetchConditionCopyStatusRetries] =
    useState(0);

  const resetDuplicateFields = () => {
    setNumCopies("1");
    setNumCellReplicates("1");
    setNewConditionNames("");
    setChangedToNamesTextBox(false);
    setSpecifyCopiedCellConditions(false);
  };

  const { enabledFlags } = useSelector<RootState, FeatureFlagsState>(
    ({ featureFlags }) => featureFlags
  );

  const {
    conditions,
    createdConditionId,
    asyncConditionsCreationInProgress,
    status: {
      duplicateFromCell: duplicateStatus,
      asyncConditionCopy: asyncConditionCopyStatus,
    },
    error: { duplicateFromCell: duplicateError },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  const { cellInfoBeforeDuplicate } = useSelector<
    RootState,
    ExperimentSingleState
  >(({ experimentSingle }) => experimentSingle);

  const splitNewConditionNames = useMemo(() => {
    return newConditionNames
      .split(/\r?\n/)
      .map((conditionName) => conditionName.trim())
      .filter((conditionName) => !!conditionName);
  }, [newConditionNames]);

  const cellConditionInfo: Condition | null = useMemo(() => {
    if (!cellInfoBeforeDuplicate || !cellInfoBeforeDuplicate.condition)
      return null;
    return cellInfoBeforeDuplicate.condition;
  }, [cellInfoBeforeDuplicate]);

  useEffect(() => {
    if (!cellConditionInfo) {
      if (disableSpecifyBox) {
        setDisableSpecifyBox(false);
      }
      return;
    }
    setNumCellReplicates(String(cellConditionInfo.replicates || 1));
    if (cellConditionInfo.bypass_commit) {
      setDisableSpecifyBox(false);
      return;
    }
    if (cellConditionInfo.specified) {
      var compareDate = new Date();
      compareDate.setDate(
        compareDate.getDate() -
          DAYS_TO_ALLOW_AUTO_SPECIFY_ON_CONDITION_DUPLICATE
      );
      var specifiedDate = new Date(cellConditionInfo.specified);
      setDisableSpecifyBox(compareDate > specifiedDate);
    }
    // eslint-disable-next-line
  }, [cellConditionInfo]);

  useEffect(() => {
    if (specifyCopiedCellConditions && (!numberCopies || numberCopies === 1)) {
      setSpecifyCopiedCellConditions(false);
    }
  }, [numberCopies, specifyCopiedCellConditions]);

  useEffect(() => {
    if (duplicateStatus === "succeeded" && createdConditionId) {
      const newConditionId = createdConditionId;
      dispatch(resetDuplicateConditionFromCell());
      setDuplicateOpen(false);

      if (conditions.length > 0 && newConditionId) {
        onDuplicate(newConditionId);
      } else {
        onDuplicate();
      }
    }
  }, [duplicateStatus, conditions, dispatch, onDuplicate, createdConditionId]);

  useEffect(() => {
    if (!!numCopies && numberCopies > 1) {
      setChangedToNamesTextBox(true);
    }
  }, [numCopies, numberCopies]);

  useEffect(() => {
    const firstFetch =
      asyncConditionCopyStatus === "loading" &&
      fetchConditionCopyStatusRetries === 0;
    if (
      asyncConditionsCreationInProgress &&
      (firstFetch || asyncConditionCopyStatus === "retry")
    ) {
      if (
        fetchConditionCopyStatusRetries <= MAX_CONDITION_COPY_STATUS_RETRIES
      ) {
        setTimeout(() => {
          dispatch(
            getPendingConditionCopy({
              conditionNames: asyncConditionsCreationInProgress,
              expId: exp_id,
            })
          );
          setFetchConditionCopyStatusRetries(
            (oldRetriesVal) => oldRetriesVal + 1
          );
        }, CONDITION_COPY_RESOLUTION_CHECK_FREQUENCY_SECS * 1000);
      } else {
        // Max retries hit, ask user to reload page in a couple minutes.
        dispatch(setFetchConditionCopyStatusTimedOut());
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    asyncConditionCopyStatus,
    asyncConditionsCreationInProgress,
    MAX_CONDITION_COPY_STATUS_RETRIES,
    exp_id,
  ]);

  useEffect(() => {
    if (asyncConditionCopyStatus === "succeeded") {
      resetDuplicateFields();
      setFetchConditionCopyStatusRetries(0);
      setDuplicateOpen(false);
    }
  }, [dispatch, asyncConditionCopyStatus]);

  const disableSubmit =
    !cellIdToDuplicate ||
    !numberCopies ||
    numberCopies > MAX_CONDITION_COPIES ||
    !numberCellReplicates ||
    numberCellReplicates > MAX_CELL_REPLICATES ||
    splitNewConditionNames.length !== numberCopies ||
    duplicateStatus === "loading";

  const showSpecifyCheckbox =
    !!numberCopies &&
    numberCopies > 1 &&
    !!cellConditionInfo &&
    !!cellConditionInfo.specified;

  const handleDuplicate = () =>
    dispatch(
      duplicateConditionFromCell({
        cell_id: cellIdToDuplicate!,
        exp_id,
        newConditionNames: splitNewConditionNames,
        specifyConditions: specifyCopiedCellConditions,
        cellReplicatesPerCondition: numberCellReplicates,
      })
    );

  return (
    <>
      <Button
        size="small"
        color="primary"
        endIcon={<ExpandIcon style={{ transform: "rotate(90deg)" }} />}
        onClick={handleButtonClick}
      >
        Add Condition
      </Button>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={onNew}>Blank cell/test condition</MenuItem>
        <MenuItem onClick={() => setDuplicateOpen(true)}>
          Duplicate previous cell/test condition
        </MenuItem>
        {enabledFlags?.includes(LAUNCH_BATCH_CONDITION_SPECIFICATION) && (
          <MenuItem
            onClick={() =>
              navigate(`/experiments/${exp_id}/batch-conditions/new`)
            }
          >
            Batch condition specification
          </MenuItem>
        )}
      </Menu>
      <Modal
        open={duplicateOpen}
        onClose={() => {
          resetDuplicateFields();
          setDuplicateOpen(false);
        }}
      >
        <>
          <Typography variant="h2">
            Duplicate previous cell/test condition
          </Typography>
          {asyncConditionCopyStatus === "timed_out" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              my={6}
            >
              <Typography
                sx={{ marginTop: 4, textAlign: "center" }}
                color="textSecondary"
              >
                Condition copy still in progress.
              </Typography>
              <Typography sx={{ textAlign: "center" }} color="textSecondary">
                Please wait a couple minutes and refresh the page.
              </Typography>
              <Button
                sx={{ marginTop: 4 }}
                size="small"
                color="secondary"
                onClick={() => {
                  resetDuplicateFields();
                  setFetchConditionCopyStatusRetries(0);
                  setDuplicateOpen(false);
                  dispatch(resetFetchConditionCopyStatus());
                }}
              >
                Close
              </Button>
            </Box>
          ) : !!asyncConditionsCreationInProgress ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              my={6}
            >
              <CircularProgress />
              <Typography sx={{ marginTop: 4 }} color="textSecondary">
                Copying conditions...
              </Typography>
            </Box>
          ) : (
            <>
              <Box mt={8} mb={2}>
                <Typography color="textSecondary">Cell ID</Typography>
              </Box>
              <FormControl fullWidth>
                <MetaFilter
                  endpoint="meta/cells/ids"
                  prefix="CEL"
                  multiple={false}
                  value={cellIdToDuplicate}
                  onChange={(value) => {
                    dispatch(resetConditionInfoForCell());
                    setCellIdToDuplicate(value);
                    if (value) {
                      dispatch(getConditionInfoForCell(parseInt(value)));
                    } else {
                      resetDuplicateFields();
                    }
                  }}
                />
                {!!cellConditionInfo &&
                cellConditionInfo.name &&
                cellConditionInfo.replicates ? (
                  <Box mt={2}>
                    <Typography color="textSecondary">
                      <span style={{ fontStyle: "italic" }}>Condition: </span>
                      {`${cellConditionInfo.cell_assembly}, "${
                        cellConditionInfo.name
                      }," ${cellConditionInfo.replicates} cell replicate${
                        cellConditionInfo.replicates > 1 ? "s" : ""
                      }.`}
                    </Typography>
                  </Box>
                ) : (
                  <Box py={4} />
                )}
              </FormControl>
              {!!cellIdToDuplicate && (
                <FormControl fullWidth>
                  <Box mt={8} mb={1}>
                    <Typography color="textSecondary">
                      Number of copies
                    </Typography>
                  </Box>
                  <Box style={{ width: 450 }}>
                    <Input
                      disableUnderline
                      type="number"
                      value={numCopies}
                      onChange={(e) => setNumCopies(e.target.value)}
                    />
                  </Box>
                  {!!numCopies &&
                  !!cellConditionInfo &&
                  numberCopies > MAX_CONDITION_COPIES ? (
                    <Box mt={2}>
                      <Typography color="textSecondary">
                        {`Cannot create more than ${MAX_CONDITION_COPIES} conditions at a time.`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box py={4} />
                  )}
                </FormControl>
              )}
              {!!cellIdToDuplicate && (
                <FormControl fullWidth>
                  <Box mt={1} mb={2}>
                    <Typography color="textSecondary">
                      Number of cell replicates per condition
                    </Typography>
                  </Box>
                  <Box style={{ width: 450 }}>
                    <Input
                      disableUnderline
                      type="number"
                      value={numCellReplicates}
                      onChange={(e) => setNumCellReplicates(e.target.value)}
                    />
                  </Box>
                  {!!numCopies && !!cellConditionInfo ? (
                    <Box mt={2}>
                      <Typography color="textSecondary">
                        {numberCellReplicates > MAX_CELL_REPLICATES
                          ? // arbitrary rule, just want to prevent accidentally creating
                            // thousands of cells
                            `Cannot create more than ${MAX_CELL_REPLICATES} cells per condition.`
                          : `will create ${numCopies} cell/test condition${
                              numberCopies === 1 ? "" : "s"
                            } and ${
                              numberCellReplicates * numberCopies || "-"
                            } cell${
                              numberCellReplicates * numberCopies === 1
                                ? ""
                                : "s"
                            }.`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box py={4} />
                  )}
                </FormControl>
              )}
              {!!cellIdToDuplicate && (
                <FormControl fullWidth>
                  <Box mt={8} mb={2}>
                    <Typography color="textSecondary">{`New condition name${
                      !!numCopies && numberCopies > 1 ? "s" : ""
                    }`}</Typography>
                  </Box>
                  <Box>
                    {changedToNamesTextBox ? (
                      <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={8}
                        helperText="Enter one name per line. Number of names must match “Number of copies.”"
                        value={newConditionNames}
                        onChange={(e) => setNewConditionNames(e.target.value)}
                      />
                    ) : (
                      <Input
                        fullWidth
                        disableUnderline
                        type="text"
                        value={newConditionNames}
                        onChange={(e) => setNewConditionNames(e.target.value)}
                      />
                    )}
                  </Box>
                </FormControl>
              )}
              {showSpecifyCheckbox && (
                <FormControl fullWidth>
                  <Box mt={8} mb={2} display="flex">
                    <Typography color="textSecondary">
                      {`Specify${
                        cellConditionInfo.bypass_commit ? "and commit" : ""
                      } conditions?`}
                    </Typography>
                    <Tooltip
                      arrow
                      title={AUTO_SPECIFIED_DISABLED_MESSAGE}
                      disableHoverListener={!disableSpecifyBox}
                    >
                      <span>
                        <Checkbox
                          color="secondary"
                          sx={{ paddingTop: 0 }}
                          value={specifyCopiedCellConditions}
                          disabled={disableSpecifyBox}
                          onChange={(e) =>
                            setSpecifyCopiedCellConditions(e.target.checked)
                          }
                        />
                      </span>
                    </Tooltip>
                  </Box>
                </FormControl>
              )}
              {duplicateStatus === "failed" || duplicateError ? (
                <Box mt={8}>
                  <Typography color="error">
                    {duplicateError || "Error duplicating cell condition"}
                  </Typography>
                </Box>
              ) : null}
              <Box mt={8} display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  disabled={disableSubmit}
                  onClick={handleDuplicate}
                  endIcon={
                    duplicateStatus === "loading" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  Duplicate condition
                </Button>
              </Box>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

type StatusProps = {
  value: ExperimentStatus;
  onChange: (status: ExperimentStatus) => void;
};

const Status = ({ value, onChange }: StatusProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = useState(false);

  const acClasses = useAutocompleteStyles();
  const btnClasses = useButtonStyles();

  const toggleEditing = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setEditing(true);
  };

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setEditing(false);
      }
    };
    if (editing) {
      document.addEventListener("click", callback);
    }
    return () => document.removeEventListener("click", callback);
  }, [editing, ref]);

  const options: ExperimentStatus[] = ["I", "P", "U", "C"];

  return (
    <div ref={ref}>
      {!editing ? (
        <Button
          className={btnClasses.root}
          size="small"
          color="text"
          disableRipple
          onClick={toggleEditing}
          endIcon={<CaretBottomIcon />}
        >
          {expStatusToString(value)}
        </Button>
      ) : (
        <FormControl fullWidth>
          <Autocomplete
            className={acClasses.root}
            open={true}
            options={options}
            getOptionLabel={(option) => expStatusToString(option)}
            isOptionEqualToValue={(option, value) => option === value}
            value={value}
            disableClearable
            onChange={(e, data) => onChange(data!)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                color="secondary"
              />
            )}
            renderOption={(props, value) => (
              <li {...props}>
                <Box px={4} py={2}>
                  {expStatusToString(value)}
                </Box>
              </li>
            )}
          />
        </FormControl>
      )}
    </div>
  );
};

export default ExperimentLayout;
