import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const PROJECTS = "PROJECTS";

interface ProjectList extends Array<Project> {}
export interface ProjectListState {
  projects: ProjectList;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: null | string;
}

const initialState: ProjectListState = {
  projects: [],
  status: "idle",
  error: null,
};

export const listProjects = createAsyncThunk(`${PROJECTS}/list`, async () => {
  const response = await client.get(
    `meta/experiments/projects?archived_at__null=1`
  );
  return { response };
});

const projectsSlice = createSlice({
  name: PROJECTS,
  initialState,
  reducers: {
    resetProjects: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listProjects.fulfilled, (state, { payload }) => {
        if (!payload.response) {
          state.status = "failed";
          state.error = "Unknown error";
          return;
        }
        state.projects = payload.response.data;
        state.status = "succeeded";
      })
      .addCase(listProjects.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message as string;
      });
  },
});

export const { resetProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
