import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { isNull } from "lodash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Button";
import colors from "../../../theme/colors";
import Modal from "../../../components/Modal";
import type { RootState } from "../../../store";
import {
  completeMetadata,
  MetadataState,
  resetCompleteMetadata,
  saveMetadata,
} from "../slice";
import { cellIdToString } from "../../../utils/labels";
import { getDirtyValues } from "../../../utils/forms";
import TeardownField from "./TeardownField";
import parseCellIdString from "../../../utils/parseCellIdString";

type Props = {
  open: boolean;
  onClose: () => void;
  formField: FieldArrayWithId<MetadataRHFormFormat, "metadata">;
  teardownType?: string;
  teardownFields?: ConditionUIField[];
  teardownState?: Record<string, any>;
  executor: User | null;
};

const TeardownEditModal = ({
  open,
  onClose,
  formField,
  teardownType,
  teardownFields = [],
  teardownState = {},
  executor,
}: Props) => {
  const { cell_id_string = "" } = useParams();

  const formMethods = useForm({ mode: "onBlur", defaultValues: teardownState });
  const { getValues, setValue, reset, register, trigger, formState } =
    formMethods;
  const { isDirty } = formState;
  const [enabledFields, setEnabledFields] = useState<{
    [key: string]: [string];
  }>({});

  // Populate enabledFields on teardownFields render
  useEffect(() => {
    let enabledFieldsCopy: { [key: string]: [string] } = {};

    teardownFields
      .filter((field) => "enabled_by" in field)
      .forEach((field) => {
        let enabled_by = field?.enabled_by?.id;
        if (enabled_by) {
          let field_id = field.id;
          if (field.type.includes("*")) {
            let reps = parseInt(field.type.split("*")[1]);
            for (let i = 1; i <= reps; i++) {
              let field_id_reps = `${field_id}__rep_${i}`;
              if (
                enabled_by in enabledFieldsCopy &&
                !(field_id_reps in enabledFieldsCopy[enabled_by])
              ) {
                enabledFieldsCopy[enabled_by].push(field_id_reps);
              } else {
                enabledFieldsCopy[enabled_by] = [field_id_reps];
              }
            }
          } else {
            // Add the conditional value if applicable to the enabled fields
            let conditional_val = field?.enabled_by?.conditional_val;
            if (!!conditional_val) {
              enabled_by = `${enabled_by}==${conditional_val}`;
            }
            let conditional_vals = field?.enabled_by?.conditional_vals;
            if (!!conditional_vals) {
              enabled_by = `${enabled_by}==${conditional_vals}`; // need to figure this out
            }
            if (
              enabled_by in enabledFieldsCopy &&
              !(field_id in enabledFieldsCopy[enabled_by])
            ) {
              enabledFieldsCopy[enabled_by].push(field_id);
            } else {
              enabledFieldsCopy[enabled_by] = [field_id];
            }
          }
        }
      });
    setEnabledFields(enabledFieldsCopy);
    // eslint-disable-next-line
  }, [teardownFields]);

  const user = useSelector<RootState, null | User>(({ auth }) => auth.user);
  useEffect(() => {
    reset(teardownState);

    // Pre-fill teardown operators if applicable
    if (
      "teardown_operators" in teardownState &&
      isNull(teardownState["teardown_operators"])
    ) {
      setValue("teardown_operators", [user], { shouldValidate: true });
    }
  }, [teardownState, reset, open, user, setValue]);

  // Metadata state in redux
  const dispatch = useDispatch();
  const {
    status: { save: saveStatus, complete: completeStatus },
    error: { save: saveError, complete: completeError },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  useEffect(() => {
    if (open) {
      dispatch(resetCompleteMetadata());
    }
  }, [dispatch, open]);

  const removeObsoleteData = (formValues: any) => {
    /**
    Removes form data if the enabled-by field doesn't meet the criteria
    for requiring the dependent field.
    **/
    let fieldsToDelete: { [key: string]: null } = {};

    // Collect data on conditional values in the format
    // {"key": "value_that_disables_requirement"}
    let conditionalVals: { [key: string]: string } = {};
    Object.keys(enabledFields).forEach((field) => {
      let split_obj = field.split("==");
      if (split_obj.length > 1) {
        conditionalVals[split_obj[0]] = split_obj[1];
      }
    });
    for (const [key, val] of Object.entries(formValues)) {
      if (key in conditionalVals) {
        // If this key has a conditional value for requiring the dependent field,
        // delete the dependent field's data if the value meets the requirement
        let _key = `${key}==${conditionalVals[key]}`;

        if (_key in enabledFields && conditionalVals[key] !== val) {
          enabledFields[_key].forEach((field) => {
            fieldsToDelete[field] = null;
          });
        }
      } else if (key in enabledFields && !!val === false) {
        // Otherwise, just check that the value isn't already null
        enabledFields[key].forEach((field) => {
          fieldsToDelete[field] = null;
        });
      }
    }

    return {
      ...formValues,
      ...fieldsToDelete,
    };
  };

  const handleSave = useCallback(async () => {
    const values = getValues();

    if (values.completed?.completed_at) {
      const result = await trigger();
      if (!result) {
        return;
      }
    }

    const dirtyValues = removeObsoleteData(
      getDirtyValues(formState.dirtyFields, values)
    );
    const { conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string);

    await dispatch(
      saveMetadata({
        metadata: [{ items: [dirtyValues] }],
        conditionIdsToRequestedCellIds,
      })
    );
    // eslint-disable-next-line
  }, [getValues, trigger, cell_id_string, formState, dispatch]);

  const handleComplete = useCallback(async () => {
    const result = await trigger();
    if (!result) {
      return;
    }

    const { completed } = getValues();
    const { conditionIdsToRequestedCellIds } =
      parseCellIdString(cell_id_string);

    await dispatch(
      completeMetadata({
        components: [{ completed }],
        executor,
        conditionIdsToRequestedCellIds,
      })
    );

    onClose();
  }, [cell_id_string, onClose, dispatch, executor, getValues, trigger]);

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="h2">Teardown</Typography>

      <Box mt={6} mb={2} display="flex" alignItems="center">
        <Typography color="textSecondary" fontWeight={500} className="small">
          Cell ID:
        </Typography>
        <Box ml={2} mr={30}>
          <Typography color="textPrimary">
            {cellIdToString(formField.cell_id)}
          </Typography>
        </Box>
        <Box mr={2}>
          <Typography color="textSecondary" fontWeight={500} className="small">
            Type:
          </Typography>
        </Box>
        <Typography color="textPrimary">{teardownType}</Typography>
      </Box>

      {open ? (
        <FormProvider {...formMethods}>
          <form>
            <div style={{ overflow: "auto", height: "50vh" }}>
              {teardownFields.map((field, index) => (
                <TeardownField
                  key={index}
                  field={field}
                  value={teardownState[field.id]}
                />
              ))}
            </div>

            <input
              key={`hidden-${teardownState.id}-api_path`}
              {...register("api_path")}
              type="hidden"
              defaultValue={teardownState.api_path}
            />
            {teardownState.completed?.api_path.do ? (
              <input
                key={`hidden-${teardownState.id}-complete-api_path`}
                {...register("completed.api_path.do")}
                type="hidden"
                defaultValue={teardownState.completed.api_path.do}
              />
            ) : null}
            {teardownState.completed?.api_path.undo ? (
              <input
                key={`hidden-${teardownState.id}-uncomplete-api_path`}
                {...register("completed.api_path.undo")}
                type="hidden"
                defaultValue={teardownState.completed.api_path.undo}
              />
            ) : null}
            <input
              key={`hidden-${teardownState.id}-complete-time`}
              {...register("completed.completed_at")}
              type="hidden"
              defaultValue={teardownState.completed?.completed_at}
            />

            <Box mt={6} display="flex" alignItems="center">
              <div className="small" style={{ color: colors.accent.red }}>
                {saveError}
                {completeError}
              </div>
              <Box ml="auto" display="flex">
                <Button
                  size="small"
                  color="primary"
                  type="button"
                  onClick={() => handleSave()}
                  endIcon={
                    saveStatus === "loading" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                  disabled={saveStatus === "loading"}
                >
                  Save
                </Button>
                {!teardownState.completed?.completed_at ? (
                  <Box ml={4}>
                    <Button
                      size="small"
                      color="cta"
                      type="button"
                      onClick={() => handleComplete()}
                      endIcon={
                        completeStatus === "loading" ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null
                      }
                      disabled={isDirty || completeStatus === "loading"}
                    >
                      Complete
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </form>
        </FormProvider>
      ) : null}
    </Modal>
  );
};

export default TeardownEditModal;
