import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MinusCircleIcon from "../../icons/MinusCircle";
import SmallChip from "../SmallChip";

type OptionFilterProps = {
  multiple?: boolean;
  selected?: string[];
  onChange: (selected: string[]) => void;
  removable?: boolean;
  onRemove?: () => void;
  options: { id: string; label: string }[];
};

const OptionFilter = ({
  multiple,
  selected = [],
  removable,
  onRemove,
  options,
  onChange,
}: OptionFilterProps) => {
  const handleToggleSelectedItem = (id?: string) => {
    if (!id) {
      return;
    }

    if (multiple) {
      const index = selected.indexOf(id);
      if (index > -1) {
        const newSelected = [...selected];
        newSelected.splice(index, 1);
        onChange(newSelected);
      } else {
        onChange([...selected, id]);
      }
    } else {
      onChange([id]);
    }
  };

  const _selected = selected.map((sel) =>
    options.find((opt) => opt.id === sel)
  );

  return (
    <Box display="flex" style={{ width: 450 }}>
      <FormControl fullWidth>
        <Autocomplete
          multiple={multiple}
          disableCloseOnSelect={multiple}
          value={multiple ? _selected : _selected[0] || null}
          options={options}
          onChange={(e, value, reason) =>
            reason === "clear" ? onChange([]) : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
            />
          )}
          renderTags={(values) =>
            values.map((value) => (
              <Box key={value?.id} mr={2} mb={1}>
                <SmallChip
                  label={value?.label}
                  onDelete={() => handleToggleSelectedItem(value?.id)}
                />
              </Box>
            ))
          }
          renderOption={(props, opt) => (
            <li {...props}>
              {multiple ? (
                <Checkbox
                  color="secondary"
                  checked={selected.includes(opt?.id || "")}
                  onChange={() => handleToggleSelectedItem(opt?.id)}
                />
              ) : null}
              <Box
                px={multiple ? undefined : 2}
                py={2}
                width="100%"
                onClick={() => handleToggleSelectedItem(opt?.id)}
              >
                {opt?.label}
              </Box>
            </li>
          )}
        />
      </FormControl>
      {removable ? (
        <IconButton size="small" onClick={onRemove}>
          <MinusCircleIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default OptionFilter;
