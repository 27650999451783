import React, { useCallback, useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../components/Button";
import CaretBottomIcon from "../../icons/CaretBottom";
import client from "../../api";
import colors from "../../theme/colors";
import { saveSingleExperiment } from "./singleSlice";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

const useAutocompleteStyles = makeStyles({
  root: {
    textAlign: "left",
    marginTop: "-0.5rem",
    "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      padding: "0.25rem",
      paddingRight: 39,
    },
  },
});

const useButtonStyles = makeStyles({
  root: {
    "&.MuiButton-root": {
      color: colors.text.primary,
      justifyContent: "space-between",
      fontSize: "1rem",
      padding: "0.5rem",
      marginTop: "-0.5rem",
    },
  },
});

type FieldProps = {
  value: Project | User;
  experiment: Experiment;
  link: string;
  extraParamString?: string;
};

const Field = ({ value, experiment, link, extraParamString }: FieldProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const acClasses = useAutocompleteStyles();
  const btnClasses = useButtonStyles();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<typeof value[]>([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const _handleLookup = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get(
        `${link}?__sort=name&name__contains=${search}${extraParamString || ""}`
      );
      setOptions(response.data);
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
  }, [search, link, extraParamString]);

  const handleSaveProject = useCallback(
    async (project_: Project) => {
      await dispatch(
        saveSingleExperiment({
          exp_id: experiment.exp_id,
          project_id: project_.project_id,
        })
      );
    },
    [dispatch, experiment]
  );

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    if (search !== "") {
      handleLookup();
    }
  }, [search, handleLookup]);

  const toggleEditing = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setEditing(true);
  };

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setEditing(false);
      }
    };
    if (editing) {
      document.addEventListener("click", callback);
    }
    return () => document.removeEventListener("click", callback);
  }, [editing, ref]);

  return (
    <div ref={ref}>
      {!editing ? (
        <Button
          className={btnClasses.root}
          style={{ width: 250, textAlign: "left" }}
          size="small"
          color="text"
          disableRipple
          onClick={toggleEditing}
          endIcon={<CaretBottomIcon />}
        >
          {value?.name}
        </Button>
      ) : (
        <FormControl fullWidth>
          <Autocomplete
            className={acClasses.root}
            style={{ width: 250 }}
            open={true}
            options={options}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={value}
            onChange={(e, data) => {
              if (data && "user_id" in data) {
                dispatch(
                  saveSingleExperiment({
                    exp_id: experiment.exp_id,
                    owner_id: data.user_id,
                  })
                );
              } else if (data) {
                handleSaveProject(data as Project);
              }
            }}
            loading={loading}
            noOptionsText={
              search.length > 0 ? "No results found." : "Start typing..."
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                variant="outlined"
                size="small"
                color="secondary"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
            )}
            renderOption={(props, { name }) => (
              <li {...props}>
                <Box px={4} py={2}>
                  {name}
                </Box>
              </li>
            )}
          />
        </FormControl>
      )}
    </div>
  );
};

export { Field, useAutocompleteStyles, useButtonStyles };
