import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Toast from "../components/Toast";
import logoLight from "../images/logo.png";
import logoDark from "../images/logo-black.png";
import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import colors from "../theme/colors";

type Props = {
  light?: boolean;
  fullscreen?: boolean;
  error?: string;
};

const Loading = ({ light, fullscreen, error }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    style={{
      background: light ? "#FFF" : colors.text.primary,
      width: fullscreen ? "100vw" : "100%",
      height: fullscreen ? "100vh" : "100%",
    }}
  >
    <Box mb={6}>
      {light ? (
        <img src={logoDark} alt="Form Energy Logo" style={{ maxWidth: 120 }} />
      ) : (
        <img src={logoLight} alt="Form Energy Logo" style={{ maxWidth: 120 }} />
      )}
    </Box>
    {error ? (
      <>
        <ExclamationCircleOutlinedIcon
          color="primary"
          style={{ width: 40, height: 40 }}
        />
        <Toast open={true} severity="error">
          {error}
        </Toast>
      </>
    ) : (
      <CircularProgress />
    )}
  </Box>
);

export default Loading;
