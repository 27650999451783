import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Microscope = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      d="M7.5 15h.563v.75c0 .414.335.75.75.75h1.874a.75.75 0 0 0 .75-.75V15H12a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 12 1.5V.75a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v.75A1.5 1.5 0 0 0 6 3v10.5A1.5 1.5 0 0 0 7.5 15Zm14.25 6h-.06A8.955 8.955 0 0 0 24 15c0-4.963-4.037-9-9-9v3c3.308 0 6 2.692 6 6s-2.692 6-6 6H2.25A2.25 2.25 0 0 0 0 23.25c0 .414.336.75.75.75h22.5a.75.75 0 0 0 .75-.75A2.25 2.25 0 0 0 21.75 21ZM4.875 19.5h9.75a.375.375 0 0 0 .375-.375v-.75a.375.375 0 0 0-.375-.375h-9.75a.375.375 0 0 0-.375.375v.75c0 .207.168.375.375.375Z"
      fill="#000"
    />
  </SvgIcon>
);

export default Microscope;
