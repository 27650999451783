import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import type { RootState } from "../store";
import type { AuthState } from "../store/auth/slice";
import { impersonate, logOut } from "../store/auth/slice";
import Button from "./Button";
import Link from "./Link";
//import AlarmBellIcon from "../icons/AlarmBell";
import CaretBottomIcon from "../icons/CaretBottom";
import CheckSquareIcon from "../icons/CheckSquare";
import NavigationMenuIcon from "../icons/NavigationMenu";
import RefreshIcon from "../icons/Refresh";
import logoLight from "../images/logo.png";
import logoDark from "../images/logo-black.png";
import colors, { addAlpha } from "../theme/colors";
import ImpersonationUserSearch from "../features/impersonation/ImpersonationUserSearch";
import { getInitialsForUser } from "../utils/labels";
import { NotificationBadge } from "./Badge";
import {
  getPendingChangeRequests,
  resetGetPendingChangeRequests,
} from "../features/change-requests/slice";
import type { ChangeRequestState } from "../features/change-requests/slice";

const useNavStyles = makeStyles({
  logo: {
    display: "block",
    maxHeight: 26,
  },
  iconButton: {
    color: "white",
    "&:not(.avatarButton)": {
      padding: "1rem",
    },
    borderRadius: 0,
    "& .MuiAvatar-root": {
      fontWeight: 400,
      "&:not(.MuiAvatar-colorDefault)": {
        background: "transparent",
        border: "3px solid transparent",
      },
      "&.menuOpen": {
        borderColor: "white",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      "& .MuiAvatar-root": {
        borderColor: "white",
      },
    },
  },
  loggedInUser: {
    cursor: "default",
    pointerEvents: "none",
  },
  navActive: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  mobileNavActive: {
    color: colors.accent.primary,
    background: addAlpha(colors.accent.primary, 0.1),
    "& .MuiListItemText-primary": {
      fontWeight: 700,
    },
  },
});

type NavLinkProps = {
  label: string;
  to: string;
  active: boolean;
};

const NavLink = ({ label, to, active }: NavLinkProps) => {
  const style = useNavStyles();

  return (
    <Box
      px={4}
      my={active ? 0 : "auto"}
      display="flex"
      alignItems="center"
      className={active ? style.navActive : undefined}
    >
      <Link
        to={to}
        className="small"
        style={{ color: active ? "#FFFFFF" : colors.rules }}
      >
        {active ? <b>{label}</b> : label}
      </Link>
    </Box>
  );
};

const NavHeader = () => {
  const style = useNavStyles();
  const isTablet = useMediaQuery("(min-width:760px)");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newVersion, setNewVersion] = useState(false);

  const { user, impersonating } = useSelector<RootState, AuthState>(
    ({ auth }) => auth
  );

  const {
    pendingChangeRequestCount,
    status: { pending: getPendingChangeRequestsStatus },
  } = useSelector<RootState, ChangeRequestState>(
    ({ changeRequests }) => changeRequests
  );

  useEffect(() => {
    if (getPendingChangeRequestsStatus === "idle") {
      dispatch(getPendingChangeRequests());
      setTimeout(() => dispatch(resetGetPendingChangeRequests()), 60 * 1000);
    }
  }, [dispatch, getPendingChangeRequestsStatus]);

  useEffect(() => {
    const intervalId = setInterval(
      async () => {
        try {
          const response = await fetch("/version.html");
          const latestHash = await response.text();

          if (
            latestHash &&
            process.env.REACT_APP_GIT_SHA &&
            !latestHash.includes(process.env.REACT_APP_GIT_SHA)
          ) {
            setNewVersion(true);
            clearInterval(intervalId);
          }
        } catch (err) {}
      },
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? 15 * 60 * 1000
        : 1 * 60 * 1000
    );

    return () => clearInterval(intervalId);
  }, []);

  const [avatarEl, setAvatarEl] = useState<null | HTMLButtonElement>(null);
  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAvatarEl(event.currentTarget);
  const handleAvatarClose = () => setAvatarEl(null);

  const [mobileOpen, setMobileOpen] = useState(false);

  const links = [
    {
      label: "Experiments",
      href: "/experiments",
      active:
        pathname.startsWith("/experiments") || pathname.startsWith("/cells"),
    },
    {
      label: "Infrastructure",
      href: "/infrastructure",
      active: pathname.startsWith("/infrastructure"),
    },
    {
      label: "Characterization",
      href: "/characterization",
      active: pathname.startsWith("/characterization"),
    },
    {
      label: "Cycling Protocols",
      href: "/cycling-protocols",
      active: pathname.startsWith("/cycling-protocols"),
    },
  ];

  const shortHash = process.env.REACT_APP_GIT_SHA
    ? process.env.REACT_APP_GIT_SHA.substring(0, 7)
    : "N/A";

  const initials = getInitialsForUser(user);

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar variant="dense">
          {!isTablet ? (
            <IconButton
              aria-label="Menu"
              size="small"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <NavigationMenuIcon htmlColor="white" />
            </IconButton>
          ) : (
            <Box py={4} pl={6} my="auto" mr={10}>
              <Link to="/">
                <img
                  src={logoLight}
                  alt="Form Energy Logo"
                  className={style.logo}
                />
              </Link>
            </Box>
          )}
          {isTablet
            ? links.map(({ label, href, active }, i) => (
                <NavLink key={i} label={label} to={href} active={active} />
              ))
            : null}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexGrow={1}
          >
            {newVersion ? (
              <Box mr={3}>
                <Button
                  size="small"
                  color="tertiary"
                  style={{ background: "white" }}
                  endIcon={<RefreshIcon style={{ width: 16, height: 16 }} />}
                  onClick={() => window.location.reload()}
                >
                  <b>New Version</b>
                </Button>
              </Box>
            ) : null}
            {impersonating ? (
              <Box display="flex" mr={3}>
                <Box mr={3} my="auto" className="small">
                  Logging&nbsp;as:
                </Box>
                <ImpersonationUserSearch
                  navMode
                  value={user || undefined}
                  onChange={(user) => dispatch(impersonate(user))}
                />
              </Box>
            ) : null}
            {isTablet ? (
              <Box mr={3}>
                <Button
                  size="small"
                  color="cta"
                  onClick={() => navigate("/experiments/create")}
                >
                  Create
                </Button>
              </Box>
            ) : null}

            {user?.role === "O" ? (
              <IconButton
                className={`${style.iconButton} ${
                  pathname.startsWith("/change-requests") ? style.navActive : ""
                }`}
                aria-label="Tasks"
                size="small"
                onClick={() => navigate("/change-requests")}
              >
                <NotificationBadge badgeContent={pendingChangeRequestCount}>
                  <CheckSquareIcon />
                </NotificationBadge>
              </IconButton>
            ) : null}

            {/*<IconButton
                  className={style.iconButton}
                  aria-label="Notifications"
                  size="small"
                >
                  <AlarmBellIcon />
                </IconButton>*/}

            <Box ml={2}>
              <IconButton
                className={`${style.iconButton} avatarButton`}
                onClick={handleAvatarClick}
                size="small"
              >
                <Avatar
                  className={!!avatarEl ? "menuOpen" : ""}
                  alt={user?.name}
                  src={user?.avatarUrl}
                >
                  {initials.toUpperCase()}
                </Avatar>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <MobileMenu open={mobileOpen} onClose={() => setMobileOpen(false)} />

      <Menu
        open={!!avatarEl}
        onClose={handleAvatarClose}
        anchorEl={avatarEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem className={style.loggedInUser} disableGutters>
          <Box px={4}>
            <b>{user?.name}</b>
            <br />
            <span className="small" style={{ color: colors.text.secondary }}>
              {user?.email}
            </span>
          </Box>
        </MenuItem>

        <Box px={4} py={2}>
          <Divider />
        </Box>

        <MenuItem className={style.loggedInUser} disableGutters>
          <Box px={4} className="small">
            For additional permissions or
            <br />
            feedback reach out on Slack:
          </Box>
        </MenuItem>

        <MenuItem
          disableGutters
          onClick={() =>
            window.open("https://slack.com/app_redirect?channel=C01NXC0FTHC")
          }
        >
          <Box
            px={4}
            className="small"
            style={{ color: colors.text.secondary }}
          >
            <b>#t-oak-users</b>
          </Box>
        </MenuItem>

        <Box px={4} py={2}>
          <Divider />
        </Box>

        <MenuItem
          disableGutters
          onClick={() => navigator.clipboard.writeText(shortHash)}
        >
          <Box className="small" px={4}>
            Version: <code>{shortHash}</code> <em>(click to copy)</em>
          </Box>
        </MenuItem>

        <Box px={4} py={2}>
          <Divider />
        </Box>

        <MenuItem disableGutters onClick={() => dispatch(logOut())}>
          <Box px={4}>Sign out</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

type MobileMenuProps = {
  open: boolean;
  onClose: () => void;
};

const MobileMenu = ({ open, onClose }: MobileMenuProps) => {
  const style = useNavStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [experimentsOpen, setExperimentsOpen] = useState(true);
  const toggleExperiments = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExperimentsOpen(!experimentsOpen);
  };

  const [infrastructureOpen, setInfrastructureOpen] = useState(true);
  const toggleInfrastructure = (event: React.MouseEvent) => {
    event.stopPropagation();
    setInfrastructureOpen(!infrastructureOpen);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClick={onClose}
      PaperProps={{
        color: "primary",
        style: { width: "75vw" },
      }}
    >
      <Box px={4} pt={4}>
        <Link to="/">
          <img src={logoDark} alt="Form Energy Logo" className={style.logo} />
        </Link>
      </Box>
      <List>
        <ListItem button onClick={toggleExperiments}>
          <ListItemText primary="Experiments" />
          <CaretBottomIcon
            style={experimentsOpen ? { transform: "rotate(180deg)" } : {}}
          />
        </ListItem>
        <Collapse in={experimentsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/experiments") ? style.mobileNavActive : ""
              }
              onClick={() => navigate("/experiments")}
            >
              <ListItemText primary="All Experiments" />
            </ListItem>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/cells") && !pathname.startsWith("/cells/")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/cells")}
            >
              <ListItemText primary="All Cells" />
            </ListItem>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/cells/specified")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/cells/specified")}
            >
              <ListItemText primary="Specified" />
            </ListItem>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/cells/staged")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/cells/staged")}
            >
              <ListItemText primary="Staged" />
            </ListItem>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/cells/committed")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/cells/committed")}
            >
              <ListItemText primary="Committed" />
            </ListItem>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/cells/testing")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/cells/testing")}
            >
              <ListItemText primary="Testing +" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={toggleInfrastructure}>
          <ListItemText primary="Infrastructure" />
          <CaretBottomIcon
            style={infrastructureOpen ? { transform: "rotate(180deg)" } : {}}
          />
        </ListItem>
        <Collapse in={infrastructureOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              sx={{ pl: 8 }}
              button
              className={
                pathname.startsWith("/infrastructure/channels")
                  ? style.mobileNavActive
                  : ""
              }
              onClick={() => navigate("/infrastructure")}
            >
              <ListItemText primary="Channels" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default NavHeader;
