import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Cog = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon {...props} style={{ ...style, fill: "transparent" }}>
    {children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5769 2.64705C10.9399 3.05002 11.4568 3.28009 11.9992 3.28009C12.5416 3.28009 13.0585 3.05002 13.4216 2.64705L14.347 1.63166C14.8904 1.03019 15.7535 0.835738 16.5024 1.14605C17.2513 1.45636 17.7239 2.20431 17.6827 3.01388L17.6132 4.38339C17.5861 4.92363 17.7886 5.45017 18.1708 5.83294C18.553 6.21571 19.0793 6.41902 19.6195 6.39265L20.9891 6.32319C21.7981 6.28331 22.5448 6.7564 22.8543 7.50495C23.1638 8.25349 22.9693 9.1158 22.3683 9.65892L21.349 10.5784C20.9466 10.942 20.7169 11.4589 20.7169 12.0013C20.7169 12.5436 20.9466 13.0606 21.349 13.4241L22.3683 14.3436C22.9698 14.8871 23.1643 15.7502 22.8539 16.4991C22.5436 17.2479 21.7957 17.7206 20.9861 17.6793L19.6166 17.6099C19.0751 17.582 18.5472 17.785 18.1639 18.1683C17.7805 18.5517 17.5775 19.0796 17.6054 19.6211L17.6749 20.9906C17.7107 21.7959 17.2388 22.5376 16.4941 22.8462C15.7495 23.1548 14.8913 22.9645 14.347 22.3699L13.4264 21.3516C13.0631 20.9492 12.5463 20.7195 12.0041 20.7195C11.4619 20.7195 10.9451 20.9492 10.5818 21.3516L9.65737 22.3699C9.11387 22.9673 8.25416 23.1598 7.50778 22.8513C6.7614 22.5427 6.28858 21.7994 6.32556 20.9926L6.39599 19.6231C6.42384 19.0816 6.22091 18.5537 5.83753 18.1703C5.45414 17.7869 4.92624 17.584 4.38477 17.6118L3.01527 17.6813C2.20602 17.7235 1.45776 17.2519 1.14676 16.5036C0.835753 15.7553 1.02932 14.8923 1.63011 14.3485L2.64844 13.429C3.0509 13.0654 3.28058 12.5485 3.28058 12.0062C3.28058 11.4638 3.0509 10.9469 2.64844 10.5833L1.63011 9.65892C1.03133 9.11576 0.838055 8.25513 1.14709 7.5081C1.45612 6.76107 2.20091 6.28851 3.00842 6.32711L4.37793 6.39656C4.92044 6.42508 5.44955 6.22188 5.8335 5.83755C6.21746 5.45322 6.42015 4.92392 6.3911 4.38143L6.32556 3.01095C6.28769 2.20379 6.7602 1.45961 7.5068 1.15053C8.2534 0.841459 9.11364 1.03391 9.65737 1.63166L10.5769 2.64705Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9992 16.4037C14.4303 16.4037 16.4011 14.4329 16.4011 12.0017C16.4011 9.57057 14.4303 7.59973 11.9992 7.59973C9.568 7.59973 7.59717 9.57057 7.59717 12.0017C7.59717 14.4329 9.568 16.4037 11.9992 16.4037Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Cog;
