import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";
import { uniq, uniqBy } from "lodash";

export interface CustomViewState {
  views: CustomTableView<Cell | Experiment | Channel>[];
  viewsQueried: string[];
  justEdited: CustomTableView<Cell | Experiment | Channel> | null;
  status: {
    list: "idle" | "loading" | "succeeded" | "failed";
    add: "idle" | "loading" | "succeeded" | "failed";
    edit: "idle" | "loading" | "succeeded" | "failed";
    star: "idle" | "loading" | "succeeded" | "failed";
    delete: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    list: null | string;
    add: null | string;
    edit: null | string;
    star: null | string;
    delete: null | string;
  };
}

const initialState: CustomViewState = {
  views: [],
  viewsQueried: [],
  justEdited: null,
  status: {
    list: "idle",
    add: "idle",
    edit: "idle",
    star: "idle",
    delete: "idle",
  },
  error: {
    list: null,
    add: null,
    edit: null,
    star: null,
    delete: null,
  },
};

const CUSTOM_VIEWS = "CUSTOM_VIEWS";

export const getCustomViews = createAsyncThunk(
  `${CUSTOM_VIEWS}/list`,
  async (tableId: string) => {
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${tableId ? `&table_id=${tableId}` : ""}`
      );
    return { data: response.data, tableId };
  }
);

export const addCustomView = createAsyncThunk(
  `${CUSTOM_VIEWS}/add`,
  async (view: CustomTableView<Cell | Experiment | Channel>) => {
    await client.post(`meta/custom-view`, view);
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${
          view.table_id ? `&table_id=${view.table_id}` : ""
        }`
      );
    return {
      views: response.data,
      justEdited: response.data[response.data.length - 1],
    };
  }
);

export const editCustomView = createAsyncThunk(
  `${CUSTOM_VIEWS}/edit`,
  async (view: CustomTableView<Cell | Experiment | Channel>) => {
    await client.put(`meta/custom-view/${view.custom_view_id}`, view);
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${
          view.table_id ? `&table_id=${view.table_id}` : ""
        }`
      );
    return {
      views: response.data,
      justEdited: response.data.find(
        ({ custom_view_id }) => view.custom_view_id === custom_view_id
      )!,
    };
  }
);

export const starCustomView = createAsyncThunk(
  `${CUSTOM_VIEWS}/star`,
  async ({
    custom_view_id,
    table_id: tab_name,
  }: CustomTableView<Cell | Experiment | Channel>) => {
    await client.post(`meta/custom-view/pref`, {
      custom_view_id,
      tab_name,
    });
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${
          tab_name ? `&table_id=${tab_name}` : ""
        }`
      );
    return response.data;
  }
);

export const unstarCustomView = createAsyncThunk(
  `${CUSTOM_VIEWS}/unstar`,
  async ({
    table_id: tab_name,
  }: CustomTableView<Cell | Experiment | Channel>) => {
    await client.post(`meta/custom-view/pref`, {
      custom_view_id: null,
      tab_name,
    });
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${
          tab_name ? `&table_id=${tab_name}` : ""
        }`
      );
    return response.data;
  }
);

export const deleteCustomView = createAsyncThunk(
  `${CUSTOM_VIEWS}/delete`,
  async ({
    custom_view_id,
    table_id,
  }: CustomTableView<Cell | Experiment | Channel>) => {
    await client.delete(`meta/custom-view/${custom_view_id}`);
    const response: { data: CustomTableView<Cell | Experiment | Channel>[] } =
      await client.get(
        `meta/custom-view?__limit=1000${
          table_id ? `&table_id=${table_id}` : ""
        }`
      );
    return response.data;
  }
);

const slice = createSlice({
  name: CUSTOM_VIEWS,
  initialState,
  reducers: {
    resetListViewsStatus: (state) => {
      state.status.list = "idle";
      state.error.list = null;
    },
    resetAddViewStatus: (state) => {
      state.status.add = "idle";
      state.error.add = null;
      state.justEdited = null;
    },
    resetEditViewStatus: (state) => {
      state.status.edit = "idle";
      state.error.edit = null;
      state.justEdited = null;
    },
    resetStarViewStatus: (state) => {
      state.status.star = "idle";
      state.error.star = null;
    },
    resetDeleteViewStatus: (state) => {
      state.status.delete = "idle";
      state.error.delete = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // List views
      .addCase(getCustomViews.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(
        getCustomViews.fulfilled,
        (state, { payload: { data, tableId } }) => {
          state.status.list = "succeeded";
          state.views = uniqBy([...state.views, ...data], "custom_view_id");
          state.viewsQueried = [...state.viewsQueried, tableId];
        }
      )
      .addCase(getCustomViews.rejected, (state, { error }) => {
        state.status.list = "failed";
        state.error.list = error.message as string;
      })
      // Add view
      .addCase(addCustomView.pending, (state) => {
        state.status.add = "loading";
      })
      .addCase(addCustomView.fulfilled, (state, { payload }) => {
        state.status.add = "succeeded";
        state.views = payload.views;
        state.viewsQueried = uniq(
          payload.views.map(({ table_id }) => table_id)
        );
        state.justEdited = payload.justEdited;
      })
      .addCase(addCustomView.rejected, (state, { error }) => {
        state.status.add = "failed";
        state.error.add = error.message as string;
      })
      // Edit view
      .addCase(editCustomView.pending, (state) => {
        state.status.edit = "loading";
      })
      .addCase(editCustomView.fulfilled, (state, { payload }) => {
        state.status.edit = "succeeded";
        state.views = payload.views;
        state.viewsQueried = uniq(
          payload.views.map(({ table_id }) => table_id)
        );
        state.justEdited = payload.justEdited;
      })
      .addCase(editCustomView.rejected, (state, { error }) => {
        state.status.edit = "failed";
        state.error.edit = error.message as string;
      })
      // Star view
      .addCase(starCustomView.pending, (state) => {
        state.status.star = "loading";
      })
      .addCase(starCustomView.fulfilled, (state, { payload }) => {
        state.status.star = "succeeded";
        state.views = payload;
        state.viewsQueried = uniq(payload.map(({ table_id }) => table_id));
      })
      .addCase(starCustomView.rejected, (state, { error }) => {
        state.status.star = "failed";
        state.error.star = error.message as string;
      })
      .addCase(unstarCustomView.pending, (state) => {
        state.status.star = "loading";
      })
      .addCase(unstarCustomView.fulfilled, (state, { payload }) => {
        state.status.star = "succeeded";
        state.views = payload;
        state.viewsQueried = uniq(payload.map(({ table_id }) => table_id));
      })
      .addCase(unstarCustomView.rejected, (state, { error }) => {
        state.status.star = "failed";
        state.error.star = error.message as string;
      })
      // Delete view
      .addCase(deleteCustomView.pending, (state) => {
        state.status.delete = "loading";
      })
      .addCase(deleteCustomView.fulfilled, (state, { payload }) => {
        state.status.delete = "succeeded";
        state.views = payload;
        state.viewsQueried = uniq(payload.map(({ table_id }) => table_id));
      })
      .addCase(deleteCustomView.rejected, (state, { error }) => {
        state.status.delete = "failed";
        state.error.delete = error.message as string;
      });
  },
});

export const {
  resetListViewsStatus,
  resetAddViewStatus,
  resetEditViewStatus,
  resetStarViewStatus,
  resetDeleteViewStatus,
} = slice.actions;

export default slice.reducer;
