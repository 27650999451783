import React, { forwardRef } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import type { CheckboxProps } from "@mui/material/Checkbox";
import type { TableCellProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ConditionCell from "./ConditionCell";
import ClockIcon from "../../icons/Clock";

type Props = {
  noTable?: boolean;
  tooltip?: string;
  pendingChange?: boolean;
  endIcon?: React.ReactNode | null;
  tableCellProps?: TableCellProps | null;
} & CheckboxProps;

const CheckboxCell = forwardRef(
  (
    {
      noTable = false,
      style,
      className,
      tooltip,
      pendingChange,
      endIcon,
      tableCellProps,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const control = (
      <Box display="flex">
        {tooltip ? (
          <Tooltip arrow title={tooltip}>
            <span>
              <Checkbox
                ref={ref}
                color="secondary"
                {...props}
                style={{ marginRight: "auto" }}
              />
            </span>
          </Tooltip>
        ) : (
          <Checkbox
            ref={ref}
            color="secondary"
            {...props}
            style={{ marginRight: "auto" }}
          />
        )}
        {pendingChange !== undefined ? (
          <Box ml="auto" my="auto">
            <Tooltip
              arrow
              title={`Pending specification change to ${pendingChange}`}
            >
              <div style={{ height: 20, width: 24 }}>
                <ClockIcon style={{ width: 20, height: 20 }} />
              </div>
            </Tooltip>
          </Box>
        ) : null}
        {!!endIcon ? endIcon : null}
      </Box>
    );

    return noTable ? (
      <div style={{ ...style }} className={className || ""}>
        {control}
      </div>
    ) : (
      <ConditionCell
        style={{ ...style }}
        className={className || ""}
        {...tableCellProps}
      >
        {control}
      </ConditionCell>
    );
  }
);

export default CheckboxCell;
