import { forwardRef, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";
import client from "../../api";
import ConditionCell from "./ConditionCell";
import useAutocompleteStyles from "./useAutocompleteStyles";
import ClockIcon from "../../icons/Clock";
import colors from "../../theme/colors";
import { useDebouncedCallback } from "use-debounce";

const MetaCellInput = styled(TextField)({
  "& .pendingChange": {
    textDecoration: "line-through",
    "& .pendingIconContainer": {
      width: 24,
      height: 20,
      color: colors.text.primary,
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
  },
});

type Props = {
  noTable?: boolean;
  style?: React.CSSProperties;
  className?: string;
  value: User | null;
  pendingChange?: User | null;
  onBlur?: () => void;
  onChange: (user: User | null) => void;
  largeWidth?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  error?: boolean;
};

const UserCell = forwardRef(
  (
    {
      noTable = false,
      style,
      className,
      value,
      pendingChange,
      onBlur,
      onChange,
      largeWidth,
      disabled,
      clearable = false,
      error,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const classes = useAutocompleteStyles();
    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState<User[]>([]);
    const [search, setSearch] = useState("");

    const _handleLookup = useCallback(async () => {
      setLoading(true);

      try {
        const response = await client.get(
          `auth/users?name__contains=${search}`
        );
        setOptions(response.data);
      } catch (err) {
        setOptions([]);
      }

      setLoading(false);
    }, [search]);

    const handleLookup = useDebouncedCallback(_handleLookup, 400, {
      leading: true,
      trailing: true,
    });

    useEffect(() => {
      if (search !== "") {
        handleLookup();
      }
    }, [search, handleLookup]);

    const control = (
      <FormControl style={{ width: "100%", height: "100%" }}>
        <Autocomplete
          ref={ref}
          className={classes.root}
          disabled={disabled}
          disableClearable={!clearable}
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.user_id === value.user_id
          }
          value={value}
          onChange={(e, data) => onChange(data)}
          loading={loading}
          noOptionsText={
            search.length > 0 ? "No results found." : "Start typing..."
          }
          renderInput={(params) => (
            <MetaCellInput
              {...params}
              variant="outlined"
              size="small"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                className: `${params.InputProps.className || ""} ${
                  pendingChange !== undefined ? "pendingChange" : ""
                }`,
                endAdornment: (
                  <>
                    {pendingChange !== undefined ? (
                      <Tooltip
                        arrow
                        title={`Pending specification change to ${pendingChange}`}
                      >
                        <div className="pendingIconContainer">
                          <ClockIcon />
                        </div>
                      </Tooltip>
                    ) : null}
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment ? (
                      <div style={{ height: 20, width: 24 }}>
                        {params.InputProps.endAdornment}
                      </div>
                    ) : null}
                  </>
                ),
                value: search,
                onChange: (e) => setSearch(e.target.value),
                error,
              }}
            />
          )}
          renderOption={(props, opt) => (
            <li {...props}>
              <Box px={3} py={2} width="100%">
                <Typography
                  color="textPrimary"
                  className={opt.email ? "small" : undefined}
                >
                  {opt.name}
                </Typography>
                {opt.email ? (
                  <Typography color="textSecondary" className="tiny">
                    {opt.email}
                  </Typography>
                ) : null}
              </Box>
            </li>
          )}
          onBlur={onBlur}
        />
      </FormControl>
    );

    return noTable ? (
      <div style={style} className={className || ""}>
        {control}
      </div>
    ) : (
      <ConditionCell
        largeWidth={largeWidth}
        style={style}
        className={className || ""}
      >
        {control}
      </ConditionCell>
    );
  }
);

export default UserCell;
