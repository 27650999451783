import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import TimesIcon from "../icons/Times";
import colors from "../theme/colors";

const EnvironmentWarning = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (!visible) {
      const timeoutId = setTimeout(() => setVisible(true), 30 * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [visible]);

  if (!visible || process.env.REACT_APP_ENVIRONMENT === "production") {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      color="white"
      className="small qa-env-warning"
      style={{
        boxShadow: "0 0 5px rgb(0 0 0 / 10%)",
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        background: colors.accent.violet,
        left: 20,
        bottom: 0,
        position: "fixed",
        zIndex: Number.MAX_SAFE_INTEGER,
      }}
    >
      <Box my={2} ml={6} display="flex">
        <ExclamationCircleOutlinedIcon />
      </Box>
      <Box ml={4}>
        You’re currently in the {process.env.REACT_APP_ENVIRONMENT || "local"}{" "}
        environment. Changes here will not reflect in the production version of
        Oak.
      </Box>
      <Box mr={2}>
        <IconButton
          className="qa-close-env-warning"
          style={{ color: "white", background: "transparent" }}
          onClick={() => setVisible(false)}
        >
          <TimesIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default EnvironmentWarning;
