import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const CELL_SINGLE = "CELL_SINGLE";

export interface CellSingleState {
  cell: CellSingle | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    save: null | string;
  };
}

const initialState: CellSingleState = {
  cell: null,
  status: {
    get: "idle",
    save: "idle",
  },
  error: {
    get: null,
    save: null,
  },
};

export const getSingleCell = createAsyncThunk(
  `${CELL_SINGLE}/get`,
  async (cell_id: number) => {
    const response = await client.get(`meta/cells/${cell_id}`);
    return response.data;
  }
);

export const saveSingleCell = createAsyncThunk(
  `${CELL_SINGLE}/save`,
  async (cell: Cell) => {
    const response = await client.put(`meta/cells/${cell.cell_id}`, cell);
    return response.data;
  }
);

const filtersSlice = createSlice({
  name: CELL_SINGLE,
  initialState,
  reducers: {
    resetSingleCell: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      // Get single cell
      .addCase(getSingleCell.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSingleCell.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.cell = payload;
      })
      .addCase(getSingleCell.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Save single cell
      .addCase(saveSingleCell.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(saveSingleCell.fulfilled, (state, { payload }) => {
        state.status.save = "succeeded";
        state.cell = payload;
      })
      .addCase(saveSingleCell.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      });
  },
});

export const { resetSingleCell } = filtersSlice.actions;

export default filtersSlice.reducer;
