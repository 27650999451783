import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CharacterizationState,
  getSampleData,
  getSampleFormFields,
  resetGetMaterials,
  resetSamples,
} from "../slice";
import { RootState } from "../../../store";
import SamplesForm from "../SamplesForm";
import Loading from "../../../layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/router";
import samplesUtils from "../samplesUtils";

const EditSamplesLayout = () => {
  const { samples } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );
  const location = useLocation();
  const { from } = (location.state as LocationState) || {};
  const query = useQuery();
  const idParams = query.get("sample_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSampleFormFields());

    if (idParams) {
      dispatch(resetSamples());
      dispatch(resetGetMaterials());
      dispatch(getSampleData({ sampleIds: idParams }));
    } else {
      navigate("/characterization/new-samples");
    }
  }, [dispatch, query, idParams, navigate]);

  useEffect(() => {
    if (idParams && samples.length > 1) {
      const requestedSamples = samples.filter(({ sample_id }) =>
        idParams!.includes(`${sample_id as number}`)
      );
      if (!samplesUtils.allSameSampleTypes(requestedSamples)) {
        navigate(
          `/characterization/edit-samples?sample_id=${requestedSamples[0].sample_id}`
        );
      }
    }
  }, [samples, idParams, navigate]);

  return (
    <>
      {samples.length === 0 ? (
        <Loading light fullscreen />
      ) : (
        <SamplesForm from={from?.pathname} samplesToEdit={samples} />
      )}
    </>
  );
};

export default EditSamplesLayout;
