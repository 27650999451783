import { useSelector } from "react-redux";
import { Control, Controller } from "react-hook-form";

import type { RootState } from "../../store";

import colors from "../../theme/colors";
import Info from "../../icons/Info";
import MetaFilter from "../../components/table/MetaFilter";
import MinusCircleIcon from "../../icons/MinusCircle";

import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import type { SignalMapState } from "./slice";

type Props = {
  field: keyof SignalFormData;
  isAdd: boolean;
  isEdit: boolean;
  FIELD_TO_LABEL: { [key: string]: string };
  control: Control<SignalFormData, object>;
  removeField: (field: keyof SignalFormData) => void;
  measurementType: string;
  setMeasurementType: any;
  sensorType: string;
  setSensorType: any;
  allSensorTypes: AllSensorTypes;
  allDeviceClasses: string[];
  allSignalGroups: { [key: string]: number | string }[];
};

const SignalsModalInput = ({
  field,
  isAdd,
  isEdit,
  control,
  FIELD_TO_LABEL,
  removeField,
  measurementType,
  setMeasurementType,
  sensorType,
  setSensorType,
  allSensorTypes,
  allDeviceClasses,
  allSignalGroups,
}: Props) => {
  const PATTERN_FIELDS = ["namingPattern", "addressPattern"];
  const THERMOCOUPLE_TYPES = ["J", "K", "T", "E"];
  const SIGNAL_RANGES = ["0.1", "1", "10", "100", "300"];
  const COIL_OPTIONS = [
    { key: "Register", value: false },
    { key: "Coil", value: true },
  ];
  const isRequired = [
    "measurementType",
    "sensorType",
    "deviceClass",
    "namingPattern",
    "addressPattern",
  ].includes(field);

  const { signalMap } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  // Dynamically set controller for the input
  let controller;
  switch (field) {
    // Measurement Type
    case "measurementType":
      controller = (
        <Controller
          control={control}
          name={field}
          rules={{ required: isRequired }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Box display="flex" alignItems="center">
              <Select
                value={value}
                error={invalid}
                onChange={(event) => {
                  let mType = event.target.value;
                  setMeasurementType(mType);
                  let keys = Object.keys(allSensorTypes[mType]);
                  if (keys.length === 1) {
                    // Auto-fill the sensor type if there's only 1 option
                    setSensorType(keys[0]);
                  } else {
                    // Otherwise, clear the value
                    setSensorType("");
                  }
                  onChange(event);
                }}
                style={{ width: "100%" }}
              >
                {Object.keys(allSensorTypes).map((measurementType, index) => (
                  <MenuItem key={index} value={measurementType}>
                    {measurementType}
                  </MenuItem>
                ))}
              </Select>

              {isEdit && (
                <Tooltip
                  arrow
                  title="Removing this field will also remove sensor type, device class, and units"
                  placement="right"
                >
                  <IconButton size="small" onClick={() => removeField(field)}>
                    <MinusCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        />
      );
      break;
    // Dropdowns
    case "sensorType":
    case "deviceClass":
    case "thermocouple_type":
    case "signal_range":
    case "is_coil":
      controller = (
        <Controller
          control={control}
          name={field}
          rules={{ required: isRequired }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Box display="flex" alignItems="center">
              <Select
                value={value}
                error={invalid}
                disabled={
                  field === "sensorType" ? measurementType === "" : false
                }
                onChange={(e) => {
                  field === "sensorType" && setSensorType(e.target.value);
                  onChange(e);
                }}
                style={{ width: "100%" }}
              >
                {field === "sensorType"
                  ? measurementType !== "" &&
                    Object.keys(allSensorTypes[measurementType]).map(
                      (sensorType, index) => (
                        <MenuItem key={index} value={sensorType}>
                          {sensorType}
                        </MenuItem>
                      )
                    )
                  : field === "thermocouple_type"
                  ? THERMOCOUPLE_TYPES.map((t, index) => (
                      <MenuItem key={index} value={t}>
                        {t}
                      </MenuItem>
                    ))
                  : field === "signal_range"
                  ? SIGNAL_RANGES.map((sr, index) => (
                      <MenuItem key={index} value={sr}>
                        {`${sr} V`}
                      </MenuItem>
                    ))
                  : field === "is_coil"
                  ? COIL_OPTIONS.map((opt, index) => (
                      <MenuItem key={index} value={opt.value as any}>
                        {opt.key}
                      </MenuItem>
                    ))
                  : allDeviceClasses.map((deviceType, index) => (
                      <MenuItem key={index} value={deviceType}>
                        {deviceType}
                      </MenuItem>
                    ))}
              </Select>

              {isEdit && field !== "sensorType" && (
                <IconButton size="small" onClick={() => removeField(field)}>
                  <MinusCircleIcon />
                </IconButton>
              )}
            </Box>
          )}
        />
      );
      break;
    // Cell Position ID
    case "cell_id":
      controller = (
        <Controller
          control={control}
          name={field}
          rules={{ required: isRequired }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Box display="flex" alignItems="center">
              <MetaFilter
                valueKey={"cell_position_id"}
                descriptionKey={"cell_id"}
                multiple={false}
                removable={false}
                endpoint="meta/cells/by-position-id"
                prefix="Position "
                value={value}
                onChange={onChange}
                queryParameters={{ module_id: signalMap?.dut_id }}
                style={{ width: "100%" }}
                error={invalid}
              />

              {isEdit && (
                <IconButton size="small" onClick={() => removeField(field)}>
                  <MinusCircleIcon />
                </IconButton>
              )}
            </Box>
          )}
        />
      );
      break;
    // Signal Group
    case "signal_group_id":
      controller = (
        <Controller
          control={control}
          name={field}
          rules={{ required: isRequired }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Box display="flex" alignItems="center">
              <Select
                value={value}
                error={invalid}
                onChange={onChange}
                style={{ width: "100%" }}
              >
                {allSignalGroups.map((sg, i) => (
                  <MenuItem key={i} value={sg.signal_group_id}>
                    {sg.name}
                  </MenuItem>
                ))}
              </Select>

              {isEdit && (
                <IconButton size="small" onClick={() => removeField(field)}>
                  <MinusCircleIcon />
                </IconButton>
              )}
            </Box>
          )}
        />
      );
      break;
    // Checkbox
    case "big_endian":
      controller = (
        <Controller
          control={control}
          name={field}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) => (
            <Checkbox
              color="secondary"
              sx={{ width: "fit-content", marginLeft: "-10px" }}
              value={value}
              checked={value}
              onChange={(val) => onChange(val)}
            />
          )}
        />
      );
      break;
    // Inputs
    default:
      controller = (
        <Controller
          control={control}
          name={field}
          rules={{ required: isRequired }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid },
          }) =>
            field === "units" ? (
              <Tooltip
                arrow
                title="Units are determined by sensor type and cannot be changed"
                placement="right"
              >
                <Input
                  className="small"
                  disableUnderline
                  disabled={true}
                  inputRef={ref}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={
                    measurementType !== "" && sensorType !== ""
                      ? allSensorTypes[measurementType][sensorType]["units"]
                      : "-"
                  }
                />
              </Tooltip>
            ) : (
              <Box display="flex" alignItems="center">
                <Input
                  className="small"
                  disableUnderline
                  inputRef={ref}
                  error={invalid}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputProps={{ maxLength: 100 }}
                  style={{ width: "100%" }}
                />

                {isEdit && (
                  <IconButton size="small" onClick={() => removeField(field)}>
                    <MinusCircleIcon />
                  </IconButton>
                )}
              </Box>
            )
          }
        />
      );
  }

  return (
    <>
      <Typography className="small" color="textSecondary" mt={5}>
        {FIELD_TO_LABEL[field as string]}
        {isRequired && <span style={{ color: colors.accent.red }}>*</span>}
        {PATTERN_FIELDS.includes(field) && (
          <Tooltip
            arrow
            title="This field can take semicolon-separated values and ranges"
            placement="right"
          >
            <span>
              <Info style={{ marginLeft: "5px" }} />
            </span>
          </Tooltip>
        )}
      </Typography>

      <FormControl style={{ width: "100%", height: "100%" }}>
        {controller}
      </FormControl>
    </>
  );
};

export default SignalsModalInput;
