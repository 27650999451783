import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import client from "../../api";
import { CellListState, cellsSlices } from "./slice";
import Modal from "../../components/Modal";
import colors from "../../theme/colors";
import Button from "../../components/Button";
import {
  CELL_LABELS_ALL,
  cellIdToString,
  TEST_STAND_LABELS_ALL,
} from "../../utils/labels";
import ConditionCell from "../../components/forms/ConditionCell";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import TestStandReservationTableCell from "../test-stands/TestStandReservationTableCell";
import TestStandDropdown from "../test-stands/TestStandDropdown";
import { useChannelCellStyles } from "./ReserveChannelsModal";

type Props = {
  open: boolean;
  onClose: () => void;
  stateKey: keyof typeof cellsSlices;
  selected: Cell[];
};

const TEST_STAND_RESERVATION_TABLE_KEYS = [
  "condition__cell_assembly",
  "anode_current_sensors",
  "h2_sensor_type",
  "temperature_range",
  "gde_air_range",
  "sweep_air_range",
  "max_current_limit",
  "daq_channels",
  "on_test__number_of_sampling_ports",
  "on_test__number_of_thermocouples_needed",
  "on_test__reference_electrode_type_continuous",
  "on_test__reference_electrode_type_intermittent",
  // items starting with "dual_oee" are considered test vehicles
  "dual_oee",
  "one_half_cell",
  "two_half_cells",
  "proto_1",
  "proto_2",
  "evt",
  "megasus",
  "flex_cell",
  "vehicle_303",
];

const BEGINNING_TEST_VEHICLE_INDEX =
  TEST_STAND_RESERVATION_TABLE_KEYS.indexOf("dual_oee");

const ReserveTestStandsModal = ({
  open,
  onClose,
  stateKey,
  selected,
}: Props) => {
  const classes = useChannelCellStyles();
  const dispatch = useDispatch();

  const [testStandSelection, setTestStandSelection] = useState<
    Record<string, TestStand | null>
  >({});
  const [initialLoadStatus, setInitialLoadStatus] = useState<
    "loading" | "complete" | null
  >(null);

  const {
    status: { testStandReservation: testStandRes },
    error: { testStandReservation: error },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );
  const cellIds = selected.map(({ cell_id }) => cell_id);

  const loadReservationsForCells = useCallback(async () => {
    setInitialLoadStatus("loading");
    try {
      let endpoint = "meta/test-stand-reservations?";
      cellIds.forEach((cellId_) => {
        endpoint += `cell_id=${cellId_}&`;
      });

      const response: { data: TestStandReservation[] } = await client.get(
        endpoint
      );
      let loadedTestStandSelection: { [cellId: number]: TestStand } = {};
      cellIds.forEach((cellId_) => {
        const reservedTestStand = response.data.find(
          (testStandRes) => testStandRes.cell_id === cellId_
        )?.test_stand;
        if (reservedTestStand) {
          loadedTestStandSelection[cellId_] = reservedTestStand;
        }
      });
      setTestStandSelection(loadedTestStandSelection);
    } catch (err) {
      setTestStandSelection({});
    }

    setInitialLoadStatus("complete");
  }, [cellIds]);

  const handleModalClose = useCallback(() => {
    setTestStandSelection({});
    setInitialLoadStatus(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (open && !initialLoadStatus) {
      dispatch(cellsSlices[stateKey].resetChannelReservation());
      if (cellIds.length > 0) {
        loadReservationsForCells();
      }
    }
  }, [
    open,
    dispatch,
    stateKey,
    cellIds,
    initialLoadStatus,
    loadReservationsForCells,
  ]);

  useEffect(() => {
    if (open && testStandRes === "succeeded") {
      handleModalClose();
    }
  }, [open, testStandRes, handleModalClose]);

  return (
    <Modal open={open} onClose={handleModalClose} maxWidth={false}>
      <Typography variant="h2">Reserve test stands</Typography>

      {initialLoadStatus !== "loading" ? (
        <TableContainer>
          <Table
            className={`dataTable ${classes.root}`}
            size="small"
            style={{ background: colors.striping, width: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ background: "white" }} />
                {cellIds.map((cell_id, index) => (
                  <TableCell key={index} style={{ background: "white" }}>
                    <Box style={{ display: "flex" }}>
                      <Box style={{ flexGrow: 1 }}>
                        {cellIdToString(cell_id)}
                      </Box>
                      {!!testStandSelection[cell_id] && (
                        <Box style={{ marginRight: 12, flexGrow: 1 }}>
                          {testStandSelection[cell_id]?.identifier}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Reserved Test Stand</TableCell>
                {cellIds.map((cell_id, index) => (
                  <ConditionCell
                    key={index}
                    style={{ background: "white" }}
                    padding="none"
                  >
                    <TestStandDropdown
                      value={testStandSelection[cell_id] || null}
                      onChange={(testStand) => {
                        setTestStandSelection((prevTestStandSelection) => ({
                          ...prevTestStandSelection,
                          [cell_id]: testStand,
                        }));
                      }}
                    />
                  </ConditionCell>
                ))}
              </TableRow>
              {TEST_STAND_RESERVATION_TABLE_KEYS.map((key, index) => {
                if (
                  index >= BEGINNING_TEST_VEHICLE_INDEX &&
                  Object.keys(testStandSelection).every(
                    (cellId) =>
                      !testStandSelection[cellId] ||
                      !testStandSelection[cellId]![key as keyof TestStand]
                  )
                )
                  return null;
                return (
                  <TableRow key={`${key}-${index}-row`}>
                    <TableCell variant="head">
                      {TEST_STAND_LABELS_ALL.find(({ id }) => id === key)
                        ?.label ||
                        CELL_LABELS_ALL.find(({ id }) => id === key)?.label}
                    </TableCell>
                    {selected.map((cell, index) => (
                      <TestStandReservationTableCell
                        key={index}
                        dataKey={
                          key as
                            | FalconKey<TestStand>
                            | "condition__cell_assembly"
                        }
                        testStand={testStandSelection[cell.cell_id]}
                        cellData={cell}
                      />
                    ))}
                  </TableRow>
                );
              })}
              {Object.keys(testStandSelection).length > 0 && (
                <TableRow>
                  <TableCell variant="head">Active reservations</TableCell>
                  {selected.map((cell, index) => (
                    <TestStandReservationTableCell
                      key={index}
                      dataKey="active_reservations"
                      testStand={testStandSelection[cell.cell_id]}
                      cellData={cell}
                    />
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}

      {testStandRes === "failed" ? (
        <Box my={4} color={colors.accent.red} textAlign="right">
          {error}
        </Box>
      ) : null}

      <Box mt={6} display="flex">
        <Box ml="auto" mr={2}>
          <Button color="secondary" size="small" onClick={handleModalClose}>
            Cancel
          </Button>
        </Box>
        <Button
          color="cta"
          size="small"
          disabled={
            testStandRes === "loading" ||
            Object.keys(testStandSelection).filter(
              (key: any) =>
                !(
                  testStandSelection[key] === null ||
                  testStandSelection[key]?.name === null
                )
            ).length !== selected.length
          }
          startIcon={
            testStandRes === "loading" ? (
              <CircularProgress color="inherit" size={20} />
            ) : null
          }
          onClick={() =>
            dispatch(
              cellsSlices[stateKey].reserveTestStands(testStandSelection)
            )
          }
        >
          Reserve test stands
        </Button>
      </Box>
    </Modal>
  );
};

export default ReserveTestStandsModal;
