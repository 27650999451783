import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  cellIdToString,
  getInitialsForUser,
  getNoteLabelForResource,
  isoDateToDateString,
  isoDateToTimeString,
} from "../../utils/labels";
import CaretBottomIcon from "../../icons/CaretBottom";
import TimesIcon from "../../icons/Times";
import colors from "../../theme/colors";
import type { RootState } from "../../store";
import { getNotes, resetGetNotesStatus } from "./slice";
import type { NotesState } from "./slice";
import PencilWriteIcon from "../../icons/PencilWrite";
import NoteViewSidePanel from "./NoteViewSidePanel";
import NoteFormSidePanel from "./NoteFormSidePanel";
import PaperWriteIcon from "../../icons/PaperWrite";

type Props = {
  resource: NoteItemRelation["resource"];
  record_ids: number[];
  noteId?: number;
  open: boolean;
  onCreate?: () => void;
  onClose: () => void;
};

const NoteListSidePanel = ({
  resource,
  record_ids,
  noteId: presetNoteId,
  open,
  onCreate,
  onClose,
}: Props) => {
  const isTablet = useMediaQuery("(min-width:760px)");
  const label = getNoteLabelForResource(resource);

  // Note state in redux
  const {
    notes,
    status: { get: getStatus },
    error: { get: getError },
  } = useSelector<RootState, NotesState>(({ notes }) => notes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(resetGetNotesStatus());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (getStatus === "idle" && open) {
      dispatch(
        getNotes(record_ids.map((record_id) => ({ resource, record_id })))
      );
    }
  }, [dispatch, open, getStatus, resource, record_ids]);

  let lastDateHeader = "";

  // Single view
  const [singleNoteId, setSingleNoteId] = useState<number | null>(
    presetNoteId || null
  );
  const singleNote = notes.find(
    ({ note }) => note.note_id === singleNoteId
  )?.note;

  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setEditing(false);
  }, [singleNoteId]);
  useEffect(() => {
    setSingleNoteId(presetNoteId || null);
  }, [presetNoteId]);

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop
        open={open}
        onClose={onClose}
        PaperProps={{ style: isTablet ? { width: 400 } : { width: "100%" } }}
      >
        <Box px={6} pb={4} pt={4} borderBottom={`1px solid ${colors.rules}`}>
          <Box display="flex" alignItems="center" width="100%">
            <Box mr="auto">
              <Typography variant="h3">Notes</Typography>
            </Box>
            {onCreate ? (
              <IconButton size="small" onClick={onCreate}>
                <PencilWriteIcon style={{ width: 20, height: 20 }} />
              </IconButton>
            ) : null}
            <IconButton size="small" onClick={onClose}>
              <TimesIcon style={{ width: 20, height: 20 }} />
            </IconButton>
          </Box>
        </Box>

        <Box px={6} pb={4}>
          {getError ? (
            <Box my={4} color={colors.accent.red} className="small">
              {getError}
            </Box>
          ) : null}

          {notes.length === 0 ? (
            <Box
              p={10}
              textAlign="center"
              style={{ color: colors.text.secondary }}
            >
              <PaperWriteIcon stroke="1" style={{ width: 45, height: 45 }} />
              <p>No notes to show</p>
            </Box>
          ) : null}

          {notes.map(({ note }, index) => {
            const dateHeader = isoDateToDateString(note.created_time);
            const dateHeaderChanged = dateHeader !== lastDateHeader;
            lastDateHeader = dateHeader;

            return (
              <React.Fragment key={index}>
                {dateHeaderChanged ? (
                  <Box mt={4} display="flex" alignItems="center">
                    <Divider style={{ flexGrow: 1 }} />
                    <Box mx={2}>
                      <Typography className="small">
                        {isoDateToDateString(note.created_time)}
                      </Typography>
                    </Box>
                    <Divider style={{ flexGrow: 1 }} />
                  </Box>
                ) : null}

                <Box
                  mt={dateHeaderChanged ? 4 : 6}
                  display="flex"
                  alignItems="flex-start"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSingleNoteId(note.note_id!)}
                >
                  <Avatar>{getInitialsForUser(note.user)}</Avatar>
                  <Box ml={3} width="100%">
                    <Box display="flex" width="100%">
                      <Box mr={4}>{note.user?.name}</Box>
                      <Typography color="textSecondary" className="small">
                        {isoDateToTimeString(note.created_time)}
                      </Typography>
                      <Box ml="auto">
                        <CaretBottomIcon
                          style={{
                            color: colors.text.secondary,
                            transform: "rotate(-90deg)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box my={2} display="flex" alignItems="center">
                      <Typography className="small" color="textSecondary">
                        {label}
                        {note.note_items.length !== 1 ? "s" : ""}:
                      </Typography>
                      <Box ml={2} className="small">
                        {note.note_items
                          .map(({ record_id }) => cellIdToString(record_id))
                          .join(", ")}
                      </Box>
                    </Box>
                    <Typography
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {note.description}
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </Drawer>

      <NoteViewSidePanel
        label={label}
        note={singleNote}
        open={singleNoteId !== null}
        onEdit={() => setEditing(true)}
        onClose={() => setSingleNoteId(null)}
      />

      <NoteFormSidePanel
        note={singleNote}
        resource={resource}
        options={singleNote?.note_items.map(({ record_id }) => record_id) || []}
        open={editing}
        onClose={() => setEditing(false)}
      />
    </>
  );
};

export default NoteListSidePanel;
