import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Button from "../../../components/Button";
import CaretBottomIcon from "../../../icons/CaretBottom";
import CheckCircleIcon from "../../../icons/CheckCircle";
import NavigationMenuHorizontalIcon from "../../../icons/NavigationMenuHorizontal";
import colors from "../../../theme/colors";
import {
  cellStatusToString,
  getInitialsForUser,
  isoDateToDateTimeString,
} from "../../../utils/labels";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import { hasPassedStatus } from "../../../utils/statuses";

type Props = {
  status: CellStatus;
  minimumStatus: CellStatus;
  maximumStatus: CellStatus;
  onView?: () => void;
  onEdit?: () => void;
  onUndo: () => void;
  completed_at: string;
  completed_by: User;
};

const CompletedCell = ({
  status,
  minimumStatus,
  maximumStatus,
  onView,
  onEdit,
  onUndo,
  completed_at,
  completed_by,
}: Props) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLButtonElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setButtonEl(event.currentTarget);
  };
  const handleButtonClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setButtonEl(null);
  };
  const [buttonMenuEl, setButtonMenuEl] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const handleButtonMenuClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setButtonMenuEl(event.currentTarget);
  };
  const handleButtonMenuClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setButtonMenuEl(null);
  };

  // Metadata state in redux
  const {
    status: { complete: completeStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  const [undoing, setUndoing] = useState(false);
  const handleUndo = () => {
    setUndoing(true);
    onUndo();
  };

  useEffect(() => {
    if (completeStatus === "succeeded") {
      setUndoing(false);
    }
  }, [completeStatus]);

  const disabled =
    !hasPassedStatus(minimumStatus, status) ||
    hasPassedStatus(maximumStatus, status, false);

  const inlineOptions = (
    <Box ml="auto" display="flex" alignItems="center">
      <Tooltip
        arrow
        title={
          !hasPassedStatus(minimumStatus, status)
            ? `This step cannot be undone as the cell hasn't reached "${cellStatusToString(
                minimumStatus
              )}"`
            : hasPassedStatus(maximumStatus, status, false)
            ? `This step cannot be undone as the cell is beyond "${cellStatusToString(
                maximumStatus
              )}"`
            : ""
        }
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
      >
        <span>
          <Button
            color="tertiary"
            type="button"
            size="small"
            disabled={completeStatus === "loading" || disabled}
            endIcon={
              undoing ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: 16, height: 16 }}
                />
              ) : undefined
            }
            style={{
              padding: "0.5rem",
              color: colors.text.secondary,
            }}
            onClick={handleUndo}
          >
            <b>Undo</b>
          </Button>
        </span>
      </Tooltip>

      {onEdit && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: 20, margin: "auto" }}
          />

          <Tooltip
            arrow
            title={
              !hasPassedStatus(minimumStatus, status)
                ? `This step cannot be edited as the cell hasn't reached "${cellStatusToString(
                    minimumStatus
                  )}"`
                : hasPassedStatus(maximumStatus, status, false)
                ? `This step cannot be edited as the cell is beyond "${cellStatusToString(
                    maximumStatus
                  )}"`
                : ""
            }
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
          >
            <span>
              <Button
                color="tertiary"
                type="button"
                size="small"
                disabled={disabled}
                style={{
                  padding: "0.5rem",
                }}
                onClick={onEdit}
              >
                <b>Edit</b>
              </Button>
            </span>
          </Tooltip>
        </>
      )}
    </Box>
  );

  const menuOptions = (
    <Box ml="auto" display="flex" alignItems="center">
      <IconButton
        size="small"
        style={{ pointerEvents: "auto" }}
        disabled={disabled}
        onClick={handleButtonMenuClick}
      >
        <NavigationMenuHorizontalIcon style={{ width: 16, height: 16 }} />
      </IconButton>
    </Box>
  );

  return (
    <Box
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton size="small" onClick={handleButtonClick}>
        <CheckCircleIcon
          style={{
            width: 20,
            height: 20,
            color: colors.accent.green,
          }}
        />
        <CaretBottomIcon
          style={{
            marginLeft: "0.5rem",
            width: 16,
            height: 16,
            color: colors.text.secondary,
            transform: !buttonEl ? "rotate(-90deg)" : undefined,
          }}
        />
      </IconButton>

      {!onView ? inlineOptions : menuOptions}

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ListItem>
          <Box display="flex" alignItems="center">
            <Avatar className="bg-cta">
              {getInitialsForUser(completed_by)}
            </Avatar>
            <Box ml={3} mr={2}>
              {completed_by.name}
            </Box>
            <Box color={colors.text.secondary}>
              {isoDateToDateTimeString(completed_at)}
            </Box>
          </Box>
        </ListItem>
      </Menu>

      {onView ? (
        <Menu
          anchorEl={buttonMenuEl}
          open={!!buttonMenuEl}
          onClose={handleButtonMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={(e) => {
              handleButtonMenuClose(e);
              onView();
            }}
          >
            View
          </MenuItem>
          {onEdit && (
            <Tooltip
              arrow
              title={
                !hasPassedStatus(minimumStatus, status)
                  ? `This step cannot be edited as the cell hasn't reached "${cellStatusToString(
                      minimumStatus
                    )}"`
                  : hasPassedStatus(maximumStatus, status, false)
                  ? `This step cannot be edited as the cell is beyond "${cellStatusToString(
                      maximumStatus
                    )}"`
                  : ""
              }
              disableFocusListener={!disabled}
              disableHoverListener={!disabled}
            >
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleButtonMenuClose(e);
                    onEdit();
                  }}
                  disabled={disabled}
                >
                  Edit
                </MenuItem>
              </span>
            </Tooltip>
          )}

          <Tooltip
            arrow
            title={
              !hasPassedStatus(minimumStatus, status)
                ? `This step cannot be undone as the cell hasn't reached "${cellStatusToString(
                    minimumStatus
                  )}"`
                : hasPassedStatus(maximumStatus, status, false)
                ? `This step cannot be undone as the cell is beyond "${cellStatusToString(
                    maximumStatus
                  )}"`
                : ""
            }
            disableFocusListener={disabled}
            disableHoverListener={disabled}
          >
            <span>
              <MenuItem
                onClick={(e) => {
                  handleButtonMenuClose(e);
                  onUndo();
                }}
                disabled={disabled}
              >
                Undo
              </MenuItem>
            </span>
          </Tooltip>
        </Menu>
      ) : null}
    </Box>
  );
};

export default CompletedCell;
