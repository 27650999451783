import { Controller, useFormContext, useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import colors from "../../../theme/colors";
import TdCell from "../../../components/forms/TdCell";

type Props = {
  field: ConditionUIField;
  value?: string;
};

const TeardownField = ({ field, value }: Props) => {
  const { control } = useFormContext();

  let depName = "";
  if (field.enabled_by) {
    // Special handling for engineer flag
    depName = field.enabled_by.id;
  }

  const depState = useWatch({
    control,
    name: depName,
    disabled: depName.length === 0,
  });

  const allChecked = !!depState === true;
  const allUnchecked = !!depState === false;

  const rowDependencyHidden =
    field.enabled_by &&
    !field.enabled_by.id.includes("engineer_flag") &&
    (field.enabled_by.conditional_val && depState
      ? typeof depState === "string"
        ? depState !== field.enabled_by!.conditional_val
        : Object.values(depState).every(
            (val) => val !== field.enabled_by!.conditional_val
          )
      : field.enabled_by.conditional_vals && depState
      ? typeof depState === "string"
        ? !field.enabled_by!.conditional_vals.includes(depState)
        : Object.values(depState).every(
            (val) =>
              !field.enabled_by!.conditional_vals!.includes(val as string)
          )
      : field.enabled_by.neg
      ? allChecked
      : allUnchecked);

  return (
    <div style={rowDependencyHidden ? { display: "none" } : {}}>
      <Box mt={6} mb={2} display="flex" alignItems="flex-end">
        <Typography color="textSecondary" className="small">
          {field.label}{" "}
          {field.required ? (
            <span style={{ color: colors.accent.red }}>*</span>
          ) : null}
        </Typography>
      </Box>

      <Controller
        name={field.id}
        control={control}
        defaultValue={value}
        rules={{
          validate: (value) => {
            if (field.required) {
              if (field.type.includes("*")) {
                return rowDependencyHidden || !value.includes(null);
              } else if (field.type === "datetime") {
                return value !== null;
              }
              return rowDependencyHidden || value !== null;
            } else {
              return true;
            }
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid },
        }) => (
          <TdCell
            ref={ref}
            noTable
            name={name}
            uiField={field}
            conditionKey=""
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={invalid}
          />
        )}
      />
    </div>
  );
};

export default TeardownField;
