import { DateTime, Interval } from "luxon";
import { CellFilterArgs } from "../features/cells/slice";

const assembleCellsQuery = (
  { refresh, pageSize, columns, ...args }: CellFilterArgs,
  noLimit = false
) => {
  const pgSize = pageSize as number;

  let pageArg;
  if (noLimit) {
    pageArg = "&__offset=0&__limit=100000";
  } else {
    pageArg = refresh
      ? `&__offset=0&__limit=${(args.page + 1) * pgSize}`
      : `&__offset=${args.page * pgSize}&__limit=${pgSize}`;
  }
  let columnsArg = "";
  const currentColumns = columns || [];

  columnsArg += currentColumns
    .map((column_) => `&view_column=${column_}`)
    .join("");

  const sortArg = `&__sort=${args.order === "desc" ? "-" : ""}${
    args.orderBy === "status" ? "status_order" : args.orderBy
  },-condition__cell_condition_id${
    args.orderBy === "status" ? "" : ",-status_order"
  }${args.orderBy === "cell_id" ? "" : ",cell_id"}`;

  let filterArg = "";

  for (let key of Object.keys(args.filters)) {
    if (key === "columns") {
      continue;
    }
    if (key === "experiment__owner") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map((sel) => `&owner__user_id=${sel.user_id}`)
        .join("");
      continue;
    }

    if (key === "experiment__project") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map((sel) => `&project__project_id=${sel.project_id}`)
        .join("");
      continue;
    }

    if (key === "channel__fullname" || key === "reserved_channel__fullname") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map((sel) => `&${key}=${encodeURIComponent(sel.channel_fullname)}`)
        .join("");
      continue;
    }

    if (key === "condition__name") {
      filterArg += `&${key}__contains=${args.filters[key]}`;
      continue;
    }

    if (
      key === "condition__specified" ||
      key === "committed" ||
      key === "test__created_time" ||
      key === "condition__plan_test_start_date"
    ) {
      const selected = args.filters[key] || [];
      const [start, end] = [
        selected[0]
          ? DateTime.fromFormat(selected[0] as string, "yyyy-MM-dd")
          : DateTime.fromMillis(0),
        selected[1]
          ? DateTime.fromFormat(selected[1] as string, "yyyy-MM-dd").plus({
              days: 1,
            })
          : DateTime.local(),
      ];

      const interval = Interval.fromDateTimes(start, end);
      if (interval.isValid) {
        filterArg += `&${key}__gte=${start.toUTC().toISO()}`;
        filterArg += `&${key}__lt=${end.toUTC().toISO()}`;
        continue;
      }
    }

    if (
      key === "gde__icm_pcm" ||
      key === "oee__icm_pcm" ||
      key === "anode__part_number" ||
      key === "anode__icm_pcm" ||
      key === "anode__additive_icm_pcm" ||
      key === "electrolyte__icm_pcm"
    ) {
      const selected = args.filters[key] || [];
      filterArg += selected.map((sel) => `&${key}=${sel.material_id}`).join("");
      continue;
    }

    if (key === "on_test__cycling_protocol") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map((sel) => `&${key}_id=${sel.cycling_protocol_id}`)
        .join("");
      continue;
    }

    if (key === "engineer_flag") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map((_filter) => `&${_filter[0]}=${_filter[1]}`)
        .join();
      continue;
    }

    if (key === "deviations_from_sop") {
      const selected = args.filters[key] || [];
      filterArg += selected
        .map(
          (_filter) =>
            `&${_filter[0]?.replaceAll("engineer_flag", "non_standard")}=${
              _filter[1]
            }`
        )
        .join();
      continue;
    }

    if (key === "teardown__flags") {
      const selected = args.filters[key] || [];
      filterArg += selected.map((sel) => `&teardown__${sel}=1`).join("");
      continue;
    }

    const selected = (args.filters[key as keyof CellFilters] as string[]) || [];
    filterArg += selected.map((sel) => `&${key}=${sel}`).join("");
  }

  if (args.presetFilters) {
    for (let key of Object.keys(args.presetFilters)) {
      if (Object.keys(args.filters).includes(key)) {
        // We are enforcing exclusive filters on the frontend
        continue;
      }

      if (key === "experiment__exp_id") {
        const selected = args.presetFilters.experiment__exp_id || [];
        filterArg += selected
          .map((sel) => `&experiment__exp_id=${sel}`)
          .join("");
        continue;
      }

      const selected =
        (args.presetFilters[key as keyof CellFilters] as string[]) || [];
      filterArg += selected.map((sel) => `&${key}=${sel}`).join("");
    }
  }
  return `${sortArg}${pageArg}${filterArg}${columnsArg}`;
};

export default assembleCellsQuery;
