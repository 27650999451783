import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { isNil, isNull, uniq } from "lodash";
import client from "../../api";

export const SIGNAL_MAP = "SIGNAL_MAP";

export interface SignalMapState {
  dutType: "cell" | "serial_hphc" | "module";
  signalMap: SignalMap | null;
  signals: Signal[];
  faultConditions: FaultCondition[];
  moduleName: string | null;
  isSerialHPHC: boolean;
  isMostRecentSignalMap: boolean;
  allTestData: {
    [key: string]: number;
  };
  selected: {
    signals: number[];
    faultConditions: number[];
  };
  order: {
    signal: "asc" | "desc";
    faultCondition: "asc" | "desc";
  };
  orderBy: {
    signal: FalconKey<Signal>;
    faultCondition: FalconKey<FaultCondition>;
  };
  visible: {
    signals: number[];
    faultConditions: number[];
  };
  status: {
    create: "idle" | "loading" | "succeeded" | "failed";
    get: "idle" | "loading" | "succeeded" | "failed";
    update: "idle" | "loading" | "succeeded" | "failed";
    delete: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    create: null | string;
    get: null | string;
    update: null | string;
    delete: null | string;
  };
}

const initialState: SignalMapState = {
  dutType: "cell",
  signalMap: null,
  signals: [],
  faultConditions: [],
  moduleName: null,
  isSerialHPHC: false,
  isMostRecentSignalMap: true,
  allTestData: {},
  selected: {
    signals: [],
    faultConditions: [],
  },
  order: {
    signal: "asc",
    faultCondition: "asc",
  },
  orderBy: {
    signal: "device_address",
    faultCondition: "expression_string",
  },
  visible: {
    signals: [],
    faultConditions: [],
  },
  status: {
    create: "idle",
    get: "idle",
    update: "idle",
    delete: "idle",
  },
  error: {
    create: null,
    get: null,
    update: null,
    delete: null,
  },
};

export const createSignalMap = createAsyncThunk(
  `${SIGNAL_MAP}/create`,
  async (data: any) => {
    const response = await client.post(
      `meta/signal-maps/${data["dut_id"]}`,
      data
    );
    return response;
  }
);

export const getSignalMap = createAsyncThunk(
  `${SIGNAL_MAP}/get`,
  async (obj: { dut_id: number; dutType: string; test_id: string | null }) => {
    let url = `meta/signal-maps/${obj["dut_id"]}?dut_type=${obj["dutType"]}`;
    if (!isNull(obj["test_id"])) {
      url += `&test_id=${obj["test_id"]}`;
    }
    const response = await client.get(url);
    return response;
  }
);

export const updateSignalMap = createAsyncThunk(
  `${SIGNAL_MAP}/update`,
  async (
    obj: {
      test_id: string;
      selected?: number[];
      objectURLStr: "signals" | "fault-conditions";
      formData: SignalFormData | FaultConditionFormData;
    },
    { getState }
  ) => {
    const state: any = getState();
    const signalState: SignalMapState = state.signalMapSingle;
    const response = await client.put(
      `meta/signal-maps/${signalState.signalMap?.dut_id}/${obj.objectURLStr}` +
        `?dut_type=${signalState.dutType}`,
      obj
    );
    return response;
  }
);

export const deleteSignals = createAsyncThunk(
  `${SIGNAL_MAP}/signals/delete`,
  async (obj, { getState }) => {
    const state: any = getState();
    const signalState: SignalMapState = state.signalMapSingle;
    let endpoint =
      `meta/signal-maps/${signalState.signalMap?.dut_id}/signals?` +
      `dut_type=${signalState.signalMap?.dut_type}&` +
      `selected=${signalState.selected.signals.join(",")}`;
    let test_id = signalState.signalMap?.test_id;
    if (!isNull(test_id)) {
      endpoint += `&test_id=${test_id}`;
    }
    const response = await client.delete(endpoint);
    return response;
  }
);

export const deleteFaultConditions = createAsyncThunk(
  `${SIGNAL_MAP}/fault-conditions/delete`,
  async (obj, { getState }) => {
    const state: any = getState();
    const signalState: SignalMapState = state.signalMapSingle;
    let endpoint =
      `meta/signal-maps/${signalState.signalMap?.dut_id}/fault-conditions?` +
      `dut_type=${signalState.signalMap?.dut_type}&` +
      `selected=${signalState.selected.faultConditions.join(",")}`;
    let test_id = signalState.signalMap?.test_id;
    if (!isNull(test_id)) {
      endpoint += `&test_id=${test_id}`;
    }
    const response = await client.delete(endpoint);
    return response;
  }
);

const slice = createSlice({
  name: SIGNAL_MAP,
  initialState,
  reducers: {
    resetState: (state) => Object.assign(state, initialState),
    // Signal reducers
    selectSignals(state, action: PayloadAction<number[]>) {
      state.selected.signals = uniq([
        ...state.selected.signals,
        ...action.payload,
      ]);
    },
    deselectSignals(state, action: PayloadAction<number[]>) {
      const selected = [...state.selected.signals];
      action.payload.forEach((fullName) => {
        const index = selected.indexOf(fullName);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      });
      state.selected.signals = uniq(selected);
    },
    sortSignals(
      state,
      action: PayloadAction<{ order: "asc" | "desc"; key: FalconKey<Signal> }>
    ) {
      let payload = action.payload;
      let order = payload.order;
      let key = payload.key;
      const compare = (a: Signal, b: Signal) => {
        let a_attr =
          typeof a[key] === "string" ? String(a[key])?.toLowerCase() : a[key];
        let b_attr =
          typeof b[key] === "string" ? String(b[key])?.toLowerCase() : b[key];

        if (isNil(a_attr)) {
          return order === "asc" ? 1 : -1;
        } else if (isNil(b_attr)) {
          return order === "asc" ? -1 : 1;
        }

        return a_attr > b_attr
          ? order === "asc"
            ? 1
            : -1
          : b_attr > a_attr
          ? order === "asc"
            ? -1
            : 1
          : 0;
      };
      state.signals.sort(compare);
      state.orderBy.signal = key;
      state.order.signal = order;
    },
    selectAllVisibleSignals(state) {
      state.selected.signals = state.visible.signals;
    },
    deselectAllSignals(state) {
      state.selected.signals = [];
    },
    filterSignals(state, action: PayloadAction<number[]>) {
      state.visible.signals = uniq(action.payload);
    },
    resetFilterSignals(state) {
      state.visible.signals = state.signals.flatMap((s) => s.signal_id);
    },
    // Fault condition reducers
    selectFaultConditions(state, action: PayloadAction<number[]>) {
      state.selected.faultConditions = uniq([
        ...state.selected.faultConditions,
        ...action.payload,
      ]);
    },
    deselectFaultConditions(state, action: PayloadAction<number[]>) {
      const selected = [...state.selected.faultConditions];
      action.payload.forEach((fullName) => {
        const index = selected.indexOf(fullName);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      });
      state.selected.faultConditions = uniq(selected);
    },
    sortFaultConditions(
      state,
      action: PayloadAction<{
        order: "asc" | "desc";
        key: FalconKey<FaultCondition>;
      }>
    ) {
      let payload = action.payload;
      let order = payload.order;
      let key = payload.key as keyof FaultCondition;
      const compare = (a: FaultCondition, b: FaultCondition) => {
        let a_attr = a[key];
        let b_attr = b[key];

        if (isNil(a_attr)) {
          return order === "asc" ? 1 : -1;
        } else if (isNil(b_attr)) {
          return order === "asc" ? -1 : 1;
        }

        return a_attr > b_attr
          ? order === "asc"
            ? 1
            : -1
          : b_attr > a_attr
          ? order === "asc"
            ? -1
            : 1
          : 0;
      };
      state.faultConditions.sort(compare);
      state.orderBy.faultCondition = key;
      state.order.faultCondition = order;
    },
    selectAllFaultConditions(state) {
      state.selected.faultConditions = state.faultConditions.map(
        (fc) => fc.fault_condition_id
      );
    },
    deselectAllFaultConditions(state) {
      state.selected.faultConditions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Create new signal map
      .addCase(createSignalMap.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(createSignalMap.fulfilled, (state, { payload }) => {
        state.status.create = "succeeded";
        window.location.href =
          window.location.origin +
          `/signal-maps/${payload.dut_type}s/${payload.dut_id}`;
      })
      .addCase(createSignalMap.rejected, (state, { error }) => {
        state.status.create = "failed";
        state.error.create = error.message as string;
      })
      // Get signal map data
      .addCase(getSignalMap.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSignalMap.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.signalMap = payload.signal_map;
        state.dutType = payload.signal_map.dut_type;
        state.signals = payload.signal_map.signals;
        state.visible.signals = state.signals.flatMap((s) => s.signal_id);
        state.faultConditions = payload.signal_map.fault_conditions;
        state.visible.faultConditions = state.faultConditions.flatMap(
          (fc) => fc.fault_condition_id
        );
        state.moduleName = payload.module_name;
        state.allTestData = payload.all_test_data;
        state.isMostRecentSignalMap = payload.is_most_recent_signal_map;
      })
      .addCase(getSignalMap.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Update signal map data
      .addCase(updateSignalMap.pending, (state) => {
        state.status.update = "loading";
      })
      .addCase(updateSignalMap.fulfilled, (state, { payload }) => {
        state.status.update = "succeeded";
        state.signalMap = payload;
        state.signals = payload.signals;
        state.faultConditions = payload.fault_conditions;
      })
      .addCase(updateSignalMap.rejected, (state, { error }) => {
        state.status.update = "failed";
        state.error.update = error.message as string;
      })
      // Delete signals
      .addCase(deleteSignals.pending, (state) => {
        state.status.delete = "loading";
      })
      .addCase(deleteSignals.fulfilled, (state, { payload }) => {
        state.status.delete = "succeeded";
        state.signalMap = payload;
        state.signals = payload.signals;
        state.faultConditions = payload.fault_conditions;
        state.selected.signals = [];
      })
      .addCase(deleteSignals.rejected, (state, { error }) => {
        state.status.delete = "failed";
        state.error.delete = error.message as string;
      })
      // Delete fault conditions
      .addCase(deleteFaultConditions.pending, (state) => {
        state.status.delete = "loading";
      })
      .addCase(deleteFaultConditions.fulfilled, (state, { payload }) => {
        state.status.delete = "succeeded";
        state.signalMap = payload;
        state.faultConditions = payload.fault_conditions;
        state.selected.faultConditions = [];
      })
      .addCase(deleteFaultConditions.rejected, (state, { error }) => {
        state.status.delete = "failed";
        state.error.delete = error.message as string;
      });
  },
});

export const {
  resetState,
  sortSignals,
  selectSignals,
  deselectSignals,
  selectAllVisibleSignals,
  deselectAllSignals,
  filterSignals,
  resetFilterSignals,
  sortFaultConditions,
  selectFaultConditions,
  deselectFaultConditions,
  selectAllFaultConditions,
  deselectAllFaultConditions,
} = slice.actions;

export default slice.reducer;
