import type { DateTime } from "luxon";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiDateRangePicker from "@mui/lab/DateRangePicker";
import type { DateRangePickerProps } from "@mui/lab/DateRangePicker";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import CalendarIcon from "../icons/Calendar";
import TimesIcon from "../icons/Times";
import colors, { addAlpha } from "../theme/colors";

const useDateRangeStyles = makeStyles({
  root: {
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      background: addAlpha(colors.accent.primary, 0.1),
    },
  },
});

const DateRangePicker = ({
  onChange,
  ...props
}: Omit<DateRangePickerProps<DateTime>, "renderInput">) => {
  const classes = useDateRangeStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MuiDateRangePicker
        className={classes.root}
        inputFormat="yyyy-MM-dd"
        calendars={2}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              label=""
              inputProps={{
                ...startProps.inputProps,
                style: { textAlign: "center", padding: "0.5rem" },
              }}
              InputProps={{
                ...startProps.InputProps,
                startAdornment: (
                  <Box ml={2}>
                    <CalendarIcon
                      fontSize="small"
                      style={{
                        color: colors.text.secondary,
                        paddingTop: 3,
                      }}
                    />
                  </Box>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    style={{
                      padding: 4,
                      visibility: props.value[0] ? "visible" : "hidden",
                    }}
                    onClick={() =>
                      onChange([null, props.value[1] as DateTime | null])
                    }
                  >
                    <TimesIcon
                      fontSize="small"
                      style={{
                        color: colors.text.secondary,
                      }}
                    />
                  </IconButton>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
            <Box mx={2} my="auto">
              to
            </Box>
            <TextField
              {...endProps}
              label=""
              inputProps={{
                ...endProps.inputProps,
                style: { textAlign: "center", padding: "0.5rem" },
              }}
              InputProps={{
                ...endProps.InputProps,
                startAdornment: (
                  <Box ml={2}>
                    <CalendarIcon
                      fontSize="small"
                      style={{
                        color: colors.text.secondary,
                        paddingTop: 3,
                      }}
                    />
                  </Box>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    style={{
                      padding: 4,
                      visibility: props.value[1] ? "visible" : "hidden",
                    }}
                    onClick={() =>
                      onChange([props.value[0] as DateTime | null, null])
                    }
                  >
                    <TimesIcon
                      fontSize="small"
                      style={{
                        color: colors.text.secondary,
                      }}
                    />
                  </IconButton>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
          </>
        )}
        onChange={onChange}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
