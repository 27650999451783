import Box from "@mui/material/Box";
import Button from "../Button";
import CommonFileTextIcon from "../../icons/CommonFileText";
import ConditionCell from "./ConditionCell";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  smallWidth?: boolean;
  label: string;
  href: string;
};

const ButtonCell = ({ style, className, href, label, smallWidth }: Props) => (
  <ConditionCell
    style={style}
    className={className || ""}
    smallWidth={smallWidth}
  >
    {href ? (
      <Button
        color="tertiary"
        style={{ background: "transparent", border: "1px solid transparent" }}
        startIcon={<CommonFileTextIcon />}
        href={href}
        target="_blank"
      >
        {label}
      </Button>
    ) : (
      <Box px={4}>N/A</Box>
    )}
  </ConditionCell>
);

export default ButtonCell;
