import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const SearchBar = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 48 48"
  >
    {children}
    <path
      d="M8.25 17.25C7.83579 17.25 7.5 17.5858 7.5 18C7.5 18.4142 7.83579 18.75 8.25 18.75C8.66421 18.75 9 18.4142 9 18C9.00053 17.8009 8.92169 17.6099 8.78092 17.4691C8.64015 17.3283 8.44908 17.2495 8.25 17.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 17.25C15.3358 17.25 15 17.5858 15 18C15 18.4142 15.3358 18.75 15.75 18.75C16.1642 18.75 16.5 18.4142 16.5 18C16.5005 17.8009 16.4217 17.6099 16.2809 17.4691C16.1402 17.3283 15.9491 17.2495 15.75 17.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5 13.5V10.5C43.5 8.84315 42.1569 7.5 40.5 7.5H4.5C2.84315 7.5 1.5 8.84315 1.5 10.5V22.5C1.5 24.1569 2.84315 25.5 4.5 25.5H15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5 36C37.299 36 42 31.299 42 25.5C42 19.701 37.299 15 31.5 15C25.701 15 21 19.701 21 25.5C21 31.299 25.701 36 31.5 36Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.5 40.4998L38.924 32.9238"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SearchBar;
