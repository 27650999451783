type ConfirmMessageState = {
  confirmationMessagesByKey: {
    [fieldId: string]: string;
  };
  currentConfirmationMessage: string | null;
  confirmationStatus: string;
};

export const initialConfirmMessagesState: ConfirmMessageState = {
  confirmationMessagesByKey: {},
  currentConfirmationMessage: null,
  confirmationStatus: "idle",
};

export const UPDATE_CONFIRMATION_MESSAGES =
  "confirmMessage/UPDATE_CONFIRMATION_MESSAGES";
export const SET_CURRENT_CONFIRMATION_MESSAGE =
  "confirmMessage/SET_CURRENT_CONFIRMATION_MESSAGE";
export const RESET_CURRENT_CONFIRMATION_MESSAGE =
  "confirmMessage/RESET_CURRENT_CONFIRMATION_MESSAGE";
export const SWITCH_TO_NEXT_CONFIRMATION_MESSAGE =
  "confirmMessage/SWITCH_TO_NEXT_CONFIRMATION_MESSAGE";
export const SET_ALL_MESSAGES_CONFIRMED =
  "confirmMessage/SET_ALL_MESSAGES_CONFIRMED";
export const RESET_CONFIRMATION_MESSAGES =
  "confirmMessage/RESET_CONFIRMATION_MESSAGES";

const confirmationMessagesReducer = function (
  state: ConfirmMessageState,
  action: { type: string; payload?: { [payloadKey: string]: any } }
) {
  const { type, payload = {} } = action;
  switch (type) {
    case UPDATE_CONFIRMATION_MESSAGES: {
      const newConfirmationMsgs = { ...state.confirmationMessagesByKey };
      if (payload.value) {
        newConfirmationMsgs[payload.fieldKey] = payload.value;
      } else {
        delete newConfirmationMsgs[payload.fieldKey];
      }
      return {
        ...state,
        confirmationMessagesByKey: newConfirmationMsgs,
      };
    }
    case SET_CURRENT_CONFIRMATION_MESSAGE: {
      return {
        ...state,
        confirmationStatus: "in_progress",
        currentConfirmationMessage:
          state.confirmationMessagesByKey[
            Object.keys(state.confirmationMessagesByKey)[0]
          ] || null,
      };
    }
    case RESET_CURRENT_CONFIRMATION_MESSAGE: {
      return {
        ...state,
        confirmationStatus: "cancelled",
        currentConfirmationMessage: null,
      };
    }
    case SWITCH_TO_NEXT_CONFIRMATION_MESSAGE: {
      // initial confirmationMessage of collection has been acknowledged,
      // remove it and move to next message.
      const { confirmationMessagesByKey } = state;
      const newConfirmationMessagesByKey: {
        [msgKey: string]: string;
      } = {};
      Object.keys(confirmationMessagesByKey).forEach(
        (messageKey: string, index: number) => {
          if (index === 0) return;
          newConfirmationMessagesByKey[messageKey] =
            confirmationMessagesByKey[messageKey]!;
        }
      );
      return {
        ...state,
        confirmationStatus: "in_progress",
        currentConfirmationMessage: Object.values(
          newConfirmationMessagesByKey
        ).find((confirmationMessage) => !!confirmationMessage)!,
        confirmationMessagesByKey: newConfirmationMessagesByKey,
      };
    }
    case SET_ALL_MESSAGES_CONFIRMED: {
      return {
        ...initialConfirmMessagesState,
        confirmationStatus: "completed",
      };
    }
    case RESET_CONFIRMATION_MESSAGES: {
      return initialConfirmMessagesState;
    }
  }
  return state;
};

export default confirmationMessagesReducer;
