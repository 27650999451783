import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { isNull } from "lodash";
import client from "../../api";

import Button from "../../components/Button";
import colors from "../../theme/colors";
import FCVariableInput from "./FCVariableInput";
import Modal from "../../components/Modal";
import OpenInNew from "../../icons/OpenInNew";
import Toast from "../../components/Toast";

import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import type { RootState } from "../../store";
import { updateSignalMap, deleteFaultConditions } from "./slice";
import type { SignalMapState } from "./slice";

type Props = {
  mode: "add" | "edit" | "delete";
  open: boolean;
  onClose: () => void;
};

const ModifyFaultConditionsModal = ({ mode, open = false, onClose }: Props) => {
  const dispatch = useDispatch();
  const isAdd = mode === "add";
  const isEdit = mode === "edit";
  const isDelete = mode === "delete";
  const MAX_VARIABLES = 8;
  const [numVariables, setNumVariables] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const FAULT_CATEGORIES = [1, 2, 3, 4, 5];
  const [allSignalGroups, setAllSignalGroups] = useState<
    { [key: string]: number | string }[]
  >([]);
  const getSignalGroups = useCallback(async () => {
    try {
      const response = await client.get("meta/signal-maps/signal-groups");
      setAllSignalGroups(response.data);
    } catch (err) {
      setAllSignalGroups([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getSignalGroups();
  }, [getSignalGroups]);

  const {
    signalMap,
    faultConditions,
    selected: { faultConditions: selected },
    status: { update: updateStatus, delete: deleteStatus },
    error: { update: updateError, delete: deleteError },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  let defaultFormData: any = {
    expressionString: "",
    variableString1: "",
    variableString2: "",
    variableString3: "",
    variableString4: "",
    variableString5: "",
    variableString6: "",
    variableString7: "",
    variableString8: "",
    isGroupOperand1: false,
    isGroupOperand2: false,
    isGroupOperand3: false,
    isGroupOperand4: false,
    isGroupOperand5: false,
    isGroupOperand6: false,
    isGroupOperand7: false,
    isGroupOperand8: false,
    signal_id_1: "",
    signal_id_2: "",
    signal_id_3: "",
    signal_id_4: "",
    signal_id_5: "",
    signal_id_6: "",
    signal_id_7: "",
    signal_id_8: "",
    signal_group_id_1: "",
    signal_group_id_2: "",
    signal_group_id_3: "",
    signal_group_id_4: "",
    signal_group_id_5: "",
    signal_group_id_6: "",
    signal_group_id_7: "",
    signal_group_id_8: "",
    dti: "",
    faultCategory: "",
  };

  const { control, handleSubmit, getValues, setValue, reset } =
    useForm<FaultConditionFormData>({
      defaultValues: { ...defaultFormData },
    });

  useEffect(() => {
    if (isEdit && open) {
      reset({ ...defaultFormData });
      // Pre-populate form data with proper values
      let fc = faultConditions.find(
        (fc) => fc.fault_condition_id === selected[0]
      );

      fc && setValue("expressionString", fc.expression_string);
      fc && setValue("dti", fc.dti);
      fc && setValue("faultCategory", fc.fault_category);
      fc?.variables.forEach((v, i) => {
        setValue(
          `variableString${i + 1}` as keyof FaultConditionFormData,
          v.variable_string
        );
        setValue(
          `isGroupOperand${i + 1}` as keyof FaultConditionFormData,
          !!v.signal_group_id,
          { shouldDirty: true }
        );
        setValue(
          `signal_id_${i + 1}` as keyof FaultConditionFormData,
          v.signal_id ? v.signal_id : ""
        );
        setValue(
          `signal_group_id_${i + 1}` as keyof FaultConditionFormData,
          v.signal_group_id ? v.signal_group_id : ""
        );
      });
      fc && setNumVariables(fc.variables.length);
    } else if (isAdd && open) {
      // Reset the form on add
      reset({ ...defaultFormData });
      setNumVariables(1);
    }
    // eslint-disable-next-line
  }, [isEdit, isAdd, faultConditions, open]);

  // "Update" response handler
  useEffect(() => {
    if (updateStatus === "failed") {
      setError(updateError);
    } else {
      setError(null);
    }

    if (updateStatus === "succeeded") {
      // Reset form data on success
      reset({ ...defaultFormData });
      setNumVariables(1);
    }
    // eslint-disable-next-line
  }, [updateStatus, updateError]);

  // "Delete" response handler
  useEffect(() => {
    if (deleteStatus === "failed") {
      setError(deleteError);
    } else {
      setError(null);
    }
    // eslint-disable-next-line
  }, [deleteStatus, deleteError]);

  const onSubmit = (data: any) => {
    let isValid = true;
    // Check that dti is numeric
    if (data["dti"] !== "" && isNaN(data["dti"])) {
      setError("DTI must be numeric");
      return;
    }

    // Verify that multiple variables aren't used
    let usedVariables: string[] = [
      data["variableString1"],
      data["variableString2"],
      data["variableString3"],
      data["variableString4"],
      data["variableString5"],
      data["variableString6"],
      data["variableString7"],
      data["variableString8"],
    ].filter((v) => !isNull(v) && v !== "");

    usedVariables.forEach((v1) => {
      if (usedVariables.filter((v2) => v1 === v2).length > 1) {
        setError(`Variable ${v1} is used more than once`);
        isValid = false;
        return;
      }
    });

    isValid &&
      signalMap &&
      dispatch(
        updateSignalMap({
          test_id: signalMap.test_id,
          formData: data,
          objectURLStr: "fault-conditions",
          ...(isEdit && { selected: selected }),
        })
      );
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        {isDelete ? (
          <>
            <Typography variant="h2" mb={5} mt={5} textAlign="center">
              {`Are you sure you want to delete the following ${
                selected.length
              } fault condition${selected.length > 1 ? "s" : ""}?`}
            </Typography>

            {faultConditions
              .filter((fc) => selected.includes(fc.fault_condition_id))
              .map((fc, i) => (
                <Typography key={i} className="small" textAlign="center">
                  {fc.expression_string}
                </Typography>
              ))}

            <Box
              p={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                color="primary"
                size="small"
                type="submit"
                disabled={deleteStatus === "loading" || selected.length === 0}
                style={{
                  width: "fit-content",
                  marginRight: "5px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                color="secondary"
                size="small"
                type="submit"
                disabled={deleteStatus === "loading" || selected.length === 0}
                style={{
                  width: "fit-content",
                }}
                onClick={() => dispatch(deleteFaultConditions())}
                endIcon={
                  deleteStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                Delete
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h2">
              {mode.charAt(0).toUpperCase() + mode.slice(1)} Fault Condition
              {selected.length > 1 && "s"}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              {(isAdd || (isEdit && selected.length) === 1) && (
                <>
                  <Typography className="small" color="textSecondary" mt={5}>
                    Expression
                    <span style={{ color: colors.accent.red }}>*</span>
                    <Link
                      href="https://wiki.formenergy.com/pages/viewpage.action?spaceKey=TI&title=Complete+Guide+to+Working+with+the+Hazelnut+Schemas#CompleteGuidetoWorkingwiththeHazelnutSchemas-FundamentalConfigurationObjects"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginLeft: "5px" }}
                    >
                      <OpenInNew />
                    </Link>
                  </Typography>

                  <FormControl style={{ width: "100%", height: "100%" }}>
                    <Controller
                      control={control}
                      name="expressionString"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid },
                      }) => (
                        <Input
                          className="small"
                          disableUnderline
                          inputRef={ref}
                          error={invalid}
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </FormControl>

                  {[...Array(numVariables)].map((_, i) => (
                    <FCVariableInput
                      key={i}
                      index={i + 1}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      allSignalGroups={allSignalGroups}
                    />
                  ))}

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                  >
                    <Typography
                      style={{
                        fontSize: "40px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      color="textSecondary"
                      onClick={() => {
                        if (numVariables === 1) {
                          setError("There must be at least 1 variable");
                          return;
                        }
                        setValue(
                          `variableString${numVariables}` as keyof FaultConditionFormData,
                          ""
                        );
                        setValue(
                          `signal_id_${numVariables}` as keyof FaultConditionFormData,
                          ""
                        );
                        setValue(
                          `signal_group_id_${numVariables}` as keyof FaultConditionFormData,
                          ""
                        );
                        setNumVariables(numVariables - 1);
                      }}
                    >
                      -
                    </Typography>
                    <Typography
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      color="textSecondary"
                      onClick={() => {
                        if (numVariables === MAX_VARIABLES) {
                          setError("Max number of variables reached");
                          return;
                        }
                        setNumVariables(numVariables + 1);
                      }}
                    >
                      +
                    </Typography>
                  </Box>
                </>
              )}

              <Box py={4}>
                <Divider />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ width: "50%", textAlign: "left" }}>
                  <Typography className="small" color="textSecondary">
                    DTI (s)
                  </Typography>

                  <FormControl style={{ width: "100%", height: "100%" }}>
                    <Controller
                      control={control}
                      name={"dti"}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid },
                      }) => (
                        <Input
                          className="small"
                          disableUnderline
                          inputRef={ref}
                          error={invalid}
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          style={{ width: "90%" }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>

                <Box style={{ width: "50%", textAlign: "left" }}>
                  <Typography className="small" color="textSecondary">
                    Fault Category
                    <span style={{ color: colors.accent.red }}>*</span>
                  </Typography>

                  <FormControl style={{ width: "100%", height: "100%" }}>
                    <Controller
                      control={control}
                      name={"faultCategory"}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid },
                      }) => (
                        <Select
                          value={value}
                          error={invalid}
                          onChange={onChange}
                        >
                          {FAULT_CATEGORIES.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Button
                color="primary"
                size="small"
                disabled={updateStatus === "loading"}
                type="submit"
                style={{
                  width: "fit-content",
                  marginTop: "20px",
                }}
                endIcon={
                  updateStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                {isAdd && "Add Fault Condition"}
                {isEdit && "Save"}
              </Button>
            </form>
          </>
        )}
      </Modal>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default ModifyFaultConditionsModal;
