import { isEqual } from "lodash";
import intersection from "lodash/intersection";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";

const FIELDS_TO_IGNORE_DEFAULTS = [
  "preferred_executor",
  "deviations_from_sop",
  "deviation_types",
];

export const compareValue = (
  value: boolean | string | number | Material | null
) => {
  if (isBoolean(value)) return value;
  if (isObject(value)) return value.material_id;
  if (isNull(value)) return "";
  return String(value);
};

export const isStandardGrouping = (
  formFieldInfo: ConditionUIFieldWithVal[]
) => {
  if (isEmpty(formFieldInfo)) return true;

  const indicesWhereValMatchesDefault = formFieldInfo.map((fieldInfo) => {
    return fieldInfo.default
      .map(
        (defaultVal, index) =>
          compareValue(fieldInfo.value) === compareValue(defaultVal) && index
      )
      .filter(isNumber);
  });

  return intersection(...indicesWhereValMatchesDefault).length > 0;
};

export const hasMultipleDefaults = (defaultField: any) =>
  Array.isArray(defaultField) &&
  defaultField.length > 0 &&
  defaultField.every((defaultVal) => !isNull(defaultVal));

export const defaultValuePresent = (defaultVal: any) =>
  !Array.isArray(defaultVal) && !!defaultVal;

export const uiFieldHasDefaults = (uiField: {
  default?: any;
  type: string;
  id: string;
}) => {
  return (
    (!hasMultipleDefaults(uiField.default) &&
      !isNull(uiField.default) &&
      !isBoolean(uiField.default) &&
      uiField.type !== "button" &&
      FIELDS_TO_IGNORE_DEFAULTS.every(
        (fieldToIgnore) => !uiField.id.includes(fieldToIgnore)
      )) ||
    hasMultipleDefaults(uiField.default)
  );
};

export const matchingDefaultIndexForSubassemblyOfAllConditions = (
  formFields: any[],
  step: number,
  fieldsWithMultiDefaults?: any[]
) => {
  // Determine if values of all groups of formFields passed in match a single group of defaults in the fieldsWithMultiDefaults collection.
  // If a the same index is found for each item in formFields, return it. If not, return null.
  if (
    !fieldsWithMultiDefaults ||
    fieldsWithMultiDefaults.length === 0 ||
    formFields.length === 0
  )
    return null;

  // Collate values by default index
  let defaultsByIndex: any = {};
  fieldsWithMultiDefaults!.forEach((_field) => {
    (_field.default as any)!.forEach((d: any, i: number) => {
      if (!(i in defaultsByIndex)) {
        defaultsByIndex[i] = {};
      }
      defaultsByIndex[i][_field.id] = d;
    });
  });

  // Check if all of the fields match for any of the default values
  const formFieldsMatchDefaults = formFields.flatMap((formField) => {
    let field = formField.items[step];

    return Object.entries(defaultsByIndex)
      .map(([index, v]) => {
        if (
          Object.entries(v as any).every(([fieldName, fieldVal], i) => {
            if (!field || !(fieldName in field)) return false;
            return isEqual(field[fieldName], fieldVal);
          })
        ) {
          return index;
        } else {
          return false;
        }
      })
      .filter((item) => {
        return !!item;
      });
  });
  return formFieldsMatchDefaults.length === formFields.length &&
    formFieldsMatchDefaults.every((val, idx, thisArray) => val === thisArray[0])
    ? parseInt(formFieldsMatchDefaults[0] as string)
    : null;
};
