import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import type { InputProps } from "@mui/material/Input";
import Input from "@mui/material/Input";
import MinusCircleIcon from "../../icons/MinusCircle";
import TimesIcon from "../../icons/Times";

type TextFilterProps = {
  removable?: boolean;
  onRemove?: () => void;
  onClear: () => void;
} & InputProps;

const TextFilter = ({
  removable,
  onRemove,
  onClear,
  ...props
}: TextFilterProps) => (
  <Box display="flex">
    <FormControl fullWidth>
      <Input
        disableUnderline
        {...props}
        endAdornment={
          props.value ? (
            <IconButton size="small" onClick={onClear}>
              <TimesIcon fontSize="small" />
            </IconButton>
          ) : null
        }
      />
    </FormControl>
    {removable ? (
      <IconButton size="small" onClick={onRemove}>
        <MinusCircleIcon />
      </IconButton>
    ) : null}
  </Box>
);

export default TextFilter;
