import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import type { RootState } from "../../store";
import type { ChangeRequestState } from "./slice";
import Toast from "../../components/Toast";
import {
  getPendingChangeRequests,
  getCompletedChangeRequests,
  resetGetCompletedChangeRequests,
  resetGetPendingChangeRequests,
} from "./slice";
import ListComponent from "./ListComponent";
import colors from "../../theme/colors";
import ApprovalPointerIcon from "../../icons/ApprovalPointer";

type Props = {
  completed?: boolean;
};

const List = ({ completed }: Props) => {
  const {
    pendingChangeRequests,
    completedChangeRequests,
    status: { pending: pendingStatus, complete: completeStatus },
    error: { pending: pendingError, complete: completeError },
  } = useSelector<RootState, ChangeRequestState>(
    ({ changeRequests }) => changeRequests
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetGetPendingChangeRequests());
    dispatch(resetGetCompletedChangeRequests());
  }, [dispatch]);

  useEffect(() => {
    if (!completed && pendingStatus === "idle") {
      dispatch(getPendingChangeRequests());
    }
  }, [dispatch, pendingStatus, completed]);

  useEffect(() => {
    if (completed && completeStatus === "idle") {
      dispatch(getCompletedChangeRequests());
    }
  }, [dispatch, completeStatus, completed]);

  let pendingToast;
  if (pendingStatus === "failed") {
    pendingToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetGetPendingChangeRequests())}
      >
        {pendingError}
      </Toast>
    );
  }

  let completeToast;
  if (completeStatus === "failed") {
    completeToast = (
      <Toast
        open
        severity="error"
        onClose={() => dispatch(resetGetCompletedChangeRequests())}
      >
        {completeError}
      </Toast>
    );
  }

  const emptyState = (
    <Box p={10} textAlign="center" style={{ color: colors.text.secondary }}>
      <ApprovalPointerIcon style={{ width: 48, height: 48 }} />
      <p>No change requests to show</p>
    </Box>
  );

  if (!completed) {
    return (
      <>
        {pendingChangeRequests.length > 0
          ? pendingChangeRequests.map((params, index) => (
              <ListComponent key={index} {...params} />
            ))
          : emptyState}
        {pendingToast}
      </>
    );
  }

  return (
    <>
      {completedChangeRequests.length > 0
        ? completedChangeRequests.map((params, index) => (
            <ListComponent key={index} {...params} />
          ))
        : emptyState}
      {completeToast}
    </>
  );
};

export default List;
