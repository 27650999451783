import React from "react";
import MetaCell from "../../components/forms/MetaCell";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

type BatchFormMetaCellProps = {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  descriptionKey?: string;
  dropdownTooltipText?: string;
  endpoint: string;
  fieldName: string;
  freeSolo?: boolean;
  fieldId: string | number;
  multiple?: boolean;
  searchKey?: string;
  value: string | null;
  valueKey?: string;
  disabled?: boolean;
};

const BatchFormMetaCell = ({
  apiRef,
  descriptionKey,
  dropdownTooltipText,
  endpoint,
  value,
  fieldName,
  fieldId,
  freeSolo = false,
  multiple = false,
  disabled = false,
  searchKey,
  valueKey,
}: BatchFormMetaCellProps) => {
  const handleValueChange = (newVal: string | null) => {
    apiRef.current.setEditCellValue({
      id: fieldId,
      field: fieldName,
      value: newVal,
    });
  };
  return (
    <MetaCell
      value={value}
      style={{ width: "100%" }}
      endpoint={endpoint}
      onBlur={() => {}}
      onChange={handleValueChange}
      noTable
      freeSolo={freeSolo}
      multiple={multiple}
      disabled={disabled}
      searchKey={searchKey}
      dropdownTooltipText={
        dropdownTooltipText || (disabled ? "field disabled" : undefined)
      }
      valueKey={valueKey}
      descriptionKey={descriptionKey}
    />
  );
};

export default BatchFormMetaCell;
