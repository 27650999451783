import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { isNull } from "lodash";

import Button from "../../components/Button";
import colors from "../../theme/colors";
import Info from "../../icons/Info";
import Modal from "../../components/Modal";
import SignalsModalInput from "./SignalsModalInput";
import Toast from "../../components/Toast";
import { BAM_SIGNAL_LABELS_MODAL } from "../../utils/labels";

import {
  Box,
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import { PRIV_USER_ROLES } from "../../utils/statuses";
import { updateSignalMap, deleteSignals, sortSignals } from "./slice";
import type { SignalMapState } from "./slice";

type Props = {
  mode: "add" | "edit" | "delete";
  open: boolean;
  onClose: () => void;
  allSensorTypes: AllSensorTypes;
  allDeviceClasses: string[];
  allSignalGroups: { [key: string]: number | string }[];
  formData: any | null;
};

const ModifySignalsModal = ({
  mode,
  open = false,
  onClose,
  allSensorTypes,
  allDeviceClasses,
  allSignalGroups,
  formData = null,
}: Props) => {
  const dispatch = useDispatch();
  const isAdd = mode === "add";
  const isEdit = mode === "edit";
  const isDelete = mode === "delete";
  const [numSignals, setNumSignals] = useState<string>("1");
  const [error, setError] = useState<string | null>(null);
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const [measurementType, setMeasurementType] = useState<string>("");
  const [sensorType, setSensorType] = useState<string>("");
  const isPrivileged = PRIV_USER_ROLES.includes(user?.role as string);
  const PRIVILEGED_FIELDS: (keyof SignalFormData)[] = ["offset", "gain"];
  const FSC_VISIBLE_FIELDS: (keyof SignalFormData)[] = [
    "measurementType",
    "sensorType",
    "units",
    "deviceClass",
    "namingPattern",
    "addressPattern",
    "thermocouple_type",
    "start_register",
    "register_count",
    "big_endian",
    "unit_id",
    "is_coil",
  ];
  const SERIAL_HPHC_VISIBLE_FIELDS: (keyof SignalFormData)[] = [
    ...FSC_VISIBLE_FIELDS,
    "cell_id",
  ];
  const BAM_VISIBLE_FIELDS: (keyof SignalFormData)[] = [
    ...SERIAL_HPHC_VISIBLE_FIELDS,
    "power_coeff_2",
    "power_coeff_3",
    "power_coeff_4",
    "power_coeff_5",
    "signal_group_id",
    "signal_range",
    "board",
    "port_name",
  ];
  const SENSOR_TYPE_FIELDS: (keyof SignalFormData)[] = [
    "measurementType",
    "sensorType",
    "units",
  ];

  const {
    dutType,
    signalMap,
    signals,
    faultConditions,
    selected: { signals: selected },
    status: { update: updateStatus, delete: deleteStatus },
    error: { update: updateError, delete: deleteError },
    order: { signal: signalOrder },
    orderBy: { signal: signalOrderBy },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  const ALL_VISIBLE_FIELDS =
    dutType === "cell"
      ? FSC_VISIBLE_FIELDS
      : dutType === "serial_hphc"
      ? SERIAL_HPHC_VISIBLE_FIELDS
      : BAM_VISIBLE_FIELDS;
  isPrivileged && ALL_VISIBLE_FIELDS.push(...PRIVILEGED_FIELDS);
  const [visibleFields, setVisibleFields] = useState<(keyof SignalFormData)[]>(
    isAdd ? ALL_VISIBLE_FIELDS : []
  );
  let FIELD_TO_LABEL: { [key: string]: string } = {};
  Object.entries(BAM_SIGNAL_LABELS_MODAL).forEach((obj) => {
    let field = obj[1].id;
    let label = obj[1].label;
    // Don't include sensor type and units since they're paired with measurement type
    FIELD_TO_LABEL[field] = label;
  });

  useEffect(() => {
    // Always set applicable fields when sensorType is updated and
    // the fields are empty
    if (sensorType !== "") {
      setValue("units", allSensorTypes[measurementType][sensorType]["units"], {
        shouldDirty: true,
      });

      // Need to explicitly set each to avoid type errors
      if (getValues("namingPattern") === "") {
        setValue(
          "namingPattern",
          allSensorTypes[measurementType][sensorType]["signal_name"],
          { shouldDirty: true }
        );
      }

      if (getValues("addressPattern") === "") {
        setValue(
          "addressPattern",
          allSensorTypes[measurementType][sensorType]["device_address"],
          { shouldDirty: true }
        );
      }

      if (getValues("offset") === "") {
        setValue(
          "offset",
          allSensorTypes[measurementType][sensorType]["offset"],
          { shouldDirty: true }
        );
      }

      if (getValues("gain") === "") {
        setValue("gain", allSensorTypes[measurementType][sensorType]["gain"], {
          shouldDirty: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [sensorType]);

  let defaultFormData: any = {
    measurementType: "",
    sensorType: "",
    deviceClass: dutType === "cell" ? "keysight-970a" : "",
    units: "",
    namingPattern: "",
    addressPattern: "",
    offset: "",
    gain: "",
    thermocouple_type: "",
    start_register: "",
    register_count: "",
    unit_id: "",
    big_endian: false,
    is_coil: false,
    numSignals: "1",
  };

  if (dutType === "serial_hphc") {
    defaultFormData = {
      ...defaultFormData,
      cell_id: "",
    };
  } else if (dutType === "module") {
    defaultFormData = {
      ...defaultFormData,
      power_coeff_2: "",
      power_coeff_3: "",
      power_coeff_4: "",
      power_coeff_5: "",
      cell_id: "",
      signal_group_id: "",
      signal_range: "",
      board: "",
      port_name: "",
    };
  }

  const { control, handleSubmit, getValues, setValue, reset } =
    useForm<SignalFormData>({
      defaultValues: { ...defaultFormData },
    });

  const removeField = (key: keyof SignalFormData) => {
    if (visibleFields.length === 1) {
      setError("There must be at least one active form field");
      return;
    }

    if (key === "measurementType") {
      // Remove all inputs associated with measurement type
      setVisibleFields(
        visibleFields.filter((field) => !SENSOR_TYPE_FIELDS.includes(field))
      );
    } else {
      setVisibleFields(
        visibleFields.filter(
          (field) => ![key as keyof SignalFormData].includes(field)
        )
      );
    }
  };

  // "Update" response handler
  useEffect(() => {
    if (updateStatus === "failed") {
      setError(updateError);
    } else {
      setError(null);
    }

    if (updateStatus === "succeeded") {
      // Reset form data on success
      reset({ ...defaultFormData });

      // Reset visible form fields
      setVisibleFields(isAdd ? ALL_VISIBLE_FIELDS : []);
    }
    // eslint-disable-next-line
  }, [updateStatus, updateError]);

  // "Delete" response handler
  useEffect(() => {
    if (deleteStatus === "failed") {
      setError(deleteError);
    } else {
      setError(null);
    }
    // eslint-disable-next-line
  }, [deleteStatus, deleteError]);

  const onSubmit = async (data: SignalFormData) => {
    // Check that numeric keys are in fact numeric if they're being submitted
    let isValid = true;
    let numericKeys = [
      "offset",
      "gain",
      "power_coeff_2",
      "power_coeff_3",
      "power_coeff_4",
      "power_coeff_5",
      "numSignals",
      "start_register",
      "register_count",
      "unit_id",
    ];

    numericKeys.every((key) => {
      if (
        visibleFields.includes(key as keyof SignalFormData) &&
        // isNaN interprets "" as numbers, so this logic correctly avoids the error for ""
        isNaN((data as any)[key])
      ) {
        setError(
          `${
            key === "numSignals" ? "Number of signals" : FIELD_TO_LABEL[key]
          } must be numeric`
        );
        isValid = false;
        return false;
      }
      return true;
    });

    // Change cell ID obj to the actual cell ID for serial HPHCs and modules
    if (
      dutType !== "cell" &&
      "cell_id" in data &&
      data["cell_id"] !== "" &&
      typeof data["cell_id"]["cell_id"] === "string"
    ) {
      data["cell_id"] = parseInt(data["cell_id"]["cell_id"].replace("CEL", ""));
    }

    if (isValid && signalMap) {
      await dispatch(
        updateSignalMap({
          test_id: signalMap.test_id,
          formData: data,
          objectURLStr: "signals",
          ...(isEdit && { selected: selected, visibleFields: visibleFields }),
        })
      );

      dispatch(
        sortSignals({
          order: signalOrder,
          key: signalOrderBy,
        })
      );
    }
  };

  const getAssociatedFaultConditions = () => {
    // Returns the fault conditions that are associated
    // with the selected signals
    let temp = faultConditions.filter((fc) => {
      let hasSelectedSignal = false;
      fc.variables.every((v) => {
        hasSelectedSignal =
          hasSelectedSignal ||
          (!isNull(v.signal_id) && selected.includes(v.signal_id));
        return true;
      });
      return hasSelectedSignal;
    });
    return temp;
  };

  useEffect(() => {
    if (updateStatus === "succeeded") {
      // Reset form data on success
      setMeasurementType("");
      setSensorType("");
    }
  }, [updateStatus]);

  useEffect(() => {
    if (open) {
      // Reset visible fields when the add modal is opened
      setVisibleFields(isAdd ? ALL_VISIBLE_FIELDS : []);
      if (!isNull(formData)) {
        // Populate the modal with form data if present
        reset({ ...defaultFormData, ...formData });
        setMeasurementType(formData.measurementType);
        setSensorType(formData.sensorType);
      } else {
        // Otherwise, reset the form entirely
        reset({ ...defaultFormData });
      }
    }
    // eslint-disable-next-line
  }, [open, isAdd]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        {isDelete ? (
          <>
            <Typography variant="h2" mb={5} mt={5} textAlign="center">
              {`Are you sure you want to delete the following ${
                selected.length
              } signal${selected.length > 1 ? "s" : ""}?`}
            </Typography>

            {signals
              .filter((signal) => selected.includes(signal.signal_id))
              .map((signal, i) => (
                <Typography key={i} className="small" textAlign="center">
                  {signal.name}
                </Typography>
              ))}

            {getAssociatedFaultConditions().length > 0 && (
              <>
                <Typography
                  className="small"
                  mb={5}
                  mt={7}
                  textAlign="center"
                  style={{ fontWeight: "bold" }}
                >
                  {`Deleting ${
                    selected.length === 1 ? "this" : "these"
                  } signal${
                    selected.length > 1 ? "s" : ""
                  } will also delete the following associated fault conditions:`}
                </Typography>

                {getAssociatedFaultConditions().map((fc, i) => (
                  <Typography key={i} className="small" textAlign="center">
                    {fc.parsed_expression_string}
                  </Typography>
                ))}
              </>
            )}

            <Box
              p={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                color="primary"
                size="small"
                type="submit"
                disabled={deleteStatus === "loading" || selected.length === 0}
                style={{
                  width: "fit-content",
                  marginRight: "5px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                color="secondary"
                size="small"
                type="submit"
                disabled={deleteStatus === "loading" || selected.length === 0}
                style={{
                  width: "fit-content",
                }}
                onClick={() => dispatch(deleteSignals())}
                endIcon={
                  deleteStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                Delete
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h2" mb={isAdd && isPrivileged ? 5 : 0}>
              {mode.charAt(0).toUpperCase() + mode.slice(1)} Signals
            </Typography>
            {isEdit && (
              <Typography className="small" mb={5}>
                {signals
                  .filter((signal) => selected.includes(signal.signal_id))
                  .map((signal) => signal.name)
                  .join(", ")}
              </Typography>
            )}

            {isEdit && (
              <>
                <Typography className="small" style={{ display: "flex" }}>
                  Add fields to edit:
                  <Tooltip
                    arrow
                    title="Any fields that are submitted with no value will have their data cleared."
                    placement="right"
                  >
                    <span>
                      <Info style={{ marginLeft: "5px" }} />
                    </span>
                  </Tooltip>
                </Typography>

                <Select
                  value=""
                  onChange={(e) => {
                    let val = e.target.value as keyof SignalFormData;
                    let newFields = [
                      ...visibleFields,
                      val,
                      // Remove sensorType and units when removing measurementType
                      ...(val === "measurementType"
                        ? dutType === "cell"
                          ? ["sensorType", "units"].map(
                              (x) => x as keyof SignalFormData
                            )
                          : ["sensorType", "units"].map(
                              (x) => x as keyof SignalFormData
                            )
                        : []),
                    ];
                    setVisibleFields(newFields);
                  }}
                  style={{ width: "50%" }}
                >
                  {ALL_VISIBLE_FIELDS.filter(
                    (f) =>
                      ![...visibleFields, "sensorType", "units"].includes(f)
                  ).map((field) => (
                    <MenuItem key={field} value={field}>
                      {FIELD_TO_LABEL[field]}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              {visibleFields.map((field) => (
                <SignalsModalInput
                  key={field}
                  field={field}
                  isAdd={isAdd}
                  isEdit={isEdit}
                  FIELD_TO_LABEL={FIELD_TO_LABEL}
                  control={control}
                  removeField={removeField}
                  measurementType={measurementType}
                  setMeasurementType={setMeasurementType}
                  sensorType={sensorType}
                  setSensorType={setSensorType}
                  allSensorTypes={allSensorTypes}
                  allDeviceClasses={allDeviceClasses}
                  allSignalGroups={allSignalGroups}
                />
              ))}

              {isAdd && (
                <>
                  <Typography className="small" color="textSecondary" mt={5}>
                    Number of Signals
                    <span style={{ color: colors.accent.red }}>*</span>
                  </Typography>

                  <FormControl style={{ width: "100%", height: "100%" }}>
                    <Controller
                      control={control}
                      name="numSignals"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid },
                      }) => (
                        <Input
                          className="small"
                          disableUnderline
                          inputRef={ref}
                          error={invalid}
                          name={name}
                          onBlur={onBlur}
                          onChange={(e) => {
                            setNumSignals(e.target.value);
                            onChange(e);
                          }}
                          value={numSignals}
                          inputProps={{ maxLength: 100 }}
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}

              {!isPrivileged && (
                <Typography className="small" color="textSecondary" mt={5}>
                  Offset and gain are only editable by certain users.
                </Typography>
              )}

              <Button
                color="primary"
                size="small"
                disabled={
                  updateStatus === "loading" || visibleFields.length === 0
                }
                type="submit"
                style={{
                  width: "fit-content",
                  marginTop: "20px",
                }}
                endIcon={
                  updateStatus === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                {isAdd &&
                  `Add ${numSignals} Signal${
                    parseInt(numSignals) > 1 ? "s" : ""
                  }`}
                {isEdit &&
                  `Edit ${selected.length} Signal${
                    selected.length > 1 ? "s" : ""
                  }`}
              </Button>
            </form>
          </>
        )}
      </Modal>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default ModifySignalsModal;
