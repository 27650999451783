import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const Check = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1359 3.10699C13.4911 3.3201 13.6063 3.7808 13.3932 4.13599L8.89315 11.636C8.78273 11.82 8.59873 11.948 8.38775 11.9874C8.17676 12.0268 7.959 11.9739 7.78958 11.8421L3.28958 8.34213C2.96262 8.08783 2.90372 7.61662 3.15802 7.28966C3.41232 6.9627 3.88353 6.9038 4.21049 7.1581L8.04238 10.1385L12.1069 3.36424C12.32 3.00906 12.7807 2.89388 13.1359 3.10699Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Check;
