import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const FEATURE_FLAGS = "FEATURE_FLAGS";
export const LAUNCH_BATCH_CONDITION_SPECIFICATION =
  "launch_batch_condition_specification";

export interface FeatureFlagsState {
  enabledFlags: null | string[];
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
  };
}

const initialState: FeatureFlagsState = {
  enabledFlags: null,
  status: {
    get: "idle",
  },
  error: {
    get: null,
  },
};

export const getEnabledFlags = createAsyncThunk(
  `${FEATURE_FLAGS}/getEnabledFlags`,
  async () => {
    const response = await client.get("meta/feature-flags");
    return response.data.enabled_flags;
  }
);

const featureFlagsSlice = createSlice({
  name: FEATURE_FLAGS,
  initialState,
  reducers: {
    resetGetFeatureFlagsStatus(state) {
      state.status.get = "idle";
      state.error.get = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnabledFlags.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getEnabledFlags.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.enabledFlags = payload;
      })
      .addCase(getEnabledFlags.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      });
  },
});

export const { resetGetFeatureFlagsStatus } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
