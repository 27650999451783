import TableCell from "@mui/material/TableCell";
import { isNil, uniq } from "lodash";
import FlagTableCell from "../../components/table/FlagTableCell";

type Props = {
  dataKey: FalconKey<TestStand>;
  testStand: TestStand;
};

const TestStandTableCell = ({ dataKey, testStand }: Props) => {
  switch (dataKey) {
    case "name":
      return (
        <TableCell>
          <div style={{ display: "flex" }}>{testStand.name || "-"}</div>
        </TableCell>
      );

    case "active_reservations":
      return (
        <TableCell>
          <div style={{ display: "flex" }}>
            {testStand.active_reservations.length || "0"}
          </div>
        </TableCell>
      );

    case "channels":
      return (
        <TableCell>
          <div style={{ display: "flex" }}>
            {`${
              uniq(
                testStand.channels
                  .filter(
                    (channel) =>
                      !!(channel.cell.cell_id || channel.cell.module.module_id)
                  )
                  .map(
                    (channelWithTestStand) =>
                      channelWithTestStand.cell.cell_id ||
                      channelWithTestStand.cell.module.module_id
                  )
              ).length || "0"
            } / ${Math.max(
              ...testStand.channels
                .filter(({ channel: { max_tests } }) => !!max_tests)
                .map(({ channel }) => channel.max_tests!),
              1
            )}`}
          </div>
        </TableCell>
      );

    case "h2_sensor_type":
    case "temperature_range":
    case "gde_air_range":
    case "sweep_air_range":
    case "max_current_limit":
    case "daq_channels":
      return (
        <TableCell>
          <div style={{ display: "flex" }}>{testStand[dataKey] || "-"}</div>
        </TableCell>
      );

    case "anode_current_sensors":
    case "dual_oee":
    case "one_half_cell":
    case "two_half_cells":
    case "proto_1":
    case "proto_2":
    case "evt":
    case "megasus":
    case "flex_cell":
    case "vehicle_303":
      return isNil(testStand[dataKey]) ? (
        <FlagTableCell icons={["na"]} />
      ) : (
        <FlagTableCell icons={[testStand[dataKey] ? "check" : "times"]} />
      );

    default:
      return <TableCell>{dataKey} not implemented</TableCell>;
  }
};

export default TestStandTableCell;
