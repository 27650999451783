import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "../../icons/ArrowBack";
import DownloadJSONButtons from "./DownloadJSONButtons";
import FaultConditionsListView from "./FaultConditionsListView";
import SignalMapSummary from "./SignalMapSummary";
import SignalsListView from "./SignalsListView";
import Toast from "../../components/Toast";

import {
  Box,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";

import type { RootState } from "../../store";
import { resetState, getSignalMap } from "./slice";
import type { SignalMapState } from "./slice";

const SignalMapLayout = ({ dutType }: { dutType: "cell" | "module" }) => {
  const { dut_id = "" } = useParams();
  const isTablet = useMediaQuery("(min-width:760px)");
  const [selectedTab, setSelectedTab] = useState<
    "SIGNALS" | "FAULT_CONDITIONS"
  >("SIGNALS");
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const {
    signalMap,
    dutType: _dutType,
    moduleName,
    status: { get: getStatus },
    error: { get: getError },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );

  useEffect(() => {
    dispatch(resetState());
    dispatch(
      getSignalMap({
        dut_id: parseInt(dut_id),
        dutType: dutType,
        test_id: urlParams.get("test-id"),
      })
    );
    // eslint-disable-next-line
  }, [dut_id, dutType, dispatch]);

  useEffect(() => {
    if (getStatus === "failed") {
      setError(getError);
    } else {
      setError(null);
    }
  }, [getStatus, getError]);

  // List view contents
  let contents;
  switch (selectedTab) {
    case "SIGNALS":
      contents = <SignalsListView />;
      break;
    case "FAULT_CONDITIONS":
      contents = <FaultConditionsListView />;
      break;
  }

  return (
    <>
      {/*Header*/}
      <Paper variant="outlined" square>
        <Box
          px={isTablet ? 6 : 0}
          py={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton
              size="large"
              onClick={() => {
                _dutType === "cell"
                  ? navigate(`/cells/${dut_id}`)
                  : navigate(`/experiments/${signalMap?.exp_id}`);
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography
              variant="h2"
              style={{ marginBottom: "15px", position: "relative" }}
            >
              Signal Map for{" "}
              <span style={{ fontWeight: 400 }}>
                {_dutType === "cell"
                  ? `CEL${dut_id}`
                  : !!moduleName
                  ? `${moduleName}`
                  : `MOD${dut_id}`}
              </span>
              <Link
                color="secondary"
                variant="subtitle1"
                href="https://wiki.formenergy.com/display/BD/106.+Signal+Mapping+in+Oak"
                target="_blank"
                rel="noreferrer"
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "-20px",
                  fontSize: "15px",
                }}
              >
                Documentation
              </Link>
            </Typography>
          </Box>

          {signalMap && (
            <DownloadJSONButtons
              dut_id={dut_id}
              onError={(err) => setError(err)}
            />
          )}
        </Box>
      </Paper>

      {/*Summary*/}
      <SignalMapSummary />

      <Box
        px={isTablet ? 6 : 0}
        my={4}
        style={{ minHeight: "calc(100vh - 48px - 60px)" }}
      >
        <Paper variant="outlined" square>
          <Box px={isTablet ? 6 : 0} my={4}>
            {isTablet ? (
              <Tabs
                value={selectedTab}
                onChange={(e, val) => setSelectedTab(val)}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab value="SIGNALS" label={"Signals"} />

                {_dutType === "module" && (
                  <Tab value="FAULT_CONDITIONS" label={"Fault Conditions"} />
                )}
              </Tabs>
            ) : null}
            {contents}
          </Box>
        </Paper>
      </Box>

      {error && (
        <Toast open severity="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
};

export default SignalMapLayout;
