import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const EXPERIMENT_SINGLE = "EXPERIMENT_SINGLE";

export interface ExperimentSingleState {
  experiment: Experiment | null;
  cellInfoBeforeDuplicate: Cell | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
    getConditionInfoForCell: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    save: null | string;
    getConditionInfoForCell: null | string;
  };
}

const initialState: ExperimentSingleState = {
  experiment: null,
  cellInfoBeforeDuplicate: null,
  status: {
    get: "idle",
    save: "idle",
    getConditionInfoForCell: "idle",
  },
  error: {
    get: null,
    save: null,
    getConditionInfoForCell: null,
  },
};

export const getSingleExperiment = createAsyncThunk(
  `${EXPERIMENT_SINGLE}/get`,
  async (exp_id: number) => {
    const response = await client.get(`meta/experiments/${exp_id}`);
    return response.data;
  }
);

export const getConditionInfoForCell = createAsyncThunk(
  `${EXPERIMENT_SINGLE}/getConditionForCell`,
  async (cell_id: number) => {
    const response = await client.get(`meta/cells/${cell_id}`);
    return response.data;
  }
);

export const saveSingleExperiment = createAsyncThunk(
  `${EXPERIMENT_SINGLE}/save`,
  async (exp_fields: {
    exp_id: number;
    status?: string;
    project_id?: number;
    owner_id?: number;
  }) => {
    const response = await client.put(
      `meta/experiments/${exp_fields.exp_id}`,
      exp_fields
    );
    return response.data;
  }
);

const filtersSlice = createSlice({
  name: EXPERIMENT_SINGLE,
  initialState,
  reducers: {
    resetSingleExperiment: (state) => Object.assign(state, initialState),
    resetConditionInfoForCell: (state) => {
      state.cellInfoBeforeDuplicate = null;
      state.status.getConditionInfoForCell = "idle";
      state.error.getConditionInfoForCell = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get single experiment
      .addCase(getSingleExperiment.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSingleExperiment.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.experiment = payload;
      })
      .addCase(getSingleExperiment.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      })
      // Get Cell info for 'Duplicate Previous Test Condition' modal
      .addCase(getConditionInfoForCell.pending, (state) => {
        state.status.getConditionInfoForCell = "loading";
      })
      .addCase(getConditionInfoForCell.fulfilled, (state, { payload }) => {
        state.status.getConditionInfoForCell = "succeeded";
        state.cellInfoBeforeDuplicate = payload;
      })
      .addCase(getConditionInfoForCell.rejected, (state, { error }) => {
        state.status.getConditionInfoForCell = "failed";
        state.error.getConditionInfoForCell = error.message as string;
      })
      // Save single experiment
      .addCase(saveSingleExperiment.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(saveSingleExperiment.fulfilled, (state, { payload }) => {
        state.status.save = "succeeded";
        state.experiment = payload;
      })
      .addCase(saveSingleExperiment.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      });
  },
});

export const { resetConditionInfoForCell, resetSingleExperiment } =
  filtersSlice.actions;

export default filtersSlice.reducer;
