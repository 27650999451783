import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Toast";
import ExistingConditionLayout from "./layout/ExistingConditionLayout";
import NoConditionLayout from "./layout/NoConditionLayout";
import LoadingLayout from "../../layout/Loading";
import type { RootState } from "../../store";
import { getUIDefinition, ConditionUIState } from "./uiSlice";
import {
  ExperimentSingleState,
  getSingleExperiment,
  resetSingleExperiment,
} from "../experiments/singleSlice";
import {
  ConditionState,
  getConditions,
  resetCreateCondition,
  resetDuplicateCondition,
  resetSaveCondition,
  resetSpecifyCondition,
  resetDeleteCondition,
  resetPreviewChangeRequests,
} from "./slice";
import { AuthState } from "../../store/auth/slice";

type Props = {
  isNew?: boolean;
};

const ConditionLayout = ({ isNew = false }: Props) => {
  const { exp_id = "", cell_condition_id = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadedCellConditionGroup, setLoadedCellConditionGroup] =
    useState(false);
  const {
    conditions,
    createdConditionId,
    status: { copy: conditionCopyStatus, ...conditionStatus },
    error: conditionError,
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);

  const {
    status: { get: expStatus },
    error: { get: expError },
  } = useSelector<RootState, ExperimentSingleState>(
    ({ experimentSingle }) => experimentSingle
  );
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  useEffect(() => {
    dispatch(resetCreateCondition());
    dispatch(resetDuplicateCondition());
    dispatch(resetSaveCondition());
    dispatch(resetSpecifyCondition());
    dispatch(resetDeleteCondition());
    dispatch(resetPreviewChangeRequests());
    dispatch(getUIDefinition());
  }, [dispatch]);

  useEffect(() => {
    if (!loadedCellConditionGroup) {
      dispatch(resetSingleExperiment());
      dispatch(getSingleExperiment(parseInt(exp_id)));
      dispatch(
        getConditions({
          exp_id: parseInt(exp_id),
          condition_ids_requested: cell_condition_id
            ? [cell_condition_id]
            : null,
        })
      );
    }
    // eslint-disable-next-line
  }, [exp_id, user, dispatch, loadedCellConditionGroup]);

  useEffect(() => {
    if (isNew) {
      navigate(`/experiments/${exp_id}/conditions`, { replace: true });
    }
  }, [exp_id, isNew, navigate]);

  useEffect(() => {
    if (createdConditionId) {
      navigate(`/experiments/${exp_id}/conditions/${createdConditionId}`);
      setNewViewInit(false);
      setLoadedCellConditionGroup(false);
    }
  }, [createdConditionId, exp_id, navigate]);

  useEffect(() => {
    if (conditionCopyStatus === "succeeded") {
      dispatch(resetDuplicateCondition());
    }
  }, [conditionCopyStatus, dispatch]);

  useEffect(() => {
    if (!loadedCellConditionGroup && conditionStatus.get === "succeeded") {
      setLoadedCellConditionGroup(true);
    }
  }, [conditionStatus.get, loadedCellConditionGroup]);

  const [newViewInit, setNewViewInit] = useState(isNew);

  /* UI definition */
  const { status: uiStatus, error: uiError } = useSelector<
    RootState,
    ConditionUIState
  >(({ conditionUI }) => conditionUI);

  const ready =
    uiStatus === "succeeded" &&
    expStatus === "succeeded" &&
    loadedCellConditionGroup;

  if (!ready) {
    return (
      <LoadingLayout
        light
        fullscreen
        error={uiError || expError || conditionError.get || ""}
      />
    );
  }

  const toasts = (
    <>
      <Toast
        severity="error"
        open={conditionStatus.create === "failed"}
        onClose={() => dispatch(resetCreateCondition())}
      >
        {conditionError.create}
      </Toast>
      <Toast
        severity="error"
        open={conditionCopyStatus === "failed"}
        onClose={() => dispatch(resetDuplicateCondition())}
      >
        {conditionError.copy}
      </Toast>
      <Toast
        severity="error"
        open={conditionStatus.save === "failed"}
        onClose={() => dispatch(resetSaveCondition())}
      >
        {conditionError.save}
      </Toast>

      <Toast
        severity="error"
        open={conditionStatus.delete === "failed"}
        onClose={() => dispatch(resetDeleteCondition())}
      >
        {conditionError.delete}
      </Toast>
      <Toast
        severity="error"
        open={conditionStatus.preview === "failed"}
        onClose={() => dispatch(resetPreviewChangeRequests())}
      >
        {conditionError.preview}
      </Toast>
      <Toast
        severity="success"
        open={conditionStatus.delete === "succeeded"}
        onClose={() => dispatch(resetDeleteCondition())}
      >
        Your condition was successfully deleted.
      </Toast>
    </>
  );

  if (!conditions || conditions.length === 0) {
    return (
      <>
        <NoConditionLayout
          exp_id={exp_id}
          onNext={() => setNewViewInit(false)}
        />
        {toasts}
      </>
    );
  }

  return (
    <>
      <ExistingConditionLayout exp_id={exp_id} newViewInit={newViewInit} />
      {toasts}
    </>
  );
};

export default ConditionLayout;
