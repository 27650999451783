import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import styled from "@mui/styles/styled";

import type { RootState } from "../store";
import Button from "../components/Button";
import logo from "../images/logo-black.png";
import googleLogo from "../images/google.png";
import colors from "../theme/colors";
import { useEffect } from "react";
import TestBotLogin from "../components/forms/TestBotLogin";
import { useGoogleLogin } from "@react-oauth/google";
import { logIn } from "../store/auth/slice";

const GOOGLE_OAUTH_SCOPES = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "openid",
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/presentations"
].join(" ");

const Layout = styled(Box)({
  background: colors.text.primary,
  height: "100vh",
  width: "100vw",
});

const FormContainer = styled(Paper)({
  borderRadius: 3,
  minWidth: 324,
});

const Auth = () => {
  const user = useSelector<RootState, null | User>(({ auth }) => auth.user);
  const dispatch = useDispatch();

  const location = useLocation();
  const { from } = (location.state as LocationState) || {
    from: { pathname: "/" },
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  const handleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(logIn(codeResponse));
    },
    flow: "auth-code",
    ux_mode: "popup",
    scope: GOOGLE_OAUTH_SCOPES,
  });

  return (
    <Layout display="flex" justifyContent="center" alignItems="center">
      <FormContainer>
        <Box p={8} textAlign="center">
          <img src={logo} style={{ maxWidth: 120 }} alt="Form Energy Logo" />
          <Box my={8}>
            <Divider />
          </Box>
          <Box my={6} display="flex" justifyContent="center">
            <img src={googleLogo} alt="Login with Google" />
            <Box ml={3}>Login with Google</Box>
          </Box>
          <Button
            color="cta"
            type="button"
            fullWidth
            onClick={() => handleSignIn()}
          >
            Continue
          </Button>
        </Box>
        {process.env.REACT_APP_ENVIRONMENT === "test" && <TestBotLogin />}
      </FormContainer>
    </Layout>
  );
};
export default Auth;
