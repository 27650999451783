import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import type { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  addSavedFilter,
  ChannelSavedFilterState,
} from "../../features/channels/filtersSlice";
import Button from "../Button";
import Modal from "../Modal";
import Toast from "../Toast";

type SaveChannelFilterModalProps = {
  open: boolean;
  data: ChannelFilters;
  onClose: () => void;
  onAfterSave: () => void;
};

const SaveChannelFilterModal = ({
  open,
  data,
  onClose,
  onAfterSave,
}: SaveChannelFilterModalProps) => {
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const {
    status: { save: status },
    error: { save: error },
  } = useSelector<RootState, ChannelSavedFilterState>(
    ({ channelFilters }) => channelFilters
  );

  useEffect(() => setTitle(""), [open]);

  const handleSave = useCallback(async () => {
    try {
      await dispatch(
        addSavedFilter({
          title,
          data,
        })
      );
      setTitle("");
      onAfterSave();
    } catch (err) {}
  }, [dispatch, data, title, onAfterSave]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) {
        return;
      }

      if (event.key === "Enter" && title) {
        handleSave();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [open, title, handleSave]);

  if (status === "failed") {
    return (
      <Toast open severity="error">
        {error}
      </Toast>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Typography variant="h2">Save filter</Typography>
        <Box mt={8} mb={2}>
          <Typography color="textSecondary">Filter name:</Typography>
        </Box>
        <FormControl fullWidth>
          <Input
            disableUnderline
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <Box mt={8} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            disabled={!title || status === "loading"}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default SaveChannelFilterModal;
