import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isNil } from "lodash";

import Button from "../../components/Button";
import ModifySignalsModal from "./ModifySignalsModal";
import Toast from "../../components/Toast";

import { Box, Tooltip } from "@mui/material";

import type { RootState } from "../../store";
import type { SignalMapState } from "./slice";

type Props = {
  allSensorTypes: AllSensorTypes;
  allDeviceClasses: string[];
  allSignalGroups: { [key: string]: number | string }[];
};

const SignalModifiers = ({
  allSensorTypes,
  allDeviceClasses,
  allSignalGroups,
}: Props) => {
  const [mode, setMode] = useState<"add" | "edit" | "delete">("add");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [formData, setFormData] = useState<any | null>(null);

  const {
    signalMap,
    signals,
    dutType,
    selected: { signals: selected },
    status: { update: updateStatus, delete: deleteStatus },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  let isOnTest = signalMap?.is_on_test;

  // "Update" response handler
  useEffect(() => {
    if (updateStatus === "succeeded") {
      setModalOpen(false);
      setSuccess("Signal map successfully updated");
    } else {
      setSuccess(null);
    }
  }, [updateStatus]);

  // "Delete" response handler
  useEffect(() => {
    if (deleteStatus === "succeeded") {
      setModalOpen(false);
      setSuccess("Signal(s) successfully deleted");
    } else {
      setSuccess(null);
    }
  }, [deleteStatus]);

  return (
    <>
      <Box
        py={6}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {selected.length !== 1 ? (
          <Tooltip
            arrow
            title={
              selected.length === 0
                ? "Select a signal to duplicate"
                : "Signals can only be duplicated one at a time"
            }
            placement="right"
          >
            <div>
              <Button
                color="secondary"
                size="small"
                type="submit"
                disabled={true}
                style={{
                  width: "fit-content",
                  marginRight: "5px",
                }}
              >
                Duplicate
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            color="secondary"
            size="small"
            type="submit"
            disabled={false}
            style={{
              width: "fit-content",
              marginRight: "5px",
            }}
            onClick={() => {
              let signalID = selected[0];
              let signal = signals.find(
                ({ signal_id }) => signal_id === signalID
              );
              let signalData: any = {
                // Evergreen fields
                measurementType: signal!.measurement_type,
                sensorType: signal!.sensor_type_raw,
                deviceClass: signal!.device_class,
                units: signal!.units,
                namingPattern: `${signal!.name}`,
                addressPattern: `${signal!.device_address}`,
              };

              let optionalKeys: (keyof Signal)[] = [
                "offset",
                "gain",
                "power_coeff_2",
                "power_coeff_3",
                "power_coeff_4",
                "power_coeff_5",
                "thermocouple_type",
                "cell_id",
                "signal_group_id",
                "signal_range",
                "start_register",
                "register_count",
                "big_endian",
                "unit_id",
                "board",
                "port_name",
              ];
              optionalKeys.forEach((k) => {
                if (signal && !isNil(signal[k])) {
                  if (k === "cell_id") {
                    signalData["cell_id"] = {
                      cell_id: signal.cell_id,
                      cell_position_id: signal.cell_position_id,
                    };
                  } else {
                    signalData[k] = signal[k];
                  }
                }
              });

              setFormData(signalData);
              setMode("add");
              setModalOpen(true);
            }}
          >
            Duplicate
          </Button>
        )}

        {isOnTest ? (
          <Tooltip
            arrow
            title={`Signals cannot be deleted while the ${dutType} is on-test`}
            placement="right"
          >
            <div>
              <Button
                color="secondary"
                size="small"
                type="submit"
                disabled={true}
                style={{
                  width: "fit-content",
                  marginRight: "5px",
                }}
              >
                Delete
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            color="secondary"
            size="small"
            type="submit"
            disabled={selected.length === 0}
            style={{
              width: "fit-content",
              marginRight: "5px",
            }}
            onClick={() => {
              setMode("delete");
              setModalOpen(true);
            }}
          >
            Delete
          </Button>
        )}

        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={selected.length === 0}
          style={{
            width: "fit-content",
            margin: "auto 5px",
          }}
          onClick={() => {
            setMode("edit");
            setModalOpen(true);
          }}
        >
          Edit
        </Button>

        <Button
          color="primary"
          size="small"
          type="submit"
          style={{
            width: "fit-content",
            marginLeft: "5px",
          }}
          onClick={() => {
            setMode("add");
            setModalOpen(true);
          }}
        >
          Add Signals
        </Button>
      </Box>

      <ModifySignalsModal
        mode={mode}
        open={modalOpen}
        formData={formData}
        onClose={() => {
          setModalOpen(false);
          setFormData(null);
        }}
        allDeviceClasses={allDeviceClasses}
        allSensorTypes={allSensorTypes}
        allSignalGroups={allSignalGroups}
      />

      {success && (
        <Toast open severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Toast>
      )}
    </>
  );
};

export default SignalModifiers;
