import { useForm, Controller } from "react-hook-form";

import Button from "../../components/Button";
import colors from "../../theme/colors";

import {
  FormControl,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

interface CellForm {
  loggingPeriod: number;
  notes: string;
}

const CellTestScriptPreDownloadForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (data: any) => void;
  loading: boolean;
}) => {
  const LOGGING_PERIOD_OPTIONS = [
    { label: "2 minutes (Default)", value: 120 },
    { label: "10 minutes (Slow)", value: 600 },
    { label: "1 hour (Super Slow)", value: 3600 },
  ];

  const { control, handleSubmit } = useForm<CellForm>({
    defaultValues: {
      loggingPeriod: 120,
      notes: "",
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2" mb={5}>
          Please enter the following:
        </Typography>

        <Typography className="small" color="textSecondary">
          Logging Period (s)
          <span style={{ color: colors.accent.red }}>*</span>
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="loggingPeriod"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Select
                onChange={onChange}
                value={value}
                style={{ width: "100%", marginBottom: "20px" }}
              >
                {LOGGING_PERIOD_OPTIONS.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Typography className="small" color="textSecondary">
          Notes
        </Typography>

        <FormControl style={{ width: "100%", height: "100%" }}>
          <Controller
            control={control}
            name="notes"
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid },
            }) => (
              <Input
                className="small"
                disableUnderline
                inputRef={ref}
                error={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </FormControl>

        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={loading}
          style={{
            width: "fit-content",
            float: "left",
            marginTop: "20px",
          }}
        >
          Download
        </Button>
      </form>
    </>
  );
};

export default CellTestScriptPreDownloadForm;
