import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Button from "../components/Button";
import ArrowBackIcon from "../icons/ArrowBack";
import ExpandIcon from "../icons/Expand";
import NavigationMenuHorizontalIcon from "../icons/NavigationMenuHorizontal";
import SearchIcon from "../icons/Search";
import { cellFilterToString } from "../utils/labels";
import type { RootState } from "../store";
import { cellsSlices, CellListState } from "../features/cells/slice";
import {
  listSavedFilters,
  deleteSavedFilter,
  CellSavedFilterState,
} from "../features/cells/filtersSlice";

const SavedCellFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stateKey } = (location.state as {
    stateKey: keyof typeof cellsSlices;
  }) || { stateKey: "ALL" };

  const { args } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  const {
    savedFilters,
    status: { list: status },
  } = useSelector<RootState, CellSavedFilterState>(
    ({ cellFilters }) => cellFilters
  );
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(savedFilters);

  useEffect(() => {
    if (status === "idle") {
      dispatch(listSavedFilters());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setFilters(savedFilters);
  }, [savedFilters]);

  useEffect(() => {
    setFilters(
      savedFilters.filter(({ title }) =>
        title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, savedFilters]);

  return (
    <>
      <Box px={6} py={3} display="flex" alignItems="center">
        <IconButton size="large" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h2">Saved Filters</Typography>
        <Box ml="auto" display="flex" alignItems="center">
          <FormControl>
            <Input
              disableUnderline
              placeholder="Filter name"
              startAdornment={
                <Box pl={2}>
                  <SearchIcon />
                </Box>
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </FormControl>
        </Box>
      </Box>

      <Box px={6} py={8}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Filter name</TableCell>
                <TableCell colSpan={2}>Filters applied</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filters.map((item, i) => (
                <TableRow key={i} hover>
                  <TableCell>{item.title}</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>
                    {cellFilterToString(item.data)}
                  </TableCell>
                  <TableCell
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DeleteMenu
                      onDelete={() => dispatch(deleteSavedFilter(item))}
                    />
                    <Button
                      color="text"
                      size="small"
                      endIcon={<ExpandIcon />}
                      onClick={async () => {
                        await dispatch(
                          cellsSlices[stateKey].listCells({
                            ...args,
                            filters: item.data,
                          })
                        );
                        navigate(
                          stateKey === "ALL"
                            ? "/cells"
                            : `/cells/${stateKey.toLowerCase()}`
                        );
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

type DeleteMenuProps = {
  onDelete: () => void;
};

const DeleteMenu = ({ onDelete }: DeleteMenuProps) => {
  const [buttonEl, setButtonEl] = useState<null | HTMLButtonElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  return (
    <>
      <IconButton size="small" onClick={handleButtonClick}>
        <NavigationMenuHorizontalIcon />
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            onDelete();
            handleButtonClose();
          }}
        >
          Delete saved filter
        </MenuItem>
      </Menu>
    </>
  );
};

export default SavedCellFilters;
