import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import uniq from "lodash/uniq";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import type { RootState } from "../../store";
import { cellsSlices, CellListState } from "./slice";
import colors from "../../theme/colors";
import CaretBottomIcon from "../../icons/CaretBottom";
import WarningIcon from "../../icons/Warning";
import SummaryContent from "./SummaryContent";
import SummaryChannelUsage from "./SummaryChannelUsage";

type Props = {
  stateKey: keyof typeof cellsSlices;
};

const CellSummary = ({ stateKey }: Props) => {
  const { cells, selected } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  const [selectedTab, setSelectedTab] = useState(1);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setSelectedTab(1);
  }, [stateKey]);

  if (selected.length === 0) {
    return null;
  }

  const selectedCells = cells.filter(({ cell_id }) =>
    selected.includes(cell_id)
  );

  const hasConflict =
    uniq(selectedCells.map(({ condition }) => condition.cell_assembly))
      .length !== 1;

  const header = (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {stateKey === "STAGED" ? (
          <Box mr={4}>
            <Typography
              variant="h3"
              onClick={() => setSelectedTab(0)}
              style={{
                cursor: "pointer",
                fontWeight: 600,
                opacity: selectedTab === 0 ? 1 : 0.5,
              }}
            >
              Channels usage
            </Typography>
          </Box>
        ) : null}
        <Typography
          variant="h3"
          onClick={() => setSelectedTab(1)}
          style={{
            cursor: "pointer",
            fontWeight: 600,
            opacity: selectedTab === 1 ? 1 : 0.5,
          }}
        >
          Summary
        </Typography>
      </Box>
      <IconButton
        size="small"
        style={{ padding: 0, color: colors.text.secondary }}
        onClick={() => setExpanded(!expanded)}
      >
        <CaretBottomIcon
          style={!expanded ? { transform: "rotate(-90deg)" } : {}}
        />
      </IconButton>
    </Box>
  );

  let content;
  if (hasConflict) {
    content = (
      <Box display="flex" alignItems="center">
        <WarningIcon
          color="primary"
          style={{
            width: 16,
            height: 16,
            marginBottom: "-0.1rem",
          }}
        />
        <Box ml={2} className="small" my="auto">
          Please select only 1 build type
        </Box>
      </Box>
    );
  } else if (selectedTab === 0) {
    content = <SummaryChannelUsage stateKey={stateKey} />;
  } else if (selectedTab === 1) {
    content = <SummaryContent selectedCells={selectedCells} />;
  }

  return (
    <Box mb={6} p={6} style={{ backgroundColor: colors.striping }}>
      {header}
      {expanded ? <Box mt={6}>{content}</Box> : null}
    </Box>
  );
};

export default CellSummary;
