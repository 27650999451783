import React from "react";
import VirtualTable from "../../components/table/VirtualTable";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";

import Button from "../../components/Button";
import ExpandIcon from "../../icons/Expand";
import { vhToPx, getVirtualTableHeight } from "../../utils/ui";

type Props = {
  rowHeights: number[];
  tableBody: (JSX.Element | null)[];
  selected: number[];
  hasMore: boolean;
  cells: Cell[];
  disabled: boolean;
  handleToggleSelectAll: () => void;
  groupingEnabled: boolean;
  conditionGroupingEnabled: boolean;
  handleToggleExpandAll: () => void;
  allGroupsExpanded: boolean;
  CELL_LABELS: DataLabel<Cell>[];
  presetExpId?: string;
  orderBy: string;
  order: any;
  handleSortClick: (e: React.MouseEvent, property: FalconKey<Cell>) => void;
  handleLoadMoreClick: () => void;
  hideControls: boolean;
  status: string;
  errorToast?: JSX.Element;
  stageToast?: JSX.Element;
  commitToast?: JSX.Element;
  priorityToast?: JSX.Element;
  readyOffToast?: JSX.Element;
  takeOffToast?: JSX.Element;
  discontinueToast?: JSX.Element;
  modifyToast?: JSX.Element;
  modifyTeardownToast?: JSX.Element;
  cancelReservationToast?: JSX.Element;
  testStandReservationToast?: JSX.Element;
  testModal: JSX.Element | null;
};

const TableBody = ({
  rowHeights,
  tableBody,
  selected,
  hasMore,
  cells,
  disabled,
  handleToggleSelectAll,
  groupingEnabled,
  conditionGroupingEnabled,
  handleToggleExpandAll,
  allGroupsExpanded,
  CELL_LABELS,
  presetExpId,
  orderBy,
  order,
  handleSortClick,
  handleLoadMoreClick,
  hideControls,
  status,
  errorToast,
  stageToast,
  commitToast,
  priorityToast,
  readyOffToast,
  takeOffToast,
  discontinueToast,
  modifyToast,
  modifyTeardownToast,
  cancelReservationToast,
  testStandReservationToast,
  testModal,
}: Props) => {
  return (
    <>
      <VirtualTable
        height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220) + 20}
        width="100%"
        itemCount={tableBody.length}
        itemSize={(index) => rowHeights[index]}
        header={
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={
                    selected.length > 0 &&
                    (hasMore || selected.length < cells.length)
                  }
                  checked={
                    cells.length > 0 &&
                    selected.length === cells.length &&
                    !hasMore
                  }
                  inputProps={{ alt: "select all cells" }}
                  disabled={disabled}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleToggleSelectAll}
                />
              </TableCell>
              <TableCell padding="checkbox">
                {groupingEnabled || conditionGroupingEnabled ? (
                  <IconButton size="small" onClick={handleToggleExpandAll}>
                    <ExpandIcon
                      style={
                        allGroupsExpanded ? { transform: "rotate(90deg)" } : {}
                      }
                    />
                  </IconButton>
                ) : null}
              </TableCell>
              {CELL_LABELS.map((headCell) => {
                if (presetExpId && headCell.id === "experiment__exp_id") {
                  return null;
                }

                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={headCell.id === "cell_id" ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(e) => handleSortClick(e, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              {presetExpId ? <TableCell /> : null}
            </TableRow>
          </TableHead>
        }
        row={({ index }) => tableBody[index] || null}
      />

      <Box
        mt={6}
        px={hideControls ? 3 : 0}
        pb={hideControls ? 3 : 0}
        display="flex"
        alignItems="center"
      >
        {status === "loading" && cells.length === 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>
        ) : cells.length > 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Viewing {cells.length}
          </Typography>
        ) : null}

        {hasMore ? (
          <Box ml={4}>
            <Button
              color="secondary"
              size="small"
              disabled={status === "loading"}
              onClick={handleLoadMoreClick}
            >
              Load more
            </Button>
          </Box>
        ) : null}

        {status === "loading" ? (
          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        ) : null}
      </Box>
      {errorToast}
      {stageToast}
      {commitToast}
      {priorityToast}
      {readyOffToast}
      {takeOffToast}
      {discontinueToast}
      {modifyToast}
      {modifyTeardownToast}
      {cancelReservationToast}
      {testModal}
      {testStandReservationToast}
    </>
  );
};

export default TableBody;
