import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const ApprovalPointer = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 48 44"
  >
    {children}
    <path
      d="M40.5 7L33 16L28.5 11.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 22H4.5C2.84315 22 1.5 20.6569 1.5 19V4C1.5 2.34315 2.84315 1 4.5 1H43.5C45.1569 1 46.5 2.34315 46.5 4V19C46.5 20.6569 45.1569 22 43.5 22H25.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 43L4.97196 36.78C4.21733 35.9509 3.96667 34.7801 4.31567 33.7146C4.66467 32.6492 5.55946 31.8536 6.6584 31.6316C7.75735 31.4097 8.89078 31.7956 9.62596 32.642L13.5 37V19C13.5 17.3431 14.8431 16 16.5 16C18.1568 16 19.5 17.3431 19.5 19V32.5H22.5C27.4705 32.5 31.5 36.5294 31.5 41.5V43"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ApprovalPointer;
