import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";
import { GoogleOAuthProvider } from "@react-oauth/google";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import theme from "./theme";
import App from "./App";
import EnvironmentWarning from "./components/EnvironmentWarning";
import ErrorLayout from "./layout/Error";
import { store } from "./store";
import "./index.css";

if (process.env.REACT_APP_ENVIRONMENT === "test") {
  dotenv.config();
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_GIT_SHA,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        // Uncomment below when https://github.com/getsentry/sentry-javascript/issues/4118 is resolved
        //routingInstrumentation: Sentry.reactRouterV5Instrumentation(),
      }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ["Non-Error exception captured"],
  });
}

ReactDOM.render(
  <StrictMode>
    <GoogleOAuthProvider clientId="877772936785-l3ag7htbfdirt5ojve96istg018mf96k.apps.googleusercontent.com">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorLayout error={error.toString()} onReset={resetError} />
                )}
              >
                <App />
                <EnvironmentWarning />
              </Sentry.ErrorBoundary>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  </StrictMode>,
  document.getElementById("root")
);
