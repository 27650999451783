import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const CHANGE_REQUESTS = "CHANGE_REQUESTS";

export interface ChangeRequestState {
  pendingChangeRequestCount: number;
  pendingChangeRequests: ConditionWithChangeRequests[];
  completedChangeRequests: ConditionWithChangeRequests[];
  singleChangeRequest: ConditionWithChangeRequests | null;
  status: {
    pending: "idle" | "loading" | "succeeded" | "failed";
    complete: "idle" | "loading" | "succeeded" | "failed";
    single: "idle" | "loading" | "succeeded" | "failed";
    approve: "idle" | "loading" | "succeeded" | "failed";
    reject: "idle" | "loading" | "succeeded" | "failed";
    undo: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    pending: null | string;
    complete: null | string;
    single: null | string;
    approve: null | string;
    reject: null | string;
    undo: null | string;
  };
}

const initialState: ChangeRequestState = {
  pendingChangeRequestCount: 0,
  pendingChangeRequests: [],
  completedChangeRequests: [],
  singleChangeRequest: null,
  status: {
    pending: "idle",
    complete: "idle",
    single: "idle",
    approve: "idle",
    reject: "idle",
    undo: "idle",
  },
  error: {
    pending: null,
    complete: null,
    single: null,
    approve: null,
    reject: null,
    undo: null,
  },
};

export const getPendingChangeRequests = createAsyncThunk(
  `${CHANGE_REQUESTS}/pending`,
  async () => {
    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      "meta/change-requests/by-condition/pending"
    );

    return data;
  }
);

export const getCompletedChangeRequests = createAsyncThunk(
  `${CHANGE_REQUESTS}/completed`,
  async () => {
    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      "meta/change-requests/by-condition/completed"
    );

    return data;
  }
);

export const getSingleChangeRequest = createAsyncThunk(
  `${CHANGE_REQUESTS}/single`,
  async (cell_condition_id: string) => {
    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      `meta/change-requests/by-condition?cell_condition_id=${cell_condition_id}`
    );

    return Promise.resolve(data[0]);
  }
);

export const approveChangeRequests = createAsyncThunk(
  `${CHANGE_REQUESTS}/approve`,
  async (change_request_ids: number[]) => {
    const updates = change_request_ids.map((change_request_id) => ({
      method: "POST",
      path: `/api/v1/meta/change-requests/${change_request_id}/approve`,
      data: {},
    }));

    await client.post("core/batch", updates);

    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      "meta/change-requests/by-condition/pending"
    );

    return data;
  }
);

export const rejectChangeRequests = createAsyncThunk(
  `${CHANGE_REQUESTS}/reject`,
  async ({
    change_request_ids,
    reason,
  }: {
    change_request_ids: number[];
    reason: string;
  }) => {
    const updates = change_request_ids.map((change_request_id) => ({
      method: "POST",
      path: `/api/v1/meta/change-requests/${change_request_id}/decline`,
      data: { reason },
    }));

    await client.post("core/batch", updates);

    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      "meta/change-requests/by-condition/pending"
    );

    return data;
  }
);

export const undoChangeRequests = createAsyncThunk(
  `${CHANGE_REQUESTS}/undo`,
  async (change_request_ids: number[]) => {
    const updates = change_request_ids.map((change_request_id) => ({
      method: "POST",
      path: `/api/v1/meta/change-requests/${change_request_id}/undo`,
      data: {},
    }));

    await client.post("core/batch", updates);

    const { data }: { data: ConditionWithChangeRequests[] } = await client.get(
      "meta/change-requests/by-condition/pending"
    );

    return data;
  }
);

const slice = createSlice({
  name: CHANGE_REQUESTS,
  initialState,
  reducers: {
    resetGetPendingChangeRequests(state) {
      state.status.pending = "idle";
      state.error.pending = null;
    },
    resetGetCompletedChangeRequests(state) {
      state.status.complete = "idle";
      state.error.complete = null;
    },
    resetGetSingleChangeRequest(state) {
      state.status.single = "idle";
      state.error.single = null;
    },
    resetApproveChangeRequests(state) {
      state.status.approve = "idle";
      state.error.approve = null;
    },
    resetRejectChangeRequests(state) {
      state.status.reject = "idle";
      state.error.reject = null;
    },
    resetUndoChangeRequests(state) {
      state.status.undo = "idle";
      state.error.undo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingChangeRequests.pending, (state) => {
        state.status.pending = "loading";
      })
      .addCase(getPendingChangeRequests.fulfilled, (state, { payload }) => {
        state.pendingChangeRequestCount = payload
          .flatMap(({ change_requests }) => change_requests)
          .filter(({ status }) => status === "P").length;
        state.pendingChangeRequests = payload;
        state.status.pending = "succeeded";
      })
      .addCase(getPendingChangeRequests.rejected, (state, { error }) => {
        state.status.pending = "failed";
        state.error.pending = error.message as string;
      })
      .addCase(getCompletedChangeRequests.pending, (state) => {
        state.status.complete = "loading";
      })
      .addCase(getCompletedChangeRequests.fulfilled, (state, { payload }) => {
        state.completedChangeRequests = payload;
        state.status.complete = "succeeded";
      })
      .addCase(getCompletedChangeRequests.rejected, (state, { error }) => {
        state.status.complete = "failed";
        state.error.complete = error.message as string;
      })
      .addCase(getSingleChangeRequest.pending, (state) => {
        state.status.single = "loading";
      })
      .addCase(getSingleChangeRequest.fulfilled, (state, { payload }) => {
        state.singleChangeRequest = payload;
        state.status.single = "succeeded";
      })
      .addCase(getSingleChangeRequest.rejected, (state, { error }) => {
        state.status.single = "failed";
        state.error.single = error.message as string;
      })
      .addCase(approveChangeRequests.pending, (state) => {
        state.status.approve = "loading";
      })
      .addCase(approveChangeRequests.fulfilled, (state, { payload }) => {
        state.pendingChangeRequests = payload;
        state.status.approve = "succeeded";
      })
      .addCase(approveChangeRequests.rejected, (state, { error }) => {
        state.status.approve = "failed";
        state.error.approve = error.message as string;
      })
      .addCase(rejectChangeRequests.pending, (state) => {
        state.status.reject = "loading";
      })
      .addCase(rejectChangeRequests.fulfilled, (state, { payload }) => {
        state.pendingChangeRequests = payload;
        state.status.reject = "succeeded";
      })
      .addCase(rejectChangeRequests.rejected, (state, { error }) => {
        state.status.reject = "failed";
        state.error.reject = error.message as string;
      })
      .addCase(undoChangeRequests.pending, (state) => {
        state.status.undo = "loading";
      })
      .addCase(undoChangeRequests.fulfilled, (state, { payload }) => {
        state.pendingChangeRequests = payload;
        state.status.undo = "succeeded";
      })
      .addCase(undoChangeRequests.rejected, (state, { error }) => {
        state.status.undo = "failed";
        state.error.undo = error.message as string;
      });
  },
});

export const {
  resetGetPendingChangeRequests,
  resetGetCompletedChangeRequests,
  resetGetSingleChangeRequest,
  resetApproveChangeRequests,
  resetRejectChangeRequests,
  resetUndoChangeRequests,
} = slice.actions;

export default slice.reducer;
