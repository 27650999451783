import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const FilterText = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      d="M13.9987 6.00061H20.6664C21.4029 6.00061 22 6.59766 22 7.33415V20.6695C22 21.406 21.4029 22.0031 20.6664 22.0031H8.66459C7.9281 22.0031 7.33105 21.406 7.33105 20.6695V15.3354"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6653 10.0012H19.3356"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.665 14.0018H19.3357"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6675 18.0024H19.3355"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66729 10.0012V7.33415L12.3914 3.61002C12.661 3.34026 12.7416 2.9347 12.5957 2.58235C12.4498 2.22999 12.106 2.00017 11.7246 2H2.94286C2.56148 2.00017 2.21773 2.22999 2.0718 2.58235C1.92588 2.9347 2.0065 3.34026 2.27609 3.61002L6.00021 7.33415V12.6683L8.66729 10.0012Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default FilterText;
