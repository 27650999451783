import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import type { RootState } from "../../store";
import colors from "../../theme/colors";
import {
  TEST_STAND_LABELS_ALL,
  TEST_STAND_LABELS_DEFAULT,
} from "../../utils/labels";
import { TEST_STAND_FILTERS } from "../../utils/filters";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import SearchBarIcon from "../../icons/SearchBar";
import Checkbox from "@mui/material/Checkbox";
import VirtualTable from "../../components/table/VirtualTable";
import {
  vhToPx,
  getVirtualTableHeight,
  EMPTY_STATE_ROW_HEIGHT,
  DATA_ROW_HEIGHT,
} from "../../utils/ui";
import ButtonTestStandFilters from "./ButtonTestStandFilters";
import {
  TestStandState,
  deselectAllVisibleTestStands,
  deselectTestStands,
  listTestStands,
  resetTestStands,
  selectAllVisibleTestStands,
  selectTestStands,
} from "./testStandsSlice";
import TestStandTableCell from "./TestStandTableCell";
import ButtonLogEvent from "../../components/table/ButtonLogEvent";
import ItemCountHeader from "../../components/table/ItemCountHeader";
import { shiftClickList } from "../../utils/shiftClick";

const TestStandsList = () => {
  const {
    testStands,
    selected,
    args: { order, filters, page, orderBy },
    hasMore,
    status: { listTestStands: listTestStandsStatus },
    error: { listTestStands: listTestStandsErrors },
  } = useSelector<RootState, TestStandState>(
    ({ testStandList }) => testStandList
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetTestStands());
  }, [dispatch]);

  useEffect(() => {
    if (listTestStandsStatus === "idle") {
      dispatch(
        listTestStands({
          order,
          orderBy,
          filters,
          page,
          refresh: true,
        })
      );
    }
  }, [dispatch, listTestStandsStatus, order, orderBy, filters, page]);

  let errorToast;
  if (listTestStandsStatus === "failed") {
    errorToast = (
      <Toast
        open
        severity="error"
        onClose={() =>
          dispatch(listTestStands({ order, orderBy, filters, page: 0 }))
        }
      >
        {listTestStandsErrors}
      </Toast>
    );
  }

  const handleSortClick = (
    e: React.MouseEvent,
    property: keyof TestStandFilters
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      listTestStands({
        order: switching ? "desc" : "asc",
        orderBy: property,
        filters,
        page: 0,
      })
    );
  };

  const handleLoadMoreClick = () => {
    dispatch(
      listTestStands({
        order,
        orderBy,
        filters,
        page: page + 1,
      })
    );
  };

  const handleSelectTestStands = (
    selectedIds: string[],
    shiftClick = false
  ) => {
    const allSelected = !selectedIds.some((id) => !selected.includes(id));
    if (allSelected) {
      dispatch(deselectTestStands(selectedIds));
    } else {
      let namesToSelect = shiftClickList(
        selected,
        selectedIds,
        testStands,
        shiftClick
      );
      dispatch(selectTestStands(namesToSelect));
    }
  };

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(deselectAllVisibleTestStands());
    } else {
      dispatch(selectAllVisibleTestStands());
    }
  };

  let tableBody: (JSX.Element | null)[] = [];
  let rowHeights: number[] = [];
  if (testStands.length === 0 && listTestStandsStatus === "succeeded") {
    tableBody = [
      <TableRow>
        <TableCell
          colSpan={TEST_STAND_LABELS_DEFAULT.length + 2}
          style={{ textAlign: "center", borderBottom: 0 }}
        >
          <Box p={10} style={{ color: colors.text.secondary }}>
            <SearchBarIcon style={{ width: 48, height: 48 }} />
            <p>No test stands to show</p>
          </Box>
        </TableCell>
      </TableRow>,
    ];
    rowHeights = [EMPTY_STATE_ROW_HEIGHT];
  } else {
    let rowIndex = 0;

    tableBody = testStands
      .map((row) => {
        rowIndex++;
        rowHeights.push(DATA_ROW_HEIGHT);

        return (
          <React.Fragment key={`grouping-${row.test_stand_id}`}>
            <TableRow
              hover
              className={`clickable ${
                rowIndex % 2 === 0 ? "" : "alternating"
              } ${
                selected.includes(String(row.test_stand_id)) ? "checked" : ""
              }`}
              onClick={() =>
                navigate(`/infrastructure/test-stands/${row.test_stand_id}`)
              }
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  checked={selected.includes(String(row.test_stand_id))}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelectTestStands(
                      [String(row.test_stand_id)],
                      (e.nativeEvent as MouseEvent).shiftKey
                    )
                  }
                />
              </TableCell>
              <TableCell padding="checkbox" />

              {TEST_STAND_LABELS_DEFAULT.map(({ id }, index) => (
                <TestStandTableCell
                  key={index}
                  dataKey={id as keyof TestStandFilters}
                  testStand={row}
                />
              ))}
            </TableRow>
          </React.Fragment>
        );
      })
      .filter((value) => !isNull(value));
  }

  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <ItemCountHeader itemCount={testStands.length} />
        <Box mr={4}>
          <ButtonTestStandFilters
            labels={TEST_STAND_LABELS_ALL}
            filterOptions={TEST_STAND_FILTERS}
            filters={filters}
            onChange={(filters) =>
              dispatch(listTestStands({ order, orderBy, filters, page: 0 }))
            }
          />
        </Box>
        <Box mr={4} />

        {selected.length > 0 && (
          <Box ml={4}>
            <ButtonLogEvent
              itemType={"testStand"}
              objects={testStands.filter(({ test_stand_id }) =>
                selected.includes(`${test_stand_id}`)
              )}
            />
          </Box>
        )}
      </Box>

      <Divider />

      <VirtualTable
        height={getVirtualTableHeight(rowHeights, vhToPx(100) - 220)}
        width="100%"
        itemCount={tableBody.length}
        itemSize={(index) => rowHeights[index]}
        header={
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  indeterminate={
                    selected.length > 0 &&
                    (hasMore || selected.length < testStands.length)
                  }
                  checked={
                    testStands.length > 0 &&
                    selected.length === testStands.length &&
                    !hasMore
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleToggleSelectAll}
                />
              </TableCell>
              <TableCell padding="checkbox" />
              {TEST_STAND_LABELS_DEFAULT.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="normal"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    disabled={
                      !Object.keys(TEST_STAND_LABELS_DEFAULT).includes(
                        headCell.id
                      )
                    }
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) =>
                      handleSortClick(e, headCell.id as keyof TestStandFilters)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        row={({ index }) => tableBody[index] || null}
      />

      <Box mt={6} display="flex" alignItems="center">
        {listTestStandsStatus === "loading" && testStands.length === 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>
        ) : testStands.length > 0 ? (
          <Typography color="textSecondary" variant="inherit" className="small">
            Viewing {testStands.length}
          </Typography>
        ) : null}

        {hasMore ? (
          <Box ml={4}>
            <Button
              color="secondary"
              size="small"
              onClick={handleLoadMoreClick}
            >
              Load more
            </Button>
          </Box>
        ) : null}

        {listTestStandsStatus === "loading" ? (
          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        ) : null}
      </Box>
      {errorToast}
    </>
  );
};

export default TestStandsList;
