import React from "react";
import MuiButton from "@mui/material/Button";
import type { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import styled from "@mui/styles/styled";

import theme from "../theme/button";

type Color = "none" | "cta" | "primary" | "secondary" | "tertiary" | "text";

export type ButtonProps = {
  color: Color;
  target?: string;
} & Omit<MuiButtonProps, "color">;

const getStyle = (variant: Color) => {
  const colors = theme[variant];
  if (!colors) {
    return {};
  }

  return {
    background: colors.background.active,
    color: colors.text,
    borderWidth: 1,
    borderStyle: "solid",
    fontWeight: variant === "tertiary" || variant === "text" ? 400 : 700,
    borderColor: colors.border || colors.background.active,
    "&:hover": {
      background: colors.background.hover,
      borderColor: colors.border || colors.background.hover,
    },
    "&.active": {
      background: colors.background.hover,
      borderColor: colors.border || colors.background.hover,
    },
    "&.active:focus": {
      background: colors.background.hover,
      borderColor: colors.border || colors.background.hover,
    },
    "&:focus": {
      background: colors.background.focus,
      borderColor: colors.border || colors.background.focus,
    },
    "&:disabled": {
      background: colors.background.active,
      color: colors.text,
      opacity: 0.5,
    },
  };
};

const BasicButton = styled(MuiButton)(getStyle("none"));
const CtaButton = styled(MuiButton)(getStyle("cta"));
const PrimaryButton = styled(MuiButton)(getStyle("primary"));
const SecondaryButton = styled(MuiButton)(getStyle("secondary"));
const TertiaryButton = styled(MuiButton)(getStyle("tertiary"));
const TextButton = styled(MuiButton)(getStyle("text"));

const Button = ({ color, ...props }: ButtonProps) => {
  switch (color) {
    case "cta":
      return (
        <CtaButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
    case "none":
      return (
        <BasicButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
    case "primary":
      return (
        <PrimaryButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
    case "secondary":
      return (
        <SecondaryButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
    case "tertiary":
      return (
        <TertiaryButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
    case "text":
      return (
        <TextButton
          disableElevation
          disableRipple
          variant="contained"
          {...props}
        />
      );
  }
};

export default Button;
