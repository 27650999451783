import React, { useState, useEffect } from "react";

// MUI
import Typography from "@mui/material/Typography";

// Components
import Chip from "../Chip";
import EventForm, { EVENT_FACILITIES } from "../forms/EventForm";
import EventIcon from "../../icons/Event";
import Modal from "../Modal";
import { DynamicEventField, ExperimentOwnerMap } from "../forms/slice";
import Toast from "../Toast";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const MODULE_CELLS_WARNING =
  "Visit exp. details page to include all module cells";

type Props = {
  itemType: string;
  objects: any[];
  shrinkButton?: boolean;
};

const ButtonLogEvent = ({ itemType, objects, shrinkButton = false }: Props) => {
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<DynamicEventField[]>([]);
  const [showModuleCellsWarning, setShowModuleCellsWarning] = useState(false);
  const [experimentOwnerMap, setExperimentOwnerMap] =
    useState<ExperimentOwnerMap>({});
  const [link, setLink] = useState<string>("");
  const [facility, setFacility] = useState<string>("");

  const handleNewFacility = (loc: string) => {
    // Ensures all given channel locations are consistent. If not, set
    // the overall channel location to an empty string.
    if (facility === "") {
      setFacility(loc);
    } else if (facility !== loc || !EVENT_FACILITIES.includes(loc)) {
      setFacility("");
    }
  };

  const isCell = (obj: any) => {
    return "cell_id" in obj;
  };
  const isTestStand = (obj: any) => {
    return "test_stand_id" in obj;
  };

  const cleanData = (objects: any[]) => {
    const cells: Cell[] = [];
    const experiment_ids: number[] = [];
    const channelData: [string, number][] = []; // [name, ID]
    const incubatorData: [string, number][] = []; // [name, ID]
    const testerData: [string, number][] = []; // [name, ID]
    const testStandData: [string, number][] = []; // [name, ID]

    const exp_owner_map: ExperimentOwnerMap = {};

    const handleInfrastructureData = (obj: any) => {
      // Record data related to channels, testers, test stands, and incubators
      if (isTestStand(obj)) {
        obj.channels.forEach((testStandChannel: TestStandChannel) => {
          handleNewFacility(testStandChannel.channel.location);

          if (!!testStandChannel.channel.tester.tester_id) {
            const tester_ = testStandChannel.channel.tester;
            if (
              testerData.every(
                ([_, testerId]: [string, number]) =>
                  testerId !== tester_.tester_id
              )
            ) {
              testerData.push([tester_.tester_name, tester_.tester_id]);
            }
          }
        });

        testStandData.every(
          ([_, testStandId]: [string, number]) =>
            testStandId !== obj.test_stand_id
        ) && testStandData.push([obj.name, obj.test_stand_id]);
      } else {
        if (obj.channel.fullname !== null && obj.channel.channel_id !== null) {
          const channelOnTestStand =
            obj.test_stand && obj.test_stand.test_stand_id;
          if (!channelOnTestStand) {
            channelData.push([obj.channel.fullname, obj.channel.channel_id]);
          }
          handleNewFacility(obj.channel.location);
        }

        if (
          obj.tester !== null &&
          obj.tester.name !== null &&
          obj.tester.tester_id !== null
        ) {
          testerData.push([obj.tester.name, obj.tester.tester_id]);
        }
      }

      if (
        !!obj.incubator &&
        !!obj.incubator.name &&
        !!obj.incubator.incubator_id
      ) {
        incubatorData.push([obj.incubator.name, obj.incubator.incubator_id]);
      }

      if (
        !!obj.test_stand &&
        obj.test_stand.test_stand_id &&
        testStandData.every(
          ([_, testStandId]: [string, number]) =>
            testStandId !== obj.test_stand.test_stand_id
        )
      ) {
        testStandData.push([obj.test_stand.name, obj.test_stand.test_stand_id]);
      }
    };

    objects.forEach((obj) => {
      // Get experiment data
      if (isTestStand(obj)) {
        obj.channels.forEach((testStandChannel: TestStandChannel) => {
          let exp_id = testStandChannel.experiment.exp_id;
          if (!!exp_id && !experiment_ids.some((e) => e === exp_id)) {
            experiment_ids.push(exp_id);
            exp_owner_map[exp_id] = testStandChannel.experiment.owner.name;
          }
        });
      } else {
        let exp_id = obj.experiment.exp_id;
        if (!!exp_id && !experiment_ids.some((e) => e === exp_id)) {
          experiment_ids.push(exp_id);
          exp_owner_map[exp_id] = obj.owner.name;
        }
      }

      if (isCell(obj)) {
        cells.push(obj);

        // Get on-test data
        if (obj.status === "N") {
          handleInfrastructureData(obj);
        }
      } else {
        // Get constant data (channels, incubators, testers)
        handleInfrastructureData(obj);

        // Get cell data
        if (isTestStand(obj)) {
          obj.channels.forEach((testStandChannel: TestStandChannel) => {
            const cellId = testStandChannel.cell.cell_id;
            if (
              !!cellId &&
              !cells.flatMap((cell) => cell.cell_id).includes(cellId)
            ) {
              cells.push(testStandChannel.cell);
            }
          });
        } else {
          if (obj.cell.cell_id !== null) {
            cells.push(obj.cell);
          }
        }
      }
    });

    setExperimentOwnerMap(exp_owner_map);

    if (
      itemType === "channel" &&
      cells.length > 0 &&
      cells.some((cell_) => !!cell_.module && !!cell_.module.module_id)
    ) {
      setShowModuleCellsWarning(true);
    }

    createDynamicFields(
      cells,
      experiment_ids,
      channelData,
      incubatorData,
      testerData,
      testStandData
    );
  };

  const createDynamicFields = (
    cells: Cell[],
    experiment_ids: number[],
    channelData: [string, number][],
    incubatorData: [string, number][],
    testerData: [string, number][],
    testStandData: [string, number][]
  ) => {
    const dynamicArray = [];

    if (cells.length > 0) {
      const cellField: DynamicEventField = {
        title: "Cell(s)",
        prefix: "CEL",
        itemType: "cell",
        items: cells.map((cell) => ({
          id: cell.cell_id,
          module_id: cell.module?.module_id,
          exp_id: cell.experiment?.exp_id || cell.module?.exp_id || null,
          module_name: cell.module?.name,
          position_id: cell.module?.position_id,
          cell_serial_number:
            cell.cell_serial_number || cell.full?.serial_number,
        })),
      };

      dynamicArray.push(cellField);
    }

    if (experiment_ids.length > 0) {
      const expField: DynamicEventField = {
        title: "Experiment ID(s)",
        prefix: "EXP",
        itemType: "experiment",
        items: experiment_ids.map((exp_id) => ({
          id: exp_id,
        })),
      };

      dynamicArray.push(expField);
    }

    if (channelData.length > 0 || testStandData.length > 0) {
      const channelNames: string[] = [];
      const incubatorNames: string[] = [];
      const testerNames: string[] = [];

      const channel_ids: number[] = [];
      const incubator_ids: number[] = [];
      const tester_ids: number[] = [];

      for (let i = 0; i < channelData.length; i++) {
        if (!channel_ids.some((c_id) => c_id === channelData[i][1])) {
          channel_ids.push(channelData[i][1]);
          channelNames.push(channelData[i][0]);
        }

        if (
          i < incubatorData.length &&
          !incubator_ids.some((i_id) => i_id === incubatorData[i][1])
        ) {
          incubator_ids.push(incubatorData[i][1]);
          incubatorNames.push(incubatorData[i][0]);
        }

        if (
          i < testerData.length &&
          !tester_ids.some((t_id) => t_id === testerData[i][1])
        ) {
          tester_ids.push(testerData[i][1]);
          testerNames.push(testerData[i][0]);
        }
      }

      const channelField: DynamicEventField = {
        title: "Channel ID(s)",
        prefix: "",
        itemType: "channel",
        items: channel_ids.map((id, index) => ({
          id: id,
          alt_id: channelNames[index],
        })),
      };
      dynamicArray.push(channelField);

      if (incubator_ids.length > 0) {
        const incubatorField: DynamicEventField = {
          title: "Incubator(s)",
          prefix: "",
          itemType: "incubator",
          items: incubator_ids.map((id, index) => ({
            id: id,
            alt_id: incubatorNames[index],
          })),
        };
        dynamicArray.push(incubatorField);
      }

      if (tester_ids.length > 0) {
        const testerField: DynamicEventField = {
          title: "Tester(s)",
          prefix: "",
          itemType: "tester",
          items: tester_ids.map((id, index) => ({
            id: id,
            alt_id: testerNames[index],
          })),
        };
        dynamicArray.push(testerField);
      }

      if (testStandData.length > 0) {
        const testStandField: DynamicEventField = {
          title: "Test Stand(s)",
          prefix: "",
          itemType: "test_stand",
          items: testStandData.map(([name, id]) => ({
            id: id,
            alt_id: name,
          })),
        };
        dynamicArray.push(testStandField);
      }
    }

    if (
      dynamicArray.some(
        (dynamicField) => dynamicField.itemType === "test_stand"
      ) &&
      channelData.length === 0
    ) {
      // remove channel info. Will be automatically saved on backend.
      const channelDataIndex = dynamicArray
        .map((dynamicField) => dynamicField.itemType)
        .indexOf("channel");
      if (channelDataIndex >= 0) {
        dynamicArray.splice(channelDataIndex, 1);
      }
    }
    setDynamicFields(dynamicArray);
  };

  useEffect(() => {
    switch (itemType) {
      case "cell":
        cleanData(objects);
        break;
      case "channel":
        cleanData(objects);
        break;
      case "testStand":
        cleanData(objects);
        break;
      case "experiment":
        const experiment = objects[0];
        if (experiment.cells.length === 0) {
          const exp_owner_map: ExperimentOwnerMap = {};
          exp_owner_map[experiment.exp_id] = experiment.owner.name;
          setExperimentOwnerMap(exp_owner_map);
          createDynamicFields([], [experiment.exp_id], [], [], [], []);
        } else {
          cleanData(experiment.cells);
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, itemType]);

  return (
    <>
      {shrinkButton ? (
        <Tooltip arrow title="Log Event">
          <span>
            <IconButton size="small" onClick={() => setEventModalOpen(true)}>
              <EventIcon />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title={MODULE_CELLS_WARNING}
          disableHoverListener={!showModuleCellsWarning}
        >
          <span>
            <Chip
              clickable
              onClick={() => setEventModalOpen(true)}
              icon={<EventIcon />}
              label="Log Event"
            />
          </span>
        </Tooltip>
      )}

      <Modal
        open={eventModalOpen}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (["escapeKeyDown", "backdropClick"].includes(reason)) {
            if (
              !window.confirm(
                "Are you sure you want to close without submitting?"
              )
            ) {
              return;
            }
          }
          setEventModalOpen(false);
        }}
      >
        <Typography variant="h2">Log Event</Typography>
        <EventForm
          itemType={itemType}
          onClose={() => setEventModalOpen(false)}
          onSuccess={(link: string) => {
            setLink(link);
            setSuccess(true);
          }}
          dynamicFields={dynamicFields}
          facility={facility}
          experimentOwnerMap={experimentOwnerMap}
          setExperimentOwnerMap={setExperimentOwnerMap}
        />
      </Modal>

      {success === true && (
        <Toast open severity="success" onClose={() => setSuccess(false)}>
          <Typography className="small">
            <a href={link} target="_blank" rel="noreferrer">
              Event
            </a>{" "}
            created (It may take some time to appear in Asana)
          </Typography>
        </Toast>
      )}
    </>
  );
};

export default ButtonLogEvent;
