import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";

import SidebarButton from "./SidebarButton";
import CellTree from "./CellTree";
import colors from "../../../../theme/colors";

const SidebarContainer = styled(Box)({
  width: 254,
  marginTop: 74,
  zIndex: 2,
  height: "calc(100vh - 74px)",
  borderRight: `1px solid ${colors.rules}`,
  background: colors.body.light,
  position: "fixed",
  overflow: "auto",
});

type Props = {
  step: number;
  onToggleCell: (id: number) => void;
  cellLayout: MetadataSidebarCellLayout;
  layout: MetadataSidebarLayout;
};

const Sidebar = ({ step, onToggleCell, cellLayout, layout }: Props) => (
  <SidebarContainer>
    <CellTree layout={cellLayout} onToggleCell={onToggleCell} />
    <Box my={2}>
      {layout!.spec.map(
        (
          { title, step: _step, flag, sopDeviations, complete, children },
          index
        ) => (
          <SidebarButton
            key={index}
            title={title}
            step={_step}
            activeStep={step}
            flag={flag}
            sopDeviations={sopDeviations}
            complete={complete}
            children={children?.map((child) => ({
              ...child,
              activeStep: step,
            }))}
          />
        )
      )}
    </Box>
  </SidebarContainer>
);

export default Sidebar;
