import React from "react";
import { useEffect, useState } from "react";
import type { FieldArrayWithId } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableBody from "@mui/material/TableBody";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import CompletedCell from "./CompletedCell";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableCell,
  TableHead,
} from "@mui/material";
import CheckSquare from "../../../icons/CheckSquare";
import { cellIdToString } from "../../../utils/labels";
import {
  MetadataState,
  markCellQualityCheckComplete,
  undoMarkCellQualityCheckComplete,
} from "../slice";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  executor?: User | null;
};

const QualityCheckSubSection = ({
  formFields,
  executor: preferredExecutor,
}: Props) => {
  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const dispatch = useDispatch();

  const {
    status: { qualityCheckUpdate: qualityCheckStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);

  const [updatingQCStatusForCellId, setUpdatingQCStatusForCellId] = useState<
    number | null
  >(null);

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );

  const isMd = useMediaQuery("(min-width:1360px)");
  const isLg = useMediaQuery("(min-width:1440px)");
  const isXl = useMediaQuery("(min-width:1560px)");
  const userDropdownWidth = isXl ? 560 : isLg ? 460 : isMd ? 360 : 254;

  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);

  useEffect(() => {
    if (qualityCheckStatus === "succeeded" && updatingQCStatusForCellId) {
      setUpdatingQCStatusForCellId(null);
    }
  }, [qualityCheckStatus, updatingQCStatusForCellId]);

  return (
    <Table size="small" style={{ width: "auto" }}>
      <TableHead>
        <BaseTableRow>
          <TableCell>EXECUTOR</TableCell>
          <TableCell />
          {formFields.map((cell_) => (
            <TableCell key={`qc-cellHeader${cell_.cell_id}`}>
              {cellIdToString(cell_.cell_id)}
            </TableCell>
          ))}
        </BaseTableRow>
      </TableHead>
      <TableBody>
        <BaseTableRow>
          <UserCell
            clearable={false}
            style={{ width: userDropdownWidth, minWidth: userDropdownWidth }}
            error={!executor}
            value={executor}
            onChange={setExecutor}
          />
          <ConditionCell smallWidth />
          {formFields.map((cell_) => (
            <React.Fragment key={`qc-cell${cell_.cell_id}`}>
              {cell_.quality_check?.quality_check_completed_at ? (
                <ConditionCell
                  className={
                    cell_.quality_check?.quality_check_completed_at
                      ? "componentCompleted"
                      : ""
                  }
                >
                  <CompletedCell
                    status={cell_.status}
                    minimumStatus={"E"}
                    maximumStatus={"F"}
                    onUndo={() => {
                      setUpdatingQCStatusForCellId(cell_.cell_id);
                      dispatch(
                        undoMarkCellQualityCheckComplete({
                          cellId: cell_.cell_id as number,
                        })
                      );
                    }}
                    completed_at={
                      cell_.quality_check?.quality_check_completed_at
                    }
                    completed_by={
                      cell_.quality_check?.quality_check_completed_by!
                    }
                  />
                </ConditionCell>
              ) : (
                <ConditionCell>
                  <Box px={4} py={2}>
                    <span>
                      {updatingQCStatusForCellId === cell_.cell_id ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <IconButton
                          onClick={() => {
                            setUpdatingQCStatusForCellId(cell_.cell_id);
                            dispatch(
                              markCellQualityCheckComplete({
                                cellId: cell_.cell_id as number,
                                executor,
                              })
                            );
                          }}
                        >
                          <CheckSquare style={{ width: 22, height: 22 }} />
                        </IconButton>
                      )}
                    </span>
                  </Box>
                </ConditionCell>
              )}
            </React.Fragment>
          ))}
        </BaseTableRow>
      </TableBody>
    </Table>
  );
};

export default QualityCheckSubSection;
