import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import client from "../../api";
import { CellListState, cellsSlices } from "./slice";
import Modal from "../../components/Modal";
import colors from "../../theme/colors";
import CaretBottomIcon from "../../icons/CaretBottom";
import Button from "../../components/Button";
import { GreyBadge } from "../../components/Badge";
import WarningIcon from "../../icons/Warning";
import ChannelDropdown from "../channels/ChannelDropdown";
import ChannelTableCell from "../channels/ChannelTableCell";
import { CHANNEL_LABELS_ALL } from "../../utils/labels";
import ConditionCell from "../../components/forms/ConditionCell";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDebouncedCallback } from "use-debounce";
import Banner from "../../components/Banner";

export const useChannelCellStyles = makeStyles({
  root: {
    "& .MuiTableCell-root": {
      "& .thCellInner": {},
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        border: 0,
      },
      "&.MuiTableCell-head": {
        background: "white",
      },
    },
  },
});

type ChannelAvailabilityFilters = {
  across_incubators: boolean;
  consecutive: boolean;
  use_other_pools: boolean;
  pool?: ChannelPoolSearchResult;
};

type Props = {
  open: boolean;
  onClose: () => void;
  stateKey: keyof typeof cellsSlices;
  selected: Cell[];
};

const ReserveChannelsModal = ({ open, onClose, stateKey, selected }: Props) => {
  const classes = useChannelCellStyles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [filterError, setFilterError] = useState("");
  const [disableConsecutive, setDisableConsecutive] = useState(true);
  const [usage, setUsage] = useState<Record<
    ChannelAvailability,
    number
  > | null>(null);

  const [channelSelection, setChannelSelection] = useState<
    Record<number, Channel | null>
  >({});

  const {
    status: { channelReservation: channelResStatus, list: listCellsStatus },
    error: { channelReservation: error },
  } = useSelector<RootState, CellListState>(
    (state) => state[`cellList_${stateKey}` as keyof RootState] as CellListState
  );

  const [filters, setFilters] = useState<ChannelAvailabilityFilters>({
    across_incubators: false,
    consecutive: false,
    use_other_pools: false,
  });

  useEffect(() => {
    if (
      channelResStatus === "idle" &&
      ["idle", "succeeded"].includes(listCellsStatus)
    ) {
      const selections: Record<number, Channel | null> = {};
      let unique_condition_ids = new Set();
      selected.forEach(({ cell_id, reserved_channel, condition }) => {
        selections[cell_id] = reserved_channel;
        unique_condition_ids.add(condition.cell_condition_id);
      });
      setChannelSelection(selections);
      setDisableConsecutive(unique_condition_ids.size > 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const endpointFilters = useMemo(() => {
    let params = "?";

    // Add cell matching
    params += `&cell_ids=${selected
      .map(({ cell_id }) => cell_id)
      .join("&cell_ids=")}`;

    // Add 3 filters
    params += (Object.keys(filters) as (keyof ChannelAvailabilityFilters)[])
      .map((key) =>
        filters[key] === undefined
          ? ""
          : key === "pool"
          ? `&${key}=${filters.pool?.pool_name}`
          : `&${key}=${filters[key] ? 1 : 0}`
      )
      .join("");

    return params;
  }, [filters, selected]);

  const handleLookup = useCallback(async () => {
    setLoading(true);
    setFilterError("");

    try {
      const endpoint = `meta/channels/usage?${endpointFilters}`;
      const response = await client.get(endpoint);
      setUsage(response);
    } catch (err) {
      setFilterError(`${err}`);
      setUsage(null);
    }

    setLoading(false);
  }, [endpointFilters]);

  const handleDryRun = async () => {
    setLoading(true);
    setFilterError("");

    try {
      const endpoint = `meta/channels/reserve?${endpointFilters}`;
      const response = await client.get(endpoint);
      setChannelSelection(response);
    } catch (err) {
      setFilterError(`${err}`);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      handleLookup();
    }
  }, [open, filters, handleLookup]);

  useEffect(() => {
    if (open) {
      setExpanded(true);
      dispatch(cellsSlices[stateKey].resetChannelReservation());
    }
  }, [open, dispatch, stateKey]);

  useEffect(() => {
    if (open && channelResStatus === "succeeded") {
      onClose();
    }
  }, [open, channelResStatus, onClose]);

  const cellIds = selected.map(({ cell_id }) => cell_id);
  let modules: Module[] = [];
  selected.forEach((cell) => {
    if (!!cell.module.module_id) {
      let mod = cell.module as Module;
      let cell_module: any = modules.find((m) => m.module_id === mod.module_id);
      if (cell_module === undefined) {
        cell_module = { ...mod, cells: [] };
        modules.push(cell_module);
      }

      cell_module["cells"].push(cell);
    }
  });

  return (
    <Modal open={open} onClose={onClose} maxWidth={false}>
      <Typography variant="h2">Reserve channels</Typography>
      <Box mt={2} mb={6}>
        <Typography className="small" color="textSecondary">
          All channels listed for reservation are available and match cell
          conditions such as Cell temperature, Tester brand, Temperature
          monitoring status, Aux voltage, CO2 scrubbing, Thermocouple Level and
          Hydrogen generation monitoring.
        </Typography>
      </Box>

      <Box p={6} mb={6} style={{ background: colors.striping }}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography>
              <b>Channel distribution settings</b>
            </Typography>
          </Box>
          <IconButton
            size="small"
            style={{ padding: 0, color: colors.text.primary }}
            onClick={() => setExpanded(!expanded)}
          >
            <CaretBottomIcon
              style={expanded ? { transform: "rotate(180deg)" } : {}}
            />
          </IconButton>
        </Box>

        {expanded ? (
          <Box mt={4}>
            <Box display="flex">
              <ChannelDistributionSetting
                title="Incubators"
                label="Across Incubators"
                disabled={filters.consecutive}
                value={filters.across_incubators}
                onChange={(across_incubators) =>
                  setFilters({
                    ...filters,
                    consecutive: across_incubators
                      ? false
                      : filters.consecutive,
                    across_incubators,
                  })
                }
              />
              <Box mx={8}>
                <Divider orientation="vertical" />
              </Box>
              <ChannelDistributionSetting
                title="Order"
                label="Consecutive"
                disabled={filters.across_incubators || disableConsecutive}
                value={filters.consecutive && !disableConsecutive}
                onChange={(consecutive) =>
                  setFilters({
                    ...filters,
                    across_incubators: consecutive
                      ? false
                      : filters.across_incubators,
                    consecutive,
                  })
                }
              />
              <Box mx={8}>
                <Divider orientation="vertical" />
              </Box>
              <ChannelDistributionSetting
                title="Other options"
                label="Use channels from other pools"
                disabled={false}
                value={filters.use_other_pools}
                onChange={(use_other_pools) =>
                  setFilters({
                    ...filters,
                    use_other_pools,
                    pool: use_other_pools ? filters.pool : undefined,
                  })
                }
              >
                <ChannelPoolAutocomplete
                  value={filters.pool}
                  onChange={(pool) => setFilters({ ...filters, pool })}
                />
              </ChannelDistributionSetting>
            </Box>
            <Box display="flex" mt={4}>
              <Box ml="auto" mr={2}>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() =>
                    setFilters({
                      across_incubators: false,
                      consecutive: false,
                      use_other_pools: false,
                    })
                  }
                >
                  Reset
                </Button>
              </Box>
              <Button
                color="primary"
                size="small"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null
                }
                disabled={loading || !usage || usage.A < selected.length}
                onClick={handleDryRun}
              >
                Suggest channels {usage ? `(${usage.A} available)` : ""}
              </Button>
            </Box>

            {filterError || (usage && usage.A < selected.length) ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt={2}
              >
                <WarningIcon
                  color="error"
                  style={{
                    width: 16,
                    height: 16,
                    marginBottom: "-0.1rem",
                  }}
                />
                <Box ml={2} className="small" my="auto">
                  {filterError
                    ? filterError
                    : "Not enough channels matching this request"}
                </Box>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>

      <TableContainer>
        <Table
          className={`dataTable ${classes.root}`}
          size="small"
          style={{ background: colors.striping, width: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ background: "white" }} />
              {cellIds.map((cell_id, index) => (
                <TableCell key={index} style={{ background: "white" }}>
                  Cell ID {`${cell_id}`.padStart(6, "0")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Reserved Channel ID</TableCell>
              {cellIds.map((cell_id, index) => {
                const cell_ = selected.find(
                  (selectedCell) => selectedCell.cell_id === cell_id
                )!;
                const allowQueueing =
                  !!cell_.cell_type.allow_reservation_queueing;
                return (
                  <ConditionCell
                    key={index}
                    style={{ background: "white" }}
                    padding="none"
                  >
                    <ChannelDropdown
                      allowQueueing={allowQueueing}
                      hideTestStandChannels
                      value={channelSelection[cell_id] || null}
                      onChange={(channel) => {
                        // Update channel selection for this cell and
                        // all sibling cells if it's in a module
                        let updatedChannelSelection = {
                          ...channelSelection,
                          [cell_id]: channel,
                        };

                        modules.forEach((mod) => {
                          let cellIds = mod.cells.flatMap(
                            (cell) => cell.cell_id
                          );
                          let index = cellIds.indexOf(cell_id);

                          if (index > -1) {
                            cellIds.splice(cellIds.indexOf(cell_id), 1);
                            cellIds.forEach(
                              (cell_id) =>
                                (updatedChannelSelection[cell_id] = channel)
                            );
                          }
                        });

                        setChannelSelection(updatedChannelSelection);
                      }}
                      cells={cellIds}
                    />
                  </ConditionCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(({ id }) => id === "channel__pool")
                    ?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__pool"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__preferred_test_vehicle"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__preferred_test_vehicle"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__temperature"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__temperature"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__airlines"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__airlines"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_channels"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_channels"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_voltage_box_type"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_voltage_box_type"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__flow_valve_type"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__flow_valve_type"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__hazelnut_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__hazelnut_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) =>
                      id === "channel__subscale_h2_interlock_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__subscale_h2_interlock_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__tc_channels"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__tc_channels"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__cathode_switching"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__cathode_switching"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__co2_scrubbing"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__co2_scrubbing"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__humidity_control"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__humidity_control"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__tester_brand"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__tester_brand"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__low_current_range"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__low_current_range"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__high_current_range"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__high_current_range"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__min_voltage"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__min_voltage"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__max_voltage"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__max_voltage"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__hydrogen_monitoring"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__hydrogen_monitoring"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__pressure_setpoint"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__pressure_setpoint"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__aux_voltage_monitoring"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__aux_voltage_monitoring"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__eis_functionality"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__eis_functionality"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) =>
                      id === "channel__thermal_uniformity_fans_installed"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__thermal_uniformity_fans_installed"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__kilobubbler_count"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__kilobubbler_count"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                {
                  CHANNEL_LABELS_ALL.find(
                    ({ id }) => id === "channel__yearly_calibration_date"
                  )?.label
                }
              </TableCell>
              {cellIds.map((cell_id, index) =>
                channelSelection[cell_id] ? (
                  <ChannelTableCell
                    key={index}
                    dataKey="channel__yearly_calibration_date"
                    channel={channelSelection[cell_id]!}
                  />
                ) : (
                  <TableCell key={index} />
                )
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {channelResStatus === "failed" ? (
        <Box
          position="fixed"
          style={{ top: 74, width: "80%" }}
          m={4}
          zIndex={10}
        >
          <Banner severity="error">
            {error || "Error saving channel reservations."}
          </Banner>
        </Box>
      ) : null}

      <Box mt={6} display="flex">
        <Button
          color="tertiary"
          size="small"
          onClick={() => {
            setFilters({
              across_incubators: false,
              consecutive: false,
              use_other_pools: false,
            });
            setChannelSelection({});
          }}
        >
          <b>Reset</b>
        </Button>
        <Box ml="auto" mr={2}>
          <Button color="secondary" size="small" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          color="cta"
          size="small"
          disabled={
            channelResStatus === "loading" ||
            Object.keys(channelSelection).filter(
              (key) =>
                !(
                  channelSelection[parseInt(key)] === null ||
                  channelSelection[parseInt(key)]?.channel.fullname === null
                )
            ).length !== selected.length
          }
          startIcon={
            channelResStatus === "loading" ? (
              <CircularProgress color="inherit" size={20} />
            ) : null
          }
          onClick={() =>
            dispatch(cellsSlices[stateKey].reserveChannels(channelSelection))
          }
        >
          Reserve channels
        </Button>
      </Box>
    </Modal>
  );
};

type ChannelDistributionSettingProps = {
  title: string;
  label: string;
  disabled: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
};

const ChannelDistributionSetting = ({
  title,
  label,
  disabled,
  value,
  onChange,
  children,
}: ChannelDistributionSettingProps) => {
  return (
    <Box>
      <Typography color="textSecondary" className="small" fontWeight={500}>
        {title}
      </Typography>
      <Box mt={2} display="flex" alignItems="center">
        <Typography
          minWidth={152}
          mr={4}
          className="small"
          style={disabled ? { opacity: 0.5 } : {}}
        >
          {label}
        </Typography>

        {label === "Consecutive" && disabled ? (
          <Tooltip
            arrow
            placement="top"
            title={
              '"Across Incubators" must be deselected and the selected cell conditions must be the same to enable this feature.'
            }
          >
            <span>
              <Switch
                color="secondary"
                disabled={disabled}
                checked={value}
                onChange={(e, checked) => onChange(checked)}
              />
            </span>
          </Tooltip>
        ) : (
          <Switch
            color="secondary"
            disabled={disabled}
            checked={value}
            onChange={(e, checked) => onChange(checked)}
          />
        )}
      </Box>
      {value && children ? <Box mt={3}>{children}</Box> : null}
    </Box>
  );
};

const usePoolStyles = makeStyles({
  root: {
    background: "#FFFFFF",
    textAlign: "left",
    "& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      padding: "0.25rem",
      paddingRight: 39,
    },
  },
});

type ChannelPoolSearchResult = { pool_name: string; channel_count: number };

type ChannelPoolAutocompleteProps = {
  value?: ChannelPoolSearchResult;
  onChange: (pool: ChannelPoolSearchResult) => void;
};

const ChannelPoolAutocomplete = ({
  value,
  onChange,
}: ChannelPoolAutocompleteProps) => {
  const acClasses = usePoolStyles();

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<ChannelPoolSearchResult[]>([]);
  const [search, setSearch] = useState<string | null>(null);

  const _handleLookup = useCallback(async () => {
    if (search === null) {
      return;
    }

    setLoading(true);

    try {
      const response = await client.get(
        `meta/channels/pools?pool_like=${search}`
      );
      setOptions(response.data);
    } catch (err) {
      setOptions([]);
    }

    setLoading(false);
  }, [search]);

  const handleLookup = useDebouncedCallback(_handleLookup, 400, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    handleLookup();
  }, [search, handleLookup]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableClearable
        className={acClasses.root}
        options={options}
        getOptionLabel={(option) => option.pool_name}
        isOptionEqualToValue={(option, value) =>
          option.pool_name === value.pool_name
        }
        value={value || undefined}
        onChange={(e, data) => onChange(data!)}
        noOptionsText={
          search !== null && search.length > 0
            ? "There are no pools with available channels"
            : "Start typing..."
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
        )}
        renderOption={(props, value) => (
          <li {...props}>
            <Box
              px={4}
              py={2}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>{value.pool_name}</span>
              <GreyBadge badgeContent={value.channel_count} />
            </Box>
          </li>
        )}
      />
    </FormControl>
  );
};

export default ReserveChannelsModal;
