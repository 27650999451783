import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CharacterizationState, getSampleFormFields } from "../slice";
import { RootState } from "../../../store";
import SamplesForm from "../SamplesForm";
import Loading from "../../../layout/Loading";
import { useLocation } from "react-router-dom";

const NewSampleLayout = () => {
  const { emptySample } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );
  const location = useLocation();
  const { from, cell_ids, newSamplesFromModal } =
    (location.state as LocationState) || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSampleFormFields());
  }, [dispatch]);

  return (
    <>
      {emptySample.fields.length === 0 ? (
        <Loading light fullscreen />
      ) : (
        <SamplesForm
          from={from?.pathname}
          originCellIds={cell_ids}
          batchCreateAfterModalForm={!!newSamplesFromModal}
          samplesToEdit={newSamplesFromModal}
        />
      )}
    </>
  );
};

export default NewSampleLayout;
