import React from "react";
import { Box, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import colors from "../../theme/colors";
import Button from "../../components/Button";
import ExclamationCircleOutlined from "../../icons/ExclamationCircleOutlined";

const CELL_DISABLED_STYLES = {
  backgroundColor: colors.accent.gray,
  opacity: "25%",
};

const BatchCellConditionViewCell = ({
  gridParams,
  componentField,
  disabled,
}: {
  gridParams: GridRenderCellParams<any>;
  componentField: ConditionUIField;
  disabled: boolean;
}) => {
  const { required, type } = componentField;
  const { field, row, value } = gridParams;
  const requireValue = !disabled && required;

  let cellComponent = null;
  switch (type) {
    case "button":
      if (!!value) {
        cellComponent = (
          <Button
            color="tertiary"
            style={{
              background: "transparent",
              border: "1px solid transparent",
            }}
            href={value as string}
            target="_blank"
          >
            Link
          </Button>
        );
      }
      break;
    case "bool":
    case "boolean":
      cellComponent = (
        <Box sx={disabled ? CELL_DISABLED_STYLES : undefined}>
          {value ? "Yes" : "No"}
        </Box>
      );
      break;
    case "sopdeviation_options":
      cellComponent = (
        <Box sx={CELL_DISABLED_STYLES}>
          {value
            ? value
                .map((deviation: SOPDeviation) => deviation.deviation_type_name)
                .join(", ")
            : ""}
        </Box>
      );
      break;
    case "cyclingprotocol_options":
    case "icmpcm_options":
    case "user_options":
      cellComponent = (
        <Box
          display="flex"
          justifyContent="center"
          sx={
            disabled
              ? CELL_DISABLED_STYLES
              : requireValue && !value
              ? { border: "1px solid red", height: "94%", width: "100%" }
              : undefined
          }
        >
          <>
            {!value
              ? ""
              : value.material_id ||
                value.name ||
                value.cycling_protocol_id ||
                value}
            {!!row[`${field}_description`] ||
              (value && value.description && (
                <Tooltip
                  arrow
                  title={row[`${field}_description`] || value.description}
                >
                  <span
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      marginLeft: "8px",
                    }}
                  >
                    <ExclamationCircleOutlined
                      style={{
                        color: colors.text.primary,
                        width: 20,
                        height: 20,
                        transform: "rotate(180deg)",
                      }}
                    />
                  </span>
                </Tooltip>
              ))}
          </>
        </Box>
      );
      break;
    default:
      cellComponent = (
        <Box
          sx={
            disabled || (requireValue && !value)
              ? {
                  border: requireValue && !value ? "1px solid red" : undefined,
                  height: disabled ? "100%" : "96%",
                  width: disabled ? "100%" : "98%",
                  ...(disabled ? CELL_DISABLED_STYLES : {}),
                }
              : undefined
          }
        >
          {value || ""}
        </Box>
      );
      break;
  }
  return cellComponent;
};

export default BatchCellConditionViewCell;
