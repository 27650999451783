import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import type { AlertProps } from "@mui/material/Alert";
import styled from "@mui/styles/styled";

import CheckCircleOutlinedIcon from "../icons/CheckCircleOutlined";
import ExclamationCircleOutlinedIcon from "../icons/ExclamationCircleOutlined";
import WarningIcon from "../icons/Warning";
import TimesIcon from "../icons/Times";
import colors from "../theme/colors";

const getStyle = (color: string, textColor: string) => ({
  color: textColor,
  background: color,
  border: "none",
  "& .MuiAlert-message": {
    marginTop: "auto",
    marginBottom: "auto",
  },
  "& .MuiAlert-icon": {
    color: textColor,
    marginTop: "auto",
    marginBottom: "auto",
  },
  "& .MuiIconButton-root:not(:hover)": {
    color: textColor,
    "& .MuiSvgIcon-root": {
      color: textColor,
    },
  },
});

const SuccessAlert = styled(Alert)(
  getStyle(colors.accent.green, colors.text.primary)
);
const WarningAlert = styled(Alert)(
  getStyle(colors.accent.cta, colors.text.primary)
);
const ErrorAlert = styled(Alert)(getStyle(colors.accent.red, "white"));

const CloseButton = styled(IconButton)({
  "& .MuiSvgIcon-root": {
    color: colors.text.secondary,
  },
});

const Banner = (props: AlertProps) => {
  const { onClose, severity } = props;

  let alert;
  switch (severity) {
    case "success":
      alert = (
        <SuccessAlert
          icon={<CheckCircleOutlinedIcon />}
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon color="inherit" fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    case "error":
      alert = (
        <ErrorAlert
          icon={<ExclamationCircleOutlinedIcon />}
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon color="inherit" fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    case "warning":
      alert = (
        <WarningAlert
          icon={<WarningIcon />}
          action={
            onClose ? (
              <CloseButton onClick={onClose}>
                <TimesIcon color="inherit" fontSize="small" />
              </CloseButton>
            ) : null
          }
          elevation={4}
          variant="outlined"
          {...props}
        />
      );
      break;
    default:
      alert = <Alert elevation={4} variant="outlined" {...props} />;
      break;
  }

  return alert;
};

export default Banner;
