import { Box } from "@mui/material";
import React, { Fragment } from "react";
import colors from "../../../../theme/colors";
import styled from "@mui/styles/styled";
import SidebarButton from "./SidebarButton";
import {
  getCharacterizationLabelForId,
  sampleIdToString,
} from "../../../../utils/labels";
import { TECHNIQUE_REQUESTED_LOOKUP_ID } from "../../slice";

const SidebarContainer = styled(Box)({
  width: 274,
  marginTop: 76,
  zIndex: 2,
  height: "calc(100vh - 74px)",
  borderRight: `1px solid ${colors.rules}`,
  background: colors.body.light,
  position: "fixed",
  overflow: "auto",
});

const CharacterizationSidebar = ({
  samples,
}: {
  samples: CharacterizationSample[];
}) => {
  return (
    <SidebarContainer>
      {samples.map((sample) => {
        return (
          <Fragment key={`sidebar-${sample.sample_id}`}>
            <div
              style={{
                background: colors.striping,
                borderBottom: `2px solid ${colors.rules}`,
              }}
            >
              <Box px={2} py={3} display="flex" alignItems="center">
                <Box ml={1} mr="auto">
                  <b>{sampleIdToString(sample.sample_id!)}</b>
                </Box>
              </Box>
            </div>
            <Box my={2}>
              <SidebarButton title="Overview" />
              {sample.specimens.map((specimen, index) => {
                const techniqueRequestedField = specimen.fields.find(
                  (_specimen_field) =>
                    _specimen_field.field_lookup_id ===
                    TECHNIQUE_REQUESTED_LOOKUP_ID
                );
                const sectionTitle = !!techniqueRequestedField?.value
                  ? getCharacterizationLabelForId(
                      techniqueRequestedField.value as string,
                      "techniques"
                    )
                  : "New characterization request";
                return <SidebarButton key={index} title={sectionTitle} />;
              })}
            </Box>
          </Fragment>
        );
      })}
    </SidebarContainer>
  );
};

export default CharacterizationSidebar;
