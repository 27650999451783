import React from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import InputCell from "../../components/forms/InputCell";
import CheckboxCell from "../../components/forms/CheckboxCell";
import SelectCell from "../../components/forms/SelectCell";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import BatchFormMetaCell from "./BatchFormMetaCell";
import UserCell from "../../components/forms/UserCell";
import { isNumber, isString } from "lodash";

const BatchCellConditionEditCell = ({
  apiRef,
  gridParams,
  componentField,
  disabled,
}: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  gridParams: GridRenderEditCellParams<any>;
  componentField: ConditionUIField;
  disabled: boolean;
}) => {
  const { options, required, type } = componentField;
  const { field, id, value } = gridParams;

  switch (type) {
    case "options":
    case "number_options":
    case "multiple_options":
      return (
        <SelectCell
          multiple={type === "multiple_options"}
          style={{ width: "100%" }}
          disableClearable={required}
          noTable
          disabled={disabled}
          options={options!}
          onChange={(newVal) => {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: newVal,
            });
          }}
          value={
            value
              ? type === "number_options"
                ? String(value)
                : value
              : type === "multiple_options"
              ? []
              : null
          }
        />
      );
    case "channel_options":
      return (
        <BatchFormMetaCell
          apiRef={apiRef}
          fieldName={field}
          fieldId={id}
          value={value}
          disabled={disabled}
          endpoint="meta/channels"
          valueKey="channel_id"
          searchKey="channel_fullname"
        />
      );
    case "cyclingprotocol_options":
      let tpvVal = value;
      if (isString(tpvVal)) {
        tpvVal = { cycling_protocol_id: value };
      }
      return (
        <BatchFormMetaCell
          apiRef={apiRef}
          fieldName={field}
          fieldId={id}
          value={tpvVal}
          disabled={disabled}
          endpoint="meta/cycling-protocols/spec"
          valueKey="cycling_protocol_id"
          descriptionKey="description"
        />
      );
    case "icmpcm_options":
      let materialVal = value;
      if (isString(materialVal)) {
        materialVal = { material_id: value };
      }
      return (
        <BatchFormMetaCell
          apiRef={apiRef}
          fieldName={field}
          fieldId={id}
          value={materialVal}
          disabled={disabled}
          endpoint="meta/materials"
          valueKey="material_id"
          descriptionKey="description"
        />
      );
    case "sopdeviation_options":
      return (
        <BatchFormMetaCell
          apiRef={apiRef}
          endpoint="meta/deviation-types"
          fieldName={field}
          fieldId={id}
          freeSolo
          multiple
          disabled={disabled}
          value={value || []}
          valueKey="deviation_type_name"
        />
      );
    case "user_options":
      let userVal = value;
      if (isNumber(userVal)) {
        userVal = { user_id: value };
      }
      return (
        <UserCell
          noTable
          onChange={(newVal: User | null) => {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: newVal,
            });
          }}
          value={userVal}
          disabled={disabled}
          clearable={true}
          style={{ width: "100%" }}
          largeWidth
        />
      );
    case "bool":
    case "boolean":
      return (
        <CheckboxCell
          noTable
          color="secondary"
          disabled={disabled}
          checked={Boolean(value)}
          onChange={(event) => {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: event.target.checked,
            });
          }}
        />
      );
    case "icmpcmlot_options":
    case "number":
    case "string":
    case "text":
    default:
      return (
        <InputCell
          noTable
          type={type === "number" ? "number" : "text"}
          value={value || ""}
          disabled={disabled}
          placeholder={disabled ? "–field disabled-" : undefined}
          onChange={(event) => {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: event.target.value,
            });
          }}
        />
      );
  }
};

export default BatchCellConditionEditCell;
