import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../api";

export const CHANNEL_SINGLE = "CHANNEL_SINGLE";

export interface ChannelSingleState {
  channel: Channel | null;
  status: {
    get: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    get: null | string;
    save: null | string;
  };
}

const initialState: ChannelSingleState = {
  channel: null,
  status: {
    get: "idle",
    save: "idle",
  },
  error: {
    get: null,
    save: null,
  },
};

export const getSingleChannel = createAsyncThunk(
  `${CHANNEL_SINGLE}/get`,
  async (channel_id: number) => {
    const response = await client.get(`meta/channels/${channel_id}`);
    return response;
  }
);

const filtersSlice = createSlice({
  name: CHANNEL_SINGLE,
  initialState,
  reducers: {
    resetSingleChannel: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      // Get single channel
      .addCase(getSingleChannel.pending, (state) => {
        state.status.get = "loading";
      })
      .addCase(getSingleChannel.fulfilled, (state, { payload }) => {
        state.status.get = "succeeded";
        state.channel = payload;
      })
      .addCase(getSingleChannel.rejected, (state, { error }) => {
        state.status.get = "failed";
        state.error.get = error.message as string;
      });
  },
});

export const { resetSingleChannel } = filtersSlice.actions;

export default filtersSlice.reducer;
