import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GreyBadge } from "./Badge";
import colors from "../theme/colors";
import SamplesList from "../features/characterization/SamplesList";

type LabelProps = {
  text: string;
  count?: number;
};

const Label = ({ text, count }: LabelProps) =>
  isNaN(count as number) ? (
    <>{text}</>
  ) : (
    <GreyBadge badgeContent={`${count}`}>{text}</GreyBadge>
  );

type Props = {
  selectedTab: "ALL_SAMPLES";
};

const CharacterizationTabs = ({ selectedTab }: Props) => {
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    switch (newValue) {
      case "ALL_SAMPLES":
        navigate("/characterization");
        break;
    }
  };

  let contents;
  switch (selectedTab) {
    case "ALL_SAMPLES":
      contents = (
        <Box mt={6}>
          <SamplesList />
        </Box>
      );
      break;
  }

  return (
    <Box
      py={6}
      px={8}
      style={{
        backgroundColor: colors.body.light,
        minHeight: "calc(100vh - 48px - 60px)",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab value="ALL_SAMPLES" label={<Label text="Samples" />} />
      </Tabs>
      {contents}
    </Box>
  );
};

export default CharacterizationTabs;
