import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import client from "../../api";

export const CELL_SAVED_FILTERS = "CELL_SAVED_FILTERS";

export interface CellSavedFilterState {
  savedFilters: SavedCellFilter[];
  status: {
    list: "idle" | "loading" | "succeeded" | "failed";
    save: "idle" | "loading" | "succeeded" | "failed";
    delete: "idle" | "loading" | "succeeded" | "failed";
  };
  error: {
    list: null | string;
    save: null | string;
    delete: null | string;
  };
}

const initialState: CellSavedFilterState = {
  savedFilters: [],
  status: {
    list: "idle",
    save: "idle",
    delete: "idle",
  },
  error: {
    list: null,
    save: null,
    delete: null,
  },
};

export const listSavedFilters = createAsyncThunk(
  `${CELL_SAVED_FILTERS}/list`,
  async () => {
    const response = await client.get(`meta/cells/saved-filters`);
    return response.data;
  }
);

export const addSavedFilter = createAsyncThunk(
  `${CELL_SAVED_FILTERS}/add`,
  async (filter: Omit<SavedCellFilter, "saved_filter_id">) => {
    const response = await client.post(`meta/cells/saved-filters`, filter);
    return response.data;
  }
);

export const deleteSavedFilter = createAsyncThunk(
  `${CELL_SAVED_FILTERS}/delete`,
  async (filter: SavedCellFilter) => {
    await client.delete(`meta/cells/saved-filters/${filter.saved_filter_id}`);
    return filter;
  }
);

const filtersSlice = createSlice({
  name: CELL_SAVED_FILTERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List Experiment Filters
      .addCase(listSavedFilters.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(listSavedFilters.fulfilled, (state, { payload }) => {
        state.status.list = "succeeded";

        const migratedPayload = payload.map((filter: any) => {
          /* Migrate engineering flag filter */

          // If no engineer flag in this filter, skip it
          if (!filter.data.engineer_flag) {
            return filter;
          }

          // If the engineer flag in this filter is in the new format, skip it
          if (isArray(filter.data.engineer_flag[0])) {
            return filter;
          }

          // Otherwise, store it in redux in the new format
          filter.data.engineer_flag = [filter.data.engineer_flag];
          return filter;
        });

        state.savedFilters = migratedPayload;
      })
      .addCase(listSavedFilters.rejected, (state, { error }) => {
        state.status.list = "failed";
        state.error.list = error.message as string;
      })
      // Add Experiment Filter
      .addCase(addSavedFilter.pending, (state) => {
        state.status.save = "loading";
      })
      .addCase(addSavedFilter.fulfilled, (state, { payload }) => {
        state.status.save = "succeeded";
        state.savedFilters = state.savedFilters.concat([payload]);
      })
      .addCase(addSavedFilter.rejected, (state, { error }) => {
        state.status.save = "failed";
        state.error.save = error.message as string;
      })
      // Delete Experiment Filter
      .addCase(deleteSavedFilter.pending, (state) => {
        state.status.delete = "loading";
      })
      .addCase(deleteSavedFilter.fulfilled, (state, { payload }) => {
        const index = state.savedFilters.findIndex(
          (filter) => filter.saved_filter_id === payload.saved_filter_id
        );
        const newFilters = [...state.savedFilters];
        if (index > -1) {
          newFilters.splice(index, 1);
        }

        state.status.delete = "succeeded";
        state.savedFilters = newFilters;
      })
      .addCase(deleteSavedFilter.rejected, (state, { error }) => {
        state.status.delete = "failed";
        state.error.delete = error.message as string;
      });
  },
});

export default filtersSlice.reducer;
