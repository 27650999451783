import styled from "@mui/styles/styled";
import Button from "./Button";
import colors, { addAlpha } from "../theme/colors";

const SidebarButton = styled(Button)({
  borderRadius: 0,
  width: "100%",
  justifyContent: "start",
  padding: "0.75rem",
  "&.Mui-disabled": {
    background: colors.body.light,
  },
  "& .MuiButton-endIcon": {
    marginLeft: "auto",
  },
  "&:hover": {
    background: addAlpha(colors.accent.primary, 0.1),
  },
  "&.active.noChildren": {
    color: colors.accent.primary,
    background: addAlpha(colors.accent.primary, 0.1),
    fontWeight: 700,
  },
  "&.active:not(.noChildren)": {
    color: colors.accent.primary,
  },
});

export default SidebarButton;
