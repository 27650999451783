import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { cellIdToString } from "../../utils/labels";
import ConditionCell from "./ConditionCell";
import Pencil from "../../icons/Pencil";
import Modal from "../Modal";
import BaseTableRow from "./BaseTableRow";
import ThCell from "./ThCell";
import SelectCell from "./SelectCell";
import Button from "../Button";

type Props = {
  className?: string;
  disabled?: boolean;
  label: string;
  options: string[];
  onChange: (value: TeardownSpecification[]) => void;
  value?: TeardownSpecification[];
};

const TeardownSpecificationCell = ({
  className,
  disabled,
  label,
  options,
  value,
  onChange,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [localValue, setLocalValue] = useState(value || []);
  useEffect(() => setLocalValue(value || []), [value, open]);

  const counts: Record<string, number> = {};

  !!value &&
    value.forEach(
      ({ component_meta_mode }) =>
        (counts[component_meta_mode] = 1 + (counts[component_meta_mode] || 0))
    );

  const cellLabel = Object.keys(counts)
    .map((key) => `${key} (${counts[key]})`)
    .join(", ");

  return (
    <>
      <ConditionCell className={className}>
        <Box
          px={4}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ whiteSpace: "pre-wrap" }}>{cellLabel}</div>
          <IconButton disabled={disabled} onClick={() => setOpen(true)}>
            <Pencil style={{ width: 16, height: 16 }} />
          </IconButton>
        </Box>
      </ConditionCell>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box mb={6}>
          <Typography variant="h2">{label}</Typography>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {localValue.map((teardown, index) => (
                <BaseTableRow key={index}>
                  <ThCell title={label}>
                    {cellIdToString(teardown.cell_id)}
                  </ThCell>
                  <SelectCell
                    disableClearable
                    className={className}
                    options={options}
                    disabled={disabled}
                    onChange={(component_meta_mode: any) => {
                      if (!component_meta_mode) {
                        return;
                      }

                      const newLocalValue = [...localValue];
                      newLocalValue[index] = {
                        cell_id: teardown.cell_id,
                        component_meta_mode,
                      };
                      setLocalValue(newLocalValue);
                    }}
                    value={teardown.component_meta_mode}
                  />
                </BaseTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={6} textAlign="right">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              onChange(localValue);
              setOpen(false);
            }}
            disabled={disabled}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default TeardownSpecificationCell;
