import type { FieldArrayWithId } from "react-hook-form";
import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";

import SidebarButton from "./SidebarButton";
import BuildTypeAssembly from "./BuildTypeAssembly";
import colors from "../../../../theme/colors";
import { getExecutorKeysForIndex } from "../../../../utils/conditions";

const SidebarContainer = styled(Box)({
  width: 254,
  marginTop: 74,
  zIndex: 2,
  height: "calc(100vh - 74px)",
  borderRight: `1px solid ${colors.rules}`,
  background: colors.body.light,
  position: "fixed",
  overflow: "auto",
});

type Props = {
  step: number;
  started: boolean;
  onAdd?: () => void;
  onToggleCondition?: (id: number) => void;
  assemblyLayout?: ConditionSidebarAssemblyLayout;
  layout?: ConditionSidebarLayout;
  fields?: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
  editMode?: boolean;
};

const Sidebar = ({
  step,
  started,
  onAdd,
  onToggleCondition,
  assemblyLayout,
  layout,
  fields,
  editMode,
}: Props) => {
  if (!layout) {
    return (
      <SidebarContainer>
        <BuildTypeAssembly started={false} onAdd={onAdd} />
        <Box my={2}>
          <SidebarButton title="Overview" step={0} activeStep={step} />
        </Box>
      </SidebarContainer>
    );
  }

  return (
    <EditSidebar
      step={step}
      started={started}
      onAdd={onAdd}
      onToggleCondition={onToggleCondition}
      assemblyLayout={assemblyLayout}
      layout={layout}
      fields={fields}
      editMode={editMode}
    />
  );
};

const EditSidebar = ({
  step,
  started,
  onAdd,
  onToggleCondition,
  assemblyLayout,
  layout,
  fields,
  editMode,
}: Props) => {
  const conditionVisible = Object.keys(assemblyLayout?.items || {}).find(
    (key) => assemblyLayout?.items[key].conditions.some((c) => c.active)
  );

  const getFlagKeys = (step: number) => {
    if (step === -1 || !fields?.length || !conditionVisible) {
      return [];
    }

    return (
      fields
        ?.filter((field_) => !("basic_info" in field_) || !field_.basic_info)
        .flatMap((field, fieldIndex) =>
          getExecutorKeysForIndex(fields, fieldIndex, "engineer_flag", step)
        ) || []
    );
  };

  const getSopDeviationKeys = (step: number) => {
    if (step === -1 || !fields?.length || !conditionVisible) {
      return [];
    }

    return (
      fields
        ?.filter((field_) => !("basic_info" in field_) || !field_.basic_info)
        .flatMap((field, fieldIndex) =>
          getExecutorKeysForIndex(
            fields,
            fieldIndex,
            "deviations_from_sop",
            step
          )
        ) || []
    );
  };

  return (
    <SidebarContainer>
      <BuildTypeAssembly
        started={started}
        editMode={editMode}
        layout={assemblyLayout}
        onAdd={onAdd}
        onToggleCondition={onToggleCondition}
      />
      <Box my={2}>
        {layout!.items.map(({ title, step: _step, children }, index) => (
          <SidebarButton
            key={index}
            title={title}
            disabled={_step !== -1 && !conditionVisible}
            step={_step}
            activeStep={step}
            flagKeys={getFlagKeys(_step)}
            sopDeviationKeys={getSopDeviationKeys(_step)}
            children={children?.map((child) => ({
              ...child,
              flagKeys: getFlagKeys(child.step),
              sopDeviationKeys: getSopDeviationKeys(child.step),
              disabled: child.step !== -1 && !conditionVisible,
              activeStep: step,
            }))}
          />
        ))}
      </Box>
    </SidebarContainer>
  );
};

export default Sidebar;
