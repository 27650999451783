import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonSignalFilters from "./ButtonSignalFilters";
import SignalModifiers from "./SignalModifiers";
import VirtualTable from "../../components/table/VirtualTable";

import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import client from "../../api";
import type { RootState } from "../../store";
import { vhToPx, getVirtualTableHeight, DATA_ROW_HEIGHT } from "../../utils/ui";
import {
  FSC_SIGNAL_LABELS_LIST,
  SERIAL_HPHC_SIGNAL_LABELS_LIST,
  BAM_SIGNAL_LABELS_LIST,
} from "../../utils/labels";
import { shiftClickList } from "../../utils/shiftClick";
import {
  selectSignals,
  deselectSignals,
  selectAllVisibleSignals,
  deselectAllSignals,
  sortSignals,
} from "./slice";
import type { SignalMapState } from "./slice";
import { uniq } from "lodash";

const SignalsListView = () => {
  const dispatch = useDispatch();
  const {
    dutType,
    signalMap,
    signals,
    selected: { signals: selected },
    visible: { signals: visibleSignals },
    order: { signal: order },
    orderBy: { signal: orderBy },
  } = useSelector<RootState, SignalMapState>(
    ({ signalMapSingle }) => signalMapSingle
  );
  const SIGNAL_LABELS =
    dutType === "module"
      ? BAM_SIGNAL_LABELS_LIST
      : dutType === "serial_hphc"
      ? SERIAL_HPHC_SIGNAL_LABELS_LIST
      : FSC_SIGNAL_LABELS_LIST;
  const [allDeviceClasses, setAllDeviceClasses] = useState<string[]>([]);
  const [allSensorTypes, setAllSensorTypes] = useState<AllSensorTypes>({});
  const getSensorTypes = useCallback(async () => {
    try {
      const response = await client.get(
        `meta/signal-maps/sensor-types?dut_type=${dutType}`
      );
      setAllSensorTypes(response);
    } catch (err) {
      setAllSensorTypes({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dutType]);
  useEffect(() => {
    getSensorTypes();
  }, [getSensorTypes]);
  const [allSignalGroups, setAllSignalGroups] = useState<
    { [key: string]: number | string }[]
  >([]);
  const getSignalGroups = useCallback(async () => {
    try {
      const response = await client.get("meta/signal-maps/signal-groups");
      setAllSignalGroups(response.data);
    } catch (err) {
      setAllSignalGroups([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getSignalGroups();
  }, [getSignalGroups]);
  const getDeviceClasses = useCallback(async () => {
    try {
      const response = await client.get(
        `meta/signal-maps/device-classes?dut_type=${dutType}`
      );
      setAllDeviceClasses(response.data);
    } catch (err) {
      setAllDeviceClasses([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dutType]);
  useEffect(() => {
    getDeviceClasses();
  }, [getDeviceClasses]);

  const handleToggleSelectAll = () => {
    if (selected.length > 0) {
      dispatch(deselectAllSignals());
    } else {
      dispatch(selectAllVisibleSignals());
    }
  };

  const handleSelectSignals = (ids: number[], shiftClick = false) => {
    const allSelected = !ids.some((_id) => !selected.includes(_id));
    if (allSelected) {
      dispatch(deselectSignals(ids));
    } else {
      let idsToSelect = shiftClickList(selected, ids, signals, shiftClick);
      dispatch(selectSignals(idsToSelect));
    }
  };

  const handleSortClick = (
    e: React.MouseEvent,
    property: FalconKey<Signal>
  ) => {
    const switching = orderBy === property && order === "asc";
    dispatch(
      sortSignals({
        order: switching ? "desc" : "asc",
        key: property,
      })
    );
  };

  let tableBody: (JSX.Element | null)[] = signals
    .filter((s) => visibleSignals.includes(s.signal_id))
    .map((signal, index) => (
      <TableRow
        key={index}
        hover
        className={`clickable ${index % 2 === 0 ? "" : "alternating"} ${
          selected.includes(signal.signal_id) ? "checked" : ""
        }`}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="secondary"
            checked={selected.includes(signal.signal_id)}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) =>
              handleSelectSignals(
                [signal.signal_id],
                (e.nativeEvent as MouseEvent).shiftKey
              )
            }
          />
        </TableCell>

        {SIGNAL_LABELS.map(({ id }, i) => (
          <TableCell key={i} scope="row">
            {["big_endian", "is_coil"].includes(id)
              ? String(signal[id]).charAt(0).toUpperCase() +
                String(signal[id]).slice(1)
              : signal[id]}
          </TableCell>
        ))}
      </TableRow>
    ));

  return (
    <>
      {signals.length > 0 ? (
        <Paper sx={{ boxShadow: "none" }} square>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ButtonSignalFilters
              allDeviceClasses={allDeviceClasses}
              allSensorTypes={uniq(
                Object.entries(allSensorTypes).flatMap(([mt, st]) =>
                  Object.keys(st)
                )
              )}
              allSignalGroups={allSignalGroups}
            />

            {signalMap && (
              <SignalModifiers
                allDeviceClasses={allDeviceClasses}
                allSensorTypes={allSensorTypes}
                allSignalGroups={allSignalGroups}
              />
            )}
          </Box>

          <VirtualTable
            height={getVirtualTableHeight(
              Array(visibleSignals.length).fill(DATA_ROW_HEIGHT),
              vhToPx(100) - 220
            )}
            width="100%"
            itemCount={visibleSignals.length}
            itemSize={(index) => DATA_ROW_HEIGHT}
            header={
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="secondary"
                      indeterminate={selected.length > 0}
                      checked={false}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleToggleSelectAll}
                    />
                  </TableCell>

                  {SIGNAL_LABELS.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="left"
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(e) => handleSortClick(e, headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            }
            row={({ index }) => tableBody[index] || null}
          />
        </Paper>
      ) : (
        <Box mt={6} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="inherit" className="small">
            Loading...
          </Typography>

          <Box ml={4}>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SignalsListView;
