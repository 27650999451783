import type { Components } from "@mui/material/styles/components";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";
import createTheme from "@mui/material/styles/createTheme";
import colors, { addAlpha } from "./colors";

const components: Components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        color: "#FFFFFF",
        backgroundColor: colors.text.primary,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        "& .MuiAutocomplete-option": {
          padding: 0,
          "&[aria-selected=true]": {
            backgroundColor: addAlpha(colors.accent.primary, 0.1),
            "&.Mui-focused": {
              backgroundColor: addAlpha(colors.accent.primary, 0.2),
            },
            "&[data-focus=true]": {
              backgroundColor: addAlpha(colors.accent.primary, 0.2),
            },
          },
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: "2rem",
        height: "2rem",
        fontSize: "0.875rem",
        fontWeight: 700,
        backgroundColor: colors.accent.green,
        color: colors.text.primary,
        "&.bg-cta": {
          backgroundColor: addAlpha(colors.accent.cta, 0.5),
        },
      },
    },
  },
  MuiDataGrid: {},
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        color: colors.text.primary,
        "&:hover": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
        },
      },
      colorPrimary: {
        borderRadius: 3,
        color: colors.text.secondary,
        "&:hover": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
        },
      },
      colorSecondary: {
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      },
      sizeSmall: {
        padding: "0.375rem 0.5rem",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        lineHeight: 1.5,
        fontSize: "1rem",
        fontWeight: 700,
        textTransform: "none",
      },
      containedSizeSmall: {
        lineHeight: 1.25,
        fontSize: "0.875rem",
        padding: "0.375rem 1rem",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.accent.gray,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        paddingLeft: 3,
        paddingRight: 3,
      },
      icon: {
        color: "inherit",
      },
      label: {
        fontSize: "0.875rem",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        "&.MuiDrawer-paper": {
          color: "#FFFFFF",
          backgroundColor: colors.text.primary,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        lineHeight: 1.5,
        padding: 0,
        "&.Mui-focused": {
          border: `1px solid ${colors.accent.primary}`,
        },
        "&.Mui-error": {
          border: `1px solid ${colors.accent.red}`,
        },
      },
      input: {
        lineHeight: 1.5,
        height: "auto",
        minHeight: "1.1786em",
        textOverflow: "ellipsis",
        padding: "0.5rem 0.75rem",
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
      formControl: {
        borderRadius: 3,
        border: `1px solid ${colors.rules}`,
        "label + &": {
          marginTop: "1.5rem",
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: `${colors.text.secondary} !important`,
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(0.875)",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&:not(:hover)": {
          textDecoration: "none",
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        color: colors.text.primary,
        "&.MuiListItem-selected": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
          "&:hover": {
            backgroundColor: addAlpha(colors.accent.primary, 0.2),
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
        },
        "&.MuiListItem-selected": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
          "&:hover": {
            backgroundColor: addAlpha(colors.accent.primary, 0.2),
          },
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
        },
        "&.Mui-selected": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
          "&:hover": {
            backgroundColor: addAlpha(colors.accent.primary, 0.2),
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${colors.rules}`,
        "&.Mui-focused": {
          border: `1px solid ${colors.accent.primary}`,
          outline: "none",
        },
        "&.Mui-error": {
          border: `1px solid ${colors.accent.red}`,
        },
      },
      notchedOutline: {
        border: 0,
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderRadius: "50rem",
        color: colors.text.primary,
        fontFamily: "'Open Sans', sans-serif",
        backgroundColor: "#FFFFFF",
        "&.Mui-selected": {
          color: "#FFFFFF",
          backgroundColor: colors.accent.primary,
          "&:hover": {
            backgroundColor: addAlpha(colors.accent.primary, 0.7),
          },
        },
        "&:focus": {
          "&.Mui-selected": {
            backgroundColor: colors.accent.primary,
          },
        },
      },
      today: {
        color: colors.accent.primary,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        fontSize: "0.875rem",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "left",
        "&:focus": {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        border: `1px solid ${colors.rules}`,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: "1rem",
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 40,
        height: 20,
        padding: 0,
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 16,
          height: 16,
        },
        "& .MuiSwitch-track": {
          backgroundColor: colors.accent.gray,
          borderRadius: 10,
          opacity: 1,
        },
      },
      switchBase: {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(20px)",
          color: "#FFFFFF",
          "& + .MuiSwitch-track": {
            opacity: 1,
            border: 0,
          },
        },
        "&.Mui-disabled": {
          color: "#FFFFFF",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        lineHeight: 1.5,
        fontSize: "1rem",
        textTransform: "none",
        padding: "0.75rem 1rem",
        minWidth: "auto",
        maxWidth: "none",
        "@media (min-width: 0px)": {
          minWidth: "auto",
          maxWidth: "none",
        },
        "&.Mui-selected": {
          fontWeight: 700,
        },
      },
      textColorSecondary: {
        color: colors.text.primary,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${colors.rules}`,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        "&.dataTable .MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:first-of-type":
          {
            padding: "0 4px 0 16px",
          },
        "&.dataTable .MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:not(:first-of-type)":
          {
            padding: "0 4px 0 0",
          },
        "&.dataTable tr:first-of-type td": {
          borderTop: 0,
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${colors.rules}`,
        "& tr": {
          border: 0,
        },
        "& th": {
          color: colors.text.secondary,
          background: colors.striping,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        whiteSpace: "nowrap",
      },
      head: {
        fontSize: "0.75rem",
        textTransform: "uppercase",
        color: "rgba(0, 0, 0, 0.5)",
        fontWeight: 600,
        letterSpacing: 0.02,
        borderBottomColor: colors.rules,
      },
      body: {
        borderBottom: "none",
        borderTop: `1px solid ${colors.rules}`,
      },
      sizeSmall: {
        borderLeft: 0,
        borderRight: 0,
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        "&.MuiTableCell-paddingNone": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      stickyHeader: {
        borderBottom: `2px solid ${colors.rules}`,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&.alternating": {
          backgroundColor: colors.striping,
        },
        "&.checked": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
        },
        "&.subRow": {
          "& td": {
            borderTopColor: "transparent",
          },
        },
        "&.clickable": {
          cursor: "pointer",
        },
        "&.MuiTableRow-hover:hover": {
          backgroundColor: addAlpha(colors.accent.primary, 0.1),
          "& .cellTable-conditionMenu": {
            display: "block",
          },
          "& .iconCellCheck": {
            backgroundColor: addAlpha(colors.accent.green, 0.4),
          },
          "& .iconCellTimes": {
            backgroundColor: addAlpha(colors.accent.red, 0.16),
          },
          "& .iconCellFlag": {
            backgroundColor: addAlpha(colors.accent.yellow, 0.4),
          },
        },
        "& .cellTable-conditionMenu:not(.opened)": {
          display: "none",
        },
        "& .iconCell": {
          textAlign: "center",
          verticalAlign: "middle",
          "&.iconCellCheck": {
            backgroundColor: addAlpha(colors.accent.green, 0.3),
          },
          "&.iconCellTimes": {
            backgroundColor: addAlpha(colors.accent.red, 0.06),
          },
          "&.iconCellFlag": {
            backgroundColor: addAlpha(colors.accent.yellow, 0.3),
          },
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        "&.MuiTableSortLabel-active": {
          color: addAlpha("#000000", 0.5),
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        alignItems: "normal",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.text.primary,
        fontSize: "0.875rem",
        padding: "0.25rem 0.75rem",
        textAlign: "center",
      },
      arrow: {
        color: colors.text.primary,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: "1.75rem",
        fontWeight: 600,
        lineHeight: 1.3,
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 600,
        lineHeight: 1.3625,
      },
      h3: {
        fontSize: "1.125rem",
        fontWeight: 600,
        lineHeight: 1.5,
      },
    },
  },
};

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans",
  },
  spacing: 4,
  palette: {
    primary: {
      main: colors.accent.cta,
    },
    secondary: {
      main: colors.accent.primary,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  components,
});

export default theme;
