import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { FieldArrayWithId, FieldPath } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import CellHeader from "./CellHeader";
import TeardownCell from "./TeardownCell";
import { hasPassedStatus } from "../../../utils/statuses";
import CompletedCell from "./CompletedCell";
import { MetadataState, completeMetadata } from "../slice";
import { Box, CircularProgress, IconButton } from "@mui/material";
import CheckSquare from "../../../icons/CheckSquare";
import { DISCARD } from "../../../utils/labels";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  teardownFields?: Record<string, ConditionUIField[]>[];
  step: number;
  executor?: User | null;
  onSave: (callback?: () => void) => void;
  eventMap: EventMap;
  onViewEvents: (cell_id: number) => void;
};

const TeardownSubSection = ({
  formFields,
  teardownFields: _teardownFields,
  step,
  executor: preferredExecutor,
  onSave,
  eventMap,
  onViewEvents,
}: Props) => {
  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);
  const {
    status: { complete: completeStatus },
  } = useSelector<RootState, MetadataState>(({ metadata }) => metadata);
  const dispatch = useDispatch();
  const [updatingTeardownStatusForCellId, setUpdatingTeardownStatusForCellId] =
    useState<number | null>(null);

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );
  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);
  useEffect(() => {
    if (updatingTeardownStatusForCellId && completeStatus === "succeeded") {
      setUpdatingTeardownStatusForCellId(null);
    }
  }, [
    setUpdatingTeardownStatusForCellId,
    completeStatus,
    updatingTeardownStatusForCellId,
  ]);

  const { control } = useFormContext<MetadataRHFormFormat>();

  const teardownTypeState = useWatch({
    control,
    name: formFields.map(
      (field, fieldIndex) =>
        `metadata.${fieldIndex}.items.${step}.component_meta_mode` as FieldPath<MetadataRHFormFormat>
    ),
  });

  return (
    <>
      <TableHead>
        <BaseTableRow>
          <TableCell>EXECUTOR</TableCell>
          <TableCell />
          {formFields.map((field, fieldIndex) => (
            <CellHeader
              key={fieldIndex}
              cell_id={field.cell_id}
              hasEvents={field.cell_id in eventMap}
              onViewEvents={onViewEvents}
            />
          ))}
        </BaseTableRow>
      </TableHead>
      <TableBody>
        <BaseTableRow>
          <UserCell
            clearable={false}
            style={{ width: 254, minWidth: 254 }}
            error={!executor}
            value={executor}
            onChange={setExecutor}
          />
          <ConditionCell smallWidth />
          {formFields.map((field, fieldIndex) => {
            const teardownType = Object.values(teardownTypeState)[fieldIndex];
            const teardownFields =
              teardownType && _teardownFields && _teardownFields[fieldIndex]
                ? _teardownFields[fieldIndex][`${teardownType}`]
                : undefined;

            const teardownState = formFields[fieldIndex].items[step];

            // Are we in a partially filled state ?
            const partial = teardownFields?.some(
              (field) => field.default !== teardownState[field.id]
            );

            const renderDiscardCell = () => {
              return !!field.items[step].completed?.completed_at ? (
                <CompletedCell
                  onUndo={() =>
                    dispatch(
                      completeMetadata({
                        components: [field.items[step]],
                        executor,
                        conditionIdsToRequestedCellIds: {
                          [field.cell_condition_id!]: [field.cell_id],
                        },
                        undo: true,
                      })
                    )
                  }
                  status={field.status}
                  minimumStatus="F"
                  maximumStatus="O"
                  completed_at={field.items[step].completed!.completed_at}
                  completed_by={field.items[step].completed!.completed_by}
                />
              ) : (
                <Box px={4} py={2}>
                  <span>
                    {updatingTeardownStatusForCellId === field.cell_id ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconButton
                        onClick={() => {
                          setUpdatingTeardownStatusForCellId(field.cell_id);
                          dispatch(
                            completeMetadata({
                              components: [field.items[step]],
                              executor,
                              conditionIdsToRequestedCellIds: {
                                [field.cell_condition_id!]: [field.cell_id],
                              },
                            })
                          );
                        }}
                      >
                        <CheckSquare style={{ width: 22, height: 22 }} />
                      </IconButton>
                    )}
                  </span>
                </Box>
              );
            };

            return (
              <ConditionCell
                key={fieldIndex}
                className={
                  field.items[step].completed?.completed_at
                    ? "componentCompleted"
                    : ""
                }
              >
                {" "}
                {teardownType !== DISCARD ? (
                  <TeardownCell
                    disabled={!hasPassedStatus("F", field.status)}
                    partial={partial}
                    teardownType={teardownType}
                    teardownFields={teardownFields}
                    teardownState={teardownState}
                    formField={field}
                    executor={executor}
                    onSave={onSave}
                  />
                ) : (
                  renderDiscardCell()
                )}
              </ConditionCell>
            );
          })}
        </BaseTableRow>
      </TableBody>
    </>
  );
};

export default TeardownSubSection;
