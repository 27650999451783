import ReactS3Uploader from "react-s3-uploader";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import DeletableCard from "../DeletableCard";
import { useState, useRef } from "react";
import client from "../../api";
import Button from "../Button";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import TakingPictureIcon from "../../icons/TakingPicture";
import colors from "../../theme/colors";
import ConditionCell from "./ConditionCell";

type Props = {
  noTable?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  onChange: (value: { filename: string; publicUrl: string } | null) => void;
  value: { filename: string; publicUrl: string } | null;
};

const PhotoCell = ({
  noTable,
  value,
  className,
  disabled,
  error,
  onChange,
}: Props) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const control = (
    <>
      {value ? (
        <Box
          px={!noTable ? 4 : undefined}
          py={!noTable ? 4 : undefined}
          border={`1px solid ${error ? colors.accent.red : "transparent"}`}
          display="flex"
          flexWrap="wrap"
        >
          <DeletableCard disabled={disabled} onDelete={() => onChange(null)}>
            <CardMedia
              sx={{ width: "6rem", height: "6rem" }}
              image={value.publicUrl}
            />
          </DeletableCard>
        </Box>
      ) : null}

      <ReactS3Uploader
        multiple
        style={{ display: "none" }}
        accept="image/*"
        getSignedUrl={client.presignS3Upload}
        preprocess={(file, next) => {
          setUploading(true);
          next(file);
        }}
        onProgress={(percent) => setProgress(percent)}
        onError={(message) => setUploadError(message)}
        onFinish={({ filename, publicUrl }) => {
          if (uploadRef.current) {
            uploadRef.current.value = "";
          }
          setUploadError(null);
          setUploading(false);
          onChange({ filename, publicUrl });
        }}
        inputRef={(input) => (uploadRef.current = input)}
        uploadRequestHeaders={{}}
      />

      {uploadError ? (
        <Box mt={6} color={colors.accent.red} className="small">
          {uploadError}
        </Box>
      ) : null}
      {!value ? (
        <Box
          display="flex"
          alignItems="center"
          pl={!noTable ? 4 : undefined}
          py={!noTable ? 2 : undefined}
          border={`1px solid ${error ? colors.accent.red : "transparent"}`}
        >
          <Button
            color="tertiary"
            style={{ padding: "0.25rem" }}
            disabled={disabled || uploading}
            onClick={() => uploadRef.current?.click()}
            startIcon={<TakingPictureIcon style={{ width: 24, height: 24 }} />}
          >
            <b>Attach photo</b>
          </Button>
          {uploading ? (
            <Box ml={3}>
              <CircularProgressWithLabel value={progress} />
            </Box>
          ) : null}
        </Box>
      ) : null}
    </>
  );

  return noTable ? (
    <div className={className || ""}>{control}</div>
  ) : (
    <ConditionCell className={className || ""}>{control}</ConditionCell>
  );
};

export default PhotoCell;
