import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import Button from "../../../components/Button";
import StatusChip from "../../../components/StatusChip";
import ExclamationCircleOutlinedIcon from "../../../icons/ExclamationCircleOutlined";
import type { RootState } from "../../../store";
import colors from "../../../theme/colors";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import ConditionCell from "../../../components/forms/ConditionCell";
import InputCell from "../../../components/forms/InputCell";
import MetaCell from "../../../components/forms/MetaCell";
import TextareaCell from "../../../components/forms/TextareaCell";
import ThCell from "../../../components/forms/ThCell";
import UserCell from "../../../components/forms/UserCell";
import type { ConditionState } from "../slice";
import type { AuthState } from "../../../store/auth/slice";
import { getExecutorKeysForIndex } from "../../../utils/conditions";
import {
  BUILD_PHASES,
  cellIdToString,
  cellStatusToString,
  CHANNEL_POOL_EXPLAINER,
} from "../../../utils/labels";
import { hasPassedStatus } from "../../../utils/statuses";
import { isUndefined } from "lodash";
import client from "../../../api";
import Toast from "../../../components/Toast";
import Check from "../../../icons/Check";
import ConditionDatePicker from "../ConditionDatePicker";
import { editConditionPath } from "../../../utils/urlPathHelpers";
import SelectCell from "../../../components/forms/SelectCell";

type Props = {
  editMode: boolean;
  fields: FieldArrayWithId<ConditionRHFormFormat, "conditions">[];
};

const OverviewEdit = ({ editMode, fields }: Props) => {
  // Existing conditions in redux
  const {
    conditions,
    status: { get: conditionStatus },
  } = useSelector<RootState, ConditionState>(({ condition }) => condition);
  const [templateRefreshSuccess, setTemplateRefreshSuccess] = useState(false);
  const [templateRefreshError, setTemplateRefreshError] = useState(false);
  const [successfulTemplateUpdateIds, setSuccessfulTemplateUpdateIds] =
    useState<Array<number>>([]);

  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const navigate = useNavigate();

  const { control, setValue, getValues } = useFormContext();

  const watchKeys = useMemo(() => {
    return fields?.flatMap((field, index) =>
      getExecutorKeysForIndex(fields, index, "engineer_flag")
    );
  }, [fields]);

  const state = useWatch({
    name: watchKeys,
    control,
  });

  const handleRefreshComponentTemplateClick = async (
    cellConitionId: number
  ) => {
    try {
      const response = await client.post(
        `meta/cell-conditions/${cellConitionId}/refresh-templates`,
        {}
      );
      if (response) {
        setTemplateRefreshSuccess(true);
        setSuccessfulTemplateUpdateIds((successfulTemplateUpdateIds) => [
          ...successfulTemplateUpdateIds,
          cellConitionId,
        ]);
      }
    } catch (err) {
      setTemplateRefreshError(true);
    }
  };

  const isAllFlagged = (index: number) => {
    const keys = getExecutorKeysForIndex(fields, index, "engineer_flag");
    // Some subassemblies do not have an engineer_flag field–remove them from consideration.
    const flagValsForKeys = keys.reduce((flagVals: boolean[], key: string) => {
      const flagStatus = state[watchKeys.indexOf(key)];
      if (!isUndefined(flagStatus)) {
        flagVals.push(flagStatus);
      }
      return flagVals;
    }, []);
    return flagValsForKeys.every((val: boolean) => val);
  };

  const toggleExecutor = (index: number) => {
    const flagKeys = getExecutorKeysForIndex(fields, index, "engineer_flag");
    const execKeys = getExecutorKeysForIndex(
      fields,
      index,
      "preferred_executor"
    );
    const flagVals = getValues(flagKeys);
    const execVals = getValues(execKeys);
    const newFlagStatus = !isAllFlagged(index);
    const preferredExecutor = newFlagStatus ? user : null;

    flagKeys.forEach((key: string, flagIndex: number) => {
      if (!isUndefined(flagVals[flagIndex]))
        setValue(key, newFlagStatus, { shouldDirty: true });
    });
    execKeys.forEach((key: string, execIndex: number) => {
      if (!isUndefined(execVals[execIndex]))
        setValue(key, preferredExecutor, { shouldDirty: true });
    });
    setValue(`conditions.${index}.preferred_executor`, preferredExecutor, {
      shouldDirty: true,
    });
  };

  const bulkChangeExecutor = (index: number, user: User | null) => {
    const flagKeys = getExecutorKeysForIndex(fields, index, "engineer_flag");
    const execKeys = getExecutorKeysForIndex(
      fields,
      index,
      "preferred_executor"
    );
    const flagVals = getValues(flagKeys);
    const execVals = getValues(execKeys);

    flagKeys.forEach((key: string, flagIndex: number) => {
      if (!isUndefined(flagVals[flagIndex]))
        setValue(key, false, { shouldDirty: true });
    });
    execKeys.forEach((key: string, execIndex: number) => {
      if (!isUndefined(execVals[execIndex]))
        setValue(key, user, { shouldDirty: true });
    });
    setValue(`conditions.${index}.preferred_executor`, user, {
      shouldDirty: true,
    });
  };

  // We shouldn't hit that, OverviewEdit should be a child component only
  if (conditionStatus !== "succeeded") {
    return null;
  }

  const renderToasts = () => (
    <>
      <Toast
        severity="success"
        open={templateRefreshSuccess}
        onClose={() => setTemplateRefreshSuccess(false)}
      >
        Template updates successfully applied.
      </Toast>
      <Toast
        severity="error"
        open={templateRefreshError}
        onClose={() => setTemplateRefreshError(false)}
      >
        Error applying template updates.
      </Toast>
    </>
  );

  return (
    <>
      <Table size="small" style={{ width: "auto" }}>
        <TableHead>
          <BaseTableRow>
            <TableCell>SPECIFICATIONS</TableCell>
            {fields.map((field, fieldIndex) => (
              <TableCell key={`title-${field.id}`}>
                <Box display="flex" alignItems="center">
                  {field.status === "I" || !field.status ? (
                    `CONDITION ${fieldIndex + 1}`
                  ) : field.status === "S" ? (
                    <StatusChip
                      color="green"
                      label={cellStatusToString(field.status).toUpperCase()}
                    />
                  ) : field.status === "T" ? (
                    <StatusChip
                      color="violet"
                      label={cellStatusToString(field.status).toUpperCase()}
                    />
                  ) : (
                    <StatusChip
                      color="yellow"
                      label={cellStatusToString(field.status).toUpperCase()}
                    />
                  )}
                </Box>
              </TableCell>
            ))}
          </BaseTableRow>
        </TableHead>
        <TableBody className="small">
          <BaseTableRow>
            <ThCell required>Condition name</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`name-${field.id}`}
                name={`conditions.${fieldIndex}.name`}
                defaultValue={field.name}
                control={control}
                rules={{
                  required: true,
                  validate: (val = "") => {
                    if (!val) {
                      return false;
                    }

                    const visibleMatch = fields.some(
                      (_field, i) =>
                        _field.id !== field.id &&
                        _field.name?.toLowerCase() === val.toLowerCase()
                    );

                    if (visibleMatch) {
                      return false;
                    }

                    const conditionMatch = conditions?.some(
                      (condition) =>
                        condition.cell_condition_id !==
                          field.cell_condition_id &&
                        condition.name?.toLowerCase() === val.toLowerCase()
                    );

                    return !conditionMatch;
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <InputCell
                    ref={ref}
                    className={`cellStatus-${field.status}`}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={invalid}
                    disabled={field.backfill === 1}
                    endAdornment={
                      invalid ? (
                        <Tooltip
                          arrow
                          title={
                            !value
                              ? "Please fill in a condition name"
                              : "This condition name is already used in this experiment"
                          }
                        >
                          <div
                            style={{
                              color: colors.accent.red,
                              height: 20,
                              width: 20,
                              marginRight: 4,
                            }}
                          >
                            <ExclamationCircleOutlinedIcon
                              color="inherit"
                              style={{
                                width: 20,
                                height: 20,
                                transform: "rotate(180deg)",
                              }}
                            />
                          </div>
                        </Tooltip>
                      ) : null
                    }
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Condition description</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`description-${field.id}`}
                name={`conditions.${fieldIndex}.description`}
                defaultValue={field.description}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <TextareaCell
                    ref={ref}
                    className={`cellStatus-${field.status}`}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={invalid}
                    disabled={field.backfill === 1}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Cells with replicate conditions</ThCell>
            {fields.map((field, fieldIndex) => {
              const min = field.min_replicates;

              return (
                <Controller
                  key={`replicates-${field.id}`}
                  name={`conditions.${fieldIndex}.replicates`}
                  defaultValue={field.replicates}
                  control={control}
                  rules={{
                    required: true,
                    min,
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid },
                  }) => (
                    <InputCell
                      ref={ref}
                      className={`cellStatus-${field.status}`}
                      name={name}
                      type="number"
                      inputProps={{
                        min,
                        onWheel: (e) => e.currentTarget.blur(),
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(parseInt(e.target.value))}
                      error={invalid}
                      disabled={field.backfill === 1}
                      endAdornment={
                        invalid ? (
                          <Tooltip
                            arrow
                            title={
                              !value || value < 1
                                ? "Please fill in a number of replicates"
                                : `The number of replicate cells can’t be reduced to less than ${min}`
                            }
                          >
                            <div
                              style={{
                                color: colors.accent.red,
                                height: 20,
                                width: 20,
                                marginRight: 4,
                              }}
                            >
                              <ExclamationCircleOutlinedIcon
                                color="inherit"
                                style={{
                                  width: 20,
                                  height: 20,
                                  transform: "rotate(180deg)",
                                }}
                              />
                            </div>
                          </Tooltip>
                        ) : null
                      }
                    />
                  )}
                />
              );
            })}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Cell type</ThCell>
            {fields.map((field, fieldIndex) => (
              <InputCell
                className={`cellStatus-${field.status}`}
                key={fieldIndex}
                disabled
                value={field.cell_type}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Cell assembly</ThCell>

            {fields.map((field, fieldIndex) => (
              <InputCell
                className={`cellStatus-${field.status}`}
                key={fieldIndex}
                disabled
                value={field.cell_assembly}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Build phase</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`build-phase-${field.id}`}
                name={`conditions.${fieldIndex}.build_phase`}
                defaultValue={field.build_phase}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <SelectCell
                    className={`cellStatus-${field.status}`}
                    ref={ref}
                    options={BUILD_PHASES}
                    error={invalid}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            ))}
          </BaseTableRow>
          <BaseTableRow>
            <ThCell>Build config</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`build-config-${field.id}`}
                name={`conditions.${fieldIndex}.build_config`}
                defaultValue={field.build_config}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid },
                }) => (
                  <InputCell
                    ref={ref}
                    error={invalid}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value || ""}
                    className={`cellStatus-${field.status}`}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Cell IDs</ThCell>

            {fields.map((field, fieldIndex) => (
              <ConditionCell
                key={fieldIndex}
                className={`cellStatus-${field.status}`}
              >
                <Box p={4} whiteSpace="normal" fontSize="1rem">
                  {(field.cells as Cell[]).map(({ cell_id }, cellIndex) => (
                    <React.Fragment key={cellIndex}>
                      <MuiLink
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/cells/${cell_id}`, {
                            state: {
                              from: editConditionPath(
                                field.exp_id,
                                field.cell_condition_id!
                              ),
                              stateKey: "ALL",
                            },
                          })
                        }
                      >
                        {cellIdToString(cell_id)}
                      </MuiLink>
                      {cellIndex < field.cells.length - 1 ? ", " : ""}
                    </React.Fragment>
                  ))}
                </Box>
              </ConditionCell>
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Group number</ThCell>

            {fields.map((field, fieldIndex) => (
              <InputCell
                className={`cellStatus-${field.status}`}
                key={fieldIndex}
                disabled
                value={field.group_number}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Channel pool</ThCell>

            {fields.map((field, fieldIndex) => (
              <Controller
                key={`pool-${field.id}`}
                name={`conditions.${fieldIndex}.pool`}
                defaultValue={field.pool}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { invalid },
                }) => (
                  <MetaCell
                    ref={ref}
                    className={`cellStatus-${field.status}`}
                    endpoint="meta/channels/pool-ids"
                    searchKey="pool"
                    dropdownTooltipText={CHANNEL_POOL_EXPLAINER}
                    disabled={
                      hasPassedStatus("C", field.status, true) && !editMode
                    }
                    error={invalid}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell required>Planned on-test date</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`plan_test_start_date-${field.id}`}
                name={`conditions.${fieldIndex}.plan_test_start_date`}
                defaultValue={field.plan_test_start_date}
                control={control}
                rules={{ required: !hasPassedStatus("N", field.status, true) }}
                render={({ field: fieldProps, fieldState: { invalid } }) => (
                  <ConditionDatePicker
                    disabled={
                      hasPassedStatus("N", field.status, true) && !editMode
                    }
                    fieldIndex={fieldIndex}
                    disablePast={false}
                    invalid={invalid}
                    {...fieldProps}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Engineer build for all steps</ThCell>
            {fields.map((field, fieldIndex) => (
              <ConditionCell
                key={fieldIndex}
                className={`cellStatus-${field.status}`}
              >
                <Button
                  color="tertiary"
                  type="button"
                  size="small"
                  disabled={
                    hasPassedStatus("C", field.status, true) && !editMode
                  }
                  onClick={() => toggleExecutor(fieldIndex)}
                >
                  <b>{isAllFlagged(fieldIndex) ? "Un-flag all" : "Flag all"}</b>
                </Button>
              </ConditionCell>
            ))}
          </BaseTableRow>

          <BaseTableRow>
            <ThCell>Assign executor for all steps</ThCell>
            {fields.map((field, fieldIndex) => (
              <Controller
                key={`preferred_executor-${field.id}`}
                name={`conditions.${fieldIndex}.preferred_executor`}
                defaultValue={field.preferred_executor}
                control={control}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { invalid },
                }) => (
                  <UserCell
                    ref={ref}
                    className={`cellStatus-${field.status}`}
                    key={fieldIndex}
                    disabled={
                      (hasPassedStatus("C", field.status, true) && !editMode) ||
                      isAllFlagged(fieldIndex)
                    }
                    onBlur={onBlur}
                    onChange={(user: User | null) => {
                      bulkChangeExecutor(fieldIndex, user);
                      onChange(user);
                    }}
                    error={invalid}
                    value={isAllFlagged(fieldIndex) ? user : value}
                  />
                )}
              />
            ))}
          </BaseTableRow>

          {fields.some(({ can_refresh }) => can_refresh) && (
            <BaseTableRow>
              <ThCell>Update Condition Templates</ThCell>
              {fields.map(
                ({ can_refresh, cell_condition_id, status }, fieldIndex) => (
                  <ConditionCell
                    key={fieldIndex}
                    className={`cellStatus-${status}`}
                  >
                    {can_refresh && (
                      <Button
                        color="tertiary"
                        type="button"
                        size="small"
                        disabled={successfulTemplateUpdateIds.includes(
                          cell_condition_id!
                        )}
                        onClick={() =>
                          handleRefreshComponentTemplateClick(
                            cell_condition_id!
                          )
                        }
                      >
                        <b>Apply Template Updates</b>
                        {successfulTemplateUpdateIds.includes(
                          cell_condition_id!
                        ) && <Check />}
                      </Button>
                    )}
                  </ConditionCell>
                )
              )}
            </BaseTableRow>
          )}
        </TableBody>
      </Table>
      {renderToasts()}
    </>
  );
};

export default OverviewEdit;
