import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  cellIdToString,
  getInitialsForUser,
  isoDateToDateTimeString,
} from "../../utils/labels";
import Modal from "../../components/Modal";
import SmallChip from "../../components/SmallChip";
import PencilIcon from "../../icons/Pencil";
import ArrowBackIcon from "../../icons/ArrowBack";

type Props = {
  open: boolean;
  onEdit: () => void;
  onClose: () => void;
  label: string;
  note?: Note;
};

const NoteViewSidePanel = ({ open, onEdit, onClose, label, note }: Props) => {
  const isTablet = useMediaQuery("(min-width:760px)");

  const [viewingPhoto, setViewingPhoto] = useState("");

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop
        open={open}
        onClose={onClose}
        PaperProps={{ style: isTablet ? { width: 400 } : { width: "100%" } }}
      >
        <Box p={4}>
          <Box display="flex" alignItems="center" width="100%">
            <IconButton size="small" onClick={onClose}>
              <ArrowBackIcon style={{ width: 20, height: 20 }} />
            </IconButton>
            <Box ml={2} mr="auto">
              <Typography variant="h3">Note Details</Typography>
            </Box>
            <IconButton size="small" onClick={onEdit}>
              <PencilIcon style={{ width: 20, height: 20 }} />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box p={6}>
          {note ? (
            <>
              <Box display="flex" alignItems="flex-start">
                <Avatar>{getInitialsForUser(note.user)}</Avatar>
                <Box display="flex" width="100%" ml={3} alignItems="flex-end">
                  <Box mr={4}>{note.user?.name}</Box>
                  <Typography color="textSecondary" className="small">
                    {isoDateToDateTimeString(note.created_time)}
                  </Typography>
                </Box>
              </Box>
              <Box mt={6} mb={4} display="flex" alignItems="center">
                <Typography className="small" color="textSecondary">
                  {label}
                  {note.note_items.length !== 1 ? "s" : ""}:
                </Typography>
                <Box ml={2} className="small">
                  {note.note_items
                    .map(({ record_id }) => cellIdToString(record_id))
                    .join(", ")}
                </Box>
              </Box>
              <Typography whiteSpace="pre-line">{note.description}</Typography>
              <Box mt={4} display="flex" flexWrap="wrap">
                {note.note_tags.map((tag, index) => (
                  <Box key={index} mb={1} mr={1}>
                    <SmallChip deleteIcon={undefined} label={tag} />
                  </Box>
                ))}
              </Box>
              <Box mt={6} display="flex" flexWrap="wrap">
                {note.images.map(({ publicUrl }, index: number) => (
                  <Box key={index} mr={4} mb={2}>
                    <Card
                      style={{ cursor: "pointer" }}
                      onClick={() => setViewingPhoto(publicUrl)}
                    >
                      <CardMedia
                        sx={{ width: "7.5rem", height: "7.5rem" }}
                        image={publicUrl}
                      />
                    </Card>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Typography>Invalid note selected.</Typography>
          )}
        </Box>
      </Drawer>

      <Modal
        open={!!viewingPhoto}
        onClose={() => setViewingPhoto("")}
        maxWidth={false}
      >
        <a href={viewingPhoto} target="_blank" rel="noreferrer">
          <img
            src={viewingPhoto}
            alt="Note Attachment"
            style={{ width: "100%" }}
          />
        </a>
      </Modal>
    </>
  );
};

export default NoteViewSidePanel;
