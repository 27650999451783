import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowForwardCircle = ({ style, children, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ ...style, fill: "transparent" }}
    viewBox="0 0 24 24"
  >
    {children}
    <path
      d="M16.501 11.9971H7.50098"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.751 8.24707L16.501 11.9971L12.751 15.7471"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.001 22.4971C17.8 22.4971 22.501 17.7961 22.501 11.9971C22.501 6.19808 17.8 1.49707 12.001 1.49707C6.20199 1.49707 1.50098 6.19808 1.50098 11.9971C1.50098 17.7961 6.20199 22.4971 12.001 22.4971Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ArrowForwardCircle;
