import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "../../theme/colors";

type Props = {
  selectedCells: Cell[];
};

const SummaryContent = ({ selectedCells }: Props) => {
  const buildCount: Record<string, number> = {};
  selectedCells.forEach(({ condition }) => {
    if (!buildCount[condition.cell_assembly]) {
      buildCount[condition.cell_assembly] = 0;
    }
    buildCount[condition.cell_assembly]++;
  });
  const buildCountKeys = Object.keys(buildCount);

  const electrolyteVolume: Record<string, number> = {};
  selectedCells.forEach(({ electrolyte, fill }) => {
    if (!electrolyte.icm_pcm) {
      return;
    }

    if (!electrolyteVolume[electrolyte.icm_pcm]) {
      electrolyteVolume[electrolyte.icm_pcm] = 0;
    }
    electrolyteVolume[electrolyte.icm_pcm] += fill.nominal_volume || 0;
  });
  const electrolyteVolumeKeys = Object.keys(electrolyteVolume);

  const anodeMass: Record<string, number> = {};
  selectedCells.forEach(({ anode }) => {
    if (!anode.icm_pcm) {
      return;
    }

    if (!anodeMass[anode.icm_pcm]) {
      anodeMass[anode.icm_pcm] = 0;
    }
    anodeMass[anode.icm_pcm] += anode.nominal_mass || 0;
  });
  const anodeMassKeys = Object.keys(anodeMass);

  const gdeCount: Record<string, number> = {};
  selectedCells.forEach(({ gde }) => {
    if (!gde.icm_pcm) {
      return;
    }

    if (!gdeCount[gde.icm_pcm]) {
      gdeCount[gde.icm_pcm] = 0;
    }
    gdeCount[gde.icm_pcm]++;
  });
  const gdeCountKeys = Object.keys(gdeCount);

  const oeeCount: Record<string, number> = {};
  selectedCells.forEach(({ oee }) => {
    if (!oee.icm_pcm) {
      return;
    }

    if (!oeeCount[oee.icm_pcm]) {
      oeeCount[oee.icm_pcm] = 0;
    }
    oeeCount[oee.icm_pcm]++;
  });
  const oeeCountKeys = Object.keys(oeeCount);

  const rowCount = Math.max(
    buildCountKeys.length,
    electrolyteVolumeKeys.length,
    anodeMassKeys.length,
    gdeCountKeys.length,
    oeeCountKeys.length
  );

  const iterator = [...new Array(rowCount)];

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              borderBottom: 0,
              borderTop: 0,
              borderRight: `1px solid ${colors.rules}`,
            }}
          >
            Build Type count
          </TableCell>
          <TableCell
            style={{
              borderBottom: 0,
              borderTop: 0,
              borderRight: `1px solid ${colors.rules}`,
            }}
          >
            Electrolyte Volume by ID
          </TableCell>
          <TableCell
            style={{
              borderBottom: 0,
              borderTop: 0,
              borderRight: `1px solid ${colors.rules}`,
            }}
          >
            Anode Mass by ID
          </TableCell>
          <TableCell
            style={{
              borderBottom: 0,
              borderTop: 0,
              borderRight: `1px solid ${colors.rules}`,
            }}
          >
            OEE count by ID{" "}
          </TableCell>
          <TableCell
            style={{
              borderBottom: 0,
              borderTop: 0,
            }}
          >
            GDE count by ID
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ borderBottom: 0 }}>
        {iterator.map((_, index) => (
          <TableRow key={index}>
            {buildCountKeys[index] ? (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ color: colors.text.secondary }}>
                    {buildCountKeys[index]}
                  </div>
                  <Box ml={6}>
                    {buildCountKeys[index]
                      ? buildCount[buildCountKeys[index]]
                      : ""}
                  </Box>
                </Box>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                {index === 0 ? "NA" : ""}
              </TableCell>
            )}
            {electrolyteVolumeKeys[index] ? (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ color: colors.text.secondary }}>
                    {electrolyteVolumeKeys[index]}
                  </div>
                  <Box ml={6}>
                    {electrolyteVolumeKeys[index]
                      ? `${electrolyteVolume[electrolyteVolumeKeys[index]]} mL`
                      : ""}
                  </Box>
                </Box>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                {index === 0 ? "NA" : ""}
              </TableCell>
            )}
            {anodeMassKeys[index] ? (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ color: colors.text.secondary }}>
                    {anodeMassKeys[index]}
                  </div>
                  <Box ml={6}>
                    {anodeMassKeys[index]
                      ? `${anodeMass[anodeMassKeys[index]]} g`
                      : ""}
                  </Box>
                </Box>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                {index === 0 ? "NA" : ""}
              </TableCell>
            )}
            {oeeCountKeys[index] ? (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ color: colors.text.secondary }}>
                    {oeeCountKeys[index]}
                  </div>
                  <Box ml={6}>
                    {oeeCountKeys[index] ? oeeCount[oeeCountKeys[index]] : ""}
                  </Box>
                </Box>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                {index === 0 ? "NA" : ""}
              </TableCell>
            )}
            {gdeCountKeys[index] ? (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ color: colors.text.secondary }}>
                    {gdeCountKeys[index]}
                  </div>
                  <Box ml={6}>
                    {gdeCountKeys[index] ? gdeCount[gdeCountKeys[index]] : ""}
                  </Box>
                </Box>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  borderBottom: 0,
                  borderTop: 0,
                  borderRight: `1px solid ${colors.rules}`,
                }}
              >
                {index === 0 ? "NA" : ""}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SummaryContent;
