import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import styled from "@mui/styles/styled";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import FilterIcon from "../../icons/Filter";
import PlusCircleIcon from "../../icons/PlusCircle";
import type { RootState } from "../../store";
import type { AuthState } from "../../store/auth/slice";
import { useSelector } from "react-redux";
import Button from "../Button";
import Chip from "../Chip";
import SaveExperimentFilterModal from "./SaveExperimentFilterModal";
import DateFilter from "./DateFilter";
import MetaFilter from "./MetaFilter";
import OptionFilter from "./OptionFilter";
import TextFilter from "./TextFilter";
import UserFilter from "./UserFilter";
import ProjectFilter from "./ProjectFilter";

const MineLabel = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 500,
});

type Props = {
  labels: DataLabel<Experiment>[];
  filterOptions: FilterOptions<ExperimentFilters>;
  filters: ExperimentFilters;
  onChange: (filters: ExperimentFilters) => void;
};

const DEFAULT_FILTERS: (keyof ExperimentFilters)[] = [
  "exp_id",
  "owner",
  "project",
  "last_commit",
  "status",
  "description",
];

const ButtonExperimentFilters = ({
  labels,
  filterOptions,
  filters,
  onChange,
}: Props) => {
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const [filterState, setFilterState] = useState(filters);
  useEffect(() => setFilterState(filters), [filters]);

  // Filter menu
  const [buttonEl, setButtonEl] = useState<null | HTMLDivElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setButtonEl(event.currentTarget);
  const handleButtonClose = () => setButtonEl(null);

  // Add filter button
  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAddFilterAnchorEl(event.currentTarget);
  const handleAddFilterClose = () => setAddFilterAnchorEl(null);

  // UI state
  const [visibleFilters, setVisibleFilters] =
    useState<(keyof ExperimentFilters)[]>(DEFAULT_FILTERS);
  const availableFilters = (
    Object.keys(filterOptions) as Array<keyof ExperimentFilters>
  ).filter((key) => !visibleFilters.includes(key));

  useEffect(() => {
    if (!!buttonEl) {
      return;
    }

    const newKeys = Object.keys(filters).map(
      (key) => key as keyof ExperimentFilters
    );
    const changed = newKeys.filter((key) => visibleFilters.indexOf(key) === -1);

    if (changed.length > 0) {
      setVisibleFilters([...visibleFilters, ...changed]);
    }
  }, [buttonEl, filters, visibleFilters]);

  // Remove filter
  const handleRemoveFilter = (key: keyof ExperimentFilters) => {
    const newState = { ...filterState };
    delete newState[key];
    const index = visibleFilters.indexOf(key);
    visibleFilters.splice(index, 1);
    setFilterState(newState);
    setVisibleFilters([...visibleFilters]);
  };

  // Reset action
  const handleResetClick = () => {
    setFilterState({});
    setVisibleFilters(DEFAULT_FILTERS);
  };

  const getCleanFilters = () => {
    const finalFilters = { ...filterState };
    for (const key in finalFilters) {
      const typedKey = key as keyof ExperimentFilters;
      if (finalFilters[typedKey]?.length === 0) {
        delete finalFilters[typedKey];
      }
    }
    return finalFilters;
  };

  // Save action
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const onAfterSave = () => {
    handleApplyClick();
    setSaveModalOpen(false);
  };

  // Apply action
  const handleApplyClick = () => {
    const finalFilters = getCleanFilters();
    onChange(finalFilters);
    setButtonEl(null);
  };

  // Mine checkbox state
  const handleMineChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setFilterState({
        ...filterState,
        owner: [],
      });
    } else {
      if (!visibleFilters.includes("owner")) {
        setVisibleFilters(["owner", ...visibleFilters]);
      }
      setFilterState({
        ...filterState,
        owner: user ? [user] : [],
      });
    }
  };

  return (
    <>
      <Chip
        count={Object.keys(filters).length}
        clickable
        classes={{
          root: "qa-exp-filter-btn",
        }}
        onClick={handleButtonClick}
        onDelete={() => onChange({})}
        icon={<FilterIcon />}
        label="Filter"
      />

      <Popover
        open={!!buttonEl}
        onClose={handleButtonClose}
        anchorEl={buttonEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={6} pt={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    !!filterState.owner?.some(
                      (filter) => filter.user_id === user?.user_id
                    )
                  }
                  onChange={handleMineChecked}
                />
              }
              label={
                <MineLabel color="textSecondary">My Experiments</MineLabel>
              }
            />
          </FormGroup>

          <Box my={4}>
            <Divider />
          </Box>

          <table>
            <tbody>
              {visibleFilters.map((key) => {
                if (!filterOptions[key]) {
                  return null;
                }

                const expLabel = labels.find((label) => label.id === key);
                const { options } = filterOptions[key]!;

                let control;
                switch (key) {
                  case "last_commit":
                    control = (
                      <DateFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={([start, end]) => {
                          if (!start && !end) {
                            const { last_commit, ...newState } = filterState;
                            setFilterState(newState);
                          } else {
                            setFilterState({
                              ...filterState,
                              last_commit: [start, end],
                            });
                          }
                        }}
                      />
                    );
                    break;
                  case "exp_id":
                    control = (
                      <MetaFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        endpoint="meta/experiments/ids"
                        prefix="EXP"
                        value={filterState.exp_id || []}
                        onChange={(exp_id) =>
                          setFilterState({ ...filterState, exp_id })
                        }
                      />
                    );
                    break;
                  case "project":
                    control = (
                      <ProjectFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  case "status":
                    control = (
                      <OptionFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        selected={filterState[key] || []}
                        options={options || []}
                        onChange={(value) =>
                          setFilterState({
                            ...filterState,
                            [key]: value as ExperimentStatus[],
                          })
                        }
                      />
                    );
                    break;
                  case "owner":
                    control = (
                      <UserFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        value={filterState[key] || []}
                        onChange={(value) =>
                          setFilterState({ ...filterState, [key]: value })
                        }
                      />
                    );
                    break;
                  default:
                    control = (
                      <TextFilter
                        key={key}
                        removable={!DEFAULT_FILTERS.includes(key)}
                        onRemove={() => handleRemoveFilter(key)}
                        onClear={() =>
                          setFilterState({
                            ...filterState,
                            [key]: [],
                          })
                        }
                        value={
                          filterState[key] ? filterState[key]![0] || "" : ""
                        }
                        onChange={(e) =>
                          setFilterState({
                            ...filterState,
                            [key]: [e.target.value],
                          })
                        }
                      />
                    );
                    break;
                }

                return (
                  <tr key={key}>
                    <Box pr={4} pb={4} component="td">
                      <MineLabel color="textSecondary">
                        {expLabel?.label}
                      </MineLabel>
                    </Box>
                    <Box pb={4} component="td">
                      {control}
                    </Box>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>

        {availableFilters.length > 0 ? (
          <Box pb={3} px={3}>
            <Button
              color="tertiary"
              size="small"
              startIcon={<PlusCircleIcon />}
              onClick={handleAddFilterClick}
            >
              Add Filter
            </Button>
            <Menu
              anchorEl={addFilterAnchorEl}
              open={!!addFilterAnchorEl}
              onClose={handleAddFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {availableFilters.map((key) => {
                const expLabel = labels.find((label) => label.id === key);
                return (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      setVisibleFilters([...visibleFilters, key]);
                      handleAddFilterClose();
                    }}
                  >
                    {expLabel?.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        ) : null}

        {visibleFilters.length > 0 ? (
          <Box pt={3} pb={6} px={6} display="flex">
            <Button
              color="tertiary"
              type="button"
              size="small"
              onClick={handleResetClick}
            >
              <b>Reset</b>
            </Button>
            <Box ml="auto">
              <Button
                color="secondary"
                size="small"
                disabled={Object.keys(getCleanFilters()).length === 0}
                onClick={() => setSaveModalOpen(true)}
              >
                Save
              </Button>
            </Box>
            <Box ml={3}>
              <Button
                className="qa-exp-apply-filters-btn"
                color="primary"
                type="button"
                size="small"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        ) : null}
      </Popover>

      <SaveExperimentFilterModal
        open={saveModalOpen}
        data={getCleanFilters()}
        onClose={() => setSaveModalOpen(false)}
        onAfterSave={onAfterSave}
      />
    </>
  );
};

export default ButtonExperimentFilters;
